import React from 'react';
import Header from './Header';
import styled from 'styled-components';
import { headerZIndex, headerHeight } from 'config/theme';

const StyledHeader = styled.div`
  z-index: ${headerZIndex};
  width: 100%;
  position: fixed;
  top: 0;
`;

const HeaderContainer = () => {
  return (
    <div style={{ paddingTop: headerHeight }}>
      <StyledHeader data-for={'app-header'}>
        <Header />
      </StyledHeader>
    </div>
  );
};

export default HeaderContainer;
