import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export interface IBaseRequestOptions<
  TData = AxiosResponse,
  TError = AxiosError,
  TRequestData = void
> {
  autoFire?: boolean;
  onError?: (
    error: TError,
    requestData?: TRequestData,
    errorMessages?: string
  ) => void;
  onSuccess?: (data: TData) => void;
}

/** Defines the interface for the hook return value */
interface IBaseResult<TData, TError = AxiosError> {
  isLoading: boolean;
  isIdle: boolean;
  isSuccess: boolean;
  isError: boolean;
  error?: AxiosError | TError | null;
  data?: TData | null;
}

export type RequestCallback<TRequestData = void, TData = void> = (
  arg: TRequestData
) => Promise<TData>;
export interface IBaseReturn<
  TData = AxiosResponse,
  TError = AxiosError,
  TRequestData = any
> extends IBaseResult<TData, TError> {
  call: RequestCallback<TRequestData, void>;
  clear: () => void;
  isSuccess: boolean;
  isError: boolean;
}

const useBaseRequest = <TData = any, TError = any, TRequestData = void>(
  callback: RequestCallback<TRequestData, TData>,
  {
    autoFire = false,
    onError,
    onSuccess,
  }: IBaseRequestOptions<TData, TError, TRequestData>
): IBaseReturn<TData, TError> => {
  const [queryResult, setQueryResult] = useState<
    Partial<IBaseResult<TData, TError>>
  >({
    data: null,
    error: null,
    isIdle: !autoFire,
    isLoading: autoFire,
  });

  const wrappedCallback = useCallback(
    async (arg?) => {
      updateQueryResult({
        isIdle: false,
        isLoading: true,
        data: null,
        error: null,
      });

      try {
        const data = await callback(arg);
        updateQueryResult({ isLoading: false, data, error: null });
        onSuccess && onSuccess(data);
      } catch (err) {
        updateQueryResult({ isLoading: false, data: null, error: err });
        onError && onError(err, arg);
      }
    },
    [callback]
  );

  const updateQueryResult = (updatedValues: Partial<IBaseResult<TData>>) => {
    setQueryResult((queryResult) => ({ ...queryResult, ...updatedValues }));
  };

  const clearState = () => {
    updateQueryResult({
      isLoading: false,
      isIdle: true,
      error: null,
      data: null,
    });
  };

  useEffect(() => {
    if (autoFire) {
      // auto-fire - should only be available for queries
      wrappedCallback();
    }
  }, [autoFire]);

  return {
    ...queryResult,
    call: wrappedCallback,
    clear: clearState,
    isSuccess: Boolean(queryResult.data),
    isError: Boolean(queryResult.error),
    isLoading: Boolean(queryResult.isLoading),
    isIdle: Boolean(queryResult.isIdle),
  };
};

export default useBaseRequest;
