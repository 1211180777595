import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { persianRed } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="12"
    height="17"
    viewBox="0 0 12 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M5.70312 8.2608C5.18216 8.2608 4.68254 8.05008 4.31417 7.67501C3.94579 7.29994 3.73884 6.79123 3.73884 6.2608C3.73884 5.73036 3.94579 5.22166 4.31417 4.84658C4.68254 4.47151 5.18216 4.2608 5.70312 4.2608C6.22409 4.2608 6.72371 4.47151 7.09209 4.84658C7.46046 5.22166 7.66741 5.73036 7.66741 6.2608C7.66741 6.52344 7.6166 6.78351 7.51789 7.02616C7.41917 7.26882 7.27449 7.48929 7.09209 7.67501C6.90968 7.86073 6.69314 8.00805 6.45482 8.10856C6.21651 8.20907 5.96108 8.2608 5.70312 8.2608ZM5.70312 0.660797C4.24443 0.660797 2.84549 1.2508 1.81404 2.301C0.782588 3.3512 0.203125 4.77559 0.203125 6.2608C0.203125 10.4608 5.70312 16.6608 5.70312 16.6608C5.70312 16.6608 11.2031 10.4608 11.2031 6.2608C11.2031 4.77559 10.6237 3.3512 9.59221 2.301C8.56076 1.2508 7.16182 0.660797 5.70312 0.660797Z"
      fill={props.fill || persianRed}
    />
  </svg>
);
