export default {
  getInvoices: () => `/billing/invoice`,
  getInvoiceDetails: (invoiceId: number) =>
    `/billing/invoice-transactions/${invoiceId}`,
  paymentReceipt: (paymentToken: string) =>
    `/billing/payment-receipt/${paymentToken}`,
  createPaymentToken: () => `/billing/customer/payment-token`,
  canAccessBilling: () => `/billing/canAccessBilling`,
  getPaymentReceiptPDF: (invoiceId: number) =>
    `/billing/generate-billing-pdf/${invoiceId}`,
};
