import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="75"
    height="63"
    viewBox="0 0 75 63"
    fill={props.fill ?? 'none'}
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <circle
      cx="37.7033"
      cy="8.58075"
      r="6.98792"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
    />
    <path
      d="M27.6957 24.83C27.9175 23.1526 28.0102 22.4837 28.1668 21.9365C29.0423 18.8776 31.6257 16.6132 34.773 16.1463C35.336 16.0628 36.0113 16.0585 37.7033 16.0585C39.3953 16.0585 40.0707 16.0628 40.6336 16.1463C43.7809 16.6132 46.3644 18.8776 47.2398 21.9365C47.3964 22.4837 47.4892 23.1526 47.711 24.83L47.802 25.5186L49.3811 25.3098L47.802 25.5186C47.9293 26.4816 47.9422 26.6711 47.9297 26.7816C47.8681 27.3288 47.4726 27.78 46.9382 27.9129L47.3117 29.4147L46.9382 27.9129C46.8303 27.9397 46.6407 27.9518 45.6694 27.9518H29.7372C28.7659 27.9518 28.5764 27.9397 28.4684 27.9129L28.084 29.4586L28.4684 27.9129C27.934 27.78 27.5386 27.3288 27.4769 26.7816C27.4644 26.6711 27.4773 26.4816 27.6046 25.5186L27.6957 24.83Z"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
    />
    <circle
      cx="13.228"
      cy="41.5167"
      r="6.98792"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
    />
    <path
      d="M3.22032 57.7659C3.44211 56.0885 3.53487 55.4196 3.69147 54.8724C4.56692 51.8135 7.15035 49.5492 10.2976 49.0822C10.8606 48.9987 11.5359 48.9944 13.228 48.9944C14.92 48.9944 15.5953 48.9987 16.1583 49.0822C19.3056 49.5492 21.889 51.8135 22.7645 54.8724C22.9211 55.4196 23.0138 56.0885 23.2356 57.7659L23.3267 58.4545L24.9058 58.2457L23.3267 58.4545C23.454 59.4175 23.4669 59.607 23.4544 59.7176C23.3927 60.2647 22.9973 60.7159 22.4629 60.8488L22.8363 62.3506L22.4629 60.8488C22.3549 60.8756 22.1654 60.8877 21.194 60.8877H5.26189C4.29056 60.8877 4.10102 60.8756 3.99305 60.8488L3.60869 62.3945L3.99305 60.8488C3.45865 60.7159 3.06323 60.2647 3.00155 59.7175C2.98908 59.607 3.00194 59.4175 3.12927 58.4545L3.22032 57.7659Z"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
    />
    <circle
      cx="62.1777"
      cy="41.5168"
      r="6.98792"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
    />
    <path
      d="M52.17 57.7661C52.3918 56.0886 52.4846 55.4197 52.6412 54.8725C53.5166 51.8136 56.1001 49.5493 59.2473 49.0824C59.8103 48.9988 60.4856 48.9946 62.1777 48.9946C63.8697 48.9946 64.545 48.9988 65.108 49.0824C68.2553 49.5493 70.8387 51.8136 71.7142 54.8725C71.8708 55.4197 71.9635 56.0886 72.1853 57.7661L72.2764 58.4547L73.8555 58.2459L72.2764 58.4547C72.4037 59.4176 72.4166 59.6071 72.4041 59.7177C72.3424 60.2649 71.947 60.716 71.4126 60.8489L71.786 62.3507L71.4126 60.8489C71.3046 60.8757 71.1151 60.8878 70.1438 60.8878H54.2116C53.2403 60.8878 53.0507 60.8757 52.9428 60.8489L52.5584 62.3947L52.9428 60.8489C52.4084 60.716 52.0129 60.2649 51.9513 59.7177C51.9388 59.6071 51.9516 59.4176 52.079 58.4547L52.17 57.7661Z"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
    />
    <path
      d="M37.6423 35.1682V44.5951L45.2722 48.8936"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7034 35.1682V44.5951L30.0735 48.8936"
      stroke={props.stroke ?? '#425664'}
      strokeWidth="3.18566"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
