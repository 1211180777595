/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskCommentRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskCommentRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskUpdateRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskUpdateRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Customer_ResponsiblePartyRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_ResponsiblePartyRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Customer_SequenceRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_SequenceRequest';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistDetailResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistDetailResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskCommentResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskCommentResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderChecklistService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets list of checklists for an order along with checklist tasks and their details
   * for current employee or external user (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistDetailResponse List of checklists with respective checklist tasks and their details.
   * @throws ApiError
   */
  public getAsync({
    orderId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistDetailResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Creates a checklist that belongs to the specific order. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistResponse Created checklist for the order.
   * @throws ApiError
   */
  public createAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist to create.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/checklists',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Gets the checklist details for a specific checklistId (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistDetailResponse Checklist Details
   * @throws ApiError
   */
  public getChecklistByAsync({
    orderId,
    checklistId,
  }: {
    /**
     * order identifier
     */
    orderId: number,
    /**
     * checklist identifier
     */
    checklistId: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistDetailResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Requested checklist/Order is not available`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updates the specific checklist. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateAsync({
    orderId,
    checklistId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist to update.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed, checklist is Locked for an update`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order or checklist was not found.`,
        409: `Conflict`,
        422: `Client Error`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Deletes the specific checklist. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteAsync({
    orderId,
    checklistId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      errors: {
        400: `Checklist is Locked for Deletion.`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updates sequence numbers of checklists that belong to the specific order.
   * List of checklist identifiers and their sequence numbers must be provided
   * for all checklists in the order. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateChecklistSequenceAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * List of checklist identifiers and their sequence numbers.
     */
    requestBody?: Array<Workspace_Common_Requests_ChecklistManagement_Customer_SequenceRequest> | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/checklists/_sequence',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Creates checklist task for the specific checklist. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponse Checklist task was successfully created.
   * @throws ApiError
   */
  public createChecklistTaskAsync({
    orderId,
    checklistId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task to create.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found.`,
        409: `Checklist task name already exists.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Deletes checklist task from the specific checklist. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteChecklistTaskAsync({
    orderId,
    checklistId,
    checklistTaskId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task identifier.
     */
    checklistTaskId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        400: `Active parties are working on the task. Hence task cannot be deleted`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found or checklist task was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updates the checklist task - Name, Status, Priority, Due Date. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateChecklistTaskAsync({
    orderId,
    checklistId,
    checklistTaskId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask identifier.
     */
    checklistTaskId: number,
    /**
     * Checklist Task - Name, Status , Priority , Due Date to update.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskUpdateRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist or checklist task was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updates sequence numbers of checklist tasks that belong to the specific checklist.
   * List of checklist task identifiers and their sequence numbers must be provided
   * for all checklist tasks in the checklist. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateChecklistTaskSequenceAsync({
    orderId,
    checklistId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * List of checklist task identifiers and their sequence numbers.
     */
    requestBody?: Array<Workspace_Common_Requests_ChecklistManagement_Customer_SequenceRequest> | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/_sequence',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Creates checklist task comment for the specific checklist task. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskCommentResponse Checklist task comment was successfully created.
   * @throws ApiError
   */
  public createChecklistTaskCommentAsync({
    orderId,
    checklistId,
    checklistTaskId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task identifier.
     */
    checklistTaskId: number,
    /**
     * Checklist task comment to create.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskCommentRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskCommentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/comments',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found or checklist task was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Gets the comments for a task (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskCommentResponse List of comments on the Task
   * @throws ApiError
   */
  public getChecklistTaskCommentsAsync({
    orderId,
    checklistId,
    checklistTaskId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist-task identifier.
     */
    checklistTaskId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskCommentResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/comments',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        400: `Order not found or No parties available for the Task.`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Fetch all responsible parties to the specific order. (Auth)
   * @returns any Responsible parties were successfully fetched for the order.
   * @throws ApiError
   */
  public getChecklistTaskResponsiblePartiesAsync({
    orderId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/task-responsible-parties',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found or checklist task was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Gets the assigned responsble parties for a task (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyResponse List of Responsible Parties assigned to the Task
   * @throws ApiError
   */
  public getChecklistTaskAssignedPartiesAsync({
    orderId,
    checklistId,
    checklistTaskId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist-task identifier.
     */
    checklistTaskId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/responsible-parties',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        400: `Order not found or No parties available for the Task.`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Adding/Updating/Removing responsible parties to the specific checklist task. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateResponsiblePartiesAsync({
    orderId,
    checklistId,
    checklistTaskId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task identifier.
     */
    checklistTaskId: number,
    /**
     * Responsible parties to add to the specific checklist task.
     */
    requestBody?: Array<Workspace_Common_Requests_ChecklistManagement_Customer_ResponsiblePartyRequest> | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/responsible-parties',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found or checklist task was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updating last viewed task comment for the current employee/customer (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateLastViewedTaskcommentAsync({
    orderId,
    checklistId,
    checklistTaskId,
  }: {
    orderId: number,
    checklistId: number,
    checklistTaskId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/_lastviewedtaskcomment',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        401: `Unauthorized.`,
        403: `Access is restricted.`,
        404: `Order was not found or checklist was not found or checklist task was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

}
