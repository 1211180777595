import React from 'react';
import styled from 'styled-components';
import Card from 'components/ui/Card';
import { lighterBlue } from 'config/theme';
import SiteCardBody, { ISiteCardBodyProps } from './SiteCardBody';
import SiteCardHeader, { ISiteCardHeaderProps } from './SiteCardHeader';
import { SiteType } from './types';
import SiteCardFooter, { ISiteCardFooterProps } from './SiteCardFooter';

export type ISiteCardProps = {
  /**
   * Optional: If given, this value will adjust the margin at the bottom of our
   * SiteCard, which is important for when we're using this on Iris vs the main
   * Dashboard
   */
  marginBottom?: string;
} & ISiteCardBodyProps &
  ISiteCardHeaderProps &
  ISiteCardFooterProps;

const SiteCard = ({
  businessSource,
  businessSourceContact,
  citiesCount,
  closedSitesCount,
  countiesCount,
  countyInformation,
  estimatedCloseDate,
  fileId,
  fileName,
  fileNumber,
  hasDocumentPermissions,
  hasWireInstructionPermissions,
  lenderLiability,
  liabilityAmount,
  marginBottom,
  multiSiteId,
  multiSiteNumber,
  openDate,
  openSitesCount,
  orderPartiesContacts,
  ownerLiability,
  pushRoute,
  serviceTeamContacts,
  siteType,
  sitesCount,
  standalone,
  stateCount,
  stateInformation,
  status,
  street,
  transactionTypes,
  isInternal,
  isCloseDateEstimated,
  invoiceNumbers,
  checkNumbers,
}: ISiteCardProps) => {
  return (
    <Container
      multisite={siteType !== SiteType.MultisiteProject}
      marginBottom={marginBottom}
    >
      <SiteCardHeader
        estimatedCloseDate={
          siteType === SiteType.MultisiteProject
            ? estimatedCloseDate
            : undefined
        }
        businessSource={businessSource}
        businessSourceContact={businessSourceContact}
        citiesCount={citiesCount}
        closedSitesCount={closedSitesCount}
        countiesCount={countiesCount}
        countyInformation={countyInformation}
        fileId={fileId}
        fileName={fileName}
        fileNumber={fileNumber}
        hasDocumentPermissions={hasDocumentPermissions}
        hasWireInstructionPermissions={hasWireInstructionPermissions}
        liabilityAmount={liabilityAmount}
        multiSiteId={multiSiteId}
        multiSiteNumber={multiSiteNumber}
        openSitesCount={openSitesCount}
        pushRoute={pushRoute}
        siteType={siteType}
        sitesCount={sitesCount}
        standalone={standalone}
        stateCount={stateCount}
        stateInformation={stateInformation}
        street={street}
        status={status}
        transactionTypes={transactionTypes}
        isInternal={isInternal}
        isCloseDateEstimated={isCloseDateEstimated}
      />
      {siteType !== SiteType.MultisiteProject && (
        <SiteCardBody
          estimatedCloseDate={estimatedCloseDate}
          lenderLiability={lenderLiability}
          openDate={openDate}
          ownerLiability={ownerLiability}
          serviceTeamContacts={serviceTeamContacts}
          orderPartiesContacts={orderPartiesContacts}
        />
      )}
      {(invoiceNumbers || checkNumbers) && (
        <SiteCardFooter
          fileId={fileId}
          invoiceNumbers={invoiceNumbers}
          checkNumbers={checkNumbers}
        ></SiteCardFooter>
      )}
    </Container>
  );
};

SiteCard.displayName = 'SiteCard';

export default SiteCard;

const Container = styled(Card)<{ multisite?: boolean; marginBottom?: string }>`
  border-radius: 3px;
  font-family: 'Circular';
  min-width: 920px;
  width: 100%;
  ${({ marginBottom }) =>
    !marginBottom
      ? ''
      : `
    margin-bottom: ${marginBottom};
  `}
  ${({ multisite }) =>
    !multisite
      ? ''
      : `
    & > div:first-child {
      border-bottom: 2px solid ${lighterBlue};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`;
