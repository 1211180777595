import { generateMedia } from 'styled-media-query';
import {
  azure4,
  azure7,
  black as realBlack,
  coral7,
  gray1,
  gray10,
  gray2,
  gray8,
  green4,
  green6,
  green8,
  neutralGray6,
  neutralGray7,
  neutralGrayGhost1,
  neutralGrayGhost2,
  orange3,
  orange6,
  primaryBlue1,
  primaryBlue2,
  primaryBlue4,
  primaryBlue5,
  primaryBlue6,
  primaryBlue7,
  primaryBlue8,
  primaryBlue9,
  primaryBlueGhost1,
  primaryBlueGhost2,
  red2,
  red5,
  red7,
  red8,
  secondaryBlue5,
  secondaryBlue7,
  secondaryBlue8,
  teal8,
  white as realWhite,
  whiteButtonDisabled,
  whiteGhost1,
  whiteGhost2,
  yellow1,
  yellow3,
  yellow4,
  yellow5,
} from './colors';

/******************************************************************************
 * LEGACY COLORS WITH IDENTICAL CORRESPONDING DESIGN SYSTEM COLORS
 *****************************************************************************/
export const athensGray = gray2; // #E9EBF0
export const black = gray10; // #111111
export const blackSqueeze = primaryBlue1; // #ECF1F7
export const blizzardBlue = azure4; // #B6D9F0
export const blue = primaryBlue8; // #345580
export const blueHover = primaryBlueGhost1; // rgba(52,88,128,0.14)
export const blueLagoon = teal8; // #028193
export const bluePressed = primaryBlueGhost2; // rgba(52,88,128,0.26)
export const burntSienna = coral7; // #EB5757
export const cadetBlue = primaryBlue5; // #ADB8C7
export const catskillWhite = gray1; // #F5F7FA
export const defaultGrayHover = neutralGrayGhost2; // rgba(122, 122, 122, 0.26)
export const disabledTagBackground = secondaryBlue5; // #A0B8D7
export const extremeBlack = realBlack; // #000000
export const forestGreen = green8; // #09873c
export const fringyFlower = green4; // #A9DFBF
export const ghostButtonHoverBackground = neutralGrayGhost1; // rgba(122,122,122,0.14)
export const ghostButtonPressedBackground = neutralGrayGhost2; // rgba(122,122,122,0.26)
export const goldenPoppy = yellow5; // #F9C004
export const iconButtonOverlayBackground = primaryBlueGhost1; // rgba(52, 85, 128, 0.14)
export const infoMessageBackground = gray1; // #F5F7FA
export const jungleGreen = green6; // '#27AE60'
export const kashmirBlue = primaryBlue7; // #506D92
export const lightApricot = orange3; // #FED1B1
export const lightBlue = secondaryBlue7; // #4372B0
export const lighterBlue = primaryBlue2; // #E6EDF7
export const linkWater = primaryBlue4; // #C6D1E0
export const navyBlue = azure7; // #0288E0
export const nepal = primaryBlue6; // #99AABF
export const persianRed = red8; // #C62828
export const persianRedHover = red7; // #CE4646
export const persianRedDisabled = red5; // #E6A2A2
export const pippin = red2; // #FFE2E1
export const salomie = yellow3; // #FCDF81
export const sanJuan = primaryBlue9; // #2B4669
export const shuttleGrey = neutralGray7; // #686B6F
export const silver = neutralGray6; // #BCBCBC
export const successGreen = green6; // #56C568
export const sun = orange6; // #FD8C3C
export const transparent = whiteButtonDisabled; // rgba(255, 255, 255, 0.5)
export const trout = gray8; // #505963
export const veryPaleOrange = yellow1; // #fff3d1
export const white = realWhite; // #FFFFFF
export const whiteHover = whiteGhost1; // rgba(255, 255, 255, 0.14)
export const whitePressed = whiteGhost2; // rgba(255, 255, 255, 0.26)
export const yellow = yellow4; // #FBCE53
export const overlayWhite = whiteGhost1; // rgba(255, 255, 255, 0.14)
export const overlayWhiteActive = whiteGhost2; // rgba(255, 255, 255, 0.26)

/******************************************************************************
 * SPECIAL LEGACY COLORS (Confirmed to NOT match to a design system color)
 *****************************************************************************/
export const boundaryTintYellow = '#F4FA58';
export const boundaryYellow = '#F7FE2E';

/******************************************************************************
 * PENDING LEGACY COLORS (Not yet matched to a design system color.)
 *****************************************************************************/
export const aliceBlue = '#F3F5F7';
export const aquaHaze = '#F3F6F8';
export const baliHali = '#8396AE';
export const beautyBush = '#F0C7C7';
export const blizzardBlueLight = '#C0DEF2';
export const blueHaze = '#C9D1DF';
export const botticelli = '#D8E1ED';
export const boulder = '#7a7a7a';
export const boundaryBlue = '#013A6F';
export const brickRed = '#C32A2E';
export const darkGray = '#B1B1B1';
export const darkGray1 = '#675E46';
export const darkerGray = '#888888';
export const dimGray = '#6E6E6E';
export const disabledGrey = '#11111133';
export const disabledTextColor = 'rgba(122, 122, 122, 0.5)';
export const dustyGray = '#999999';
export const gallery = '#F0F0F0';
export const geyser = '#DCE1E8';
export const geyserApprox = '#CAD3dE';
export const goldenRod = '#FACD5E';
export const gray = '#DADADA';
export const gray82 = '#D1D1D1';
export const green = '#54C90C';
export const havelockBlue = '#54A5DA';
export const heather = '#C3CCDA';
export const jewel = '#16863F';
export const lightGray = '#E9EBEE';
export const lightGray1 = '#CCCCCC';
export const lightGreen = '#2AA059';
export const lightningYellow = '#FFCB21';
export const manatee = '#8E8E93';
export const mystic = '#E2E7EE';
export const outerSpaceBlack = '#2F353D';
export const pictonBlue = '#48A0DA';
export const pigeonPost = '#B4C7DF';
export const pink = '#FEE2E1';
export const placeholderColor = 'rgba(122, 122, 122, 0.5)';
export const red = '#D6403A';
export const redPressed = '#C6282842';
export const sanMarino = '#416eaa';
export const silverSand = '#bec2c7';
export const singedSienna = 'rgba(235, 87, 87, 0.15)';
export const softBlue = '#53bcf8';
export const solidYellow = '#FFFF00';
export const spindle = 'rgba(255, 255, 255, 0.7)';
export const summerSky = '#2AABF3';
export const valencia = '#D4413F';
export const veryLightGray = '#C7C7C7';
export const secondaryBlue = '#3759D0';
export const paleTurquoise = '#C6E6FC';

export const tagStateColors = {
  hover: `linear-gradient(0deg, ${whiteGhost1}, ${whiteGhost1}), ${secondaryBlue7};`,
  pressed: secondaryBlue8,
};

export const buttonBusyBackground = `linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), ${primaryBlue8};`;

export const stateColors = {
  warning: sun,
  error: persianRed,
  default: blue,
};

// Shadows
export const shadow = `
  0 3px 6px rgba(0, 0, 0, 0.08),
  0 3px 6px rgba(0, 0, 0, 0.12)
`;
export const shadow2 = `
  0px 1px 3px rgba(0, 0, 0, 0.2),
  0px 2px 2px rgba(0, 0, 0, 0.12),
  0px 0px 2px rgba(0, 0, 0, 0.14)
`;
export const shadow3 = `
  0px 3px 5px rgba(0, 0, 0, 0.2),
  0px 2px 14px rgba(0, 0, 0, 0.1),
  0px 8px 10px rgba(0, 0, 0, 0.12)
`;
export const heavyShadow = `
  0px 5px 6px rgba(0, 0, 0, 0.2),
  0px 3px 16px rgba(0, 0, 0, 0.12),
  0px 9px 12px rgba(0, 0, 0, 0.14)
`;
export const insetShadow = `
  inset 0px -2px 4px rgba(0, 0, 0, 0.16),
  inset 0px -2px 5px rgba(0, 0, 0, 0.14),
  inset 0px 2px 5px rgba(0, 0, 0, 0.14)
`;
export const inlineFormShadow = `
  0px 24px 38px rgba(0, 0, 0, 0.14),
  0px 9px 46px rgba(0, 0, 0, 0.12),
  0px 11px 15px rgba(0, 0, 0, 0.2)
`;
export const optionsShadow = `
  0px 5px 6px 0px rgba(0, 0, 0, 0.2),
  0px 3px 16px 0px rgba(0, 0, 0, 0.12)
`;
export const tagPressedShadow = `
  0px -2px 4px 0px rgba(0, 0, 0, 0.16) inset,
  0px -2px 5px 0px rgba(0, 0, 0, 0.14) inset
`;

//focusShadows
export const focusShadow =
  '0 0 0 1px #0064E5, 0 0 0 4px rgba(46, 137, 255, 0.4)';
export const focusShadow2 =
  '0 0 0 1px #FFCB21, 0 0 0 4px rgba(255, 203, 33, 0.6)';
export const focusShadow3 =
  '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 3px #0064E5, 0 0 0 7px rgba(46, 137, 255, 0.4)';
export const focusShadow4 =
  '0px 0px 0px 5px #FFFFFF, 0px 0px 0px 6px #0064E5, 0 0 0 10px rgba(46,137,255,0.4)';

export const linearGradient = `linear-gradient(0deg, ${neutralGrayGhost1}, ${neutralGrayGhost1}), ${primaryBlue8};`;
// Grid
export const minPageWidth = 980;
export const gridSpacing = 25;

// Distances and Layout
export const headerHeight = 55;
export const liveDealsHeaderHeight = 131;
export const pageContentBottomPadding = 36;
export const noOverflowClassName = 'no-overflow';
export const footerHeight = 59;
export const footerTopPadding = 20;
export const footerBottomPadding = 61;

// Elevations / Dropdown
export const dropdownBG = `
  0.0px 5.0px 6.0px 0px rgba(0, 0, 0, 0.2),
  0.0px 3.0px 16.0px 0px rgba(0, 0, 0, 0.12),
  0.0px 9.0px 12.0px 0px rgba(0, 0, 0, 0.14)
`;

// Media Queries
export const media = generateMedia({
  lg: '1300px',
  md: '1150px',
  sm: '960px',
  xs: '640px',
});

export const rowHoverColor = primaryBlueGhost2; // rgba(52, 88, 128, 0.26)

// Other settings
export const headerZIndex = 10;
export const subheaderZIndex = headerZIndex - 1;

export const pencilIcon =
  '<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9543 4.82461C18.3289 4.44995 18.3289 3.82553 17.9543 3.47009L15.7064 1.22217C15.3509 0.847514 14.7265 0.847514 14.3518 1.22217L12.5842 2.98016L16.1867 6.5826L17.9543 4.82461ZM0.941162 14.6329V18.2353H4.5436L15.1684 7.60089L11.566 3.99845L0.941162 14.6329Z" fill="#345580"/> </svg>';
export const radiusIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 13V11H11.72C11.36 11.62 10.71 12 10 12C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8C10.71 8 11.36 8.38 11.72 9H14V7L17 10L14 13Z" fill="#505963"/></svg>';
export const undoIcon =
  '<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.99989 4.78824V0.64706L3.70577 5.82353L8.99989 11V6.85883C10.6848 6.85883 12.3007 7.51328 13.4921 8.67821C14.6835 9.84314 15.3528 11.4231 15.3528 13.0706C15.3528 14.7181 14.6835 16.298 13.4921 17.463C12.3007 18.6279 10.6848 19.2824 8.99989 19.2824C7.31498 19.2824 5.69909 18.6279 4.50768 17.463C3.31627 16.298 2.64694 14.7181 2.64694 13.0706H0.529297C0.529297 15.2672 1.42173 17.3739 3.01027 18.9271C4.59882 20.4803 6.75335 21.3529 8.99989 21.3529C11.2464 21.3529 13.401 20.4803 14.9895 18.9271C16.578 17.3739 17.4705 15.2672 17.4705 13.0706C17.4705 10.874 16.578 8.76732 14.9895 7.21408C13.401 5.66084 11.2464 4.78824 8.99989 4.78824Z" fill="#345580"/></svg>';
export const deleteIcon =
  '<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3998 1.17778H10.9998L10.0284 0.200001H5.17123L4.1998 1.17778H0.799805V3.13333H14.3998V1.17778ZM1.77123 15.8444C1.77123 16.3631 1.97593 16.8605 2.34028 17.2272C2.70464 17.594 3.19881 17.8 3.71409 17.8H11.4855C12.0008 17.8 12.495 17.594 12.8593 17.2272C13.2237 16.8605 13.4284 16.3631 13.4284 15.8444V4.11111H1.77123V15.8444Z" fill="#345580"/></svg>';
export const overlayIcon =
  '<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.99 16.3926L1.62 10.7136L0 11.9624L9 18.9L18 11.9624L16.37 10.7037L8.99 16.3926ZM9 13.8752L16.36 8.19628L18 6.9376L9 0L0 6.9376L1.63 8.19628L9 13.8752Z" fill="#345580"/> </svg>';
export const mapOptionIcon =
  '<svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13 0L9.25 5L12.1 8.8L10.5 10C8.81 7.75 6 4 6 4L0 12H22L13 0Z" fill="#505963"/>  </svg>';
export const closeIcon =
  '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 1.60531L12.59 0.195312L7 5.78531L1.41 0.195312L0 1.60531L5.59 7.19531L0 12.7853L1.41 14.1953L7 8.60531L12.59 14.1953L14 12.7853L8.41 7.19531L14 1.60531Z" fill="white"/> </svg>';
export const flexboxGridTheme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1.25,
    outerMargin: 2,
    mediaQuery: 'only screen',
    container: {
      lg: 90, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
};

export const collapsibleShadow = `0px 4px 4px rgba(50, 57, 68, 0.05),
0px 1px 3px rgba(0, 0, 0, 0.17)`;
