import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { UserType } from 'store/auth/types';
import { HasPermissions } from 'components/HasPermissions';
import SearchBar from 'components/SearchBar';
import { blue, white } from 'config/theme';
import {
  ApolloFeatures,
  OfficeViewFeatures,
  PlatformFeatures,
} from 'store/features/types';
import {
  DashboardIcon,
  PropertySearchIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import Logo from './Logo';
import LinkIcon from './LinkIcon';
import HeaderWrapper from './HeaderWrapper';
import AccountDropdown from './AccountDropdown';
import Notifications from './Notifications';
import ResourcesNav from './ResourcesNav';
import AccountingNav from './AccountingNav';
import Forum from 'icons/Forum';

interface IHeaderProps {
  userType: UserType;
}

class Header extends React.Component<IHeaderProps> {
  public accountDropdownNode = null;
  public accountDropdownTimeoutId = null;
  public activeLinkStyle = {
    background: blue,
    color: white,
    fill: white,
  };

  public render() {
    const { userType } = this.props;
    const isExternal = userType === UserType.External;

    return (
      <HeaderWrapper>
        <Logo />
        {!isExternal && <SearchBar />}
        <RightArea>
          <NavIconsContainer>
            <LinkIcon
              to="/dashboard"
              activeOn={/^\/dashboard/}
              label="Dashboard"
              icon={<DashboardIcon />}
              domId="dashboardIcon"
            />
            {userType === UserType.Internal && (
              <HasPermissions
                forFeatures={[
                  OfficeViewFeatures.IncomingWires,
                  OfficeViewFeatures.OutgoingWires,
                ]}
              >
                <AccountingNav />
              </HasPermissions>
            )}
            <LinkIcon
              to="/property-search"
              activeOn={/^\/property-search/}
              label="Property Search"
              icon={<PropertySearchIcon style={{ height: 16 }} />}
              domId="propertySearchIcon"
            />
          </NavIconsContainer>

          <Separator />

          {userType === UserType.Internal && (
            <HasPermissions forFeatures={[ApolloFeatures.Relay]}>
              <LinkIcon
                to="/relay"
                activeOn={/^\/relay/}
                label="Relay"
                icon={<Forum />}
                domId="relayIcon"
              />
            </HasPermissions>
          )}

          <HasPermissions forFeatures={[PlatformFeatures.Notifications]}>
            <Notifications />
          </HasPermissions>

          <HasPermissions forFeatures={[PlatformFeatures.Resources]}>
            <ResourcesNav userType={userType} />
          </HasPermissions>

          <AccountDropdown />
        </RightArea>
      </HeaderWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userType: state.auth.session.type,
  };
}

export default withRouter<RouteComponentProps<any>, any>(
  connect(mapStateToProps)(Header)
);

const RightArea = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0;
  justify-content: flex-end;
`;

const NavIconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  white-space: nowrap;
  padding-right: 24px;
`;

const Separator = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.14),
      rgba(255, 255, 255, 0.14)
    ),
    ${blue};
  height: 55px;
  margin-right: 24px;
  width: 1px;
`;
