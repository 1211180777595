import { IUiLoaders, IUiLoadingKeys, IUiModalKeys, IUiModals } from './types';

export const getLoadingState = (
  loaders: IUiLoaders,
  loadingKey: IUiLoadingKeys
): boolean => {
  return loaders && loaders[loadingKey] ? true : false;
};

export const getModalState = (
  modals: IUiModals,
  modalKey: IUiModalKeys
): boolean => {
  return modals && modals[modalKey] ? true : false;
};
