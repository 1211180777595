import { AxiosError } from 'axios';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import workspaceAPI from 'services/workspaceAPI';
import { AsyncAction } from 'store';
import { collectionToMaphash } from 'store/utils';
import { showNotification } from 'store/notifications/actions';
import notificationTheme from 'components/Tile/themes';
import {
  UsersTypeKeys,
  IUserFormParams,
  IUserPostParams,
  ISendInvitationParams,
  IUser,
} from './types';
import { FORM_ERROR_STATUS_CODE } from 'utils/constants';
import { addToast } from 'store/ui/actions';
import { getSession } from 'store/auth/actions';
import { UserType } from 'store/auth/types';

interface IFetchAllUsersParams {
  page?: number;
  query?: string | null;
}

export function fetchUsers({ page, query }: IFetchAllUsersParams) {
  return (dispatch) => {
    dispatch({
      type: UsersTypeKeys.FETCH_ALL_REQUEST,
      query,
    });

    let url;

    if (query) {
      url = buildUsersPath('users/_search', { page, q: query });
    } else {
      url = buildUsersPath('users', { page });
    }

    return workspaceAPI
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: UsersTypeKeys.FETCH_ALL_SUCCESS,
          resultsById: collectionToMaphash(data.users, 'id'),
          pagination: data._meta.pagination,
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.FETCH_ALL_ERROR,
          error,
        });
        dispatch(
          showNotification(
            {
              title: 'An error occurred while getting users information.',
              ...notificationTheme.alert,
            },
            true
          )
        );
      });
  };
}

export function fetchUser(userId: number): AsyncAction<void> {
  return (dispatch) => {
    dispatch({ type: UsersTypeKeys.REQUEST_START });

    const url = buildUsersPath(`users/${userId}`);

    return workspaceAPI
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: UsersTypeKeys.FETCH_SUCCESS,
          result: { [userId]: data },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.REQUEST_ERROR,
          error,
        });
        dispatch(
          showNotification({
            title: 'An error occurred while getting user information.',
            ...notificationTheme.alert,
          })
        );
      });
  };
}

export function updateUser(
  userId: number,
  userFormParams: IUserFormParams
): AsyncAction<void> {
  return (dispatch) => {
    dispatch({ type: UsersTypeKeys.REQUEST_START });
    const url = buildUsersPath(`users/${userId}`);

    if (
      userFormParams.type === 'Internal' &&
      userFormParams.domain &&
      userFormParams.domain.length > 0
    )
      userFormParams.username = `${userFormParams.domain}\\${userFormParams.username}`;
    const params: IUserPostParams = normalizeUserFormParams(userFormParams);
    return workspaceAPI
      .put(url, params)
      .then(() => {
        dispatch({ type: UsersTypeKeys.REQUEST_SUCCESS });
        dispatch(push('/admin/users'));
        dispatch(
          showNotification(
            {
              title: 'User was updated successfully.',
              ...notificationTheme.success,
            },
            true
          )
        );
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.REQUEST_ERROR,
          error,
        });

        let errorMessage = 'An error occurred while updating user.';
        window.scrollTo(0, 0);

        if (
          error.response &&
          error.response.status === FORM_ERROR_STATUS_CODE
        ) {
          const { errors } = error.response.data;
          errorMessage = 'Invalid user data';
          dispatch(
            showNotification({
              title: errorMessage,
              ...notificationTheme.alert,
            })
          );
          throw new SubmissionError(errors);
        } else {
          dispatch(
            showNotification({
              title: errorMessage,
              ...notificationTheme.alert,
            })
          );
        }
      });
  };
}

export function createUser(userFormParams: IUserFormParams): AsyncAction<void> {
  return (dispatch) => {
    dispatch({ type: UsersTypeKeys.REQUEST_START });
    const url = buildUsersPath('users');
    if (userFormParams.type === 'Internal') {
      userFormParams.username = `${userFormParams.domain}\\${userFormParams.username}`;
    } else {
      userFormParams.username = userFormParams.email;
    }

    const params: IUserPostParams = normalizeUserFormParams(userFormParams);

    const useremail = userFormParams.email;
    const isExternalUser = userFormParams.type === 'External';

    return workspaceAPI
      .post(url, params)
      .then(() => {
        dispatch({ type: UsersTypeKeys.REQUEST_SUCCESS });
        dispatch(push('/admin/users'));
        if (isExternalUser) {
          addToast(
            'User created successfully, invitation sent to ' + useremail,
            'success'
          )(dispatch);
        } else {
          dispatch(
            showNotification(
              {
                title: 'User was created successfully.',
                ...notificationTheme.success,
              },
              true
            )
          );
        }
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.REQUEST_ERROR,
          error,
        });

        let errorMessage = 'An error occurred while creating user.';

        window.scrollTo(0, 0);

        if (
          error.response &&
          error.response.status === FORM_ERROR_STATUS_CODE
        ) {
          const { errors } = error.response.data;
          errorMessage = 'Invalid user data';
          dispatch(
            showNotification({
              title: errorMessage,
              ...notificationTheme.alert,
            })
          );
          throw new SubmissionError(errors);
        } else {
          dispatch(
            showNotification({
              title: errorMessage,
              ...notificationTheme.alert,
            })
          );
        }
      });
  };
}

export function goToPage(num: number): AsyncAction<void> {
  return (dispatch): Promise<void> => {
    const searchParams = new URLSearchParams(location.search.substring(1));
    const query = searchParams.get('q') || null;

    return new Promise((resolve) => {
      dispatch(fetchUsers({ query, page: num }))
        .then(() => {
          window.scrollTo(0, 0);
          searchParams.set('p', num.toString());

          return dispatch(push(`?${searchParams}`));
        })
        .then(() => resolve());
    });
  };
}

function buildUsersPath(path, query?: any) {
  const params = new URLSearchParams(query as any).toString();
  return `${path}?${params}`;
}

export function normalizeUserFormParams(
  userFormParams: IUserFormParams
): IUserPostParams {
  const activityRights = userFormParams.activityRights.filter((permission) =>
    Boolean(permission)
  );

  return {
    ...userFormParams,
    activityRights,
  };
}

export function resetUsers() {
  return { type: UsersTypeKeys.RESET };
}

export function sendInvitation(params: ISendInvitationParams) {
  return (dispatch) => {
    dispatch({ type: UsersTypeKeys.REQUEST_START });
    const url = 'users/_send-invitation';
    var toastMessage = `ClarityFirst invitation email sent to ${params.firstName} ${params.lastName} (${params.email})`;
    if (params.isEmailSendOnBehalfChecked) {
      toastMessage =
        toastMessage +
        ` on behalf of ${params.fromEmailName} (${params.fromEmailAddress})`;
    }
    toastMessage = toastMessage + `.`;
    return workspaceAPI
      .post(url, params)
      .then(() => {
        dispatch({ type: UsersTypeKeys.REQUEST_SUCCESS });
        addToast(toastMessage, 'success')(dispatch);
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.REQUEST_ERROR,
          error,
        });
        addToast(
          'An error occurred while sending the invitation.',
          'error'
        )(dispatch);
      });
  };
}

export function requestEmailVerificationCode() {
  return () => {
    const url = '/users/_me/email-verification';

    // no need to dispatch any actions for the time being
    return workspaceAPI.get(url);
  };
}

export function resetEmailVerification(user: IUser) {
  return (dispatch) => {
    if (!user.id) return;
    // intentionally don't dispatch request/success/error because those cause
    // the user admin form to unmount so that the ViewLoader can appear. For form
    // submission this is okay, but not for the Reset Email Verification link,
    // which we expect to do its job on click.
    const url = `${AppConfig.workspaceBackendUrl}/users/${user.id}/_reset-email-verification`;

    return workspaceAPI
      .delete(url)
      .then(() => {
        dispatch({
          // This is actually an update not a fetch, but it can be thought of as
          // a fetch. Do this in order to avoid adding all the plumbing for
          // implementation of UPDATE_USER since this is the only function that would
          // use it.
          type: UsersTypeKeys.FETCH_SUCCESS,
          result: {
            // okay to do non-null assertion here because we already verified that above
            [user.id!]: {
              ...user,
              lastEmailVerification: null,
            },
          },
        });
        addToast(
          `The last email verification date for ${user.email} has been reset.`,
          'success'
        )(dispatch);
      })
      .catch(() => {
        addToast(
          `There was a problem resetting the last email verification date for ${user.email}.`,
          'error'
        )(dispatch);
      });
  };
}

export function bypassEmailVerification(user: IUser) {
  return (dispatch) => {
    if (!user.id) return;

    const url = `${AppConfig.workspaceBackendUrl}/users/${user.id}/_bypass-email-verification`;

    return workspaceAPI
      .put(url)
      .then(() => {
        addToast(
          `The last email verification date for ${user.email} has been set to today.`,
          'success'
        )(dispatch);
      })
      .catch(() => {
        addToast(
          `There was a problem bypassing the email verification for ${user.email}.`,
          'error'
        )(dispatch);
      });
  };
}

export function processEmailCode(emailCode: string): AsyncAction<string> {
  return (dispatch) => {
    const url = `${AppConfig.workspaceBackendUrl}/users/_me/email-verification`;
    const params = { code: emailCode };

    return workspaceAPI
      .put(url, params)
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          //should initiate and update session
          dispatch(getSession({ type: UserType.External }));
        }
        return 'success';
      })
      .catch(() => {
        return 'failure';
      });
  };
}

export function activateAccount(userId: number, name: string) {
  return (dispatch) => {
    dispatch({ type: UsersTypeKeys.REQUEST_START });
    const searchParams = new URLSearchParams(location.search.substring(1));
    const page = Number(searchParams.get('p')) || 1;
    const query = searchParams.get('q') || null;
    const url = buildUsersPath(`users/${userId}/_activate-account`);

    return workspaceAPI
      .put(url)
      .then(() => {
        dispatch({ type: UsersTypeKeys.REQUEST_SUCCESS });
        dispatch(fetchUsers({ page, query }));
        addToast(
          `${name}'s ClarityFirst account has been activated successfully.`,
          'success'
        )(dispatch);
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.REQUEST_ERROR,
          error,
        });
        addToast(
          'An error occurred while activating the user account.',
          'error'
        )(dispatch);
      });
  };
}

export function deactivateAccount(userId: number, name: string) {
  return (dispatch) => {
    dispatch({ type: UsersTypeKeys.REQUEST_START });
    const searchParams = new URLSearchParams(location.search.substring(1));
    const page = Number(searchParams.get('p')) || 1;
    const query = searchParams.get('q') || null;
    const url = buildUsersPath(`users/${userId}/_deactivate-account`);

    return workspaceAPI
      .put(url)
      .then(() => {
        dispatch({ type: UsersTypeKeys.REQUEST_SUCCESS });
        dispatch(fetchUsers({ page, query }));
        addToast(
          `${name}'s ClarityFirst account has been disabled successfully.`,
          'success'
        )(dispatch);
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: UsersTypeKeys.REQUEST_ERROR,
          error,
        });
        addToast(
          'An error occurred while deactivating the user account.',
          'error'
        )(dispatch);
      });
  };
}
