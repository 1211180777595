/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Microsoft_AspNetCore_JsonPatch_Operations_Operation } from '../models/Microsoft_AspNetCore_JsonPatch_Operations_Operation';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UpdateSectionLineItemViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UpdateSectionLineItemViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateClosingGroupGeneralInformationViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateClosingGroupGeneralInformationViewModel';
import type { Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto } from '../models/Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto';
import type { Workspace_Common_Features_SettlementStatement_Models_DTOs_SettlementStatementSectionOrderDto } from '../models/Workspace_Common_Features_SettlementStatement_Models_DTOs_SettlementStatementSectionOrderDto';
import type { Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType } from '../models/Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType';
import type { Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto } from '../models/Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto';
import type { WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_GroupBalanceSummaryViewModel } from '../models/WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_GroupBalanceSummaryViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersistentClosingGroupsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the Settlement Statement Header for a particular Closing Group (Auth)
   * @returns Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto Header data successfully retrieved
   * @throws ApiError
   */
  public getCommercialHeader({
    orderId,
    closingGroupId,
  }: {
    /**
     * Order id to provide the order id to the filters applied to the controller
     */
    orderId: number,
    /**
     * Identifier for the Closing Group for which the Settlement Statement Header is to be retrieved
     */
    closingGroupId: number,
  }): CancelablePromise<Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/header',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order or Closing Group could not be found`,
      },
    });
  }

  /**
   * Updates properties and parties for Closing Groups settlement statements (Auth)
   * @returns any Data successfully updated
   * @throws ApiError
   */
  public patchGeneralInformation({
    orderId,
    closingGroupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Identifier for the Closing Group to be updated
     */
    closingGroupId: number,
    /**
     * Data used in the update process
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateClosingGroupGeneralInformationViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/header',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Endpoint received invalid payload`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order or Closing Group could not be found`,
      },
    });
  }

  /**
   * Fetches a settlement statement by id. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Settlement statement fetched successfully
   * @throws ApiError
   */
  public getSettlementStatementAsync({
    orderId,
    settlementStatementId,
    closingGroupId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Identifier for the Settlement Statement
     */
    settlementStatementId: number,
    closingGroupId: string,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{settlementStatementId}',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'closingGroupId': closingGroupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Current user doesn't have access to the settlement statement`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Gets the settlement statement for a closing group (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Settlement Statement successfully retrieved
   * @throws ApiError
   */
  public getSettlementStatement({
    orderId,
    closingGroupId,
    statementType,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * The type of Settlement Statement to filter by
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Settlement statement/order not found`,
      },
    });
  }

  /**
   * Creates a Settlement Statement (Auth)
   * @returns any Settlement statement was created.
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Success
   * @throws ApiError
   */
  public createSettlementStatementAsync({
    orderId,
    closingGroupId,
    statementType,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * The type of Settlement Statement to create
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
  }): CancelablePromise<any | Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      errors: {
        400: `The endpoint was called with invalid data`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Closing group/Order not found`,
        409: `Settlement statement already exists`,
      },
    });
  }

  /**
   * Exports a closing group settlement statement by its Id, the related Order Id and specific Type (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel Settlement Statement successfully exported
   * @throws ApiError
   */
  public exportClosingGroupSettlementStatementByIdAndType({
    orderId,
    closingGroupId,
    statementType,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * The type of Settlement Statement to filter by
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
    /**
     * Export settings
     */
    requestBody?: Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}/export',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Updates properties of a Settlement Statement's section (Auth)
   * @returns any Section properties successfully patched
   * @throws ApiError
   */
  public updateSettlementStatementLineDetail({
    orderId,
    lineItemDetailId,
    closingGroupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * The Id for the Settlement Statement whose section needs to be updated.
     */
    lineItemDetailId: number,
    closingGroupId: string,
    /**
     * The Json PATCH operations to be performed.
     */
    requestBody?: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation> | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/line-items/{lineItemDetailId}',
      path: {
        'orderId': orderId,
        'lineItemDetailId': lineItemDetailId,
        'closingGroupId': closingGroupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement or section could not be found`,
        422: `External user or user cannot access into this order`,
      },
    });
  }

  /**
   * Updates Sections Positions (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel Section properties successfully patched
   * @throws ApiError
   */
  public updateSettlementStatementSectionPositionAsync({
    orderId,
    settlementStatementId,
    closingGroupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * The Id for the Settlement Statement whose section needs to be updated.
     */
    settlementStatementId: number,
    closingGroupId: string,
    /**
     * List of objects representing the Section, by the Id, and new position.
     */
    requestBody?: Array<Workspace_Common_Features_SettlementStatement_Models_DTOs_SettlementStatementSectionOrderDto> | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{settlementStatementId}/sections',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'closingGroupId': closingGroupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement or section could not be found`,
        422: `Un-matching section ordering cannot be processed`,
      },
    });
  }

  /**
   * Updates properties of a Settlement Statement's section (Auth)
   * @returns any Section properties successfully patched
   * @throws ApiError
   */
  public updateSettlementStatementSectionPropertyAsync({
    orderId,
    settlementStatementId,
    sectionId,
    closingGroupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * The Id for the Settlement Statement whose section needs to be updated.
     */
    settlementStatementId: number,
    /**
     * The Id for the specific section that will be updated.
     */
    sectionId: number,
    closingGroupId: string,
    /**
     * The Json PATCH operations to be performed.
     */
    requestBody?: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation> | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{settlementStatementId}/sections/{sectionId}',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
        'closingGroupId': closingGroupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement or section could not be found`,
        422: `External user or user cannot access into this order`,
      },
    });
  }

  /**
   * Updates the line item position values for a section (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel Line item positions processed
   * @throws ApiError
   */
  public updateSectionLineItemPositionsAsync({
    orderId,
    settlementStatementId,
    sectionId,
    closingGroupId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Settlement Statement identifier
     */
    settlementStatementId: number,
    /**
     * Section identifier
     */
    sectionId: number,
    closingGroupId: string,
    /**
     * List of new line item positions for the section
     */
    requestBody: Array<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UpdateSectionLineItemViewModel>,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{settlementStatementId}/sections/{sectionId}/line-items',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
        'closingGroupId': closingGroupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieves the export settings (print and signature options) for a settlement statement (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel Setting successfully retrieved
   * @throws ApiError
   */
  public getExportSettingsAsync({
    orderId,
    closingGroupId,
    statementType,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Order Id
     */
    closingGroupId: number,
    /**
     * Settlement statement type
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}/export/settings',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement print/signature options not found`,
      },
    });
  }

  /**
   * .
   * Gets balance summary for a specific closing group (Auth)
   * @returns WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_GroupBalanceSummaryViewModel Balance summary successfully retrieved.
   * @throws ApiError
   */
  public getBalanceSummary({
    orderId,
    closingGroupId,
  }: {
    /**
     * Order id.
     */
    orderId: number,
    /**
     * Closing group id.
     */
    closingGroupId: number,
  }): CancelablePromise<WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_GroupBalanceSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/orders/{orderId}/groups/{closingGroupId}/settlement-statement/balance-summary',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
      },
      errors: {
        401: `Invalid authorization header.`,
        403: `External user or user cannot access file.`,
        404: `Order or closing group not found.`,
      },
    });
  }

}
