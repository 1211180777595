import { useCallback, useEffect, useState } from 'react';
import useInterval from './useInterval';
import { noop } from 'lodash';

const useBackgroundProcessingStatus = ({
  inProgress,
  pollingList,
  fetchStatuses,
  clearList = noop,
  shouldAutoClearList = false,
}) => {
  const [pollingDelay, setPollingDelay] = useState<number | null>(null);

  useEffect(() => {
    setPollingDelay(inProgress ? 10 * 1000 : null); //10 seconds
  }, [inProgress]);

  useEffect(() => {
    if (shouldAutoClearList) {
      const timeout = setTimeout(clearList, 7000);
      return () => clearTimeout(timeout);
    }
  }, [shouldAutoClearList]);

  useInterval(
    useCallback(() => {
      fetchStatuses({
        requestData: pollingList,
      });
    }, [pollingList]),
    pollingDelay
  );
};

export default useBackgroundProcessingStatus;
