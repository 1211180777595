import { AnyAction } from 'redux';
import {
  SortingTypeKeys,
  SortingField,
  SortingDirection,
  ISorting,
} from 'store/searchSorting/types';
import { initialState } from 'store/searchSorting/reducers';

export function setSorting(value: ISorting): AnyAction {
  return {
    type: SortingTypeKeys.SET,
    sorting: value,
  };
}

export function sortingToURLParams(sorting: ISorting): string | null {
  const { field, direction } = sorting;
  return field !== SortingField.DEFAULT ? `${field},${direction}` : null;
}

export function sortingFromURLParams(queryString: string): ISorting {
  const params = new URLSearchParams(queryString);
  const [field, direction] = (params.get('sort') || '').split(',');

  if (field && direction) {
    return {
      field: field as SortingField,
      direction: direction as SortingDirection,
    };
  }

  return { ...initialState };
}
