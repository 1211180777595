import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108 54C108 83.8242 83.8242 108 54 108C24.1758 108 0 83.8242 0 54C0 24.1758 24.1758 0 54 0C83.8242 0 108 24.1758 108 54Z"
      fill="url(#paint0_linear_2090_304513)"
    />
    <path
      d="M54 91.917C74.8763 91.917 91.8 93.6722 91.8 95.8374C91.8 98.0026 74.8763 99.7578 54 99.7578C33.1236 99.7578 16.2 98.0026 16.2 95.8374C16.2 93.6722 33.1236 91.917 54 91.917Z"
      fill="url(#paint1_linear_2090_304513)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.7046 86.668H25.6057C24.8491 86.668 24.2374 87.276 24.2374 88.0282V90.7488H83.0728V88.0282C83.0728 87.276 82.4599 86.668 81.7046 86.668Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.7046 86.668H25.6057C24.8491 86.668 24.2374 87.276 24.2374 88.0282V90.7488H83.0728V88.0282C83.0728 87.276 82.4599 86.668 81.7046 86.668Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.0728 90.749H24.2374C25.1282 92.5228 26.9521 93.4695 28.9456 93.4695H78.3865C80.365 93.4695 82.178 92.5296 83.0619 90.7708L83.0728 90.749Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.0728 90.749H24.2374C25.1282 92.5228 26.9521 93.4695 28.9456 93.4695H78.3865C80.365 93.4695 82.178 92.5296 83.0619 90.7708L83.0728 90.749Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.968 58.1033C78.968 56.6016 77.7421 55.3828 76.2315 55.3828H31.0787C29.5682 55.3828 28.3422 56.6016 28.3422 58.1033V86.6689H78.968V58.1033Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3378 55.3828H31.0787C29.5682 55.3828 28.3422 56.6016 28.3422 58.1033V86.6689L67.3378 55.3828Z"
      fill="white"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.2517 71.446C45.8757 70.3714 46.0412 69.1186 45.7183 67.9202L41.8235 55.3828H31.0793C29.5673 55.3828 28.3427 56.6002 28.3427 58.1033V77.3618L42.414 73.6129C43.6194 73.2905 44.6278 72.522 45.2517 71.446Z"
      fill="#EFEFEF"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.2773 79.2753C61.6534 78.2007 61.4878 76.9479 61.8107 75.7495L68.0555 55.8135H76.4498C77.9617 55.8135 79.1863 57.0309 79.1863 58.534V85.1911L65.1151 81.4422C63.9096 81.1198 62.9012 80.3513 62.2773 79.2753Z"
      fill="#D8D8D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.968 58.1033C78.968 56.6016 77.7421 55.3828 76.2315 55.3828H31.0787C29.5682 55.3828 28.3422 56.6016 28.3422 58.1033V86.6689H78.968V58.1033Z"
      stroke="#394C66"
      strokeWidth="0.675"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2583 68.5023C43.4351 69.1555 43.045 69.8287 42.385 70.0041L26.3515 74.2645C25.6901 74.4399 25.0124 74.0517 24.837 73.3972L19.7184 54.4533C19.5415 53.8002 19.933 53.1282 20.5917 52.9529L31.6793 50.0068L39.4652 54.4641L43.2583 68.5023Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2583 68.5023C43.4351 69.1555 43.045 69.8287 42.385 70.0041L26.3515 74.2645C25.6901 74.4399 25.0124 74.0517 24.837 73.3972L19.7184 54.4533C19.5415 53.8002 19.933 53.1282 20.5917 52.9529L31.6793 50.0068L39.4652 54.4641L43.2583 68.5023Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6792 50.0068L33.3246 56.0957L39.4664 54.4641L31.6792 50.0068Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6792 50.0068L33.3246 56.0957L39.4664 54.4641L31.6792 50.0068Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.114 58.5431L30.2545 56.9102"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9363 61.588L36.7056 58.46"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.7591 64.6326L37.5284 61.5059"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5817 67.6765L38.3523 64.5498"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.6261 78.2337C66.4492 78.8869 66.8393 79.5602 67.4994 79.7355L83.5329 83.996C84.1943 84.1713 84.8719 83.7832 85.0474 83.1287L90.166 64.1848C90.3428 63.5316 89.9514 62.8597 89.2927 62.6843L78.205 59.7383L70.4191 64.1955L66.6261 78.2337Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.6261 78.2337C66.4492 78.8869 66.8393 79.5602 67.4994 79.7355L83.5329 83.996C84.1943 84.1713 84.8719 83.7832 85.0474 83.1286L90.166 64.1848C90.3428 63.5316 89.9514 62.8597 89.2927 62.6843L78.205 59.7383L70.4191 64.1955L66.6261 78.2337Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.2048 59.7383L76.5594 65.8271L70.4176 64.1955L78.2048 59.7383Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.2048 59.7383L76.5594 65.8271L70.4176 64.1955L78.2048 59.7383Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.558 67.3948C82.2557 68.463 82.8833 69.5712 83.9605 69.8711C85.0377 70.1695 86.1554 69.5471 86.4577 68.4804C86.7587 67.4136 86.1311 66.3053 85.0552 66.0055C83.9794 65.7043 82.8617 66.328 82.558 67.3948Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.558 67.3948C82.2557 68.463 82.8833 69.5712 83.9605 69.8711C85.0377 70.1695 86.1554 69.5471 86.4577 68.4804C86.7587 67.4136 86.1311 66.3053 85.0552 66.0055C83.9794 65.7043 82.8617 66.328 82.558 67.3948Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.7068 79.2527L81.2044 75.1274C80.9452 74.4207 80.1529 74.062 79.4469 74.3337L77.5882 75.0471L76.4745 71.2792C76.1789 70.2753 74.8885 69.9809 74.1812 70.7545L69.7105 75.636L82.7068 79.2527Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.7068 79.2527L81.2044 75.1274C80.9452 74.4207 80.1529 74.062 79.4469 74.3337L77.5882 75.0471L76.4745 71.2792C76.1789 70.2753 74.8885 69.9809 74.1812 70.7545L69.7105 75.636L82.7068 79.2527Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.41333"
      y="0.238636"
      width="18.3001"
      height="18.3035"
      rx="4.725"
      transform="matrix(0.965912 -0.25887 0.258769 0.965939 20.8774 5.02724)"
      fill="white"
      stroke="#394C66"
      strokeWidth="0.675"
    />
    <rect
      x="0.41333"
      y="0.238636"
      width="18.3001"
      height="18.3035"
      rx="4.725"
      transform="matrix(0.965912 -0.25887 0.258769 0.965939 20.8774 5.02724)"
      fill="white"
      stroke="#394C66"
      strokeWidth="0.675"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9896 17.475L24.8636 17.5088C25.219 18.8354 25.3967 19.4987 25.7008 19.9969C26.4178 21.1716 27.6823 21.9017 29.0582 21.9353C29.6417 21.9496 30.305 21.7718 31.6316 21.4163L38.0396 19.6989C39.3662 19.3434 40.0295 19.1656 40.5277 18.8614C41.7024 18.1442 42.4325 16.8796 42.4663 15.5037C42.4779 15.0296 42.3628 14.5028 42.1287 13.6105C42.0021 15.1545 40.9254 16.5236 39.3432 16.9477L29.4234 19.6062C27.5965 20.0958 25.7236 19.1573 24.9896 17.475Z"
      fill="#D3DFEF"
    />
    <rect
      x="0.41333"
      y="0.238636"
      width="18.3001"
      height="18.3035"
      rx="4.725"
      transform="matrix(0.965912 -0.25887 0.258769 0.965939 20.8774 5.02724)"
      stroke="#394C66"
      strokeWidth="0.675"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7348 6.96973L34.385 5.18744C35.6093 4.85933 36.8677 5.58602 37.1957 6.81054L38.9776 13.4621L36.7609 14.0562L35.573 9.62182L34.979 7.40463L32.7623 7.99873L28.3288 9.18692L27.7348 6.96973Z"
      fill="url(#paint2_linear_2090_304513)"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3284 9.18716L26.1117 9.78125L27.8936 16.4328C28.2216 17.6573 29.48 18.384 30.7043 18.0559L37.3545 16.2736L36.7605 14.0564L30.1103 15.8387L28.3284 9.18716Z"
      fill="url(#paint3_linear_2090_304513)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.7609 14.0568L30.1107 15.8391L28.3288 9.1875L32.7623 7.99931L34.979 7.40522L35.5729 9.62241L36.7609 14.0568ZM33.8761 12.1573L30.5511 13.0485L30.6996 13.6027L34.0246 12.7115L33.8761 12.1573ZM30.2541 11.9397L34.6875 10.7515L34.836 11.3057L30.4026 12.4939L30.2541 11.9397ZM34.3906 9.64281L29.9572 10.831L30.1056 11.3852L34.539 10.197L34.3906 9.64281Z"
      fill="url(#paint4_linear_2090_304513)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.5566 15.5164C74.6827 14.9927 74.7661 14.4528 74.7661 13.8917C74.7661 10.0103 71.6026 6.86392 67.6988 6.86392C66.0952 6.86392 64.6329 7.41595 63.4462 8.31073C60.8532 5.27962 56.9961 3.35156 52.6805 3.35156C44.8749 3.35156 38.5459 9.64328 38.5459 17.404C38.5459 17.4344 38.551 17.4637 38.551 17.494C38.2571 17.4566 37.9673 17.404 37.6632 17.404C33.7594 17.404 30.5959 20.5504 30.5959 24.4308C30.5959 28.3122 33.7594 31.4585 37.6632 31.4585H69.4651C74.3441 31.4585 78.2998 27.5256 78.2998 22.6746C78.2998 19.7133 76.8161 17.1068 74.5566 15.5164Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.4653 26.9075H37.6634C34.5609 26.9075 31.9333 24.9178 30.9815 22.1556C30.7354 22.8704 30.5951 23.6338 30.5951 24.4305C30.5951 28.3119 33.7596 31.4572 37.6634 31.4572H69.4653C74.3443 31.4572 78.3 27.5243 78.3 22.6743C78.3 21.8847 78.1871 21.1234 77.9898 20.3984C76.9811 24.1454 73.5532 26.9075 69.4653 26.9075Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.5566 15.5164C74.6827 14.9927 74.7661 14.4528 74.7661 13.8917C74.7661 10.0103 71.6026 6.86392 67.6988 6.86392C66.0952 6.86392 64.6329 7.41595 63.4462 8.31073C60.8532 5.27962 56.9961 3.35156 52.6805 3.35156C44.8749 3.35156 38.5459 9.64328 38.5459 17.404C38.5459 17.4344 38.551 17.4637 38.551 17.494C38.2571 17.4566 37.9673 17.404 37.6632 17.404C33.7594 17.404 30.5959 20.5504 30.5959 24.4308C30.5959 28.3122 33.7594 31.4585 37.6632 31.4585H69.4651C74.3441 31.4585 78.2998 27.5256 78.2998 22.6746C78.2998 19.7133 76.8161 17.1068 74.5566 15.5164Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6481 43.2819C63.1637 43.2819 63.6334 42.9869 63.8605 42.522C64.0875 42.0571 64.0316 41.5021 63.7173 41.0923L55.0062 29.7244C54.7501 29.3912 54.3554 29.1953 53.9371 29.1953C53.5177 29.1953 53.1229 29.3912 52.8679 29.7244L44.1557 41.0923C43.8414 41.5021 43.7866 42.0571 44.0137 42.522C44.2407 42.9869 44.7104 43.2819 45.2249 43.2819H48.5353V57.0778C48.5353 58.5796 49.7443 59.7965 51.2362 59.7965H56.638C58.1288 59.7965 59.3389 58.5796 59.3389 57.0778V43.2819H62.6481Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.2254 43.2819H46.7162L52.8673 35.2553C53.1234 34.9209 53.5182 34.7261 53.9376 34.7261C54.3558 34.7261 54.7506 34.9209 55.0067 35.2553L61.1578 43.2819H62.6486C63.1642 43.2819 63.6328 42.9869 63.861 42.522C64.088 42.0571 64.0321 41.5021 63.7178 41.0923L55.0067 29.7244C54.7506 29.3912 54.3558 29.1953 53.9376 29.1953C53.5182 29.1953 53.1234 29.3912 52.8673 29.7244L44.1562 41.0923C43.8419 41.5021 43.786 42.0571 44.0142 42.522C44.2412 42.9869 44.7109 43.2819 45.2254 43.2819Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6481 43.2819C63.1637 43.2819 63.6334 42.9869 63.8605 42.522C64.0875 42.0571 64.0316 41.5021 63.7173 41.0923L55.0062 29.7244C54.7501 29.3912 54.3554 29.1953 53.9371 29.1953C53.5177 29.1953 53.1229 29.3912 52.8679 29.7244L44.1557 41.0923C43.8414 41.5021 43.7866 42.0571 44.0137 42.522C44.2407 42.9869 44.7104 43.2819 45.2249 43.2819H48.5353V57.0778C48.5353 58.5796 49.7443 59.7965 51.2362 59.7965H56.638C58.1288 59.7965 59.3389 58.5796 59.3389 57.0778V43.2819H62.6481Z"
      stroke="#394C66"
      strokeWidth="0.675"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.87 66.7796H51.0041C50.3868 66.7796 49.8857 66.2753 49.8857 65.6539V63.8279C49.8857 63.2054 50.3868 62.7021 51.0041 62.7021H56.87C57.4874 62.7021 57.9884 63.2054 57.9884 63.8279V65.6539C57.9884 66.2753 57.4874 66.7796 56.87 66.7796Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.053 64.5692H50.9681C50.3277 64.5692 49.808 64.3857 49.808 64.1597V63.4954C49.808 63.269 50.3277 63.0859 50.9681 63.0859H57.053C57.6934 63.0859 58.2131 63.269 58.2131 63.4954V64.1597C58.2131 64.3857 57.6934 64.5692 57.053 64.5692Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.87 66.7796H51.0041C50.3868 66.7796 49.8857 66.2753 49.8857 65.6539V63.8279C49.8857 63.2054 50.3868 62.7021 51.0041 62.7021H56.87C57.4874 62.7021 57.9884 63.2054 57.9884 63.8279V65.6539C57.9884 66.2753 57.4874 66.7796 56.87 66.7796Z"
      stroke="#394C66"
      strokeWidth="0.675"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.1945 73.5767H51.6785C51.0611 73.5767 50.5601 73.0723 50.5601 72.4509V70.6238C50.5601 70.0024 51.0611 69.498 51.6785 69.498H56.1945C56.8119 69.498 57.3129 70.0024 57.3129 70.6238V72.4509C57.3129 73.0723 56.8119 73.5767 56.1945 73.5767Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.1598 71.4937H51.8614C51.2738 71.4937 50.7969 71.2491 50.7969 70.9477V70.0616C50.7969 69.7602 51.2738 69.5156 51.8614 69.5156H56.1598C56.7474 69.5156 57.2243 69.7602 57.2243 70.0616V70.9477C57.2243 71.2491 56.7474 71.4937 56.1598 71.4937Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.1945 73.5767H51.6785C51.0611 73.5767 50.5601 73.0723 50.5601 72.4509V70.6238C50.5601 70.0024 51.0611 69.498 51.6785 69.498H56.1945C56.8119 69.498 57.3129 70.0024 57.3129 70.6238V72.4509C57.3129 73.0723 56.8119 73.5767 56.1945 73.5767Z"
      stroke="#394C66"
      strokeWidth="0.675"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2090_304513"
        x1="75.7113"
        y1="76.6632"
        x2="108.392"
        y2="8.11345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="#9AB5DB" stopOpacity="0.45" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2090_304513"
        x1="54"
        y1="99.7578"
        x2="54"
        y2="91.917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="#9AB5DB" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2090_304513"
        x1="30.1107"
        y1="15.8384"
        x2="36.6037"
        y2="4.59457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#75B8C8" />
        <stop offset="1" stopColor="#75B8C8" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2090_304513"
        x1="28.4875"
        y1="18.65"
        x2="34.9805"
        y2="7.40609"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#77CBB9" />
        <stop offset="1" stopColor="#77CBB9" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2090_304513"
        x1="30.1107"
        y1="15.8391"
        x2="34.9805"
        y2="7.40609"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#335580" />
        <stop offset="1" stopColor="#335580" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);
