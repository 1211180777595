import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5984 14.5071C13.6322 14.5071 12.8576 15.2817 12.8576 16.2479C12.8576 16.7096 13.041 17.1523 13.3674 17.4788C13.6939 17.8053 14.1367 17.9887 14.5984 17.9887C15.0601 17.9887 15.5028 17.8053 15.8293 17.4788C16.1558 17.1523 16.3392 16.7096 16.3392 16.2479C16.3392 15.2817 15.5558 14.5071 14.5984 14.5071ZM0.671875 0.580566V2.32138H2.41269L5.54615 8.92776L4.3624 11.0603C4.23184 11.304 4.1535 11.5912 4.1535 11.8958C4.1535 12.3575 4.33691 12.8003 4.66337 13.1268C4.98984 13.4532 5.43262 13.6367 5.89431 13.6367H16.3392V11.8958H6.25988C6.20217 11.8958 6.14682 11.8729 6.10601 11.8321C6.06521 11.7913 6.04228 11.736 6.04228 11.6782C6.04228 11.6347 6.05098 11.5999 6.06839 11.5738L6.85176 10.155H13.3363C13.9891 10.155 14.5636 9.78946 14.8595 9.25851L17.9755 3.62699C18.0365 3.48772 18.08 3.33975 18.08 3.19178C18.08 2.96094 17.9883 2.73955 17.8251 2.57631C17.6618 2.41308 17.4404 2.32138 17.2096 2.32138H4.33628L3.5181 0.580566H0.671875ZM5.89431 14.5071C4.92816 14.5071 4.1535 15.2817 4.1535 16.2479C4.1535 16.7096 4.33691 17.1523 4.66337 17.4788C4.98984 17.8053 5.43262 17.9887 5.89431 17.9887C6.356 17.9887 6.79878 17.8053 7.12525 17.4788C7.45172 17.1523 7.63512 16.7096 7.63512 16.2479C7.63512 15.2817 6.85176 14.5071 5.89431 14.5071Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
