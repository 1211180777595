import { IOrderProduct } from 'store/orders/types';

export interface IProductsState {
  products: IOrderProduct[];
  fetchingProducts: boolean;
}

export enum ProductsTypeKeys {
  FETCH_PRODUCTS = 'products/fetch',
  FETCH_PRODUCTS_SUCCESS = 'products/fetch-success',
  FETCH_PRODUCTS_ERROR = 'products/error',
}

interface IFetchAllProducts {
  type: ProductsTypeKeys.FETCH_PRODUCTS;
}

export interface IFetchAllProductsSuccess {
  type: ProductsTypeKeys.FETCH_PRODUCTS_SUCCESS;
  products: IOrderProduct[];
}

interface IFetchAllProductsError {
  type: ProductsTypeKeys.FETCH_PRODUCTS_ERROR;
}

export type ProductActions =
  | IFetchAllProducts
  | IFetchAllProductsSuccess
  | IFetchAllProductsError;
