import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import CloseIcon from 'react-icons/lib/md/cancel';

import {
  headerZIndex,
  white,
  noOverflowClassName,
  trout,
  focusShadow,
} from 'config/theme';

const ESC_KEY_CODE = 27;

interface IPortalProps {
  onClose?: () => void;
  showCloseButton?: boolean;
  closeOnEsc: boolean;
  padding?: string;
  width?: string;
  className?: string;
  id?: string;
  children?: React.ReactNode;
}
interface IPortalState {
  mounted: boolean;
}

export default class Portal extends React.Component<
  IPortalProps,
  IPortalState
> {
  public static defaultProps = {
    closeOnEsc: true,
    showCloseButton: true,
  };

  public el = document.createElement('div');
  public containerRef: HTMLElement | null = null;

  constructor(props) {
    super(props);
    this.containerRef = null;
    this.state = {
      mounted: false,
    };
  }

  public componentDidMount() {
    document.body.classList.add(noOverflowClassName);
    document.body.appendChild(this.el);
    document.addEventListener('keydown', this.handleEscKeyPress, false);
    this.setState({
      mounted: true,
    });

    if (this.containerRef) {
      this.containerRef.focus();
    }
  }

  public componentWillUnmount() {
    document.body.removeChild(this.el);
    document.body.classList.remove(noOverflowClassName);
    document.removeEventListener('keydown', this.handleEscKeyPress, false);
  }

  public render() {
    return createPortal(this.renderPortal(), this.el);
  }

  private renderPortal() {
    return (
      <Overlay onClick={this.handleOverlayClick}>
        <Wrapper className={this.props.className}>
          <Container
            tabIndex={1}
            ref={(node) => (this.containerRef = node)}
            padding={this.props.padding}
            width={this.props.width}
            className={this.props.className}
            id={this.props.id}
          >
            {this.props.showCloseButton && (
              <StyledCloseIcons
                className="portal-close-icon"
                onClick={this.handleCloseButtonClick}
              />
            )}
            {this.state.mounted ? this.props.children : null}
          </Container>
        </Wrapper>
      </Overlay>
    );
  }

  private handleOverlayClick = (e) => {
    if (
      !this.containerRef?.contains(e.target) &&
      typeof this.props.onClose === 'function'
    ) {
      this.props.onClose();
    }
  };

  private handleCloseButtonClick = () => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  private handleEscKeyPress = (e) => {
    if (
      this.props.closeOnEsc &&
      e.keyCode === ESC_KEY_CODE &&
      typeof this.props.onClose === 'function'
    ) {
      this.props.onClose();
    }
  };
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 20px 0;
  height: 100%;
  min-height: 500px;
  max-height: calc(100vh - 100px);
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${headerZIndex * 10};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: auto;
`;
Overlay.displayName = 'Overlay';

interface IContainerProps {
  padding?: string;
  width?: string;
}

const Container = styled.div<IContainerProps>`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.12);
  margin: auto 0;
  min-height: 200px;
  padding: ${({ padding }: IContainerProps) => padding || '20px 50px'};
  position: relative;
  min-width: ${({ width }: IContainerProps) => width || '540px'};
  width: ${({ width }: IContainerProps) => width || 'auto'};
  &:focus {
    outline: none;
  }
`;

const StyledCloseIcons = styled(CloseIcon)`
  background: ${white};
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  width: 34px;
  position: absolute;
  top: -12px;
  right: -12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  color: ${trout};
  &:focus {
    box-shadow: ${focusShadow};
    outline: none;
  }
`;
StyledCloseIcons.displayName = 'StyledCloseIcons';
