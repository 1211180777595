import { AxiosError } from 'axios';
import { IPagination } from 'store/common/types';

export enum BillingAndPaymentsTypeKeys {
  ERROR = 'billingAndPayments/ERROR',
  CLEAR = 'billingAndPayments/CLEAR',
  GET_INVOICE_DETAIL = 'billingAndPayments/GET_INVOICE_DETAIL',
  GET_INVOICE_TRANSACTION = 'billingAndPayments/GET_INVOICE_TRANSACTION',
  SET_PAYMENT_SESSION = 'billingAndPayments/SET_PAYMENT_SESSION',
  GET_LATEST_INVOICE = 'billingAndPayments/GET_LATEST_INVOICE',
}

export interface IBillingAndPaymentsState {
  invoiceDetail: IInvoiceDetail | null;
  invoiceTransactions: IInvoiceTransaction[];
  pagination: IPagination | null;
  paymentSession: IPaymentSession | null;
  error: string | null | AxiosError;
}

export interface IInvoiceDetail {
  invoiceId: number;
  invoiceDate: Date;
  invoiceAmount: number;
  taxAmount: number;
  totalAmount: number;
  invoiceDueDate: Date;
  paid: boolean;
  paymentDate: Date;
  cardLastFourDigits: string;
  productMasterId: number;
  chargeDate: string;
  propertyProductName: string;
  propertyAddressCounty: string;
  chargeAmount: number;
  formattedPropertyAddress: string;
}

export interface IInvoiceTransaction {
  propertyAPN: string;
  propertyProductName: string;
  propertyAddressState: string;
  propertyAddressStateCode: string;
  propertyAddressCounty: string;
  propertyAddressStreet1: string;
  propertyAddressStreet2: string;
  propertyAddressCity: string;
  propertyAddressZip: string;
  propertyAddressCountry: string;
  chargeAmount: number;
  chargeDate: Date;
  recordedDocumentId: string;
  formattedPropertyAddress: string;
}

export interface IPaymentSession {
  paymentToken: string;
  paymentWindow: any;
}

export interface IBillingAndPaymentsClearOption {
  clearPaymentSession: boolean;
}

export interface IPaymentTokenRequest {
  orderNumbers: number[];
  amount: number;
}

export type BillingAndPaymentsAction =
  | IBillingAndPaymentsErrorAction
  | IBillingAndPaymentsClearAction
  | IBillingAndPaymentsGetInvoiceDetailAction
  | IBillingAndPaymentsGetInvoiceTransactionAction
  | IBillingAndPaymentsSetPaymentSessionAction
  | IBillingAndPaymentsGetLatestInvoiceAction;

export interface IBillingAndPaymentsErrorAction {
  type: BillingAndPaymentsTypeKeys.ERROR;
  error: string | null | AxiosError;
}

export interface IBillingAndPaymentsClearAction {
  type: BillingAndPaymentsTypeKeys.CLEAR;
  options?: IBillingAndPaymentsClearOption | null;
}

export interface IBillingAndPaymentsGetInvoiceDetailAction {
  type: BillingAndPaymentsTypeKeys.GET_INVOICE_DETAIL;
  data: IInvoiceDetail;
}

export interface IBillingAndPaymentsGetLatestInvoiceAction {
  type: BillingAndPaymentsTypeKeys.GET_LATEST_INVOICE;
  data: IInvoiceDetail;
}

export interface IBillingAndPaymentsGetInvoiceTransactionAction {
  type: BillingAndPaymentsTypeKeys.GET_INVOICE_TRANSACTION;
  invoiceTransactions: IInvoiceTransaction[];
  pagination: IPagination;
}

export enum BillingAndPaymentsLoadingKeys {
  billingAndPaymentsInvoiceDetailLoading = 'billingAndPaymentsInvoiceDetailLoading',
  billingAndPaymentsInvoiceTransactionsLoading = 'billingAndPaymentsInvoiceTransactionsLoading',
  paymentInProcessLoading = 'paymentInProcessLoading',
  invoiceDownloadLoading = 'invoiceDownloadLoading',
}

export interface IBillingAndPaymentsSetPaymentSessionAction {
  type: BillingAndPaymentsTypeKeys.SET_PAYMENT_SESSION;
  data: IPaymentSession;
}
