import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { neutralGray7 } from 'config/colors';

export default (props: ISVGProps) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3262 10.3691H5.59242L13.2787 2.68289L11.3262 0.744141L0.326172 11.7441L11.3262 22.7441L13.2649 20.8054L5.59242 13.1191H22.3262V10.3691Z"
      fill={props.fill || neutralGray7}
    />
  </svg>
);
