/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_FileBusinessPartyEmployee } from '../models/Workspace_Common_Models_FileBusinessPartyEmployee';
import type { Workspace_Common_Models_FileDocument } from '../models/Workspace_Common_Models_FileDocument';
import type { Workspace_Common_Models_Product } from '../models/Workspace_Common_Models_Product';
import type { Workspace_Common_Responses_FileDetail } from '../models/Workspace_Common_Responses_FileDetail';
import type { Workspace_Common_Responses_FilePartiesList } from '../models/Workspace_Common_Responses_FilePartiesList';
import type { Workspace_Common_Responses_FileWireInstruction } from '../models/Workspace_Common_Responses_FileWireInstruction';
import type { Workspace_Common_Responses_ImagesList } from '../models/Workspace_Common_Responses_ImagesList';
import type { Workspace_Common_Responses_UpdatedWireInstruction } from '../models/Workspace_Common_Responses_UpdatedWireInstruction';
import type { Workspace_Common_Responses_WireInstructionAssociationList } from '../models/Workspace_Common_Responses_WireInstructionAssociationList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FilesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Fetches a file by ID. (Auth)
   * @returns Workspace_Common_Responses_FileDetail File fetched successfully
   * @throws ApiError
   */
  public get({
    fileId,
  }: {
    fileId: number,
  }): CancelablePromise<Workspace_Common_Responses_FileDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{fileId}',
      path: {
        'fileId': fileId,
      },
      errors: {
        403: `Current user doesn't have access to the file`,
        404: `File not found`,
      },
    });
  }

  /**
   * For a given fileId, retrieve the related business parties. (Auth)
   * @returns Workspace_Common_Responses_FilePartiesList Business parties response
   * @throws ApiError
   */
  public getFilePartiesAsync({
    fileId,
  }: {
    /**
     * FAST File ID
     */
    fileId: number,
  }): CancelablePromise<Workspace_Common_Responses_FilePartiesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{fileId}/parties',
      path: {
        'fileId': fileId,
      },
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Get a list of wire instructions related to a file (Auth)
   * @returns Workspace_Common_Responses_WireInstructionAssociationList Wire instructions response
   * @throws ApiError
   */
  public getFileWireInstructions({
    fileId,
  }: {
    /**
     * The Id of the File.
     */
    fileId: number,
  }): CancelablePromise<Workspace_Common_Responses_WireInstructionAssociationList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{fileId}/wire-instructions',
      path: {
        'fileId': fileId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User doesn't have access to list Wire Instructions.`,
        404: `Invalid UserType.`,
      },
    });
  }

  /**
   * Submits a list of wire instructions, disbursement combination to save to FAST.
   * Then updates the DisbursementId column on the WireInstructionFiles table with
   * wire details created and changes the Status column on the WireInstructionFiles
   * table to DisbursementMatched. (Auth)
   * @returns Workspace_Common_Responses_UpdatedWireInstruction All Successful
   * @throws ApiError
   */
  public updateWireInstructionsListAsync({
    fileId,
    requestBody,
  }: {
    /**
     * the ID of the file
     */
    fileId: number,
    /**
     * All the wire accounts to submit to FAST
     */
    requestBody?: Array<Workspace_Common_Responses_FileWireInstruction> | null,
  }): CancelablePromise<Array<Workspace_Common_Responses_UpdatedWireInstruction>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/files/{fileId}/wire-instructions',
      path: {
        'fileId': fileId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid Authorization header`,
        404: `File does not exist or Invalid UserType.`,
        409: `All Failed`,
        423: `All failed due to password protection`,
      },
    });
  }

  /**
   * For a given fileId, retrieve the file's images in a paginated way (Auth)
   * @returns Workspace_Common_Responses_ImagesList File and images found
   * @throws ApiError
   */
  public getImages({
    fileId,
    page = 1,
  }: {
    /**
     * FAST File ID
     */
    fileId: number,
    page?: number,
  }): CancelablePromise<Workspace_Common_Responses_ImagesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{fileId}/images',
      path: {
        'fileId': fileId,
      },
      query: {
        'page': page,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user doesn't have access to the file`,
        404: `File not found`,
      },
    });
  }

  /**
   * For a given File ID and Image ID, retrieve the image's contents (Auth)
   * @returns any Successfully retrieved the image's contents
   * @throws ApiError
   */
  public getImageContentsAsync({
    fileId,
    imageId,
    download = true,
    fileName = '',
  }: {
    /**
     * FAST File Id
     */
    fileId: number,
    /**
     * FAST Image Id
     */
    imageId: number,
    /**
     * Set Content-Disposition header to trigger download
     */
    download?: boolean,
    /**
     * file name to fetch from Eclipse Temporary Location. Pass complete filename with extension
     */
    fileName?: string | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{fileId}/images/{imageId}/contents',
      path: {
        'fileId': fileId,
        'imageId': imageId,
      },
      query: {
        'download': download,
        'fileName': fileName,
      },
      errors: {
        404: `File or image not found`,
      },
    });
  }

  /**
   * This endpoint will upload wire instruction document to FAST.
   * Document type and status is hardcoded.
   * Uploaded document is passed in as Request.Form.Files (Auth)
   * @returns Workspace_Common_Models_FileDocument Document successfully uploaded.
   * @throws ApiError
   */
  public uploadDocumentAsync({
    fileId,
    externalDocumentId,
  }: {
    /**
     * fileId of file for which document will be uploaded
     */
    fileId: number,
    /**
     * externalDocumentId will be used to move the documents from temporary location to permanent location
     */
    externalDocumentId?: number,
  }): CancelablePromise<Workspace_Common_Models_FileDocument> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/files/{fileId}/external-documents',
      path: {
        'fileId': fileId,
      },
      query: {
        'externalDocumentId': externalDocumentId,
      },
      errors: {
        422: `Validation Failed.`,
      },
    });
  }

  /**
   * For a given fileId and imageId, Delete the image (Auth)
   * @returns boolean Image found
   * @throws ApiError
   */
  public deleteImageAsync({
    fileId,
    imageId,
  }: {
    /**
     * FAST File ID
     */
    fileId: number,
    /**
     * FAST Image ID
     */
    imageId: number,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/files/{fileId}/images/{imageId}',
      path: {
        'fileId': fileId,
        'imageId': imageId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Image or file not found`,
      },
    });
  }

  /**
   * For given OrderId this method will return OrderDetails. (Auth)
   * @returns void
   * @throws ApiError
   */
  public getOrderDetails({
    orderId,
  }: {
    /**
     * long value, for which method will return OrderDetails.
     */
    orderId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{orderId}/order-details',
      path: {
        'orderId': orderId,
      },
      errors: {
        301: `This endpoint it moved to /orders/{orderId}`,
      },
    });
  }

  /**
   * Get the products for a given order id (Auth)
   * @returns Workspace_Common_Models_Product Successfully retrieved the products
   * @throws ApiError
   */
  public getOrderProducts({
    orderId,
  }: {
    /**
     * the ID of the order
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_Product>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{orderId}/order-details/products',
      path: {
        'orderId': orderId,
      },
      errors: {
        404: `OrderId not found`,
      },
    });
  }

  /**
   * For a given fileId, retrieve the related file business party employees detail. (Auth)
   * @returns Workspace_Common_Models_FileBusinessPartyEmployee File Business party employee list
   * @throws ApiError
   */
  public getOrderBusinessPartyEmployees({
    orderId,
  }: {
    /**
     * FAST Order ID
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_FileBusinessPartyEmployee>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{orderId}/business-party-employees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `File not found`,
      },
    });
  }

  /**
   * Gets all the role names for a specific user (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public getPartiesRolesAsync({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{orderId}/party-roles',
      path: {
        'orderId': orderId,
      },
    });
  }

}
