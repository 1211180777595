/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FA_NCS_ClarityFirst_DocumentInstance_Models_CreateDocumentResultResponse } from '../models/FA_NCS_ClarityFirst_DocumentInstance_Models_CreateDocumentResultResponse';
import type { Workspace_API_ViewModels_TemplateInstance_CreateTemplateInstanceViewModel } from '../models/Workspace_API_ViewModels_TemplateInstance_CreateTemplateInstanceViewModel';
import type { Workspace_API_ViewModels_TemplateInstance_UpdateDocumentViewModel } from '../models/Workspace_API_ViewModels_TemplateInstance_UpdateDocumentViewModel';
import type { Workspace_API_ViewModels_TemplateInstance_UpdateInfotabViewModel } from '../models/Workspace_API_ViewModels_TemplateInstance_UpdateInfotabViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TemplateInstanceService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Creates one or more documents on DMS and our DB.
 * If Creation fails on DMS side, no entities are created on our DB.
   * @returns FA_NCS_ClarityFirst_DocumentInstance_Models_CreateDocumentResultResponse Success
   * @throws ApiError
   */
  public createDocumentAsync({
orderId,
requestBody,
}: {
/**
 * OrderId used to create the Document. Used also for checking user access.
 */
orderId: number,
/**
 * Payload used to create a Document on DMS.
 */
requestBody?: Workspace_API_ViewModels_TemplateInstance_CreateTemplateInstanceViewModel,
}): CancelablePromise<FA_NCS_ClarityFirst_DocumentInstance_Models_CreateDocumentResultResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/templateInstances',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates a Document on DMS and Db
   * @returns void 
   * @throws ApiError
   */
  public updateDocumentAsync({
orderId,
documentId,
requestBody,
}: {
/**
 * The order id
 */
orderId: number,
/**
 * Document id of the document that is being updated
 */
documentId: number,
/**
 * Payload used to update a document on file doc
 */
requestBody?: Workspace_API_ViewModels_TemplateInstance_UpdateDocumentViewModel,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/templateInstances/{documentId}',
      path: {
        'orderId': orderId,
        'documentId': documentId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Set a Document as Removed on DMS and deletes Document from the Database.
   * @returns void 
   * @throws ApiError
   */
  public deleteDocumentAsync({
orderId,
documentId,
}: {
/**
 * the order id
 */
orderId: number,
/**
 * the document id
 */
documentId: number,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/templateInstances/{documentId}',
      path: {
        'orderId': orderId,
        'documentId': documentId,
      },
      errors: {
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Get document infotab data
   * @returns any Success
   * @throws ApiError
   */
  public getInfoTabDataAsync({
orderId,
documentId,
docType,
}: {
/**
 * The order Id
 */
orderId: number,
/**
 * id of the document
 */
documentId: number,
/**
 * type of the document
 */
docType?: number,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/templateInstances/{documentId}/info-tab',
      path: {
        'orderId': orderId,
        'documentId': documentId,
      },
      query: {
        'docType': docType,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Update document instance infotab data
   * @returns any Success
   * @throws ApiError
   */
  public updateInfotabAsync({
orderId,
requestBody,
}: {
/**
 * The order Id
 */
orderId: number,
/**
 * Document infotab details
 */
requestBody?: Workspace_API_ViewModels_TemplateInstance_UpdateInfotabViewModel,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/templateInstances/info-tab',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Download the contents of a document
   * @returns string Success
   * @throws ApiError
   */
  public downloadDocumentAsync({
orderId,
documentId,
}: {
/**
 * The order id
 */
orderId: number,
/**
 * id of the document
 */
documentId: string,
}): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/templateInstances/{documentId}/download',
      path: {
        'orderId': orderId,
        'documentId': documentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}
