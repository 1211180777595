import { AxiosError } from 'axios';

export type GroupsAction =
  | IGroupsFetchAllRequestAction
  | IGroupsFetchAllSuccessAction
  | IGroupsFetchAllErrorAction
  | IGroupRequestStartAction
  | IGroupRequestSuccessAction
  | IGroupRequestErrorAction
  | IGroupFetchSuccessAction;

export interface IListGroup {
  id: number;
  name: string;
  isVirtual: boolean;
  usersCount: number;
}

export interface IGroup {
  id: number;
  name: string;
  isVirtual: boolean;
  users: IGroupUser[];
}

export interface IGroupUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  primaryOfficeName: string;
  primaryRegionName: string;
  type: string;
}

export interface IGroupHash {
  [id: number]: IListGroup;
}

export interface IGroupsState {
  resultsById: IGroupHash;
  loading: boolean;
  error: null | AxiosError;
}

export enum GroupsTypeKeys {
  FETCH_ALL_REQUEST = 'groups/FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS = 'groups/FETCH_ALL_SUCCESS',
  FETCH_ALL_ERROR = 'groups/FETCH_ALL_ERROR',
  FETCH_SUCCESS = 'groups/FETCH_SUCCESS',
  REQUEST_START = 'groups/REQUEST_START',
  REQUEST_SUCCESS = 'groups/REQUEST_SUCCESS',
  REQUEST_ERROR = 'groups/REQUEST_ERROR',
}

export interface IGroupsFetchAllRequestAction {
  type: GroupsTypeKeys.FETCH_ALL_REQUEST;
}

export interface IGroupsFetchAllSuccessAction {
  type: GroupsTypeKeys.FETCH_ALL_SUCCESS;
  resultsById: IGroupHash;
}

export interface IGroupsFetchAllErrorAction {
  type: GroupsTypeKeys.FETCH_ALL_ERROR;
  error: AxiosError;
}

export interface IGroupFetchSuccessAction {
  type: GroupsTypeKeys.FETCH_SUCCESS;
  result: IGroupHash;
}

export interface IGroupRequestStartAction {
  type: GroupsTypeKeys.REQUEST_START;
}

export interface IGroupRequestSuccessAction {
  type: GroupsTypeKeys.REQUEST_SUCCESS;
}

export interface IGroupRequestErrorAction {
  type: GroupsTypeKeys.REQUEST_ERROR;
  error: AxiosError;
}

export interface IGroupCreateFormParams {
  name: string;
  userIds: number[];
}

export interface IGroupUpdateFormParams {
  name: string;
}

export interface IGroupInitialFormValues {
  name: string;
}
