import { useState } from 'react';
import toastImport from './ToastUtilities';
import { IToastOnceProps } from './types';

const ToastOnce = ({
  message,
  intent,
  options,
  toast = toastImport,
}: IToastOnceProps) => {
  const [hasDisplayed, setHasDisplayed] = useState(false);

  if (hasDisplayed) {
    return null;
  }

  toast(message, intent, options);
  setHasDisplayed(true);

  return null;
};
ToastOnce.displayName = 'ToastOnce';

export default ToastOnce;
