import React from 'react';
export default () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0V2H14V0H0ZM0 10H4V16H10V10H14L7 3L0 10Z" fill="#686B6F" />
  </svg>
);
