export enum SettingsTypeKeys {
  SET_NOTIFICATION_PREFERENCES = 'settings/SET_NOTIFICATION_PREFERENCES',
  SET_NOTIFICATION_PREFERENCE = 'settings/SET_NOTIFICATION_PREFERENCE',
}

export type ISettingsAction =
  | ISetNotificationPreferencesAction
  | ISetNotificationPreferenceAction;

export interface ISettingsState {
  notificationsPreferences: INotificationPreference[];
}

export interface INotificationPreference {
  type: NotificationUpdateType;
  email: boolean;
  inApp: boolean;
  push: boolean;
  frequency: number | null;
}

export interface ISetNotificationPreferencesAction {
  type: SettingsTypeKeys.SET_NOTIFICATION_PREFERENCES;
  notificationsPreferences: INotificationPreference[];
}

export interface ISetNotificationPreferenceAction {
  type: SettingsTypeKeys.SET_NOTIFICATION_PREFERENCE;
  notificationsPreference: INotificationPreference;
}

export enum NotificationUpdateType {
  MILESTONE_DIGEST = 'MilestoneDigest',
  MILESTONE_UPDATES = 'MilestoneUpdates',
  ORDER_UPDATES = 'OrderUpdates',
  SHARED_DOCUMENTS = 'SharedDocuments',
  WIRE_REQUESTS = 'WireRequests',
  CUSTOMER_DOCUMENT_UPLOADS = 'CustomerDocumentUploads',
  INVOICES = 'InvoiceUpdates',
  SETTLEMENT_STATEMENT_COMMENTS = 'SettlementStatementComments',
}

export enum NotificationFrequency {
  realtime = '0',
  hourly = '1',
  daily = '2',
}

export enum Frequencies {
  hourly = 'Send hourly',
  daily = 'Send daily',
  realtime = 'Send real time',
}
