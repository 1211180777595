import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.83429H3.41L8.67778 0L10.12 9.64132L14.0556 5.24032L18.1256 8.83429H22V10.9864H17.1111L14.2633 8.4792L8.45778 15L7.26 7.01578L4.88889 10.9864H0V8.83429Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
