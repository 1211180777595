/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Common_Models_Enums_FileSearchProperty } from '../models/Common_Models_Enums_FileSearchProperty';
import type { Common_Models_Enums_FileSortProperty } from '../models/Common_Models_Enums_FileSortProperty';
import type { Microsoft_AspNetCore_JsonPatch_Operations_Operation } from '../models/Microsoft_AspNetCore_JsonPatch_Operations_Operation';
import type { Microsoft_AspNetCore_Mvc_OkObjectResult } from '../models/Microsoft_AspNetCore_Mvc_OkObjectResult';
import type { Workspace_API_Requests_AddSiteOrderRequest } from '../models/Workspace_API_Requests_AddSiteOrderRequest';
import type { Workspace_API_ViewModels_AddServiceTypeViewModel } from '../models/Workspace_API_ViewModels_AddServiceTypeViewModel';
import type { Workspace_API_ViewModels_BusinessParties_FilePartyViewModel } from '../models/Workspace_API_ViewModels_BusinessParties_FilePartyViewModel';
import type { Workspace_API_ViewModels_FileTransactionType_FileTransactionTypeViewModel } from '../models/Workspace_API_ViewModels_FileTransactionType_FileTransactionTypeViewModel';
import type { Workspace_API_ViewModels_Order_OrderFlagsViewModel } from '../models/Workspace_API_ViewModels_Order_OrderFlagsViewModel';
import type { Workspace_API_ViewModels_Order_OrderStatesViewModel } from '../models/Workspace_API_ViewModels_Order_OrderStatesViewModel';
import type { Workspace_API_ViewModels_Services_ServiceTypesViewModel } from '../models/Workspace_API_ViewModels_Services_ServiceTypesViewModel';
import type { Workspace_API_ViewModels_UpdateAddressViewModel } from '../models/Workspace_API_ViewModels_UpdateAddressViewModel';
import type { Workspace_API_ViewModels_UpdateCoInsuranceFlagViewModel } from '../models/Workspace_API_ViewModels_UpdateCoInsuranceFlagViewModel';
import type { Workspace_API_ViewModels_UpdateOrderStatusViewModel } from '../models/Workspace_API_ViewModels_UpdateOrderStatusViewModel';
import type { Workspace_API_ViewModels_UpdateTransactionTypeViewModel } from '../models/Workspace_API_ViewModels_UpdateTransactionTypeViewModel';
import type { Workspace_Common_Models_Dto_Address_AddressDto } from '../models/Workspace_Common_Models_Dto_Address_AddressDto';
import type { Workspace_Common_Models_FileDocumentSortPropertyType } from '../models/Workspace_Common_Models_FileDocumentSortPropertyType';
import type { Workspace_Common_Models_FileLoanAmount } from '../models/Workspace_Common_Models_FileLoanAmount';
import type { Workspace_Common_Models_FileOwnerLiability } from '../models/Workspace_Common_Models_FileOwnerLiability';
import type { Workspace_Common_Models_FileProductionOffice } from '../models/Workspace_Common_Models_FileProductionOffice';
import type { Workspace_Common_Models_FileProductionOfficeType } from '../models/Workspace_Common_Models_FileProductionOfficeType';
import type { Workspace_Common_Models_FileType } from '../models/Workspace_Common_Models_FileType';
import type { Workspace_Common_Models_IngestFileMode } from '../models/Workspace_Common_Models_IngestFileMode';
import type { Workspace_Common_Models_OrderStatusType } from '../models/Workspace_Common_Models_OrderStatusType';
import type { Workspace_Common_Models_ProductViewModel } from '../models/Workspace_Common_Models_ProductViewModel';
import type { Workspace_Common_Models_SalesPriceViewModel } from '../models/Workspace_Common_Models_SalesPriceViewModel';
import type { Workspace_Common_Models_Services_OwningOfficeUpdateResponse } from '../models/Workspace_Common_Models_Services_OwningOfficeUpdateResponse';
import type { Workspace_Common_Models_Services_ServiceToUpdate } from '../models/Workspace_Common_Models_Services_ServiceToUpdate';
import type { Workspace_Common_Models_SiteFileSortType } from '../models/Workspace_Common_Models_SiteFileSortType';
import type { Workspace_Common_Models_SortDirectionType } from '../models/Workspace_Common_Models_SortDirectionType';
import type { Workspace_Common_Requests_UpdateFileRequest } from '../models/Workspace_Common_Requests_UpdateFileRequest';
import type { Workspace_Common_Requests_UpdateSalesPricesRequest } from '../models/Workspace_Common_Requests_UpdateSalesPricesRequest';
import type { Workspace_Common_Responses_DocumentList } from '../models/Workspace_Common_Responses_DocumentList';
import type { Workspace_Common_Responses_FileDetail } from '../models/Workspace_Common_Responses_FileDetail';
import type { Workspace_Common_Responses_FilesGeodataList } from '../models/Workspace_Common_Responses_FilesGeodataList';
import type { Workspace_Common_Responses_FilesList } from '../models/Workspace_Common_Responses_FilesList';
import type { Workspace_Common_Responses_FileSummary } from '../models/Workspace_Common_Responses_FileSummary';
import type { Workspace_Common_Responses_UpdateCloseDatesResponse } from '../models/Workspace_Common_Responses_UpdateCloseDatesResponse';
import type { Workspace_Common_Responses_UpdateSalesPricesResponse } from '../models/Workspace_Common_Responses_UpdateSalesPricesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrdersService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Document list using order number and fast region id (Auth)
   * @returns Workspace_Common_Responses_DocumentList Success
   * @throws ApiError
   */
  public getDocumentsByOrderNumberAndRegionAsync({
    orderNumber,
    regionFastId,
    paginateResponse = false,
    page,
    sortProperty,
    sortDirection,
  }: {
    /**
     * order number
     */
    orderNumber: string | null,
    /**
     * region fast id
     */
    regionFastId: number,
    /**
     * should paginate the response
     */
    paginateResponse?: boolean,
    /**
     * page number
     */
    page?: number,
    /**
     * sort field
     */
    sortProperty?: Workspace_Common_Models_FileDocumentSortPropertyType,
    /**
     * sort direct (asc,desc)
     */
    sortDirection?: Workspace_Common_Models_SortDirectionType,
  }): CancelablePromise<Workspace_Common_Responses_DocumentList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderNumber}/{regionFastId}/documents',
      path: {
        'orderNumber': orderNumber,
        'regionFastId': regionFastId,
      },
      query: {
        'paginateResponse': paginateResponse,
        'page': page,
        'sortProperty': sortProperty,
        'sortDirection': sortDirection,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Get order summary (Auth)
   * @returns Workspace_Common_Responses_FileSummary Success
   * @throws ApiError
   */
  public getSummary({
    orderId,
  }: {
    /**
     * order id to lookup
     */
    orderId: number,
  }): CancelablePromise<Workspace_Common_Responses_FileSummary> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * For given OrderId this method will return OrderDetails. (Auth)
   * @returns Workspace_Common_Responses_FileDetail Successfully retrieved the order details
   * @throws ApiError
   */
  public getOrderDetails({
    orderId,
    includeOrderFavorites = false,
  }: {
    /**
     * long value, for which method will return OrderDetails.
     */
    orderId: number,
    /**
     * The optional parameter to populate isfavorite flag for the order
     */
    includeOrderFavorites?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_FileDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}',
      path: {
        'orderId': orderId,
      },
      query: {
        'includeOrderFavorites': includeOrderFavorites,
      },
      errors: {
        403: `External Users do not have permission`,
        404: `OrderId not found`,
      },
    });
  }

  /**
   * Patches the File in CF and the SourceParties resource in Eclipse (Auth)
   * @returns Workspace_Common_Requests_UpdateFileRequest Successfully patched Eclipse and Clarity First.
   * @throws ApiError
   */
  public patchFileAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    /**
     * The Json Patch request for this endpoint can use Add, Replace, or Remove operations
     */
    requestBody: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation>,
  }): CancelablePromise<Workspace_Common_Requests_UpdateFileRequest> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Unable to update the file in CF.`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
        409: `A finalized invoice exists on this file so the property cannot be changed`,
      },
    });
  }

  /**
   * For a given fileId and office type, retrieves the production offices. (Auth)
   * @returns Workspace_Common_Models_FileProductionOffice Production Offices for the type and orderId
   * @throws ApiError
   */
  public getProductionOffices({
    orderId,
    type,
  }: {
    /**
     * FAST Order ID
     */
    orderId: number,
    /**
     * Production Office Type: This will be either title or escrow
     */
    type: Workspace_Common_Models_FileProductionOfficeType,
  }): CancelablePromise<Array<Workspace_Common_Models_FileProductionOffice>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/production-offices/{type}',
      path: {
        'orderId': orderId,
        'type': type,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `File not found`,
      },
    });
  }

  /**
   * Return orders for given user:
   * * Internal user, retrieves orders where user has an EmployeeRole on the Order,
   * * External user, retrieves orders where user organization is part of (Auth)
   * @returns Workspace_Common_Responses_FilesList Success
   * @throws ApiError
   */
  public getMyOrders({
    page = 1,
    searchTerm,
    searchAttributes,
    statuses,
    sortProperty,
    sortDirection,
    fileTypes,
    includeOrderFavorites = false,
  }: {
    /**
     * a page number
     */
    page?: number,
    /**
     * the term for which to search. case-insensitive
     */
    searchTerm?: string | null,
    /**
     * the order attributes to search. case-insensitive
     */
    searchAttributes?: Array<Common_Models_Enums_FileSearchProperty> | null,
    /**
     * the order statuses for which to return orders. case-insensitive
     */
    statuses?: Array<Workspace_Common_Models_OrderStatusType> | null,
    /**
     * The property on which to sort.
     */
    sortProperty?: Common_Models_Enums_FileSortProperty,
    /**
     * The direction in which to sort.
     */
    sortDirection?: Workspace_Common_Models_SortDirectionType,
    /**
     * The file types to be filtered on
     */
    fileTypes?: Array<Workspace_Common_Models_FileType> | null,
    /**
     * The optional parameter to populate isfavorite flag from order
     */
    includeOrderFavorites?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_FilesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders',
      query: {
        'page': page,
        'searchTerm': searchTerm,
        'searchAttributes': searchAttributes,
        'statuses': statuses,
        'sortProperty': sortProperty,
        'sortDirection': sortDirection,
        'fileTypes': fileTypes,
        'includeOrderFavorites': includeOrderFavorites,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Returns a list of site files for a multisite project file: (Auth)
   * @returns Workspace_Common_Responses_FilesList Success
   * @throws ApiError
   */
  public getSiteOrders({
    orderId,
    page = 1,
    pageSize = 10,
    searchTerm,
    searchAttributes,
    sortProperty,
    sortDirection,
    filters,
    includePayoffLoans = false,
    includeLoansInfo = false,
  }: {
    /**
     * The order id.
     */
    orderId: number,
    /**
     * The page number.
     */
    page?: number,
    /**
     * The number of site orders to retrieve per page. Defaults to 10.
     */
    pageSize?: number,
    /**
     * The term for which to search. case-insensitive.
     */
    searchTerm?: string | null,
    /**
     * The order attributes to search. case-insensitive.
     */
    searchAttributes?: Array<Common_Models_Enums_FileSearchProperty> | null,
    /**
     * The property on which to sort.
     */
    sortProperty?: Workspace_Common_Models_SiteFileSortType,
    /**
     * The direction in which to sort.
     */
    sortDirection?: Workspace_Common_Models_SortDirectionType,
    /**
     * The filters to filter the results by. If omitted, all matching orders are returned
     * regardless of the status/state they are in. Specifying "other" as a value in the state filters will return
     * files with a blank or null state. This is for countries outside the USA that don't have states/provinces.
     */
    filters?: Record<string, Array<string>> | null,
    /**
     * The flag to indicate if Payoff Loans will be loaded for site orders (backward compatibility)
     */
    includePayoffLoans?: boolean,
    /**
     * The flag to indicate if Loans Info (Lender and PayOff) will be loaded for site orders
     */
    includeLoansInfo?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_FilesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/orders',
      path: {
        'orderId': orderId,
      },
      query: {
        'page': page,
        'pageSize': pageSize,
        'searchTerm': searchTerm,
        'searchAttributes': searchAttributes,
        'sortProperty': sortProperty,
        'sortDirection': sortDirection,
        'filters': filters,
        'includePayoffLoans': includePayoffLoans,
        'includeLoansInfo': includeLoansInfo,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Add a site order to a project file (Auth)
   * @returns any Site added successfully
   * @throws ApiError
   */
  public addOrderToProjectFileAsync({
    orderId,
    requestBody,
  }: {
    /**
     * A project file Id
     */
    orderId: number,
    /**
     * AddSiteOrderRequest with site order information
     */
    requestBody: Workspace_API_Requests_AddSiteOrderRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/orders',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user has no access to either order`,
        404: `No Found - Order (project file) not found or site order not found`,
        409: `Conflict - Site exists in another parent order`,
        422: `Unprocessable - Site is not a single order, order is not a project`,
      },
    });
  }

  /**
   * Get the site information for the sites in the given list (Auth)
   * @returns Workspace_Common_Responses_FilesList Success
   * @throws ApiError
   */
  public getSites({
    orderId,
    siteIds,
  }: {
    orderId: number,
    siteIds: Array<number>,
  }): CancelablePromise<Workspace_Common_Responses_FilesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/sites',
      path: {
        'orderId': orderId,
      },
      query: {
        'siteIds': siteIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Returns a list of geodata corresponding to the site files associated with a multisite project file. (Auth)
   * @returns Workspace_Common_Responses_FilesGeodataList Success
   * @throws ApiError
   */
  public getSitesGeodataAsync({
    orderId,
    statuses,
  }: {
    /**
     * The order id.
     */
    orderId: number,
    /**
     * The statuses to filter the results by. If omitted, all matching orders are returned
     * regardless of the status they are in.
     */
    statuses?: Array<Workspace_Common_Models_OrderStatusType> | null,
  }): CancelablePromise<Workspace_Common_Responses_FilesGeodataList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/geodata',
      path: {
        'orderId': orderId,
      },
      query: {
        'statuses': statuses,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Returns a list of files that the user accessed recently.
   * if status is passed orders will be filtered based on status. (Auth)
   * @returns Workspace_Common_Responses_FilesList FilesList object
   * @throws ApiError
   */
  public getRecentOrders({
    status,
    page = 1,
    includeOrderFavorites = false,
  }: {
    /**
     * multiple file-status, comma separated. Valid status are: Open, Closed, Cancelled, Pending, OpenInError
     */
    status?: string | null,
    /**
     * a page number
     */
    page?: number,
    /**
     * The optional parameter to populate isfavorite flag from order
     */
    includeOrderFavorites?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_FilesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/_recent',
      query: {
        'status': status,
        'page': page,
        'includeOrderFavorites': includeOrderFavorites,
      },
      errors: {
        400: `Invalid page number`,
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Returns a list of files that the user accessed recently.
   * if status is passed orders will be filtered based on status. (Auth)
   * @returns Workspace_Common_Responses_FilesList FilesList object
   * @throws ApiError
   */
  public getRecentOrders1({
    status,
    page = 1,
    includeOrderFavorites = false,
  }: {
    /**
     * multiple file-status, comma separated. Valid status are: Open, Closed, Cancelled, Pending, OpenInError
     */
    status?: string | null,
    /**
     * a page number
     */
    page?: number,
    /**
     * The optional parameter to populate isfavorite flag from order
     */
    includeOrderFavorites?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_FilesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/recent',
      query: {
        'status': status,
        'page': page,
        'includeOrderFavorites': includeOrderFavorites,
      },
      errors: {
        400: `Invalid page number`,
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Add recently accessed File tracking info into database. (Auth)
   * @returns void
   * @throws ApiError
   */
  public trackRecentOrder({
    orderId,
  }: {
    /**
     * a order Id
     */
    orderId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/track',
      path: {
        'orderId': orderId,
      },
      errors: {
        404: `OrderId does not exist`,
      },
    });
  }

  /**
   * Get Organizations associated with an Order (Auth)
   * @returns Workspace_API_ViewModels_BusinessParties_FilePartyViewModel Successfully retrieved organizations.
   * @throws ApiError
   */
  public getOrganizations({
    orderId,
    includeNameSeparation = false,
  }: {
    /**
     * a order Id
     */
    orderId: number,
    /**
     * Specifies whether Name and Name2 properties should be returned or not. Defaults to false.
     */
    includeNameSeparation?: boolean,
  }): CancelablePromise<Array<Workspace_API_ViewModels_BusinessParties_FilePartyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/organizations',
      path: {
        'orderId': orderId,
      },
      query: {
        'includeNameSeparation': includeNameSeparation,
      },
      errors: {
        403: `Can not access order`,
        404: `OrderId does not exist`,
      },
    });
  }

  /**
   * Remove a site order from a project file (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeOrderFromProjectFileAsync({
    projectFileId,
    orderId,
  }: {
    /**
     * A project file Id
     */
    projectFileId: number,
    /**
     * site order Id
     */
    orderId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{projectFileId}/orders/{orderId}',
      path: {
        'projectFileId': projectFileId,
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user has no access to either order`,
        404: `No Found - project file not found or site order not found or site order is not part of the project`,
        422: `Unprocessable - projectFileId refers to a non-project file, orderId refers to a non-site file`,
      },
    });
  }

  /**
   * Ingest file based on order Number (Auth)
   * @returns void
   * @throws ApiError
   */
  public ingestFilesAsync({
    orderNumber,
    mode,
  }: {
    /**
     * order number to ingest
     */
    orderNumber: string,
    /**
     * Mode of the order ingest
     */
    mode?: Workspace_Common_Models_IngestFileMode,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderNumber}',
      path: {
        'orderNumber': orderNumber,
      },
      query: {
        'mode': mode,
      },
      errors: {
        403: `Cannot access order`,
        404: `Order number does not exist or external user tries to access`,
      },
    });
  }

  /**
   * Patches the Close Dates in Eclipse and Clarity First (Auth)
   * @returns Workspace_Common_Responses_UpdateCloseDatesResponse Successfully patched Eclipse and Clarity First.
   * @throws ApiError
   */
  public patchCloseDatesAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    /**
     * The JsonPatchDocument for this endpoint must only use the Replace operation.
     * To set a date to null, a date of 0001-01-01 must be passed in using a Replace operation.
     */
    requestBody: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation>,
  }): CancelablePromise<Workspace_Common_Responses_UpdateCloseDatesResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/closing-dates',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `User does not have rights to edit the file.`,
      },
    });
  }

  /**
   * Patches the Loan Amount in Eclipse and Clarity First (Auth)
   * @returns Workspace_Common_Models_FileLoanAmount Successfully patched Eclipse and Clarity First.
   * @throws ApiError
   */
  public patchLoanAmount({
    orderId,
    loanAmountId,
    requestBody,
  }: {
    orderId: number,
    loanAmountId: number,
    /**
     * The JsonPatchDocument for this endpoint must only use the Replace operation.
     * To set an amount to null, a value of 0 must be passed in using a Replace operation.
     */
    requestBody: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation>,
  }): CancelablePromise<Workspace_Common_Models_FileLoanAmount> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/loan-amounts/{loanAmountId}',
      path: {
        'orderId': orderId,
        'loanAmountId': loanAmountId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Forbidden`,
        404: `Loan Amount or FastId not found`,
        409: `Loan in Eclipse is not associated with a Lender`,
      },
    });
  }

  /**
   * Patches the FileProducts collection in CF and the FileData.Products resource in Eclipse (Auth)
   * @returns Workspace_Common_Models_ProductViewModel Successfully patched Eclipse and Clarity First.
   * @throws ApiError
   */
  public updateFileProductsAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    /**
     * The collection of products to be updated
     */
    requestBody: Array<Workspace_Common_Models_ProductViewModel>,
  }): CancelablePromise<Array<Workspace_Common_Models_ProductViewModel>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/products',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `There are no products in the collection`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Gets the sales prices related to the order. (Auth)
   * @returns Workspace_Common_Models_SalesPriceViewModel Successfully fetched the sales prices.
   * @throws ApiError
   */
  public getSalesPrices({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_SalesPriceViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/sales-prices',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `User cannot access file.`,
        404: `Order Sales Prices not found.`,
      },
    });
  }

  /**
   * Updates the Total and Individual Sales Prices in CF and Eclipse. (Auth)
   * @returns Workspace_Common_Responses_UpdateSalesPricesResponse Successfully updated Eclipse and Clarity First.
   * @throws ApiError
   */
  public updateSalesPrices({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_Common_Requests_UpdateSalesPricesRequest,
  }): CancelablePromise<Workspace_Common_Responses_UpdateSalesPricesResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/sales-prices',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid individual or total sales price`,
        403: `A user without permission to edit tries to edit.`,
        404: `Order isn't found in Clarity First`,
        409: `Order Type or Service Type does not support editing sales price`,
      },
    });
  }

  /**
   * Updates the owner liabilities in CF and Eclipse (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateOwnerLiabilitiesAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Array<Workspace_Common_Models_FileOwnerLiability>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/owner-liabilities',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `OwnerLiabilities input is null`,
        403: `A user without permission to edit tries to edit.`,
        404: `Order isn't found in Clarity First`,
      },
    });
  }

  /**
   * Retrieves the list of addresses for the specified file from the CF Database (Auth)
   * @returns Workspace_Common_Models_Dto_Address_AddressDto Successfully retrieved addresses
   * @throws ApiError
   */
  public getAddressesForOrder({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_Dto_Address_AddressDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/addresses',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `No access to file.`,
        404: `File not found.`,
      },
    });
  }

  /**
   * Updates an Address in CF and Eclipse (Auth)
   * @returns any Successfully updated Eclipse and Clarity First.
   * @throws ApiError
   */
  public updateAddressAsync({
    orderId,
    addressId,
    requestBody,
  }: {
    orderId: number,
    addressId: number,
    requestBody: Workspace_API_ViewModels_UpdateAddressViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/addresses/{addressId}',
      path: {
        'orderId': orderId,
        'addressId': addressId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid property type.`,
        403: `No access to file.`,
        404: `File not found.`,
      },
    });
  }

  /**
   * Add a service type to a file. (Auth)
   * @returns void
   * @throws ApiError
   */
  public addServiceAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id.
     */
    orderId: number,
    /**
     * Service type add request.
     */
    requestBody: Workspace_API_ViewModels_AddServiceTypeViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/services',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `ServiceType input is null.`,
        403: `A user without permission to edit tries to edit.`,
        404: `File not found/office detail is null.`,
        409: `Service Type already exists.`,
        422: `Unprocessable`,
      },
    });
  }

  /**
   * Update owning office and team members for multiple service type to a file. (Auth)
   * @returns Workspace_Common_Models_Services_OwningOfficeUpdateResponse Success
   * @throws ApiError
   */
  public updateServiceOwningOfficeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id.
     */
    orderId: number,
    /**
     * Services to update.
     */
    requestBody: Array<Workspace_Common_Models_Services_ServiceToUpdate>,
  }): CancelablePromise<Array<Workspace_Common_Models_Services_OwningOfficeUpdateResponse>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/services/owningoffice',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `A user without permission to edit tries to edit.`,
        404: `File not found/office detail is null.`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Remove a service type from order. (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeServiceAsync({
    orderId,
    serviceId,
  }: {
    /**
     * Order Id.
     */
    orderId: number,
    /**
     * Service Id.
     */
    serviceId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/services/{serviceId}',
      path: {
        'orderId': orderId,
        'serviceId': serviceId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `A user without permission to edit tries to edit.`,
        404: `File not found/Service type does not exist.`,
        422: `Unprocessable`,
      },
    });
  }

  /**
   * Returns list of service types for an order (Auth)
   * @returns Workspace_API_ViewModels_Services_ServiceTypesViewModel Service Types successfully retrieved
   * @throws ApiError
   */
  public getServiceTypes({
    orderId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Services_ServiceTypesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/serviceType',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates the order status in CF and Eclipse (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateOrderStatus({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_ViewModels_UpdateOrderStatusViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/_status',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        403: `A user without permission to edit tries to edit.`,
        404: `Order not found by OrderId or Status not found by StatusId.`,
        409: `Order with Escrow service type cannot be closed without close date.`,
      },
    });
  }

  /**
   * Updates a Transaction Type in CF and Eclipse (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateTransactionTypeAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_ViewModels_UpdateTransactionTypeViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/_transaction-type',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `No access to file.`,
        404: `File or submitted transaction type not found.`,
        409: `Business Rules Conflict.`,
      },
    });
  }

  /**
   * Updates a CoInsurance Flag in CF and Eclipse (Auth)
   * @returns Microsoft_AspNetCore_Mvc_OkObjectResult Current status of the co-insurance flag
   * @throws ApiError
   */
  public updateCoInsuranceFlagAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * An update view model to update co-insurance flag
     */
    requestBody: Workspace_API_ViewModels_UpdateCoInsuranceFlagViewModel,
  }): CancelablePromise<Microsoft_AspNetCore_Mvc_OkObjectResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/_coInsurance-flag',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `No access to file.`,
        404: `Order not found.`,
        422: `Co-Insurance flag is false`,
      },
    });
  }

  /**
   * Adds an order by OrderId into the users favorites list.
   * This endpoint is going to used by mobile client. (Auth)
   * @returns Microsoft_AspNetCore_Mvc_OkObjectResult Successfully updated the favorites lists for an user.
   * @throws ApiError
   */
  public addOrderFavorites({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Microsoft_AspNetCore_Mvc_OkObjectResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/favorites',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `User cannot access file.`,
        404: `Order not found.`,
        422: `Order is already in favorite.`,
      },
    });
  }

  /**
   * Remove an order by OrderId from users favorites list.
   * This endpoint is going to be used by mobile client. (Auth)
   * @returns Microsoft_AspNetCore_Mvc_OkObjectResult Successfully removed order by OrderId from users favorites list.
   * @throws ApiError
   */
  public removeOrderFavorites({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Microsoft_AspNetCore_Mvc_OkObjectResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/favorites',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `No access to file.`,
        404: `Order not found.`,
        422: `Order is already in favorite.`,
      },
    });
  }

  /**
   * This endpoint is to fetch the list of favorite orders based on logged in user.
   * It is used for both external and internal user by mobile team. (Auth)
   * @returns Workspace_Common_Responses_FilesList Successfully fetched the list of favorite orders for a user.
   * @throws ApiError
   */
  public getMyFavoritesOrdersAsync({
    page = 1,
  }: {
    /**
     * a page number
     */
    page?: number,
  }): CancelablePromise<Workspace_Common_Responses_FilesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/favorites',
      query: {
        'page': page,
      },
      errors: {
        400: `Invalid page number.`,
        401: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a list of unique States present in all Addresses (Auth)
   * @returns Workspace_API_ViewModels_Order_OrderStatesViewModel Successfully returned list of States.
   * @throws ApiError
   */
  public getUniqueStates({
    orderId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Order_OrderStatesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/states',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `No access to file.`,
        404: `Order not found.`,
      },
    });
  }

  /**
   * Get the status whether User can access an order. (Auth)
   * @returns Microsoft_AspNetCore_Mvc_OkObjectResult User has access to the order.
   * @throws ApiError
   */
  public getUserCanAccessOrderAsync({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Microsoft_AspNetCore_Mvc_OkObjectResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/can-access-order',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `No access to file.`,
        404: `Order not found.`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Returns list of transaction types for orders (Auth)
   * @returns Workspace_API_ViewModels_FileTransactionType_FileTransactionTypeViewModel Service Types successfully retrieved
   * @throws ApiError
   */
  public getTransactionTypesAsync({
    orderIds,
  }: {
    /**
     * List of Order Id
     */
    orderIds: Array<number>,
  }): CancelablePromise<Array<Workspace_API_ViewModels_FileTransactionType_FileTransactionTypeViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/_transactionTypes',
      query: {
        'orderIds': orderIds,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Retrieves the Order flags from Eclipse for a given order ID. (Auth)
   * @returns Workspace_API_ViewModels_Order_OrderFlagsViewModel Successfully retrieved the order flags
   * @throws ApiError
   */
  public getOrderFlags({
    orderId,
  }: {
    /**
     * long value of the order ID for which the flags will be retrieved.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Order_OrderFlagsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/flags',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user`,
      },
    });
  }

}
