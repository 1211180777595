import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M7.73912 0.695312C9.7093 0.695313 11.5988 1.47796 12.9919 2.87109C14.385 4.26422 15.1677 6.1537 15.1677 8.12388C15.1677 9.96388 14.4934 11.6553 13.3848 12.9582L13.6934 13.2667H14.5963L20.3105 18.981L18.5963 20.6953L12.882 14.981V14.0782L12.5734 13.7696C11.2705 14.8782 9.57912 15.5525 7.73912 15.5525C5.76894 15.5525 3.87945 14.7698 2.48633 13.3767C1.0932 11.9836 0.310547 10.0941 0.310547 8.12388C0.310547 6.1537 1.0932 4.26422 2.48633 2.87109C3.87945 1.47796 5.76894 0.695313 7.73912 0.695312ZM7.73912 2.98103C4.88198 2.98103 2.59626 5.26674 2.59626 8.12388C2.59626 10.981 4.88198 13.2667 7.73912 13.2667C10.5963 13.2667 12.882 10.981 12.882 8.12388C12.882 5.26674 10.5963 2.98103 7.73912 2.98103Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
