import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IApplicationState } from 'store';
import { headerZIndex } from 'config/theme';
import { gray8, primaryBlue2, white } from 'config/colors';
import { Row } from 'components/ui/Box';
import UploadProgressLoader from 'components/UploadProgressLoader';
import { withRouter } from 'react-router';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import ProgressHeader from './ProgressHeader';
import ProgressRow from './ProgressRow';

const headerHeight: number = 56;
const rowHeight: number = 70;
const maxVisibleRows: number = 4;

const OrderDisbursementIssueProgress = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const inProgressIssues = useSelector(
    (state: IApplicationState) =>
      state.orders.ui.disbursementIssueProgress.inProgressIssues
  );
  const progressValue = useSelector(
    (state: IApplicationState) =>
      state.orders.ui.disbursementIssueProgress.progressValue
  );

  if (!inProgressIssues.length) {
    return null;
  }

  return (
    <FixedContainer>
      <ProgressHeader
        isExpanded={isExpanded}
        onToggleExpanded={() => setIsExpanded(!isExpanded)}
      />
      <div
        style={{
          overflowY: 'auto',
          maxHeight: rowHeight * maxVisibleRows,
          overflowX: 'hidden',
        }}
      >
        {isExpanded && (
          <>
            <div
              style={{
                marginBottom: '13px',
                borderBottom: `1px solid ${primaryBlue2}`,
              }}
            >
              <ProgressRow finishedUploading={inProgressIssues.length === 0}>
                <StyledProgressSubHeader>
                  {`Issuing ${inProgressIssues.length} disbursement${
                    inProgressIssues.length > 1 ? 's' : ''
                  }`}
                  <div
                    style={{
                      display: 'inline-flex',
                      fontSize: '12px',
                      color: gray8,
                    }}
                  >
                    <OverflowText>
                      {inProgressIssues.map((u) => u.payeeName).join(', ')}
                    </OverflowText>
                  </div>
                </StyledProgressSubHeader>
              </ProgressRow>
              <UploadProgressLoader value={progressValue} />
            </div>
          </>
        )}
      </div>
    </FixedContainer>
  );
};

export default withRouter(OrderDisbursementIssueProgress as any);

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${rowHeight * maxVisibleRows + headerHeight}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
  transform: translateX(0);
  transition: transform 5s ease;
  &.animate-out-z {
    transform: translateX(100vw);
  }
`;

const StyledProgressSubHeader = styled(Row).attrs({ justify: 'between' })`
  ${SMALL_BODY_MEDIUM};

  height: ${headerHeight}px;
  display: grid;
  padding: 0px 0 10px 0px;
  align-items: center;
`;

const OverflowText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 270px;
`;

OrderDisbursementIssueProgress.displayName = 'OrderDisbursementIssueProgress';
