import {
  IPropertyFarmingState,
  PropertyFarmingTypeKeys,
  PropertyFarmingActions,
} from './types';

export const initialState: IPropertyFarmingState = {
  loading: false,
  error: null,
  farms: [],
  selectedFarmDetails: null,
  farmShapes: [],
  farmCheckedPropertyIds: [],
};

export default (
  state = initialState,
  action: PropertyFarmingActions
): IPropertyFarmingState => {
  switch (action.type) {
    case PropertyFarmingTypeKeys.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PropertyFarmingTypeKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PropertyFarmingTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PropertyFarmingTypeKeys.SET_FARMS:
      return {
        ...state,
        farms: action.farms || [],
      };
    case PropertyFarmingTypeKeys.SET_SELECTED_FARM_DETAILS:
      return {
        ...state,
        selectedFarmDetails: action.farmDetails,
      };
    case PropertyFarmingTypeKeys.SET_FARM_SHAPES:
      return {
        ...state,
        farmShapes: action.farmShapes,
      };
    case PropertyFarmingTypeKeys.SET_FARM_CHECKED_PROPERTY_IDS:
      return {
        ...state,
        farmCheckedPropertyIds: action.farmCheckedPropertyIds,
      };
    default:
      return state;
  }
};
