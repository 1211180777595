import {
  extremeBlack,
  heavyShadow,
  linkWater,
  persianRed,
  white,
} from 'config/theme';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  margin: 0;
  padding: 0;
  background: ${white};
  border-radius: 3px;
  box-shadow: ${heavyShadow};
  position: absolute;
  right: -18px;
  text-align: left;
  top: 56px;
  z-index: 10;

  &:after,
  &:before {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 12px;
    margin-left: -12px;
  }
`;
DropdownContainer.displayName = 'ResourcesDropdownContainer';

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${linkWater};
  display: flex;
  height: 50px;
  padding: 20px 17px;
`;

export const Title = styled.div`
  ${SMALL_BODY_MEDIUM};
  color: ${extremeBlack};
`;
Title.displayName = 'Title';

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 22px;
  height: 26px;
  justify-content: center;
  width: 23px;
`;

export const DropdownRowContainer = styled.a<{ noBorder?: boolean }>`
  ${({ noBorder }) =>
    noBorder ? '' : `border-bottom: 1px solid ${linkWater};`};
  align-items: center;
  box-shadow: none !important;
  display: flex;
  padding: 0px 20px 0px 13px;
  font-size: 16px;
  font-style: normal;
  height: 48px;

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &:hover {
    background: rgba(52, 85, 128, 0.14);
    text-decoration: none;
  }
  &:active {
    background: rgba(52, 85, 128, 0.26);
  }
`;
DropdownRowContainer.displayName = 'DropdownRowContainer';

export const Counter = styled.div`
  background: ${persianRed};
  border-radius: 50%;
  color: ${white};
  font-size: 10px;
  font-weight: 100;
  min-width: 16px;
  padding: 2px;
  position: absolute;
  right: 32px;
  text-align: center;
  top: -4px;
  z-index: 1;
`;
Counter.displayName = 'Counter';
