import {
  NotificationsTypeKeys,
  INotificationsState,
  NotificationsAction,
} from './types';

export const initialState: INotificationsState = {
  visible: false,
  tileProps: null,
  autohide: false,
};

export default (state = initialState, action: NotificationsAction) => {
  switch (action.type) {
    case NotificationsTypeKeys.SHOW:
      return {
        ...state,
        ...action,
        visible: true,
      };
    case NotificationsTypeKeys.HIDE:
      return {
        ...state,
        tileProps: null,
        visible: false,
      };
    default:
      return state;
  }
};
