/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_SplitFeesPayeesViewModel } from '../models/Workspace_API_ViewModels_SplitFeesPayeesViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderTaxesAndFeesPayeesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search payees by organization, based on the given criteria (Auth)
   * @returns Workspace_API_ViewModels_SplitFeesPayeesViewModel The search results.
   * @throws ApiError
   */
  public searchPayeesByOrganization({
    orderId,
    entityTypeId,
    entityName,
    idCode,
    contactName,
    country,
    stateId,
    countyId,
    cityId,
    addressTypeId,
  }: {
    /**
     * The order id to check for.
     */
    orderId: number,
    entityTypeId?: number | null,
    entityName?: string | null,
    idCode?: string | null,
    contactName?: string | null,
    country?: string | null,
    stateId?: number | null,
    countyId?: number | null,
    cityId?: number | null,
    addressTypeId?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_SplitFeesPayeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/payees/organization',
      path: {
        'orderId': orderId,
      },
      query: {
        'EntityTypeId': entityTypeId,
        'EntityName': entityName,
        'IdCode': idCode,
        'ContactName': contactName,
        'Country': country,
        'StateId': stateId,
        'CountyId': countyId,
        'CityId': cityId,
        'AddressTypeId': addressTypeId,
      },
      errors: {
        401: `Unauthenticated user.`,
        404: `Order or Fee Not Found or not available payees or External user.`,
      },
    });
  }

  /**
   * Get the payees, in the file address book, associated to the order (Auth)
   * @returns Workspace_API_ViewModels_SplitFeesPayeesViewModel The search results.
   * @throws ApiError
   */
  public searchPayeesInFileAddressBook({
    orderId,
  }: {
    /**
     * The order id to check for.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_SplitFeesPayeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/payees/address-book',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user.`,
        404: `Order or Fee Not Found or not available payees or External user.`,
      },
    });
  }

  /**
   * Allows to get the split fee payees for the current order (Auth)
   * @returns Workspace_API_ViewModels_SplitFeesPayeesViewModel The fee summary obtained.
   * @throws ApiError
   */
  public fetchSplitFeePayeesAsync({
    orderId,
  }: {
    /**
     * The order id to check for.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_SplitFeesPayeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/payees/split-fees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user.`,
        403: `User doesn't have the required activity right.`,
        404: `Order or Fee Not Found or External user.`,
      },
    });
  }

}
