import React from 'react';
import styled from 'styled-components';
import appleStoreBadge from 'images/appleStoreBadge.png';

const redirect = () => {
  const specs = 'noopener, noreferrer';
  const baseURL = 'https://apps.apple.com/us/app/apple-store/id';
  const mediaType = '?mt=8';
  const url = baseURL + AppConfig.appStoreId + mediaType;
  window.open(url, 'windowOpenTab', specs);
};

const AppStoreLink: React.FC = () => {
  return (
    <a onClick={redirect}>
      <AppStoreImage alt="apple store icon" src={appleStoreBadge} />
    </a>
  );
};
export default AppStoreLink;

const AppStoreImage = styled.img`
  max-height: 40px;
`;
