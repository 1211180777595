/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Overdrafts_CreateUpdateOverdraftViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_CreateUpdateOverdraftViewModel';
import type { Workspace_API_ViewModels_Overdrafts_OverdraftDetailsViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_OverdraftDetailsViewModel';
import type { Workspace_API_ViewModels_Overdrafts_OverdraftRecoverablePartyViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_OverdraftRecoverablePartyViewModel';
import type { Workspace_API_ViewModels_Overdrafts_OverdraftsViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_OverdraftsViewModel';
import type { Workspace_API_ViewModels_Overdrafts_RequiredOverdraftAmountViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_RequiredOverdraftAmountViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderOverdraftsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all Overdraft records for an Order (Auth)
   * @returns Workspace_API_ViewModels_Overdrafts_OverdraftsViewModel Return Order Overdraft Records
   * @throws ApiError
   */
  public getOverdraftsAsync({
    orderId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Overdrafts_OverdraftsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/overdrafts',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Creates or Updates an Overdraft in FAST (Auth)
   * @returns any Overdraft was created/updated successfully
   * @throws ApiError
   */
  public createOrUpdateOverdraftAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * >The object containing all information needed to create an Overdraft record.
     */
    requestBody: Workspace_API_ViewModels_Overdrafts_CreateUpdateOverdraftViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/overdrafts',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid sent info to process`,
        401: `Invalid Authorization header`,
        404: `Order Not Found, external user or user doesn't
        have permission to perform this action`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Get details for a specific Order Overdraft record (Auth)
   * @returns Workspace_API_ViewModels_Overdrafts_OverdraftDetailsViewModel Overdraft record details are available
   * @throws ApiError
   */
  public getOverdraftDetailsAsync({
    orderId,
    overdraftId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
    /**
     * An Overdraft record Id
     */
    overdraftId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Overdrafts_OverdraftDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/overdrafts/{overdraftId}',
      path: {
        'orderId': orderId,
        'overdraftId': overdraftId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order or Overdraft Record Not Found`,
      },
    });
  }

  /**
   * Delete an Overdraft record (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteOverdraftAsync({
    orderId,
    overdraftId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
    /**
     * An Overdraft record Id
     */
    overdraftId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/overdrafts/{overdraftId}',
      path: {
        'orderId': orderId,
        'overdraftId': overdraftId,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order or Overdraft Not Found`,
      },
    });
  }

  /**
   * Get Order Business parties that could recover an Overdraft amount (Auth)
   * @returns Workspace_API_ViewModels_Overdrafts_OverdraftRecoverablePartyViewModel Overdraft record details are available
   * @throws ApiError
   */
  public getOverdraftRecoverableParties({
    orderId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_Overdrafts_OverdraftRecoverablePartyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/overdrafts/recoverable-parties',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get the required overdraft amount for either a collection of Disbursements or a Deposit Adjusting amount. You can only compute one or the other. (Auth)
   * @returns Workspace_API_ViewModels_Overdrafts_RequiredOverdraftAmountViewModel Required Overdraft amount details
   * @throws ApiError
   */
  public calculateOverdraftAmountAsync({
    orderId,
    overdraftType,
    disbursementIds,
    depositId,
    depositAdjustingAmount,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
    /**
     * The type of Overdraft
     */
    overdraftType?: string | null,
    /**
     * A collection of Disbursement Ids
     */
    disbursementIds?: Array<number> | null,
    /**
     * Id of adjusted deposit that triggered the overdraft requests. Required if depositAdjustingAmount is not empty.
     */
    depositId?: number | null,
    /**
     * Deposit adjusting amount
     */
    depositAdjustingAmount?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_Overdrafts_RequiredOverdraftAmountViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/overdrafts/_calculateAmount',
      path: {
        'orderId': orderId,
      },
      query: {
        'overdraftType': overdraftType,
        'disbursementIds': disbursementIds,
        'depositId': depositId,
        'depositAdjustingAmount': depositAdjustingAmount,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get overdraft settings for a file (Auth)
   * @returns any Overdraft Record deleted
   * @throws ApiError
   */
  public getOverdraftSettingsAsync({
    orderId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/overdrafts/settings',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order or Overdraft Not Found`,
      },
    });
  }

}
