/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Responses_PropertyProductPolicyDetailsResponse } from '../models/Workspace_Common_Responses_PropertyProductPolicyDetailsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PricingPoliciesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the property product prices and payment policy details. (Auth)
   * @returns Workspace_Common_Responses_PropertyProductPolicyDetailsResponse Property Products prices and payment policy details fetched successfully.
   * @throws ApiError
   */
  public getPropertyProductPricesDetails(): CancelablePromise<Workspace_Common_Responses_PropertyProductPolicyDetailsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/pricingPolicy/property-policy-details',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

}
