import {
  IDocumentCreateErrorAction,
  IDocumentCreateResetAction,
  IDocumentCreateStartAction,
  IDocumentCreateSuccessAction,
  ISingleDocumentDownloadErrorAction,
  ISingleDocumentDownloadStartAction,
  ISingleDocumentDownloadSuccessAction,
  DocumentTypeKeys,
  ISingleDocumentDownloadClearAction,
  IDocumentCopyStartAction,
  IDocumentCopySuccessAction,
  IDocumentCopyErrorAction,
  IDocumentCopyClearAction,
  DocumentCopyResult,
} from './types';

const {
  CREATE_ERROR,
  CREATE_RESET,
  CREATE_START,
  CREATE_SUCCESS,
  SINGLE_DOWNLOAD_ERROR,
  SINGLE_DOWNLOAD_START,
  SINGLE_DOWNLOAD_SUCCESS,
  SINGLE_DOWNLOAD_CLEAR,
  DOCUMENT_COPY_CLEAR,
  DOCUMENT_COPY_ERROR,
  DOCUMENT_COPY_START,
  DOCUMENT_COPY_SUCCESS,
} = DocumentTypeKeys;

export const setCreateStartAction = (
  tracked: IDocumentCreateStartAction['trackDocumentCreate'],
  fileId: string
): IDocumentCreateStartAction => {
  return {
    type: CREATE_START,
    trackDocumentCreate: tracked,
    fileId,
  };
};

export const setCreateSuccessAction = (
  tracked: IDocumentCreateSuccessAction['trackDocumentUpdate']
): IDocumentCreateSuccessAction => {
  return {
    type: CREATE_SUCCESS,
    trackDocumentUpdate: tracked,
  };
};

export const setCreateErrorAction = (): IDocumentCreateErrorAction => {
  return {
    type: CREATE_ERROR,
  };
};

export const setCreateResetAction = (): IDocumentCreateResetAction => {
  return {
    type: CREATE_RESET,
  };
};

export const setSingleDownloadStartAction = (
  document: ISingleDocumentDownloadStartAction['document']
): ISingleDocumentDownloadStartAction => {
  return {
    type: SINGLE_DOWNLOAD_START,
    document,
  };
};

export const setSingleDownloadSuccessAction = (
  id: string
): ISingleDocumentDownloadSuccessAction => {
  return {
    type: SINGLE_DOWNLOAD_SUCCESS,
    id,
  };
};

export const setSingleDownloadErrorAction = (
  id: string
): ISingleDocumentDownloadErrorAction => {
  return {
    type: SINGLE_DOWNLOAD_ERROR,
    id,
  };
};

export const setSingleDownloadClearAction = (
  id: string
): ISingleDocumentDownloadClearAction => {
  return {
    type: SINGLE_DOWNLOAD_CLEAR,
    id,
  };
};

export const setDocumentCopyStartAction = (
  input: IDocumentCopyStartAction['input'],
  requestId: string
): IDocumentCopyStartAction => {
  return {
    type: DOCUMENT_COPY_START,
    input,
    requestId,
  };
};

export const setDocumentCopySuccessAction = (
  requestId: string,
  copyResult: DocumentCopyResult
): IDocumentCopySuccessAction => {
  return {
    type: DOCUMENT_COPY_SUCCESS,
    requestId,
    copyResult,
  };
};

export const setDocumentCopyErrorAction = (
  requestId: string,
  errorMessages?: string
): IDocumentCopyErrorAction => {
  return {
    type: DOCUMENT_COPY_ERROR,
    requestId,
    errorMessages,
  };
};

export const setDocumentCopyClearAction = (
  requestId: string
): IDocumentCopyClearAction => {
  return {
    type: DOCUMENT_COPY_CLEAR,
    requestId,
  };
};
