import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    style={props.style}
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.166016C17.083 0.166016 22 5.08302 22 11.166C22 17.249 17.083 22.166 11 22.166C4.917 22.166 0 17.249 0 11.166C0 5.08302 4.917 0.166016 11 0.166016ZM14.949 5.66602L11 9.61502L7.051 5.66602L5.5 7.21702L9.449 11.166L5.5 15.115L7.051 16.666L11 12.717L14.949 16.666L16.5 15.115L12.551 11.166L16.5 7.21702L14.949 5.66602Z"
      fill="#686B6F"
    />
  </svg>
);
