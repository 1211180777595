import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M9.00781 0.931641C10.2013 0.931641 11.3459 1.40575 12.1898 2.24966C13.0337 3.09357 13.5078 4.23817 13.5078 5.43164C13.5078 6.62511 13.0337 7.76971 12.1898 8.61362C11.3459 9.45753 10.2013 9.93164 9.00781 9.93164C7.81434 9.93164 6.66975 9.45753 5.82583 8.61362C4.98192 7.76971 4.50781 6.62511 4.50781 5.43164C4.50781 4.23817 4.98192 3.09357 5.82583 2.24966C6.66975 1.40575 7.81434 0.931641 9.00781 0.931641ZM9.00781 12.1816C13.9803 12.1816 18.0078 14.1954 18.0078 16.6816V18.9316H0.0078125V16.6816C0.0078125 14.1954 4.03531 12.1816 9.00781 12.1816Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
