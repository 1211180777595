/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_IndexDataElementResponse_ } from '../models/FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_IndexDataElementResponse_';
import type { FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_Template_ } from '../models/FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_Template_';
import type { FA_NCS_ClarityFirst_DocumentInstance_Models_TemplateSearchRequest } from '../models/FA_NCS_ClarityFirst_DocumentInstance_Models_TemplateSearchRequest';
import type { Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_DocumentAssociationMap_ } from '../models/Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_DocumentAssociationMap_';
import type { Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_TemplateRegionResponse_ } from '../models/Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_TemplateRegionResponse_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TemplatesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all favorites for the logged user (Auth)
   * @returns FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_Template_ Success
   * @throws ApiError
   */
  public getFavorites({
    fileId,
    regionIds,
  }: {
    /**
     * The fileId of the document
     */
    fileId: number,
    /**
     * The region Ids to search
     */
    regionIds: Array<number>,
  }): CancelablePromise<FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_Template_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/Templates/{fileId}/favorites',
      path: {
        'fileId': fileId,
      },
      query: {
        'regionIds': regionIds,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * remove favorite template (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeFavoriteTemplateContractAsync({
    templateId,
  }: {
    /**
     * template to remove
     */
    templateId: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/Templates/{templateId}/favorite',
      path: {
        'templateId': templateId,
      },
      errors: {
        400: `Unable to remove template from favorite list`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * add favorite template (Auth)
   * @returns void
   * @throws ApiError
   */
  public addFavoriteTemplateContractAsync({
    templateId,
  }: {
    /**
     * template to add
     */
    templateId: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/Templates/{templateId}/favorite',
      path: {
        'templateId': templateId,
      },
      errors: {
        400: `Unable to add template to favorite list`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Search for available document templates (Auth)
   * @returns FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_Template_ Success
   * @throws ApiError
   */
  public searchTemplatesAsync({
    fileId,
    requestBody,
  }: {
    /**
     * The fileId of the document
     */
    fileId: number,
    /**
     * Search parameters with optional filters
     */
    requestBody?: FA_NCS_ClarityFirst_DocumentInstance_Models_TemplateSearchRequest,
  }): CancelablePromise<FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_Template_> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/Templates/{fileId}/search',
      path: {
        'fileId': fileId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Fetch template association-maps (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_DocumentAssociationMap_ Success
   * @throws ApiError
   */
  public getAssociationMapsAsync(): CancelablePromise<Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_DocumentAssociationMap_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/Templates/association-maps',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Fetch template regions (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_TemplateRegionResponse_ Success
   * @throws ApiError
   */
  public getTemplateRegionsAsync(): CancelablePromise<Workspace_API_ViewModels_Templates_DmsResponseViewModel_List_TemplateRegionResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/Templates/regions',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get a preview of a document template (Auth)
   * @returns any Template preview was successfully fetched
   * @throws ApiError
   */
  public previewAsync({
    templateId,
    orderId,
  }: {
    /**
     * The Id of the template
     */
    templateId: string,
    /**
     * An orderId - the order data will be used to populate the template
     */
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/Templates/{templateId}/preview/{orderId}',
      path: {
        'templateId': templateId,
        'orderId': orderId,
      },
      errors: {
        400: `Preview request failed`,
        403: `Access denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get index data elements for a file (Auth)
   * @returns FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_IndexDataElementResponse_ Success
   * @throws ApiError
   */
  public getIndexDataElements({
    fileId,
    templateIds,
  }: {
    /**
     * The fileId of the document
     */
    fileId: number,
    /**
     * List of template ids
     */
    templateIds?: Array<string>,
  }): CancelablePromise<FA_NCS_ClarityFirst_DocumentInstance_Models_ApiResponse_List_IndexDataElementResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/Templates/{fileId}/indexDataElements',
      path: {
        'fileId': fileId,
      },
      query: {
        'templateIds': templateIds,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
