/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_VerifyWireInstructionRequest } from '../models/Workspace_Common_Requests_VerifyWireInstructionRequest';
import type { Workspace_Common_Requests_WireInvitation } from '../models/Workspace_Common_Requests_WireInvitation';
import type { Workspace_Common_Responses_WireInstructionVerification } from '../models/Workspace_Common_Responses_WireInstructionVerification';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WireInstructionVerificationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This endpoint gets a Wire Instruction Association for a given Request Code. (Auth)
   * @returns Workspace_Common_Responses_WireInstructionVerification Wire Instruction File verification retrieved.
   * @throws ApiError
   */
  public getWireInstructionVerification({
    code,
  }: {
    /**
     * The Request Code of the Wire Instruction Association.
     */
    code: string,
  }): CancelablePromise<Workspace_Common_Responses_WireInstructionVerification> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instruction-verifications/{code}',
      path: {
        'code': code,
      },
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to the Wire Instruction Association.`,
        404: `Wire Instruction Association does not exist.`,
        409: `Wire Instruction Association is not ready for verification.`,
        410: `Wire Instruction action is already completed`,
      },
    });
  }

  /**
   * This endpoint checks a Wire Instruction Association for a given Request Code. (Auth)
   * @returns any Wire Instruction File exits and can be fetched.
   * @throws ApiError
   */
  public checkWireInstructionVerification({
    code,
  }: {
    /**
     * The Request Code of the Wire Instruction Association.
     */
    code: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'HEAD',
      url: '/ncs/v1/wire-instruction-verifications/{code}',
      path: {
        'code': code,
      },
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to the Wire Instruction Association.`,
        404: `Wire Instruction Association does not exist or not ready for verification.`,
        409: `2FA required.`,
        410: `Wire Instruction action is already completed`,
      },
    });
  }

  /**
   * This endpoint Verifies a Wire Instruction Association for a given Request Code. (Auth)
   * @returns void
   * @throws ApiError
   */
  public verifyWireInstructionVerification({
    requestBody,
  }: {
    /**
     * The Wire Instruction request information.
     */
    requestBody: Workspace_Common_Requests_VerifyWireInstructionRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instruction-verifications/verify',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to the Wire Instruction Association.`,
        404: `Wire Instruction Association does not exist.`,
        409: `Wire Instruction Association is not ready for verification.`,
        410: `Wire Instruction action is already completed`,
      },
    });
  }

  /**
   * Send or Resend  wire request email for the given wire instruction (Auth)
   * @returns any Wire request email was sent
   * @throws ApiError
   */
  public sendInvitation({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_WireInvitation,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/wire-instruction-verifications/_send-invitation',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `User is not permitted to access this wire instruction`,
        404: `Wire instruction not found`,
        409: `Wire instruction not in the correct status`,
      },
    });
  }

}
