/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_Requests_AddUsersRequest } from '../models/Workspace_API_Requests_AddUsersRequest';
import type { Workspace_API_Requests_CreateGroupRequest } from '../models/Workspace_API_Requests_CreateGroupRequest';
import type { Workspace_API_Requests_UpdateGroupRequest } from '../models/Workspace_API_Requests_UpdateGroupRequest';
import type { Workspace_Common_Models_Group } from '../models/Workspace_Common_Models_Group';
import type { Workspace_Common_Responses_FeatureGroupStatus } from '../models/Workspace_Common_Responses_FeatureGroupStatus';
import type { Workspace_Common_Responses_GroupsList } from '../models/Workspace_Common_Responses_GroupsList';
import type { Workspace_Common_Responses_NewGroup } from '../models/Workspace_Common_Responses_NewGroup';
import type { Workspace_Common_Responses_UpdatedGroup } from '../models/Workspace_Common_Responses_UpdatedGroup';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of all users groups. (Auth)
   * @returns Workspace_Common_Responses_GroupsList Groups successfully retrieved
   * @throws ApiError
   */
  public getGroups(): CancelablePromise<Workspace_Common_Responses_GroupsList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/groups',
      errors: {
        403: `User doesn't have the required Admin activity right`,
      },
    });
  }

  /**
   * Create a new group and add initial members (Auth)
   * @returns Workspace_Common_Responses_NewGroup Group successfully created
   * @throws ApiError
   */
  public createGroup({
    requestBody,
  }: {
    requestBody: Workspace_API_Requests_CreateGroupRequest,
  }): CancelablePromise<Workspace_Common_Responses_NewGroup> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/groups',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Get a group by Id and all its members (Auth)
   * @returns Workspace_Common_Models_Group Group successfully retrieved
   * @throws ApiError
   */
  public getGroup({
    id,
  }: {
    /**
     * a group ID
     */
    id: number,
  }): CancelablePromise<Workspace_Common_Models_Group> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/groups/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `User doesn't have the required Admin activity right`,
        404: `Group does not exist`,
      },
    });
  }

  /**
   * Update group name (Auth)
   * @returns Workspace_Common_Responses_UpdatedGroup Group successfully updated
   * @throws ApiError
   */
  public updateGroup({
    id,
    requestBody,
  }: {
    /**
     * a group ID
     */
    id: number,
    requestBody: Workspace_API_Requests_UpdateGroupRequest,
  }): CancelablePromise<Workspace_Common_Responses_UpdatedGroup> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/groups/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User doesn't have the required Admin activity right`,
        404: `Group does not exist`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add users to group (Auth)
   * @returns void
   * @throws ApiError
   */
  public addGroupUsers({
    id,
    requestBody,
  }: {
    /**
     * a group ID
     */
    id: number,
    requestBody: Workspace_API_Requests_AddUsersRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/groups/{id}/users',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User doesn't have the required Admin activity right`,
        404: `Group does not exist`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Remove a user from a group (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeGroupUser({
    groupId,
    userId,
  }: {
    /**
     * a group ID
     */
    groupId: number,
    /**
     * a user ID
     */
    userId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/groups/{groupId}/users/{userId}',
      path: {
        'groupId': groupId,
        'userId': userId,
      },
      errors: {
        403: `User doesn't have the required Admin activity right`,
        404: `Group does not exist or User does not exist`,
      },
    });
  }

  /**
   * Get Groups Status bby feature id (Auth)
   * @returns Workspace_Common_Responses_FeatureGroupStatus Success
   * @throws ApiError
   */
  public getGroupsStatusByFeatureId({
    featureId,
  }: {
    /**
     * feature id
     */
    featureId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_FeatureGroupStatus>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/groups/features/{featureId}',
      path: {
        'featureId': featureId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}
