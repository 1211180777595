/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CommentsCountViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CommentsCountViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationForSectionListViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationForSectionListViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CreateCommentViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CreateCommentViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_MarkCommentsAsReadViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_MarkCommentsAsReadViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettlementStatementsCommentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns comments for section
   * @returns Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationViewModel Success
   * @throws ApiError
   */
  public getConversationsForSection({
    orderId,
    settlementStatementId,
    sectionId,
  }: {
    /**
     * order id
     */
    orderId: number,
    /**
     * settlement statement id
     */
    settlementStatementId: number,
    /**
     * section id
     */
    sectionId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/section/{sectionId}/comments',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Add a comment for section
   * @returns any Success
   * @throws ApiError
   */
  public markCommentsAsRead({
    orderId,
    settlementStatementId,
    sectionId,
    requestBody,
  }: {
    /**
     * order id
     */
    orderId: number,
    /**
     * settlement statement id
     */
    settlementStatementId: number,
    /**
     * section id
     */
    sectionId: number,
    /**
     * view model with comment ids
     */
    requestBody: Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_MarkCommentsAsReadViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/section/{sectionId}/comments',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Returns all comments for sections of settlement statement
   * @returns Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationForSectionListViewModel Success
   * @throws ApiError
   */
  public getConversationsForSettlementStatement({
    orderId,
    settlementStatementId,
  }: {
    /**
     * order id
     */
    orderId: number,
    /**
     * settlement statement id
     */
    settlementStatementId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_ConversationForSectionListViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/comments',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Returns all comments for sections of settlement statement
   * @returns Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CommentsCountViewModel Success
   * @throws ApiError
   */
  public getConversationCommentsCounts({
    orderId,
    settlementStatementId,
  }: {
    /**
     * order id
     */
    orderId: number,
    /**
     * settlement statement id
     */
    settlementStatementId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CommentsCountViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/comments/counts',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Add a comment for section
   * @returns void
   * @throws ApiError
   */
  public createCommentForSection({
    orderId,
    settlementStatementId,
    sectionId,
    requestBody,
  }: {
    /**
     * order id
     */
    orderId: number,
    /**
     * settlement statement id
     */
    settlementStatementId: number,
    /**
     * section id
     */
    sectionId: number,
    /**
     * comment model
     */
    requestBody: Workspace_API_FeatureAreas_SettlementStatementCommenting_ViewModels_CreateCommentViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/section/{sectionId}/comment',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

}
