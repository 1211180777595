import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M8.5 0.5C7.88518 0.5 7.29554 0.743131 6.8608 1.17591C6.42605 1.60868 6.18182 2.19565 6.18182 2.80769C6.18182 3.41973 6.42605 4.0067 6.8608 4.43948C7.29554 4.87225 7.88518 5.11538 8.5 5.11538C9.11482 5.11538 9.70446 4.87225 10.1392 4.43948C10.5739 4.0067 10.8182 3.41973 10.8182 2.80769C10.8182 2.19565 10.5739 1.60868 10.1392 1.17591C9.70446 0.743131 9.11482 0.5 8.5 0.5ZM3.86364 2.16923C3.35129 2.16923 2.85992 2.37184 2.49763 2.73249C2.13535 3.09313 1.93182 3.58228 1.93182 4.09231C1.93182 4.34485 1.98179 4.59492 2.07887 4.82824C2.17595 5.06156 2.31825 5.27355 2.49763 5.45213C2.67702 5.6307 2.88998 5.77236 3.12436 5.869C3.35874 5.96564 3.60995 6.01538 3.86364 6.01538C4.54364 6.01538 5.13864 5.66154 5.47864 5.13846C4.96091 4.48462 4.63636 3.69231 4.63636 2.80769C4.63636 2.65385 4.63636 2.5 4.66727 2.34615C4.42 2.23077 4.14955 2.16923 3.86364 2.16923ZM13.1364 2.16923C12.8505 2.16923 12.58 2.23077 12.3327 2.34615C12.3636 2.5 12.3636 2.65385 12.3636 2.80769C12.3636 3.69231 12.0391 4.48462 11.5214 5.13846C11.8614 5.66154 12.4564 6.01538 13.1364 6.01538C13.3901 6.01538 13.6413 5.96564 13.8756 5.869C14.11 5.77236 14.323 5.6307 14.5024 5.45213C14.6818 5.27355 14.824 5.06156 14.9211 4.82824C15.0182 4.59492 15.0682 4.34485 15.0682 4.09231C15.0682 3.83977 15.0182 3.5897 14.9211 3.35638C14.824 3.12306 14.6818 2.91106 14.5024 2.73249C14.323 2.55391 14.11 2.41226 13.8756 2.31562C13.6413 2.21897 13.3901 2.16923 13.1364 2.16923ZM8.5 6.65385C6.95455 6.65385 3.86364 7.42308 3.86364 8.96154V10.5H13.1364V8.96154C13.1364 7.42308 10.0455 6.65385 8.5 6.65385ZM2.83591 7.4C1.54545 7.62308 0 8.22308 0 9.21538V10.5H2.31818V8.96154C2.31818 8.36154 2.54227 7.83077 2.83591 7.4ZM14.1641 7.4C14.4577 7.83077 14.6818 8.36154 14.6818 8.96154V10.5H17V9.21538C17 8.22308 15.4545 7.62308 14.1641 7.4Z"
      fill="#345580"
    />
  </svg>
);
