const GoogleApi = (opts: any) => {
  opts = opts || {};
  const apiKey: any = opts.apiKey;
  const libraries: any = opts.libraries || [];
  const client: any = opts.client;
  const URL: string = 'https://maps.googleapis.com/maps/api/js';
  const channel: any = null;
  const language: any = null;
  const region: any = null;
  const url = () => {
    const baseurl = URL;
    const params = {
      key: apiKey,
      libraries: libraries.join(','),
      client,
      v: AppConfig.googleMapApiVersion,
      channel,
      language,
      region,
    };
    const paramStr = Object.keys(params)
      .filter((k) => !!(params as any)[k])
      .map((k) => `${k}=${(params as any)[k]}`)
      .join('&');
    return `${baseurl}?${paramStr}`;
  };
  return url();
};

export const MapPOIStyles: Record<string, google.maps.MapTypeStyle[]> = {
  default: [
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
  ],
  hide: [
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export const INITIAL_ZOOM = 5;
// Initial Center Maps to this address 54640 County Road EE, Arapahoe, CO 80802
export const INITIAL_CENTER_LAT = 39.0149856;
export const INITIAL_CENTER_LNG = -102.1455916;

export default GoogleApi;
