import React, { createContext, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import ContentContainer from 'components/ContentContainer';
import { Column, Row } from 'components/ui/Box';
import { white, primaryBlue4 } from 'config/colors';
import styled from 'styled-components';
import FARegion from './FARegion';
import { IOfficeHash, IOffice } from 'store/offices/types';
import FAOffice from './FAOffice';
import AccountingSubheader from './AccountingSubheader';
import { subheaderZIndex } from 'config/theme';
import workspaceAPI from 'services/workspaceAPI';
import {
  AccountingURLPath,
  AccountingView,
  IRegionAndOfficeData,
  ActivityRightId,
} from './types';
import NotFound from 'views/NotFound';
import { IApplicationState } from 'store';
import { isFeatureEnabled } from 'store/features/helpers';
import { DepositsFeatures } from 'store/features/types';
import { MenuOption } from '../IncomingFunds/constants';

interface IAccountingContainerProps {
  children: React.ReactNode;
  officeId: number | string | null;
  breadcrumbs: any;
  order: any;
  push: (path: string) => void;
}

interface IAccountingContext {
  office: IOffice;
  offices: IOfficeHash;
}

export const OFFICE_ID_LOCAL_STORAGE_KEY = 'accountingOfficeId';

export const AccountingContext = createContext<IAccountingContext>({} as any);

const getViewFromUrl = (path: string): AccountingView | null => {
  switch (true) {
    case /\/incoming-funds/.test(path):
    case /\/deposits\//.test(path):
      return 'Incoming Wires';
    case /\/outgoing-funds/.test(path):
    case /\/files/.test(path):
    case /\/funds/.test(path):
      return 'Outgoing Wires';
    case /\/customer-uploads/.test(path):
      return 'Customer Uploads';
    case /\/document-submission/.test(path):
      return 'Document Submission';
    case /\/iba-transactions/.test(path):
      return 'IBA Transactions';
    case /\/checklists/.test(path):
      return 'Checklists Templates';
    case /\/trust-accounting/.test(path):
      return 'Trust Accounting';
    case /\/deposit-list/.test(path):
      return 'Deposit List';
  }
  return null;
};

const isRootView = (path: string) =>
  !!path.match(
    /\/(customer-uploads|incoming-funds|outgoing-funds|iba-transactions|deposit-list|checklists|trust-accounting)$/
  );

export default connect(null, { push })(function AccountingContainer({
  children,
  officeId,
  push,
}: IAccountingContainerProps) {
  const [offices, setOffices] = useState<IOfficeHash>({});
  const [primaryOfficeId, setPrimaryOfficeId] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOfficeLoading, setIsOfficeLoading] = useState<boolean>(false);
  const [isAccounting, setIsAccounting] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<string>('');

  const isAchDepositEnabled = useSelector((state: IApplicationState) =>
    isFeatureEnabled(state, DepositsFeatures.AchDeposit)
  );
  // For Future Activity Rights Implementation whichever path you want just remove the path from the array and add the path another constant and update the shouldRenderChildren const.
  var hasAccountingPath = [
    AccountingURLPath.INCOMING_WIRES,
    AccountingURLPath.OUTGOING_WIRES,
    AccountingURLPath.IBA_TRANSACTIONS,
    AccountingURLPath.OUTGOING_FUNDS,
    AccountingURLPath.INCOMING_RECEIPT,
    AccountingURLPath.DEPOSIT_LIST,
  ].some((path) => location.pathname.includes(path.toString()));

  var hasNonAccountingPath = [
    AccountingURLPath.CUSTOMER_UPLOADS,
    AccountingURLPath.CHECKLISTS,
    AccountingURLPath.DOCUMENT_SUBMISSION,
  ].some((path) => location.pathname.includes(path.toString()));

  var hasTrustAccountingPath = location.pathname.includes(
    AccountingURLPath.TRUST_ACCOUNTING.toString()
  );

  var hasACHPath = [
    AccountingURLPath.INCOMING_WIRES,
    AccountingURLPath.INCOMING_RECEIPT,
  ].some((path) => location.pathname.includes(path.toString()));

  useEffect(() => {
    const pathName = getViewFromUrl(location.pathname);
    if (pathName === null) {
      setIsLoading(false);
      return;
    }
    if (pathName && pathName !== currentView) {
      setIsOfficeLoading(true);
      setCurrentView(pathName);

      var activityId = hasNonAccountingPath
        ? 0
        : hasTrustAccountingPath
        ? ActivityRightId.TRUST_EXTRACT
        : ActivityRightId.ESCROW_DISBURSEMENT;

      workspaceAPI
        .get<IRegionAndOfficeData>(`/users/regions/${activityId}`)
        .then(({ data }) => {
          setOffices(
            data.regions
              .filter((r) => r.id === 1)
              .reduce((allOffices, { id: regionId, offices }) => {
                offices.forEach((office) => {
                  allOffices[office.fastId] = {
                    ...office,
                    regionId,
                  };
                });
                return allOffices;
              }, {})
          );
        })
        .catch(() => {
          push('/error');
        })
        .finally(() => {
          setIsLoading(false);
          setIsOfficeLoading(false);
          setPrimaryOfficeId(officeId);
          setIsAccounting(hasAccountingPath);
        });
    }
  }, [location.pathname]);

  const office = offices[officeId as number];
  const accountingContext = { office, offices };

  if (!isLoading && !office && !isOfficeLoading) {
    if (localStorage.getItem(OFFICE_ID_LOCAL_STORAGE_KEY) === `${officeId}`) {
      localStorage.removeItem(OFFICE_ID_LOCAL_STORAGE_KEY);
    }
    return <NotFound />;
  }

  // For Future Activity Rights Implementation indroduce a new state variable and add the condition in const shouldRenderChildren if require.
  const shouldRenderChildren =
    isAccounting || hasNonAccountingPath || hasTrustAccountingPath;

  return (
    <div>
      <HeaderSubheaderContainer id="accountingHeader">
        <Header>
          <ContentContainer>
            <Row>
              <FARegion />
              <Divider />
              <FAOffice
                disabled={!isRootView(location.pathname)}
                officeId={officeId}
                officeLoading={isOfficeLoading}
                offices={offices}
                setOfficeId={(newOfficeId: number) => {
                  localStorage.setItem(
                    OFFICE_ID_LOCAL_STORAGE_KEY,
                    newOfficeId.toString()
                  );
                  if (location.pathname.includes('accounting')) {
                    const accountingPath = location.pathname.match(
                      /\/accounting\/\d+\/(.*)/
                    )![1];
                    push(`/accounting/${newOfficeId}/${accountingPath}`);
                  }
                  if (location.pathname.includes('office')) {
                    const officePath = location.pathname.match(
                      /\/office\/\d+\/(.*)/
                    )![1];
                    push(`/office/${newOfficeId}/${officePath}`);
                  }
                }}
              />
            </Row>
          </ContentContainer>
        </Header>
        <AccountingSubheader
          activeNavItem={
            isAchDepositEnabled && hasACHPath
              ? MenuOption.IncomingFunds
              : getViewFromUrl(location.pathname)
          }
          fastOfficeId={primaryOfficeId}
        />
      </HeaderSubheaderContainer>

      {!!office && shouldRenderChildren && (
        // Don't even render the children unless a selected office exists.
        // No point in doing so otherwise, since all possible content here
        // relates to a certain office.
        <ChildrenContainer>
          <AccountingContext.Provider value={accountingContext}>
            {children}
          </AccountingContext.Provider>
        </ChildrenContainer>
      )}
    </div>
  );
});

const HeaderSubheaderContainer = styled(Column)`
  box-shadow: 0px 4px 4px rgba(50, 57, 68, 0.05),
    0px 1px 3px rgba(0, 0, 0, 0.17);
  position: fixed;
  top: 55px;
  width: 100%;
  z-index: ${subheaderZIndex};
`;

const Header = styled.div`
  background: ${white};
  border-bottom: 1px solid ${primaryBlue4};
`;

const Divider = styled.div`
  border-left: 1px solid ${primaryBlue4};
  margin: 10px 0 10px 30px;
  width: 30px;
`;

const ChildrenContainer = styled.div`
  padding-top: 16px;
  margin-top: 122px;
`;
