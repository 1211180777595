import React from 'react';
import styled from 'styled-components';
import { blue, trout } from 'config/theme';
import { IApplicationState } from 'store';
import { connect } from 'react-redux';
import { isInternalUser } from 'store/auth/getters';

interface IBlankSlateProps {
  theme: NotificationsBlankSlateTheme;
  isExternalUser: boolean;
}

export enum NotificationsBlankSlateTheme {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

class NotificationsBlankSlate extends React.Component<IBlankSlateProps> {
  public render() {
    const { theme, isExternalUser } = this.props;
    const styles = Themes[theme];

    return (
      <Container style={styles.container}>
        <BlankSlateGraphic style={styles.graphic} />
        <Title style={styles.title}>No notifications yet</Title>
        {isExternalUser && (
          <Content>
            You will see notifications here when you are added to an order,
            requested to provide and verify wire instructions, or shared a
            document.
          </Content>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state: IApplicationState) {
  return {
    isExternalUser: !isInternalUser(state.auth),
  };
}

const ConnectedNotificationsBlankSlate = connect(mapStateToProps)(
  NotificationsBlankSlate
);
ConnectedNotificationsBlankSlate.displayName =
  'ConnectedNotificationsBlankSlate';
export default ConnectedNotificationsBlankSlate;

const Themes = {
  [NotificationsBlankSlateTheme.LARGE]: {
    container: {
      height: 500,
    },
    graphic: {
      height: 270,
      width: 390,
    },
    title: {
      fontSize: 32,
      lineHeight: '42px',
    },
  },
  [NotificationsBlankSlateTheme.SMALL]: {
    container: {
      height: '100%',
    },
    graphic: {
      height: 120,
      width: 140,
    },
    title: {
      fontSize: 24,
      lineHeight: '32px',
      margin: 0,
    },
  },
};

interface IBlankSlateGraphicProps {
  style: React.CSSProperties;
}

export const BlankSlateGraphic = ({ style }: IBlankSlateGraphicProps) => (
  <svg
    width="196"
    height="140"
    viewBox="0 0 196 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M97 140C131.794 140 160 137.314 160 134C160 130.686 131.794 128 97 128C62.2061 128 34 130.686 34 134C34 137.314 62.2061 140 97 140Z"
      fill="#CBD6E4"
    />
    <path
      d="M56.4838 13.5196L146.657 7.17601C153.085 6.7531 158.594 11.6165 159.018 18.0306L163.715 91.2288C164.139 97.6429 159.265 103.141 152.837 103.564L26.1104 112.233C19.6825 112.656 14.1729 107.793 13.7491 101.379L9.08708 28.1804C8.66326 21.7663 13.5371 16.2685 19.965 15.8456L56.4838 13.5196Z"
      fill="#AEBBCE"
    />
    <path
      d="M25.3687 113.326C18.7289 113.326 13.1487 108.18 12.7249 101.449L8.02756 28.2507C7.56842 21.2727 12.9014 15.2463 19.8944 14.7882L56.4485 12.4622L146.621 6.11857C150.012 5.90711 153.261 6.99962 155.804 9.25513C158.347 11.4754 159.866 14.5767 160.078 17.96L164.775 91.1582C165.234 98.1361 159.901 104.163 152.908 104.621L26.181 113.29C25.8985 113.29 25.6512 113.326 25.3687 113.326ZM147.434 8.2331C147.222 8.2331 146.975 8.2331 146.727 8.26834L20.0004 16.9379C14.1729 17.3256 9.75814 22.33 10.1113 28.145L14.8086 101.343C14.9852 104.163 16.2567 106.735 18.3757 108.603C20.4948 110.471 23.2143 111.387 26.0397 111.211L152.767 102.541C158.594 102.154 163.009 97.1493 162.656 91.3344L157.959 18.1362C157.782 15.3168 156.511 12.7441 154.391 10.8763C152.449 9.1494 150.012 8.2331 147.434 8.2331Z"
      fill="#95A4BA"
    />
    <path
      d="M76.7937 7.88059L170.002 13.8718C176.43 14.2594 181.304 19.7572 180.915 26.1713L176.465 99.3695C176.077 105.784 170.567 110.647 164.139 110.259L34.3767 102.048C27.9489 101.66 23.075 96.1625 23.4635 89.7484L27.9135 16.5502C28.302 10.1361 33.8116 5.27266 40.2395 5.66033L76.7937 7.88059Z"
      fill="#C8D5E8"
    />
    <path
      d="M164.881 111.317C164.634 111.317 164.351 111.317 164.104 111.282L34.3414 103.105C30.9509 102.894 27.8429 101.378 25.6179 98.8763C23.3575 96.3388 22.2274 93.0965 22.4393 89.7133L26.8893 16.5151C27.1012 13.1318 28.6199 10.0305 31.1275 7.81023C33.6704 5.55473 36.9196 4.42697 40.3102 4.63843L170.073 12.8146C173.463 13.0261 176.571 14.5415 178.796 17.0437C181.056 19.5811 182.187 22.8234 181.975 26.2067L177.525 99.4049C177.136 106.136 171.521 111.317 164.881 111.317ZM39.5332 6.71772C36.955 6.71772 34.4827 7.63402 32.5402 9.36089C30.4211 11.2287 29.185 13.8014 29.0084 16.6208L24.523 89.819C24.3464 92.6384 25.3 95.352 27.1719 97.4666C29.0437 99.5811 31.6219 100.815 34.4474 100.991L164.21 109.167C164.422 109.167 164.634 109.202 164.846 109.202C170.39 109.202 175.052 104.867 175.406 99.2992L179.856 26.101C180.032 23.2816 179.079 20.5679 177.207 18.4534C175.335 16.3389 172.757 15.1054 169.931 14.9292L40.1689 6.71772C39.957 6.71772 39.7451 6.71772 39.5332 6.71772Z"
      fill="#96A7C1"
    />
    <path
      d="M13.9165 20.4343L13.9168 20.4343L170.812 20.392C170.812 20.392 170.812 20.392 170.812 20.392C177.849 20.3922 183.53 26.0707 183.53 33.1022V85.0912C183.53 92.1228 177.849 97.8014 170.812 97.8014H13.9165C6.87938 97.8014 1.19863 92.1228 1.19863 85.0912V33.1445C1.19863 26.1128 6.87938 20.4343 13.9165 20.4343Z"
      fill="white"
      stroke="#9BABC2"
      strokeWidth="2.39726"
    />
    <path
      d="M111.826 53.8401H66.7897C63.9726 53.8401 61.6744 51.1771 61.6744 47.9127C61.6744 44.6484 63.9726 41.9854 66.7897 41.9854H111.826C114.643 41.9854 116.941 44.6484 116.941 47.9127C116.904 51.22 114.643 53.8401 111.826 53.8401Z"
      fill="#BECFE8"
    />
    <path
      d="M164.35 75.5735H66.7897C63.9726 75.5735 61.6744 72.9105 61.6744 69.6461C61.6744 66.3818 63.9726 63.7188 66.7897 63.7188H164.35C167.167 63.7188 169.465 66.3818 169.465 69.6461C169.465 72.9105 167.167 75.5735 164.35 75.5735Z"
      fill="#BECFE8"
    />
    <ellipse
      cx="30.5349"
      cy="58.7792"
      rx="15.5698"
      ry="15.5946"
      fill="#BECFE8"
    />
    <circle cx="173.5" cy="22.5" r="22.5" fill="#9BABC2" />
    <path
      d="M169.993 22.4562C169.993 20.663 170.256 19.1336 170.915 18.1051C171.443 17.2613 172.26 16.7075 173.5 16.7075C174.765 16.7075 175.53 17.2613 176.058 18.1051C176.743 19.1336 177.007 20.663 177.007 22.4562C177.007 24.2493 176.743 25.7788 176.058 26.8072C175.53 27.651 174.765 28.2048 173.5 28.2048C172.26 28.2048 171.443 27.651 170.915 26.8072C170.256 25.7788 169.993 24.2493 169.993 22.4562ZM166.353 22.4562C166.353 24.6449 166.696 26.7808 167.857 28.5476C168.99 30.2353 170.836 31.4219 173.5 31.4219C176.163 31.4219 178.009 30.2353 179.116 28.5476C180.277 26.7808 180.646 24.6449 180.646 22.4562C180.646 20.2675 180.277 18.1315 179.116 16.3647C178.009 14.6771 176.163 13.4904 173.5 13.4904C170.836 13.4904 168.99 14.6771 167.857 16.3647C166.696 18.1315 166.353 20.2675 166.353 22.4562Z"
      fill="white"
    />
  </svg>
);

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 50px;
  width: 100%;
`;

const Title = styled.h1`
  color: ${blue};
  font-size: 32px;
  line-height: 42px;
`;
Title.displayName = 'Title';

const Content = styled.div`
  width: 346px;
  height: 102px;
  left: 928px;
  top: 545px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.0015em;
  color: ${trout};
  padding-top: 20px;
`;
Content.displayName = 'Content';
