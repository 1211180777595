import { AxiosError } from 'axios';

export enum HelpPageTypeKeys {
  ENTITY = '[HelpPage]',

  SUBMIT_SUPPORT_REQUEST = 'helpPage/SUBMIT_SUPPORT_REQUEST',
  SUCCESS = 'helpPage/SUCCESS',
  ERROR = 'helpPage/ERROR',
  RESET = 'helpPage/RESET',
}

export interface IHelpPageState {
  loading: boolean;
  success: boolean;
  error: AxiosError | string;
}

export interface IHelpPageForm {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  phone: string;
  message: string;
}
