import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || 'currentColor'}
      d="M22.8008 1.06055L15.1602 0.947266L18.0703 3.74023L9.60547 11.8633L11.543 13.7246L20.0078 5.60156L22.918 8.39258L22.8008 1.06055ZM18.1797 19.5332V11.1152L20.6992 8.69922V20.9492C20.6992 21.5 20.25 21.9492 19.6992 21.9492H1.91797C1.36719 21.9492 0.917969 21.5 0.917969 20.9492V3.9668C0.917969 3.41406 1.36719 2.9668 1.91797 2.9668H14.4219L11.9062 5.38281H3.43359V19.5332H18.1797Z"
    />
  </svg>
);
