import React from 'react';
import styled from 'styled-components';
import { primaryBlue8 } from 'config/colors';
import { H6_BOLD } from 'config/typography';

interface IStyledHeadingProps {
  cf?: boolean;
  color?: string;
}

const colorSelector = ({ cf, color }) => color || (cf ? primaryBlue8 : '#000');

const h1 = styled.h1<IStyledHeadingProps>`
  color: ${colorSelector as any};
  font-weight: 500;
`;
const h2 = styled.h2<IStyledHeadingProps>`
  color: ${colorSelector as any};
  font-weight: 500;
`;
const h3 = styled.h3<IStyledHeadingProps>`
  color: ${colorSelector as any};
  font-weight: 500;
`;
const h4 = styled.h4<IStyledHeadingProps>`
  color: ${colorSelector as any};
  font-weight: 500;
`;
const h5 = styled.h5<IStyledHeadingProps>`
  color: ${colorSelector as any};
  font-weight: 500;
`;
const h6 = styled.h6<IStyledHeadingProps>`
  color: ${colorSelector as any};
  ${H6_BOLD}
`;

const styledHeadings = [h1, h2, h3, h4, h5, h6];

interface IHeadingProps extends Record<string, any> {
  /**
   * This is a shorthand for the ClarityFirst blue color (#345580)
   */
  cf?: boolean;
  /**
   * The child content inside of our Header elements
   */
  children: React.ReactNode;
  /**
   * A string color we can apply directly to CSS, any value that is valid for
   * color in CSS can work.
   */
  color?: string;
  /**
   * Used to select a component between h1, h2, ..., h6
   */
  level: 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * Unique identifier for the element
   */
  domIdPrefix?: string;
}

export default function Heading({
  cf,
  children,
  color,
  level,
  domIdPrefix,
  ...props
}: IHeadingProps) {
  const Component = styledHeadings[level - 1];
  return (
    <Component
      id={domIdPrefix ? `${domIdPrefix}-heading` : 'heading'}
      cf={cf}
      color={color}
      {...props}
    >
      {children}
    </Component>
  );
}
