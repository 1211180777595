import AppInsights from 'appInsights';

// Objective of these utility functions is to modify
// as needed to add additional telemetry data without
// refactoring individual files.

export const initializeAI = () => {
  if (!AppConfig.appinsightsInstrumentationkey) return;
  AppInsights.loadAppInsights();
};

export const trackPageView = (properties: { [name: string]: any }) => {
  try {
    AppInsights.trackPageView(properties);
  } catch (e) {
    return null;
  }
};

export const setAuthenticatedUserContext = (
  authenticatedUserId: string,
  accountId?: string,
  storeInCookie?: boolean
) => {
  try {
    AppInsights.setAuthenticatedUserContext(
      authenticatedUserId,
      accountId,
      storeInCookie
    );
  } catch (e) {
    return null;
  }
};

export const trackEvent = (
  name: string,
  properties?: { [name: string]: any },
  measurements?: { [name: string]: any }
) => {
  try {
    AppInsights.trackEvent({ name, properties, measurements });
  } catch (e) {
    return null;
  }
};

export const trackExceptions = (
  error: Error,
  properties?: { [name: string]: any }
) => {
  try {
    AppInsights.trackException({ exception: error, properties });
  } catch (e) {
    return null;
  }
};

export const getCorrelationContext = () => {
  try {
    const context = AppInsights.context;
    return context;
  } catch (e) {
    return null;
  }
};
