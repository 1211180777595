import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="68"
    height="60"
    viewBox="0 0 68 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <rect
      x="23.6387"
      y="1.32812"
      width="43.1716"
      height="43.1672"
      rx="1.67523"
      fill="#CED6E2"
      fillOpacity="0.2"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeDasharray="1.68 1.68"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8486 20.108C51.2347 20.719 50.2367 20.719 49.6228 20.108L47.1938 17.6938V31.9101C47.1938 32.9905 46.3131 33.8659 45.2261 33.8659C44.1392 33.8659 43.2584 32.9905 43.2584 31.9101V17.6938L40.8295 20.108C40.2156 20.719 39.2175 20.719 38.6036 20.108C37.9889 19.497 37.9889 18.5066 38.6036 17.8956L44.1132 12.4194C44.4201 12.1135 44.8231 11.9609 45.2261 11.9609C45.6291 11.9609 46.0321 12.1135 46.3391 12.4194L51.8486 17.8956C52.4633 18.5066 52.4633 19.497 51.8486 20.108Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.6764 20.1683L44.1112 14.7663C44.419 14.4605 44.822 14.3079 45.2242 14.3079C45.6272 14.3079 46.0309 14.4605 46.3371 14.7663L51.7727 20.1683C51.7971 20.1471 51.8238 20.1307 51.8467 20.108C52.4622 19.497 52.4622 18.5066 51.8467 17.8956L46.3371 12.4194C46.0309 12.1135 45.6272 11.9609 45.2242 11.9609C44.822 11.9609 44.419 12.1135 44.1112 12.4194L38.6017 17.8956C37.9869 18.5066 37.9869 19.497 38.6017 20.108C38.6253 20.1307 38.6528 20.1471 38.6764 20.1683Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8486 20.108C51.2347 20.719 50.2367 20.719 49.6228 20.108L47.1938 17.6938V31.9101C47.1938 32.9905 46.3131 33.8659 45.2261 33.8659C44.1392 33.8659 43.2584 32.9905 43.2584 31.9101V17.6938L40.8295 20.108C40.2156 20.719 39.2175 20.719 38.6036 20.108C37.9889 19.497 37.9889 18.5066 38.6036 17.8956L44.1132 12.4194C44.4201 12.1135 44.8231 11.9609 45.2261 11.9609C45.6291 11.9609 46.0321 12.1135 46.3391 12.4194L51.8486 17.8956C52.4633 18.5066 52.4633 19.497 51.8486 20.108Z"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.2442 48.4187C38.5249 49.458 37.9057 50.5293 36.8579 50.8083L11.4071 57.5872C10.3572 57.8662 9.28158 57.2486 9.00303 56.2071L0.877974 26.0652C0.597282 25.0259 1.21866 23.9567 2.26429 23.6778L19.8643 18.9902L32.2233 26.0822L38.2442 48.4187Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.2442 48.4187C38.5249 49.458 37.9057 50.5293 36.8579 50.8083L11.4071 57.5872C10.3572 57.8662 9.28158 57.2486 9.00303 56.2071L0.877974 26.0652C0.597282 25.0259 1.21866 23.9567 2.26429 23.6778L19.8643 18.9902L32.2233 26.0822L38.2442 48.4187Z"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8633 18.9902L22.4752 28.6783L32.2244 26.0822L19.8633 18.9902Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8633 18.9902L22.4752 28.6783L32.2244 26.0822L19.8633 18.9902Z"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.85547 32.5709L17.6025 29.9727"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16016 37.4166L27.8422 32.4395"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4668 42.2641L29.1489 37.2891"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7715 47.1059L30.4557 42.1309"
      stroke="#9BABC2"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.6397 56.5304C37.4956 56.9416 36.9277 56.9765 36.7343 56.5861L31.334 45.6837C31.1394 45.2909 31.5169 44.8588 31.9323 44.999L43.7904 49.0009C44.2058 49.1411 44.2443 49.7136 43.8514 49.9082L39.3848 52.1207L39.0997 52.3632L37.6397 56.5304Z"
      fill="#9BABC2"
    />
    <path
      d="M44.5686 54.2111C44.773 54.3897 44.7942 54.7001 44.616 54.9048L42.9357 56.8358C42.758 57.04 42.4488 57.0623 42.2436 56.8858L34.3192 50.0697L36.7245 47.3588L44.5686 54.2111Z"
      fill="#9BABC2"
    />
    <path
      d="M38.0829 49.7423L36.8945 51.1152L42.3861 55.8678L43.5745 54.4949L38.0829 49.7423Z"
      fill="#E6EDF7"
    />
    <path
      d="M32.5898 46.166L37.1024 55.2762L38.3742 51.7085L38.6593 51.466L42.5638 49.532L32.5898 46.166Z"
      fill="#E6EDF7"
    />
  </svg>
);
