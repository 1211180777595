import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { spindle, white, heavyShadow } from 'config/theme';
import { IconButton } from './LinkIcon';
import {
  ChevronDownIcon,
  InfoFilledIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import styled from 'styled-components';
import ResourcesDropdown from './ResourcesDropdown';
import { UserType } from 'store/auth/types';

interface IResourcesDropdownState {
  resourcesDropdownHidden: boolean;
}

interface IResourcesDropdownProps {
  userType: UserType;
}

const ESCAPE_KEY_CODE = 27;

export class ResourcesNav extends React.Component<
  IResourcesDropdownProps,
  IResourcesDropdownState
> {
  public state = {
    resourcesDropdownHidden: true,
  };

  public render() {
    const { resourcesDropdownHidden } = this.state;

    return (
      <ResourceContainer
        onKeyDown={this.handleOnKeyDown}
        aria-labelledby="resources-dropdown-menu"
        id="dashboard__resource_icon"
      >
        <DropdownHandle
          onClick={this.toggleResourcesDropdown}
          aria-haspopup="true"
          aria-expanded={!resourcesDropdownHidden}
          aria-controls="dropdown-menu-list"
          tabIndex={0}
          id="dashboard__resource_dropdown_icon"
          role="menuitem"
        >
          <IconButton
            icon={
              <InfoFilledIcon
                style={{ height: '18px', width: '18px', marginLeft: '-12px' }}
                fill={'currentColor'}
              />
            }
            label={
              <React.Fragment>
                Resources <ChevronDownIcon style={{ marginLeft: '6px' }} />
              </React.Fragment>
            }
          />
        </DropdownHandle>

        {!resourcesDropdownHidden && (
          <ResourcesDropdownContainer>
            <ResourcesDropdown
              onResourceClick={this.closeResourcesDropdown}
              userType={this.props.userType}
            />
          </ResourcesDropdownContainer>
        )}
      </ResourceContainer>
    );
  }

  private toggleResourcesDropdown = () => {
    this.setState({
      resourcesDropdownHidden: !this.state.resourcesDropdownHidden,
    });
  };

  private closeResourcesDropdown = () => {
    this.setState({ resourcesDropdownHidden: true });
  };

  private handleOnKeyDown = (e) => {
    if (e.keyCode === ESCAPE_KEY_CODE) {
      this.closeResourcesDropdown();
    }
  };

  // @ts-ignore /* tslint:disable:no-unused-variable */
  private handleClickOutside = () => {
    this.closeResourcesDropdown();
  };
}

export default enhanceWithClickOutside(ResourcesNav);

const ResourceContainer = styled.div`
  margin-right: 20px;
  position: relative;
`;

const DropdownHandle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${spindle};
  cursor: pointer;
  position: relative;
  &:hover {
    color: ${white};
    fill: ${white};
  }
`;

const ResourcesDropdownContainer = styled.div`
  margin: 0;
  padding: 0;
  background: ${white};
  border-radius: 3px;
  box-shadow: ${heavyShadow};
  position: absolute;
  right: -18px;
  text-align: left;
  top: 56px;
  width: 414px;
  z-index: 10;

  &:after,
  &:before {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 12px;
    margin-left: -12px;
  }
`;
ResourcesDropdownContainer.displayName = 'ResourcesDropdownContainer';
