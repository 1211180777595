import {
  IPropertyDetails,
  IPropertyFilters,
  IFarmShape,
} from 'store/propertySearch/types';

export enum PropertyFarmingTypeKeys {
  ENTITY = '[propertyFarming]',
  // Generic actions used to update current request state
  REQUEST = 'propertyFarming/REQUEST',
  SUCCESS = 'propertyFarming/SUCCESS',
  ERROR = 'propertyFarming/ERROR',
  // Store different types of data received via XHR
  SET_FARMS = 'propertyFarming/SET_FARMS',
  SET_SELECTED_FARM_DETAILS = 'propertyFarming/SET_SELECTED_FARM_DETAILS',
  SET_FARM_SHAPES = 'propertyFarming/SET_FARM_SHAPES',
  SET_FARM_CHECKED_PROPERTY_IDS = 'propertyFarming/SET_FARM_CHECKED_PROPERTY_IDS',
}

export interface IPropertyFarmingState {
  loading: boolean;
  error: Error | null;
  farms: IFarm[];
  selectedFarmDetails: IFarm | null;
  farmShapes: IFarmShape[];
  farmCheckedPropertyIds: string[];
}

export type PropertyFarmingActions =
  | ISuccessAction
  | IErrorAction
  | IRequestAction
  | ISetFarmsAction
  | ISelectedFarmDetailsAction
  | IFarmShapesAction
  | ISetFarmCheckedPropertyIds;

interface ISuccessAction {
  type: PropertyFarmingTypeKeys.SUCCESS;
}

interface IErrorAction {
  type: PropertyFarmingTypeKeys.ERROR;
  error: Error;
}

interface IRequestAction {
  type: PropertyFarmingTypeKeys.REQUEST;
}

export interface ISetFarmsAction {
  type: PropertyFarmingTypeKeys.SET_FARMS;
  farms: IFarm[];
}

export interface ISelectedFarmDetailsAction {
  type: PropertyFarmingTypeKeys.SET_SELECTED_FARM_DETAILS;
  farmDetails: IFarm | null;
}

export interface IFarmShapesAction {
  type: PropertyFarmingTypeKeys.SET_FARM_SHAPES;
  farmShapes: IFarmShape[];
}

export interface ISetFarmCheckedPropertyIds {
  type: PropertyFarmingTypeKeys.SET_FARM_CHECKED_PROPERTY_IDS;
  farmCheckedPropertyIds: string[];
}

export interface IFarmProperties {
  propertyId: string;
  sequenceNo: string;
  isSelected: boolean;
}

export interface IFarm {
  id?: number;
  farmName: string;
  updatedDate?: Date;
  farmPropertiesCount?: number;
  propertyDetails?: IPropertyDetails[];
  farmProperties?: IFarmProperties[] | null;
  isEditFarmMode?: boolean;
  farmShapes?: IFarmShape[] | null;
  filters?: IPropertyFilters | null;
}
