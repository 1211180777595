interface IInGroupsOfResultMeta {
  total: number;
  pages: number;
  limit: number;
}

interface IInGroupsOfResult {
  data: any[][];
  meta: IInGroupsOfResultMeta;
}

export const inGroupsOf = (
  collection: any[],
  limit: number = 5
): IInGroupsOfResult => {
  const inGroups: any = [];
  const clonedCollection = collection.slice(0);

  while (clonedCollection.length) {
    inGroups.push(clonedCollection.splice(0, limit));
  }

  return {
    data: inGroups,
    meta: {
      total: collection.length,
      pages: Math.ceil(collection.length / limit),
      limit,
    },
  };
};

export function moveItemToFront(
  array: Array<string | number>,
  item: string | number
) {
  const elIidx = array.indexOf(item);

  if (elIidx > 0) {
    const clonedArray = array.slice(0);
    clonedArray.splice(elIidx, 1);
    clonedArray.unshift(item);

    return clonedArray;
  } else {
    return array;
  }
}

// deprecate since i'ts weakly typed and only support string/string serializable values to identify uniqueness
export function removeDuplicates(arr: object[], prop: string): object[] {
  const obj = {};
  return Object.keys(
    arr.reduce((_, next) => {
      if (!obj[next[prop]]) {
        obj[next[prop]] = next;
      }

      return obj;
    }, obj)
  ).map((i) => obj[i]);
}

/**
 * @description Returns an array of T removing duplicate entries, discriminated by a given value
 * @param arr The array whose unique values we want to extract
 * @param keySelector A string representing a property of T which will be used to determine the unique values
 * Or a function that receives a T and returns a value which will determine uniqueness.
 */
export function uniqueValues<T, K extends keyof T>(arr: T[], key: K): T[];
export function uniqueValues<T, K>(arr: T[], keySelector: (value: T) => K): T[];
// eslint-disable-next-line import/no-unused-modules
export function uniqueValues<T, K>(arr: T[], keySelector: (value: T) => K) {
  let keyValues: Array<[K, T]>;
  if (typeof keySelector === 'string') {
    keyValues = arr.map((x) => [x[keySelector], x]);
  } else {
    keyValues = arr.map((x) => [keySelector(x), x]);
  }
  const result: T[] = [];
  new Map(keyValues).forEach((x) => result.push(x));
  return result;
}

export function getMostCommonElement(arr: Array<string | null>) {
  const counts = arr.reduce((acc, curr) => {
    if (curr !== null) {
      if (curr in acc) {
        acc[curr]++;
      } else {
        acc[curr] = 1;
      }
    }
    return acc;
  }, {});

  const keys = Object.keys(counts);
  const isEmpty = !keys.length;
  return !isEmpty && keys.reduce((a, b) => (counts[a] > counts[b] ? a : b));
}

export function groupBy(objectArray, property, allKeys) {
  const grouped: any[] = [];

  Object.keys(allKeys).forEach((key) => {
    if (!grouped[allKeys[key]]) {
      grouped[allKeys[key]] = [];
    }
  });

  objectArray.forEach((obj) => {
    const key = obj[property];
    if (grouped[key]) {
      grouped[key].push(obj);
    }
  });
  return grouped;
}

/**
 * Creates a sequence of n consecutive integer numbers.
 * @param n the amount of numbers to generate.
 * @param start where to start generating numbers.
 * @returns An array containing the sequence.
 * For example range(5, 3) will return [5, 6, 7].
 */
export const range = (n: number, start = 0) =>
  Array.from({ length: n }, (_x, i) => i + start);

interface ISequence {
  sequenceNumber: number;
}
export const sequenceSorter = (a: ISequence, b: ISequence) =>
  a.sequenceNumber - b.sequenceNumber;

export const arrayCompare = (array1: number[], array2: number[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  array1.sort((a, b) => a - b);
  array2.sort((a, b) => a - b);
  for (const id in array1) {
    if (array1[id] !== array2[id]) {
      return false;
    }
  }
  return true;
};

export const safeFilterJoin = (
  array: Array<string | null | undefined>,
  separator = ', '
): string =>
  array
    .map((s) => s?.trim())
    .filter((n) => !!n)
    .join(separator);

export const moveItemToPosition = <T>(
  array: T[],
  oldPosition: number,
  newPosition: number
) => {
  const newData = [...array];
  const [originalElement] = newData.splice(newPosition, 1);
  newData.splice(oldPosition, 0, originalElement);
  return newData;
};

/**
 * Map an array into an object
 * @param array the array that will be mapped into an object
 * @param key the property we are going to get the value to be our map key.
 * @returns An object containing N properties, each property name will be item[key] and value will be the item.
 * For example, input array is `[{ name: 'John', id: 1}, { name: 'Mary', id: 5 }]`
 * if we pass `key = 'name'` the output will be: `{ John: { name: 'John', id: 1 }, Mary: { name: 'Mary', id: 5 }}`
 */
export const mapByKey = <T>(array: T[], key: keyof T): { [key: string]: T } =>
  array.reduce((acc, current) => {
    acc = { ...acc, [current[key] as any]: current };
    return acc;
  }, {});

export const sortByStringField = <T>(a: T, b: T, field: string) => {
  if (a[field]! === b[field]!) {
    return 0;
  }

  return (a[field]?.toLowerCase() || '') > (b[field]?.toLowerCase() || '')
    ? 1
    : -1;
};
