import React from 'react';
import Icon from 'react-icon-base';

const BiCheckCircle = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
    </g>
  </Icon>
);

export default BiCheckCircle;
