/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_GroupComponentFeature } from '../models/Workspace_Common_Models_GroupComponentFeature';
import type { Workspace_Common_Responses_Component } from '../models/Workspace_Common_Responses_Component';
import type { Workspace_Common_Responses_ComponentsList } from '../models/Workspace_Common_Responses_ComponentsList';
import type { Workspace_Common_Responses_Feature } from '../models/Workspace_Common_Responses_Feature';
import type { Workspace_Common_Responses_FeatureStatus } from '../models/Workspace_Common_Responses_FeatureStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ComponentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all components (Auth)
   * @returns Workspace_Common_Responses_ComponentsList Components successfully retrieved
   * @throws ApiError
   */
  public getComponents(): CancelablePromise<Workspace_Common_Responses_ComponentsList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/components',
      errors: {
        401: `Invalid Authorization header`,
        403: `User doesn't have the required Admin activity right`,
      },
    });
  }

  /**
   * Get a component by ID. The response includes a list of all associated features (Auth)
   * @returns Workspace_Common_Responses_Component Component successfully retrieved
   * @throws ApiError
   */
  public getComponent({
    id,
  }: {
    /**
     * a component Id
     */
    id: number,
  }): CancelablePromise<Workspace_Common_Responses_Component> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/components/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User doesn't have the required Admin activity right`,
        404: `Component does not exist`,
      },
    });
  }

  /**
   * Get a feature by ID. The response includes a list of all associated groups (Auth)
   * @returns Workspace_Common_Responses_Feature Feature successfully retrieved
   * @throws ApiError
   */
  public getFeature({
    componentId,
    featureId,
  }: {
    /**
     * a component Id
     */
    componentId: number,
    /**
     * a feature Id
     */
    featureId: number,
  }): CancelablePromise<Workspace_Common_Responses_Feature> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/components/{componentId}/features/{featureId}',
      path: {
        'componentId': componentId,
        'featureId': featureId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User doesn't have the required Admin activity right`,
        404: `Feature does not exist`,
      },
    });
  }

  /**
   * Retrieve all features for the given component, those that are enabled for the given group will be marked as such in the `isEnabled` property (Auth)
   * @returns Workspace_Common_Models_GroupComponentFeature Features successfully retrieved
   * @throws ApiError
   */
  public getGroupFeatures({
    componentId,
    groupId,
  }: {
    /**
     * a component Id
     */
    componentId: number,
    /**
     * a group Id
     */
    groupId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_GroupComponentFeature>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/components/{componentId}/groups/{groupId}/features',
      path: {
        'componentId': componentId,
        'groupId': groupId,
      },
      errors: {
        401: `Unauthorized`,
        403: `User doesn't have the required Admin activity right`,
      },
    });
  }

  /**
   * Enable/Disable feature for group (Auth)
   * @returns Workspace_Common_Responses_FeatureStatus Feature successfully enabled/disabled for group
   * @throws ApiError
   */
  public toggleFeatureForGroup({
    componentId,
    featureId,
    groupId,
  }: {
    /**
     * a component Id
     */
    componentId: number,
    /**
     * a feature Id
     */
    featureId: number,
    /**
     * a group Id
     */
    groupId: number,
  }): CancelablePromise<Workspace_Common_Responses_FeatureStatus> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/components/{componentId}/features/{featureId}/groups/{groupId}/toggle',
      path: {
        'componentId': componentId,
        'featureId': featureId,
        'groupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User doesn't have the required Admin activity right`,
        404: `Feature does not exist or Group does not exist or Group does not have this Feature`,
      },
    });
  }

}
