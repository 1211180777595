import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

// Like the default check, but slightly wider checkmark, used in toasters
export default (props: ISVGProps) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M21.6622 10.5115L19.2969 7.80759L19.6265 4.22836L16.1271 3.43081L14.2949 0.337891L10.9991 1.75791L7.70319 0.337891L5.87107 3.43081L2.37162 4.21863L2.70121 7.79786L0.335938 10.5115L2.70121 13.2153L2.37162 16.8043L5.87107 17.6018L7.70319 20.6948L10.9991 19.265L14.2949 20.685L16.1271 17.5921L19.6265 16.7946L19.2969 13.2153L21.6622 10.5115ZM11.9684 15.3745H10.0297V13.4293H11.9684V15.3745ZM11.9684 11.4841H10.0297V5.64838H11.9684V11.4841Z"
      fill={props.fill || white}
    />
  </svg>
);
