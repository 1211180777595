import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ApiRedirect from 'components/ApiRedirect';
import { History } from 'history';
import { store } from 'store';
import * as authUtils from 'utils/auth';
import ErrorPage, {
  ErrorPageWithGraphic,
  UnknownErrorGraphic,
  ErrorCode,
} from 'components/ErrorPage';
import Button, { ButtonSize } from 'components/ui/Button';
import ErrorIcon from 'components/svg/Error';
import ShadowIcon from 'components/svg/Shadow';
import AccessDeniedBadge from 'components/svg/AccessDeniedBadge';
import { blue, black, lightBlue, trout } from 'config/theme';
import { WiresIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import {
  redirectToSignIn,
  redirectToSignInExternal,
  updateSession,
} from 'store/auth/actions';

function errorDescriptionForCode(code: string | null) {
  switch (code) {
    // AADB2C90091: The user has cancelled entering self-asserted information.
    case 'AADB2C90091':
      return (
        <>
          Looks like you've cancelled the authentication process.
          <br />
          Please try again in order to proceed with the task.
        </>
      );
    // CFCDU001: Failed to retrieve external uploads
    case 'CFCDU001':
      return (
        <>
          We're working on a fix and appreciate your patience. If this matter is
          urgent,
          <br />
          please contact us at (833) 627-2255.
        </>
      );
    default:
      return (
        <>
          We're working on a fix and appreciate your patience. If this matter is
          urgent,
          <br />
          please contact us at <strong>(833) 627-2255.</strong>
        </>
      );
  }
}

/*****************************************************************************
 * InternalError
 *****************************************************************************/
const InternalError = ({ location }: RouteComponentProps) => {
  authUtils.clearAfterLoginRedirectPath();

  const queryParams = new URLSearchParams(location.search.substring(1));
  const code = queryParams.get('code');
  const description = errorDescriptionForCode(code);

  // AADB2C90091: The user has cancelled entering self-asserted information.
  if (code === 'AADB2C90091') {
    return (
      <ApiRedirect to={`${AppConfig.workspaceBackendUrl}/external/signin`} />
    );
  }

  return (
    <ErrorPage badge={<UnknownErrorGraphic />} style={{ maxWidth: 790 }}>
      <ErrorHeader>Oops. Something went wrong here.</ErrorHeader>
      <ErrorDescription>{description}</ErrorDescription>
      {code !== 'CFCDU001' && (
        <Button onClick={() => window.location.reload()} size={ButtonSize.XL}>
          Try Again
        </Button>
      )}
      <Message>
        <strong>Error Code:</strong> {ErrorCode.UNKNOWN_ERROR}
      </Message>
    </ErrorPage>
  );
};

const InternalErrorWithRouter = withRouter(InternalError);
export { InternalErrorWithRouter as InternalError };

/*****************************************************************************
 * AcccountDeactivated
 *****************************************************************************/
export function AccountDeactivated() {
  return (
    <ErrorPageWithGraphic
      showCode={false}
      statusCode={ErrorCode.ACCOUNT_DEACTIVATED}
      title="Oops. Something went wrong."
      titleSize="32px"
      description={
        <>
          This account has been deactivated. If you believe this has been done
          in error, call ClarityFirst™ Support at 833.627.2255.
        </>
      }
    />
  );
}

/*****************************************************************************
 * SecurityError
 *****************************************************************************/
const SecurityError = ({ location }: RouteComponentProps) => {
  const queryParams = new URLSearchParams(location.search.substring(1));
  const isExternal = queryParams.get('external') === 'true';
  const handleSecurityCTA = () => {
    // resetting the user session
    store.dispatch(updateSession(null));
    if (isExternal) {
      redirectToSignInExternal();
    } else {
      redirectToSignIn();
    }
  };

  return (
    <ErrorPage
      badge={<UnknownErrorGraphic />}
      style={{ maxWidth: 790, paddingTop: '90px' }}
    >
      <ErrorHeader>Oops. Something went wrong.</ErrorHeader>
      <ErrorDescription>
        For security reasons you have been signed out.{' '}
        <a onClick={handleSecurityCTA}>Please sign in again</a>.
        <br />
        For further assistance, call ClarityFirst™ Support at{' '}
        <strong>833.627.2255</strong>.
      </ErrorDescription>
      <Message>Error Code: 417</Message>
      <Button onClick={handleSecurityCTA} size={ButtonSize.XL}>
        Sign In Again
      </Button>
    </ErrorPage>
  );
};

const SecurityErrorWithRouter = withRouter(SecurityError);
export { SecurityErrorWithRouter as SecurityError };

/*****************************************************************************
 * Unauthorized
 *****************************************************************************/
interface IUnauthorizedProps {
  history: History;
  errorMessage?: React.ReactNode | string;
}

const Unauthorized = ({ history, errorMessage }: IUnauthorizedProps) => (
  <ErrorPage badge={<AccessDeniedBadge />} title="Access Denied">
    Looks like you might not have sufficient access permissions.
    <br />
    Please contact your FAST administrator for additional assistance.
    <br />
    <Button onClick={history.goBack}>Go Back</Button>
    <br />
    {errorMessage && (
      <Message>
        <strong>Error Code:</strong> {errorMessage}
      </Message>
    )}
  </ErrorPage>
);

const UnauthorizedWithRouter = withRouter<
  RouteComponentProps & IUnauthorizedProps,
  any
>(Unauthorized);
export { UnauthorizedWithRouter as Unauthorized };

/*****************************************************************************
 * WireActivityError
 *****************************************************************************/
export const WireActivityError = () => (
  <ErrorPage
    badge={
      <WireActivityErrorBadge>
        <ErrorIcon />
        <ShadowIcon style={{ marginTop: 9 }} />
      </WireActivityErrorBadge>
    }
  >
    <ErrorHeader>Oops! Something went wrong.</ErrorHeader>
    <ErrorDescription>
      We're having trouble loading wire instruction activity
      <br />
      right now, please try again shortly. If this matter is
      <br />
      urgent, you can&nbsp;
      <a href="mailto:ClarityFirst.Support@firstam.com" target="_top">
        email technical support
      </a>
      &nbsp;or call the
      <br />
      support line at <strong>(833) 627-2255</strong>.
    </ErrorDescription>
  </ErrorPage>
);

/*****************************************************************************
 * WireNotFoundError
 *****************************************************************************/
export const WireNotFoundError = () => (
  <ErrorPage badge={<WiresIcon />} style={{ maxWidth: 790 }}>
    <ErrorHeader>Wire Instructions not available</ErrorHeader>
    <ErrorDescription>
      It looks like this set of wire instructions was either removed or does not
      exist for this order. Please reach out
      <br /> to your First American NCS escrow officer or contact support
      at&nbsp;
      <strong>(833) 627-2255</strong>.
    </ErrorDescription>
    <Message>
      <strong>Error Code:</strong> 404
    </Message>
    <Button to={'/dashboard'} size={ButtonSize.LG}>
      Go to Dashboard
    </Button>
  </ErrorPage>
);

/*****************************************************************************
 * WireInstructionActionCompleted
 *****************************************************************************/
export const WireInstructionActionCompleted = () => (
  <ErrorPage badge={<WiresIcon />} style={{ maxWidth: 790 }}>
    <ErrorHeader>Wire instructions actions already completed</ErrorHeader>
    <ErrorDescription>
      It looks like the necessary actions for these wire instructions have
      already been completed. If you believe this
      <br />
      is an error, please reach out to your First American NCS escrow officer or
      contact support at <strong>833.627.2255</strong>.
    </ErrorDescription>
    <Message>
      <strong>Error Code:</strong> 204
    </Message>
    <Button to={'/dashboard'} size={ButtonSize.LG}>
      Go to Dashboard
    </Button>
  </ErrorPage>
);

/*****************************************************************************
 * Order1099SActionCompleted
 *****************************************************************************/
export const Order1099sActionCompleted = () => (
  <ErrorPage badge={<WiresIcon />} style={{ maxWidth: 790 }}>
    <ErrorHeader>
      1099-S Information request actions already completed
    </ErrorHeader>
    <ErrorDescription>
      It looks like you already completed necessary actions for this 1099-S
      Request. If you believe this is an
      <br />
      error, please reach out to your First American NCS escrow officer or
      contact support at <strong>(833) 627-2255</strong>.
    </ErrorDescription>
    <Message>
      <strong>Error Code:</strong> 410
    </Message>
    <Button to={'/dashboard'} size={ButtonSize.LG}>
      Go to Dashboard
    </Button>
  </ErrorPage>
);

const WireActivityErrorBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorHeader = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 37px;
  color: ${blue};
  padding: 30px 0 12px 0;
`;

const ErrorDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${black};
  text-align: center;
  > a {
    color: ${lightBlue};
  }
  > strong {
    font-weight: 600;
  }
`;

const Message = styled.div`
  color: ${trout};
  font-size: 12px;
  margin-top: 20px;
`;
