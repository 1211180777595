import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || neutralGray7}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12ZM10.67 13.02C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.47 18.87 10 17.49 10 16C10 14.93 10.25 13.93 10.67 13.02ZM20.6937 15.3963C20.7224 15.5968 20.7499 15.7892 20.7499 16C20.7499 16.2108 20.7224 16.4032 20.6937 16.6037L20.6899 16.63L21.8299 17.64L20.8299 19.37L19.3799 18.88C19.0599 19.15 18.6999 19.36 18.2999 19.51L17.9999 21H15.9999L15.6999 19.51C15.2999 19.36 14.9399 19.15 14.6199 18.88L13.1699 19.37L12.1699 17.64L13.3099 16.63L13.3062 16.6037C13.2775 16.4033 13.2499 16.2108 13.2499 16C13.2499 15.7892 13.2775 15.5967 13.3062 15.3963L13.3099 15.37L12.1699 14.36L13.1699 12.63L14.6199 13.12C14.9399 12.85 15.2999 12.64 15.6999 12.49L15.9999 11H17.9999L18.2999 12.49C18.6999 12.64 19.0599 12.85 19.3799 13.12L20.8299 12.63L21.8299 14.36L20.6899 15.37L20.6937 15.3963ZM14.9999 16C14.9999 17.1 15.8999 18 16.9999 18C18.0999 18 18.9999 17.1 18.9999 16C18.9999 14.9 18.0999 14 16.9999 14C15.8999 14 14.9999 14.9 14.9999 16Z"
    />
  </svg>
);
