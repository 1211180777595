import React from 'react';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'store';
import { isInternalUser } from 'store/auth/getters';
import { SuperSymbols } from 'views/Resources/Common';

const addPeriodTo = (value?: string): string => {
  let result = '';
  if (value) {
    result = value.trim();
    if (!result.endsWith('.')) {
      result += '.';
    }
  }
  return result;
};

export const getServiceDeskErrorMessage = (prefix: string): string => {
  return `${addPeriodTo(
    prefix
  )} Please try again or contact the IT Service Desk at ${
    AppConfig.serviceDeskPhoneNumber
  } option ${
    AppConfig.serviceDeskPhoneOption
  } or via the ServiceFirst website.`;
};

export const ContactServiceDeskErrorMessage = ({
  messagePrefix,
  callToAction = 'Please try again or',
}: {
  messagePrefix?: string;
  callToAction?: React.ReactNode;
}) => {
  const message = addPeriodTo(messagePrefix);
  const auth = useSelector((state: IApplicationState) => state.auth);
  const isInternal = isInternalUser(auth);

  return (
    <span>
      {`${message} `}
      {isInternal ? (
        <>
          {callToAction} contact the IT Service Desk at{' '}
          {AppConfig.serviceDeskPhoneNumber} option{' '}
          {AppConfig.serviceDeskPhoneOption} or via the{' '}
          <a
            href={AppConfig.serviceDeskUrl}
            rel="noopener noreferrer"
            target="blank"
            style={{ textDecoration: 'none' }}
          >
            ServiceFirst
          </a>{' '}
          website
        </>
      ) : (
        <>
          For assistance please contact ClarityFirst
          <SuperSymbols>®</SuperSymbols> Support at{' '}
          {AppConfig.clarityFirstSupportPhoneNumber} or your FANCS point of
          contact
        </>
      )}
      .
    </span>
  );
};
