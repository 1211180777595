import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { lightBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="6"
    height="13"
    viewBox="0 0 6 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.546876L6 6.54688L1.04907e-06 12.5469L0 0.546876Z"
      fill={props.fill || lightBlue}
    />
  </svg>
);
