import React from 'react';
import styled from 'styled-components';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

interface IIConProps {
  svgComponent: (p: ISVGProps) => any;
  width?: number;
  height?: number;
}

const Icon = ({ height, svgComponent: SvgComponent, width }: IIConProps) => (
  <IconWrapper>
    <SvgComponent style={{ height, width }} />
  </IconWrapper>
);
Icon.displayName = 'Icon';

export default Icon;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 6px;
`;
