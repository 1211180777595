import converter from 'number-to-words';
import Decimal from 'decimal.js';

export const formatMoney = (
  amount?: number | null,
  decimals: number = 0,
  sections: number = 3,
  currencySymbol = '$'
) => {
  if (amount || amount === 0) {
    const re =
      '\\d(?=(\\d{' +
      (sections || 3) +
      '})+' +
      (decimals > 0 ? '\\.' : '$') +
      ')';

    if (amount < 0) {
      currencySymbol = `-${currencySymbol}`;
      amount *= -1;
    }

    return `${currencySymbol}${amount
      .toFixed(Math.max(0, Math.floor(decimals)))
      .replace(new RegExp(re, 'g'), '$&,')}`;
  }
};

export const toWordsOrdinal = (input: number) => {
  if (input || input === 0) {
    return converter.toWordsOrdinal(input);
  }

  return '';
};

export const toOrdinal = (input: number) => {
  if (input || input === 0) {
    return converter.toOrdinal(input);
  }

  return '';
};

export const BYTES_IN_ONE_KIBIBYTE = Math.pow(2, 10);
export const BYTES_IN_ONE_MEBIBYTE = Math.pow(BYTES_IN_ONE_KIBIBYTE, 2);

export const extractCurrencyValue = (currStr): [string, string] => {
  if (currStr !== 0 && !currStr) return ['', ''];
  currStr = currStr.toString();
  const [integer, ...decimalArr] = currStr.split('.');
  const decimal = decimalArr.join('.');
  const extractedValues = [integer?.replace('$', '').replace(/,/g, '') ?? ''];
  if (decimal) extractedValues.push(decimal);

  return [extractedValues.join('.'), decimal];
};

export const needsRounding = (decimal: string, decPlaces = 2) => {
  return decimal.length > decPlaces;
};

export const hasMoreDecimalPuncts = (decimal: string) => {
  return /\.|,/.test(decimal);
};

export const validateMoneyInput = (value) => {
  // removes '$' and punctuations (commas)
  // targetValue includes the decimal
  const [targetValue, decimal] = extractCurrencyValue(value.toString());
  const nextValue: number = Number(targetValue);

  if (isNaN(nextValue)) {
    if (hasMoreDecimalPuncts(decimal))
      return 'Only include one decimal place (0.00)';
    if (value) return 'Invalid input';
  }
  if (needsRounding(decimal))
    return 'Round your amount to the nearest cent (0.00)';
  if (nextValue < 0) return 'The charge amount must be positive';
  return true;
};

export const getAmountByPctg = (pctg?: number, total?: number): Decimal => {
  const totalDecimal = new Decimal(total || 0);
  const pctgDecimal = new Decimal(pctg || 0);
  return pctgDecimal.lessThanOrEqualTo(0) || totalDecimal.lessThanOrEqualTo(0)
    ? new Decimal(0)
    : pctgDecimal.mul(totalDecimal).div(100);
};

export const getPctgByAmount = (amount?: number, total?: number): Decimal => {
  const totalDecimal = new Decimal(total || 0);
  const amountDecimal = new Decimal(amount || 0);
  return amountDecimal.lessThanOrEqualTo(0) || totalDecimal.lessThanOrEqualTo(0)
    ? new Decimal(0)
    : amountDecimal.mul(100).div(totalDecimal);
};
