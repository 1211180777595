export enum IModalSize {
  XXS = -2,
  XS = -1,
  SM = 0,
  MD = 1,
  LG = 2,
}

export enum IModalContentPaddingSize {
  MD = 1,
  LG = 2,
}

export interface IDefaultModalProps {
  closeOnOutsideClick: boolean;
  padding: string;
  radius: string;
  showCloseButton: boolean;
  size: IModalSize;
  closeButtonDisabled: boolean;
}

export interface IModalProps {
  children: any;
  className?: string;
  closeOnEsc?: boolean;
  closeOnOutsideClick?: boolean;
  containerStyle?: React.CSSProperties;
  dataFor?: string;
  id?: string;
  maxWidth?: string;
  backgroundColor?: string;

  // this overrides the 'no-overflow' classname that normally gets
  // added to <body> by Modal.
  // TODO: look at getting rid of that <body> class entirely, not
  // sure what it's trying to accomplish
  noOverflowScroll?: boolean;

  onClose?: (e?: any) => void;
  onEnter?: () => void;
  open?: boolean;
  padding?: string;
  radius?: string;
  showCloseButton?: boolean;
  size?: IModalSize;
  closeButtonDisabled?: boolean;
}
