import { AxiosError } from 'axios';
import { IPagination } from 'store/common/types';
import { IOffice } from 'store/offices/types';
import { IRegion } from 'store/regions/types';
import { IGroup } from 'store/groups/types';

export enum UsersTypeKeys {
  FETCH_ALL_REQUEST = 'user/FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS = 'user/FETCH_ALL_SUCCESS',
  FETCH_ALL_ERROR = 'user/FETCH_ALL_ERROR',
  FETCH_SUCCESS = 'user/FETCH_SUCCESS',
  REQUEST_START = 'user/REQUEST_START',
  REQUEST_SUCCESS = 'user/REQUEST_SUCCESS',
  REQUEST_ERROR = 'user/REQUEST_ERROR',
  RESET = 'user/RESET',
}

interface IFetchAllUsersRequestAction {
  type: UsersTypeKeys.FETCH_ALL_REQUEST;
  query: string | null;
}

interface IFetchAllUsersSuccessAction {
  type: UsersTypeKeys.FETCH_ALL_SUCCESS;
  resultsById: IUserHash;
  pagination: Pagination;
}

interface IFetchAllUsersErrorAction {
  type: UsersTypeKeys.FETCH_ALL_ERROR;
  error: AxiosError;
}

interface IFetchUserSuccessAction {
  type: UsersTypeKeys.FETCH_SUCCESS;
  result: IUserHash;
}

interface IUserRequestStartAction {
  type: UsersTypeKeys.REQUEST_START;
}

interface IUserRequestSuccessAction {
  type: UsersTypeKeys.REQUEST_SUCCESS;
}

interface IUserRequestErrorAction {
  type: UsersTypeKeys.REQUEST_ERROR;
  error: AxiosError;
}

interface IUserResetAction {
  type: UsersTypeKeys.RESET;
}

export type UsersAction =
  | IFetchAllUsersRequestAction
  | IFetchAllUsersSuccessAction
  | IFetchAllUsersErrorAction
  | IFetchUserSuccessAction
  | IUserRequestStartAction
  | IUserRequestSuccessAction
  | IUserRequestErrorAction
  | IUserResetAction;

export type Pagination = null | IPagination;

export interface IUserHash {
  [id: number]: IUser;
}

export interface IUser {
  id?: number;
  firstName: string;
  lastName: string;
  domain: string;
  username: string;
  email: string;
  primaryRegionId: number;
  primaryOfficeId: number;
  offices: IOffice[];
  regions: IRegion[];
  accountStatus: 'Pending' | 'Active' | 'Disabled';
  type: 'Internal' | 'External';
  activityRights: string[];
  groups: IGroup[];
  createdDate: Date;
  lastEmailVerification?: string | null;
  initialInvitationSentDate?: Date;
  lastInvitationSentDate?: Date;
  lastInvitationSentById?: number;
  lastSignInDate?: Date;
  manager: string;
}

export interface IUsersState {
  loading: boolean;
  error: null | AxiosError;
  resultsById: IUserHash;
  pagination: Pagination;
  query: string | null;
}

export interface IUserFormParams {
  userId?: number;
  accountStatus: string;
  email: string;
  domain: string;
  username: string;
  firstName: string;
  lastName: string;
  primaryRegionId?: string;
  primaryOfficeId?: string;
  activityRights: string[];
  createdDate: Date | null;
  lastSignInDate: Date | null;
  type: 'Internal' | 'External';
  manager: string;
  mfaPhoneNumber: string;
  mfaPhoneCountryCode: string;
}

export interface IUserPostParams extends IUserFormParams {
  type: 'Internal' | 'External';
}

export interface ISendInvitationParams {
  email: string;
  firstName: string;
  lastName: string;
  fileId: number | null;
  fromEmailAddress: string | null;
  fromEmailName: string | null;
  isEmailSendOnBehalfChecked: boolean | false;
  MFAPhoneNumber: string;
}

export interface IUserInvitationInfo {
  createdDate: Date | null;
  createdById: number | null;
  createdByFirstName: string | null;
  createdByLastName: string | null;
  createdByEmail: string | null;
  lastInviterFirstName: string | null;
  lastInviterLastName: string | null;
  lastInviterEmail: string | null;
}
