import { DashboardTypeKeys, DashboardAction, IDashboardState } from './types';

const pagination = {
  limit: 10,
  page: 1,
  total: 0,
  pages: 0,
};

const countByFileType = {
  project: '0',
  single: '0',
  site: '0',
  total: '0',
};

export const initialState: IDashboardState = {
  countByFileType,
  error: null,
  incompleteWireInstructions: [],
  incomplete1099sCustomerESignIn: [],
  invoiceId: null,
  orders: [],
  pagination,
  recentOrders: [],
};

export default (
  state: IDashboardState = initialState,
  action: DashboardAction
): IDashboardState => {
  switch (action.type) {
    case DashboardTypeKeys.ERROR:
      return {
        ...state,
        error: action.error,
      };
    case DashboardTypeKeys.SET_INCOMPLETE_WIRE_INSTRUCTIONS:
      return {
        ...state,
        incompleteWireInstructions: action.data,
      };
    case DashboardTypeKeys.SET_UNPAID_INVOICE:
      return {
        ...state,
        invoiceId: action.data,
      };
    case DashboardTypeKeys.SET_MY_ORDERS:
      return {
        ...state,
        orders: action.orders || [],
        pagination: {
          ...pagination,
          ...action.pagination,
        },
        countByFileType: {
          ...countByFileType,
          ...action.countByFileType,
        },
      };
    case DashboardTypeKeys.SET_MY_RECENT_ORDERS:
      return {
        ...state,
        recentOrders: (action.recentOrders || []).slice(0, 3),
      };
    case DashboardTypeKeys.SET_INCOMPLETE_1099S_CUSTOMER_E_SIGNIN:
      return {
        ...state,
        incomplete1099sCustomerESignIn: action.data,
      };
    default:
      return state;
  }
};
