import React from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import ContentContainer from 'components/ContentContainer';
import { gray1, primaryBlue8, gray8, red8 } from 'config/colors';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import { Row } from 'components/ui/Box';
import { ActivityRightValue } from 'store/permissions/types';

import { IApplicationState } from 'store';

import {
  DocumentsFeatures,
  OfficeViewFeatures,
  ChecklistsFeatures,
  DepositsFeatures,
} from 'store/features/types';
import { FeatureToggle } from 'components/Features';
import accountingUrls from 'config/urls/accounting';
import { AccountingView } from './types';
import { isFeatureEnabled } from 'store/features/helpers';
import { MenuOption } from '../IncomingFunds/constants';

interface IAccountingSubheaderProps {
  activeNavItem: AccountingView | null;
  fastOfficeId: number | string | null;
  isCheckList?: boolean;
  push: (path: string) => void;
}

interface INavItem {
  route: string;
  text: AccountingView;
  feature?: DocumentsFeatures | OfficeViewFeatures | ChecklistsFeatures;
  id: string;
}

export default connect(null, { push })(function AccountingSubheader({
  activeNavItem,
  fastOfficeId,
  push,
}: IAccountingSubheaderProps) {
  const isTemplateManager = useSelector((state: IApplicationState) =>
    state.auth.session.activityRights.includes(
      ActivityRightValue.TemplateManagement
    )
  );

  const isAchDepositEnabled = useSelector((state: IApplicationState) =>
    isFeatureEnabled(state, DepositsFeatures.AchDeposit)
  );
  const isDepositListEnabled = useSelector((state: IApplicationState) =>
    isFeatureEnabled(state, OfficeViewFeatures.DepositList)
  );
  const RenderItem = (
    navItem: INavItem,
    index: number,
    active: boolean
  ): JSX.Element => {
    return (
      <NavItem
        id={navItem.id}
        active={active}
        href={navItem.route}
        key={index}
        onClick={(e) => {
          e.preventDefault();
          const currentLocation = window.location.pathname;
          if (currentLocation === navItem.route) return;
          push(navItem.route);
        }}
      >
        {navItem.text}
        {active && <ActiveIndicator />}
      </NavItem>
    );
  };
  const navItems: INavItem[] = [
    {
      route: `/accounting/${fastOfficeId}/incoming-funds`,
      text: isAchDepositEnabled
        ? MenuOption.IncomingFunds
        : MenuOption.IncomingWires,
      feature: OfficeViewFeatures.IncomingWires,
      id: 'seo-immutable-incoming-office-wires-header',
    },
    {
      route: `/accounting/${fastOfficeId}/outgoing-funds`,
      text: 'Outgoing Wires',
      feature: OfficeViewFeatures.OutgoingWires,
      id: 'seo-immutable-outgoing-office-wires-header',
    },
    {
      route: `/office/${fastOfficeId}/checklists`,
      text: 'Checklists Templates',
      feature: ChecklistsFeatures.CustomerChecklistTemplates,
      id: 'seo-immutable-checklist-header',
    },
    {
      route: `/office/${fastOfficeId}/customer-uploads`,
      text: 'Customer Uploads',
      feature: DocumentsFeatures.CDUReview,
      id: 'seo-immutable-external-uploads-header',
    },
    {
      route: accountingUrls.ibaTransactions(fastOfficeId as number),
      text: 'IBA Transactions',
      id: 'seo-immutable-iba-transactions-header',
    },
    {
      route: accountingUrls.trustAccounting(fastOfficeId as number),
      text: 'Trust Accounting',
      feature: OfficeViewFeatures.TrustExtractOverview,
      id: 'seo-immutable-trust-accounting-header',
    },
  ];

  if (isDepositListEnabled) {
    navItems.push({
      route: `/accounting/${fastOfficeId}/deposit-list`,
      text: 'Deposit List',
      id: 'seo-immutable-deposit-list-header',
    });
  }

  return (
    <Container>
      <ContentContainer>
        <Row alignItems="center" style={{ height: 36 }}>
          {navItems
            .filter((navItem) => {
              if (!isTemplateManager)
                return navItem.text !== 'Checklists Templates';
              else return navItem;
            })
            .map((navItem, index) => {
              const active = navItem.text === activeNavItem;
              return (
                <React.Fragment key={index}>
                  {!navItem?.feature ? (
                    RenderItem(navItem, index, active)
                  ) : (
                    <FeatureToggle flag={navItem.feature} key={index}>
                      {RenderItem(navItem, index, active)}
                    </FeatureToggle>
                  )}
                </React.Fragment>
              );
            })}
        </Row>
      </ContentContainer>
    </Container>
  );
});

const Container = styled.div`
  background-color: ${gray1};
  flex-direction: row;
  width: 100%;
`;

const NavItem = styled.a<{ active: boolean }>`
  ${SMALL_BODY_MEDIUM};
  align-items: center;
  color: ${({ active }) => (active ? primaryBlue8 : gray8)};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-right: 48px;
  position: relative;

  &:hover {
    text-decoration: none;
  }
`;

const ActiveIndicator = styled.div`
  background: ${red8};
  bottom: 0;
  height: 2px;
  position: absolute;
  width: 100%;
`;
