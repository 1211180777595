/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChecklistDocumentService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This method accepts the BlobId of the document and returns the document Stream (Auth)
   * @returns any Successful file content retrieval
   * @throws ApiError
   */
  public getAsync({
    blobId,
  }: {
    /**
     * blobId of the task document
     */
    blobId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/checklist-task-document/{blobId}',
      path: {
        'blobId': blobId,
      },
      errors: {
        403: `Access is Forbidden.`,
        500: `Unexpected error in getting the file.`,
      },
    });
  }

  /**
   * This controller will take an input of file and upload it to Azure Blob and returns a GUID String (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public uploadTaskFile({
    formData,
  }: {
    formData?: {
      ContentType?: string | null;
      ContentDisposition?: string | null;
      Headers?: Record<string, Array<string>> | null;
      Length?: number;
      Name?: string | null;
      FileName?: string | null;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/checklist-task-document',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        403: `Access is Forbidden.`,
        500: `Unexpected error.`,
      },
    });
  }

}
