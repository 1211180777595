import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M12.182 1.79653C11.3381 0.952621 10.1935 0.478516 9 0.478516C7.80653 0.478516 6.66193 0.952621 5.81802 1.79653C4.97411 2.64045 4.5 3.78504 4.5 4.97852C4.5 6.17199 4.97411 7.31658 5.81802 8.1605C6.66193 9.00441 7.80653 9.47852 9 9.47852C10.1935 9.47852 11.3381 9.00441 12.182 8.1605C13.0259 7.31658 13.5 6.17199 13.5 4.97852C13.5 3.78504 13.0259 2.64045 12.182 1.79653Z"
      fill={props.fill}
    />
    <path
      d="M18 16.2285C18 13.7423 13.9725 11.7285 9 11.7285C4.0275 11.7285 0 13.7423 0 16.2285V18.4785H18V16.2285Z"
      fill={props.fill}
    />
    <path
      d="M15 7.25658V10.7005H19.5455V13.4785L24 8.97852L19.5455 4.47852V7.25658H15Z"
      fill={props.fill}
    />
  </svg>
);
