import { Row } from 'components/ui/Box';
import { primaryBlue2, red8, neutralGray7, green8 } from 'config/colors';
import React from 'react';
import {
  IDisplayNameProgressRow,
  ProgressBarKeys,
  TaskStatus,
} from 'store/ProgressBar/types';
import styled from 'styled-components';
import CloseIcon from 'react-icons/lib/md/close';
import ErrorIcon from 'react-icons/lib/md/error';
import { useDispatch, useSelector } from 'react-redux';
import { removeProgressItem } from 'store/ProgressBar/actions';
import { IApplicationState } from 'store';
import CheckCircle from 'icons/CheckCircle';

const rowHeight = 63;
const iconStyle = { height: 23, width: 23 };

interface IProgressRowViewStatusProps {
  fileNameDisplayRender: (
    fileName: IDisplayNameProgressRow,
    uniqueId: string,
    documentType: string
  ) => React.ReactNode;
  documentType: string;
  hideCloseIcon: boolean;
  identifier: ProgressBarKeys;
}

const ProgressRowViewStatus: React.FC<IProgressRowViewStatusProps> = ({
  identifier,
  fileNameDisplayRender,
  hideCloseIcon,
  documentType,
}) => {
  const dispatch = useDispatch();

  const progressItems = useSelector(
    (state: IApplicationState) => state.progressBar.progressData[identifier]
  );
  return (
    <div>
      {progressItems.progressDataItems?.map((progressData) => {
        const { displayName, status, progressUniqueId } = progressData ?? {};
        const taskCompleted = status === TaskStatus.SUCCESS;
        const taskInProgressOrSuccess =
          status === TaskStatus.PROCESSING || status === TaskStatus.SUCCESS;
        return (
          <div key={progressData.progressUniqueId}>
            <Row
              id={`${identifier}__Progress__Row${progressUniqueId}`}
              justify="between"
              alignItems="center"
              alignContent="between"
              style={{
                height: rowHeight,
                padding: 16,
                borderBottom: `2px solid ${primaryBlue2}`,
              }}
            >
              {displayName ? (
                fileNameDisplayRender(
                  displayName,
                  progressUniqueId?.toString() ?? '',
                  documentType ?? '.pdf'
                )
              ) : (
                <></>
              )}
              <RowActions
                id={`${identifier}__Progress__Row__Button${progressUniqueId}`}
              >
                {taskInProgressOrSuccess ? (
                  taskCompleted && <CheckCircle fill={green8} />
                ) : (
                  <ErrorIcon style={{ ...iconStyle, color: red8 }} />
                )}
                <ButtonContainer
                  onClick={() => {
                    if (progressData) {
                      dispatch(
                        removeProgressItem({
                          identifier: identifier,
                          progressItem: progressData,
                        })
                      );
                    }
                  }}
                >
                  {!hideCloseIcon && <StyledCloseIcon />}
                </ButtonContainer>
              </RowActions>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;
  height: 30px;
  color: ${neutralGray7};
`;

const RowActions = styled.span`
  display: inline-flex;
  align-items: center;
`;

const ButtonContainer = styled.span`
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  vertical-align: bottom;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${neutralGray7};
    }
  }
`;

export default ProgressRowViewStatus;
