const redirectOrigins = (): string[] => {
  return AppConfig.redirectOrigins ? AppConfig.redirectOrigins.split(',') : [];
};

export const shouldRedirectOrigin = (): boolean => {
  const origin: string = window.location.origin;
  const includesOrigin = redirectOrigins().some((o: string) =>
    o.includes(origin)
  );

  return AppConfig.redirectOriginEnabled && includesOrigin;
};
