import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState, Dispatch } from 'store';
import styled from 'styled-components';
import { linkWater } from 'config/theme';
import Modal, { ModalTitle, IModalSize } from 'components/ui/Modal';
import { isEmpty } from 'utils/text';
import { setClarityFirstVideo } from 'store/resources/actions';
import { CLARITYFIRST_VIDEO_LINK } from 'store/resources/constants';

interface IClarityFirstVideoModalProps {
  showClarityFirstVideo: boolean;
  dispatch: Dispatch;
}

export const ClarityFirstVideoModal = ({
  showClarityFirstVideo,
  dispatch,
}: IClarityFirstVideoModalProps) => {
  if (!showClarityFirstVideo) {
    return null;
  }

  return (
    <Modal
      closeOnEsc
      onClose={() => dispatch(setClarityFirstVideo(false))}
      open={showClarityFirstVideo}
      padding="0"
      size={IModalSize.MD}
    >
      <ModalTitle>
        The Power of ClarityFirst<SuperSymbols>&reg; </SuperSymbols> - Welcome
        Video
      </ModalTitle>
      <ModalContainer>
        <VideoPlayer
          src={CLARITYFIRST_VIDEO_LINK}
          allow="autoplay;"
          allowFullScreen
        />
      </ModalContainer>
    </Modal>
  );
};

export function mapStateToProps(state: IApplicationState) {
  return {
    showClarityFirstVideo:
      state.resources.showClarityFirstVideo &&
      AppConfig.landingPageVideoEnabled &&
      !isEmpty(AppConfig.landingPageVideoId),
  };
}

export default connect(mapStateToProps)(ClarityFirstVideoModal);

const ModalContainer = styled.div`
  padding: 32px 32px 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid ${linkWater};
  overflow-y: auto;
`;
ModalContainer.displayName = 'ModalContainer';

const VideoPlayer = styled.iframe`
  border-style: none;
  min-height: 480px;
  min-width: 836px;
`;
VideoPlayer.displayName = 'VideoPlayer';

const SuperSymbols = styled.span`
  font-size: 20px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.005em;
`;
SuperSymbols.displayName = 'SuperSymbols';
