import {
  ProrationOrAdjustmentType,
  IHoldFund,
  IChargeLine,
} from 'views/MultiSiteAllocations/types';

export enum AllocationsTypeKeys {
  CHARGES_SHOW_MODAL = 'allocations/CHARGES_SHOW_MODAL',
  CHARGES_HIDE_MODAL = 'allocations/CHARGES_HIDE_MODAL',

  PRORATIONS_ADJUSTMENTS_SHOW_MODAL = 'allocations/PRORATIONS_ADJUSTMENTS_SHOW_MODAL',
  PRORATIONS_ADJUSTMENTS_HIDE_MODAL = 'allocations/PRORATIONS_ADJUSTMENTS_HIDE_MODAL',

  HOLDFUNDS_SHOW_MODAL = 'allocations/HOLDFUNDS_SHOW_MODAL',
  HOLDFUNDS_HIDE_MODAL = 'allocations/HOLDFUNDS_HIDE_MODAL',
}

export interface IShowChargeAllocationsModal {
  chargeId?: number;
  ecpTypeId?: number;
  chargeLines?: IChargeLine[];
}

export interface IShowProrationsAdjustmentsAllocationsModal {
  type?: ProrationOrAdjustmentType;
  subType?: string;
}

export interface IShowHoldFundsAllocationsModal {
  holdFundId?: number;
  holdFunds?: IHoldFund[];
}

export interface IAllocationsState {
  showChargesAllocationsModal?: IShowChargeAllocationsModal;
  showProrationsAdjustmentsAllocationsModal?: IShowProrationsAdjustmentsAllocationsModal;
  showHoldFundsAllocationsModal?: IShowHoldFundsAllocationsModal;
}

export type AllocationsAction =
  | IAllocationsChargesModalShowAction
  | IAllocationsChargesModalHideAction
  | IAllocationsProrationsAdjustmentsModalShowAction
  | IAllocationsProrationsAdjustmentsModalHideAction
  | IAllocationsHoldFundsModalShowAction
  | IAllocationsHoldFundsModalHideAction;

export interface IAllocationsChargesModalShowAction {
  type: AllocationsTypeKeys.CHARGES_SHOW_MODAL;
  payload: IShowChargeAllocationsModal;
}

export interface IAllocationsChargesModalHideAction {
  type: AllocationsTypeKeys.CHARGES_HIDE_MODAL;
}

export interface IAllocationsProrationsAdjustmentsModalShowAction {
  type: AllocationsTypeKeys.PRORATIONS_ADJUSTMENTS_SHOW_MODAL;
  payload: IShowProrationsAdjustmentsAllocationsModal;
}

export interface IAllocationsProrationsAdjustmentsModalHideAction {
  type: AllocationsTypeKeys.PRORATIONS_ADJUSTMENTS_HIDE_MODAL;
}

export interface IAllocationsHoldFundsModalShowAction {
  type: AllocationsTypeKeys.HOLDFUNDS_SHOW_MODAL;
  payload: IShowHoldFundsAllocationsModal;
}

export interface IAllocationsHoldFundsModalHideAction {
  type: AllocationsTypeKeys.HOLDFUNDS_HIDE_MODAL;
}
