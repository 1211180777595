import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.06282 0.959961L0.617188 5.38387V7.15343H17.5085V5.38387L9.06282 0.959961ZM13.0634 8.92299V15.1165H15.7304V8.92299H13.0634ZM0.617188 19.5404H17.5085V16.886H0.617188V19.5404ZM7.7293 8.92299V15.1165H10.3963V8.92299H7.7293ZM2.39522 8.92299V15.1165H5.06226V8.92299H2.39522Z"
      fill={props.fill || white}
    />
  </svg>
);
