import Card from 'components/ui/Card';
import { SiteType } from '../types';
import { white } from 'config/colors';
import LeftContent from './LeftContent';
import React, { ReactNode } from 'react';
import RightContent from './RightContent';
import styled from 'styled-components';

export interface ISiteCardHeaderProps {
  /**
   * Optional: The business source we wish to display in our card
   */
  businessSource: string | null;
  /**
   * Optional: The Business Source contact associated with our business source
   */
  businessSourceContact: string | null;
  /**
   * Optional: The number of cities included in our multisite collection
   */
  citiesCount?: number;
  /**
   * Optional: A count of how many of the multisite sites have closed so far
   */
  closedSitesCount?: number;
  /**
   * Optional: The number of counties included in our multisite collection
   */
  countiesCount?: number;
  /**
   * Optional: Our county information, usually this receives the name of the
   * county our site is in.
   */
  countyInformation?: string;
  /**
   * Optional: A string formatted date, our estimation of when the transaction will be closed
   */
  estimatedCloseDate?: string;
  /**
   * Our file's unique ID
   */
  fileId: number;
  /**
   * The name of our File. This is displayed directly in the card.
   */
  fileName: string;
  /**
   * The number our File has been assigned. Usually a value like '928121'
   */
  fileNumber: string;
  /**
   * Optional: If enabled, that means the current component has document editing
   * permissions, which would enable links to Documents from the card
   */
  hasDocumentPermissions?: boolean;
  /**
   * Optional: If enabled, that means the current component has wire instruction
   * permissions, which would enable links to Wire Instructions from the card
   */
  hasWireInstructionPermissions?: boolean;
  /**
   * Flag to know whether the Close Date is Estimated or Settled.
   */
  isCloseDateEstimated?: boolean;
  /**
   * Flag to check whether the user file access has expired
   */
  isFileAccessExpired?: boolean;
  /**
   * To check whether Logged In user is Internal or External.
   */
  isInternal?: boolean;
  /**
   *  Icon to display next to left content header text
   */
  leftHighlightIcon?: ReactNode;
  /**
   * Optional: The outstanding liability in our site, or multisite
   */
  liabilityAmount?: number;
  /**
   * Optional: The unique ID in our multisite information
   */
  multiSiteId: number | null;
  /**
   * Optional: The number of our multisite, the multisite equivalent of an order number
   */
  multiSiteNumber: string | null;
  /**
   * Optional: The amount of open sites in our multisite
   */
  openSitesCount?: number;
  /**
   * A function which when given a route, will redirect the browser to it
   * through a navigation mechanism.
   */
  pushRoute: (route: string) => void;
  /**
   * Optional: The total number of sites included in our multisite collection
   */
  sitesCount?: number;
  /**
   * Optional: The number of states included in our multisite collection
   */
  stateCount?: number;
  /**
   * Optional: Information about our state. This is automatically built from our
   * address objects, but usually maps to something like "City, STATE CP" e.g. Wellington, AZ 33923
   */
  stateInformation?: string;
  /**
   * Optional: The status of our site. eg. "Open", "Closed"
   */
  status?: string;
  /**
   * Optional: The street number, and street name of our site. e.g. "23223 E. Wilson Rd."
   */
  street?: string;
  /**
   * What type our site is, this influences how our component will render.
   * Currently Single Site/Multisite Single Site Entry/Multisite
   */
  siteType: SiteType;
  /**
   * Optional: This should be set to `true` if `SiteCardHeader` is not used as part of `SiteCard`.
   */
  standalone?: boolean;
  /**
   * An array of all the transaction types included in our order.
   */
  transactionTypes: string[];
  /**
   * This should be set to `true` if we want the address, business source and liability
   * info container to respect parent wrapper.
   */
  wrapRightContents?: boolean;
}

const SiteCardHeader = ({
  businessSource,
  businessSourceContact,
  citiesCount,
  closedSitesCount,
  countiesCount,
  countyInformation,
  estimatedCloseDate,
  fileId,
  fileName,
  fileNumber,
  hasDocumentPermissions,
  hasWireInstructionPermissions,
  isCloseDateEstimated,
  isInternal,
  leftHighlightIcon,
  liabilityAmount,
  multiSiteId,
  multiSiteNumber,
  openSitesCount,
  pushRoute,
  sitesCount,
  siteType,
  standalone,
  stateCount,
  stateInformation,
  status,
  street,
  transactionTypes,
  wrapRightContents,
}: ISiteCardHeaderProps) => {
  const ContainerComponent = standalone ? CardContainer : Container;

  return (
    <ContainerComponent id={`orderRow-${fileId}`}>
      <LeftContent
        fileNumber={fileNumber}
        leftHighlightIcon={leftHighlightIcon}
        multiSiteId={multiSiteId}
        multiSiteNumber={multiSiteNumber}
        name={fileName}
        siteType={siteType}
      />
      <RightContent
        businessSource={businessSource}
        businessSourceContact={businessSourceContact}
        citiesCount={citiesCount || 0}
        closedSitesCount={closedSitesCount || 0}
        countiesCount={countiesCount || 0}
        countyInformation={countyInformation}
        estimatedCloseDate={estimatedCloseDate}
        fileId={fileId}
        fileNumber={fileNumber}
        hasDocumentPermissions={hasDocumentPermissions}
        hasWireInstructionPermissions={hasWireInstructionPermissions}
        liabilityAmount={liabilityAmount}
        multisite={(siteType === SiteType.MultisiteProject) as true | false}
        name={fileName}
        openSitesCount={openSitesCount || 0}
        pushRoute={pushRoute}
        sitesCount={sitesCount || 0}
        stateCount={stateCount || 0}
        stateInformation={stateInformation}
        status={status}
        street={street}
        transactionTypes={transactionTypes}
        wrapContents={wrapRightContents}
        isInternal={isInternal}
        isCloseDateEstimated={isCloseDateEstimated}
      />
    </ContainerComponent>
  );
};
SiteCardHeader.displayName = 'SiteCardHeader';

export default SiteCardHeader;
export { default as HeaderRightContent } from './RightContent';
export { RightContentProps as IHeaderRightContentProps } from './props';
export {
  MultiSiteLocationTitle,
  MultiSiteStatusDisplay,
  TitleContainer,
} from './Addresses';
export { default as TransactionTypes } from './TransactionTypes';
export { SiteCardMenu } from './SiteCardMenu';

const CardContainer = styled(Card)`
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 920px;
  padding: 20px 23px;
  & > * {
    font-family: 'Circular';
  }
`;

const Container = styled.div`
  background: ${white};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 124px;
  padding: 20px 23px;
  width: 100%;
  & > * {
    font-family: 'Circular';
  }
`;
