import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { headerZIndex, white, shuttleGrey } from 'config/theme';
import { green8, red8 } from 'config/colors';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import Close from 'icons/Close';
import { Row } from 'react-styled-flexboxgrid';
import { setBulkAllocationsUpdatesStatusProgress } from 'store/orders/actions';
import workspaceAPI from 'services/workspaceAPI';
import { toast } from 'components/Snackbar';
import useInterval from 'hooks/useInterval';
import CheckCircle from 'icons/CheckCircle';
import { JobResultStatus, JobStatus } from '../types';
import AlertCircle from 'icons/AlertCircle';
import Truncate from 'components/ui/Truncate';
import MTooltip from 'components/ToolTip/MTooltip';
import { AllocationEntityTypeToName } from 'store/orders/types';

const HEADER_HEIGHT = 56;
const ROW_HEIGHT = 52;
const MAX_VISIBLE_ROWS = 3;

export const fetchStatus = async (
  orderId,
  jobId,
  stopPolling,
  setJobResult,
  closePopover
) => {
  try {
    const {
      data: { jobStatus, jobResult },
    } = await workspaceAPI.get(
      `orders/${orderId}/bulk-allocations?jobid=${jobId}`
    );

    if (jobStatus === JobStatus.SUCCESS) {
      stopPolling();
      setJobResult(jobResult);
      setTimeout(closePopover, 3000);
    } else if (jobStatus === JobStatus.FAILED) {
      stopPolling();
      toast('Job failed', 'error');
    }
  } catch (error) {
    stopPolling();
    toast('Error fetching job status', 'error');
  }
};

const BulkAllocationsUpdateProgress = ({
  bulkAllocationsUpdateProgress,
  setBulkAllocationsUpdatesStatusProgress,
}) => {
  const {
    entityType,
    jobId,
    orderId,
    jobResult,
    entities,
  } = bulkAllocationsUpdateProgress;
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const inProgress = !!jobId && !jobResult.length;

  useEffect(() => {
    if (jobId) {
      startPolling();
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [jobId]);

  const closePopover = () => {
    setBulkAllocationsUpdatesStatusProgress(null, null, null, null, null);
  };

  const setJobResult = (newJobResult) => {
    setBulkAllocationsUpdatesStatusProgress(
      entityType,
      orderId,
      jobId,
      entities,
      newJobResult
    );
  };

  useInterval(
    () => fetchStatus(orderId, jobId, stopPolling, setJobResult, closePopover),
    intervalId
  );

  const startPolling = () => {
    if (!intervalId) setIntervalId(3000);
  };

  const stopPolling = () => {
    if (intervalId) setIntervalId(null);
  };

  const getHeaderTitleAndIcon = () => {
    const failedUpdates = jobResult.filter(
      ({ value }) => value !== JobResultStatus.SUCCESS
    );

    if (inProgress) {
      return {
        icon: null,
        title: `${AllocationEntityTypeToName[entityType]} allocation in progress...`,
      };
    }

    if (failedUpdates.length) {
      const failedSources = failedUpdates
        .map(({ key }) => {
          const ent = entities.find(({ id }) => id === key);
          if (!ent) return key;
          if (ent.source) return ent.source;
          if (ent.description) return ent.description;
          return ent.id;
        })
        .filter(Boolean) // Filter out undefined values
        .join(', ');

      return {
        icon: <AlertCircle fill={red8} />,
        title: `Failed for ${failedSources}`,
      };
    }

    return {
      icon: <CheckCircle fill={green8} />,
      title: `${AllocationEntityTypeToName[entityType]} allocation successfully made`,
    };
  };

  if (!jobId) return null;

  return (
    <FixedContainer>
      <ProgressHeader
        inProgress={inProgress}
        onClose={closePopover}
        {...getHeaderTitleAndIcon()}
      />
      {inProgress && (
        <LinearProgress
          {...{ 'data-testid': 'bulk-updates__linear-progress' }}
        />
      )}
    </FixedContainer>
  );
};

const ProgressHeader = ({ inProgress, onClose, title, icon }) => {
  return (
    <>
      <StyledProgressHeader inProgress={inProgress}>
        <MTooltip title={title} placement="top" enterDelay={1000}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header>
              {icon}
              <Truncate width={280}>{title}</Truncate>
            </Header>
          </div>
        </MTooltip>
        {!inProgress && (
          <ButtonContainer
            onClick={onClose}
            id={'bulk-updates__popover-progress__button__close'}
            {...{ 'data-testid': 'bulk-updates__close-button' }}
          >
            <StyledCloseIcon />
          </ButtonContainer>
        )}
      </StyledProgressHeader>
    </>
  );
};

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${ROW_HEIGHT * MAX_VISIBLE_ROWS + HEADER_HEIGHT}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  margin-left: 16px;
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
`;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })<{
  inProgress: boolean;
}>`
  height: ${({ inProgress }) =>
    inProgress ? HEADER_HEIGHT - 4 : HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  padding: 10px 14px 10px 24px;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${shuttleGrey};
    }
  }
`;

const Header = styled.div`
  ${SMALL_BODY_MEDIUM}
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const StyledCloseIcon = styled(Close)`
  width: 26px;
  height: 30px;
  color: ${shuttleGrey};
`;

const mapStateToProps = (state) => ({
  bulkAllocationsUpdateProgress:
    state.orders?.ui?.bulkAllocationsUpdatesStatusProgress,
});

const mapDispatchToProps = {
  setBulkAllocationsUpdatesStatusProgress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkAllocationsUpdateProgress);
