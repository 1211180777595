import React from 'react';
import { Row } from 'components/ui/Box';
import { neutralGray7, primaryBlue2 } from 'config/colors';
import ChevronIcon from 'react-icons/lib/md/chevron-left';
import CloseIcon from 'react-icons/lib/md/close';
import styled from 'styled-components';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState } from 'store';
import { ProgressBarKeys, TaskStatus } from 'store/ProgressBar/types';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {
  clearAllProgressItems,
  toggleProgressItems,
} from 'store/ProgressBar/actions';

const headerHeight = 56;

interface IProgressHeaderProps {
  titleHeader: string;
  inProgressTitleHeader: string;
  identifier: ProgressBarKeys;
}

const ProgressHeader: React.FC<IProgressHeaderProps> = ({
  titleHeader,
  inProgressTitleHeader,
  identifier,
}) => {
  const progressItems = useSelector(
    (state: IApplicationState) => state.progressBar.progressData[identifier]
  );
  const dispatch = useDispatch();

  const inProgress = progressItems?.progressDataItems?.some(
    (x) => x.status === TaskStatus.PROCESSING
  );

  const isExpanded = progressItems?.isExpanded;
  const onToggleExpanded = () => {
    dispatch(toggleProgressItems({ identifier: identifier }));
  };
  const onClose = () =>
    dispatch(clearAllProgressItems({ identifier: identifier }));

  return (
    <>
      <StyledProgressHeader
        id={`${identifier}__Progress__Bar__Header`}
        inProgress={inProgress ?? false}
      >
        <HeaderTextContainer>
          {titleHeader ? (
            <Header>
              {inProgress ? `${inProgressTitleHeader}` : titleHeader}
            </Header>
          ) : (
            <Header>Generating{inProgress ? '...' : ''}</Header>
          )}
        </HeaderTextContainer>
        <ButtonContainer
          onClick={
            inProgress
              ? onToggleExpanded
              : !isExpanded
              ? onToggleExpanded
              : onClose
          }
          id={`${identifier}__Progress__Bar__Header__button`}
        >
          {isExpanded ? (
            inProgress ? (
              <ExpandedChevron />
            ) : (
              <StyledCloseIcon />
            )
          ) : (
            <CollapsedChevron />
          )}
        </ButtonContainer>
      </StyledProgressHeader>
      {inProgress && isExpanded && <LinearProgress />}
    </>
  );
};

const Header = styled.div`
  ${SMALL_BODY_MEDIUM}
`;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })<{
  inProgress: boolean;
}>`
  height: ${({ inProgress }) =>
    inProgress ? headerHeight - 4 : headerHeight}px;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  align-items: center;
  ${({ inProgress }) =>
    inProgress ? '' : `border-bottom: 2px solid ${primaryBlue2};`}
`;

const HeaderTextContainer = styled.div`
  display: 'flex';
  flexdirection: 'column';
`;

const StyledChevron = styled(ChevronIcon)`
  width: 30px;
  height: 30px;
  vertical-align: bottom;
  color: ${neutralGray7};
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;
  height: 30px;
  color: ${neutralGray7};
`;

const CollapsedChevron = styled(StyledChevron)`
  transform: rotate(90deg);
`;

const ExpandedChevron = styled(StyledChevron)`
  transform: rotate(270deg);
`;

const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  vertical-align: bottom;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${neutralGray7};
    }
  }
`;

export default ProgressHeader;
