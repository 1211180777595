import {
  UniversalWireAccountsTypeKeys,
  UniversalWireAccountsAction,
  IUniversalWireAccountsState,
} from './types';

export const initialState: IUniversalWireAccountsState = {
  loading: false,
  universalWireAccounts: [],
  universalWireAccountDetails: null,
  universalWireAccountFileAssociation: null,
  error: null,
  pagination: null,
};

export default (state = initialState, action: UniversalWireAccountsAction) => {
  switch (action.type) {
    case UniversalWireAccountsTypeKeys.FETCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UniversalWireAccountsTypeKeys.FETCH_ALL_ERROR:
      return {
        ...state,
        loading: false,
        universalWireAccounts: [],
        error: action.error,
      };
    case UniversalWireAccountsTypeKeys.CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        universalWireAccounts: [],
        error: null,
      };
    case UniversalWireAccountsTypeKeys.SET_UNIVERSAL_WIRE_INSTRUCTION_DETAILS:
      return {
        ...state,
        loading: false,
        universalWireAccountDetails: action.universalWireAccountDetails,
      };
    case UniversalWireAccountsTypeKeys.SET_UNIVERSAL_WIRE_INSTRUCTION_FILE_ASSOCIATION:
      return {
        ...state,
        universalWireAccountFileAssociation:
          action.universalWireAccountFileAssociation,
      };
    case UniversalWireAccountsTypeKeys.SET_UNIVERSAL_WIRE_INSTRUCTIONS:
      return {
        ...state,
        universalWireAccounts: action.universalWireAccounts,
        pagination: action.pagination,
      };
    case UniversalWireAccountsTypeKeys.RESET_UNIVERSAL_WIRE_STATE:
      return {
        ...state,
        loading: false,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};
