import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M0.588867 9.81858H8.14442V0.929688H0.588867V9.81858ZM0.588867 16.9297H8.14442V11.5964H0.588867V16.9297ZM10.0333 16.9297H17.5889V8.0408H10.0333V16.9297ZM10.0333 0.929688V6.26302H17.5889V0.929688H10.0333Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
