import { IMilestonesState, MilestoneTypeKeys, MilestonesAction } from './types';

export const initialState: IMilestonesState = {
  loading: false,
  error: null,
  milestones: [],
};

export default (
  state: IMilestonesState = initialState,
  action: MilestonesAction
): IMilestonesState => {
  switch (action.type) {
    case MilestoneTypeKeys.SET_LOADING:
      return {
        ...state,
        error: null,
        loading: action.loading,
      };

    case MilestoneTypeKeys.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case MilestoneTypeKeys.SET_MILESTONES:
      return {
        ...state,
        milestones: action.milestones,
      };

    default:
      return state;
  }
};
