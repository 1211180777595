import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default ({ style }: ISVGProps) => (
  <svg
    width="75"
    height="102"
    viewBox="0 0 75 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M37.6792 102C58.1396 102 74.7261 100.466 74.7261 98.5733C74.7261 96.6807 58.1396 95.1465 37.6792 95.1465C17.2188 95.1465 0.632324 96.6807 0.632324 98.5733C0.632324 100.466 17.2188 102 37.6792 102Z"
      fill="#CBD6E4"
    />
    <rect
      x="0.54671"
      y="0.631645"
      width="58.9219"
      height="73.1419"
      rx="8.25018"
      transform="matrix(0.997238 0.0742778 -0.0695083 0.997581 13.77 2.73057)"
      fill="#AEBBCE"
      stroke="#9BABC2"
      strokeWidth="1.1786"
    />
    <rect
      x="0.627063"
      y="0.546034"
      width="58.0958"
      height="73.1429"
      rx="8.25018"
      transform="matrix(0.997462 -0.0711952 0.0666218 0.997778 1.48914 6.05031)"
      fill="#C8D5E8"
      stroke="#9BABC2"
      strokeWidth="1.1786"
    />
    <rect
      x="7.81391"
      y="1.22992"
      width="59.5192"
      height="73.073"
      rx="8.25018"
      fill="white"
      stroke="#9BABC2"
      strokeWidth="1.1786"
    />
    <rect
      x="17.832"
      y="11.248"
      width="17.0897"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="17.832"
      y="17.7302"
      width="10.6074"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="17.832"
      y="31.8734"
      width="37.7151"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="17.832"
      y="38.3557"
      width="33.0007"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="17.832"
      y="44.838"
      width="41.2509"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="17.832"
      y="51.3203"
      width="25.9291"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="17.832"
      y="60.749"
      width="5.89299"
      height="3.53579"
      rx="1.1786"
      fill="#BECFE8"
    />
    <rect
      x="47.8862"
      y="59.5704"
      width="11.1967"
      height="5.89299"
      rx="1.1786"
      fill="#BECFE8"
    />
    <circle cx="54.6633" cy="16.257" r="5.00904" fill="#BECFE8" />
  </svg>
);
