import workspaceAPI from 'services/workspaceAPI';
import { AsyncAction } from 'store';
import { collectionToMaphash } from 'store/utils';
import { OfficesTypeKeys } from './types';

export function fetchOffices(): AsyncAction<void> {
  return (dispatch) => {
    dispatch({
      type: OfficesTypeKeys.FETCH_ALL_REQUEST,
    });

    return workspaceAPI
      .get('offices')
      .then(({ data }) => {
        dispatch({
          type: OfficesTypeKeys.FETCH_ALL_SUCCESS,
          resultsById: collectionToMaphash(data.offices, 'id'),
        });
      })
      .catch((error) => {
        dispatch({
          type: OfficesTypeKeys.FETCH_ALL_ERROR,
          error,
        });
      });
  };
}

export const setBuildTrustExtractProgress = (
  items,
  shouldReFetchTrustExtracts = false
) => ({
  type: OfficesTypeKeys.SET_BUILD_TRUST_EXTRACT_PROGRESS,
  buildTrustExtractProgress: {
    items,
    shouldReFetchTrustExtracts,
  },
});

export const shouldReFetchTrustExtracts = (fetchTrustExtracts = false) => ({
  type: OfficesTypeKeys.SET_REFETCH_TRUST_EXTRACT,
  reFetchTrustExtracts: {
    fetchTrustExtracts,
  },
});
