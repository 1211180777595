import {
  BulkInvitationsAction,
  BulkInvitationsTypeKeys,
  IBulkInvitationState,
  IInvitationsData,
} from './types';

export const initialState: IBulkInvitationState = {
  isValidData: false,
  isDataError: false,
  invitationsData: {} as IInvitationsData,
  file: null,
  fileName: '',
  error: null,
  percent: 0,
  customError: '',
  validAccountsMessage: '',
  showBulkInvitationModal: false,
  files: [],
  showProgressBar: false,
  progressBarPercent: 0,
};

export default (
  state = initialState,
  action: BulkInvitationsAction
): IBulkInvitationState => {
  switch (action.type) {
    case BulkInvitationsTypeKeys.FILE_NAME_AVAILABLE: {
      return {
        ...state,
        customError: '',
        showProgressBar: true,
        fileName: action.fileName,
      };
    }
    case BulkInvitationsTypeKeys.UPDATE_PROGRESS_BAR: {
      return {
        ...state,
        customError: '',
        showProgressBar: true,
        progressBarPercent: action.newProgressBarValue,
      };
    }
    case BulkInvitationsTypeKeys.VALIDATE_FILE_ERROR: {
      return {
        ...state,
        customError: action.error?.toString(),
        error: action.error,
        isValidData: false,
        isDataError: true,
        validAccountsMessage: '',
        showProgressBar: true,
      };
    }
    case BulkInvitationsTypeKeys.SET_VALID_DATA: {
      const data = action.bulkInvitationsData || ({} as IInvitationsData);
      const numberOfValidAccounts = data.invitations.length;
      return {
        ...state,
        invitationsData: data || ({} as IInvitationsData),
        validAccountsMessage:
          numberOfValidAccounts > 0
            ? `${numberOfValidAccounts} accounts will be created and invited to ClarityFirst`
            : '',
        isValidData: true,
        isDataError: false,
        customError: '',
        showProgressBar: true,
      };
    }
    case BulkInvitationsTypeKeys.CLEAR_DATA:
      return {
        ...initialState,
        showBulkInvitationModal: state.showBulkInvitationModal,
      };
    case BulkInvitationsTypeKeys.SUBMIT_REQUEST_SUCCESS: {
      return {
        ...initialState,
        showBulkInvitationModal: false,
      };
    }
    case BulkInvitationsTypeKeys.SUBMIT_REQUEST_ERROR: {
      return {
        ...state,
        error: action.error,
        showBulkInvitationModal: false,
      };
    }
    case BulkInvitationsTypeKeys.CHANGE_MODAL_MODE:
      return {
        ...initialState,
        showBulkInvitationModal: action.showBulkInvitationModal,
      };
    default: {
      return state;
    }
  }
};
