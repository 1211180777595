import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default ({ style }: ISVGProps) => (
  <svg
    width="142"
    height="163"
    viewBox="0 0 142 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M63 162.37C97.7939 162.37 126 159.684 126 156.37C126 153.056 97.7939 150.37 63 150.37C28.2061 150.37 0 153.056 0 156.37C0 159.684 28.2061 162.37 63 162.37Z"
      fill="#CBD6E4"
    />
    <rect
      x="0.927729"
      y="1.07186"
      width="99.9866"
      height="124.117"
      rx="14"
      transform="matrix(0.997238 0.0742778 -0.0695083 0.997581 22.2939 3.54599)"
      fill="#AEBBCE"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <rect
      x="1.06408"
      y="0.926583"
      width="98.5847"
      height="124.119"
      rx="14"
      transform="matrix(0.997462 -0.0711952 0.0666218 0.997778 1.45366 9.18036)"
      fill="#C8D5E8"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <rect
      x="12.1865"
      y="1"
      width="101"
      height="124"
      rx="14"
      fill="white"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <rect x="29.1865" y="18" width="29" height="6" rx="2" fill="#BECFE8" />
    <rect x="29.1865" y="29" width="18" height="6" rx="2" fill="#BECFE8" />
    <rect x="29.1865" y="53" width="64" height="6" rx="2" fill="#BECFE8" />
    <rect x="29.1865" y="64" width="56" height="6" rx="2" fill="#BECFE8" />
    <rect x="29.1865" y="75" width="70" height="6" rx="2" fill="#BECFE8" />
    <rect x="29.1865" y="86" width="44" height="6" rx="2" fill="#BECFE8" />
    <rect x="29.1865" y="102" width="10" height="6" rx="2" fill="#BECFE8" />
    <rect x="80.1865" y="100" width="19" height="10" rx="2" fill="#BECFE8" />
    <circle cx="91.6865" cy="26.5" r="8.5" fill="#BECFE8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.19 106.742C140.19 126.481 124.253 142.486 104.595 142.486C84.9365 142.486 69 126.481 69 106.742C69 87.0022 84.9365 71 104.595 71C124.253 71 140.19 87.0022 140.19 106.742Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.19 106.742C140.19 126.481 124.253 142.486 104.595 142.486C84.9365 142.486 69 126.481 69 106.742C69 87.0022 84.9365 71 104.595 71C124.253 71 140.19 87.0022 140.19 106.742Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.452 106.741C132.452 122.19 119.981 134.712 104.593 134.712C89.208 134.712 76.7372 122.19 76.7372 106.741C76.7372 91.2927 89.208 78.7705 104.593 78.7705C119.981 78.7705 132.452 91.2927 132.452 106.741Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.594 86.5402C118.665 86.5402 130.264 97.0213 132.153 110.627C132.33 109.353 132.453 108.064 132.453 106.743C132.453 91.2943 119.98 78.7695 104.594 78.7695C89.2091 78.7695 76.7383 91.2943 76.7383 106.743C76.7383 108.064 76.8588 109.353 77.0383 110.627C78.9249 97.0213 90.5267 86.5402 104.594 86.5402Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.452 106.741C132.452 122.19 119.981 134.712 104.593 134.712C89.208 134.712 76.7372 122.19 76.7372 106.741C76.7372 91.2927 89.208 78.7705 104.593 78.7705C119.981 78.7705 132.452 91.2927 132.452 106.741Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101.249 90.8677C101.249 88.9377 102.814 87.373 104.744 87.373C106.674 87.373 108.239 88.9377 108.239 90.8677V112.878C108.239 114.808 106.674 116.373 104.744 116.373C102.814 116.373 101.249 114.808 101.249 112.878V90.8677Z"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M102.248 90.8692C102.248 89.4906 103.365 88.373 104.744 88.373C106.123 88.373 107.24 89.4906 107.24 90.8692V94.373L102.248 97.373V90.8692Z"
      fill="#E8F4FA"
    />
    <ellipse
      cx="104.744"
      cy="122.873"
      rx="3.49468"
      ry="3.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M107.24 122.456C107.24 122.873 106.123 122.348 104.744 122.348C103.365 122.348 102.248 122.873 102.248 122.456C102.248 121.306 103.365 120.373 104.744 120.373C106.123 120.373 107.24 121.306 107.24 122.456Z"
      fill="#E8F4FA"
    />
  </svg>
);
