import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00390625 0.583984H18.7378V13.0732H0.00390625V0.583984ZM9.37085 3.7063C10.1989 3.7063 10.9931 4.03526 11.5787 4.62081C12.1642 5.20635 12.4932 6.00053 12.4932 6.82862C12.4932 7.65671 12.1642 8.45088 11.5787 9.03643C10.9931 9.62198 10.1989 9.95093 9.37085 9.95093C8.54276 9.95093 7.74859 9.62198 7.16304 9.03643C6.5775 8.45088 6.24854 7.65671 6.24854 6.82862C6.24854 6.00053 6.5775 5.20635 7.16304 4.62081C7.74859 4.03526 8.54276 3.7063 9.37085 3.7063ZM4.16699 2.66553C4.16699 3.21759 3.94769 3.74704 3.55732 4.1374C3.16696 4.52777 2.63751 4.74707 2.08545 4.74707V8.91016C2.63751 8.91016 3.16696 9.12947 3.55732 9.51983C3.94769 9.9102 4.16699 10.4396 4.16699 10.9917H14.5747C14.5747 10.4396 14.794 9.9102 15.1844 9.51983C15.5747 9.12947 16.1042 8.91016 16.6563 8.91016V4.74707C16.1042 4.74707 15.5747 4.52777 15.1844 4.1374C14.794 3.74704 14.5747 3.21759 14.5747 2.66553H4.16699Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
