import { blue, forestGreen, persianRed, sun, trout } from 'config/theme';
import { SiteStatus } from './types';

const StatusColors = {
  [SiteStatus.Cancelled]: trout, // should probably be deprecated
  [SiteStatus.Canceled]: sun,
  [SiteStatus.Closed]: blue,
  [SiteStatus.OpenInError]: persianRed,
  [SiteStatus.Open]: forestGreen,
  [SiteStatus.Pending]: trout,
  [SiteStatus.Reserved]: persianRed,
};

export { StatusColors };
