import React from 'react';
import styled from 'styled-components';
import AccordionSnackbarBase from './AccordionSnackbarBase';
import AccordionSnackbarHeader from './AccordionSnackbarHeader';

interface IAccordionSnackbarProps {
  headerLabel: string;
  subtext?: React.ReactNode | string;
  onClose?: () => void;
}

const AccordionSnackbar: React.FC<IAccordionSnackbarProps> = ({
  children,
  headerLabel,
  subtext,
  onClose,
}) => {
  return (
    <AccordionSnackbarBase
      header={
        <AccordionSnackbarHeader
          label={headerLabel}
          subtext={subtext}
          onClose={onClose}
        />
      }
      showExpandIcon={!onClose}
    >
      <InnerContent>{children}</InnerContent>
    </AccordionSnackbarBase>
  );
};

const InnerContent = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 188px;
`;

export default AccordionSnackbar;
