import { Dispatch } from 'redux';

export enum NotificationsTypeKeys {
  SHOW = 'notifications/SHOW',
  HIDE = 'notifications/HIDE',
}

export interface INotificationsState {
  visible: boolean;
  tileProps: ITileProps | null;
  autohide: boolean;
}

export interface INotificationsShowAction {
  type: NotificationsTypeKeys.SHOW;
  tileProps: ITileProps;
  autohide: boolean;
}

export interface INotificationsHideAction {
  type: NotificationsTypeKeys.HIDE;
}

export type NotificationsAction =
  | INotificationsShowAction
  | INotificationsHideAction;

export interface INotificationTileProps {
  dispatch: Dispatch;
  autohide: boolean;
  visible: boolean;
  tileProps: ITileProps;
}

export interface ITileProps {
  backgroundColor?: string;
  className?: string;
  title?: string;
  children?: any;
  borderedIcon?: boolean;
  customIcon?: any;
  icon?: string;
  iconColor?: string;
  textColor?: string;
  textAlign?: string;
  value?: string;
  animated?: boolean;
  shadow?: string;
  showButton?: boolean;
  buttonTitle?: string;
  buttonBackgroundColor?: string;
  buttonHoverBackgroundColor?: string;
  buttonHoverTextColor?: string;
  buttonBorder?: string;
  buttonHoverBorder?: string;
}
