import React from 'react';
import {
  ProgressBarKeys,
  IProgressData,
  TaskStatus,
} from 'store/ProgressBar/types';
import workspaceAPI from 'services/workspaceAPI';
import useInterval from 'hooks/useInterval';
import styled from 'styled-components';
import { headerZIndex, white } from 'config/theme';
import { useDispatch } from 'react-redux';
import {
  setFailProgressItem,
  setSuccessProgressItem,
} from 'store/ProgressBar/actions';

const rowHeight = 63;
const maxVisibleRows = 3;
const headerHeight = 56;

interface IProgressBarCoreProps {
  progressHeader: () => React.ReactNode;
  progressRows: () => React.ReactNode;
  progressBarItems: IProgressData[];
  isExpanded: boolean;
  pollingDelay: number;
  identifier: ProgressBarKeys;
}

export const ProgressBarCore: React.FC<IProgressBarCoreProps> = ({
  progressHeader,
  isExpanded,
  progressRows,
  progressBarItems,
  pollingDelay,
  identifier,
}) => {
  const dispatch = useDispatch();
  // Execute each of the polling url for all the jobs in processing status
  // success function evaluates to true, update the status to success, if request fails, job fails.
  // note that the even pending or waiting response has to return a 200 series code, not 400/500 series code.
  const genericRequestExecute = async () => {
    progressBarItems?.forEach(async (progress) => {
      if (progress.status === TaskStatus.PROCESSING) {
        var currentProgress = progressBarItems.find(
          (x) => x.progressUniqueId === progress.progressUniqueId
        );
        if (currentProgress) {
          const { pollingUrl, successFunction } = currentProgress;
          try {
            const response = await workspaceAPI.get(pollingUrl);
            if (successFunction(response)) {
              dispatch(
                setSuccessProgressItem({
                  identifier: identifier,
                  progressItem: currentProgress,
                })
              );
            }
          } catch {
            dispatch(
              setFailProgressItem({
                identifier: identifier,
                progressItem: currentProgress,
              })
            );
          }
        }
      }
    });
  };

  useInterval(() => {
    genericRequestExecute();
  }, pollingDelay);

  if (!progressBarItems?.length) {
    return null;
  }

  return (
    <FixedContainer id={`${identifier}__Progress__bar`}>
      {progressHeader()}
      {isExpanded && (
        <div
          style={{ overflowY: 'auto', maxHeight: rowHeight * maxVisibleRows }}
        >
          {progressRows()}
        </div>
      )}
    </FixedContainer>
  );
};

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${rowHeight * maxVisibleRows + headerHeight}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  margin-left: 16px;
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
`;
