import * as React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

const WireDesktopIntercept = (props: ISVGProps) => (
  <svg
    width={200}
    height={200}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M99 170.369c39.212 0 71-2.91 71-6.5s-31.788-6.5-71-6.5-71 2.91-71 6.5 31.788 6.5 71 6.5Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187.739 114.508v19.002c0 5.249-4.238 9.501-9.472 9.501H50.469c-5.23 0-9.469-4.252-9.469-9.501v-19.002H187.74Z"
      fill="#F8FBFF"
    />
    <path
      clipRule="evenodd"
      d="M187 115.369v18.667c0 5.156-4.217 9.333-9.424 9.333H50.421c-5.204 0-9.421-4.177-9.421-9.333v-18.667h146Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 129.369a5 5 0 0 1-5.002 5 5 5 0 1 1 5.002-5Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M120 129.369a5 5 0 0 1-5.002 5 5 5 0 1 1 5.002-5Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.047 162.013h-47.34l2.366-19.002h42.611l2.363 19.002Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M138 162.369H91l2.35-19h42.304l2.346 19Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m187.754 114.509-.016-76.008c0-5.248-4.238-9.501-9.468-9.501H50.468C45.238 29 41 33.253 41 38.5l.015 76.009h146.739Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.938 114.509 145.152 29H50.466c-5.226 0-9.464 4.253-9.464 9.5l.015 76.009h18.921Z"
      fill="#fff"
    />
    <path
      d="M77 162.369h75"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="m187 115.369-.015-76.444c0-5.278-4.216-9.556-9.42-9.556H50.42c-5.204 0-9.42 4.278-9.42 9.556l.015 76.444H187Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.702 120.431c0 22.877-18.514 41.425-41.351 41.425S12 143.308 12 120.431s18.514-41.423 41.35-41.423c22.838 0 41.352 18.546 41.352 41.423Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.702 120.431c0 22.877-18.514 41.425-41.351 41.425S12 143.308 12 120.431s18.514-31.423 41.35-31.423c22.838 0 41.352 8.546 41.352 31.423Z"
      fill="#E6EDF7"
    />
    <path
      clipRule="evenodd"
      d="M94.702 120.431c0 22.877-18.514 41.425-41.351 41.425S12 143.308 12 120.431s18.514-41.423 41.35-41.423c22.838 0 41.352 18.546 41.352 41.423Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.713 120.431c0 17.904-14.488 32.416-32.364 32.416-17.873 0-32.36-14.512-32.36-32.416 0-17.905 14.487-32.417 32.36-32.417 17.876 0 32.364 14.512 32.364 32.417Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.35 97.018c16.346 0 29.82 12.147 32.015 27.915.206-1.476.349-2.971.349-4.501 0-17.904-14.49-32.42-32.364-32.42-17.873 0-32.36 14.516-32.36 32.42 0 1.53.14 3.025.348 4.501 2.192-15.768 15.67-27.915 32.012-27.915Z"
      fill="#F0F0F0"
    />
    <path
      clipRule="evenodd"
      d="M85.713 120.431c0 17.904-14.488 32.416-32.364 32.416-17.873 0-32.36-14.512-32.36-32.416 0-17.905 14.487-32.417 32.36-32.417 17.876 0 32.364 14.512 32.364 32.417Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54 106.369v-5"
      stroke="#9BABC2"
      strokeWidth={4}
      strokeLinecap="round"
    />
    <path d="M54 106.369v-5" stroke="#E8F4FA" strokeLinecap="round" />
    <path
      d="M54 142.369v-5"
      stroke="#9BABC2"
      strokeWidth={4}
      strokeLinecap="round"
    />
    <path d="M54 142.369v-5" stroke="#E8F4FA" strokeLinecap="round" />
    <path
      d="M46 129.618c0 4.279 3.357 7.751 7.499 7.751 4.144 0 7.501-3.472 7.501-7.751 0-4.277-3.357-7.749-7.501-7.749-4.142 0-7.499-3.472-7.499-7.751 0-4.28 3.357-7.749 7.499-7.749 4.144 0 7.501 3.469 7.501 7.749"
      stroke="#9BABC2"
      strokeWidth={5}
      strokeLinecap="round"
    />
    <path
      d="M46 129.618c0 4.279 3.357 7.751 7.499 7.751 4.144 0 7.501-3.472 7.501-7.751 0-4.277-3.357-7.749-7.501-7.749-4.142 0-7.499-3.472-7.499-7.751 0-4.28 3.357-7.749 7.499-7.749 4.144 0 7.501 3.469 7.501 7.749"
      stroke="#BECFE6"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M46 114.118c0-4.28 3.357-7.749 7.499-7.749 4.144 0 7.501 3.469 7.501 7.749"
      stroke="#E8F4FA"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default WireDesktopIntercept;
