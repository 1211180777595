import {
  ApiTypeKeys,
  IApiRequestAction,
  IApiSuccessAction,
  IApiError,
} from './types';

export const apiRequest = (
  method: string,
  url: string,
  body: any,
  params: object,
  entity: string
): IApiRequestAction => ({
  type: `${entity} ${ApiTypeKeys.API_REQUEST}`,
  payload: {
    data: body,
    meta: { method, url, params, entity },
  },
});

export const apiSuccess = (
  response: any,
  entity: string
): IApiSuccessAction => ({
  type: `${entity} ${ApiTypeKeys.API_SUCCESS}`,
  payload: {
    data: response,
    meta: { entity },
  },
});

export const apiError = (error: any, entity: string): IApiError => ({
  type: `${entity} ${ApiTypeKeys.API_ERROR}`,
  payload: {
    data: error,
    meta: { entity },
  },
});
