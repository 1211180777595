export enum GoogleMapTypeKeys {
  ENTITY = '[googleMap]',
  // Generic actions used to update current request state
  REQUEST = 'googleMap/REQUEST',
  SUCCESS = 'googleMap/SUCCESS',
  ERROR = 'googleMap/ERROR',
  // Store different types of data received via sXHR
  SET_PREDICTIVE_SEARCH_REQUEST = 'googlemap/SET_PREDICTIVE_SEARCH_REQUEST',
  SET_PREDICTIVE_SEARCH_RESPONSE = 'googlemap/SET_PREDICTIVE_SEARCH_RESPONSE',
  SET_GOOGLE_OBJECT = 'googlemap/SET_GOOGLE_OBJECT',
  SET_LAT_LNG = 'googlemap/SET_CENTER',
  SET_BOUNDS = 'googlemap/SET_BOUNDS',
  SET_STREETVIEW_PANORAMA_DATA = 'googlemap/SET_STREETVIEW_PANORAMA_DATA',
  SET_CURRENT_ZOOM_LEVEL = 'googlemap/SET_CURRENT_ZOOM_LEVEL',
  SET_PREDICTIVE_QUERY = 'googlemap/SET_PREDICTIVE_QUERY',
  SET_GEOCODER_LOCATION_TYPE = 'googlemap/SET_GEOCODER_LOCATION_TYPE',
  SET_GEOCODE_ADDRESS_TYPE = 'googlemap/SET_GEOCODE_ADDRESS_TYPE',
  SET_POLYGON_COLOR = 'googlemap/SET_POLYGON_COLOR',
  SET_OVERLAY_OPTION = 'googlemap/SET_OVERLAY_OPTION',
  SET_MAP_TYPE = 'googlemap/SET_MAP_TYPE',
  SET_MAP_LABEL_OPTION = 'googlemap/SET_MAP_LABEL_OPTION',
  SET_RECENTLY_VIEWED_PROPERTIES = 'googlemap/SET_RECENTLY_VIEWED_PROPERTIES',
}

export interface IGoogleMapState {
  loading: boolean;
  error: string | null;
  mapConfig: google.maps.MapOptions;
  bounds: any;
  currentZoomLevel: any;
  predictiveSearchQuery: string | null;
  geocoderLocationType: string | null;
  latitude: number;
  longitude: number;
  streetViewPanoramaData: google.maps.StreetViewPanoramaData | null;
  autocompletionRequest: google.maps.places.AutocompletionRequest | null;
  autocompletePredictions: IAutocompletePrediction[];
  googleObj: any;
  geocoderAddressType: string | null;
  polygonColor: string;
  mapOverlayOption: IMapOverlayOption;
  mapLabelOption: IMapLabelOption;
  recentlyViewedProperties: IRecentlyViewedProperty[];
}

export interface IAutocompletePrediction
  extends google.maps.places.AutocompletePrediction {
  id: string;
}

export interface IMapOverlayOption {
  selectedLayer: string;
  isLayerOn: boolean;
}

export interface IMapType {
  mapType: string;
}

export interface IMapLabelOption {
  showTerrain: boolean;
  showLabel: boolean;
  showPOI: boolean;
}

export interface IRecentlyViewedProperty {
  propertyId: string;
  latitude: string;
  longitude: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  country: string;
}

export interface IRecentlyViewedProperties {
  properties: IRecentlyViewedProperty[];
}

export type GoogleMapActions =
  | IGoogleMapSuccessAction
  | IGoogleMapErrorAction
  | IGoogleMapPredictiveSearchRequest
  | IGoogleMapPredictiveSearchRespose
  | IGoogleMapSetGoogleObject
  | IGoogleMapRequest
  | IGoogleMapSetLatLng
  | IGoogleMapSetStreetViewPanoramaData
  | IGoogleMapSetBounds
  | IGoogleMapSetCurrentZoomLevel
  | IGoogleMapSetPredictiveQuery
  | IGoogleMapSetGeocoderLocationType
  | IGoogleMapSetGeocodeAddressTypes
  | IGoogleMapsSetPolygonColor
  | IGoogleMapsSetOverlayOption
  | IGoogleMapSetMapType
  | IGoogleMapSetMapLabelOption
  | IGoogleMapRecentlyViewedProperties;

interface IGoogleMapSuccessAction {
  type: GoogleMapTypeKeys.SUCCESS;
}

interface IGoogleMapErrorAction {
  type: GoogleMapTypeKeys.ERROR;
  error: string;
}

interface IGoogleMapPredictiveSearchRequest {
  type: GoogleMapTypeKeys.SET_PREDICTIVE_SEARCH_REQUEST;
  request: google.maps.places.AutocompletionRequest;
}

interface IGoogleMapPredictiveSearchRespose {
  type: GoogleMapTypeKeys.SET_PREDICTIVE_SEARCH_RESPONSE;
  searchResponse: IAutocompletePrediction[];
}

interface IGoogleMapSetGoogleObject {
  type: GoogleMapTypeKeys.SET_GOOGLE_OBJECT;
  googleObjResp: any;
}

interface IGoogleMapRequest {
  type: GoogleMapTypeKeys.REQUEST;
}

interface IGoogleMapSetLatLng {
  type: GoogleMapTypeKeys.SET_LAT_LNG;
  coordinates: google.maps.LatLng;
}

interface IGoogleMapSetStreetViewPanoramaData {
  type: GoogleMapTypeKeys.SET_STREETVIEW_PANORAMA_DATA;
  streetViewPanoramaData: google.maps.StreetViewPanoramaData;
}

interface IGoogleMapSetBounds {
  type: GoogleMapTypeKeys.SET_BOUNDS;
  viewport: any;
}

interface IGoogleMapSetCurrentZoomLevel {
  type: GoogleMapTypeKeys.SET_CURRENT_ZOOM_LEVEL;
  zoom: number;
}

interface IGoogleMapSetPredictiveQuery {
  type: GoogleMapTypeKeys.SET_PREDICTIVE_QUERY;
  query: string;
}

interface IGoogleMapSetGeocoderLocationType {
  type: GoogleMapTypeKeys.SET_GEOCODER_LOCATION_TYPE;
  locationType: string;
}

interface IGoogleMapSetGeocodeAddressTypes {
  type: GoogleMapTypeKeys.SET_GEOCODE_ADDRESS_TYPE;
  addressType: string;
}

interface IGoogleMapsSetPolygonColor {
  type: GoogleMapTypeKeys.SET_POLYGON_COLOR;
  polygonColor: string;
}

interface IGoogleMapsSetOverlayOption {
  type: GoogleMapTypeKeys.SET_OVERLAY_OPTION;
  mapOverlayOption: IMapOverlayOption;
}

interface IGoogleMapSetMapType {
  type: GoogleMapTypeKeys.SET_MAP_TYPE;
  mapType: IMapType;
}

interface IGoogleMapSetMapLabelOption {
  type: GoogleMapTypeKeys.SET_MAP_LABEL_OPTION;
  mapLabelOption: IMapLabelOption;
}

interface IGoogleMapRecentlyViewedProperties {
  type: GoogleMapTypeKeys.SET_RECENTLY_VIEWED_PROPERTIES;
  recentlyViewedProperties: IRecentlyViewedProperty[];
}

export enum geocoderAddressTypes {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  NEIGHBORHOOD = 'neighborhood',
  LOCALITY = 'locality',
  SUBLOCALITY = 'sublocality',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
  INTERSECTION = 'intersection',
  STREET_ADDRESS = 'street_address',
}

export enum MarkerType {
  ACTIVE,
  INACTIVE,
}
