import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M9.92481 4.3101C10.6197 4.3101 11.2861 4.59349 11.7774 5.09793C12.2687 5.60236 12.5448 6.28653 12.5448 6.99991C12.5448 7.71329 12.2687 8.39745 11.7774 8.90189C11.2861 9.40633 10.6197 9.68972 9.92481 9.68972C9.22996 9.68972 8.56357 9.40633 8.07223 8.90189C7.5809 8.39745 7.30487 7.71329 7.30487 6.99991C7.30487 6.28653 7.5809 5.60236 8.07223 5.09793C8.56357 4.59349 9.22996 4.3101 9.92481 4.3101ZM9.92481 0.275391C14.2914 0.275391 18.0204 3.06382 19.5313 6.99991C18.0204 10.936 14.2914 13.7244 9.92481 13.7244C5.55824 13.7244 1.82919 10.936 0.318359 6.99991C1.82919 3.06382 5.55824 0.275391 9.92481 0.275391ZM2.22218 6.99991C3.66315 10.0125 6.64115 11.9312 9.92481 11.9312C13.2085 11.9312 16.1865 10.0125 17.6274 6.99991C16.1865 3.98732 13.2085 2.0686 9.92481 2.0686C6.64115 2.0686 3.66315 3.98732 2.22218 6.99991Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
