import React from 'react';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core/IconButton';
import styled from 'styled-components';
import StyledReactTooltip, {
  IStyledReactTooltipProps,
} from 'components/ToolTip/StyledReactTooltip';
import { IDesignSystemIconProps } from 'icons/types';
import themes, { IconButtonColor, IIconButtonTheme } from './themes';

interface ITooltipProps extends IStyledReactTooltipProps {
  id: string;
  text: string;
  /**
   * Function or tag name: span, div, etc.
   * @example
   * container: StyledSpan
   * container: 'div'
   */
  container?: React.ElementType<{ children: React.ReactNode }>;
}

interface IIconButtonProps extends Omit<MuiIconButtonProps, 'color' | 'size'> {
  /**
   * The icon component.
   * @example icon={Star}
   */
  icon: React.FC<IDesignSystemIconProps>;
  /**
   * The color of the component.
   * @example color={IconButtonColor.RED}
   */
  color?: IconButtonColor;
  /**
   * The tooltip props for the component.
   * @example
   * tooltip={{
   *   id: 'edit-tooltip',
   *   text: 'Edit',
   * }}
   */
  tooltip?: ITooltipProps;
}

/**
 * Icon buttons are used to initiate actions closely associated to the icon they are made up of.
 * @see https://zeroheight.com/8fd9b3c86/p/929305-icon-button
 */
const IconButton = ({
  icon: Icon,
  color = IconButtonColor.NEUTRAL_GRAY,
  tooltip,
  ...buttonProps
}: IIconButtonProps) => {
  const theme = themes[color];
  const { iconColor, disabledIconColor } = theme;
  const iconFill = buttonProps.disabled ? disabledIconColor : iconColor;

  const button = (
    <StyledMuiIconButton $theme={theme} {...buttonProps}>
      <Icon fill={iconFill} />
    </StyledMuiIconButton>
  );

  if (!tooltip) {
    return button;
  }

  const { id, text, container, ...tooltipProps } = tooltip;
  const TooltipContainer = container ?? StyledTooltipContainer;
  const ONE_SECOND = 1000;

  return (
    <TooltipContainer data-tip={text} data-for={id} tabIndex={0}>
      {button}
      <StyledReactTooltip
        id={id}
        delayShow={tooltip.delayShow ?? ONE_SECOND}
        effect="solid"
        place="bottom"
        padding="8px"
        {...tooltipProps}
      />
    </TooltipContainer>
  );
};

export default IconButton;

interface IStyledMuiIconButtonProps {
  $theme: IIconButtonTheme;
}

const StyledMuiIconButton = styled(MuiIconButton)<IStyledMuiIconButtonProps>`
  --hover-bg-color: ${({ $theme }) => $theme.hoverBackgroundColor};
  --active-bg-color: ${({ $theme }) => $theme.activeBackgroundColor};
  --focus-box-shadow: ${({ $theme }) => $theme.focusBoxShadow};
  --padding: 8px;
  --edge-margin: calc(-1 * var(--padding) - 2px);

  padding: var(--padding);

  &:hover {
    background-color: var(--hover-bg-color);
  }

  // Focused by keyboard.
  &:focus-visible {
    box-shadow: var(--focus-box-shadow);
  }

  // Active (pressed).
  .MuiTouchRipple-child {
    background-color: var(--active-bg-color);
  }

  &.Mui-focusVisible {
    // Hide the ripple when focused by keyboard.
    .MuiTouchRipple-child.MuiTouchRipple-childPulsate {
      background-color: transparent;
    }
  }

  &.MuiIconButton-edgeStart {
    margin-inline-start: var(--edge-margin);
  }

  &.MuiIconButton-edgeEnd {
    margin-inline-end: var(--edge-margin);
  }
`;

const StyledTooltipContainer = styled.span`
  display: inline-block; // Expands the mouse detection area.
  position: relative;
`;
