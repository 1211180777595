import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    fill="none"
    height="132"
    style={props.style}
    viewBox="0 0 106 132"
    width="106"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86171 114.782H95.1901C97.4843 114.782 99.3424 112.909 99.3424 110.602V91.3775C99.3424 89.0685 97.4843 87.1982 95.1901 87.1982H9.86171C7.56967 87.1982 5.70947 89.0685 5.70947 91.3775V110.602C5.70947 112.909 7.56967 114.782 9.86171 114.782Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86171 114.782H95.1901C97.4843 114.782 99.3424 112.909 99.3424 110.602V91.3775C99.3424 89.0685 97.4843 87.1982 95.1901 87.1982H9.86171C7.56967 87.1982 5.70947 89.0685 5.70947 91.3775V110.602C5.70947 112.909 7.56967 114.782 9.86171 114.782Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.9134 46.9722H20.1386C17.8445 46.9722 15.9863 45.0999 15.9863 42.7929V5.17929C15.9863 2.87232 17.8445 1 20.1386 1H84.9134C87.2055 1 89.0657 2.87232 89.0657 5.17929V42.7929C89.0657 45.0999 87.2055 46.9722 84.9134 46.9722Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.4977 1H20.5533C18.0308 1 15.9858 3.0583 15.9858 5.59722V11.4252L50.0466 33.5943C51.556 34.5743 53.4971 34.5743 55.0065 33.5943L89.0652 11.4252V5.59722C89.0652 3.0583 87.0202 1 84.4977 1Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.9134 46.9722H20.1386C17.8445 46.9722 15.9863 45.0999 15.9863 42.7929V5.17929C15.9863 2.87232 17.8445 1 20.1386 1H84.9134C87.2055 1 89.0657 2.87232 89.0657 5.17929V42.7929C89.0657 45.0999 87.2055 46.9722 84.9134 46.9722Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.052 128.552C105.052 130.458 81.5354 132 52.5258 132C23.5162 132 0 130.458 0 128.552C0 126.649 23.5162 125.106 52.5258 125.106C81.5354 125.106 105.052 126.649 105.052 128.552Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9858 5.59771V5.95086L50.0466 28.1178C51.556 29.102 53.4971 29.102 55.0065 28.1178L89.0652 5.95086V5.59771C89.0652 3.05879 87.0202 1.00049 84.4977 1.00049H20.5533C18.0308 1.00049 15.9858 3.05879 15.9858 5.59771Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.2601 6.47536L89.0657 5.95086V5.59771C89.0657 3.05879 87.0207 1.00049 84.4982 1.00049H20.5538C18.0313 1.00049 15.9863 3.05879 15.9863 5.59771V5.95086L16.7919 6.47536H88.2601Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9858 5.59771V5.95086L50.0466 28.1178C51.556 29.102 53.4971 29.102 55.0065 28.1178L89.0652 5.95086V5.59771C89.0652 3.05879 87.0202 1.00049 84.4977 1.00049H20.5533C18.0308 1.00049 15.9858 3.05879 15.9858 5.59771Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5537 46.9722L43.8478 24.0864"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.4977 46.9722L61.2036 24.0864"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.3772 68.8095C58.746 68.8095 58.2353 68.2955 58.2353 67.6602V53.8685C58.2353 53.2333 57.7246 52.7192 57.0934 52.7192H47.9585C47.3274 52.7192 46.8166 53.2333 46.8166 53.8685V67.6602C46.8166 68.2955 46.3059 68.8095 45.6748 68.8095H42.7225C42.2596 68.8095 41.8443 69.0895 41.6679 69.52C41.4914 69.9484 41.589 70.4415 41.9149 70.7696L51.7184 80.639C52.1647 81.0883 52.8872 81.0883 53.3336 80.639L63.137 70.7696C63.463 70.4415 63.6855 69.854 63.509 69.4256C63.3326 68.9952 62.7924 68.8095 62.3294 68.8095H59.3772Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.3772 68.8095C58.746 68.8095 58.2353 68.2955 58.2353 67.6602V53.8685C58.2353 53.2333 57.7246 52.7192 57.0934 52.7192H47.9585C47.3274 52.7192 46.8166 53.2333 46.8166 53.8685V67.6602C46.8166 68.2955 46.3059 68.8095 45.6748 68.8095H42.7225C42.2596 68.8095 41.8443 69.0895 41.6679 69.52C41.4914 69.9484 41.589 70.4415 41.9149 70.7696L51.7184 80.639C52.1647 81.0883 52.8872 81.0883 53.3336 80.639L63.137 70.7696C63.463 70.4415 63.5605 69.9484 63.3841 69.52C63.2076 69.0895 62.7924 68.8095 62.3294 68.8095H59.3772Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3424 87.1978C99.3424 84.6589 97.2974 82.6006 94.7749 82.6006H76.0213C74.2297 82.6006 72.6041 83.6559 71.8629 85.2962L68.5868 92.5494C67.8456 94.1898 66.22 95.245 64.4283 95.245H40.6235C38.8319 95.245 37.2063 94.1898 36.4651 92.5494L33.189 85.2962C32.4478 83.6559 30.8222 82.6006 29.0305 82.6006H10.2769C7.75445 82.6006 5.70947 84.6589 5.70947 87.1978V92.9443C5.70947 90.4054 7.75445 88.3471 10.2769 88.3471H25.6049C27.3966 88.3471 29.0222 89.4024 29.7634 91.0428L33.0395 98.2959C33.7807 99.9363 35.4063 100.992 37.1979 100.992H67.8539C69.6456 100.992 71.2712 99.9363 72.0124 98.2959L75.2885 91.0428C76.0297 89.4024 77.6553 88.3471 79.4469 88.3471H94.7749C97.2974 88.3471 99.3424 90.4054 99.3424 92.9443V87.1978Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3424 87.1978C99.3424 84.6589 97.2974 82.6006 94.7749 82.6006H76.0213C74.2297 82.6006 72.6041 83.6559 71.8629 85.2962L68.5868 92.5494C67.8456 94.1898 66.22 95.245 64.4283 95.245H40.6235C38.8319 95.245 37.2063 94.1898 36.4651 92.5494L33.189 85.2962C32.4478 83.6559 30.8222 82.6006 29.0305 82.6006H10.2769C7.75445 82.6006 5.70947 84.6589 5.70947 87.1978V92.9443C5.70947 90.4054 7.75445 88.3471 10.2769 88.3471H25.6049C27.3966 88.3471 29.0222 89.4024 29.7634 91.0428L33.0395 98.2959C33.7807 99.9363 35.4063 100.992 37.1979 100.992H67.8539C69.6456 100.992 71.2712 99.9363 72.0124 98.2959L75.2885 91.0428C76.0297 89.4024 77.6553 88.3471 79.4469 88.3471H94.7749C97.2974 88.3471 99.3424 90.4054 99.3424 92.9443V87.1978Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.446 88.3467C77.6543 88.3467 76.0287 89.402 75.2875 91.0423L72.0135 98.2955C71.2723 99.9359 69.6467 100.991 67.855 100.991H37.197C35.4053 100.991 33.7797 99.9359 33.0385 98.2955L29.7645 91.0423C29.0233 89.402 27.3977 88.3467 25.606 88.3467H10.276C7.75555 88.3467 5.7085 90.405 5.7085 92.9439V110.183C5.7085 112.722 7.75555 114.781 10.276 114.781H94.776C97.2964 114.781 99.3414 112.722 99.3414 110.183V92.9439C99.3414 90.405 97.2964 88.3467 94.776 88.3467H79.446Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.7749 110.184H10.2769C7.75445 110.184 5.70947 108.128 5.70947 105.587V110.184C5.70947 112.723 7.75445 114.781 10.2769 114.781H94.7749C97.2974 114.781 99.3424 112.723 99.3424 110.184V105.587C99.3424 108.128 97.2974 110.184 94.7749 110.184Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.446 88.3467C77.6543 88.3467 76.0287 89.402 75.2875 91.0423L72.0135 98.2955C71.2723 99.9359 69.6467 100.991 67.855 100.991H37.197C35.4053 100.991 33.7797 99.9359 33.0385 98.2955L29.7645 91.0423C29.0233 89.402 27.3977 88.3467 25.606 88.3467H10.276C7.75555 88.3467 5.7085 90.405 5.7085 92.9439V110.183C5.7085 112.722 7.75555 114.781 10.276 114.781H94.776C97.2964 114.781 99.3414 112.722 99.3414 110.183V92.9439C99.3414 90.405 97.2964 88.3467 94.776 88.3467H79.446Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
