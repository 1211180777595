import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.653 17.6L11 14.795L6.347 17.6L7.579 12.309L3.476 8.756L8.888 8.294L11 3.3L13.112 8.283L18.524 8.745L14.421 12.298L15.653 17.6ZM11 0C4.917 0 0 4.95 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C4.24327 19.7996 5.4559 20.6099 6.79048 21.1627C8.12506 21.7155 9.55546 22 11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 9.55546 21.7155 8.12506 21.1627 6.79048C20.6099 5.4559 19.7996 4.24327 18.7782 3.22183C17.7567 2.20038 16.5441 1.39013 15.2095 0.837325C13.8749 0.284523 12.4445 0 11 0Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
