/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_Forms_FilePartyContactForm } from '../models/Workspace_API_Forms_FilePartyContactForm';
import type { Workspace_API_ViewModels_BusinessParties_FilePartyViewModel } from '../models/Workspace_API_ViewModels_BusinessParties_FilePartyViewModel';
import type { Workspace_Common_Models_FileBusinessPartyEmployee } from '../models/Workspace_Common_Models_FileBusinessPartyEmployee';
import type { Workspace_Common_Models_FileBusinessPartyPartyType } from '../models/Workspace_Common_Models_FileBusinessPartyPartyType';
import type { Workspace_Common_Models_FilePartyContact } from '../models/Workspace_Common_Models_FilePartyContact';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderPartiesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get parties associated with an Order (Auth)
   * @returns Workspace_API_ViewModels_BusinessParties_FilePartyViewModel Successfully retrieved parties.
   * @throws ApiError
   */
  public getPartiesAsync({
    orderId,
  }: {
    /**
     * a order Id
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_BusinessParties_FilePartyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/parties',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `Can not access order`,
        404: `OrderId does not exist`,
      },
    });
  }

  /**
   * For a given orderId, retrieve the related business parties. (Auth)
   * @returns Workspace_Common_Models_FileBusinessPartyEmployee Business parties response
   * @throws ApiError
   */
  public getPartiesByType({
    orderId,
    partyType,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Party Type
     */
    partyType: Workspace_Common_Models_FileBusinessPartyPartyType,
  }): CancelablePromise<Array<Workspace_Common_Models_FileBusinessPartyEmployee>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/parties/{partyType}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      errors: {
        400: `Invalid party type`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Add a contact to an order (Auth)
   * @returns Workspace_Common_Models_FilePartyContact FilePartyContact
   * @throws ApiError
   */
  public addPartyContact({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * FilePartyContact
     */
    requestBody: Workspace_API_Forms_FilePartyContactForm,
  }): CancelablePromise<Workspace_Common_Models_FilePartyContact> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/parties/contacts',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Remove a contact from an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public removePartyContactAsync({
    orderId,
    contactId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Contact ID
     */
    contactId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/parties/contacts/{contactId}',
      path: {
        'orderId': orderId,
        'contactId': contactId,
      },
      errors: {
        403: `External Users do not have permission`,
        404: `Contact does not exist or order does not exist`,
      },
    });
  }

  /**
   * Revoke Access for a contact from an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public revokeOrderAccessAsync({
    orderId,
    partyId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Party ID
     */
    partyId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/parties/contacts/{partyId}/_revoke',
      path: {
        'orderId': orderId,
        'partyId': partyId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Forbidden`,
        404: `Contact does not exist or order does not exist`,
      },
    });
  }

  /**
   * Revoke Access for a contact from an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public grantOrderAccessAsync({
    orderId,
    partyId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Party ID
     */
    partyId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/parties/contacts/{partyId}/_grant',
      path: {
        'orderId': orderId,
        'partyId': partyId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Forbidden`,
        404: `Contact does not exist or order does not exist`,
      },
    });
  }

}
