import { CreateDocumentStateMap, IDocumentCreateSuccessAction } from './types';

export const updateTrackedDocuments = (
  current: CreateDocumentStateMap,
  updates: IDocumentCreateSuccessAction['trackDocumentUpdate']
) => {
  const clone = { ...current };
  updates.forEach((update) => {
    const tracked = clone[update.name];
    if (tracked) {
      tracked.status = update.status;
      tracked.documentId = update.documentId;
    }
  });

  return clone;
};

export const failTrackedDocuments = (current: CreateDocumentStateMap) => {
  const clone = { ...current };
  for (const docKey in clone) {
    clone[docKey].status = 'error';
  }

  return clone;
};
