import { Box, Column } from 'components/ui/Box';
import { default as MButton } from 'components/ui/MButton';
import { white } from 'config/colors';
import styled from 'styled-components';

export enum DisplaySize {
  DESKTOP = 1440,
  LAPTOP = 1024,
  TABLET = 768,
  MOBILE = 414,
}

export const Page = styled(Column)`
  background-color: ${white};
  min-height: 100vh;
  overflow: hidden;
  padding-top: 32px;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    padding-top: 24px;
  }
`;

export const Logo = styled.div`
  display: block;
  pointer-events: none;
  text-align: left;

  svg {
    height: 41px;
    width: 229px;
  }
`;

export const Section = styled(Box).attrs({
  as: 'section',
})<{ $color?: string }>`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  padding-top: 12px;

  ${({ $color }) => $color && `background-color: ${$color};`}
`;

export const Content = styled(Column)`
  max-width: ${DisplaySize.DESKTOP}px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
`;

export const ExternalButtonLink = styled(MButton).attrs({
  component: 'a',
  rel: 'noopener noreferrer',
  target: '_blank',
})``;

export const ExternalLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})``;

export const ButtonLink = styled(ExternalLink)`
  color: ${white};
`;
