import React from 'react';
import styled from 'styled-components';
import { AppNotificationType } from 'store/appNotifications/types';
import { cadetBlue, lightBlue, white } from 'config/theme';
import {
  NotificationActionIcon,
  NotificationKnoxIcon,
  NotificationOrderIcon,
  NotificationDocumentIcon,
  CheckIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import TextIcon from 'icons/Text';
import InvoiceNotification from 'icons/InvoiceNotification';
import FolderStar from 'icons/FolderStar';

interface IRowIconProps {
  notificationType: AppNotificationType;
  visited: boolean;
}

export default ({ notificationType, visited }: IRowIconProps) => {
  const Icon = {
    [AppNotificationType.ADD_WIRE_INSTRUCTION]: NotificationKnoxIcon,
    [AppNotificationType.ADDED_TO_ORDER]: NotificationOrderIcon,
    [AppNotificationType.VERIFY_WIRE_INSTRUCTION]: NotificationActionIcon,
    [AppNotificationType.SHARED_DOCUMENTS]: NotificationDocumentIcon,
    [AppNotificationType.ADDED_WIRE_INSTRUCTION]: NotificationKnoxIcon,
    [AppNotificationType.VERIFIED_WIRE_INSTRUCTION]: NotificationKnoxIcon,
    [AppNotificationType.MILESTONE_DIGEST]: NotificationActionIcon,
    [AppNotificationType.MILESTONE_UPDATE]: CheckIcon,
    [AppNotificationType.CUSTOMER_DOCUMENT_UPLOADS]: NotificationDocumentIcon,
    [AppNotificationType.INVOICE_CREATED]: NotificationDocumentIcon,
    [AppNotificationType.INVOICE_APPROVED]: NotificationDocumentIcon,
    [AppNotificationType.INVOICE_REJECTED]: NotificationDocumentIcon,
    [AppNotificationType.ORDER10999S_UPDATES]: NotificationDocumentIcon,
    [AppNotificationType.SETTLEMENT_STATEMENT_COMMENTS]: TextIcon,
    [AppNotificationType.PSFINVOICE_UPDATES]: InvoiceNotification,
    [AppNotificationType.ORDER_CHECKLIST]: FolderStar,
  }[notificationType];

  return (
    <IconContainer visited={visited}>
      <Icon
        style={
          notificationType == AppNotificationType.PSFINVOICE_UPDATES
            ? { width: 28, height: 28 }
            : { width: 14, height: 14 }
        }
        fill={white}
      />
    </IconContainer>
  );
};

const IconContainer = styled.div<{ visited: boolean }>`
  align-items: center;
  background: ${({ visited }) => (!visited ? lightBlue : cadetBlue)};
  border-radius: 50%;
  display: flex;
  flex: 0 0 26px;
  height: 26px;
  justify-content: center;
  width: 26px;
`;
