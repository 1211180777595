import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M11.9996 9.45H4.95723L7.06894 11.5145L4.25 14.2477L7.06894 16.9906L9.88789 14.2477L11.9996 16.3025V9.45Z"
      fill="#4372B0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.125 0H10.625L17 6.3V18.9C17 20.055 16.0438 21 14.875 21H2.11437C0.945625 21 0 20.055 0 18.9V2.1C0 0.945 0.95625 0 2.125 0ZM2.125 18.9H14.875V7.35H9.5625V2.1H2.125V18.9Z"
      fill="#4372B0"
    />
  </svg>
);
