import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M10 4.36602C9.27668 4.36602 8.58299 4.66101 8.07153 5.18612C7.56006 5.71122 7.27273 6.42341 7.27273 7.16602C7.27273 7.90862 7.56006 8.62081 8.07153 9.14592C8.58299 9.67102 9.27668 9.96602 10 9.96602C10.7233 9.96602 11.417 9.67102 11.9285 9.14592C12.4399 8.62081 12.7273 7.90862 12.7273 7.16602C12.7273 6.42341 12.4399 5.71122 11.9285 5.18612C11.417 4.66101 10.7233 4.36602 10 4.36602ZM10 11.8327C8.79447 11.8327 7.63832 11.341 6.78588 10.4658C5.93344 9.59068 5.45455 8.40369 5.45455 7.16602C5.45455 5.92834 5.93344 4.74135 6.78588 3.86618C7.63832 2.99101 8.79447 2.49935 10 2.49935C11.2055 2.49935 12.3617 2.99101 13.2141 3.86618C14.0666 4.74135 14.5455 5.92834 14.5455 7.16602C14.5455 8.40369 14.0666 9.59068 13.2141 10.4658C12.3617 11.341 11.2055 11.8327 10 11.8327ZM10 0.166016C5.45455 0.166016 1.57273 3.06868 0 7.16602C1.57273 11.2633 5.45455 14.166 10 14.166C14.5455 14.166 18.4273 11.2633 20 7.16602C18.4273 3.06868 14.5455 0.166016 10 0.166016Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
