import styled from 'styled-components';

import {
  black,
  blue,
  blueHover,
  lighterBlue,
  bluePressed,
  darkGray,
  white,
  dropdownBG,
} from 'config/theme';

interface IListItemProps {
  isLoading?: boolean;
  disabled?: boolean;
  selected?: boolean;
}

interface IMenuProps {
  width?: number;
}

export const ListItem = styled.li<IListItemProps>`
  height: 48px;
  padding: 8px 16px;
  color: ${black};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Circular, sans-serif;

  svg {
    margin-right: 16px;
    width: 24px;
  }

  ${({ isLoading }) => (isLoading ? 'max-width: 150px;' : '')}

  ${({ disabled, isLoading }) =>
    disabled || isLoading
      ? `background-color: inherit;
         color: ${darkGray};
         pointer-events: none;

        &:hover {
          background-color: inherit;
          color: ${darkGray};
        }`
      : ''}

  &:hover {
    background-color: ${blueHover};
    color: ${black};
  }

  ${({ selected }) =>
    selected
      ? `background-color: ${blue};
         color: ${white};

         &:hover {
          background-color: ${blue};
          color: ${white};
         }
         `
      : ''}

  &:active {
    background-color: ${bluePressed};
    color: ${black};
  }
`;

export const ListItemContent = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  width: 100%;
`;

/* eslint-disable import/no-unused-modules */
export const MenuDivider = styled.hr`
  margin: 8px 0;
  border: 1px solid ${lighterBlue};
`;

export const MENU_PADDING_Y = 8;
export const MenuContainer = styled.ul<IMenuProps>`
  background-color: ${white};
  list-style: none;
  position: relative;
  border-radius: 3px;
  padding: ${MENU_PADDING_Y}px 0;
  box-shadow: ${dropdownBG};
  ${({ width }) => `width: ${width ? width + 'px' : 'auto'}`};
`;
