import workspaceAPI from 'services/workspaceAPI';
import { ApplicationStateGetter, AsyncAction, Dispatch } from 'store';
import { addToast } from 'store/ui/actions';
import {
  OrderTypeKeys,
  IOrderOverdraft,
  IOverdraftRecoverableType,
  IOverdraftCause,
  IOverdraftRecoverableParty,
} from '../types';

export const getOrderOverdrafts = (orderId: string) => {
  return (dispatch) => {
    return workspaceAPI
      .get<{ overdrafts: IOrderOverdraft[] }>(`/orders/${orderId}/overdrafts`)
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.REQUEST_ORDER_OVERDRAFTS_SUCCESS,
          overdrafts: data?.overdrafts || [],
        });
      })
      .catch(() => {
        addToast(
          'There was an error loading order overdrafts.',
          'error'
        )(dispatch);
      });
  };
};

export const getOverdraftRecoverableTypes = (): AsyncAction<void> => {
  return (dispatch: Dispatch, getState: ApplicationStateGetter) => {
    const { orders } = getState();

    if (orders?.overdraftRecoverableTypes?.length > 0) {
      return Promise.resolve();
    }

    return workspaceAPI
      .get<IOverdraftRecoverableType[]>('/lookups/overdraft-recoverable-types')
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.REQUEST_OVERDRAFTS_RECOVERABLE_TYPES_SUCCESS,
          overdraftRecoverableTypes: data || [],
        });
      })
      .catch(() => {
        addToast(
          'There was an error loading overdraft recoverable types.',
          'error'
        )(dispatch);
      });
  };
};

export const getOverdraftCauses = (): AsyncAction<void> => {
  return (dispatch: Dispatch, getState: ApplicationStateGetter) => {
    const { orders } = getState();

    if (orders?.overdraftCauses?.length > 0) {
      return Promise.resolve();
    }

    return workspaceAPI
      .get<IOverdraftCause[]>('/lookups/overdraft-causes')
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.REQUEST_OVERDRAFTS_CAUSES_SUCCESS,
          overdraftCauses: data || [],
        });
      })
      .catch(() => {
        addToast(
          'There was an error loading overdraft causes.',
          'error'
        )(dispatch);
      });
  };
};

export const getOverdraftRecoverableParties = (
  orderId: string
): AsyncAction<void> => {
  return (dispatch: Dispatch, getState: ApplicationStateGetter) => {
    const { orders } = getState();

    if (orderId && orders?.overdraftRecoverableParties?.length > 0) {
      return Promise.resolve();
    }

    return workspaceAPI
      .get<IOverdraftRecoverableParty[]>(
        `/orders/${orderId}/overdrafts/recoverable-parties`
      )
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.REQUEST_OVERDRAFTS_RECOVERABLE_PARTIES_SUCCESS,
          overdraftRecoverableParties: data || [],
        });
      })
      .catch(() => {
        addToast(
          'There was an error loading overdraft recoverable parties.',
          'error'
        )(dispatch);
      });
  };
};
