import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M20.6145 4.86387L11.8145 10.3639L3.01445 4.86387V2.66387L11.8145 8.16387L20.6145 2.66387V4.86387ZM20.6145 0.463867H3.01445C1.79345 0.463867 0.814453 1.44287 0.814453 2.66387V15.8639C0.814453 16.4473 1.04624 17.0069 1.45882 17.4195C1.8714 17.8321 2.43098 18.0639 3.01445 18.0639H20.6145C21.1979 18.0639 21.7575 17.8321 22.1701 17.4195C22.5827 17.0069 22.8145 16.4473 22.8145 15.8639V2.66387C22.8145 1.44287 21.8245 0.463867 20.6145 0.463867Z"
      fill={props.fill || shuttleGrey}
    />
  </svg>
);
