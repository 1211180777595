import React from 'react';
import styled from 'styled-components';
import { MagnifierIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import {
  lighterBlue,
  outerSpaceBlack,
  shuttleGrey,
  blue,
  trout,
  lightningYellow,
  white,
  subheaderZIndex,
} from 'config/theme';
import ClearButton from './ClearButton';

const SearchInputContainer = styled.form`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const SearchInput = styled.input<{ clearSearchButtonActive?: boolean }>`
  align-items: center;
  align-self: center;
  background: ${white};
  border: 1px solid ${lighterBlue};
  border-radius: 4px;
  color: ${outerSpaceBlack};
  display: flex;
  outline: 0;
  height: ${(props) => props.height || '40'}px;
  line-height: 1;
  padding: ${(props) =>
    Boolean(props.clearSearchButtonActive) ? '0 48px 0 52px' : '0 0 0 52px'};
  width: 100%;
  position: relative;
  left: 4.5px;

  &::placeholder {
    color: ${trout};
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    border-radius: 4px;
    box-shadow: 0 0 0 1px ${lightningYellow}, 0 0 0 4px rgba(255, 203, 33, 0.6);
  }
`;

interface ISearchFieldProps {
  onSubmit: (evt: React.FormEvent<HTMLFormElement>) => void;
  placeholder: null | string;
  inputRef?: (node: null) => void;
  focused?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClearButtonClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  onClearButtonMouseOver?: (evt: React.MouseEvent<HTMLElement>) => void;
  onClearButtonMouseOut?: (evt: React.MouseEvent<HTMLElement>) => void;
  autoComplete?: null | string;
  inputHeight?: number;
  domId?: string;
  clearButtonId?: string;
}

const SearchField = ({
  inputRef,
  focused,
  onSubmit,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  onClearButtonClick,
  onClearButtonMouseOver,
  onClearButtonMouseOut,
  autoComplete,
  inputHeight,
  domId,
  clearButtonId,
}: ISearchFieldProps) => (
  <SearchInputContainer onSubmit={onSubmit} className="searchForm">
    <SearchInput
      name="q"
      type="text"
      placeholder={placeholder || undefined}
      ref={inputRef}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      autoComplete={autoComplete || undefined}
      id={domId || 'propertySearchBox'}
      height={inputHeight}
      clearSearchButtonActive={Boolean(onClearButtonClick)}
    />
    <MagnifierIcon
      fill={focused ? blue : shuttleGrey}
      style={{
        order: -1,
        marginRight: '-38px',
        zIndex: subheaderZIndex - 1,
      }}
    />
    <ClearButton
      onClick={onClearButtonClick}
      className="searchForm__clear"
      id={clearButtonId}
      visible={Boolean(onClearButtonClick)}
      onMouseOver={onClearButtonMouseOver}
      onMouseOut={onClearButtonMouseOut}
    />
  </SearchInputContainer>
);

export default SearchField;
