import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M0.0546875 0.294922L4.05469 4.29492L8.05469 0.294922L0.0546875 0.294922Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
