/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Disbursements_ChargesViewModel_ICharge_ } from '../models/Workspace_API_ViewModels_Disbursements_ChargesViewModel_ICharge_';
import type { Workspace_Common_Models_ICharge } from '../models/Workspace_Common_Models_ICharge';
import type { Workspace_Common_Models_MiscellaneousCharge } from '../models/Workspace_Common_Models_MiscellaneousCharge';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MiscellaneousChargesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all charges for an ECP (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_ChargesViewModel_ICharge_ Order has disbursements
   * @throws ApiError
   */
  public getChargesAsync({
    orderId,
    fileProcessId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The file process Id
     */
    fileProcessId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_ChargesViewModel_ICharge_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/miscellaneous/{fileProcessId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Adds charge in FAST/Eclipse (Auth)
   * @returns Workspace_Common_Models_ICharge Charge was created
   * @throws ApiError
   */
  public addChargeAsync({
    orderId,
    fileProcessId,
    requestBody,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The Id of the ECP
     */
    fileProcessId: number,
    /**
     * The object containing all information needed to create the charge line.
     */
    requestBody: Workspace_Common_Models_MiscellaneousCharge,
  }): CancelablePromise<Workspace_Common_Models_ICharge> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/miscellaneous/{fileProcessId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Updates a charge in FAST/Eclipse (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateChargeAsync({
    orderId,
    fileProcessId,
    requestBody,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The Id of the ECP
     */
    fileProcessId: number,
    /**
     * The object containing all information needed to update the charge line.
     */
    requestBody: Workspace_Common_Models_MiscellaneousCharge,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/charges/miscellaneous/{fileProcessId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Get a specific charge line (Auth)
   * @returns Workspace_Common_Models_ICharge Order has disbursements
   * @throws ApiError
   */
  public getChargeAsync({
    orderId,
    fileProcessId,
    chargeId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The file process Id
     */
    fileProcessId: number,
    /**
     * The Id of the charge line
     */
    chargeId: number,
  }): CancelablePromise<Workspace_Common_Models_ICharge> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/miscellaneous/{fileProcessId}/chargeLine/{chargeId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Deletes a charge in FAST/Eclipse (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteChargeAsync({
    orderId,
    fileProcessId,
    chargeId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The Id of the ECP
     */
    fileProcessId: number,
    /**
     * The id of the charge line to be deleted.
     */
    chargeId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/charges/miscellaneous/{fileProcessId}/chargeLine/{chargeId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        422: `Request validation failed`,
      },
    });
  }

}
