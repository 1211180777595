import { AnyAction } from 'redux';
import { FiltersTypeKeys, IFilters } from 'store/searchFilters/types';
import {
  DealStatusFilter,
  EstimatedCloseDateFilter,
  LocationFilter,
  OwningOfficeFilter,
  FileTypeFilter,
} from 'utils/searchFilters';

export function setFilters(filters: IFilters): AnyAction {
  return {
    type: FiltersTypeKeys.SET,
    filters,
  };
}

const filterClassMap = {
  dealStatus: DealStatusFilter,
  estimatedCloseDate: EstimatedCloseDateFilter,
  location: LocationFilter,
  owningOffice: OwningOfficeFilter,
  siteType: FileTypeFilter,
};

export function filtersToURLParams(filters: IFilters): string[] {
  return Object.keys(filterClassMap).reduce((allParams, name) => {
    const filterClass = filterClassMap[name];
    const value = filters[name];
    return allParams.concat(filterClass.toURLParams(value));
  }, []);
}

export function filtersFromURLParams(queryString: string): IFilters {
  return Object.keys(filterClassMap).reduce((filters, name) => {
    const filterClass = filterClassMap[name];
    filters[name] = filterClass.fromURLParams(queryString);
    return filters;
  }, {}) as IFilters;
}
