import useAPIRequest, { IRequestOptions } from './useAPIRequest';

/**
 * @deprecated Use or `useMutation that allows usage of the generated workspace API client code
 * Send a HTTP request using react hooks. Uses workspaceAPI to send out the request
 */
function useAPIMutation<TData = any, TError = any, TRequestData = any>(
  config: Omit<IRequestOptions, 'method'> & {
    method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  }
) {
  return useAPIRequest<TData, TError, TRequestData>({
    ...config,
    method: config.method || 'POST',
  });
}

export default useAPIMutation;
