/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Loans_LoanCreationViewModel } from '../models/Workspace_API_ViewModels_Loans_LoanCreationViewModel';
import type { Workspace_API_ViewModels_Loans_LoanDetailsViewModel } from '../models/Workspace_API_ViewModels_Loans_LoanDetailsViewModel';
import type { Workspace_API_ViewModels_Loans_LoanDisbursementChargesModel } from '../models/Workspace_API_ViewModels_Loans_LoanDisbursementChargesModel';
import type { Workspace_API_ViewModels_Loans_LoanDisbursementRequestModel } from '../models/Workspace_API_ViewModels_Loans_LoanDisbursementRequestModel';
import type { Workspace_API_ViewModels_Loans_LoanResponseViewModel } from '../models/Workspace_API_ViewModels_Loans_LoanResponseViewModel';
import type { Workspace_API_ViewModels_Loans_LoansViewModel } from '../models/Workspace_API_ViewModels_Loans_LoansViewModel';
import type { Workspace_API_ViewModels_Loans_LoanUpdateViewModel } from '../models/Workspace_API_ViewModels_Loans_LoanUpdateViewModel';
import type { Workspace_API_ViewModels_Loans_RecapInfoViewModel } from '../models/Workspace_API_ViewModels_Loans_RecapInfoViewModel';
import type { Workspace_Common_Models_Enums_LoanKind } from '../models/Workspace_Common_Models_Enums_LoanKind';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoansService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get loan details by loanId. (Auth)
   * @returns Workspace_API_ViewModels_Loans_LoanDetailsViewModel Valid loan of the specified kind
   * @throws ApiError
   */
  public getLoanDetailsAsync({
    orderId,
    loanKind,
    loanId,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
    /**
     * The kind of loan (Payoff, New, Assumption)
     */
    loanKind: Workspace_Common_Models_Enums_LoanKind,
    /**
     * The loan id
     */
    loanId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Loans_LoanDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/Loans/{loanKind}/{loanId}',
      path: {
        'orderId': orderId,
        'loanKind': loanKind,
        'loanId': loanId,
      },
      errors: {
        401: `Invalid authorization header`,
        404: `Loan or order not found`,
      },
    });
  }

  /**
   * Remove a loan from an order. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteLoanAsync({
    orderId,
    loanKind,
    loanId,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
    /**
     * The kind of loan (Payoff, New or Assumption)
     */
    loanKind: Workspace_Common_Models_Enums_LoanKind,
    /**
     * The loan id
     */
    loanId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/Loans/{loanKind}/{loanId}',
      path: {
        'orderId': orderId,
        'loanKind': loanKind,
        'loanId': loanId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order or loan not found`,
        409: `Cannot delete loan due to issued disbursements.`,
      },
    });
  }

  /**
   * Get all loans for an order. (Auth)
   * @returns Workspace_API_ViewModels_Loans_LoansViewModel Valid order with zero or more payoff loans
   * @throws ApiError
   */
  public getLoansAsync({
    orderId,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Loans_LoansViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/Loans',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Add a loan to an order. (Auth)
   * @returns Workspace_API_ViewModels_Loans_LoanResponseViewModel Loan successfully created
   * @throws ApiError
   */
  public createLoanAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
    /**
     * The details of the loan to be created
     */
    requestBody?: Workspace_API_ViewModels_Loans_LoanCreationViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Loans_LoanResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/Loans',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order or loan not found`,
      },
    });
  }

  /**
   * Update a loan. (Auth)
   * @returns Workspace_API_ViewModels_Loans_LoanResponseViewModel Loan successfully updated
   * @throws ApiError
   */
  public updateLoanAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
    /**
     * The details of the loan to be updated
     */
    requestBody?: Workspace_API_ViewModels_Loans_LoanUpdateViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Loans_LoanResponseViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/Loans',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order or loan not found`,
      },
    });
  }

  /**
   * Disburse loan charges to a payee. (Auth)
   * @returns Workspace_API_ViewModels_Loans_LoanDisbursementChargesModel Disbursements successfully created
   * @throws ApiError
   */
  public disburseLoanChargesAsync({
    orderId,
    loanKind,
    loanId,
    requestBody,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
    /**
     * The kind of loan (New, Payoff, Assumption)
     */
    loanKind: Workspace_Common_Models_Enums_LoanKind,
    /**
     * The ID of the loan
     */
    loanId: number,
    /**
     * The payee details and charges
     */
    requestBody?: Workspace_API_ViewModels_Loans_LoanDisbursementRequestModel,
  }): CancelablePromise<Workspace_API_ViewModels_Loans_LoanDisbursementChargesModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/Loans/{loanKind}/{loanId}/_disburseCharges',
      path: {
        'orderId': orderId,
        'loanKind': loanKind,
        'loanId': loanId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        422: `Unable to process disbursements`,
      },
    });
  }

  /**
   * Get a recap for an existing loan.
   * Only new loan is currently supported. (Auth)
   * @returns Workspace_API_ViewModels_Loans_RecapInfoViewModel Loan recap.
   * @throws ApiError
   */
  public getLoanRecapAsync({
    orderId,
    loanId,
  }: {
    /**
     * Order (file) id.
     */
    orderId: number,
    /**
     * Loan id.
     */
    loanId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Loans_RecapInfoViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/Loans/{loanId}/recap',
      path: {
        'orderId': orderId,
        'loanId': loanId,
      },
      errors: {
        401: `Invalid authorization header.`,
        404: `Loan recap not found.`,
      },
    });
  }

  /**
   * Delete a payee disbursment from a loan. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteLoanDisbursementAsync({
    orderId,
    loanKind,
    loanId,
    disbursementId,
  }: {
    /**
     * The order id (file id)
     */
    orderId: number,
    /**
     * The kind of loan (Payoff, New or Assumption)
     */
    loanKind: Workspace_Common_Models_Enums_LoanKind,
    /**
     * The loan id
     */
    loanId: number,
    /**
     * The disbursement id
     */
    disbursementId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/Loans/{loanKind}/{loanId}/disbursements/{disbursementId}',
      path: {
        'orderId': orderId,
        'loanKind': loanKind,
        'loanId': loanId,
        'disbursementId': disbursementId,
      },
      errors: {
        400: `Invalid request: order, loan or disbursementId`,
        401: `Invalid Authorization header`,
        404: `Order or loan not found`,
      },
    });
  }

}
