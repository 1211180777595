import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1111 3.75H1.88889L0 15H17L15.1111 3.75ZM1.88889 13.125L3.30556 5.625H13.6625L15.1111 13.125H1.88889Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 2.8125C11.3333 1.2592 10.0648 0 8.5 0C6.93519 0 5.66667 1.2592 5.66667 2.8125V3.75H8.5H11.3333V2.8125ZM8.5 3.75C7.9784 3.75 7.55556 3.33027 7.55556 2.8125C7.55556 2.29473 7.9784 1.875 8.5 1.875C9.0216 1.875 9.44444 2.29473 9.44444 2.8125C9.44444 3.33027 9.0216 3.75 8.5 3.75Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M7.53158 8.02063C7.53158 8.41675 7.80243 8.67065 8.6448 8.91663C9.48718 9.16687 10.3889 9.56665 10.3889 10.7501C10.3889 11.604 9.81367 12.0831 9.08997 12.229V13.125H7.97674V12.2211C7.26411 12.0502 6.65569 11.5375 6.61111 10.625H7.41998C7.46824 11.1169 7.76892 11.5002 8.53351 11.5002C9.35344 11.5002 9.53544 11.0419 9.53544 10.7544C9.53544 10.3668 9.34975 10 8.42222 9.74976C7.38677 9.47083 6.67782 8.9917 6.67782 8.02917C6.67782 7.22473 7.25673 6.69983 7.97674 6.52527V5.625H9.08997V6.53748C9.86563 6.74988 10.2552 7.40845 10.2813 8.125H9.46105C9.43891 7.60437 9.19388 7.24976 8.53351 7.24976C7.90634 7.24976 7.53158 7.56653 7.53158 8.02063Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
