/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_TaxesAndFees_SplitFeeResultsViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_SplitFeeResultsViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_SplitFeesViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_SplitFeesViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_SplitStateViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_SplitStateViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderTaxesAndFeesSplitFeesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Allows to get the summary of the fees for a given order. (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_SplitFeesViewModel The fee summary obtained.
   * @throws ApiError
   */
  public fetchSplitFeesAsync({
    orderId,
  }: {
    /**
     * The order id to check for.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_SplitFeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/split-fees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user.`,
        403: `User doesn't have the required activity right.`,
        404: `Order or Fee Not Found or External user.`,
      },
    });
  }

  /**
   * Perform the add or update split fee action. (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_SplitFeeResultsViewModel Success
   * @returns any The results of the create or update split fee operation.
   * @throws ApiError
   */
  public splitFeeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order id which will be affected by the operation.
     */
    orderId: number,
    /**
     * The split fee details.
     */
    requestBody: Workspace_API_ViewModels_TaxesAndFees_SplitFeesViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_SplitFeeResultsViewModel | any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/split-fees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated user.`,
        403: `User doesn't have the required activity right.`,
        404: `Order or Fee Not Found or External user.`,
      },
    });
  }

  /**
   * Adds or updates split state information to a split fee (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public addsOrUpdatesSplitStates({
    orderId,
    serviceFileFeeId,
    requestBody,
  }: {
    /**
     * The order to which the splits belong
     */
    orderId: number,
    /**
     * The split fee id
     */
    serviceFileFeeId: number,
    /**
     * The split state details
     */
    requestBody: Array<Workspace_API_ViewModels_TaxesAndFees_SplitStateViewModel>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/split-fees/{serviceFileFeeId}/split-states',
      path: {
        'orderId': orderId,
        'serviceFileFeeId': serviceFileFeeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
