import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { store, history } from 'store';

import ApiRedirect from 'components/ApiRedirect';
import AuthRoute from 'components/AuthRoute';
import Snackbar from './components/Snackbar';
import {
  InternalError,
  Unauthorized,
  SecurityError,
  AccountDeactivated,
} from './views/Error';
import MobileOnly from 'views/MobileOnly';
import PublicLandingPage from 'views/PublicLandingPage';
import App from './App';
import { StylesProvider } from '@material-ui/core/styles';

import 'intersection-observer'; // IntersectionObserver Polyfill for IE11
import 'url-search-params-polyfill'; // Polyfill for IE and Edge
import 'formdata-polyfill'; // Polyfill for IE and Edge
import 'url-polyfill'; // Polyfill for IE11 (to support URL())
import './utils/String.prototype.polyfills'; // Polyfill for IE11
import './utils/Array.prototype.polyfills'; // Polyfill for IE11
import { shouldRedirectOrigin } from './utils/originRedirect';
import 'normalize.css';
import './index.css';
import { initializeAI, trackEvent, trackPageView } from 'utils/appInsights';
import {
  clearAfterLoginRedirectPath,
  getAfterLoginRedirectPath,
} from 'utils/auth';
import { clearWhatfixIdentifiers } from 'store/auth/actions';
import { SignupExpired } from 'views/SignupExpired';
// Initialize App Insights
initializeAI();

// Track history changes
let prevLocation = history.location.pathname;
history.listen((location) => {
  if (prevLocation != location.pathname) {
    trackPageView({ name: location.pathname });
  }
  prevLocation = location.pathname;
});

// Track uncaught exceptions to AppInsights
window.onerror = (message, _url, _lineNo, _columnNo, error) => {
  const stack = error && error.stack;
  trackEvent('Client Uncaught Exception', { message, stack });
  // Returning false means the default event handler will be run
  return false;
};

const target = document.getElementById('root');

function loadApp() {
  render(
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ConnectedRouter history={history}>
          <>
            <Switch>
              <Route exact path="/mobile" component={MobileOnly} />
              <Route exact path="/error/auth" component={InternalError} />
              <Route
                exact
                path="/error/unauthorized"
                component={Unauthorized}
              />
              <Route exact path="/error/security" component={SecurityError} />
              <Route exact path="/error/403" component={AccountDeactivated} />
              <Route exact path="/SignupExpired" component={SignupExpired} />
              <Route
                exact
                path="/customer/signup"
                render={({ location }: any) => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/signup${location.search}`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/forgot-password"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/forgot-password`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/forgot-username"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/forgot-username`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/edit-profile"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/edit-profile`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/view-profile"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/view-profile`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/edit-phone"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/edit-phone`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/reset-password"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/reset-password`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/ea-opt-in"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/ea-opt-in`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/ea-opt-out"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/ea-opt-out`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/signin"
                render={({ location }) => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/signin${location.search}`}
                  />
                )}
              />
              <Route
                exact
                path="/customer/signout"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/external/signout`}
                  />
                )}
              />
              <Route
                exact
                path="/employee/signout"
                render={() => (
                  <ApiRedirect
                    to={`${AppConfig.workspaceBackendUrl}/auth/signout`}
                  />
                )}
              />
              <Route
                exact
                path={['/customer/signed-out', '/employee/signed-out']}
                render={(props: any) => {
                  // first check if we were supposed to redirect somewhere following logout
                  const redirectPath = getAfterLoginRedirectPath();
                  clearAfterLoginRedirectPath();
                  clearWhatfixIdentifiers();
                  // if so, go there
                  if (redirectPath) return <Redirect to={redirectPath} />;
                  // otherwise, continue on to the public landing page
                  return <PublicLandingPage {...props} />;
                }}
              />
              <AuthRoute authView={App} guestView={PublicLandingPage} />
            </Switch>

            <Snackbar />

            <Helmet defer={false}>
              <title>ClarityFirst</title>
            </Helmet>
          </>
        </ConnectedRouter>
      </StylesProvider>
    </Provider>,
    target
  );
}

if (shouldRedirectOrigin()) {
  window.location.href = `${AppConfig.redirectTarget}${window.location.pathname}${window.location.search}`;
} else if (
  isMobileOnly &&
  ['/mobile', '/customer/signup', '/help', '/'].indexOf(
    window.location.pathname
  ) === -1
) {
  window.location.href = '/mobile';
} else {
  // Track '/' as default page
  trackPageView({ name: '/' });
  loadApp();
}
