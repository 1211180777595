import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { shadow3 } from 'config/theme';
import { white, neutralGray7 } from 'config/colors';
import { Column } from '../Box';
import Option, { IPopoverOption } from './Option';

interface IOptionsProps {
  hasHeader: boolean;
  options: IPopoverOption[];
  popupWidth?: number | string;
  id?: string;
  setOpen: (open: boolean) => void;
  triggerRef: HTMLElement | null;
  leftAlign?: boolean;
  popupPosition?: 'bottom' | 'top';
  popupMaxHeight?: number | string;
  hasFooter?: boolean;
  zIndex?: number;
}

export default function Options({
  hasHeader,
  leftAlign,
  options,
  popupWidth,
  setOpen,
  id,
  triggerRef,
  popupPosition,
  popupMaxHeight,
  hasFooter,
  zIndex,
}: IOptionsProps) {
  const [position, setPosition] = useState<'bottom' | 'top'>(
    popupPosition || 'bottom'
  );
  const optionsContainer = useRef<HTMLDivElement>(null as any);
  const [height, setHeight] = useState<number>(0);

  // Change the menu position to "top" in case its location on the screen is
  // near the bottom, so that menu content will always be visible and clickable.
  //
  // Do it via effect because the menu height is indeterminate. Needs to render
  // before we can know the height. This causes a barely-noticeable "flicker"
  // effect, but I don't think there's any way to avoid this other than by
  // fixing row height or setting a maximum, which would allow pre-calculation.
  useEffect(
    () => {
      if (!optionsContainer.current) return;
      const el = optionsContainer.current;
      if (!popupPosition)
        setPosition(
          el.getBoundingClientRect().height >
            window.innerHeight - top - (50 + (hasFooter ? 80 : 0))
            ? 'top'
            : 'bottom'
        );
      setHeight(el.getBoundingClientRect().height);
    },
    // Only check this once on mount since menu content height shouldn't
    // change over component lifetime. Technically it could since the
    // option content can be any JSX, but this would probably be bad UI,
    // and IMO not something we need to take into account at this time.
    []
  );
  if (!triggerRef) return null;

  const topOffset =
    options.length > 5 && options.length < 10 ? options.length * 15 : 0;
  const top =
    position === 'top'
      ? triggerRef.getBoundingClientRect().top + topOffset
      : triggerRef.getBoundingClientRect().top - topOffset;
  return (
    <OptionsContainer
      hasHeader={hasHeader}
      position={position}
      id={id}
      width={popupWidth || 300}
      height={height}
      maxHeight={popupMaxHeight}
      leftAlign={leftAlign}
      data-testid="options-container"
      zIndex={zIndex}
    >
      {/* attach the ref here versus directly on OptionsContainer because SC
      innerRef prop seems to only work properly with old-style refs (not with
      the kind returned by useRef()) */}
      <div ref={optionsContainer}>
        {options
          .filter((option) => !option.hidden)
          .map((option, index) => (
            <Option
              index={index}
              key={index}
              option={option}
              setOpen={setOpen}
            />
          ))}
      </div>
    </OptionsContainer>
  );
}

interface IOptionsContainerProps {
  hasHeader: boolean;
  height: number;
  leftAlign?: boolean;
  position: 'bottom' | 'top';
  width: number | string;
  maxHeight?: number | string;
  zIndex?: number;
}

export const OPTIONS_PADDING_TOP = 8;
export const OptionsContainer = styled(Column)<IOptionsContainerProps>`
  background: ${white};
  border-radius: 3px;
  box-shadow: ${shadow3};
  position: absolute;
  z-index: ${({ zIndex }) => zIndex || 3};
  ${({ leftAlign }) => (leftAlign ? 'left' : 'right')}: 0px;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  ${({ hasHeader }) => !hasHeader && `padding-top: ${OPTIONS_PADDING_TOP}px;`}

  ${({ maxHeight }) =>
    !!maxHeight
      ? `max-height: ${
          typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight
        }; overflow-y: auto;`
      : ''}

  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${white};
    border: 4px solid ${white};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${neutralGray7};
    border: 1px solid ${neutralGray7};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${neutralGray7};
  }

  ${({ position, height }) =>
    position === 'bottom' ? 'top: 100%;' : `top: -${height}px`}
`;
OptionsContainer.displayName = 'OptionsContainer';
