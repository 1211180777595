import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';
import React from 'react';

const VIEWBOX_SIZE = 24;

export default ({
  className,
  fill = neutralGray7,
  size = VIEWBOX_SIZE,
  style,
}: IDesignSystemIconProps) => (
  <svg
    className={className}
    fill="none"
    height={size}
    style={style}
    viewBox={`0 0 ${VIEWBOX_SIZE} ${VIEWBOX_SIZE}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
    />
  </svg>
);
