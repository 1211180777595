/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Features_LoanAllocation_ViewModels_GetAllocationChargeDetailsViewModel } from '../models/Workspace_Common_Features_LoanAllocation_ViewModels_GetAllocationChargeDetailsViewModel';
import type { Workspace_Common_Features_LoanAllocation_ViewModels_GetLoansAllocationsViewModel } from '../models/Workspace_Common_Features_LoanAllocation_ViewModels_GetLoansAllocationsViewModel';
import type { Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModel } from '../models/Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModel';
import type { Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModelResponse } from '../models/Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModelResponse';
import type { Workspace_Common_Models_Enums_AllocationType } from '../models/Workspace_Common_Models_Enums_AllocationType';
import type { Workspace_Common_Models_Enums_LoanKind } from '../models/Workspace_Common_Models_Enums_LoanKind';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoanAllocationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get the charge and the allocations for the charge using the loan id as param (get all charge for a loan) or a specific charge using charge line id
   * @returns Workspace_Common_Features_LoanAllocation_ViewModels_GetAllocationChargeDetailsViewModel Success
   * @throws ApiError
   */
  public getAllocationsByLoan({
    orderId,
    loanKind,
    loanId,
    chargeLineId,
    isInterest = false,
  }: {
    /**
     * project file id
     */
    orderId: number,
    /**
     * loan kind
     */
    loanKind: Workspace_Common_Models_Enums_LoanKind,
    /**
     * loan id
     */
    loanId: number,
    /**
     * charge line id
     */
    chargeLineId?: number,
    /**
     * boolean indicating if the charge is an interest charge
     */
    isInterest?: boolean,
  }): CancelablePromise<Workspace_Common_Features_LoanAllocation_ViewModels_GetAllocationChargeDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/loan-allocations/{loanKind}/{loanId}/charge-lines',
      path: {
        'orderId': orderId,
        'loanKind': loanKind,
        'loanId': loanId,
      },
      query: {
        'chargeLineId': chargeLineId,
        'isInterest': isInterest,
      },
    });
  }

  /**
   * Get all loans charges and allocations for a project file
   * @returns Workspace_Common_Features_LoanAllocation_ViewModels_GetLoansAllocationsViewModel Successfully fetched all allocatable charges and allocations
   * @throws ApiError
   */
  public getAllocationsByOrderIdAsync({
    orderId,
    allocationType,
    siteFileId,
    groupId,
  }: {
    /**
     * The project file id
     */
    orderId: number,
    allocationType?: Workspace_Common_Models_Enums_AllocationType,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_Common_Features_LoanAllocation_ViewModels_GetLoansAllocationsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/loan-allocations',
      path: {
        'orderId': orderId,
      },
      query: {
        'AllocationType': allocationType,
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        400: `Bad Request`,
        404: `Project file not found`,
      },
    });
  }

  /**
   * Save allocations for a single chargeline
   * @returns Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModelResponse Success
   * @throws ApiError
   */
  public saveAllocationsForCharge({
    orderId,
    loanKind,
    loanId,
    chargeLineId,
    requestBody,
  }: {
    /**
     * project order id
     */
    orderId: number,
    /**
     * Tke kind of loan: New, Payoff or Assumption
     */
    loanKind: Workspace_Common_Models_Enums_LoanKind,
    /**
     * loan id of the allocations
     */
    loanId: number,
    /**
     * charge line id of the allocations
     */
    chargeLineId: number,
    /**
     * allocations to save
     */
    requestBody?: Array<Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModel> | null,
  }): CancelablePromise<Workspace_Common_Features_LoanAllocation_ViewModels_SaveAllocationChargeViewModelResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/loan-allocations/{loanKind}/{loanId}/charge-line/{chargeLineId}',
      path: {
        'orderId': orderId,
        'loanKind': loanKind,
        'loanId': loanId,
        'chargeLineId': chargeLineId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        409: `Conflict`,
      },
    });
  }

}
