/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Affiliates_ViewModels_Requests_AffiliateCompanyViewModel } from '../models/Workspace_API_FeatureAreas_Affiliates_ViewModels_Requests_AffiliateCompanyViewModel';
import type { Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse } from '../models/Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AffiliateCompanyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Adds an Affiliate Company to the Principal (Auth)
   * @returns Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addAffiliateCompanyAsync({
    orderId,
    partyId,
    requestBody,
  }: {
    /**
     * The Id of the current order
     */
    orderId: number,
    /**
     * The partyId of the current order
     */
    partyId: number,
    /**
     * Affiliate Company input
     */
    requestBody: Workspace_API_FeatureAreas_Affiliates_ViewModels_Requests_AffiliateCompanyViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/principal/{partyId}/affiliate-company',
      path: {
        'orderId': orderId,
        'partyId': partyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid authorization header`,
        403: `External users do not have permission`,
        404: `Not Found`,
        409: `Creates a conflict if more than one Affiliate is created per party`,
        422: `Contact details validation failed`,
      },
    });
  }

  /**
   * Removes an Affiliate Company associated with the party, given the orderId, partyId (Auth)
   * @returns Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public removeAffiliateCompany({
    orderId,
    partyId,
  }: {
    /**
     * The Id of the current order
     */
    orderId: number,
    /**
     * The partyId of the current order
     */
    partyId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/principal/{partyId}/affiliate-company',
      path: {
        'orderId': orderId,
        'partyId': partyId,
      },
      errors: {
        401: `Invalid authorization header`,
        403: `External users do not have permission`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

}
