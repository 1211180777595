/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Responses_DocumentTypeList } from '../models/Workspace_Common_Responses_DocumentTypeList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DocumentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a map of document types and their document sub types (Auth)
   * @returns Workspace_Common_Responses_DocumentTypeList Document types response
   * @throws ApiError
   */
  public getDocumentTypes(): CancelablePromise<Workspace_Common_Responses_DocumentTypeList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/documents/types',
      errors: {
        401: `Invalid Authorization header`,
        404: `Not Found`,
      },
    });
  }

}
