import { Content, Section } from '.';
import React, { FunctionComponent } from 'react';

interface IProps {
  contentProps?: any;
  sectionProps?: any;
}

const LandingPageSection: FunctionComponent<IProps> = ({
  children,
  contentProps,
  sectionProps,
}) => {
  return (
    <Section {...sectionProps}>
      <Content {...contentProps}>{children}</Content>
    </Section>
  );
};

export default LandingPageSection;
