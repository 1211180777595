import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { StyledReactTooltip as Tooltip } from 'components/ToolTip';
import { slugify } from 'utils/text';

interface ILinkContentsProps {
  text: string | JSX.Element;
  children?: React.ReactNode | React.ReactNodeArray;
  customTooltipId?: string;
  handleShowCustomDisplayNameTooltip?: (showTooltip: boolean) => void;
  handleShowCustomSubtextTooltip?: (showTooltip: boolean) => void;
  handleOnLeave?: (onLeave: boolean) => void;
  tooltipId?: number | string;
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
  tooltipType?: 'dark' | 'light';
  tooltipContent?: string;
  width?: number | undefined;
}

const Container = styled.div<{ width: number | undefined }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const getTooltipID = (tooltipId, text) => {
  return (
    tooltipId ||
    (typeof text === 'string'
      ? slugify(text)
      : (Math.random() + 1).toString(36).substring(6))
  );
};
const LinkContents: React.FC<PropsWithChildren<ILinkContentsProps>> = ({
  children,
  text,
  width,
  tooltipId,
  tooltipType,
  tooltipPlace,
  tooltipContent,
  customTooltipId,
  handleShowCustomDisplayNameTooltip,
  handleShowCustomSubtextTooltip,
  handleOnLeave,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);

  const id = getTooltipID(tooltipId, text);
  const shouldShowToolTip = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (tooltipContent) {
      setShowToolTip(true);
    }

    if (evt.currentTarget) {
      if (evt.currentTarget.scrollWidth > evt.currentTarget.clientWidth) {
        // confirm if functions are defined then call
        handleShowCustomSubtextTooltip && handleShowCustomSubtextTooltip(true);
        handleShowCustomDisplayNameTooltip &&
          handleShowCustomDisplayNameTooltip(true);

        setShowToolTip(true);
      } else {
        // confirm if functions are defined then call
        handleShowCustomSubtextTooltip && handleShowCustomSubtextTooltip(false);
        handleShowCustomDisplayNameTooltip &&
          handleShowCustomDisplayNameTooltip(false);

        setShowToolTip(false);
      }
    }
  };

  return (
    <>
      <Container
        onMouseEnter={shouldShowToolTip}
        onMouseLeave={() => {
          // confirm if functions are defined then call
          handleOnLeave && handleOnLeave(false);
        }}
        data-tip={text}
        data-for={customTooltipId ?? id}
        width={width}
      >
        {children || text}
        <Tooltip
          id={`${id}`}
          disable={!showToolTip}
          content={tooltipContent || text}
          place={tooltipPlace}
          type={tooltipType}
        />
      </Container>
    </>
  );
};

export default LinkContents;
