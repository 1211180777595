import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M100 181.37C134.794 181.37 163 178.684 163 175.37C163 172.056 134.794 169.37 100 169.37C65.2061 169.37 37 172.056 37 175.37C37 178.684 65.2061 181.37 100 181.37Z"
      fill="#CBD6E4"
    />
    <rect
      x="0.927729"
      y="1.07186"
      width="99.9866"
      height="124.117"
      rx="14"
      transform="matrix(0.997238 0.0742778 -0.0695083 0.997581 59.2939 22.5465)"
      fill="#AEBBCE"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <rect
      x="1.06408"
      y="0.926583"
      width="98.5847"
      height="124.119"
      rx="14"
      transform="matrix(0.997462 -0.0711952 0.0666218 0.997778 38.4537 28.1799)"
      fill="#C8D5E8"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <rect
      x="49.1865"
      y="20"
      width="101"
      height="124"
      rx="14"
      fill="white"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <rect x="66.1865" y="37" width="29" height="6" rx="2" fill="#BECFE8" />
    <rect x="66.1865" y="48" width="18" height="6" rx="2" fill="#BECFE8" />
    <rect x="66.1865" y="72" width="64" height="6" rx="2" fill="#BECFE8" />
    <rect x="66.1865" y="83" width="56" height="6" rx="2" fill="#BECFE8" />
    <rect x="66.1865" y="94" width="70" height="6" rx="2" fill="#BECFE8" />
    <rect x="66.1865" y="105" width="44" height="6" rx="2" fill="#BECFE8" />
    <rect x="66.1865" y="121" width="10" height="6" rx="2" fill="#BECFE8" />
    <rect x="117.187" y="119" width="19" height="10" rx="2" fill="#BECFE8" />
    <circle cx="128.687" cy="45.5" r="8.5" fill="#BECFE8" />
  </svg>
);
