/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_UserNotificationSubscription } from '../models/Workspace_Common_Models_UserNotificationSubscription';
import type { Workspace_Common_Requests_UpdateLastViewedNotification } from '../models/Workspace_Common_Requests_UpdateLastViewedNotification';
import type { Workspace_Common_Responses_NotificationList } from '../models/Workspace_Common_Responses_NotificationList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * It will return List of all notifications for current user. (Auth)
   * @returns Workspace_Common_Responses_NotificationList list of notification and page information.
   * @throws ApiError
   */
  public getAllNotifications({
    page = 1,
  }: {
    /**
     * page number
     */
    page?: number,
  }): CancelablePromise<Workspace_Common_Responses_NotificationList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/notifications',
      query: {
        'page': page,
      },
      errors: {
        400: `Invalid page number.`,
        401: `Invalid Authorization header.`,
      },
    });
  }

  /**
   * Returns unseen notification count (badgevalue) for current user (Auth)
   * @returns number int value for total unread notification count
   * @throws ApiError
   */
  public getUnreadCount(): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/notifications/unread-count',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * It will update notification read timeStamp for give notificationId (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateReadTimestamp({
    notificationId,
  }: {
    /**
     * notification Id
     */
    notificationId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/notifications/{notificationId}/visited',
      path: {
        'notificationId': notificationId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Notification does not exist`,
      },
    });
  }

  /**
   * Update user's last viewed notification Id (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateLastViewedNotificationId({
    requestBody,
  }: {
    /**
     * Notification Id
     */
    requestBody: Workspace_Common_Requests_UpdateLastViewedNotification,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/notifications/viewed',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid NotificationId`,
        401: `Invalid Authorization header`,
        404: `Notification does not exist for the current user`,
      },
    });
  }

  /**
   * Get user's notification settings. (Auth)
   * @returns Workspace_Common_Models_UserNotificationSubscription List of UserNotificationSubscription
   * @throws ApiError
   */
  public getNotificationSettings(): CancelablePromise<Array<Workspace_Common_Models_UserNotificationSubscription>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/notifications/preferences',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Add or Update user's notification settings. Valid Notification Types are: OrderUpdates and WireRequests (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateNotificationSettings({
    requestBody,
  }: {
    requestBody: Workspace_Common_Models_UserNotificationSubscription,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/notifications/preferences',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Validation failed for Notification type`,
        401: `Invalid Authorization header`,
        422: `Validation failed.`,
      },
    });
  }

}
