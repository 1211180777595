export enum JobStatus {
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  SUCCESS = 'Succeeded',
  SCHEDULED = 'Scheduled',
}

export enum TrustExtractPreviewStatus {
  PROCESSING = 'Processing',
  SUCCESS = 'Success',
}

export enum SortingFields {
  ORDER = 'number',
  LOCATION = 'location',
  STATUS = 'status',
  ORDER_TYPE = 'transactionType',
  PRODUCTS = 'products',
  GROUPS = 'closingGroup.name',
}

export enum SiteOrderStatusFilter {
  Open = 'Open',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Pending = 'Pending',
  OpenInError = 'Open In Error',
  Reserved = 'Reserved',
}

export const COLUMN_COUNT = 5;

const generateCancelWarningMessage = (customText: string) =>
  `You are about to leave this page. The process of ${customText} will be canceled, and changes will not be updated. Do you want to continue with the action?`;

export const PRODUCTS_CANCEL_WARNING = generateCancelWarningMessage(
  'assigning and removing products'
);

export const ORDER_TYPE_CANCEL_WARNING = generateCancelWarningMessage(
  'updating the Order Type'
);

export const DOUBLE_TO_SINGLE_SIDED_WARNING =
  'The allocated sale price, all seller charges, and credits will be removed by updating the order type. Are you sure you would like to continue?';

export const BU_IN_PROGRESS_TOOLTIP =
  'This feature is currently unavailable until the last update is complete.';
