/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_CoInsurance_AddCoInsuranceDetailViewModel } from '../models/Workspace_API_ViewModels_CoInsurance_AddCoInsuranceDetailViewModel';
import type { Workspace_API_ViewModels_CoInsurance_CoInsuranceDetailViewModel } from '../models/Workspace_API_ViewModels_CoInsurance_CoInsuranceDetailViewModel';
import type { Workspace_API_ViewModels_CoInsurance_CoInsuranceDocumentsViewModel } from '../models/Workspace_API_ViewModels_CoInsurance_CoInsuranceDocumentsViewModel';
import type { Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel } from '../models/Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel';
import type { Workspace_API_ViewModels_CoInsurance_CoInsurerSummaryViewModel } from '../models/Workspace_API_ViewModels_CoInsurance_CoInsurerSummaryViewModel';
import type { Workspace_API_ViewModels_CoInsurance_CreateCoInsurerDetailViewModel } from '../models/Workspace_API_ViewModels_CoInsurance_CreateCoInsurerDetailViewModel';
import type { Workspace_Common_Models_DocumentType } from '../models/Workspace_Common_Models_DocumentType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderCoInsuranceService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Given an order id, this method will return co-Insurance Summary on the provided order (Auth)
   * @returns Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel Order has co-insurance
   * @throws ApiError
   */
  public getCoInsuranceSummaryAsync({
    orderId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/co-insurance',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id this method will reset the Co-Insurance data. (Auth)
   * @returns any Co-Insurance data was reset successfully
   * @throws ApiError
   */
  public resetCoInsuranceAsync({
    orderId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/co-insurance',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Given an order id and policy document type id, this method will return the policy documents of that type, for the file (Auth)
   * @returns Workspace_API_ViewModels_CoInsurance_CoInsuranceDocumentsViewModel Returns the policy documents associated to the file
   * @throws ApiError
   */
  public getCoInsurancePolicyDocumentsAsync({
    orderId,
    policyType,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * Id of the policy document type in FAST, 5 for Owner Policy and 6 for Lender Policy documents
     */
    policyType: Workspace_Common_Models_DocumentType,
  }): CancelablePromise<Workspace_API_ViewModels_CoInsurance_CoInsuranceDocumentsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/co-insurance/policy-documents',
      path: {
        'orderId': orderId,
      },
      query: {
        'policyType': policyType,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id and policy id, this method will return a object with a list of co-insurers on the order (Auth)
   * @returns Workspace_API_ViewModels_CoInsurance_CoInsurerSummaryViewModel Order has co-insurers
   * @throws ApiError
   */
  public getCoInsurerSummaryAsync({
    orderId,
    policyId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * A policy Id
     */
    policyId: number,
  }): CancelablePromise<Workspace_API_ViewModels_CoInsurance_CoInsurerSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/co-insurance/policy/{policyId}/co-insurers',
      path: {
        'orderId': orderId,
        'policyId': policyId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Given an order id, co-insurance type id and policy details list, this method will update Co-Insurance
   * policies and return co-Insurance Summary. (Auth)
   * @returns Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel Co-Insurance Policies updated successfully
   * @throws ApiError
   */
  public updateCoInsurancePoliciesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * List of policies needs to be updated
     */
    requestBody: Workspace_API_ViewModels_CoInsurance_CoInsuranceDetailViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/co-insurance/policies',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found / Policy Document not found / policy not found /`,
        409: `Modification to IsSimultaneousPolicy is prohibited /`,
        422: `Incorrect request body`,
      },
    });
  }

  /**
   * Given an order id and Workspace.API.ViewModels.CoInsurance.CoInsuranceDetailViewModel will add policy detail objects to an order. (Auth)
   * @returns Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel Co-Insurance Policies added successfully
   * @throws ApiError
   */
  public addCoInsurancePoliciesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * Workspace.API.ViewModels.CoInsurance.CoInsuranceDetailViewModel
     */
    requestBody: Workspace_API_ViewModels_CoInsurance_AddCoInsuranceDetailViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_CoInsurance_CoInsuranceViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/co-insurance/policies',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Incorrect request body`,
      },
    });
  }

  /**
   * Add Co-Insurer(s) (Auth)
   * @returns void
   * @throws ApiError
   */
  public addCoInsurerAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Array<Workspace_API_ViewModels_CoInsurance_CreateCoInsurerDetailViewModel>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/co-insurance/co-insurers',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found or No permission to order`,
        422: `Incorrect request body`,
      },
    });
  }

  /**
   * Given an order id and an array of co-insurer id's, will delete the co-insurers from the policies on the order. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteCoInsurersAsync({
    orderId,
    coInsurerIds,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * An array of co-insurer id's to be deleted
     */
    coInsurerIds: Array<number>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/co-insurance/co-insurers',
      path: {
        'orderId': orderId,
      },
      query: {
        'coInsurerIds': coInsurerIds,
      },
      errors: {
        401: `Invalid authorization header`,
        403: `User is forbidden to access the order`,
        422: `Incorrect request body`,
      },
    });
  }

  /**
   * Given an order id and Workspace.API.ViewModels.CoInsurance.CreateCoInsurerDetailViewModel will update co-insurer details for a policy. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateCoInsurerAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * List of co-insurer you want to update
     */
    requestBody: Array<Workspace_API_ViewModels_CoInsurance_CreateCoInsurerDetailViewModel>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/co-insurance/policy/co-insurers',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Incorrect request body`,
      },
    });
  }

}
