import React from 'react';
import styled from 'styled-components';
import {
  MultiMapPinIcon,
  LocationIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { Row, Column } from 'components/ui/Box';
import {
  athensGray,
  blue,
  extremeBlack,
  forestGreen,
  linkWater,
  trout,
} from 'config/theme';
import { isEmpty, pluralize } from 'utils/text';
import TextTruncate from '../TextTruncate';
import { BorderContainer, AdjustableBorderContainer } from '../Attribute';
import { StyledReactTooltip } from 'components/ToolTip';

interface ISingleSiteAddressProps {
  countyInformation: string;
  countyInformationMaxWidth?: number;
  fileId: number;
  stateInformation: string;
  stateInformationMaxWidth?: number;
  street: string;
  streetMaxWidth?: number;
}

interface IMultiSiteHeadProps {
  citiesCount: number;
  closedSitesCount: number;
  countiesCount: number;
  fileId: number;
  openSitesCount: number;
  sitesCount: number;
  stateCount: number;
}

const ADDRESS_TITLE_MAX_WIDTH = 550;
const ADDRESS_DETAILS_MAX_WIDTH = 300;

export const SingleSiteAddress = ({
  countyInformation,
  countyInformationMaxWidth,
  fileId,
  stateInformation,
  stateInformationMaxWidth,
  street,
  streetMaxWidth,
}: ISingleSiteAddressProps) => (
  <Column>
    <Row className={'site-cart-street__container'} alignItems="center">
      <LocationIcon style={{ marginRight: '10px' }} />
      <TitleContainer style={{ maxWidth: streetMaxWidth }}>
        <TextTruncate
          classSelectorName="site-card-street"
          maxWidth={streetMaxWidth || ADDRESS_TITLE_MAX_WIDTH}
          text={street || 'TBD'}
          tooltipId={`${fileId}-street-tooltip`}
        />
      </TitleContainer>
    </Row>
    <Row
      style={{
        height: '24px',
        marginLeft: '22px',
        marginTop: '6.5px',
        marginBottom: '13.5px',
      }}
    >
      {!isEmpty(stateInformation) && (
        <AdjustableBorderContainer
          separatorHeight="16px"
          separatorColor={linkWater}
        >
          <AddressDetailsContainer
            style={{ maxWidth: stateInformationMaxWidth }}
          >
            <TextTruncate
              classSelectorName="site-card-state"
              maxWidth={stateInformationMaxWidth || ADDRESS_DETAILS_MAX_WIDTH}
              text={stateInformation}
              tooltipId={`${fileId}-state-tooltip`}
            />
          </AddressDetailsContainer>
        </AdjustableBorderContainer>
      )}
      {!isEmpty(countyInformation) && (
        <AdjustableBorderContainer>
          <AddressDetailsContainer
            style={{ maxWidth: countyInformationMaxWidth }}
          >
            <TextTruncate
              classSelectorName="site-card-county"
              maxWidth={countyInformationMaxWidth || ADDRESS_DETAILS_MAX_WIDTH}
              text={countyInformation}
              tooltipId={`${fileId}-county-tooltip`}
            />
          </AddressDetailsContainer>
        </AdjustableBorderContainer>
      )}
    </Row>
  </Column>
);

export const MultiSiteHeader = ({
  citiesCount,
  closedSitesCount,
  countiesCount,
  fileId,
  openSitesCount,
  sitesCount,
  stateCount,
}: IMultiSiteHeadProps) => (
  <Column>
    <Row alignItems="center">
      <MultiMapPinIcon style={{ marginRight: 10 }} />
      <AdjustableBorderContainer
        separatorColor={linkWater}
        separatorHeight="22px"
      >
        <TitleContainer data-for={`msCardSitesCounter.${fileId}`} data-tip>
          {sitesCount} {pluralize(sitesCount, 'Site')}
        </TitleContainer>
        <StyledReactTooltip
          content="Open and Closed Sites"
          id={`msCardSitesCounter.${fileId}`}
          textAlign="center"
        />
      </AdjustableBorderContainer>

      <AdjustableBorderContainer
        separatorColor={linkWater}
        separatorHeight="18px"
      >
        <MultiSiteLocationTitle>
          {stateCount} {pluralize(stateCount, 'State')}
        </MultiSiteLocationTitle>
      </AdjustableBorderContainer>

      <AdjustableBorderContainer
        separatorColor={linkWater}
        separatorHeight="18px"
      >
        <MultiSiteLocationTitle>
          {countiesCount} {pluralize(countiesCount, 'County', 'Counties')}
        </MultiSiteLocationTitle>
      </AdjustableBorderContainer>

      <AdjustableBorderContainer
        separatorColor={linkWater}
        separatorHeight="18px"
      >
        <MultiSiteLocationTitle>
          {citiesCount} {pluralize(citiesCount, 'City', 'Cities')}
        </MultiSiteLocationTitle>
      </AdjustableBorderContainer>
    </Row>
    <Row
      style={{
        marginLeft: '28px',
        marginTop: '6.5px',
        marginBottom: '13.5px',
      }}
    >
      <AdjustableBorderContainer
        separatorColor={athensGray}
        separatorHeight="14px"
      >
        <MultiSiteStatusDisplay isOpen>
          {openSitesCount} Open
        </MultiSiteStatusDisplay>
      </AdjustableBorderContainer>

      <BorderContainer>
        <MultiSiteStatusDisplay>
          {closedSitesCount} Closed
        </MultiSiteStatusDisplay>
      </BorderContainer>
    </Row>
  </Column>
);

export const TitleContainer = styled.div`
  color: ${extremeBlack};
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0015em;
  line-height: 29px;
  max-width: ${ADDRESS_TITLE_MAX_WIDTH}px;
  white-space: nowrap;
`;
TitleContainer.displayName = 'SiteCardHeaderTitleContainer';

const AddressDetailsContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  max-width: ${ADDRESS_DETAILS_MAX_WIDTH}px;
`;
AddressDetailsContainer.displayName = 'AddressDetailsContainer';

export const MultiSiteLocationTitle = styled.div`
  align-items: center;
  color: ${trout};
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0015em;
  line-height: 24px;
  white-space: nowrap;
`;
MultiSiteLocationTitle.displayName = 'MultiSiteLocationTitle';

export const MultiSiteStatusDisplay = styled.div<{ isOpen?: boolean }>`
  color: ${({ isOpen }) => (isOpen ? forestGreen : blue)};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.005em;
`;
MultiSiteStatusDisplay.displayName = 'MultiSiteStatusDisplay';
