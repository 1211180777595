import React from 'react';
import styled from 'styled-components';
import { black, lighterBlue, trout } from 'config/theme';
import { StyledReactTooltip } from 'components/ToolTip';
import { secondaryBlue7 } from 'config/colors';
import { slugify } from 'utils/text';
import TextTruncate from './TextTruncate';

interface IAttributeProps {
  adjustable?: boolean;
  /**
   * A class name we can use to generate selectors for the E2E suite
   */
  classSelectorName?: string;
  color?: string;
  data?: {
    [k: string]: number | string | boolean;
  };
  iconFillColor?: string;
  iconHeight?: number;
  iconSvgComponent?: any;
  iconTooltipId?: string;
  iconTooltipText?: string;
  iconWidth?: number;
  labelText?: React.ReactNode | string;
  labelTextSize?: number;
  lineHeight?: string;
  maxWidth?: number;
  naText?: string | React.ReactNode;
  separatorColor?: string;
  separatorHeight?: string;
  showBorder?: boolean;
  style?: React.CSSProperties;
  textColor?: string;
  valueComponent?: React.ReactNode;
  valueText?: string;
  valueTextSize?: number;
  onClick?: () => void;
}

export const Attribute = React.forwardRef<HTMLDivElement, IAttributeProps>(
  (
    {
      adjustable = false,
      classSelectorName,
      color,
      data,
      iconFillColor,
      iconHeight,
      iconSvgComponent,
      iconTooltipId,
      iconTooltipText,
      iconWidth,
      labelText,
      labelTextSize,
      lineHeight = 'inherit',
      maxWidth,
      naText = 'N/A',
      separatorColor,
      separatorHeight,
      showBorder = true,
      style,
      textColor = 'inherit',
      valueComponent,
      valueText,
      valueTextSize,
      onClick,
    },
    ref
  ) => {
    // We have two wrapper components, so we want to choose which one we use, one
    // allows us to adjust the border, the other one has a border the size of the
    // element div
    const OptionalBorderContainer = adjustable
      ? AdjustableBorderContainer
      : BorderContainer;

    return (
      <OptionalBorderContainer
        ref={ref}
        {...data}
        className={
          classSelectorName ? `${classSelectorName}__container` : undefined
        }
        color={color}
        separatorColor={separatorColor}
        separatorHeight={separatorHeight}
        showBorder={showBorder}
        style={style}
        onClick={onClick}
      >
        {iconSvgComponent && (
          <Icon
            classSelectorName={classSelectorName}
            fill={iconFillColor}
            height={iconHeight}
            svgComponent={iconSvgComponent}
            tooltipId={slugify(`${iconTooltipId}-icon`)}
            tooltipText={iconTooltipText}
            width={iconWidth}
          />
        )}
        {labelText && (
          <span
            className={
              classSelectorName ? `${classSelectorName}__label` : undefined
            }
            style={{
              color: trout,
              fontSize: labelTextSize || 'inherit',
              marginRight: 4,
            }}
          >
            {labelText}:
          </span>
        )}{' '}
        {!valueComponent ? (
          <span
            className={
              classSelectorName ? `${classSelectorName}__value` : undefined
            }
            style={{
              color: textColor,
              display: 'inline-block',
              fontSize: valueTextSize || 'inherit',
              lineHeight,
              maxWidth,
              whiteSpace: 'nowrap',
            }}
          >
            {valueText ? (
              <TextTruncate
                maxWidth={maxWidth}
                text={valueText}
                tooltipId={slugify(`${valueText}-${iconTooltipId}-value`)}
              />
            ) : (
              naText
            )}
          </span>
        ) : (
          valueComponent
        )}
      </OptionalBorderContainer>
    );
  }
);
Attribute.displayName = 'Attribute';

interface IBorderContainerProps {
  color?: string;
  separatorColor?: string;
  separatorHeight?: string;
  showBorder?: boolean;
  onClick?: () => void;
}

export const BorderContainer = styled.div<IBorderContainerProps>`
  align-items: center;
  ${({ showBorder }) =>
    showBorder
      ? `
        border-right: 1px solid ${lighterBlue};
        padding-right: 10px;
      `
      : ''}
  color: ${({ color }) => color || black};
  display: flex;
  font-weight: 500;
  margin-right: 10px;
  ${({ onClick }) =>
    onClick
      ? `
        &:hover {
          cursor: pointer;
          color: ${secondaryBlue7};
          text-decoration: underline;
        }
      `
      : ''}
  &:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
`;
BorderContainer.defaultProps = { showBorder: true };
BorderContainer.displayName = 'BorderContainer';

interface IAdjustableBorderContainerProps extends IBorderContainerProps {
  separatorHeight?: string;
  separatorColor?: string;
  showBorder?: boolean;
}

export const AdjustableBorderContainer = styled(BorderContainer)<
  IAdjustableBorderContainerProps
>`
  border-right: 0;
  color: ${({ color }) => color || black};
  margin-right: 10px;
  padding-right: 0px;

  &:after {
    content: '';
    ${({ showBorder }) =>
      showBorder
        ? `
    background: ${({ separatorColor }) => separatorColor || lighterBlue};
    height: ${({ separatorHeight }) => separatorHeight || '100%'};
    `
        : ''}
    width: 1px;
    margin-left: 10px;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;
AdjustableBorderContainer.defaultProps = { showBorder: true };
AdjustableBorderContainer.displayName = 'AdjustableBorderContainer';

interface IIconProps {
  /**
   * A class name we can use to generate selectors for the E2E suite
   */
  classSelectorName?: string;
  fill?: string;
  height?: number;
  svgComponent: any;
  tooltipId?: string;
  tooltipText?: string;
  width?: number;
  place?: 'top' | 'right' | 'bottom' | 'left';
  style?: React.CSSProperties;
}

export const Icon = ({
  classSelectorName,
  fill,
  height,
  svgComponent: SvgComponent,
  tooltipId,
  tooltipText,
  width,
  place,
  style,
}: IIconProps) => (
  <>
    <IconWrapper
      className={
        classSelectorName ? `${classSelectorName}__icon-wrapper` : undefined
      }
      data-for={tooltipId}
      data-tip={tooltipText}
      style={
        style
          ? style
          : {
              cursor: tooltipId && tooltipText ? 'pointer' : 'default',
              height,
              marginRight: 8,
              width,
            }
      }
    >
      <SvgComponent fill={fill || 'currentColor'} style={{ width, height }} />
      {tooltipId && tooltipText && (
        <StyledReactTooltip
          effect="solid"
          id={tooltipId}
          textAlign="center"
          place={place}
        />
      )}
    </IconWrapper>
  </>
);
Icon.displayName = 'Icon';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  line-height: 1;
`;

interface IAttributesContainerProps {
  direction?: 'row' | 'column';
}

export const AttributesContainer = styled.div<IAttributesContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  ${({ direction = 'row' }) =>
    direction === 'column' &&
    `
    align-items: flex-start;
    ${BorderContainer},
    ${AdjustableBorderContainer} {
      border: 0;
      margin-bottom: 24px;
      margin-right: 0;
      padding-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `};
`;
