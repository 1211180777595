import React from 'react';
import styled from 'styled-components';
import Button from 'components/ui/Button';
import {
  black,
  blue,
  footerBottomPadding,
  footerHeight,
  headerHeight,
  pageContentBottomPadding,
} from 'config/theme';

interface IErrorPageProps {
  statusCode?: number;
  badge?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const ErrorPage = ({
  statusCode,
  title,
  children,
  badge,
  style,
}: IErrorPageProps) => (
  <Container style={style}>
    {statusCode ? (
      <StatusCode>{statusCode}</StatusCode>
    ) : (
      <ErrorBadge>{badge}</ErrorBadge>
    )}
    {title && <Title>{title}</Title>}
    <Description>{children}</Description>
  </Container>
);

export default ErrorPage;
export {
  default as ErrorPageWithGraphic,
  ErrorCode,
} from './ErrorPageWithGraphic';

export { UnknownErrorGraphic } from './ErrorGraphics';

const Container = styled.div`
  color: ${black};
  text-align: center;
  height: calc(
    100vh - ${headerHeight}px - ${footerHeight}px - ${footerBottomPadding}px -
      ${pageContentBottomPadding}px - 10px
  );
  max-width: 680px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StatusCode = styled.div`
  color: ${blue};
  font-size: 266px;
  font-weight: 500;
  margin-top: -40px;
`;

const ErrorBadge = styled.div``;

const Title = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 30px 0;
`;

const Description = styled.div`
  font-size: 1.125rem;
  line-height: 1.875rem;

  ${Button} {
    margin-top: 30px;
  }
`;
