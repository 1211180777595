import { ThunkAction } from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';

import configureStore from './configureStore';

import { IApplicationState } from './types';
import { injectStore } from './injector';

declare module 'redux' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Dispatch<A extends Action = AnyAction> {
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

// Docz does server side rendering, so it doesn't have access to the window
// object. With this check, we can use our store in Docz
export const history =
  typeof window !== 'undefined'
    ? createBrowserHistory()
    : createMemoryHistory();

export const store = configureStore(history);

// Makes possible to use store with dependency injection.
injectStore(store);

export const getStore = (initialStateChanges?: Partial<IApplicationState>) =>
  configureStore(history, initialStateChanges);

// re-exporting types from index
export {
  AsyncDataState,
  IAsyncData,
  ApplicationStateGetter,
  AsyncAction,
  Dispatch,
  IApplicationState,
} from './types';

export default store;
