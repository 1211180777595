import { JobStatus } from 'components/MultiSiteBulkUpdates/Shared';

export enum OfficesTypeKeys {
  FETCH_ALL_REQUEST = 'offices/FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS = 'offices/FETCH_ALL_SUCCESS',
  FETCH_ALL_ERROR = 'offices/FETCH_ALL_ERROR',

  // Build trust extract progress
  SET_BUILD_TRUST_EXTRACT_PROGRESS = 'offices/SET_BUILD_TRUST_EXTRACT_PROGRESS',
  CLEAR_BUILD_TRUST_EXTRACT_PROGRESS = 'offices/CLEAR_BUILD_TRUST_EXTRACT_PROGRESS',
  SET_REFETCH_TRUST_EXTRACT = 'offices/SET_REFETCH_TRUST_EXTRACT',
}

interface IBuildTrustExtractStatus {
  officeId: number;
  state: JobStatus;
}

interface IOfficeUiState {
  buildTrustExtractProgress: {
    items: IBuildTrustExtractStatus[];
    shouldReFetchTrustExtracts: boolean;
  };
}

interface IReFetchTrustExtract {
  reFetchTrustExtracts: boolean;
}

export interface IOfficesState {
  resultsById: IOfficeHash;
  error: string | null;
  ui: IOfficeUiState;
  fetch: IReFetchTrustExtract;
}

export interface IFetchOfficesRequestAction {
  type: OfficesTypeKeys.FETCH_ALL_REQUEST;
}

export interface IFetchOfficesSuccessAction {
  type: OfficesTypeKeys.FETCH_ALL_SUCCESS;
  resultsById: IOfficeHash;
}

export interface IFetchOfficesErrorAction {
  type: OfficesTypeKeys.FETCH_ALL_ERROR;
  error: string;
}

export interface ISetBuildTrustExtractProgressAction
  extends Pick<IOfficeUiState, 'buildTrustExtractProgress'> {
  type: OfficesTypeKeys.SET_BUILD_TRUST_EXTRACT_PROGRESS;
}

export interface IRefetchTrustExtractAction
  extends Pick<IReFetchTrustExtract, 'reFetchTrustExtracts'> {
  type: OfficesTypeKeys.SET_REFETCH_TRUST_EXTRACT;
}
export type OfficesAction =
  | IFetchOfficesRequestAction
  | IFetchOfficesSuccessAction
  | IFetchOfficesErrorAction
  | ISetBuildTrustExtractProgressAction
  | IRefetchTrustExtractAction;

export interface IOfficeHash {
  [id: string]: IOffice;
}

export interface IOffice {
  name: string;
  fastId: number;
  id: number;
  regionId: number;
  state: string;
  city: string;
  street: string;
  isPrimary?: boolean;
}

export interface IRegionalOffice extends Omit<IOffice, 'isPrimary'> {
  apns: [];
  email: string;
  phoneNumber: string;
  county: string;
  zip: string;
}
