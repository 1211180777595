import React from 'react';
import {
  ISettlementDocumentItemProgress,
  ISettlementDocumentProgressMetaData,
} from 'store/orders/types';
import {
  trackSettlementDocumentProgress,
  clearSettlementDocumentProgress,
} from 'store/orders/actions';
import { connect } from 'react-redux';
import { IApplicationState } from 'store';
import workspaceAPI from 'services/workspaceAPI';
import DocumentGenerationProgress from 'components/DocumentGenerationProgress';
import orderDocumentUrls from 'config/urls/documents';

export interface IProps {
  settlementDocuments: ISettlementDocumentItemProgress[];
  trackSettlementDocumentProgress: typeof trackSettlementDocumentProgress;
  clearSettlementDocumentProgress: typeof clearSettlementDocumentProgress;
}

const SettlementDocumentGenerationProgress = ({
  settlementDocuments,
  clearSettlementDocumentProgress,
  trackSettlementDocumentProgress,
}: IProps) => {
  return (
    <DocumentGenerationProgress
      title="Generating documents..."
      selectorKey="settlement-statement"
      items={settlementDocuments.map((item) => ({
        ...item,
        id: `${item.previewId}`,
      }))}
      getItemPendingStatus={(itm) => {
        const item = (itm as unknown) as ISettlementDocumentItemProgress;
        return item.status !== 'Success' && !item.fetching;
      }}
      getItemReadyStatus={(item) => item.status === 'Success'}
      fetchItemProgressStatus={async (itm) => {
        const item = (itm as unknown) as ISettlementDocumentItemProgress;
        const documentRequest = {
          fileFormat: item.format,
          fileLocation: item.location,
          fileName: item.name,
        };
        trackSettlementDocumentProgress([{ ...item, fetching: true }]);
        const { data } = await workspaceAPI.post<
          ISettlementDocumentProgressMetaData
        >(
          `/orders/${item.orderId}${item.statusAPI || '/settlement/export'}/${
            item.previewId
          }`,
          documentRequest
        );
        trackSettlementDocumentProgress([
          {
            ...item,
            status: data.status,
            bytes: data.bytes,
            documentId: data.documentId,
            fetching: false,
          },
        ]);
      }}
      fetchItemBytes={(item) => Promise.resolve(item.bytes)}
      clearItem={(item) =>
        clearSettlementDocumentProgress(
          (item as unknown) as ISettlementDocumentItemProgress
        )
      }
      trackItems={(items) =>
        trackSettlementDocumentProgress(
          (items as unknown) as ISettlementDocumentItemProgress[]
        )
      }
      onCompleteItemClick={(itm) => {
        const item = (itm as unknown) as ISettlementDocumentItemProgress;
        if (item.documentId) {
          window.open(
            orderDocumentUrls.viewDocument(item.orderId, `${item.documentId}`),
            '_blank'
          );
        } else {
          window.open(orderDocumentUrls.documents(item.orderId), '_blank');
        }
      }}
      errorText={'There was an error exporting settlement statement'}
      data-testid="settlement-documentGeneration-progress"
      stopOnError
    />
  );
};

export { SettlementDocumentGenerationProgress };

function mapStateToProps(state: IApplicationState) {
  return {
    settlementDocuments: state.orders.ui.settlementDocumentProgress.items,
  };
}

export default connect(mapStateToProps, {
  trackSettlementDocumentProgress,
  clearSettlementDocumentProgress,
})(SettlementDocumentGenerationProgress);
