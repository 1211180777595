/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_AdjustmentAllocationViewModel_ } from '../models/Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_AdjustmentAllocationViewModel_';
import type { Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_ExtendedAllocationViewModel_ } from '../models/Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_ExtendedAllocationViewModel_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdjustmentAllocationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Saves adjustment allocations (Auth)
   * @returns void
   * @throws ApiError
   */
  public saveAllocationsAsync({
    orderId,
    adjustmentId,
    requestBody,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the adjustment
     */
    adjustmentId: number,
    /**
     * model with collection of allocations to save
     */
    requestBody: Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_ExtendedAllocationViewModel_,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/{adjustmentId}/allocations',
      path: {
        'orderId': orderId,
        'adjustmentId': adjustmentId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

  /**
   * Get allocations for an adjustment (Auth)
   * @returns Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_AdjustmentAllocationViewModel_ Allocations were saved
   * @throws ApiError
   */
  public getAllocationsByAdjustmentIdAsync({
    orderId,
    adjustmentId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the adjustment
     */
    adjustmentId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_AdjustmentAllocationViewModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/{adjustmentId}/allocations',
      path: {
        'orderId': orderId,
        'adjustmentId': adjustmentId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

}
