/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_OrderProperty_ViewModels_AddOrderPropertyViewModel } from '../models/Workspace_API_FeatureAreas_OrderProperty_ViewModels_AddOrderPropertyViewModel';
import type { Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyDetailsViewModel } from '../models/Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyDetailsViewModel';
import type { Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyLegalDescriptionViewModel } from '../models/Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyLegalDescriptionViewModel';
import type { Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyViewModel } from '../models/Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyViewModel';
import type { Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel } from '../models/Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel';
import type { Workspace_API_FeatureAreas_OrderProperty_ViewModels_UpdateOrderPropertyViewModel } from '../models/Workspace_API_FeatureAreas_OrderProperty_ViewModels_UpdateOrderPropertyViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderPropertiesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve properties of an order (Auth)
   * @returns Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyViewModel Property added successfully
   * @throws ApiError
   */
  public getPropertiesAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/properties',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Order Not Found or External user`,
      },
    });
  }

  /**
   * Add a property to an existing order (Auth)
   * @returns any Property added successfully
   * @returns number Success
   * @throws ApiError
   */
  public addPropertyAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * AddOrderPropertyViewModel
     */
    requestBody: Workspace_API_FeatureAreas_OrderProperty_ViewModels_AddOrderPropertyViewModel,
  }): CancelablePromise<any | number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/properties',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        404: `Order Not Found or External user`,
      },
    });
  }

  /**
   * Retrieve details of a property (Auth)
   * @returns Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyDetailsViewModel Property found successfully
   * @throws ApiError
   */
  public getPropertyDetailsAsync({
    orderId,
    propertyFastId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property details Not Found or External user`,
      },
    });
  }

  /**
   * Update existing order property details (Auth)
   * @returns any Property updated successfully
   * @throws ApiError
   */
  public updatePropertyDetailsAsync({
    orderId,
    propertyFastId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
    /**
     * UpdateOrderPropertyViewModel
     */
    requestBody: Workspace_API_FeatureAreas_OrderProperty_ViewModels_UpdateOrderPropertyViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property details Not Found or External user`,
      },
    });
  }

  /**
   * Remove an existing property from an order (Auth)
   * @returns any Property deleted successfully
   * @throws ApiError
   */
  public removePropertyAsync({
    orderId,
    propertyFastId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property Identifier
     */
    propertyFastId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property details Not Found or External user`,
        422: `It wasn't possible to delete the property`,
      },
    });
  }

  /**
   * Retrieve the legal description of a property (Auth)
   * @returns Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyLegalDescriptionViewModel Legal description found successfully
   * @throws ApiError
   */
  public getPropertyLegalDescriptionAsync({
    orderId,
    propertyFastId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyLegalDescriptionViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}/legal',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property legal description Not Found or External user`,
      },
    });
  }

  /**
   * Update the legal description of a property (Auth)
   * @returns any Legal description updated successfully
   * @throws ApiError
   */
  public updatePropertyLegalDescriptionAsync({
    orderId,
    propertyFastId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
    /**
     * OrderPropertyLegalDescriptionViewModel
     */
    requestBody: Workspace_API_FeatureAreas_OrderProperty_ViewModels_OrderPropertyLegalDescriptionViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}/legal',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property legal description Not Found or External user`,
      },
    });
  }

  /**
   * Retrieve existing property tax details of an order (Auth)
   * @returns Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel Property tax details retrieved successfully
   * @throws ApiError
   */
  public getPropertyTaxDetailsAsync({
    orderId,
    propertyFastId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
  }): CancelablePromise<Array<Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}/tax',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property Not Found or External user`,
      },
    });
  }

  /**
   * Update existing order property tax details (Auth)
   * @returns Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel Property tax details updated successfully
   * @throws ApiError
   */
  public updatePropertyTaxDetailsAsync({
    orderId,
    propertyFastId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
    /**
     * A collection of PropertyTaxViewModel objects
     */
    requestBody: Array<Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel>,
  }): CancelablePromise<Array<Workspace_API_FeatureAreas_OrderProperty_ViewModels_PropertyTaxViewModel>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}/tax',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        404: `Order or property Not Found or External user`,
      },
    });
  }

  /**
   * Trigger property title search in FAST (Auth)
   * @returns any Property title search has been triggered successfully
   * @throws ApiError
   */
  public triggerTitleSearchAsync({
    orderId,
    propertyFastId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Property identifier
     */
    propertyFastId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/properties/{propertyFastId}/search',
      path: {
        'orderId': orderId,
        'propertyFastId': propertyFastId,
      },
      errors: {
        400: `Invalid parameter(s) to execute the action`,
        401: `Unauthenticated user`,
        404: `Order or property Not Found or External user`,
        422: `Trigger failed`,
      },
    });
  }

}
