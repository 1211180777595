import { OfficesTypeKeys, OfficesAction, IOfficesState } from './types';

export const initialState: IOfficesState = {
  error: null,
  resultsById: {},
  ui: {
    buildTrustExtractProgress: {
      items: [],
      shouldReFetchTrustExtracts: false,
    },
  },
  fetch: {
    reFetchTrustExtracts: false,
  },
};

export default (state = initialState, action: OfficesAction) => {
  switch (action.type) {
    case OfficesTypeKeys.FETCH_ALL_SUCCESS:
      return {
        ...state,
        error: null,
        resultsById: action.resultsById,
      };
    case OfficesTypeKeys.FETCH_ALL_ERROR:
      return {
        ...state,
        error: action.error,
        resultsById: {},
      };
    case OfficesTypeKeys.SET_BUILD_TRUST_EXTRACT_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          buildTrustExtractProgress: action.buildTrustExtractProgress,
        },
      };
    }
    case OfficesTypeKeys.SET_REFETCH_TRUST_EXTRACT: {
      return {
        ...state,
        fetch: {
          reFetchTrustExtracts: action.reFetchTrustExtracts,
        },
      };
    }
    default:
      return state;
  }
};
