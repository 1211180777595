import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 10.7148H22V12.7148H19V10.7148ZM0 10.7148H3V12.7148H0V10.7148ZM12 0.714844V3.71484H10V0.714844H12ZM3.92 3.21484L6.05 5.35484L4.63 6.76484L2.5 4.64484L3.92 3.21484ZM15.95 5.34484L18.07 3.21484L19.5 4.64484L17.37 6.76484L15.95 5.34484ZM11 5.71484C12.5913 5.71484 14.1174 6.34698 15.2426 7.4722C16.3679 8.59742 17 10.1235 17 11.7148C17 13.9348 15.79 15.8748 14 16.9148V18.7148C14 18.9801 13.8946 19.2344 13.7071 19.422C13.5196 19.6095 13.2652 19.7148 13 19.7148H9C8.73478 19.7148 8.48043 19.6095 8.29289 19.422C8.10536 19.2344 8 18.9801 8 18.7148V16.9148C6.21 15.8748 5 13.9348 5 11.7148C5 10.1235 5.63214 8.59742 6.75736 7.4722C7.88258 6.34698 9.4087 5.71484 11 5.71484ZM13 20.7148V21.7148C13 21.9801 12.8946 22.2344 12.7071 22.422C12.5196 22.6095 12.2652 22.7148 12 22.7148H10C9.73478 22.7148 9.48043 22.6095 9.29289 22.422C9.10536 22.2344 9 21.9801 9 21.7148V20.7148H13ZM10 17.7148H12V15.5848C13.73 15.1448 15 13.5748 15 11.7148C15 10.654 14.5786 9.63656 13.8284 8.88642C13.0783 8.13627 12.0609 7.71484 11 7.71484C9.93913 7.71484 8.92172 8.13627 8.17157 8.88642C7.42143 9.63656 7 10.654 7 11.7148C7 13.5748 8.27 15.1448 10 15.5848V17.7148Z"
      fill={props.fill || blue}
    />
  </svg>
);
