/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_ChecklistManagement_Employee_CreateEmployeeChecklistRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Employee_CreateEmployeeChecklistRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Employee_CreateEmployeeChecklistTaskRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Employee_CreateEmployeeChecklistTaskRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Employee_UpdateEmployeeChecklistRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Employee_UpdateEmployeeChecklistRequest';
import type { Workspace_Common_Requests_ChecklistManagement_Employee_UpdateEmployeeChecklistTaskRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Employee_UpdateEmployeeChecklistTaskRequest';
import type { Workspace_Common_Requests_SortFieldOption } from '../models/Workspace_Common_Requests_SortFieldOption';
import type { Workspace_Common_Requests_SortOrderOption } from '../models/Workspace_Common_Requests_SortOrderOption';
import type { Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistsResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistsResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeTaskResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeTaskResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Employee_UpcomingTasksResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Employee_UpcomingTasksResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmployeeChecklistService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get list of upcoming (ready or in progress) tasks for provided teams. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Employee_UpcomingTasksResponse List of checklists with respective checklist tasks and their details.
   * @throws ApiError
   */
  public getUpcomingEmployeeTasks({
    apolloTeamId,
    assignedToMe = false,
    sortField,
    sortOrder,
    cursor,
  }: {
    /**
     * Team Ids for which to get upcoming tasks.
     */
    apolloTeamId?: Array<string> | null,
    /**
     * Filter result to only tasks assigned to the current user.
     */
    assignedToMe?: boolean,
    /**
     * Field by which the response should be sorted.
     */
    sortField?: Workspace_Common_Requests_SortFieldOption,
    /**
     * Order of sorting.
     */
    sortOrder?: Workspace_Common_Requests_SortOrderOption,
    /**
     * Number of the first entry that should be returned.
     */
    cursor?: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Employee_UpcomingTasksResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/upcoming-tasks',
      headers: {
        'Apollo-Team-Id': apolloTeamId,
      },
      query: {
        'assignedToMe': assignedToMe,
        'sortField': sortField,
        'sortOrder': sortOrder,
        'cursor': cursor,
      },
      errors: {
        401: `User is unauthorized.`,
      },
    });
  }

  /**
   * Get list of checklists of an order along with their active tasks and their details.
   * If email is provided then will filter checklists that have been assigned to the contact party with this email. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistsResponse List of checklists with respective checklist tasks and their details.
   * @throws ApiError
   */
  public getEmployeeOrderChecklists({
    orderId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/employee-checklists',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `User is unauthorized.`,
        403: `User does not have access to resource.`,
      },
    });
  }

  /**
   * Creates a checklist that belongs to the specific order. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistResponse Created checklist for the order.
   * @throws ApiError
   */
  public createEmployeeOrderChecklist({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist to create.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Employee_CreateEmployeeChecklistRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeChecklistResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/employee-checklists',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `User is unauthorized.`,
        403: `User does not have access to resource.`,
      },
    });
  }

  /**
   * Updates a checklist that belongs to the specific order. (Auth)
   * @returns any Updated checklist for the order.
   * @throws ApiError
   */
  public updateEmployeeOrderChecklist({
    orderId,
    checklistId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist to create.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Employee_UpdateEmployeeChecklistRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/employee-checklists/{checklistId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `User is unauthorized.`,
        403: `User does not have access to resource.`,
        404: `Checklist not found.`,
      },
    });
  }

  /**
   * Get a task that belongs to the specific checklist. (Auth)
   * @returns any Updated checklist for the order.
   * @throws ApiError
   */
  public getEmployeeChecklistTaskAsync({
    orderId,
    checklistId,
    taskId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Task identifier.
     */
    taskId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/employee-checklists/{checklistId}/tasks/{taskId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'taskId': taskId,
      },
      errors: {
        400: `Bad Request`,
        401: `User is unauthorized.`,
        403: `User does not have access to resource.`,
        404: `Task not found.`,
      },
    });
  }

  /**
   * Updates a task that belongs to the specific checklist. (Auth)
   * @returns any Updated checklist for the order.
   * @throws ApiError
   */
  public updateEmployeeChecklistTaskAsync({
    orderId,
    checklistId,
    taskId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Task identifier.
     */
    taskId: number,
    /**
     * Task data to update.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Employee_UpdateEmployeeChecklistTaskRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/employee-checklists/{checklistId}/tasks/{taskId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'taskId': taskId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `User is unauthorized.`,
        403: `User does not have access to resource.`,
        404: `Task not found.`,
      },
    });
  }

  /**
   * Creates a task that belongs to the specific checklist. (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeTaskResponse Created checklist for the order.
   * @throws ApiError
   */
  public createEmployeeChecklistTaskAsync({
    orderId,
    checklistId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Task to create.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Employee_CreateEmployeeChecklistTaskRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistManagement_Employee_EmployeeTaskResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/employee-checklists/{checklistId}/tasks',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Request object validation failed.`,
        401: `User is unauthorized.`,
        403: `User does not have access to resource.`,
        404: `Checklist not found.`,
      },
    });
  }

  /**
   * Gets the Count of Unread task for each User. (Auth)
   * @returns any Count of Unread task for each User.
   * @throws ApiError
   */
  public getUpcomingEmployeeTasksUnreadCount({
    apolloTeamId,
  }: {
    /**
     * Team Ids for which to get upcoming tasks.
     */
    apolloTeamId?: Array<string> | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/upcoming-tasks/unread-count',
      headers: {
        'Apollo-Team-Id': apolloTeamId,
      },
      errors: {
        401: `User is unauthorized.`,
      },
    });
  }

}
