/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_RecordedDocumentRequest } from '../models/Workspace_Common_Requests_RecordedDocumentRequest';
import type { Workspace_Common_Requests_ReportGenerationRequest } from '../models/Workspace_Common_Requests_ReportGenerationRequest';
import type { Workspace_Common_Responses_ReportDataResponse } from '../models/Workspace_Common_Responses_ReportDataResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Updates the payment policy acknowledgment (Auth)
   * @returns void
   * @throws ApiError
   */
  public updatePaymentPolicyAcknowledgment(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/reports/payment-policy-acknowledgment',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Generates report by calling MESH API. (Auth)
   * @returns Workspace_Common_Responses_ReportDataResponse Report generated successfully
   * @throws ApiError
   */
  public generateReport({
    requestBody,
  }: {
    requestBody?: Workspace_Common_Requests_ReportGenerationRequest,
  }): CancelablePromise<Workspace_Common_Responses_ReportDataResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/reports/generate-report',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Report not found / Invalid params`,
        500: `Server Error`,
      },
    });
  }

  /**
   * Generates report PDF by calling MESH API. (Auth)
   * @returns binary Report generated successfully
   * @throws ApiError
   */
  public getReportPdf({
    requestBody,
  }: {
    requestBody?: Workspace_Common_Requests_ReportGenerationRequest,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/reports/get-report-pdf',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Report not found / Invalid params`,
      },
    });
  }

  /**
   * Get Recorded Doc by ID calling MESH API. (Auth)
   * @returns binary Report generated successfully
   * @throws ApiError
   */
  public getRecordedDocById({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_RecordedDocumentRequest,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/reports/get-recordedDocById',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Report not found / Invalid params`,
      },
    });
  }

  /**
   * Generates .csv report for properties (Auth)
   * @returns binary Report generated successfully
   * @throws ApiError
   */
  public exportReportForProperties({
    requestBody,
  }: {
    requestBody?: Array<string> | null,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/reports/properties-export',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        500: `Internal server error`,
      },
    });
  }

}
