import { UsersTypeKeys, UsersAction, IUsersState } from './types';

export const initialState: IUsersState = {
  loading: false,
  error: null,
  resultsById: {},
  pagination: null,
  query: null,
};

export default (state = initialState, action: UsersAction) => {
  switch (action.type) {
    case UsersTypeKeys.FETCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
      };
    case UsersTypeKeys.FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        resultsById: action.resultsById,
        pagination: action.pagination,
      };
    case UsersTypeKeys.FETCH_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        resultsById: {},
        pagination: null,
      };
    case UsersTypeKeys.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        resultsById: {
          ...state.resultsById,
          ...action.result,
        },
      };
    case UsersTypeKeys.REQUEST_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UsersTypeKeys.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UsersTypeKeys.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UsersTypeKeys.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
