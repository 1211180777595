/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_ChargeAllocationViewModel_ } from '../models/Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_ChargeAllocationViewModel_';
import type { Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_ExtendedAllocationViewModel_ } from '../models/Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_ExtendedAllocationViewModel_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChargeAllocationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Saves charge allocations in Db (Auth)
   * @returns void
   * @throws ApiError
   */
  public saveAllocationsAsync({
    orderId,
    chargeId,
    chargeLineId,
    requestBody,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the charge
     */
    chargeId: number,
    /**
     * Id of the charge line
     */
    chargeLineId: number,
    /**
     * model with collection of allocations to save
     */
    requestBody: Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_ExtendedAllocationViewModel_,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/{chargeId}/lines/{chargeLineId}/allocations',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
        'chargeLineId': chargeLineId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

  /**
   * Get charge allocations for charge line (Auth)
   * @returns Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_ChargeAllocationViewModel_ Allocations were saved
   * @throws ApiError
   */
  public getAllocationsByChargeLineIdAsync({
    orderId,
    chargeId,
    chargeLineId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the charge
     */
    chargeId: number,
    /**
     * Id of the charge line
     */
    chargeLineId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_ChargeAllocationViewModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/{chargeId}/lines/{chargeLineId}/allocations',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
        'chargeLineId': chargeLineId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

  /**
   * Get charge allocations for charge (Auth)
   * @returns Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_ChargeAllocationViewModel_ Allocations were saved
   * @throws ApiError
   */
  public getAllocationsByChargeIdAsync({
    orderId,
    chargeId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the charge
     */
    chargeId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_ChargeAllocationViewModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/{chargeId}/allocations',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

}
