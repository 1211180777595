import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { sun } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 17H26.5L21 11.5V17ZM14 10H22L28 16V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H14C12.89 30 12 29.1 12 28V12C12 10.89 12.89 10 14 10ZM23 26V24H14V26H23ZM26 22V20H14V22H26Z"
      fill={props.fill || sun}
    />
  </svg>
);
