export function wrapCallback(
  wrappedFn?: (...args: any) => any,
  wrapperFn?: (...args: any) => any
) {
  return (...args: any) => {
    wrapperFn?.(...args);
    wrappedFn?.(...args);
  };
}

// TODO: Use variadic tuples once migrated to typescript >4.0 https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-0.html
/**
 * Takes a function and returns a new functions whose first arguments are fixed to rest of the arguments passed to this fucntion.
 * @param fn Function which we want to partially apply.
 * @param appliedArgs The arguments that are going to be fixed in the returned function.
 * @returns A new function with the first n arguments fixed with the rest of the arguments supplied to partial.
 */
export function partial(fn: (...args: any) => any, ...appliedArgs: any) {
  return (...args: any) => fn(...appliedArgs, ...args);
}
