import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M44.7131 8.00963L25.9131 0.353395C25.3403 0.120109 24.7259 0 24.1054 0C23.4849 0 22.8705 0.120109 22.2977 0.353395L3.49773 8.00963C2.64121 8.35845 1.9095 8.94727 1.39481 9.70188C0.880108 10.4565 0.605459 11.3431 0.605469 12.25C0.605469 31.2458 11.8164 44.3788 22.2978 48.6466C22.8706 48.8799 23.485 49 24.1055 49C24.726 49 25.3404 48.8799 25.9132 48.6466C34.2953 45.2336 47.6055 33.4274 47.6055 12.25C47.6055 11.3431 47.3308 10.4565 46.8161 9.70186C46.3014 8.94725 45.5696 8.35844 44.7131 8.00963ZM24.708 45.8196C24.321 45.9701 23.8897 45.9701 23.5027 45.8195C13.9221 42.1094 3.7388 29.0938 3.7388 12.25C3.7388 11.6299 4.11725 11.0751 4.70289 10.8366L23.5029 3.18036C23.6938 3.10255 23.8986 3.06249 24.1055 3.06249C24.3123 3.06249 24.5171 3.10255 24.708 3.18036L43.508 10.8366C43.7939 10.9524 44.0381 11.1486 44.2097 11.4002C44.3814 11.6518 44.4727 11.9476 44.4721 12.25C44.4721 29.0938 34.2888 42.1094 24.708 45.8196ZM38.8722 17.1561L21.2002 34.29C20.7395 34.7366 19.9955 34.7337 19.5385 34.2834L11.1297 25.9982C10.6727 25.548 10.6756 24.8208 11.1363 24.3742L11.9706 23.5654C12.4313 23.1187 13.1752 23.1216 13.6322 23.5719L20.3861 30.2264L36.3897 14.7101C36.8504 14.2634 37.5944 14.2663 38.0513 14.7166L38.8788 15.532C39.3359 15.9823 39.3329 16.7094 38.8722 17.1561Z"
      fill={props.fill ?? '#425664'}
    />
  </svg>
);
