import { IStagedDocumentUpload } from 'store/orders/types';

export const generateInternalDocumentName = ({
  metadata = {},
  file,
}: IStagedDocumentUpload): string => {
  let docName = '';
  if (metadata.subType) {
    docName += `${metadata.subType} `;
  }

  if (metadata.additionalInfo) {
    docName += `${metadata.additionalInfo} `;
  }

  if (metadata.displayName) {
    if (docName) {
      docName += `(${metadata.displayName.trim()})`;
    } else {
      docName = metadata.displayName.trim();
    }
  }

  return docName.trim() || file.name;
};
