import { BODY_NORMAL, H4_NORMAL } from 'config/typography';
import { FlexBox, Column, Box } from 'components/ui/Box';
import { DisplaySize } from '.';
import { gray8, red8 } from 'config/colors';
import { HeroH2 } from './Hero.styles';
import LandingPageSection from './Section';
import styled from 'styled-components';

export const FeaturesGrid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: minmax(429px, min-content) min-content;
  grid-template-rows: auto;

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    display: flex;
    flex-direction: column;
  }

  ${HeroH2} {
    grid-column: 1 / span 2;
    transition-duration: 0.3s;
    transition-property: flex-direction, grid-column, order;
    transition-timing-function: ease-out;
    will-change: flex-direction, grid-column, order;

    @media only screen and (max-width: ${DisplaySize.TABLET}px) {
      display: flex;
      flex-direction: column;
      grid-column: 1 / span 1;
      order: 2;
    }
  }

  & > ${FlexBox} {
    @media only screen and (max-width: ${DisplaySize.TABLET}px) {
      order: 2;
    }
  }
`;

export const FeaturesSection = styled(LandingPageSection)``;

export const List = styled(Column).attrs({
  gap: 'xlarge',
})`

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  li {
    ${H4_NORMAL}
    color: ${gray8};
    font-weight: 450;
    padding-left: 24px;
    position: relative;
    transition-duration: 0.3s;
    transition-property: font-size, line-height;
    transition-timing-function: ease-out;
    will-change: font-size, line-height;

    :before {
      color: ${red8};
      content: '•';
      left: 0;
      position: absolute;
    }

    @media only screen and (max-width: ${DisplaySize.TABLET}px) {
      font-size: 16px;
      line-height: 25px;
    }
    
    @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }


`;

export const DeviceImage = styled(Box)`
  display: flex;
  position: relative;
  transition-duration: 0.25s;
  transition-property: width, height;
  transition-timing-function: ease-out;
  width: 100%;
  will-change: width, height;

  div {
    max-height: 478px;
    max-width: 100%;
    transition-duration: 0.3s;
    transition-property: max-height;
    transition-timing-function: ease-out;
    will-change: max-height;
  }

  img {
    height: 100%;
    transition-duration: 0.3s;
    transition-property: width;
    transition-timing-function: ease-out;
    will-change: width;
  }

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    div {
      max-height: 449px;
    }
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    div {
      max-height: none;
    }

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    order: 1;

    div {
      max-height: 285px;
    }

    img {
      position: relative;
      width: auto;
    }
  }
`;

export const MobileAppContent = styled(Column).attrs({
  gap: 'small',
})`
  ${FlexBox} {
    @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
      justify-content: center;
    }
  }
`;

export const MobileAppMessage = styled.p`
  ${BODY_NORMAL}
  display: none;
  font-weight: 450;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    display: block;
  }
`;

export const BottomLinks = styled(Column).attrs({ gap: 'xsmall' })`
  display: none;
  gap: 16px;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    display: flex;
    justify-content: center;
    order: 4;
  }
`;
