import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.00049H12C12.0862 2.00049 12.1711 1.99987 12.2545 1.99927C13.2355 1.99218 14 1.98666 14 3.00049V12.0005C14 13.1005 13.1 14.0005 12 14.0005H2C0.9 14.0005 0 13.1005 0 12.0005V2.00049C0 0.900488 0.9 0.000488281 2 0.000488281H4L6 2.00049ZM10.472 11.0005L8 9.62628L5.528 11.0005L6.18 8.41049L4 6.66786L6.876 6.43944L8 4.00049L9.124 6.43944L12 6.66786L9.816 8.41049L10.472 11.0005Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
