import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { cadetBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.404 15.204C17.657 14.654 17.8 14.049 17.8 13.4C17.8 10.969 15.831 9 13.4 9C10.969 9 9 10.969 9 13.4C9 15.831 10.969 17.8 13.4 17.8C14.049 17.8 14.654 17.657 15.204 17.404L17.8 20L15.204 22.596C14.654 22.343 14.049 22.2 13.4 22.2C10.969 22.2 9 24.169 9 26.6C9 29.031 10.969 31 13.4 31C15.831 31 17.8 29.031 17.8 26.6C17.8 25.951 17.657 25.346 17.404 24.796L20 22.2L27.7 29.9H31V28.8L17.404 15.204ZM13.4 15.6C12.19 15.6 11.2 14.621 11.2 13.4C11.2 12.179 12.19 11.2 13.4 11.2C14.61 11.2 15.6 12.179 15.6 13.4C15.6 14.621 14.61 15.6 13.4 15.6ZM13.4 28.8C12.19 28.8 11.2 27.821 11.2 26.6C11.2 25.379 12.19 24.4 13.4 24.4C14.61 24.4 15.6 25.379 15.6 26.6C15.6 27.821 14.61 28.8 13.4 28.8ZM20 20.55C19.692 20.55 19.45 20.308 19.45 20C19.45 19.692 19.692 19.45 20 19.45C20.308 19.45 20.55 19.692 20.55 20C20.55 20.308 20.308 20.55 20 20.55ZM27.7 10.1L21.1 16.7L23.3 18.9L31 11.2V10.1H27.7Z"
      fill={props.fill || cadetBlue}
    />
  </svg>
);
