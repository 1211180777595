/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_PublicRecordsCMTPortfolio } from '../models/Workspace_Common_Models_PublicRecordsCMTPortfolio';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PublicRecordsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the CMT Document Profile information for public records (Auth)
   * @returns Workspace_Common_Models_PublicRecordsCMTPortfolio CmtProfileDetails serialized JSON
   * @throws ApiError
   */
  public getCmtProfileInfo({
    stateFips,
  }: {
    /**
     * state FIPS code
     */
    stateFips: string,
  }): CancelablePromise<Array<Workspace_Common_Models_PublicRecordsCMTPortfolio>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/property-profiles/cmtProfileInfo/{stateFips}',
      path: {
        'stateFips': stateFips,
      },
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `CmtDocumentProfile not found`,
      },
    });
  }

}
