import React, { useMemo } from 'react';
import { IOfficeHash } from 'store/offices/types';
import { Column } from 'components/ui/Box';
import { default as Menu } from 'components/ui/Menu';
import styled from 'styled-components';
import {
  BODY_MEDIUM,
  SMALL_BODY_BOOK,
  SMALL_BODY_BOLD,
} from 'config/typography';
import { primaryBlueGhost1, primaryBlue8, gray8, white } from 'config/colors';
import { MENU_PADDING_Y } from 'components/ui/Menu/Common';

interface IFAOfficeDropdownProps {
  close: () => void;
  offices: IOfficeHash;
  selectedId: number | string | null;
  setOfficeId: (officeId: number) => void;
  show: boolean;
}

export default function FAOfficeDropdown({
  close,
  offices,
  selectedId,
  setOfficeId,
  show,
}: IFAOfficeDropdownProps) {
  const sortedOffices = useMemo(
    () => Object.values(offices).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [offices]
  );

  return (
    <FAOfficeMenu show={show} key={'FA_Office_Menu'} idx={0}>
      {sortedOffices.map((office) => {
        const selected = office.id === selectedId;
        return (
          <FAOfficeMenuItem
            justify="center"
            key={office.id}
            onClick={
              selected
                ? undefined
                : () => {
                    close();
                    setOfficeId(office.fastId);
                  }
            }
            selected={selected}
          >
            <OfficeNameRow>{office.name}</OfficeNameRow>
            <OfficeIDRow selected={selected}>
              <span css={SMALL_BODY_BOOK}>Office BUID: </span>
              <span css={SMALL_BODY_BOLD}>{office.fastId}</span>
            </OfficeIDRow>
          </FAOfficeMenuItem>
        );
      })}
    </FAOfficeMenu>
  );
}

const rowHeight = 72;

const FAOfficeMenu = styled(Menu)`
  max-height: ${rowHeight * 4 + MENU_PADDING_Y * 2}px;
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 66px;
  z-index: 1;
`;

export const FAOfficeMenuItem = styled(Column)<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? primaryBlue8 : 'inherit')};
  color: ${({ selected }) => (selected ? white : 'inherit')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  height: ${rowHeight}px;
  padding: 0 12px;

  &:hover {
    background-color: ${({ selected }) =>
      selected ? primaryBlue8 : primaryBlueGhost1};
  }
`;

export const OfficeNameRow = styled.div`
  ${BODY_MEDIUM};
  white-space: nowrap;
`;

export const OfficeIDRow = styled.div<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? white : gray8)};
`;
