import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouterState } from 'connected-react-router';
import enhanceWithClickOutside from 'react-click-outside';
import { spindle, white, heavyShadow } from 'config/theme';
import {
  ChevronDownIcon,
  BellIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { IApplicationState, Dispatch } from 'store';
import * as actions from 'store/appNotifications/actions';
import Poller from 'services/poller';
import workspaceAPI from 'services/workspaceAPI';
import { NotificationsDropdown } from 'components/Notifications';
import { IconButton } from '../LinkIcon';
import { Counter } from '../Common';

interface INotificationsDropdownProps {
  counter: number;
  dispatch: Dispatch;
  router: RouterState;
}

interface INotificationsDropdownState {
  notificationsDropdownHidden: boolean;
}

// Set to match the polling interval for /_me endpoint
const NOTIFICATIONS_POLLING_INTERNVAL =
  AppConfig.notificationsPollingIntervalDuration;
const ESCAPE_KEY_CODE = 27;

export class Notifications extends React.Component<
  INotificationsDropdownProps,
  INotificationsDropdownState
> {
  public NotificationsDropdownNode: HTMLDivElement | null = null;
  public NotificationsDropdownTimeoutId = null;
  public poller;
  public state = {
    notificationsDropdownHidden: true,
  };

  public constructor(props) {
    super(props);

    this.poller = new Poller(
      () => workspaceAPI.get('/notifications/unread-count'),
      {
        interval: NOTIFICATIONS_POLLING_INTERNVAL,
        onComplete: ({ data }) =>
          props.dispatch(actions.setNotificationsCounter(data)),
      }
    );
  }

  public componentDidMount() {
    this.poller.start();
  }

  public componentWillUnmount() {
    this.poller.stop();
  }

  public componentDidUpdate(prevProps) {
    const { router: prevRouter, counter: prevCounter } = prevProps;
    const { counter, dispatch, router } = this.props;
    const { notificationsDropdownHidden } = this.state;

    if (prevRouter !== router) {
      const { location } = router;
      const { location: prevLocation } = prevRouter;

      if (location.pathname !== prevLocation.pathname) {
        this.setState({ notificationsDropdownHidden: true });
      }
    }

    if (!notificationsDropdownHidden && counter > prevCounter) {
      dispatch(actions.getAppNotifications());
    }
  }

  public render() {
    const { notificationsDropdownHidden } = this.state;
    const { counter } = this.props;
    const counterBadge = counter >= 10 ? '9+' : counter;

    return (
      <Container
        ref={(node) => (this.NotificationsDropdownNode = node)}
        onKeyDown={this.handleOnKeyDown}
        aria-labelledby="notifications-dropdown-menu"
      >
        <DropdownHandle
          onClick={this.toggleNotificationsDropdown}
          aria-haspopup="true"
          aria-expanded={!notificationsDropdownHidden}
          aria-controls="dropdown-menu-list"
          tabIndex={0}
          id="notificationsBadge"
          role="menuitem"
        >
          {counter > 0 && <Counter>{counterBadge}</Counter>}
          <IconButton
            icon={<BellIcon style={{ height: '18px', marginLeft: '-12px' }} />}
            label={
              <React.Fragment>
                Notifications <ChevronDownIcon style={{ marginLeft: '6px' }} />
              </React.Fragment>
            }
          />
        </DropdownHandle>
        {!notificationsDropdownHidden && (
          <NotificationsDropdownContainer>
            <NotificationsDropdown
              onNotificationClick={this.closeNotificationsDropdown}
              onViewAllClick={this.closeNotificationsDropdown}
            />
          </NotificationsDropdownContainer>
        )}
      </Container>
    );
  }

  private toggleNotificationsDropdown = () => {
    this.setState({
      notificationsDropdownHidden: !this.state.notificationsDropdownHidden,
    });
  };

  private closeNotificationsDropdown = () => {
    this.setState({ notificationsDropdownHidden: true });
  };

  private handleOnKeyDown = (e) => {
    if (e.keyCode === ESCAPE_KEY_CODE) {
      this.setState({ notificationsDropdownHidden: true });
    }
  };

  // @ts-ignore /* tslint:disable:no-unused-variable */
  private handleClickOutside = () => {
    this.setState({ notificationsDropdownHidden: true });
  };
}

export function mapStateToProps(state: IApplicationState) {
  return {
    counter: state.appNotifications.counter,
    router: state.router,
  };
}

export default withRouter(
  connect(mapStateToProps)(enhanceWithClickOutside(Notifications))
);

const NotificationsDropdownContainer = styled.div`
  margin: 0;
  padding: 0;
  background: ${white};
  border-radius: 3px;
  box-shadow: ${heavyShadow};
  position: absolute;
  right: -18px;
  text-align: left;
  top: 56px;
  width: 400px;
  z-index: 10;

  &:after,
  &:before {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 12px;
    margin-left: -12px;
  }
`;

const Container = styled.div`
  margin-right: 20px;
  position: relative;
`;

const DropdownHandle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${spindle};
  cursor: pointer;
  position: relative;
  &:hover {
    color: ${white};
    fill: ${white};
  }
`;
