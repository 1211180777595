/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_BankVerifyDetails } from '../models/Workspace_Common_Requests_BankVerifyDetails';
import type { Workspace_Common_Requests_RemoveDocumentRequest } from '../models/Workspace_Common_Requests_RemoveDocumentRequest';
import type { Workspace_Common_Requests_RequireReason } from '../models/Workspace_Common_Requests_RequireReason';
import type { Workspace_Common_Responses_WireInstructionFileAssociation } from '../models/Workspace_Common_Responses_WireInstructionFileAssociation';
import type { Workspace_Common_Responses_WireInstructionFileAuditList } from '../models/Workspace_Common_Responses_WireInstructionFileAuditList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WireInstructionFilesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This endpoint gets a wire instruction file association by Wire Instruction File Id (Auth)
   * @returns Workspace_Common_Responses_WireInstructionFileAssociation Success
   * @throws ApiError
   */
  public getWireInstructionFileAsync({
    wireInstructionFileId,
  }: {
    /**
     * The Id of the wire instruction file.
     */
    wireInstructionFileId: number,
  }): CancelablePromise<Workspace_Common_Responses_WireInstructionFileAssociation> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instruction-files/{wireInstructionFileId}',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      errors: {
        403: `User doesn't have access to the Wire Instruction File association.`,
        404: `Wire Instruction or file association does not exist.`,
      },
    });
  }

  /**
   * Marks a wire instruction file as "removed". (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeWireInstructionFile({
    wireInstructionFileId,
    sendNotification = false,
    requestBody,
  }: {
    wireInstructionFileId: number,
    sendNotification?: boolean,
    requestBody?: Workspace_Common_Requests_RequireReason,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instruction-files/{wireInstructionFileId}/_remove',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      query: {
        'sendNotification': sendNotification,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to "remove" this Wire Instruction File association.`,
        404: `Wire Instruction doesn't exist or File doesn't exist or Invalid UserType.`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * This endpoint overrides Bank Verification (Auth)
   * @returns void
   * @throws ApiError
   */
  public overrideBankVerification({
    wireInstructionFileId,
    sendNotification,
    additionalWireInstructionsId,
    requestBody,
  }: {
    /**
     * The Id of the wire instruction file.
     */
    wireInstructionFileId: number,
    /**
     * Send user invite to customer if true
     */
    sendNotification?: boolean,
    /**
     * The Id of the additional wireinstruction found
     */
    additionalWireInstructionsId?: number,
    /**
     * The details of DocumentIds when finalising the wire.
     */
    requestBody?: Workspace_Common_Requests_BankVerifyDetails,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instruction-files/{wireInstructionFileId}/bank-verify',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      query: {
        'sendNotification': sendNotification,
        'additionalWireInstructionsId': additionalWireInstructionsId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to internally verify this Wire Instruction Bank information.`,
        404: `Wire Instruction doesn't exist or File doesn't exist or Invalid UserType.`,
        409: `Wire Instruction File is not awaiting bank verification.`,
      },
    });
  }

  /**
   * This endpoint overrides User Verification (Auth)
   * @returns void
   * @throws ApiError
   */
  public overrideUserVerification({
    wireInstructionFileId,
    sendNotification = false,
    additionalWireInstructionsId,
    requestBody,
  }: {
    /**
     * The Id of the wire instruction file.
     */
    wireInstructionFileId: number,
    /**
     * Send Bypass User Verifcation Notification Email to Customer if true
     */
    sendNotification?: boolean,
    /**
     * additionalWireInstructionsId for addtional Wire
     */
    additionalWireInstructionsId?: number,
    /**
     * The reason why employee is overwriting user verification.
     */
    requestBody?: Workspace_Common_Requests_RequireReason,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instruction-files/{wireInstructionFileId}/internally-verify',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      query: {
        'sendNotification': sendNotification,
        'additionalWireInstructionsId': additionalWireInstructionsId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to internally verify this Wire Instruction File association.`,
        404: `Wire Instruction doesn't exist or File doesn't exist or Invalid UserType.`,
        409: `Wire Instruction File association is not set for User Verification`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * This endpoint gets all activity logs for wire instruction file (Auth)
   * @returns Workspace_Common_Responses_WireInstructionFileAuditList Success
   * @throws ApiError
   */
  public getWireInstructionFileAudits({
    wireInstructionFileId,
  }: {
    /**
     * Wire Instruction File Id
     */
    wireInstructionFileId: number,
  }): CancelablePromise<Workspace_Common_Responses_WireInstructionFileAuditList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instruction-files/{wireInstructionFileId}/logs',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to internally view activity information.`,
        404: `Wire Instruction doesn't exist or File doesn't exist or Invalid UserType.`,
      },
    });
  }

  /**
   * Removes a wire instruction document. (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeWireInstructionFileDocumentAsync({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_RemoveDocumentRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instruction-files/_removeDocument',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to "remove" this Wire Instruction File association.`,
        404: `Wire Instruction doesn't exist or File doesn't exist or Invalid UserType.`,
        422: `Validation failed.`,
      },
    });
  }

}
