import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M21.9427 11.8508L20.7107 13.0861L18.1852 10.5537L19.4171 9.31843C19.6758 9.05902 20.107 9.05902 20.3658 9.31843L21.9427 10.8996C22.2014 11.159 22.2014 11.5914 21.9427 11.8508ZM9.99252 18.7561L17.4583 11.2702L19.9838 13.8025L12.5304 21.3008H9.99252V18.7561ZM9.99252 12.6537C4.54719 12.6537 0.136719 14.8649 0.136719 17.5949V20.0655H7.52857V17.7308L12.4565 12.7896C11.6434 12.6908 10.8179 12.6537 9.99252 12.6537ZM9.99252 0.300781C8.68556 0.300781 7.43213 0.821368 6.50797 1.74802C5.58381 2.67467 5.06462 3.93148 5.06462 5.24196C5.06462 6.55244 5.58381 7.80925 6.50797 8.7359C7.43213 9.66255 8.68556 10.1831 9.99252 10.1831C11.2995 10.1831 12.5529 9.66255 13.4771 8.7359C14.4012 7.80925 14.9204 6.55244 14.9204 5.24196C14.9204 3.93148 14.4012 2.67467 13.4771 1.74802C12.5529 0.821368 11.2995 0.300781 9.99252 0.300781Z"
      fill={shuttleGrey}
    />
  </svg>
);
