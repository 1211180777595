import { IRegion } from 'store/regions/types';
import { IOffice } from 'store/offices/types';

interface IRegionDatum extends IRegion {
  offices: Array<Omit<IOffice, 'regionId'>>;
}

export interface IRegionAndOfficeData {
  primaryRegionId: number;
  primaryOfficeId: number;
  regions: IRegionDatum[];
}

export type AccountingView =
  | 'Incoming Wires'
  | 'Incoming Funds'
  | 'Deposits'
  | 'Outgoing Wires'
  | 'Customer Uploads'
  | 'IBA Transactions'
  | 'Checklists Templates'
  | 'Trust Accounting'
  | 'Document Submission'
  | 'Deposit List';

export enum AccountingURLPath {
  CUSTOMER_UPLOADS = 'customer-uploads',
  CHECKLISTS = 'checklists',
  DOCUMENT_SUBMISSION = 'document-submission',
  TRUST_ACCOUNTING = 'trust-accounting',
  INCOMING_WIRES = 'incoming-funds',
  OUTGOING_WIRES = 'outgoing-funds',
  IBA_TRANSACTIONS = 'iba-transactions',
  OUTGOING_FUNDS = 'funds',
  INCOMING_RECEIPT = 'deposits/receipt',
  DEPOSIT_LIST = 'deposit-list',
}

export enum ActivityRightId {
  TRUST_EXTRACT = 112,
  ESCROW_DISBURSEMENT = 102,
}
