/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_TrustExtract_ViewModels_DiscardAndTransmitResponse } from '../models/Workspace_API_FeatureAreas_TrustExtract_ViewModels_DiscardAndTransmitResponse';
import type { Workspace_API_FeatureAreas_TrustExtract_ViewModels_GeneratePreviewResponse } from '../models/Workspace_API_FeatureAreas_TrustExtract_ViewModels_GeneratePreviewResponse';
import type { Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildRequest } from '../models/Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildRequest';
import type { Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildResponse } from '../models/Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildResponse';
import type { Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractsPagination } from '../models/Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractsPagination';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TrustExtractService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This endpoint fetches the list for trust extracts for the given office (Auth)
   * @returns Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractsPagination The list of Trust Extracts
   * @throws ApiError
   */
  public getTrustExtractsAsync({
    officeId,
    cutoffDate,
    pageSize = 30,
    pageNumber = 1,
  }: {
    /**
     * The office Id
     */
    officeId: number,
    /**
     * The cutoff date filter
     */
    cutoffDate?: string | null,
    /**
     * The page size
     */
    pageSize?: number,
    /**
     * The page number
     */
    pageNumber?: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractsPagination> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/trust-extracts',
      path: {
        'officeId': officeId,
      },
      query: {
        'cutoffDate': cutoffDate,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        404: `Office or Region not found`,
      },
    });
  }

  /**
   * Build a new trust extract file. (Auth)
   * @returns Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildResponse build is processing successfully.
   * @throws ApiError
   */
  public buildTrustExtractAsync({
    officeId,
    requestBody,
  }: {
    /**
     * Office Id.
     */
    officeId: number,
    /**
     * Contains cutoff time
     */
    requestBody: Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildRequest,
  }): CancelablePromise<Workspace_API_FeatureAreas_TrustExtract_ViewModels_TrustExtractBuildResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{officeId}/trust-extracts',
      path: {
        'officeId': officeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request.`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Office not found.`,
        409: `Error occured while processing.`,
        422: `Unprocessable Entity. May not be possible to build  a new TE since an existing one is building.`,
      },
    });
  }

  /**
   * Generate a Preview of a built trust extract file. (Auth)
   * @returns Workspace_API_FeatureAreas_TrustExtract_ViewModels_GeneratePreviewResponse Trust Extract can be previewed successfully. Returns bytes.
   * @throws ApiError
   */
  public generatePreviewTrustExtractAsync({
    officeId,
    extractId,
    withOrderActivities = false,
  }: {
    /**
     * Office Id.
     */
    officeId: number,
    /**
     * Extract Id.
     */
    extractId: number,
    /**
     * withOrderActivities flag
     */
    withOrderActivities?: boolean,
  }): CancelablePromise<Workspace_API_FeatureAreas_TrustExtract_ViewModels_GeneratePreviewResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{officeId}/trust-extracts/{extractId}/generate-preview',
      path: {
        'officeId': officeId,
        'extractId': extractId,
      },
      query: {
        'withOrderActivities': withOrderActivities,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `Forbidden`,
        404: `Office not found.`,
        500: `Error occured while processing request.`,
      },
    });
  }

  /**
   * Preview document of a built trust extract file. Returns byte content. No Content is returned if the preview is still being generated. (Auth)
   * @returns binary Trust Extract can be previewed successfully. Returns Filestream.
   * @throws ApiError
   */
  public previewTrustExtractDocumentAsync({
    officeId,
    eventDeliveryLogId,
  }: {
    /**
     * Office Id.
     */
    officeId: number,
    /**
     * Event Id of the generated preview to fetch the Bytes response.
     */
    eventDeliveryLogId: number,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/trust-extracts/preview/{eventDeliveryLogId}',
      path: {
        'officeId': officeId,
        'eventDeliveryLogId': eventDeliveryLogId,
      },
      errors: {
        400: `Error occured while processing request.`,
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `Forbidden`,
        404: `Office not found.`,
      },
    });
  }

  /**
   * Discard the pending trust extract. (Auth)
   * @returns Workspace_API_FeatureAreas_TrustExtract_ViewModels_DiscardAndTransmitResponse Discarded trust extract successfully.
   * @throws ApiError
   */
  public discardTrustExtractAsync({
    officeId,
    extractId,
  }: {
    /**
     * Office Id.
     */
    officeId: number,
    /**
     * Extract Id.
     */
    extractId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_TrustExtract_ViewModels_DiscardAndTransmitResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/offices/{officeId}/trust-extracts/{extractId}',
      path: {
        'officeId': officeId,
        'extractId': extractId,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        422: `Error occured while discarding trust extract`,
      },
    });
  }

  /**
   * Transmit the pending trust extract. (Auth)
   * @returns Workspace_API_FeatureAreas_TrustExtract_ViewModels_DiscardAndTransmitResponse Transmitted trust extract successfully.
   * @throws ApiError
   */
  public transmitTrustExtractAsync({
    officeId,
    extractId,
  }: {
    /**
     * Office Id.
     */
    officeId: number,
    /**
     * Extract Id.
     */
    extractId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_TrustExtract_ViewModels_DiscardAndTransmitResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{officeId}/trust-extracts/{extractId}/transmit',
      path: {
        'officeId': officeId,
        'extractId': extractId,
      },
      errors: {
        400: `Return bad request when office id or extract is not correct`,
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        422: `Error occured while transmitting trust extract`,
      },
    });
  }

}
