import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 0.714844C1.00125 0.714844 0 1.70484 0 2.91484V20.5148C0 21.0983 0.237053 21.6579 0.65901 22.0705C1.08097 22.4831 1.65326 22.7148 2.25 22.7148H15.75C16.3467 22.7148 16.919 22.4831 17.341 22.0705C17.7629 21.6579 18 21.0983 18 20.5148V7.31484L11.25 0.714844H2.25ZM10.125 2.36484L16.3125 8.41484H10.125V2.36484ZM5.54625 11.9568H13.5V19.7338L11.115 17.4018L7.93125 20.5148L4.7475 17.4018L7.93125 14.2998"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
