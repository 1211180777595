/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Microsoft_AspNetCore_JsonPatch_Operations_Operation } from '../models/Microsoft_AspNetCore_JsonPatch_Operations_Operation';
import type { Workspace_Common_Responses_FileMilestones } from '../models/Workspace_Common_Responses_FileMilestones';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderMilestonesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a list of milestones for the given file. (Auth)
   * @returns Workspace_Common_Responses_FileMilestones Milestones successfully retrieved.
   * @throws ApiError
   */
  public getOrderMilestones({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Workspace_Common_Responses_FileMilestones> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/milestones',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header.`,
        404: `The order doesn't exist or the external user doesn't have an intersect between user offices and file offices.`,
      },
    });
  }

  /**
   * Updates a FileMilestone using JSON Patch syntax. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateOrderMilestone({
    orderId,
    milestoneId,
    requestBody,
  }: {
    orderId: number,
    milestoneId: number,
    requestBody: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/milestones/{milestoneId}',
      path: {
        'orderId': orderId,
        'milestoneId': milestoneId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header.`,
        404: `The order doesn't exist or the external user doesn't have an intersect between user offices and file offices.`,
      },
    });
  }

}
