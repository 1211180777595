import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { api } from 'services/workspaceAPI';
import { ApiTypeKeys } from 'store/api/types';
import { apiSuccess, apiError } from 'store/api/actions';

const apiMiddleware: Middleware = ({ dispatch }: MiddlewareAPI) => (
  next: Dispatch
) => (action) => {
  next(action);

  const ENTITY =
    action.payload && action.payload.meta && action.payload.meta.entity;

  if (action.type === `${ENTITY} ${ApiTypeKeys.API_REQUEST}`) {
    const { method, url, params } = action.payload.meta;

    api({ method, url, params })
      .then(({ data }) => {
        dispatch(apiSuccess(data, ENTITY));
      })
      .catch((error) => {
        dispatch(apiError(error, ENTITY));
      });
  }
};

export default apiMiddleware;
