import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M8.79308 2.28269C10.0681 2.28269 11.1013 3.31588 11.1013 4.59087C11.1013 5.86587 10.0681 6.89906 8.79308 6.89906C7.51808 6.89906 6.48489 5.86587 6.48489 4.59087C6.48489 3.31588 7.51808 2.28269 8.79308 2.28269ZM8.79308 12.1749C12.0575 12.1749 15.4978 13.7796 15.4978 14.4831V15.6921H2.08836V14.4831C2.08836 13.7796 5.52865 12.1749 8.79308 12.1749ZM8.79308 0.194336C6.36399 0.194336 4.39654 2.16179 4.39654 4.59087C4.39654 7.01996 6.36399 8.98741 8.79308 8.98741C11.2222 8.98741 13.1896 7.01996 13.1896 4.59087C13.1896 2.16179 11.2222 0.194336 8.79308 0.194336ZM8.79308 10.0865C5.85839 10.0865 0 11.5594 0 14.4831V17.7805H17.5862V14.4831C17.5862 11.5594 11.7278 10.0865 8.79308 10.0865Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
