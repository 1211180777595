import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.12195 8.18182V10.3636H17.7073V8.18182H9.12195ZM9.12195 1.63636V3.81818L13.4146 3.81818V1.63636L9.12195 1.63636ZM9.12195 14.7273V16.9091L22 16.9091V14.7273L9.12195 14.7273ZM4.82927 3.81818L7.5122 3.81818L3.7561 0L0 3.81818L2.68293 3.81818L2.68293 18H4.82927L4.82927 3.81818Z"
      fill={props.fill || blue}
    />
  </svg>
);
