/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_Forms_CreateWireInstruction } from '../models/Workspace_API_Forms_CreateWireInstruction';
import type { Workspace_API_Forms_CreateWireInstructionFile } from '../models/Workspace_API_Forms_CreateWireInstructionFile';
import type { Workspace_API_Forms_UpdateVerifiedWireInstruction } from '../models/Workspace_API_Forms_UpdateVerifiedWireInstruction';
import type { Workspace_API_Forms_UpdateWireInstruction } from '../models/Workspace_API_Forms_UpdateWireInstruction';
import type { Workspace_API_ViewModels_UpdateWireInstructionNotesViewModel } from '../models/Workspace_API_ViewModels_UpdateWireInstructionNotesViewModel';
import type { Workspace_Common_Requests_RequireReason } from '../models/Workspace_Common_Requests_RequireReason';
import type { Workspace_Common_Requests_SearchWireInstructionRequest } from '../models/Workspace_Common_Requests_SearchWireInstructionRequest';
import type { Workspace_Common_Responses_CreateWireInstruction } from '../models/Workspace_Common_Responses_CreateWireInstruction';
import type { Workspace_Common_Responses_VerifiedWireInstructionList } from '../models/Workspace_Common_Responses_VerifiedWireInstructionList';
import type { Workspace_Common_Responses_WireInstruction } from '../models/Workspace_Common_Responses_WireInstruction';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WireInstructionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This endpoint creates a new Wire Instruction and associated File. (Auth)
   * @returns Workspace_Common_Responses_CreateWireInstruction Wire Instruction was successfully created and return.
   * @throws ApiError
   */
  public createWireInstruction({
    requestBody,
  }: {
    /**
     * The validator object (`CreateWireInstructionForm`) containing all information needed to create a Wire Instruction and Associated File.
     */
    requestBody: Workspace_API_Forms_CreateWireInstruction,
  }): CancelablePromise<Workspace_Common_Responses_CreateWireInstruction> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/wire-instructions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Invalid UserType.`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * This endpoints fetches the text of the questions and possible answers for international wires (Auth)
   * @returns string Success
   * @throws ApiError
   */
  public geInternationalWireQuestionnaire(): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instructions',
      errors: {
        404: `Not found`,
      },
    });
  }

  /**
   * Get a list of verified wire instructions matching by organization name. (Auth)
   * @returns Workspace_Common_Responses_VerifiedWireInstructionList Success
   * @throws ApiError
   */
  public getVerifiedWireInstructions({
    organizationName,
    orderId,
  }: {
    /**
     * Organization name
     */
    organizationName?: string | null,
    /**
     * Order Id
     */
    orderId?: number | null,
  }): CancelablePromise<Workspace_Common_Responses_VerifiedWireInstructionList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instructions/verified',
      query: {
        'organizationName': organizationName,
        'orderId': orderId,
      },
      errors: {
        401: `Missing or invalid authorization header`,
        403: `User doesn't have access to search Wire Instructions.`,
        404: `Invalid UserType.`,
      },
    });
  }

  /**
   * This endpoint gets a wire instruction by Id and wireInstructionFileId. (Auth)
   * @returns Workspace_Common_Responses_WireInstruction Success
   * @throws ApiError
   */
  public getWireInstruction({
    id,
    wireInstructionFileId,
  }: {
    /**
     * The Id of the wire instruction.
     */
    id: number,
    /**
     * The Id of the  WireInstructionFile.
     */
    wireInstructionFileId?: number,
  }): CancelablePromise<Workspace_Common_Responses_WireInstruction> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instructions/{id}',
      path: {
        'id': id,
      },
      query: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      errors: {
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Wire Instruction does not exist.`,
      },
    });
  }

  /**
   * This endpoint updates an existing Wire Instruction with bank account details. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateWireInstruction({
    id,
    requestBody,
  }: {
    /**
     * The Id of the wire instruction.
     */
    id: number,
    /**
     * The Wire Instruction information.
     */
    requestBody: Workspace_API_Forms_UpdateWireInstruction,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instructions/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Wire Instruction does not exists.`,
        409: `User's request needs to re-validate MFA.`,
        410: `Wire Instruction action is already completed`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * This endpoint updates an existing verfied Wire Instruction with notes and documents. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateVerifiedWireInstruction({
    wireInstructionFileId,
    requestBody,
  }: {
    wireInstructionFileId: number,
    requestBody: Workspace_API_Forms_UpdateVerifiedWireInstruction,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instructions/verified/{wireInstructionFileId}',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized user.`,
        403: `User doesn't have access to the Verfied Wire Instruction.`,
        404: `Verfied Wire Instruction does not exists.`,
        409: `Wire Instruction is not verified one.`,
        410: `Wire Instruction File is not verified one.`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * This endpoint updates notes for bank verified wire. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateWireInstructionNotesAsync({
    wireInstructionFileId,
    requestBody,
  }: {
    /**
     * The wire-instruction file Id
     */
    wireInstructionFileId: number,
    /**
     * The request body
     */
    requestBody?: Workspace_API_ViewModels_UpdateWireInstructionNotesViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instructions/notes/{wireInstructionFileId}',
      path: {
        'wireInstructionFileId': wireInstructionFileId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized user.`,
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Wire Instruction does not exists.`,
        410: `Wire Instruction File is not the bank verified one.`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * This endpoint creates a new Wire Instruction File association. (Auth)
   * @returns void
   * @throws ApiError
   */
  public createWireInstructionFileAssociation({
    id,
    fileId,
    requestBody,
  }: {
    /**
     * The Id of the wire instruction.
     */
    id: number,
    /**
     * The Id of the associated file.
     */
    fileId: number,
    /**
     * The validator object (`CreateWireInstructionFile`) containing all information needed to create a Wire Instruction File association.
     */
    requestBody: Workspace_API_Forms_CreateWireInstructionFile,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instructions/{id}/files/{fileId}',
      path: {
        'id': id,
        'fileId': fileId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to create this Wire Instruction File association.`,
        404: `Wire Instruction doesn't exist or File doesn't exist or Invalid UserType.`,
        422: `Validation failed on form body.`,
      },
    });
  }

  /**
   * Sets Wire Instruction to be active or non-active. (Auth)
   * @returns void
   * @throws ApiError
   */
  public activateWireInstructionAsync({
    id,
    active,
    orderId,
    requestBody,
  }: {
    /**
     * Wire Instruction Id
     */
    id: number,
    /**
     * Flag determining whether WireInstruction should be active or not.
     */
    active: boolean,
    /**
     * Id of the order where the wire instruction is being activated / inactivated (optional). Important for reused wire instructions, to allow recording events for the current order and not only for the original order.
     */
    orderId?: number | null,
    /**
     * The reason why employee is activating/deactivating wire instruction.
     */
    requestBody?: Workspace_Common_Requests_RequireReason,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instructions/{id}/_activate',
      path: {
        'id': id,
      },
      query: {
        'active': active,
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Wire Instruction marked as fradulent earlier.`,
        401: `Missing or invalid Authorization header.`,
        404: `Wire Instruction doesn't exist or Invalid UserType.`,
        409: `Wire Instruction is not in the state for making it active or non-active.`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * Resets ability to reuse Wire Instruction without verification. (Auth)
   * @returns void
   * @throws ApiError
   */
  public resetReuseWithoutVerification({
    id,
    fileId,
    sendNotification,
    requestBody,
  }: {
    id: number,
    fileId: number,
    /**
     * To verify if user required to be notified
     */
    sendNotification?: boolean,
    requestBody?: Workspace_Common_Requests_RequireReason,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instructions/{id}/files/{fileId}/_expire-verification',
      path: {
        'id': id,
        'fileId': fileId,
      },
      query: {
        'sendNotification': sendNotification,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have knox permission.`,
        404: `Wire Instruction doesn't exist or Invalid UserType.`,
      },
    });
  }

  /**
   * Get a list of verified wire instructions matching by routing and account number. (Auth)
   * @returns Workspace_Common_Responses_VerifiedWireInstructionList Success
   * @throws ApiError
   */
  public searchWireInstructions({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_SearchWireInstructionRequest,
  }): CancelablePromise<Workspace_Common_Responses_VerifiedWireInstructionList> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/wire-instructions/_search',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid authorization header`,
        403: `User doesn't have access to search Wire Instructions.`,
        404: `Invalid UserType.`,
      },
    });
  }

  /**
   * Get a list of latest payee names for wireinstructions matching by routing and account number. (Auth)
   * @returns string Success
   * @throws ApiError
   */
  public searchLatestPayeeNames({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_SearchWireInstructionRequest,
  }): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/wire-instructions/_searchLatestPayeeNames',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Missing or invalid authorization header`,
        403: `User doesn't have access to search Latest Payee Names.`,
        404: `Invalid UserType.`,
      },
    });
  }

}
