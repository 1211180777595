/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Settlement_FractionalChargeViewModel } from '../models/Workspace_API_ViewModels_Settlement_FractionalChargeViewModel';
import type { Workspace_API_ViewModels_Settlement_FractionalPartyManualAllocationViewModel } from '../models/Workspace_API_ViewModels_Settlement_FractionalPartyManualAllocationViewModel';
import type { Workspace_API_ViewModels_Settlement_FractionalStatementViewModel } from '../models/Workspace_API_ViewModels_Settlement_FractionalStatementViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateVestedInterestViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateVestedInterestViewModel';
import type { Workspace_Common_Models_Enums_PartyType } from '../models/Workspace_Common_Models_Enums_PartyType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FractionalSettlementStatementService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Fractional Settlement Statement for a file (Auth)
   * @returns Workspace_API_ViewModels_Settlement_FractionalStatementViewModel Fractional statement successfully retrieved
   * @throws ApiError
   */
  public getFractionalStatementAsync({
    orderId,
    partyType,
  }: {
    /**
     * file id
     */
    orderId: number,
    /**
     * party type can be buyer or seller
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_FractionalStatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/fractional-settlement/parties/{partyType}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Updates vested interest allocations for parties (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateVestedInterestAllocationsAsync({
    orderId,
    partyType,
    requestBody,
  }: {
    orderId: number,
    partyType: Workspace_Common_Models_Enums_PartyType,
    /**
     * Updated Allocations
     */
    requestBody?: Array<Workspace_API_ViewModels_Settlement_UpdateVestedInterestViewModel> | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/fractional-settlement/parties/{partyType}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Updates a Fractional Settlement charge for an order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_FractionalChargeViewModel Fractional settlement charge successfully updated
   * @throws ApiError
   */
  public updateFractionalSettlementChargeAsync({
    orderId,
    partyType,
    partyId,
    chargeId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Party type (Buyer or Seller)
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
    /**
     * Party identifier to whom the charge belongs to
     */
    partyId: number,
    /**
     * Charge identifier
     */
    chargeId: number,
    /**
     * Party Manual Allocation info
     */
    requestBody: Workspace_API_ViewModels_Settlement_FractionalPartyManualAllocationViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_FractionalChargeViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/fractional-settlement/party-types/{partyType}/parties/{partyId}/charges/{chargeId}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
        'partyId': partyId,
        'chargeId': chargeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Refreshes allocations for a Fractional Settlement charge for an order amd party type (Auth)
   * @returns Workspace_API_ViewModels_Settlement_FractionalChargeViewModel Fractional settlement charge successfully updated
   * @throws ApiError
   */
  public refreshFractionalSettlementChargesAsync({
    orderId,
    partyType,
    chargeId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Party type (Buyer or Seller)
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
    /**
     * Charge identifier
     */
    chargeId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_FractionalChargeViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/fractional-settlement/party-types/{partyType}/charges/{chargeId}/refresh',
      path: {
        'orderId': orderId,
        'partyType': partyType,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Clears a Fractional Settlement charge for an order and party type (Auth)
   * @returns Workspace_API_ViewModels_Settlement_FractionalChargeViewModel Fractional settlement charge successfully updated
   * @throws ApiError
   */
  public clearFractionalSettlementChargesAsync({
    orderId,
    partyType,
    chargeId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Party type (Buyer or Seller)
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
    /**
     * Charge identifier
     */
    chargeId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_FractionalChargeViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/fractional-settlement/party-types/{partyType}/charges/{chargeId}/clear',
      path: {
        'orderId': orderId,
        'partyType': partyType,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

}
