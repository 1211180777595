import { isCurrentUser } from 'store/auth/getters';
import { isFeatureEnabled } from 'store/features/helpers';
import { IFileOffices } from 'store/files/types';
import { IAuthState } from 'store/auth/types';
import {
  IActivityRights,
  ICanAccessFeatureHelper,
  ActivityRightValue,
  UserAcceptedActivityRights,
} from './types';
import { IUser } from 'store/users/types';

export function hasAccessToFileInFast(
  authState: IAuthState,
  file: IFileOffices
): boolean {
  const { offices } = file;
  const userOfficeIds: number[] = authState.session.officeIds;
  const owningOfficeIds: number[] = (offices || []).map(
    ({ businessUnitId }) => businessUnitId
  );

  return userOfficeIds.some((officeId) => owningOfficeIds.includes(officeId));
}

export function currentUserActivityRights(
  authState: IAuthState
): string[] | [] {
  const activityRights = authState.session.activityRights;
  return activityRights;
}

export function canUserEditActivityRights(
  authState: IAuthState,
  user?: IUser
): boolean {
  const currentUser = isCurrentUser(authState, user);
  const userActivityRights = authState.session.activityRights;
  const acceptedActvityRights = [
    ActivityRightValue.Admin,
    ActivityRightValue.UserManagement,
  ];
  const admin = acceptedActvityRights.some(
    (activity) => userActivityRights.indexOf(activity) !== -1
  );

  return Boolean(admin && !currentUser);
}

export function userEditableActivityRights(
  userActivityRights: string[],
  allActivityRights: IActivityRights[]
): IActivityRights[] {
  return allActivityRights.map((activityRight) => {
    // hide admin option if user does not have admin in activity right
    const isAdmin = userActivityRights.indexOf(ActivityRightValue.Admin) !== -1;

    return {
      ...activityRight,
      hidden: !isAdmin && activityRight.value === ActivityRightValue.Admin,
    };
  });
}

export function canUserAccessFeature({
  state,
  featureFlags,
  acceptedActivityRights,
  file,
  userAcceptedActivityRights,
}: ICanAccessFeatureHelper) {
  let isEnabledFeature;
  let hasAccessToFile;
  let canUserAccess = true;

  if (acceptedActivityRights && acceptedActivityRights.length > 0) {
    let userActivityRights: ActivityRightValue[] = [];
    const sessionActivityRights = state?.auth?.session?.activityRights || [];

    // if userAcceptedActivityRights === 'CurrentlyNotSupported'
    // we allow this part of logic this can be removed when permissions is set.
    // if userAcceptedActivityRights is undefined it automatically checks what
    // is in the users activityRight session.
    // and checks to find a match with the acceptedActivityRights
    if (
      userAcceptedActivityRights ===
      UserAcceptedActivityRights.CurrentlyNotSupported
    ) {
      canUserAccess = true;
    } else if (
      userAcceptedActivityRights &&
      userAcceptedActivityRights.length > 0
    ) {
      userActivityRights = [
        ...userAcceptedActivityRights,
        ...sessionActivityRights,
      ] as ActivityRightValue[];
      canUserAccess = acceptedActivityRights.some(
        (activity) => userActivityRights.indexOf(activity) !== -1
      );
    } else {
      userActivityRights = sessionActivityRights as ActivityRightValue[];
      canUserAccess = acceptedActivityRights.some(
        (activity) => userActivityRights.indexOf(activity) !== -1
      );
    }
  }

  if (file) {
    hasAccessToFile = hasAccessToFileInFast(state.auth, file);
    canUserAccess = canUserAccess && hasAccessToFile;
  }

  if (featureFlags && featureFlags.length > 0) {
    isEnabledFeature = featureFlags.some((flag) =>
      isFeatureEnabled(state, flag)
    );
    canUserAccess = canUserAccess && isEnabledFeature;
  }

  return canUserAccess;
}

export function formatActivityRightOptionsByName(options) {
  return (currentValue) => {
    if (currentValue) {
      const result = options.find((option) => option.value === currentValue);

      if (result) {
        return String(result.name).trim();
      }
    }

    return '';
  };
}

export function orderActivityRights(order) {
  return ((order && order._meta && order._meta.permissions) ||
    UserAcceptedActivityRights.CurrentlyNotSupported) as
    | ActivityRightValue[]
    | UserAcceptedActivityRights;
}
