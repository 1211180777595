import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { AsyncAction } from 'store';
import workspaceAPI, { workspaceAPIv2 } from 'services/workspaceAPI';
import {
  SearchTypeKeys,
  SearchType,
  ISearchURLParams,
  INavigateSearchParams,
} from 'store/search/types';
import { setFilters, filtersToURLParams } from 'store/searchFilters/actions';
import { setSorting, sortingToURLParams } from 'store/searchSorting/actions';

export function setSearchType(searchType: SearchType): AnyAction {
  return {
    type: SearchTypeKeys.SET_TYPE,
    searchType,
  };
}

export function setSearchQuery(query: string): AnyAction {
  return {
    type: SearchTypeKeys.SET_QUERY,
    query,
  };
}

export function setSearchPage(page: number | null): AnyAction {
  return {
    type: SearchTypeKeys.SET_PAGE,
    page,
  };
}

export function navigateToSearch(params: INavigateSearchParams) {
  return (dispatch, getState) => {
    const {
      search: searchState,
      filters: filtersState,
      sorting: sortingState,
    } = getState();
    const type = params.type || searchState.searchType;
    const query = params.query || searchState.query;
    let filters;
    let sorting;
    let page;

    if (!type || !query) {
      throw new Error('Search action requires type and query parameters.');
    }

    if (params.sorting) {
      filters = filtersToURLParams(params.filters || filtersState);
      sorting = sortingToURLParams(params.sorting);
      page = 1;
    } else if (params.page) {
      filters = filtersToURLParams(params.filters || filtersState);
      sorting = sortingToURLParams(params.sorting || sortingState);
      page = params.page;
    } else {
      filters = params.filters && filtersToURLParams(params.filters);
      page = 1;
    }

    const url = buildSearchUrl(type, query, page, filters, sorting);
    dispatch(push(url));
  };
}

export function searchFromURLParams(
  params: ISearchURLParams,
  searchActionCreator = search
) {
  return (dispatch) => {
    dispatch(setSearchType(params.type));
    dispatch(setSearchQuery(params.query));
    dispatch(setSearchPage(params.page));
    dispatch(setFilters(params.filters));
    dispatch(setSorting(params.sorting));
    dispatch(searchActionCreator());
  };
}

export function multisiteSearch() {
  return (dispatch, getState) => {
    const { search: searchState, filters, sorting } = getState();
    const { searchType, query, page } = searchState;
    const filtersParam = filtersToURLParams(filters);
    const sortingParam = sortingToURLParams(sorting);
    const queryParamName = 'query';
    const url = buildSearchUrl(
      searchType,
      query?.trim() || '',
      page,
      filtersParam,
      sortingParam,
      queryParamName
    );

    dispatch({ type: SearchTypeKeys.REQUEST });

    return workspaceAPIv2
      .get(url)
      .then((response) => {
        if (response.status >= 300) throw Error(response.statusText);
        return response;
      })
      .then(({ data }) => {
        dispatch({
          type: SearchTypeKeys.SUCCESS,
          aggregations: data.aggregations,
          countByFileType: data?._meta?.aggregations?.countByFileType || {},
          pagination: data?._meta?.pagination,
          results: data.list,
        });
      })
      .catch((error) => dispatch({ type: SearchTypeKeys.ERROR, error }));
  };
}

export function search(): AsyncAction<void | {
  type: SearchTypeKeys;
  error: any;
}> {
  return (dispatch, getState) => {
    const { search: searchState, filters, sorting } = getState();
    const { searchType, query, page } = searchState;
    const filtersParam = filtersToURLParams(filters);
    const sortingParam = sortingToURLParams(sorting);
    const queryParamName = 'query'; // Iris expects a "query" parameter, instead of the "q" we use
    const url = buildSearchUrl(
      searchType,
      query?.trim() || '',
      page,
      filtersParam,
      sortingParam,
      queryParamName
    );

    dispatch({ type: SearchTypeKeys.REQUEST });

    return workspaceAPI
      .get(url)
      .then((response) => {
        if (response.status >= 300) {
          throw Error(response.statusText);
        }

        return response;
      })
      .then(({ data }) => {
        // TODO: Should we make the results attribute consistent on Iris end?
        const results = data.list || data.files;
        dispatch({
          type: SearchTypeKeys.SUCCESS,
          results,
          pagination: data._meta && data._meta.pagination,
          aggregations: data.aggregations,
        });
      })
      .catch((error) => {
        dispatch({
          type: SearchTypeKeys.ERROR,
          error,
        });
      });
  };
}

export const clearSearch = () => {
  return (dispatch) => {
    dispatch({ type: SearchTypeKeys.CLEAR });
    dispatch(push(`/`));
  };
};

function buildSearchUrl(
  type: string,
  query: string,
  page?: number,
  filters?: string[],
  sorting?: string | null,
  queryParamName: string = 'q'
) {
  const params = new URLSearchParams();
  params.append(queryParamName, query);

  if (filters) {
    filters.forEach((filter) => params.append('filter', filter));
  }

  if (sorting) {
    params.append('sort', sorting);
  }

  if (page && page > 1) {
    params.append('page', page.toFixed(0));
  }

  return `/search/${type}?${params.toString()}`;
}
