import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { cadetBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5714 9H13.4286C12.7845 9 12.1668 9.25754 11.7113 9.71596C11.2559 10.1744 11 10.7961 11 11.4444V31L19.5 27.3333L28 31V11.4444C28 10.0878 26.9071 9 25.5714 9Z"
      fill={props.fill || cadetBlue}
    />
  </svg>
);
