export const MIN_DEPOSIT_AMOUNT = 1;
export const MIN_AMOUNT_COPY = `Value can be no less than $${MIN_DEPOSIT_AMOUNT}`;
export const MAX_DEPOSIT_AMOUNT = 100000000000;
export const MAX_AMOUNT_COPY = `Value must be less than $100,000,000,000.00`;
export const REQUIRED_SELECT_ERROR_COPY = 'Must select a value';
export const NO_MATCH_ERROR_COPY =
  'Value must match one of the dropdown options';
export const FORM_INVALID_COPY =
  'All required fields must be completed before proceeding';
export const MONEY_OVERAGE_COPY =
  'All funds must be allocated before proceeding';

export const CREDIT_TO_VALUES = [
  {
    description: 'Buyer',
    id: 113,
  },
  {
    description: 'Seller',
    id: 114,
  },
  {
    description: 'Other',
    id: 320,
  },
];

export const PAYOR_TYPES = [
  {
    description: 'Buyer',
    id: 113,
  },
  {
    description: "Buyer's Attorney",
    id: 322,
  },
  {
    description: "Buyer's Broker",
    id: 323,
  },
  {
    description: 'New Lender',
    id: 688,
  },
  {
    description: 'New Mortgage Broker',
    id: 1670,
  },
  {
    description: 'Other',
    id: 329,
  },
  {
    description: 'Outside Title Company',
    id: 317,
  },
  {
    description: 'Seller',
    id: 114,
  },
  {
    description: "Seller's Attorney",
    id: 325,
  },
  {
    description: "Seller's Broker",
    id: 326,
  },
] as const;

export const PAYOR_TYPES_REFINANCE = [
  {
    description: 'Borrower',
    id: 113,
  },
  {
    description: "Borrower's Attorney",
    id: 322,
  },
  {
    description: 'New Lender',
    id: 688,
  },
  {
    description: 'New Mortgage Broker',
    id: 1670,
  },
  {
    description: 'Other',
    id: 329,
  },
  {
    description: 'Outside Title Company',
    id: 317,
  },
] as const;
export const ALL_PAYOR_TYPES = [
  {
    description: 'Buyer',
    id: 113,
  },
  {
    description: "Buyer's Attorney",
    id: 322,
  },
  {
    description: "Buyer's Broker",
    id: 323,
  },
  {
    description: 'New Lender',
    id: 688,
  },
  {
    description: 'New Mortgage Broker',
    id: 1670,
  },
  {
    description: 'Other',
    id: 329,
  },
  {
    description: 'Outside Title Company',
    id: 317,
  },
  {
    description: 'Seller',
    id: 114,
  },
  {
    description: "Seller's Attorney",
    id: 325,
  },
  {
    description: "Seller's Broker",
    id: 326,
  },
  {
    description: 'Borrower',
    id: 113,
  },
  {
    description: "Borrower's Attorney",
    id: 322,
  },
] as const;

export enum FundType {
  ACH = 'ACH',
  CashiersCheck = "Cashier's Check",
  CompanyCheck = 'Company Check',
  InternalTransfer = 'Internal Transfer',
  MoneyOrder = 'Money Order',
  PersonalCheck = 'Personal Check',
  Wire = 'Wire',
}

export const FUND_TYPES = [
  { id: 4559, description: FundType.ACH },
  { id: 336, description: FundType.CashiersCheck },
  { id: 2226, description: FundType.CompanyCheck },
  { id: 343, description: FundType.InternalTransfer },
  { id: 337, description: FundType.MoneyOrder },
  { id: 335, description: FundType.PersonalCheck },
  { id: 339, description: FundType.Wire },
] as const;

export enum AdjustmentMode {
  Amount = 'Change Amount',
  Payor = 'Change Payor Name',
  Cancel = 'Cancel',
  Repost = 'Repost',
}

export enum EmdMilestoneTransactionTypes {
  ConstructionFinance = 'Construction Finance',
  ConstructionLoan = 'Construction Loan',
  LimitedEscrow = 'Limited Escrow',
  REOSalewMortgage = 'REO Sale w/Mortgage',
  REOSaleCash = 'REO Sale/Cash',
  SalewConstructionLoan = 'Sale w/Construction Loan',
  SalewMortgage = 'Sale w/Mortgage',
  SaleCash = 'Sale/Cash',
  SaleExchange = 'Sale/Exchange',
  ShortSalewMortgage = 'Short Sale w/Mortgage',
}

export const FIRST_AMERICAN_TRUST_BANK = 'First American Trust-Santa Ana';

export enum TrustBanksLocation {
  HI = '15',
  MD = '24',
  VA = '51',
}

export const TRUST_BANK_TYPE = [
  { value: '15', description: 'HI' },
  { value: '24', description: 'MD' },
  { value: '51', description: 'VA' },
] as const;
