import { AxiosError } from 'axios';
import { IPagination } from 'store/common/types';
import { SiteType } from 'components/SiteCard/types';

export enum DashboardTypeKeys {
  ERROR = 'dashboard/ERROR',
  SET_UNPAID_INVOICE = 'dashboard/SET_UNPAID_INVOICE',
  SET_INCOMPLETE_WIRE_INSTRUCTIONS = 'dashboard/SET_INCOMPLETE_WIRE_INSTRUCTIONS',
  SET_MY_ORDERS = 'dashboard/SET_MY_ORDERS',
  SET_MY_RECENT_ORDERS = 'dashboard/SET_MY_RECENT_ORDERS',
  SET_INCOMPLETE_1099S_CUSTOMER_E_SIGNIN = 'dashboard/SET_INCOMPLETE_1099S_CUSTOMER_E_SIGNIN',
}

export interface IDashboardState {
  incompleteWireInstructions: IIncompleteWireInstruction[];
  incomplete1099sCustomerESignIn: IIncomplete1099sCustomerESign[];
  invoiceId: number | null;
  recentOrders: IDashboardOrder[];
  orders: IDashboardOrder[];
  pagination: IPagination;
  error: string | null | AxiosError;
  countByFileType?: {
    project: string;
    single: string;
    site: string;
    total: string;
  };
}

export interface IIncompleteWireInstruction {
  fileId: number;
  fileNumber: string;
  wireInstructionId: number;
  status: IncompleteWireInstuctionStatus;
  requestCode: string;
}

export enum IncompleteWireInstuctionStatus {
  AwaitingUserVerification = 'AwaitingUserVerification',
  Requested = 'Requested',
}

export type DashboardAction =
  | IDashboardErrorAction
  | IDashboardSetUnpaidInvoice
  | IDashboardSetWireInstructionsAction
  | IDashboardSetMyOrdersAction
  | IDashboardSetMyRecentOrdersAction
  | IDashboardSet1099sCustomerESignAction;

export interface IDashboardSetWireInstructionsAction {
  type: DashboardTypeKeys.SET_INCOMPLETE_WIRE_INSTRUCTIONS;
  data: IIncompleteWireInstruction[];
}

export interface IDashboardSetUnpaidInvoice {
  type: DashboardTypeKeys.SET_UNPAID_INVOICE;
  data: number | null;
}

export interface IDashboardSetMyOrdersAction {
  type: DashboardTypeKeys.SET_MY_ORDERS;
  orders: IDashboardOrder[];
  pagination: IPagination;
  countByFileType: {
    project: string;
    single: string;
    site: string;
    total: string;
  };
}

export interface IDashboardSetMyRecentOrdersAction {
  type: DashboardTypeKeys.SET_MY_RECENT_ORDERS;
  recentOrders: IDashboardOrder[];
}

export interface IDashboardErrorAction {
  type: DashboardTypeKeys.ERROR;
  error: string | null | AxiosError;
}

export interface IDashboardOrderMeta {
  permissions: string[];
  projectFileData?: {
    cityCount?: number;
    closedSitesCount?: number;
    countyCount?: number;
    isCloseDateEstimated?: boolean;
    latestClosingDate?: string;
    orderTypes?: string[];
    stateCount?: number;
    statuses: {
      closed?: number;
      open?: number;
    };
    totalLiability?: number;
  };
}

export interface IDashboardOrder {
  closeDate: string;
  name: string;
  status: string;
  fileID: number;
  fileNumber: string;
  fileType: SiteType;
  addresses: IDashboardOrderAddress[];
  transactionType: string;
  businessSource: IDashboardOrderBusinessSource;
  businessSourceContact: IDashboardOrderBusinessSource;
  projectFileId?: number;
  projectFileNumber?: string;
  isCloseDateEstimated?: boolean;
  _meta?: IDashboardOrderMeta;
  isFileAccessExpired?: boolean;
}

export interface IDashboardOrderBusinessSource {
  organizationName: string | null;
  roleDescription: string | null;
}

export interface IDashboardOrderAddress {
  city: string | null;
  county: string | null;
  state: string | null;
  street: string | null;
  zip: string | null;
}

export enum DashboardLoadingKeys {
  dashboardIncompleteLoading = 'dashboardIncompleteLoading',
  dashboardMyOrdersLoading = 'dashboardMyOrdersLoading',
  dashboardRecentOrdersLoading = 'dashboardRecentOrdersLoading',
}
export interface IIncomplete1099sCustomerESign {
  orderId: number;
  fileNumber: string;
  requestCode: string;
}
export interface IDashboardSet1099sCustomerESignAction {
  type: DashboardTypeKeys.SET_INCOMPLETE_1099S_CUSTOMER_E_SIGNIN;
  data: IIncomplete1099sCustomerESign[];
}
