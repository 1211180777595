// tslint rules do not allow for certain things happening in this file (eg. use of `arguments`, regular functions)
// given the cause of renaming this file from .js to .ts was only for keeping the codebase consistent
// and in order to avoid potential issues from transpiled code, tslint will be disabled from this file.
/* tslint:disable */
(function (proto) {
  if (!proto.startsWith) {
    proto.startsWith = function (string) {
      return !!(string && this) && !this.lastIndexOf(string, 0);
    };
  }
})(String.prototype);
