import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { Container, Input, Item, Separator } from './styles';

const KEY_CODE = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  DELETE: 46,
};

interface IInputVerificationCodeProps {
  autoFocus?: boolean;
  length?: number;
  onChange?: (data: string) => any;
  onCompleted?: (data: string) => any;
  placeholder?: string;
  separatorSymbol?: string;
  separatorLocations?: number[];
  value?: string;
  id?: string;
  isValid?: boolean;
  onReset?: (data: string) => any;
}

const InputVerificationCode = (
  {
    autoFocus = false,
    length = 6,
    onChange = () => {
      return;
    },
    onCompleted = () => {
      return;
    },
    id = '',
    placeholder = ' ',
    separatorSymbol = '-',
    separatorLocations = [],
    value: pValue,
    isValid = true,
  }: IInputVerificationCodeProps,
  ref
) => {
  const emptyValue = new Array(length).fill(placeholder);

  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [value, setValue] = React.useState<string[]>(
    pValue ? pValue.split('') : emptyValue
  );

  const codeInputRef = React.createRef<HTMLInputElement>();
  const itemsRef = React.useMemo(
    () =>
      new Array(length).fill(null).map(() => React.createRef<HTMLDivElement>()),
    [length]
  );

  const isCodeRegex = new RegExp(`^[0-9]{${length}}$`);

  const getItem = (index: number) => itemsRef[index]?.current;
  const focusItem = (index: number): void => getItem(index)?.focus();
  const blurItem = (index: number): void => getItem(index)?.blur();

  const onItemFocus = (index: number) => () => {
    setActiveIndex(index);
    if (codeInputRef.current) codeInputRef.current.focus();
  };

  useImperativeHandle(
    ref,
    () => ({
      resetValue() {
        setValue([]);
      },
    }),
    []
  );

  const onInputKeyUp = ({ key, keyCode }: React.KeyboardEvent) => {
    const newValue = [...value];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;

    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);

    const isLast = nextIndex === length;
    const isDeleting =
      keyCode === KEY_CODE.DELETE || keyCode === KEY_CODE.BACKSPACE;

    // keep items focus in sync
    onItemFocus(activeIndex);

    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex] = placeholder;
      setValue(newValue);

      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
        focusItem(prevIndex);
      }

      return;
    }

    // if the key pressed is not a number
    // don't do anything
    if (Number.isNaN(+key)) return;

    // reset the current value
    // and set the new one
    if (codeInput) codeInput.value = '';
    newValue[activeIndex] = key;
    setValue(newValue);

    if (!isLast) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
      return;
    }

    if (codeInput) codeInput.blur();
    if (currentItem) currentItem.blur();

    setActiveIndex(-1);
  };

  // handle mobile autocompletion
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: changeValue } = e.target;
    const isCode = isCodeRegex.test(changeValue);

    if (!isCode) return;

    setValue(changeValue.split(''));
    blurItem(activeIndex);
  };

  const onInputBlur = () => {
    if (activeIndex === -1) return;

    blurItem(activeIndex);
    setActiveIndex(-1);
  };

  // autoFocus
  useEffect(() => {
    if (autoFocus && itemsRef[0].current) {
      itemsRef[0].current.focus();
    }
  }, []);

  // handle pasting
  useEffect(() => {
    const codeInput = codeInputRef.current;
    if (!codeInput) return;

    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault();

      const pastedString = e.clipboardData?.getData('text');
      if (!pastedString) return;

      const isNumber = !Number.isNaN(+pastedString);
      if (isNumber) {
        setValue(pastedString.split(''));
        setActiveIndex(pastedString.length);
      }
    };

    codeInput.addEventListener('paste', onPaste);
    return () => codeInput.removeEventListener('paste', onPaste);
  }, []);

  useEffect(() => {
    const stringValue = value.join('');
    const isCompleted = !stringValue.includes(placeholder);
    if (isCompleted) {
      onCompleted(stringValue);
    }
    onChange(stringValue);
  }, [value]);

  useEffect(() => {
    if (typeof pValue !== 'string') return;

    // avoid infinite loop
    if (pValue === '' && value.join('') === emptyValue.join('')) return;

    // keep internal and external states in sync
    if (pValue !== value.join('')) setValue(pValue.split(''));
  }, [pValue]);

  return (
    <React.Fragment>
      <Container
        className="InputVerificationCode__container"
        // needed for styling
        itemsCount={length}
      >
        <Input
          ref={codeInputRef}
          className="InputVerificationCode__input"
          autoComplete="off"
          type="text"
          inputMode="decimal"
          id={id}
          // use onKeyUp rather than onChange for a better control
          // onChange is still needed to handle the autocompletion
          // when receiving a code by SMS
          onChange={onInputChange}
          onKeyUp={onInputKeyUp}
          onBlur={onInputBlur}
          // needed for styling
          activeIndex={activeIndex}
          readOnly
        />
        {itemsRef.map((ref, i) => (
          <React.Fragment key={i}>
            <Item
              ref={ref}
              role="button"
              tabIndex={0}
              className={`InputVerificationCode__item
                ${i === activeIndex ? 'is-active' : ''}
                ${!isValid ? 'is-error' : ''}`}
              onFocus={onItemFocus(i)}
            >
              {value[i] || placeholder}
            </Item>
            {separatorLocations.includes(i) && (
              <Separator>{separatorSymbol}</Separator>
            )}
          </React.Fragment>
        ))}
      </Container>
    </React.Fragment>
  );
};

export default forwardRef(InputVerificationCode);
