/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Deposits_DepositRepresentingTypeViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositRepresentingTypeViewModel';
import type { Workspace_API_ViewModels_Overdrafts_OverdraftCauseViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_OverdraftCauseViewModel';
import type { Workspace_API_ViewModels_Overdrafts_OverdraftRecoverableTypeViewModel } from '../models/Workspace_API_ViewModels_Overdrafts_OverdraftRecoverableTypeViewModel';
import type { Workspace_Common_Models_BankABADetails } from '../models/Workspace_Common_Models_BankABADetails';
import type { Workspace_Common_Models_CityDto } from '../models/Workspace_Common_Models_CityDto';
import type { Workspace_Common_Models_CountyDto } from '../models/Workspace_Common_Models_CountyDto';
import type { Workspace_Common_Models_Dto_PropertyType_PropertyTypeDto } from '../models/Workspace_Common_Models_Dto_PropertyType_PropertyTypeDto';
import type { Workspace_Common_Models_EclipseStateDto } from '../models/Workspace_Common_Models_EclipseStateDto';
import type { Workspace_Common_Models_Forms_BusinessEntityTypesViewModel } from '../models/Workspace_Common_Models_Forms_BusinessEntityTypesViewModel';
import type { Workspace_Common_Models_Forms_EntityTypesViewModel } from '../models/Workspace_Common_Models_Forms_EntityTypesViewModel';
import type { Workspace_Common_Models_OrganizationData } from '../models/Workspace_Common_Models_OrganizationData';
import type { Workspace_Common_Models_ProductViewModel } from '../models/Workspace_Common_Models_ProductViewModel';
import type { Workspace_Common_Models_SwiftCodeDetails } from '../models/Workspace_Common_Models_SwiftCodeDetails';
import type { Workspace_Common_Models_TransactionType } from '../models/Workspace_Common_Models_TransactionType';
import type { Workspace_Common_Responses_BusinessPartyRoleResponse } from '../models/Workspace_Common_Responses_BusinessPartyRoleResponse';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyTaskStatus } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyTaskStatus';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskStatus } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskStatus';
import type { Workspace_Common_Responses_ServiceTypeResponse } from '../models/Workspace_Common_Responses_ServiceTypeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LookupsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Populates the dropdown of possible Wire Instruction Verification values (Auth)
   * @returns string Key/Value list of wire instruction verification options.
   * @throws ApiError
   */
  public getWireInstructionVerifications(): CancelablePromise<Record<string, string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/wire-instruction-verifications',
      errors: {
        403: `Forbidden`,
        404: `User is an External type user.`,
      },
    });
  }

  /**
   * Get the BankABADetails for a given routing number (Auth)
   * @returns Workspace_Common_Models_BankABADetails Bank ABA details fetched successfully
   * @throws ApiError
   */
  public getBankAbaDetailsAsync({
    routingNumber,
  }: {
    routingNumber: string,
  }): CancelablePromise<Workspace_Common_Models_BankABADetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/bank-aba-details/{routingNumber}',
      path: {
        'routingNumber': routingNumber,
      },
      errors: {
        404: `Bank ABA Details not found`,
      },
    });
  }

  /**
   * Get the list of possible product types (Auth)
   * @returns Workspace_Common_Models_ProductViewModel Product types fetched successfully
   * @throws ApiError
   */
  public getProducts(): CancelablePromise<Array<Workspace_Common_Models_ProductViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/products',
      errors: {
        403: `Forbidden`,
        404: `Product types not found`,
      },
    });
  }

  /**
   * Get the list of states from Eclipse (Auth)
   * @returns Workspace_Common_Models_EclipseStateDto States fetched successfully
   * @throws ApiError
   */
  public getStatesFromEclipse({
    country,
  }: {
    /**
     * The string country to query. Currently restricted to USA and Canada
     */
    country: string | null,
  }): CancelablePromise<Array<Workspace_Common_Models_EclipseStateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/countries/{country}/states',
      path: {
        'country': country,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Country not found`,
      },
    });
  }

  /**
   * Get the list of counties from Eclipse (Auth)
   * @returns string Counties fetched successfully
   * @throws ApiError
   */
  public getCountiesFromEclipse({
    country,
    stateAbbreviation,
  }: {
    /**
     * The string country to query. Currently restricted to USA and Canada
     */
    country: string | null,
    /**
     * The state id from Eclipse
     */
    stateAbbreviation: string | null,
  }): CancelablePromise<Record<string, string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/countries/{country}/states/{stateAbbreviation}/counties',
      path: {
        'country': country,
        'stateAbbreviation': stateAbbreviation,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Country not found`,
      },
    });
  }

  /**
   * Get the list of possible transaction types (Auth)
   * @returns Workspace_Common_Models_TransactionType Transaction types fetched successfully
   * @throws ApiError
   */
  public getTransactionTypes(): CancelablePromise<Array<Workspace_Common_Models_TransactionType>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/transaction-types',
      errors: {
        403: `Forbidden`,
        404: `Transaction types not found`,
      },
    });
  }

  /**
   * This endpoint returns the list of property types from the CF database
   * https://firstam.sharepoint.com/:u:/s/ClarityFirstRollout/EaI7dr_AMJxKg8IBGvLhiCcBwUIXKiMEN7JCGR8idXl8Pw?e=kL9CZu (Auth)
   * @returns Workspace_Common_Models_Dto_PropertyType_PropertyTypeDto Success
   * @throws ApiError
   */
  public getPropertyTypes(): CancelablePromise<Array<Workspace_Common_Models_Dto_PropertyType_PropertyTypeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/property-types',
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * Gets the list of deposit representing types from Eclipse (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositRepresentingTypeViewModel Success
   * @throws ApiError
   */
  public getDepositRepresentingTypes(): CancelablePromise<Array<Workspace_API_ViewModels_Deposits_DepositRepresentingTypeViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/deposit-representing-types',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the list of Overdraft Causes from Eclipse (Auth)
   * @returns Workspace_API_ViewModels_Overdrafts_OverdraftCauseViewModel Success
   * @throws ApiError
   */
  public getOverdraftCausesAsync(): CancelablePromise<Array<Workspace_API_ViewModels_Overdrafts_OverdraftCauseViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/overdraft-causes',
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * Gets the list of Overdraft Recoverable Types from Eclipse (Auth)
   * @returns Workspace_API_ViewModels_Overdrafts_OverdraftRecoverableTypeViewModel Success
   * @throws ApiError
   */
  public getOverdraftRecoverableTypesAsync(): CancelablePromise<Array<Workspace_API_ViewModels_Overdrafts_OverdraftRecoverableTypeViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/overdraft-recoverable-types',
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get the SwiftCodeDetails for a given swift code (Auth)
   * @returns Workspace_Common_Models_SwiftCodeDetails Swift Code Detail successfully
   * @throws ApiError
   */
  public getSwiftCodeDetailsAsync({
    swiftCode,
  }: {
    swiftCode: string,
  }): CancelablePromise<Workspace_Common_Models_SwiftCodeDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/swift-code-details/{swiftCode}',
      path: {
        'swiftCode': swiftCode,
      },
      errors: {
        401: `User is unauthorised to fetch swift code`,
        404: `Swift Code Not Found`,
      },
    });
  }

  /**
   * Gets all the entity types (Auth)
   * @returns Workspace_Common_Models_Forms_EntityTypesViewModel Entity types fetched successfully
   * @throws ApiError
   */
  public fetchEntityTypesAsync(): CancelablePromise<Workspace_Common_Models_Forms_EntityTypesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/entity-types',
      errors: {
        401: `User is unauthorized`,
        403: `Forbidden`,
        404: `Entity types not found`,
      },
    });
  }

  /**
   * Gets all the entity types (Auth)
   * @returns Workspace_Common_Models_Forms_BusinessEntityTypesViewModel Entity types fetched successfully
   * @throws ApiError
   */
  public fetchBusinessEntityTypesAsync(): CancelablePromise<Workspace_Common_Models_Forms_BusinessEntityTypesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/business-entity-types',
      errors: {
        401: `User is unauthorized`,
        403: `Forbidden`,
        404: `Entity types not found`,
      },
    });
  }

  /**
   * Return all the organizations for given search term: (Auth)
   * @returns Workspace_Common_Models_OrganizationData OrganizationData Fetched Successfully
   * @throws ApiError
   */
  public getAllOrganizationsAsync({
    searchString,
    regionId,
    fieldSearchBy,
    numberOfRecords = 10,
    includeNameSeparation = false,
  }: {
    /**
     * String to be Searched
     */
    searchString: string,
    /**
     * Region Id
     */
    regionId: number,
    /**
     * Field Search by Gab Code, Company, Contact or All
     */
    fieldSearchBy?: number,
    /**
     * Number of Records
     */
    numberOfRecords?: number,
    /**
     * Specifies whether Name and Name2 properties should be returned or not. Defaults to false.
     */
    includeNameSeparation?: boolean,
  }): CancelablePromise<Array<Workspace_Common_Models_OrganizationData>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/gab-search',
      query: {
        'searchString': searchString,
        'regionId': regionId,
        'fieldSearchBy': fieldSearchBy,
        'numberOfRecords': numberOfRecords,
        'includeNameSeparation': includeNameSeparation,
      },
      errors: {
        401: `User is unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get the list of counties in the given state (Auth)
   * @returns Workspace_Common_Models_CountyDto Success
   * @throws ApiError
   */
  public getCounties({
    stateId,
  }: {
    /**
     * The state where the counties belong
     */
    stateId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_CountyDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/states/{stateId}/counties',
      path: {
        'stateId': stateId,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get the list of cities that belong to the given county (Auth)
   * @returns Workspace_Common_Models_CityDto Success
   * @throws ApiError
   */
  public getCities({
    countyId,
  }: {
    /**
     * The county to filter
     */
    countyId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_CityDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/counties/{countyId}/cities',
      path: {
        'countyId': countyId,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * Fetch roles for specific feature
   * [Pull Request 73750: New column FastRoleId to BusinessPartyRole table] (Auth)
   * @returns Workspace_Common_Responses_BusinessPartyRoleResponse List of role names matches the roleids.
   * @throws ApiError
   */
  public getBusinessPartyRoles({
    featureName,
  }: {
    /**
     * Requesting feature name for which roles and fastRoleId are needed.
     */
    featureName?: string | null,
  }): CancelablePromise<Array<Workspace_Common_Responses_BusinessPartyRoleResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/business-party-roles',
      query: {
        'featureName': featureName,
      },
      errors: {
        400: `BadRequest result.`,
        404: `NotFound result.`,
      },
    });
  }

  /**
   * Fetch service types for specific feature
   * [Pull Request 75252: Create API to fetch service types] (Auth)
   * @returns Workspace_Common_Responses_ServiceTypeResponse List of service types with corresponding service type id.
   * @throws ApiError
   */
  public getServiceTypes({
    featureName,
  }: {
    /**
     * Requesting feature name for which service types are needed.
     */
    featureName: string,
  }): CancelablePromise<Array<Workspace_Common_Responses_ServiceTypeResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/service-types',
      query: {
        'featureName': featureName,
      },
      errors: {
        404: `NotFound result.`,
      },
    });
  }

  /**
   * Gets the List of Status for checklist task status list (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskStatus List of Checklist Task Statuses.
   * @throws ApiError
   */
  public getChecklistTaskStatusesAsync(): CancelablePromise<Array<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskStatus>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/checklist-management/checklist-task-statuses',
    });
  }

  /**
   * Gets the List of Status for checklist tasks - responsible party status list (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyTaskStatus List of checklist tasks - responsible party status list.
   * @throws ApiError
   */
  public getChecklistTaskResponsiblePartyStatusesAsync(): CancelablePromise<Array<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskResponsiblePartyTaskStatus>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/lookups/checklist-management/responsible-party-statuses',
    });
  }

}
