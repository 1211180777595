/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_IBA_IBABankProductRateViewModel } from '../models/Workspace_API_ViewModels_IBA_IBABankProductRateViewModel';
import type { Workspace_API_ViewModels_IBA_IBABankProductViewModel } from '../models/Workspace_API_ViewModels_IBA_IBABankProductViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BankService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all products for a particular IBA bank id (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBABankProductViewModel Success
   * @throws ApiError
   */
  public getIbaBankProducts({
    bankId,
  }: {
    /**
     * IBA bank identifier
     */
    bankId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_IBA_IBABankProductViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/banks/{bankId}/products',
      path: {
        'bankId': bankId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get all products and rates for a particular IBA bank id (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBABankProductRateViewModel Success
   * @throws ApiError
   */
  public getIbaBankProductsRatesAsync({
    bankId,
  }: {
    /**
     * IBA bank identifier
     */
    bankId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_IBA_IBABankProductRateViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/banks/{bankId}/product-rates',
      path: {
        'bankId': bankId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
