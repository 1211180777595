import { BroadcastChannel } from 'broadcast-channel';
import store from 'store';
import { push } from 'connected-react-router';
import { updateSession } from './actions';
import { ISession } from './types';

const SESSION_BROADCAST_NAME = 'ClarityFirst_Session_Broadcast';

enum AuthBroadcastTypeKeys {
  SESSION_MANUAL_UPDATE = 'authBroadcast/SESSION_MANUAL_UPDATE',
  SESSION_LOG_OUT = 'authBroadcast/SESSION_LOG_OUT',
}

interface IAuthBroadcastMessage {
  type: AuthBroadcastTypeKeys;
  payload: any;
}

const authChannel: BroadcastChannel<IAuthBroadcastMessage> = new BroadcastChannel(
  SESSION_BROADCAST_NAME
);

authChannel.onmessage = (message: IAuthBroadcastMessage) => {
  switch (message.type) {
    case AuthBroadcastTypeKeys.SESSION_MANUAL_UPDATE: {
      return store.dispatch(updateSession(message.payload.session));
    }

    case AuthBroadcastTypeKeys.SESSION_LOG_OUT: {
      return store.dispatch(push(message.payload.route));
    }
  }
};

export const broadcastSessionUpdate = (session: ISession) =>
  authChannel.postMessage({
    type: AuthBroadcastTypeKeys.SESSION_MANUAL_UPDATE,
    payload: {
      session,
    },
  });

export const broadcastLogOut = (route: string) =>
  authChannel.postMessage({
    type: AuthBroadcastTypeKeys.SESSION_LOG_OUT,
    payload: {
      route,
    },
  });
