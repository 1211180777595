import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white, lightBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.1719" cy="16.8281" r="16" fill={lightBlue} />
    <circle cx="16.1719" cy="16.8281" r="16" fill={white} fillOpacity="0.14" />
    <path
      d="M9.24215 9.59961C8.28068 9.59961 7.50977 10.3705 7.50977 11.332V19.9939C7.50977 20.4534 7.69228 20.894 8.01717 21.2189C8.34206 21.5438 8.7827 21.7263 9.24215 21.7263H16.1717V26.0573L18.7703 23.4587L21.3689 26.0573V21.7263H23.1013C23.5607 21.7263 24.0014 21.5438 24.3262 21.2189C24.6511 20.894 24.8336 20.4534 24.8336 19.9939V13.9306V12.1982V11.332C24.8336 10.8725 24.6511 10.4319 24.3262 10.107C24.0014 9.78213 23.5607 9.59961 23.1013 9.59961H19.6365H9.24215ZM16.1717 11.332L18.7703 13.0644L21.3689 11.332V14.3637L23.9675 15.663L21.3689 16.9623V19.9939L18.7703 18.2616L16.1717 19.9939V16.9623L13.5731 15.663L16.1717 14.3637V11.332ZM9.24215 11.332H13.5731V13.0644H9.24215V11.332ZM9.24215 14.7968H11.8407V16.5292H9.24215V14.7968ZM9.24215 18.2616H13.5731V19.9939H9.24215V18.2616Z"
      fill={props.fill || white}
    />
  </svg>
);
