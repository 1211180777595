import { AxiosError } from 'axios';
import { IPagination } from 'store/common/types';

export enum AppNotificationTypeKeys {
  ERROR = 'appNotifications/ERROR',
  MARK_AS_READ = 'appNotifications/MARK_AS_READ',
  SET_COUNTER = 'appNotifications/SET_COUNTER',
  SET_NOTIFICATIONS = 'appNotifications/SET_NOTIFICATIONS',
  SET_LATEST_NOTIFICATIONS = 'appNotifications/SET_LATEST_NOTIFICATIONS',
  SET_TASK_WITH_COMMENT_NOTIFICATION = 'appNotifications/SET_TASK_WITH_COMMENT_NOTIFICATION',
}

export interface IAppNotificationsState {
  counter: number;
  error: null | AxiosError;
  notifications: IAppNotification[];
  pagination: IPagination;
  latest: {
    notifications: IAppNotification[];
    pagination: IPagination;
  };
  taskWithCommentId: number | null;
}

export interface IAppNotification {
  fileDisplayName: string;
  fileId: number;
  fileNumber: string;
  id: number;
  message: string;
  meta: INotificationMeta;
  relatedId: number;
  relatedIds: number[];
  subject: string | null;
  timestamp: string;
  type: AppNotificationType;
  visited: boolean;
}

export interface INotificationMeta {
  requestCode?: string;
  statementId?: string;
  statementClosingGroupId?: string;
  statementFileBusinessPartyId?: string;
  statementType?: string;
}

export enum AppNotificationType {
  ADD_WIRE_INSTRUCTION = 'AddWireInstruction',
  ADDED_TO_ORDER = 'AddedToOrder',
  ADDED_WIRE_INSTRUCTION = 'AddedWireInstruction',
  CUSTOMER_DOCUMENT_UPLOADS = 'CustomerDocumentUploads',
  INVOICE_APPROVED = 'InvoiceApproved',
  INVOICE_CREATED = 'InvoiceCreated',
  INVOICE_REJECTED = 'InvoiceRejected',
  MILESTONE_DIGEST = 'MilestoneDigest',
  MILESTONE_UPDATE = 'MilestoneUpdates',
  ORDER10999S_UPDATES = 'Order1099SUpdates',
  PSFINVOICE_UPDATES = 'PSFInvoiceUpdates',
  SETTLEMENT_STATEMENT_COMMENTS = 'SettlementStatementComments',
  SHARED_DOCUMENTS = 'SharedDocuments',
  VERIFIED_WIRE_INSTRUCTION = 'VerifiedWireInstruction',
  VERIFY_WIRE_INSTRUCTION = 'VerifyWireInstruction',
  ORDER_CHECKLIST = 'OrderChecklist',
}

export enum AppNotificationsLoadingKeys {
  appNotificationsPageLoading = 'appNotificationsPageLoading',
  appNotificationsDropdownLoading = 'appNotificationsDropdownLoading',
}

export type AppNotificationAction =
  | IAppNotificationErrorAction
  | IAppNotificationMarkAsReadAction
  | IAppNotificationSetLatestNotificationsAction
  | IAppNotificationSetNotificationsAction
  | IAppNotificationsetNotificationsCounterAction
  | IAppNotificationSetTaskWithCommentAction;

export interface IAppNotificationErrorAction {
  type: AppNotificationTypeKeys.ERROR;
  error: AxiosError | null;
}

export interface IAppNotificationSetNotificationsAction {
  type: AppNotificationTypeKeys.SET_NOTIFICATIONS;
  notifications: IAppNotification[];
  pagination: IPagination;
}

export interface IAppNotificationSetLatestNotificationsAction {
  type: AppNotificationTypeKeys.SET_LATEST_NOTIFICATIONS;
  notifications: IAppNotification[];
  pagination: IPagination;
}

export interface IAppNotificationMarkAsReadAction {
  type: AppNotificationTypeKeys.MARK_AS_READ;
  notificationId: number;
}

export interface IAppNotificationsetNotificationsCounterAction {
  type: AppNotificationTypeKeys.SET_COUNTER;
  counter: number;
}

export interface IAppNotificationSetTaskWithCommentAction {
  type: AppNotificationTypeKeys.SET_TASK_WITH_COMMENT_NOTIFICATION;
  taskWithCommentId: number;
}
