type UnloadEventHandler = () => string;

interface IUnloadEventQueueEntry {
  id: string;
  handler: UnloadEventHandler;
}

class WindowUnloadManager {
  private unloadEventQueue: IUnloadEventQueueEntry[] = [];

  // The following check is a fix for Docz: gatsby complains
  // for `window` not being present when building and fails.
  private defaultHandler: UnloadEventHandler = (typeof window !== 'undefined'
    ? window.onbeforeunload
    : {}) as UnloadEventHandler;

  public registerHandler = (id: string, handler: UnloadEventHandler) => {
    this.spliceHandler(id);
    this.unloadEventQueue.push({ id, handler });
    window.onbeforeunload = handler;
  };

  public unregisterHandler = (id: string) => {
    const existingIndex = this.spliceHandler(id);

    if (existingIndex >= 0 && existingIndex === this.unloadEventQueue.length) {
      window.onbeforeunload =
        this.unloadEventQueue[this.unloadEventQueue.length - 1]?.handler ||
        this.defaultHandler;
    }
  };

  private spliceHandler = (id: string) => {
    const existingIndex = this.unloadEventQueue.findIndex((h) => h.id === id);

    if (existingIndex >= 0) {
      this.unloadEventQueue.splice(existingIndex, 1);
    }

    return existingIndex;
  };
}

export type IWindowUnloadManager = WindowUnloadManager;
export const windowUnloadManager = new WindowUnloadManager();
