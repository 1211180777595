import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="190"
    height="140"
    viewBox="0 0 190 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <rect y="108" width="6" height="2" rx="1" fill="#9BABC2" />
    <rect x="184" y="108" width="6" height="2" rx="1" fill="#9BABC2" />
    <path
      d="M8 109C8 108.448 8.44772 108 9 108H181C181.552 108 182 108.448 182 109C182 109.552 181.552 110 181 110H9C8.44772 110 8 109.552 8 109Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 134.002C160 137.315 132.018 140 97.5013 140C62.9822 140 35 137.315 35 134.002C35 130.688 62.9822 128 97.5013 128C132.018 128 160 130.688 160 134.002Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.217 126.749C166.217 128.523 164.787 129.964 163.022 129.964H81.8291C80.0637 129.964 78.6343 128.523 78.6343 126.749V4.21771C78.6343 2.44111 80.0637 1 81.8291 1H148.919C149.765 1 150.579 1.34058 151.177 1.94549L165.28 16.1355C165.881 16.7404 166.217 17.5588 166.217 18.4102V126.749Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.6338 13.8617C78.6338 12.0876 80.0632 10.6465 81.8286 10.6465H159.827L151.179 1.94392C150.578 1.34156 149.768 1.00098 148.919 1.00098H81.8286C80.0632 1.00098 78.6338 2.44209 78.6338 4.21615V13.8617Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166 126.784C166 128.558 164.58 130 162.826 130H82.1735C80.4199 130 79 128.558 79 126.784V4.21869C79 2.44155 80.4199 1 82.1735 1H148.818C149.658 1 150.466 1.34068 151.061 1.94578L165.069 16.14C165.666 16.7451 166 17.5638 166 18.4155V126.784Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 80.5C160 91.2674 151.27 100 140.503 100C129.73 100 121 91.2674 121 80.5C121 69.7298 129.73 61 140.503 61C151.27 61 160 69.7298 160 80.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.502 69.125C149.876 69.125 157.707 75.7437 159.575 84.5639C159.855 83.2526 159.999 81.8933 159.999 80.5C159.999 69.7298 151.272 61 140.502 61C129.729 61 121.002 69.7298 121.002 80.5C121.002 81.8933 121.149 83.2526 121.426 84.5639C123.294 75.7437 131.125 69.125 140.502 69.125Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 80.5C160 91.2674 151.27 100 140.503 100C129.73 100 121 91.2674 121 80.5C121 69.7298 129.73 61 140.503 61C151.27 61 160 69.7298 160 80.5Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.376 86.0476C131.888 85.5597 131.888 84.7702 132.376 84.2823L135.913 80.7517C136.157 80.508 136.157 80.1123 135.913 79.868L132.376 76.3374C131.888 75.8501 131.888 75.06 132.376 74.5721L134.587 72.3659C135.075 71.878 135.866 71.878 136.355 72.3659L139.892 75.8966C140.136 76.1402 140.532 76.1402 140.776 75.8966L144.313 72.3659C144.801 71.878 145.593 71.878 146.081 72.3659L148.292 74.5721C148.781 75.06 148.781 75.8501 148.292 76.3374L144.755 79.868C144.511 80.1123 144.511 80.508 144.755 80.7517L148.292 84.2823C148.781 84.7702 148.781 85.5597 148.292 86.0476L146.081 88.2537C145.593 88.7416 144.801 88.7416 144.313 88.2537L140.776 84.7231C140.532 84.4795 140.136 84.4795 139.892 84.7231L136.355 88.2537C135.866 88.7416 135.075 88.7416 134.587 88.2537L132.376 86.0476Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.355 72.3659L139.892 75.8966C140.137 76.1402 140.532 76.1402 140.776 75.8966L144.313 72.3659C144.802 71.878 145.593 71.878 146.081 72.3659L148.292 74.5721C148.781 75.06 148.781 75.8501 148.292 76.3374L144.755 79.868C144.511 80.1123 144.511 80.508 144.755 80.7517L148.292 84.2823C148.781 84.7695 148.781 85.5597 148.292 86.0476L146.913 87.4239L133.208 73.7423L134.587 72.3659C135.075 71.878 135.867 71.878 136.355 72.3659Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.376 86.0476C131.888 85.5597 131.888 84.7702 132.376 84.2823L135.913 80.7517C136.157 80.508 136.157 80.1123 135.913 79.868L132.376 76.3374C131.888 75.8501 131.888 75.06 132.376 74.5721L134.587 72.3659C135.075 71.878 135.866 71.878 136.355 72.3659L139.892 75.8966C140.136 76.1402 140.532 76.1402 140.776 75.8966L144.313 72.3659C144.801 71.878 145.593 71.878 146.081 72.3659L148.292 74.5721C148.781 75.06 148.781 75.8501 148.292 76.3374L144.755 79.868C144.511 80.1123 144.511 80.508 144.755 80.7517L148.292 84.2823C148.781 84.7702 148.781 85.5597 148.292 86.0476L146.081 88.2537C145.593 88.7417 144.801 88.7417 144.313 88.2537L140.776 84.7231C140.532 84.4795 140.136 84.4795 139.892 84.7231L136.355 88.2537C135.866 88.7417 135.075 88.7417 134.587 88.2537L132.376 86.0476Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101 37C101 36.4477 101.448 36 102 36H140C140.552 36 141 36.4477 141 37C141 37.5523 140.552 38 140 38H102C101.448 38 101 37.5523 101 37Z"
      fill="#9BABC2"
    />
    <path
      d="M101 25C101 24.4477 101.448 24 102 24H128C128.552 24 129 24.4477 129 25C129 25.5523 128.552 26 128 26H102C101.448 26 101 25.5523 101 25Z"
      fill="#9BABC2"
    />
    <path
      d="M101 49C101 48.4477 101.448 48 102 48H121C121.552 48 122 48.4477 122 49C122 49.5523 121.552 50 121 50H102C101.448 50 101 49.5523 101 49Z"
      fill="#9BABC2"
    />
    <path
      d="M101 61C101 60.4477 101.448 60 102 60H115C115.552 60 116 60.4477 116 61C116 61.5523 115.552 62 115 62H102C101.448 62 101 61.5523 101 61Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.242 14.1548C150.242 15.7687 151.543 17.0777 153.147 17.0777H165.905C165.749 16.7346 165.552 16.4092 165.282 16.1347L151.179 1.94478C150.909 1.67282 150.583 1.47203 150.242 1.31445V14.1548Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 14.2186C150 15.7544 151.246 17 152.781 17H165C164.85 16.6735 164.661 16.3639 164.403 16.1027L150.897 2.59981C150.639 2.34102 150.327 2.14995 150 2V14.2186Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.964 120.318H24.9772C23.5831 120.318 22.4517 119.179 22.4517 117.776V107.978C22.4517 102.364 26.9749 97.8115 32.5537 97.8115H111.388C116.967 97.8115 121.49 102.364 121.49 107.978V117.776C121.49 119.179 120.358 120.318 118.964 120.318Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.49 109.503C121.49 103.047 116.287 97.8115 109.872 97.8115H34.069C27.6542 97.8115 22.4517 103.047 22.4517 109.503V117.392C22.4517 117.601 22.5274 117.784 22.5704 117.977C23.1588 112.08 28.0507 107.457 34.069 107.457H109.872C115.891 107.457 120.783 112.08 121.374 117.977C121.414 117.784 121.49 117.601 121.49 117.392V109.503Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.9297 56.0135H57.0124C56.2926 56.0135 55.6057 55.8635 54.9692 55.6221C56.5325 58.9033 60.7906 70.3559 60.7906 97.8107H83.1515C83.1515 70.3559 87.4095 58.9033 88.9753 55.6221C88.3364 55.8635 87.652 56.0135 86.9297 56.0135Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.0129 56.0135C56.2906 56.0135 55.6062 55.8635 54.9697 55.6221C55.394 56.5142 56.0178 58.0341 56.6997 60.286H87.2408C87.9252 58.0341 88.549 56.5142 88.9733 55.6221C88.3343 55.8635 87.6499 56.0135 86.9302 56.0135H57.0129Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.165 127.965C113.771 129.16 112.66 129.965 111.41 129.965H32.5331C31.2829 129.965 30.1717 129.16 29.7777 127.965L27.2446 120.317H116.698L114.165 127.965Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.475 120H24.5245C23.131 120 22 118.887 22 117.516V107.938C22 102.45 26.5215 98 32.0982 98H110.902C116.479 98 121 102.45 121 107.938V117.516C121 118.887 119.869 120 118.475 120Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.9547 56.3897H57.0428C56.3231 56.3897 55.6363 56.2404 55 56C56.563 59.2666 60.8203 70.668 60.8203 98H83.1772C83.1772 70.668 87.4345 59.2666 89 56C88.3612 56.2404 87.6769 56.3897 86.9547 56.3897Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.451 127.927C114.055 129.165 112.937 130 111.679 130H32.3207C31.063 130 29.9449 129.165 29.5486 127.927L27 120H117L114.451 127.927Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M61 87H83V89H61V87Z" fill="#9BABC2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.6867 56.0134H56.2566C53.4659 56.0134 51.2056 53.7361 51.2056 50.9301V35.3753C51.2056 32.5693 53.4659 30.292 56.2566 30.292H87.6867C90.4748 30.292 92.7377 32.5693 92.7377 35.3753V50.9301C92.7377 53.7361 90.4748 56.0134 87.6867 56.0134Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.9291 30.293H57.0118C53.8044 30.293 51.2031 32.9109 51.2031 36.1387V44.1754C51.2031 40.9475 53.8044 38.3322 57.0118 38.3322H86.9291C90.1365 38.3322 92.7378 40.9475 92.7378 44.1754V36.1387C92.7378 32.9109 90.1365 30.293 86.9291 30.293Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.8921 57H56.1079C53.2858 57 51 54.6095 51 51.664V35.336C51 32.3905 53.2858 30 56.1079 30H87.8921C90.7116 30 93 32.3905 93 35.336V51.664C93 54.6095 90.7116 57 87.8921 57Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
