import { DisplaySize, Section } from '.';
import { Row, FlexBox } from 'components/ui/Box';
import { gray8, red8 } from 'config/colors';
import styled from 'styled-components';

export const FooterSection = styled(Section).attrs({
  as: 'footer',
  gap: 'xlarge',
})`
  border-top: 3px solid ${red8};
  flex-shrink: auto;
  padding-bottom: 40px;
  padding-top: 28px;
`;

export const FooterLogoLink = styled.div`
  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const FooterHeader = styled(Row).attrs({
  justify: 'between',
})`
  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    flex-direction: column;
    gap: 40px;

    a {
      font-size: 12px;
      line-height: 16px;
    }

    ${FlexBox} {
      justify-content: space-between;
    }
  }
`;

export const LegalText = styled.small`
  color: ${gray8};
  font-size: 12px;
  line-height: 18px;

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    text-align: center;
  }
`;
