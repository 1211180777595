import { AxiosResponse, AxiosError } from 'axios';
import { SubmissionError } from 'redux-form';
import workspaceAPI from 'services/workspaceAPI';
import { collectionToMaphash } from 'store/utils';
import { organizationContactToOrderContact } from './helpers';
import { setLoadingStateAction } from 'store/ui/actions';
import {
  IFilePartyContactForm,
  IOrderBusinessPartiesHash,
  IOrderBusinessParty,
  IOrderPartyContact,
  IOrderPartyContactsHash,
  IOrderSetOrderOrganizationContacts,
  IOrderSetOrderOrganizations,
  OrderLoadingKeys,
  OrderTypeKeys,
} from '../types';

function setOrderOrganizations(
  organizations: IOrderBusinessPartiesHash
): IOrderSetOrderOrganizations {
  return {
    type: OrderTypeKeys.SET_ORDER_ORGANIZATIONS,
    organizations,
  };
}

function getOrderOrganizations(orderId: string) {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderOrganizationsLoading, true)
    );

    return await workspaceAPI
      .get(`/orders/${orderId}/organizations`)
      .then(({ data }: AxiosResponse) => {
        dispatch(setOrderOrganizations(collectionToMaphash(data, 'id')));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderOrganizationsLoading,
            false
          )
        );
      });
  };
}

function setOrderOrganizationContacts(
  contacts: IOrderPartyContactsHash
): IOrderSetOrderOrganizationContacts {
  return {
    type: OrderTypeKeys.SET_ORDER_ORGANIZATION_CONTACTS,
    contacts,
  };
}

function getOrderOrganizationContacts(
  orderId: string,
  organization: IOrderBusinessParty
) {
  return async (dispatch) => {
    if (!organization?.transactionParty) return;

    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderOrganizationContactsLoading,
        true
      )
    );

    return await workspaceAPI
      .get(
        `/files/${orderId}/${organization.transactionParty.type}s/${organization.transactionParty.id}/contacts`
      )
      .then(({ data }: AxiosResponse) => {
        const businessParties: IOrderPartyContact[] = data.map(
          organizationContactToOrderContact
        );
        dispatch(
          setOrderOrganizationContacts(
            collectionToMaphash(businessParties, 'id')
          )
        );
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderOrganizationContactsLoading,
            false
          )
        );
      });
  };
}

function addOrderContact(
  orderId: string,
  partyContact: IFilePartyContactForm,
  successCallback: (contact?: any) => void
) {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderAddContactLoading, true)
    );

    return await workspaceAPI
      .post(`/orders/${orderId}/parties/contacts`, partyContact)
      .then(async ({ data }) => await successCallback(data))
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 409) {
          throw new SubmissionError({
            organizationContactIdProxy:
              'This contact is already on this order in the selected role.',
          });
        }
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderAddContactLoading, false)
        );
      });
  };
}

export { addOrderContact, getOrderOrganizationContacts, getOrderOrganizations };
