import React from 'react';
import styled from 'styled-components';
import { blueLagoon, white, lightBlue } from 'config/theme';
import {
  BuildingsSiteIcon,
  BuildingsIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { SiteType } from '../types';
import Icon from './Icon';
import MultisiteSite from './MultisiteSite';

interface ISiteTypeTag {
  siteType?: SiteType;
}

const SiteTypeTag = ({ siteType }: ISiteTypeTag) => {
  const ContainerComponent = {
    [SiteType.MultisiteProject]: MultiSiteContainer,
    [SiteType.MultisiteSite]: MultisiteSiteContainer,
    [SiteType.SingleSite]: SingleSiteContainer,
  }[siteType || SiteType.SingleSite];

  return (
    <ContainerComponent>
      {(() => {
        if (siteType === SiteType.MultisiteSite) {
          return <MultisiteSite />;
        }

        if (siteType === SiteType.MultisiteProject) {
          return (
            <div className="MultisiteProject__label">
              <Icon svgComponent={BuildingsSiteIcon} />
              Multisite
            </div>
          );
        }

        return (
          <div className="SingleSite__label">
            <Icon svgComponent={BuildingsIcon} width={8} height={11} />
            Single Site
          </div>
        );
      })()}
    </ContainerComponent>
  );
};
SiteTypeTag.displayName = 'SiteTypeTag';

export default SiteTypeTag;

const Container = styled.div`
  background: ${white};
  border-radius: 3px;
  height: 23px;

  & > div {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    line-height: 16px;
    text-transform: uppercase;
  }
`;

const MultiSiteContainer = styled(Container)`
  background: ${blueLagoon};
  color: ${white};
  width: 95px;
`;

const MultisiteSiteContainer = styled(Container)`
  position: relative;
  color: ${white};
  width: 95px;
`;

const SingleSiteContainer = styled(Container)`
  border: 1px solid ${lightBlue};
  color: ${lightBlue};
  width: 102px;
`;
