import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="39"
    height="45"
    viewBox="0 0 39 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M2.78569 0H26.5909L39 12.6V42.1875C39 43.7414 37.7521 45 36.2143 45H2.78569C1.24793 45 0 43.7414 0 42.1875V2.81247C0 1.25862 1.24808 0 2.78569 0Z"
      fill="#148079"
    />
    <path
      opacity="0.8"
      d="M39 12.6H29.3574C27.8302 12.6 26.5909 11.3429 26.5909 9.79379V5.36516e-10L39 12.6Z"
      fill="#055C57"
    />
    <path
      d="M11.2378 28.3952C14.0978 28.3952 15.3198 26.4452 15.6188 25.1712L13.6558 24.6122C13.4738 25.2752 12.8368 26.3412 11.2378 26.3412C9.85984 26.3412 8.58584 25.3402 8.58584 23.6112C8.58584 21.6742 9.97684 20.7902 11.2118 20.7902C12.8368 20.7902 13.4218 21.8692 13.5648 22.5062L15.5018 21.8952C15.2028 20.5692 13.9808 18.7882 11.2118 18.7882C8.63784 18.7882 6.45384 20.7382 6.45384 23.6112C6.45384 26.4842 8.58584 28.3952 11.2378 28.3952ZM23.3728 21.2452C23.1778 20.1662 22.3068 18.7882 20.0058 18.7882C18.2378 18.7882 16.7038 20.0102 16.7038 21.7522C16.7038 23.1172 17.6528 24.1572 19.2128 24.4562L20.4738 24.6902C21.0848 24.8072 21.4618 25.1452 21.4618 25.6132C21.4618 26.1852 20.9678 26.5752 20.1618 26.5752C18.9008 26.5752 18.3288 25.8342 18.2508 24.9892L16.3658 25.4442C16.4958 26.7442 17.5878 28.3952 20.1358 28.3952C22.3328 28.3952 23.5418 26.9262 23.5418 25.4832C23.5418 24.1702 22.6318 23.0392 20.8768 22.7142L19.6418 22.4802C19.0048 22.3632 18.7318 22.0382 18.7318 21.5962C18.7318 21.0892 19.2258 20.6082 19.9928 20.6082C21.1108 20.6082 21.4618 21.3362 21.5268 21.7652L23.3728 21.2452ZM28.5012 25.4702L26.2782 18.9832H24.0162L27.3962 28.2002H29.5152L32.8822 18.9832H30.6982L28.5012 25.4702Z"
      fill="white"
    />
  </svg>
);
