import {
  IPropertySearchState,
  PropertySearchTypeKeys,
  PropertySearchActions,
} from './types';
import { Tabs } from 'store/propertySearch/constants';

const parentDetails = {
  zoom: null,
  lat: null,
  lng: null,
  bounds: null,
  address: '',
};

export const initialState: IPropertySearchState = {
  loading: false,
  error: null,
  propertyDetails: null,
  propertyDocuments: [],
  selectedPropertyDetail: null,
  hoveredPropertyDetail: null,
  parentDetails,
  propertyDocumentData: null,
  activeIndex: Tabs.PropertySearch,
  checkedPropertyIds: [],
  actualResultsCount: 0,
  statesCounties: [],
  propertySearchRequest: null,
  landUseTypes: [],
  filters: null,
  isUserAcceptedPaymentPolicy: false,
  advancedSearchFields: null,
  cmtDocumentProfilesByState: [],
};

export default (
  state = initialState,
  action: PropertySearchActions
): IPropertySearchState => {
  switch (action.type) {
    case PropertySearchTypeKeys.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PropertySearchTypeKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PropertySearchTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PropertySearchTypeKeys.SET_PROPERTY_DETAILS:
      return {
        ...state,
        propertyDetails: action.details,
      };
    case PropertySearchTypeKeys.SET_SELECTED_PROPERTY_DETAIL:
      return {
        ...state,
        selectedPropertyDetail: action.selectedPropertyDetail,
      };
    case PropertySearchTypeKeys.SET_HOVERED_PROPERTY_DETAIL:
      return {
        ...state,
        hoveredPropertyDetail: action.hoveredPropertyDetail,
      };
    case PropertySearchTypeKeys.SET_PARENT_DETAILS:
      return {
        ...state,
        parentDetails: action.parentDetails,
      };
    case PropertySearchTypeKeys.GET_PROPERTY_DOCUMENTS:
    case PropertySearchTypeKeys.SET_PROPERTY_DOCUMENTS:
      return {
        ...state,
        propertyDocuments: action.documents || [],
      };
    case PropertySearchTypeKeys.GET_PROPERTY_DOCUMENTS_DATA:
      return {
        ...state,
        propertyDocumentData: action.propertyDocumentData,
      };
    case PropertySearchTypeKeys.UPDATE_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.activeIndex || Tabs.PropertySearch,
      };
    case PropertySearchTypeKeys.SET_CHECKED_PROPERTY_IDS:
      return {
        ...state,
        checkedPropertyIds: action.checkedPropertyIds,
      };
    case PropertySearchTypeKeys.SET_ACTUAL_RESULTS_COUNT:
      return {
        ...state,
        actualResultsCount: action.actualResultsCount,
      };
    case PropertySearchTypeKeys.GET_STATES_COUNTIES:
      return {
        ...state,
        statesCounties: action.statesCounties,
      };
    case PropertySearchTypeKeys.SET_PROPERTY_SEARCH_REQUEST:
      return {
        ...state,
        propertySearchRequest: action.request,
      };
    case PropertySearchTypeKeys.GET_LAND_USE_TYPES:
      return {
        ...state,
        landUseTypes: action.landUseTypes,
      };
    case PropertySearchTypeKeys.SET_PROPERTY_SEARCH_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case PropertySearchTypeKeys.SET_USER_ACCEPTED_PAYMENT_POLICY:
      return {
        ...state,
        isUserAcceptedPaymentPolicy: action.isUserAcceptedPaymentPolicy,
      };
    case PropertySearchTypeKeys.SET_ADVANCED_SEARCH_FIELDS:
      return {
        ...state,
        advancedSearchFields: action.advancedSearchFields,
      };
    case PropertySearchTypeKeys.SET_CMT_DOCUMENT_PROFILES_BY_STATE:
      return {
        ...state,
        cmtDocumentProfilesByState: action.cmtDocumentProfilesByState,
      };
    default:
      return state;
  }
};
