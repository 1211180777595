import React from 'react';
import styled from 'styled-components';
import { white } from 'config/theme';
import LeftContent from './LeftContent';
import RightContent, { IOrderContact } from './RightContent';

export interface ISiteCardBodyProps {
  /**
   * A string formatted date, an estimation of when the site transaction will close
   */
  estimatedCloseDate: string;
  /**
   * The liability remaining for the lender
   */
  lenderLiability: number;
  /**
   * A string formatted date, communicates when the site process was
   * started/opened
   */
  openDate: string | null;
  /**
   * Communicates the liabililty remaining for the owner
   */
  ownerLiability: number;
  /**
   * An array of our Order Party Contacts
   */
  orderPartiesContacts: IOrderContact[];
  /**
   * An array of our Service Team Contacts
   */
  serviceTeamContacts: IOrderContact[];
}

const SiteCardBody = ({
  estimatedCloseDate,
  lenderLiability,
  openDate,
  orderPartiesContacts,
  ownerLiability,
  serviceTeamContacts,
}: ISiteCardBodyProps) => {
  return (
    <Container>
      <LeftContent
        estimatedCloseDate={estimatedCloseDate}
        lenderLiability={lenderLiability}
        openDate={openDate}
        ownerLiability={ownerLiability}
      />
      <RightContent
        orderPartiesContacts={orderPartiesContacts}
        serviceTeamContacts={serviceTeamContacts}
      />
    </Container>
  );
};
SiteCardBody.displayName = 'SiteCardBody';

export default SiteCardBody;

const Container = styled.div`
  background: ${white};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 20px 23px;
  width: 100%;
`;
