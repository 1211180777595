import { SS_COMMENT_SECTION_URL_PARAM } from 'Orders/Settlement/Multisite/Comment/constants';

export const getSettlementStatementLink = ({
  statementClosingGroupId,
  relatedId,
  fileId,
  statementId,
  statementType,
  isInternal,
  notificationPropId,
}) => {
  const base = `/order/${fileId}/settlement-statements/`;
  const statementUrl = isInternal ? '' : statementId;
  const commentSectionId = relatedId
    ? `${SS_COMMENT_SECTION_URL_PARAM}=${relatedId}`
    : '';
  const type = isInternal ? statementType?.toLowerCase() : '';
  const closingGroup = statementClosingGroupId
    ? `&closingGroupId=${statementClosingGroupId}`
    : '';
  const notificationId = notificationPropId
    ? `notificationId=${notificationPropId}`
    : '';
  return isInternal
    ? `${base}${statementUrl}?type=${type}${closingGroup}&${commentSectionId}&${notificationId}`
    : `${base}${statementUrl}?${commentSectionId}&${notificationId}`;
};
