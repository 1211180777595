import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M8.21602 0.574219C12.322 0.574219 15.641 3.89319 15.641 7.99922C15.641 12.1052 12.322 15.4242 8.21602 15.4242C4.10999 15.4242 0.791016 12.1052 0.791016 7.99922C0.791016 3.89319 4.10999 0.574219 8.21602 0.574219ZM10.8816 4.28672L8.21602 6.95229L5.55044 4.28672L4.50352 5.33364L7.16909 7.99922L4.50352 10.6648L5.55044 11.7117L8.21602 9.04614L10.8816 11.7117L11.9285 10.6648L9.26294 7.99922L11.9285 5.33364L10.8816 4.28672Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
