import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { History } from 'history';
import {
  markUserAsActive,
  markUserAsActiveLimiter,
} from 'store/auth/middleware';
import { IApplicationState } from './types';
import { reducers } from './reducers';
import { IFeatureComponent, IFeatureToggle } from './features/types';
import { initialState as initialStateAuth } from './auth/reducers';
import { initialState as initialStatePage } from './page/reducers';
import { initialState as initialStateSearch } from './search/reducers';
import { initialState as initialStateFilters } from './searchFilters/reducers';
import { initialState as initialStateSorting } from './searchSorting/reducers';
import { initialState as initialStateFiles } from './files/reducers';
import { initialState as initialStateFastWindow } from './fastWindow/reducers';
import { initialState as initialStateUniversalWireAccounts } from './universalWireAccounts/reducers';
import { initialState as initialStateUi } from './ui/reducers';
import { initialState as initialStateOrder } from './orders/reducers';
import { initialState as initialWireInstructions } from './wireInstructions/reducers';
import { initialState as initialHelpPage } from './faq/reducers';
import { initialState as initialGoogleMap } from './googleMap/reducers';
import { initialState as initialPropertySearch } from './propertySearch/reducers';
import { initialState as initialDashboard } from './dashboard/reducers';
import { initialState as initialPermissions } from './permissions/reducers';
import { initialState as initialPropertyFarming } from './propertyFarming/reducers';
import { initialState as initialSettings } from './settings/reducers';
import { initialState as initialBillingAndPayments } from './billingAndPayments/reducers';
import { initialState as initialAppNofitications } from './appNotifications/reducers';
import { initialState as initialMilestones } from './milestones/reducers';
import { initialState as initialChecklists } from './checklists/reducers';
import { initialState as initialResources } from './resources/reducers';
import { initialState as initialBulkInvitations } from './bulkInvitations/reducers';
import { initialState as productsInitialState } from './products/reducers';
import { initialState as lookupsInitialState } from './lookups/reducers';
import { initialState as initialOrderOrganizationState } from './orderOrganization/reducers';
import { initialState as initialInvoiceState } from './invoice/reducers';
import { initialState as initialAllocationsState } from './allocations/reducers';
import { initialState as initialOfficesState } from './offices/reducers';
import { initialState as initialDocumentsState } from './documents/reducers';
import { initialState as initialProgressBarState } from './ProgressBar/reducers';

import { composeWithDevTools } from 'redux-devtools-extension';

import apiMiddleware from './middleware/api';

export default function configureStore(
  history: History,
  initialStateChanges?: Partial<IApplicationState>
) {
  const enhancers = [];
  const middleware = [
    thunk,
    routerMiddleware(history),
    apiMiddleware,
    markUserAsActive,
    markUserAsActiveLimiter,
  ];

  const composer =
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools({}) || compose
      : compose;

  const composedEnhancers = composer(
    applyMiddleware(...middleware),
    ...enhancers
  );
  const initialState: IApplicationState = {
    users: {
      loading: false,
      error: null,
      resultsById: {},
      pagination: null,
      query: null,
    },
    offices: {
      ...initialOfficesState,
    },
    regions: {
      error: null,
      resultsById: {},
    },
    form: undefined,
    router: undefined,
    notifications: {
      visible: false,
      tileProps: null,
      autohide: false,
    },
    groups: {
      error: null,
      resultsById: {},
      loading: false,
    },
    features: {
      error: null,
      loading: false,
      components: [],
      features: [],
      userFeatures: [],
      component: {} as IFeatureComponent,
      feature: {} as IFeatureToggle,
      componentFeatures: [] as IFeatureComponent[],
      featureGroups: [] as IFeatureToggle[],
    },
    auth: { ...initialStateAuth },
    page: { ...initialStatePage },
    search: { ...initialStateSearch },
    filters: { ...initialStateFilters },
    sorting: { ...initialStateSorting },
    files: { ...initialStateFiles },
    fastSystem: { ...initialStateFastWindow },
    universalWireAccounts: { ...initialStateUniversalWireAccounts },
    ui: { ...initialStateUi },
    orders: { ...initialStateOrder },
    wireInstructions: { ...initialWireInstructions },
    googleMap: { ...initialGoogleMap },
    propertySearch: { ...initialPropertySearch },
    dashboard: { ...initialDashboard },
    permissions: { ...initialPermissions },
    propertyFarming: { ...initialPropertyFarming },
    helpPage: { ...initialHelpPage },
    settings: { ...initialSettings },
    billingPayments: { ...initialBillingAndPayments },
    appNotifications: { ...initialAppNofitications },
    milestones: { ...initialMilestones },
    checklists: { ...initialChecklists },
    resources: { ...initialResources },
    bulkInvitations: { ...initialBulkInvitations },
    products: { ...productsInitialState },
    lookups: { ...lookupsInitialState },
    orderOrganizations: { ...initialOrderOrganizationState },
    invoice: { ...initialInvoiceState },
    allocations: { ...initialAllocationsState },
    documents: { ...initialDocumentsState },
    progressBar: { ...initialProgressBarState },
    ...initialStateChanges,
  };

  return createStore(reducers(history), initialState, composedEnhancers);
}
