/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_Requests_SaveEmployeeRequest } from '../models/Workspace_API_Requests_SaveEmployeeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderEmployeesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Save an employee to an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public saveEmployeeAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_Requests_SaveEmployeeRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/employees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `No privilege to save an employee`,
        404: `Order Id does not exist`,
        409: `Employee with the same role already in order`,
      },
    });
  }

  /**
   * Remove an employee from an order by business party role Id (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeEmployeeAsync({
    orderId,
    employeeId,
    roleId,
  }: {
    orderId: number,
    employeeId: number,
    roleId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/employees/{employeeId}/roles/{roleId}',
      path: {
        'orderId': orderId,
        'employeeId': employeeId,
        'roleId': roleId,
      },
      errors: {
        403: `No privilege to save an employee`,
        404: `Order Id does not exist or no employee with the specific role on the order`,
      },
    });
  }

  /**
   * Save an underwriter to an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public saveUnderwriterAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_Requests_SaveEmployeeRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/underwriters',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `No privilege to save an underwriter`,
        404: `Order Id does not exist`,
        409: `Underwriter with the same role already in order`,
      },
    });
  }

  /**
   * Remove an underwriter from an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeUnderwriterAsync({
    orderId,
    underwriterId,
  }: {
    orderId: number,
    underwriterId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/underwriters/{underwriterId}',
      path: {
        'orderId': orderId,
        'underwriterId': underwriterId,
      },
      errors: {
        403: `No privilege to save an underwriter`,
        404: `Order Id does not exist or no underwriter on the order`,
      },
    });
  }

  /**
   * Save an account manager to an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public saveAccountManagerAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_Requests_SaveEmployeeRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/account-managers',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `No privilege to save an employee`,
        404: `Order Id does not exist`,
        409: `Employee with the same role already in order`,
      },
    });
  }

  /**
   * Remove an account manager from an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeAccountManagerAsync({
    orderId,
    accountManagerId,
  }: {
    orderId: number,
    accountManagerId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/account-managers/{accountManagerId}',
      path: {
        'orderId': orderId,
        'accountManagerId': accountManagerId,
      },
      errors: {
        403: `No privilege to save an acconut manager`,
        404: `Order Id does not exist or no acconut manager on the order`,
      },
    });
  }

}
