import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="45"
    height="19"
    viewBox="0 0 45 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
    data-testid="new-label-icon"
  >
    <rect width="45" height="18.9999" rx="3" fill="#48A0DA" />
    <path
      d="M16.3386 14V5.492H14.6826V11.12L11.1426 5.492H9.07856V14H10.7346V7.964L14.5986 14H16.3386ZM23.7258 14V12.44H20.0538V10.472H23.3778V8.996H20.0538V7.052H23.7258V5.492H18.3978V14H23.7258ZM33.5173 11.372L31.6213 5.492H29.9053L28.0213 11.348L26.4613 5.492H24.7093L27.0733 14H28.8013L30.7453 7.94L32.6893 14H34.3933L36.7453 5.492H35.0533L33.5173 11.372Z"
      fill="white"
    />
  </svg>
);
