export const CLARITYFIRST_VIDEO_LINK = `https://player.vimeo.com/video/${AppConfig.landingPageVideoId}?autoplay=0&loop=0&autopause=0`;
export const STATE_GUIDE_TO_REAL_ESTATE_LAWS =
  'https://www.firstam.com/assets/commercial/real-estate-customs-guide/fa-ncs-realestatecustoms-digital-04.2021.pdf';
export const ALTA_ENDORSEMENT_GUIDE =
  'https://online.flippingbook.com/view/290114290/?utm_source=web&utm_medium=clarityfirst&utm_campaign=2024+Commercial+Endorsement+Guide+Field+Campaign';
export const TEACH_ME = 'https://iss.firstam.net/teachme/commercial/';
export const REAL_ESTATE_GLOSSARY =
  'https://www.firstam.com/title/resources/reference-information/national-real-estate-glossary.html';
export const DUE_DILIGENCE_SERVICES_GUIDE =
  'http://files.ctctcdn.com/c5fa83aa501/aa331b8f-fb3e-4b03-8787-1599d9f9c1f2.pdf';
export const FIRST_AMERICAN_NATIONAL_COMMERCIAL_SERVICES =
  'https://www.firstam.com/title/commercial/';
export const LECTURE_SERIES =
  'https://www.firstam.com/title/commercial/resources/?utm_source=cre-cf&utm_medium=web-cf&utm_campaign=cre-cf&utm_id=cre-cf&utm_content=cre-cf#cre-economic-hub';
export const TITLE_CAMP_WEBINARS =
  'https://www.firstam.com/title/commercial/resources/title-camp/?utm_source=ncs-cf&utm_medium=web&utm_campaign=tc2024&utm_content=resources';
export const ONE_ZERO_THREE_ONE_EXCHANGE = 'https://www.firstexchange.com/';
export const CRE_INSIDER_BLOG = 'https://blog.firstam.com/commercial';
export const FIRST_AMERICAN_NEWS = 'https://www.firstam.com/news/index.html';
export const HELP_URL = '/help';
export const LEAP_FROG = 'https://leapfrog.firstam.com/extractions';
