import styled from 'styled-components';
import { blue, linkWater, trout } from 'config/theme';
import { Col } from 'react-styled-flexboxgrid';

export const Divider = styled.hr<{
  margin?: string;
  padding?: string;
  width?: string;
}>`
  border: 0;
  border-top: 1px solid ${linkWater};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: ${(props) => (props.padding ? props.padding : '0px')};
`;
Divider.displayName = 'Divider';

export const SectionContainer = styled(Col)`
  padding-left: 0px;
  display: flex;
`;
SectionContainer.displayName = 'SectionContainer';

export const SectionTitle = styled.div<{ float?: string; marginTop?: string }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${(props) => (props.color ? props.color : blue)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  float: ${(props) => (props.float ? props.float : 'left')};
`;
SectionTitle.displayName = 'SectionTitle';

export const SectionTagLine = styled.div`
  font-size: 14px;
  font-weight: 450;
  line-height: 19px;
  letter-spacing: 0.0025em;
  color: ${trout};
  padding-left: 12px;
  margin-top: 12px;
`;
SectionTagLine.displayName = 'SectionTagLine';

export const SuperSymbols = styled.span`
  font-size: 12px;
  line-height: 20px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.005em;
  vertical-align: super;
`;

SuperSymbols.displayName = 'SuperSymbols';
