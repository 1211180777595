import { ButtonLink, Logo } from '.';
import { HeaderLinks } from './Header.styles';
import { primaryBlue8 } from 'config/colors';
import { Row } from 'components/ui/Box';
import { default as Button } from 'components/ui/MButton';
import AccountOutline from 'icons/AccountOutline';
import HelpOutline from 'icons/HelpOutline';
import logo from 'images/logo.png';
import React, { FunctionComponent, useCallback } from 'react';
import Section from './Section';

enum Links {
  HELP = 'https://registration.firstam.com/EA/ClarityFirst/HelpCustomersOutside/index.html',
  SIGN_IN = 'https://ncsmarketing.firstam.com/clarityfirst/?utm_source=cf&utm_medium=web&utm_campaign=request_account&utm_content=top_nav',
}

interface IProps {
  location?: any;
}

const Header: FunctionComponent<IProps> = ({ location }) => {
  const signIn = useCallback(() => {
    window.location.href = `/customer/signin${location.search}`;
  }, []);

  return (
    <Section>
      <Row justify="between">
        <Logo tabIndex={1} title="Clarify First Top Logo">
          <img src={logo} alt="Clarify First Top Logo" />
        </Logo>

        <HeaderLinks>
          <Button
            component={'a'}
            href={Links.HELP}
            startIcon={<HelpOutline fill={primaryBlue8} />}
            tabIndex={2}
            variant="ghost"
          >
            Help
          </Button>
          <Button
            component={'a'}
            id="homepage__signIn_Button"
            onClick={signIn}
            startIcon={<AccountOutline fill={primaryBlue8} />}
            tabIndex={3}
            variant="ghost"
          >
            Sign In
          </Button>
          <Button
            aria-label="Request Account Button"
            component={ButtonLink}
            href={Links.SIGN_IN}
            tabIndex={4}
          >
            Request Account
          </Button>
        </HeaderLinks>
      </Row>
    </Section>
  );
};

export default Header;
