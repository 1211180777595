import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props?: ISVGProps) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props?.style}
  >
    <path
      d="M9.84546 5.53444L12.7273 8.3618C12.7273 8.31707 12.7273 8.26338 12.7273 8.21865C12.7273 7.50675 12.4399 6.82401 11.9285 6.32062C11.417 5.81724 10.7233 5.53444 10 5.53444C9.94545 5.53444 9.9 5.53444 9.84546 5.53444ZM5.93636 6.25023L7.34545 7.63707C7.3 7.82496 7.27273 8.01286 7.27273 8.21865C7.27273 8.93054 7.56006 9.61328 8.07153 10.1167C8.58299 10.6201 9.27668 10.9029 10 10.9029C10.2 10.9029 10.4 10.876 10.5909 10.8313L12 12.2181C11.3909 12.5134 10.7182 12.6923 10 12.6923C8.79447 12.6923 7.63832 12.221 6.78588 11.382C5.93344 10.543 5.45455 9.40514 5.45455 8.21865C5.45455 7.51181 5.63636 6.8497 5.93636 6.25023ZM0.909091 1.30233L2.98182 3.34233L3.39091 3.74496C1.89091 4.90812 0.709091 6.42917 0 8.21865C1.57273 12.1465 5.45455 14.9292 10 14.9292C11.4091 14.9292 12.7545 14.6608 13.9818 14.1776L14.3727 14.5534L17.0273 17.166L18.1818 16.0297L2.06364 0.166016L0.909091 1.30233ZM10 3.74496C11.2055 3.74496 12.3617 4.2163 13.2141 5.05527C14.0666 5.89425 14.5455 7.03215 14.5455 8.21865C14.5455 8.79128 14.4273 9.34601 14.2182 9.84707L16.8818 12.4686C18.2455 11.3502 19.3364 9.88286 20 8.21865C18.4273 4.29075 14.5455 1.50812 10 1.50812C8.72727 1.50812 7.50909 1.7318 6.36364 2.13444L8.33636 4.05812C8.85455 3.86128 9.40909 3.74496 10 3.74496Z"
      fill={props?.fill || 'currentColor'}
    />
  </svg>
);
