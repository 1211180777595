export enum FarmShapeType {
  Point = 1,
  Polygon,
  Multipolygon,
}

export enum Tabs {
  PropertySearch,
  MyFarms,
  PublicRecords,
  Billing,
}
