import React from 'react';
import { connect } from 'react-redux';
import { IFeatureComponentTree } from 'store/features/types';

interface IFeatureToggleProps {
  features: IFeatureComponentTree;
  off?: boolean;
  children: React.ReactElement;
  flag: string;
}

interface IFeatureFlag {
  component: string;
  feature: string;
}

export const FEATURE_FLAG_PATTERN = /^([A-Za-z0-9-]+)\.([A-Za-z0-9_]+)$/;

export function FeatureToggle(props: IFeatureToggleProps) {
  const { children, features, flag, off } = props;

  let component;
  let feature;

  try {
    const parsedFlag = parseFeatureFlag(flag);
    component = parsedFlag.component;
    feature = parsedFlag.feature;
  } catch (e) {
    return null;
  }

  const componentFeatures = features[component];
  const enabled = Boolean(
    componentFeatures && componentFeatures.indexOf(feature) > -1
  );
  const toggledOff = Boolean(off && !enabled);

  if ((enabled && !off) || toggledOff) {
    return children;
  }

  return null;
}

export default connect(mapStateToProps)(FeatureToggle);

export function mapStateToProps(state) {
  const featureComponentsArray = state.auth.session.features;
  const featuresTree = featureComponentsArray.reduce(
    (res, { name, features }) => {
      res[name] = features;
      return res;
    },
    {}
  );

  return { features: featuresTree };
}

export function parseFeatureFlag(flag: string): IFeatureFlag {
  const matches = flag.match(FEATURE_FLAG_PATTERN);

  if (!matches) {
    throw new Error('Flag must be in the format of `ComponentA.FeatureA`.');
  }

  return {
    component: matches[1],
    feature: matches[2],
  };
}
