import { RegionsTypeKeys, RegionsAction, IRegionsState } from './types';

export const initialState: IRegionsState = {
  error: null,
  resultsById: {},
};

export default (state = initialState, action: RegionsAction) => {
  switch (action.type) {
    case RegionsTypeKeys.FETCH_ALL_SUCCESS:
      return {
        ...state,
        error: null,
        resultsById: action.resultsById,
      };
    case RegionsTypeKeys.FETCH_ALL_ERROR:
      return {
        ...state,
        error: action.error,
        resultsById: {},
      };
    default:
      return state;
  }
};
