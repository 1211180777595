/* eslint-disable import/no-unused-modules */
import { AxiosError } from 'axios';

export interface IBulkInvitationState {
  file: any;
  fileName: string;
  isValidData: boolean;
  isDataError: boolean;
  invitationsData: IInvitationsData;
  error: string | null | AxiosError;
  customError: string | null | undefined;
  percent: number;
  validAccountsMessage: string;
  showBulkInvitationModal: boolean;
  files: File[];
  showProgressBar: boolean;
  progressBarPercent: number;
}

export type BulkInvitationsAction =
  | IBulkInvitationsSetFileNameAvailableAction
  | IBulkInvitationsUpdateProgessBarAction
  | IBulkInvitationsSetFileSelectedAction
  | IBulkInvitationValidateFileErrorAction
  | IBulkInvitationsSetValidDataAction
  | IBulkInvitationsSubmitSuccessAction
  | IBulkInvitationsSubmitErrorAction
  | IBulkInvitationsClearAction
  | IBulkInvitationModalSetModeAction;

export enum BulkInvitationsTypeKeys {
  VALIDATE_FILE_ERROR = 'bulkInvitationModal/VALIDATE_FILE_ERROR',
  SET_VALID_DATA = 'bulkInvitationModal/SET_VALID_DATA',
  SUBMIT_REQUEST_SUCCESS = 'bulkInvitationModal/SUBMIT_REQUEST_SUCCESS',
  SUBMIT_REQUEST_ERROR = 'bulkInvitationModal/SUBMIT_REQUEST_ERROR',
  CLEAR_DATA = 'bulkInvitationModal/CLEAR_DATA',
  CHANGE_MODAL_MODE = 'bulkInvitationModal/CHANGE_MODAL_MODE',
  FILE_NAME_AVAILABLE = 'bulkInvitationModal/FILE_NAME_AVAILABLE',
  UPDATE_PROGRESS_BAR = 'bulkInvitationModal/UPDATE_PROGRESS_BAR',
  ERROR = 'bulkInvitationModal/ERROR',
  FILE_SELECTED = 'bulkInvitationModal/FILE_SELECTED',
}

export interface IBulkInvitationsSetFileNameAvailableAction {
  type: BulkInvitationsTypeKeys.FILE_NAME_AVAILABLE;
  fileName: string;
}

export interface IBulkInvitationsUpdateProgessBarAction {
  type: BulkInvitationsTypeKeys.UPDATE_PROGRESS_BAR;
  newProgressBarValue: number;
}

export interface IBulkInvitationsSetFileSelectedAction {
  type: BulkInvitationsTypeKeys.FILE_SELECTED;
  files: File[];
}

export interface IBulkInvitationValidateFileErrorAction {
  type: BulkInvitationsTypeKeys.VALIDATE_FILE_ERROR;
  error: string | null | AxiosError;
}

export interface IBulkInvitationsSetValidDataAction {
  type: BulkInvitationsTypeKeys.SET_VALID_DATA;
  bulkInvitationsData: IInvitationsData;
}

export interface IBulkInvitationsSubmitSuccessAction {
  type: BulkInvitationsTypeKeys.SUBMIT_REQUEST_SUCCESS;
}

export interface IBulkInvitationsSubmitErrorAction {
  type: BulkInvitationsTypeKeys.SUBMIT_REQUEST_ERROR;
  error: string | null | AxiosError;
}

export interface IBulkInvitationsClearAction {
  type: BulkInvitationsTypeKeys.CLEAR_DATA;
}

export interface IBulkInvitationModalSetModeAction {
  type: BulkInvitationsTypeKeys.CHANGE_MODAL_MODE;
  showBulkInvitationModal: boolean;
}

export interface IInvitationsData {
  employeeUserEmail: string;
  invitations: IInvitation[];
}

export interface IInvitation {
  resend: boolean;
  email: string;
  firstName: string;
  lastName: string;
  mfaPhoneNumber: string;
}

export enum BulkInvitationsLoadingKeys {
  fileValidationInProgress = 'bulkInvitationModal/FILE_VALIDATION_IN_PROGRESS',
  submitProcessing = 'bulkInvitationModal/SUBMIT_REQUEST_IN_PROGRESS',
}
