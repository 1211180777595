/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_RecordingTaxPayeesViewModel } from '../models/Workspace_API_ViewModels_RecordingTaxPayeesViewModel';
import type { Workspace_Common_Models_Dto_TaxesAndFees_PayRecordingFeeDto } from '../models/Workspace_Common_Models_Dto_TaxesAndFees_PayRecordingFeeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderRecordingTaxFeePayeesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Allows to get the available payees for an specific recording tax/fee. (Auth)
   * @returns Workspace_API_ViewModels_RecordingTaxPayeesViewModel The list of payees.
   * @throws ApiError
   */
  public getPayees({
    orderId,
  }: {
    /**
     * The order id to check for.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_RecordingTaxPayeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/recording-fees-taxes/payees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user.`,
        404: `Order or Fee Not Found or not available payees or External user.`,
      },
    });
  }

  /**
   * Pays the given recording fees to the specified payee (Auth)
   * @returns void
   * @throws ApiError
   */
  public payRecordingFeeAsync({
    orderId,
    payeeId,
    requestBody,
  }: {
    /**
     * The order Id where the fees belong to
     */
    orderId: number,
    /**
     * The id of the payee to pay the fees to
     */
    payeeId: number,
    /**
     * A DTO containing information about the fees to pay and the payee to assign
     */
    requestBody?: Workspace_Common_Models_Dto_TaxesAndFees_PayRecordingFeeDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/recording-fees-taxes/payees/{payeeId}',
      path: {
        'orderId': orderId,
        'payeeId': payeeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        404: `Order or Fee Not Found or External user`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns void
   * @throws ApiError
   */
  public deletePayee({
    orderId,
    payeeId,
  }: {
    orderId: number,
    payeeId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/recording-fees-taxes/payees/{payeeId}',
      path: {
        'orderId': orderId,
        'payeeId': payeeId,
      },
    });
  }

}
