/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_Requests_CreateUserRequest } from '../models/Workspace_API_Requests_CreateUserRequest';
import type { Workspace_API_Requests_UpdateUserRequest } from '../models/Workspace_API_Requests_UpdateUserRequest';
import type { Workspace_Common_Models_PropertyProductTransaction } from '../models/Workspace_Common_Models_PropertyProductTransaction';
import type { Workspace_Common_Models_User } from '../models/Workspace_Common_Models_User';
import type { Workspace_Common_Requests_Invitation } from '../models/Workspace_Common_Requests_Invitation';
import type { Workspace_Common_Requests_PropertyProductUserSettingsRequest } from '../models/Workspace_Common_Requests_PropertyProductUserSettingsRequest';
import type { Workspace_Common_Requests_RecordPropertyProductTransactionRequest } from '../models/Workspace_Common_Requests_RecordPropertyProductTransactionRequest';
import type { Workspace_Common_Responses_IncompleteWireRequest } from '../models/Workspace_Common_Responses_IncompleteWireRequest';
import type { Workspace_Common_Responses_UserInvitationInfo } from '../models/Workspace_Common_Responses_UserInvitationInfo';
import type { Workspace_Common_Responses_UsersList } from '../models/Workspace_Common_Responses_UsersList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve a paged list of users (Auth)
   * @returns Workspace_Common_Responses_UsersList Page of users successfully retrieved
   * @throws ApiError
   */
  public getUsers({
    page = 1,
  }: {
    /**
     * page offset for the list
     */
    page?: number,
  }): CancelablePromise<Workspace_Common_Responses_UsersList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users',
      query: {
        'page': page,
      },
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        404: `User does not exist`,
      },
    });
  }

  /**
   * Create a user (Auth)
   * @returns Workspace_Common_Models_User User created successfully
   * @throws ApiError
   */
  public createUserAsync({
    requestBody,
  }: {
    requestBody: Workspace_API_Requests_CreateUserRequest,
  }): CancelablePromise<Workspace_Common_Models_User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Retrieve a paginated list of users that match a search by name or email (Auth)
   * @returns Workspace_Common_Responses_UsersList Page of users successfully retrieved
   * @throws ApiError
   */
  public searchUsersByNameOrEmail({
    q,
    page = 1,
    onlyInternal = false,
  }: {
    /**
     * a search query
     */
    q: string,
    /**
     * a page number
     */
    page?: number,
    /**
     * Whether or not to filter out external users
     */
    onlyInternal?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_UsersList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/_search',
      query: {
        'q': q,
        'page': page,
        'onlyInternal': onlyInternal,
      },
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        422: `Invalid page number (less than 1)`,
      },
    });
  }

  /**
   * Retrieve information about a User, given its ID (Auth)
   * @returns Workspace_Common_Models_User User successfully retrieved
   * @throws ApiError
   */
  public getUserDetails({
    id,
  }: {
    /**
     * a user ID
     */
    id: number,
  }): CancelablePromise<Workspace_Common_Models_User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        404: `User does not exist`,
      },
    });
  }

  /**
   * Updates a user (Auth)
   * @returns Workspace_Common_Models_User User updated successfully
   * @throws ApiError
   */
  public updateUser({
    id,
    requestBody,
  }: {
    /**
     * a user ID
     */
    id: number,
    requestBody: Workspace_API_Requests_UpdateUserRequest,
  }): CancelablePromise<Workspace_Common_Models_User> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Get User's Invitation Info (Auth)
   * @returns Workspace_Common_Responses_UserInvitationInfo User valid invitation info successfully retrieved
   * @throws ApiError
   */
  public getUserInvitationInfo({
    id,
  }: {
    /**
     * a user ID
     */
    id: number,
  }): CancelablePromise<Workspace_Common_Responses_UserInvitationInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/{id}/invitations/_valid',
      path: {
        'id': id,
      },
      errors: {
        401: `Invalid Authorization header, or current user is not an admin`,
        403: `User doesn't have the required Admin activity right`,
        404: `User valid invitation info does not exist`,
      },
    });
  }

  /**
   * Force the sync for a given User (Auth)
   * @returns void
   * @throws ApiError
   */
  public syncUserAsync({
    id,
  }: {
    /**
     * a user ID
     */
    id: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/{id}/sync',
      path: {
        'id': id,
      },
      errors: {
        403: `User doesn't have the required Admin activity right`,
        404: `User does not exist.`,
        422: `User validation failed.`,
      },
    });
  }

  /**
   * Get incomplete wireInstructions for external user by his/her email address.
   * wireInstruction with Status as either Requested or AwaitingUserVerification will be considered as incomplete. (Auth)
   * @returns Workspace_Common_Responses_IncompleteWireRequest Success
   * @throws ApiError
   */
  public getIncompleteWireInstructions(): CancelablePromise<Array<Workspace_Common_Responses_IncompleteWireRequest>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/incomplete-wire-instructions',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Record property product download activity (Auth)
   * @returns boolean Activity recorded successfully
   * @throws ApiError
   */
  public recordPropertyProductTransaction({
    requestBody,
  }: {
    /**
     * Request to add BrainTree billing account to a user
     */
    requestBody: Workspace_Common_Requests_RecordPropertyProductTransactionRequest,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/users/property-product-transaction',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get property product download activities by start and end time (Auth)
   * @returns Workspace_Common_Models_PropertyProductTransaction Activity recorded successfully
   * @throws ApiError
   */
  public getPropertyProductTransactions({
    startDate,
    endDate,
  }: {
    startDate?: string | null,
    endDate?: string | null,
  }): CancelablePromise<Array<Workspace_Common_Models_PropertyProductTransaction>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/property-product-transaction',
      query: {
        'startDate': startDate,
        'endDate': endDate,
      },
      errors: {
        401: `User is internal and doesn't have billable transactions`,
        404: `Invalid time range or no record found`,
      },
    });
  }

  /**
   * Send (or Resend) an Invitation to a user (Auth)
   * @returns any Invitation successfully sent
   * @throws ApiError
   */
  public sendUserInvitation({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_Invitation,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/users/_send-invitation',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        409: `User is not in "Pending" status`,
      },
    });
  }

  /**
   * Get property product related user settings by type. Type "All" will return all settings (Auth)
   * @returns string Activity recorded successfully
   * @throws ApiError
   */
  public getPropertyProductUserSettings({
    settingType,
  }: {
    settingType: string,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/property-product-user-settings/{settingType}',
      path: {
        'settingType': settingType,
      },
      errors: {
        404: `No record found`,
      },
    });
  }

  /**
   * Update property product user settings by type (Auth)
   * @returns void
   * @throws ApiError
   */
  public updatePropertyProductUserSettings({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_PropertyProductUserSettingsRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/users/property-product-user-settings',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        422: `Validation failed.`,
      },
    });
  }

  /**
   * For the user, identified by the id param, to validate their email. (Auth)
   * @returns any UserLastEmailVerification date is cleared
   * @throws ApiError
   */
  public resetEmailVerification({
    id,
  }: {
    /**
     * Id of the user that will be forced to validate their email
     */
    id: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/users/{id}/_reset-email-verification',
      path: {
        'id': id,
      },
      errors: {
        403: `Current User is not an admin`,
        404: `Not Found`,
      },
    });
  }

  /**
   * For the user, identified by the id param, to bypass their email verification for 45 days (Auth)
   * @returns any UserLastEmailVerification date is set to today
   * @throws ApiError
   */
  public bypassEmailVerification({
    id,
  }: {
    /**
     * Id of the user that will be forced to validate their email
     */
    id: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/{id}/_bypass-email-verification',
      path: {
        'id': id,
      },
      errors: {
        403: `Current User is not an admin`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Activate user account (Auth)
   * @returns void
   * @throws ApiError
   */
  public activateUserAccount({
    id,
  }: {
    /**
     * a user ID
     */
    id: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/{id}/_activate-account',
      path: {
        'id': id,
      },
      errors: {
        403: `Current user is not an admin`,
        404: `No record found`,
      },
    });
  }

  /**
   * De-activate user account (Auth)
   * @returns void
   * @throws ApiError
   */
  public deactivateUserAccount({
    id,
  }: {
    /**
     * a user ID
     */
    id: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/{id}/_deactivate-account',
      path: {
        'id': id,
      },
      errors: {
        403: `Current user is not an admin`,
        404: `No record found`,
      },
    });
  }

}
