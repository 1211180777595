import { lightBlue } from 'config/theme';
import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3369 0.89502H11.5856C10.3822 0.89502 9.39777 1.88716 9.39777 3.09882V9.71021C9.39777 10.9219 10.3822 11.914 11.5856 11.914H20.3369C21.5403 11.914 22.5247 10.9219 22.5247 9.71021V3.09882C22.5247 1.88716 21.5403 0.89502 20.3369 0.89502ZM20.3369 9.71021H11.5856V5.30261H20.3369V9.71021Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M7.20995 6.40451H5.02213V14.1178C5.02213 15.3295 6.00654 16.3216 7.20995 16.3216H17.0551V14.1178H7.20995V6.40451Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M0.646484 10.8121H2.83431V18.5254H12.6795V20.7292H2.83431C1.6309 20.7292 0.646484 19.7371 0.646484 18.5254V10.8121Z"
      fill={props.fill || lightBlue}
    />
  </svg>
);
