import React from 'react';
import styled from 'styled-components';
import CollapsibleCard from 'components/ui/CollapsibleCard';
import {
  CollapsibleHeader,
  CollapsibleContent,
} from 'components/ui/CollapsibleCard/Common';

interface IAccordionSnackbarBaseProps {
  header: React.ReactNode;
  style?: React.CSSProperties;
  showExpandIcon: boolean;
}

const AccordionSnackbarBase: React.FC<IAccordionSnackbarBaseProps> = ({
  header,
  children,
  style,
  showExpandIcon,
}) => {
  return (
    <BaseCollapsible
      headerNode={header}
      divider
      style={style}
      // `undefined` for expandIcon uses default icon - false will prevent it from rendering
      expandIcon={showExpandIcon ? undefined : false}
    >
      {children}
    </BaseCollapsible>
  );
};

const BaseCollapsible = styled(CollapsibleCard)`
  width: 400px;
  ${CollapsibleHeader} {
    padding: 16px;
  }
  ${CollapsibleContent} {
    padding: 0;
  }
`;

export default AccordionSnackbarBase;
