import React from 'react';
import { Dispatch } from 'store';

export enum IButtonType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export interface IButtonUrlWithState {
  url: string;
  state?: any;
}

export interface IButtonProps {
  ariaLabel: string;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  borderColor?: string;
  borderHoverColor?: string;
  activeBackgroundColor?: string;
  capitalize?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledTextColor?: string;
  dispatch: Dispatch;
  icon?: ButtonIcon;
  minWidth?: string;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  plain?: boolean;
  ghost?: boolean;
  secondary?: boolean;
  shadow?: string;
  size: ButtonSize;
  style?: React.CSSProperties;
  tabIndex?: number;
  textColor?: string;
  textHoverColor?: string;
  title?: string;
  to?: string | IButtonUrlWithState;
  type: IButtonType | 'button' | 'reset' | 'submit';
  uppercase?: boolean;
  id?: string;
  aiTrackName?: string;
  aiProperties?: { [name: string]: any };
  data?: { [name: string]: any };
  loading?: boolean;
  dataFor?: string;
}

export enum ButtonSize {
  SM = 0,
  MD = 1,
  LG = 2,
  XL = 3,
}

export enum ButtonIcon {
  ADD = 'add',
  DOLLAR = 'dollar',
  PLUS = 'plus',
  LINK = 'link',
  QUESTION = 'question',
  PREVIEW = 'preview',
  EDIT = 'edit',
  EYE = 'eye',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
  CANCEL = 'cancel',
  OPEN_EXTERNAL_LINK = 'openExternalLink',
  FILE_SEARCH = 'fileSearch',
}
