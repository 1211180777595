import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || neutralGray7}
      d="M21.8412 13.35L20.8333 14.35L18.7669 12.3L19.7749 11.3C19.9866 11.09 20.3394 11.09 20.551 11.3L21.8412 12.58C22.0529 12.79 22.0529 13.14 21.8412 13.35ZM12.0638 18.94L18.1722 12.88L20.2386 14.93L14.1403 21H12.0638V18.94ZM12.0638 14C7.60857 14 4 15.79 4 18V20H10.0479V18.11L14.0798 14.11C13.4145 14.03 12.7392 14 12.0638 14ZM12.0638 4C10.9945 4 9.96897 4.42143 9.21284 5.17157C8.45671 5.92172 8.03192 6.93913 8.03192 8C8.03192 9.06087 8.45671 10.0783 9.21284 10.8284C9.96897 11.5786 10.9945 12 12.0638 12C13.1332 12 14.1587 11.5786 14.9148 10.8284C15.671 10.0783 16.0958 9.06087 16.0958 8C16.0958 6.93913 15.671 5.92172 14.9148 5.17157C14.1587 4.42143 13.1332 4 12.0638 4Z"
    />
  </svg>
);
