import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AppLogoIcon } from 'components/svg/legacyIcons_DO_NOT_USE';

export default () => (
  <LogoContainer>
    <Link to="/dashboard" className="rootLink">
      <AppLogoIcon />
    </Link>
  </LogoContainer>
);

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 130px;
  margin: 4px 50px 0 0;
`;
