import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useBaseRequest, {
  IBaseRequestOptions,
  IBaseReturn,
} from './useBaseRequest';

interface IQueryReturn<TData = AxiosResponse, TError = AxiosError>
  extends Omit<IBaseReturn<TData, TError>, 'call'> {
  refetch: ReturnType<typeof useCallback>;
  clear: () => void;
  isSuccess: boolean;
  isError: boolean;
}

/** Send a HTTP request using react hooks. Uses workspaceAPI to send out the request */
export default function useQuery<
  TData = AxiosResponse,
  TError = AxiosError,
  TRequestData = any
>(
  // Callback is enforced so refetch can trigger with latest definition of function
  callback: ReturnType<typeof useCallback>,
  config: IBaseRequestOptions<TData, TError, TRequestData>
): IQueryReturn<TData, TError> {
  const baseRequest = useBaseRequest(callback, config);

  return {
    ...baseRequest,
    refetch: baseRequest.call,
  };
}
