import { AsyncDataState, IAsyncData } from 'store';
import { TransactionType } from '../orders/types';

export enum LookupsTypeKeys {
  SET_COUNTRIES = 'lookups/SET_COUNTRIES',
  SET_COUNTRY_STATES = 'lookups/SET_COUNTRY_STATES',
  SET_PROPERTY_TYPES = 'lookups/SET_PROPERTY_TYPES',
  SET_STATE_COUNTIES = 'lookups/SET_STATE_COUNTIES',
  FETCH_TRANSACTION_TYPES = 'lookups/FETCH_TRANSACTION',
  SET_BUSINESS_ENTITY_TYPES = 'lookups/SET_BUSINESS_ENTITY_TYPES',
}

export interface ILookupsState {
  countries: ICountry[];
  countryStates: ICountryStatesHashMap;
  stateCounties: IStateCountiesHashMap;
  businessEntityTypes: IBusinessEntityTypes[];
  propertyTypes: IPropertyType[];
  transactionTypes: IAsyncData<TransactionType[], any>;
}

export type LookupsAction =
  | IFetchTransactionType
  | ILookupsSetCountries
  | ILookupsSetCountryStates
  | ILookupsSetBusinessEntityTypes
  | ILookupsSetPropertyTypes
  | ILookupsSetStateCounties;

export interface IFetchTransactionType {
  type: LookupsTypeKeys.FETCH_TRANSACTION_TYPES;
  state: AsyncDataState;
  transactionTypes?: TransactionType[];
  error?: any;
}

export interface ILookupsSetCountries {
  type: LookupsTypeKeys.SET_COUNTRIES;
  payload: {
    countries: ICountry[];
  };
}

export interface ILookupsSetCountryStates {
  type: LookupsTypeKeys.SET_COUNTRY_STATES;
  payload: {
    countryStates: ICountryStatesHashMap;
  };
}

export interface ILookupsSetBusinessEntityTypes {
  type: LookupsTypeKeys.SET_BUSINESS_ENTITY_TYPES;
  payload: {
    businessEntityTypes: IBusinessEntityTypes[];
  };
}

export interface ILookupsSetPropertyTypes {
  type: LookupsTypeKeys.SET_PROPERTY_TYPES;
  payload: {
    propertyTypes: IPropertyType[];
  };
}

export interface ILookupsSetStateCounties {
  type: LookupsTypeKeys.SET_STATE_COUNTIES;
  payload: {
    stateCounties: IStateCountiesHashMap;
  };
}

export interface ICountry {
  id: number;
  code: string;
  name: string;
}

export interface ICountryState {
  id: number;
  abbreviation: string;
  name: string;
}

export interface IBusinessEntityTypes {
  id: number;
  description: string;
  objectCd?: string;
}

export interface IStateCounty {
  name: string;
  value: string;
}

export interface ICounty extends IStateCounty {
  id: number;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IPropertyType {
  displayName: string;
  ncsSpecific: boolean;
  propertyTypeId: number;
}

export interface IStateCountiesHashMap {
  [countryDotState: string]: IStateCounty[];
}

export interface ICountryStatesHashMap {
  [country: string]: ICountryState[];
}
