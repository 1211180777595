export enum RegionsTypeKeys {
  FETCH_ALL_REQUEST = 'regions/FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS = 'regions/FETCH_ALL_SUCCESS',
  FETCH_ALL_ERROR = 'regions/FETCH_ALL_ERROR',
}

export interface IRegionsState {
  resultsById: IRegionHash;
  error: string | null;
}

export interface IFetchRegionsRequestAction {
  type: RegionsTypeKeys.FETCH_ALL_REQUEST;
}

export interface IFetchRegionsSuccessAction {
  type: RegionsTypeKeys.FETCH_ALL_SUCCESS;
  resultsById: IRegionHash;
}

export interface IFetchRegionsErrorAction {
  type: RegionsTypeKeys.FETCH_ALL_ERROR;
  error: string;
}

export type RegionsAction =
  | IFetchRegionsRequestAction
  | IFetchRegionsSuccessAction
  | IFetchRegionsErrorAction;

export interface IRegionHash {
  [id: string]: IRegion;
}

export interface IRegion {
  name: string;
  fastId: number;
  id: number;
  isPrimary?: boolean;
}
