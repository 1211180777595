import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || 'currentColor'}
      d="M0.999276 0.201172H14.8419C15.3765 0.201172 15.8099 0.641684 15.8099 1.18009V17.7854C15.8099 18.3256 15.3783 18.7643 14.8419 18.7643H0.999276C0.464505 18.7643 0.03125 18.3238 0.03125 17.7854V1.18009C0.03125 0.639872 0.462919 0.201172 0.999276 0.201172ZM6.51127 2.05567H9.288V4.84196H6.51127V2.05567ZM4.65996 4.84196V2.05567H1.9673V4.84196H4.65996ZM1.9673 6.69828H4.65996V9.48275H1.9673V6.69828ZM6.51127 6.69828H9.288V9.48275H6.51127V6.69828ZM11.1393 6.69828V9.48275H13.9135V6.69828H11.1393ZM13.9135 4.84196L13.9163 2.05567H11.1393V4.84196H13.9135ZM13.9135 11.3391L13.9163 16.9062L10.2136 16.908V14.1235C10.2136 13.6105 9.79807 13.1954 9.2855 13.1954H6.51353C6.00097 13.1954 5.58539 13.6105 5.58539 14.1235V16.908L1.9673 16.9062V11.3391H13.9135Z"
    />
  </svg>
);
