import React from 'react';
import styled from 'styled-components';
import {
  BuildingsSiteIcon,
  BuildingsIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import Icon from './Icon';

const MultisiteSite = () => {
  return (
    <Container className="MultisiteSite__label">
      <Background />
      <MultisiteSiteContents>
        <Icon svgComponent={BuildingsSiteIcon} />
        <div>
          <Icon svgComponent={BuildingsIcon} width={8} height={11} />
          Site
        </div>
      </MultisiteSiteContents>
    </Container>
  );
};
MultisiteSite.displayName = 'MultisiteSite';

export default MultisiteSite;

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const MultisiteSiteContents = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: space-between;
  left: 8px;
  position: absolute;
  right: 8px;
  top: 0;
  & > div {
    align-items: center;
    display: flex;
    height: 100%;
  }
`;

const Background = () => (
  <svg
    width="95"
    height="23"
    viewBox="0 0 95 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H24.6457C25.5751 0 26.4521 0.430784 27.0201 1.1664L33.584 9.6664C34.4181 10.7465 34.4181 12.2535 33.584 13.3336L27.0201 21.8336C26.4521 22.5692 25.5751 23 24.6457 23H3C1.34315 23 0 21.6569 0 20V3Z"
      fill="#028193"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4873 23H92.0004C93.6573 23 95.0004 21.6569 95.0004 20V3C95.0004 1.34315 93.6573 0 92.0004 0H28.4873C29.4389 0 30.3342 0.451526 30.8998 1.21683L37.1824 9.71683C37.9657 10.7766 37.9657 12.2234 37.1824 13.2832L30.8998 21.7832C30.3342 22.5485 29.4389 23 28.4873 23Z"
      fill="#345580"
    />
  </svg>
);
