import { SearchTypeKeys } from 'store/search/types';
import {
  SortingTypeKeys,
  SortingAction,
  SortingDirection,
  SortingField,
} from 'store/searchSorting/types';

export const initialState = {
  direction: SortingDirection.DESC,
  field: SortingField.DEFAULT,
};

export default (state = initialState, action: SortingAction) => {
  switch (action.type) {
    case SortingTypeKeys.SET:
      return {
        ...state,
        direction: action.sorting.direction,
        field: action.sorting.field,
      };
    case SearchTypeKeys.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
