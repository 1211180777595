import React from 'react';
import styled from 'styled-components';
import { default as Button, ButtonSize } from 'components/ui/Button';
import { NavLink } from 'react-router-dom';
import { HelpIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import {
  blue,
  white,
  lightGray,
  aquaHaze,
  black,
  catskillWhite,
  heavyShadow,
  geyserApprox,
} from 'config/theme';
import { UserType } from 'store/auth/types';
import { store } from 'store';
import { push } from 'connected-react-router';
import { HasPermissions } from 'components/HasPermissions';
import { PlatformFeatures } from 'store/features/types';
import CurrencyUsdIcon from 'icons/CurrencyUsd';
import SettingIcon from 'icons/Settings';
import AccountEditIcon from 'icons/AccountEdit';
import AccountSettingIcon from 'icons/AccountSettings';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import { gray10 } from 'config/colors';

interface IAccountMenuProps {
  hidden: boolean;
  canUserAccessAdminMenu: boolean;
  name: string;
  userType: UserType;
  isResourcesFeatureEnabled: boolean;
  isBillingEnabled: boolean;
}

export default ({
  hidden,
  canUserAccessAdminMenu,
  name,
  userType,
  isResourcesFeatureEnabled,
  isBillingEnabled,
}: IAccountMenuProps) => (
  <UserDropDown hidden={hidden} role="menu" id="accountDropdownMenu">
    <Name>{name}</Name>
    <LinksContainer role="presentation">
      {canUserAccessAdminMenu && (
        <DropdownLink to="/admin/users" role="menuitem" name="admin">
          <SettingIcon fill={blue} /> Admin
        </DropdownLink>
      )}
    </LinksContainer>

    <HasPermissions forFeatures={[PlatformFeatures.SettingsPage]}>
      <LinksContainer role="presentation" id="account-settings-link-container">
        <DropdownLink
          to="/settings"
          role="menuitem"
          id="account-settings-menu-link"
        >
          <AccountSettingIcon fill={blue} /> Account Settings
        </DropdownLink>
      </LinksContainer>
    </HasPermissions>

    {userType === UserType.External && (
      <LinksContainer role="presentation">
        <DropdownLink to="/customer/view-profile" role="menuitem">
          <AccountEditIcon fill={blue} /> Profile View &amp; Edit
        </DropdownLink>
      </LinksContainer>
    )}

    {userType === UserType.External && isBillingEnabled && (
      <LinksContainer role="presentation" id="billing-payments-link-container">
        <DropdownLink
          to="/billing"
          role="menuitem"
          name="BillingPayments"
          id="billing-payments-menu-link"
        >
          <CurrencyUsdIcon fill={blue} /> Property Search
          <br /> Billing & Payments
        </DropdownLink>
      </LinksContainer>
    )}
    {!isResourcesFeatureEnabled && (
      <LinksContainer role="presentation" id="help-link-container">
        <HelpLink
          id="help-link"
          onClick={() => redirectHelp(userType)}
          role="menuitem"
          name="help"
        >
          <HelpIcon /> Help
        </HelpLink>
      </LinksContainer>
    )}
    <LinksContainer role="presentation" id="logout-button-container">
      <LogoutWrapper>
        <LogoutButton
          id="logout-button"
          size={ButtonSize.LG}
          onClick={() => handleLogoutClick(userType)}
        >
          Log Out
        </LogoutButton>
      </LogoutWrapper>
    </LinksContainer>
  </UserDropDown>
);

const handleLogoutClick = (userType: UserType) => {
  if (userType === UserType.Internal) {
    store.dispatch(push('/employee/signout'));
  } else {
    store.dispatch(push('/customer/signout'));
  }
};

const redirectHelp = (userType) => {
  // allow the new window to be resizable in <=IE11
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);
  let specs = 'noopener, noreferrer';
  if (isIE) {
    specs += ' scrollbars, resizable, height=800, width=1200';
  }
  const isInternal = userType === UserType.Internal;
  const internalUrl = 'http://iss.firstam.net/teachme/commercial/';
  const externalUrl = `${window.location.origin}/help`;
  const url = isInternal ? internalUrl : externalUrl;
  const win = window.open(url, 'windowOpenTab', specs);
  if (isInternal && win) {
    win.focus();
  }
};

const UserDropDown = styled.ul`
  margin: 0;
  padding: 0;
  background: ${white};
  border-radius: 3px;
  box-shadow: ${heavyShadow};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  position: absolute;
  right: -18px;
  text-align: left;
  top: 56px;
  width: 300px;
  z-index: 10;

  &:after,
  &:before {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${white};
    border-width: 12px;
    margin-left: -12px;
  }
`;

const LogoutWrapper = styled.div`
  padding: 16px;
`;
const LogoutButton = styled(Button)`
  width: 100%;
`;

const Name = styled.li`
  ${SMALL_BODY_MEDIUM}
  align-items: center;
  background: ${catskillWhite};
  color: ${gray10};
  display: flex;
  height: 43px;
  padding: 0 25px;
`;

const DropdownLink = styled(NavLink)<{ name?: string }>`
  color: ${black};
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 12px 16px;
  text-decoration: none;
  height: 64px;
  &:hover {
    color: ${black};
    background-color: ${aquaHaze};
    text-decoration: none;
  }
  &:active {
    background-color: ${geyserApprox};
  }
  & > svg {
    margin-right: 20px;
  }
`;
DropdownLink.displayName = 'DropdownLink';

const HelpLink = DropdownLink.withComponent('a');

const LinksContainer = styled.li`
  list-style-type: none;
  border-top: 1px solid ${lightGray};
  &:first-child {
    border-top: 0;
  }
`;
