/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_LandUseTypes } from '../models/Workspace_Common_Models_LandUseTypes';
import type { Workspace_Common_Models_PropertyProductUsage } from '../models/Workspace_Common_Models_PropertyProductUsage';
import type { Workspace_Common_Models_StateCounty } from '../models/Workspace_Common_Models_StateCounty';
import type { Workspace_Common_Requests_PropertySearchRequest } from '../models/Workspace_Common_Requests_PropertySearchRequest';
import type { Workspace_Common_Requests_ReportGenerationRequest } from '../models/Workspace_Common_Requests_ReportGenerationRequest';
import type { Workspace_Common_Responses_PropertyProductResponse } from '../models/Workspace_Common_Responses_PropertyProductResponse';
import type { Workspace_Common_Responses_PropertySearchResponse } from '../models/Workspace_Common_Responses_PropertySearchResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PropertiesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the properties detail from Mesh API. (Auth)
   * List of properties(PropertyDetails) will be returned empty for both 'No close match to show' and Search results are more than 999'.
   * @returns Workspace_Common_Responses_PropertySearchResponse Property Details fetched successfully
   * @throws ApiError
   */
  public searchProperty({
    page = 1,
    size = 999,
    requestBody,
  }: {
    /**
     * a page number
     */
    page?: number,
    /**
     * items per page
     */
    size?: number,
    /**
     * request parameters to fetch property details
     */
    requestBody?: Workspace_Common_Requests_PropertySearchRequest,
  }): CancelablePromise<Workspace_Common_Responses_PropertySearchResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/properties',
      query: {
        'page': page,
        'size': size,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Property not found`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * @deprecated
   * Gets the property products and acknowledgment status as per the payment policy. (Auth)
   * @returns Workspace_Common_Responses_PropertyProductResponse Property Products fetched successfully
   * @throws ApiError
   */
  public getPropertyProducts({
    state,
    apn,
    zipCode,
  }: {
    state: string,
    apn: string,
    zipCode: string,
  }): CancelablePromise<Workspace_Common_Responses_PropertyProductResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/properties/{state}/{apn}/{zipCode}/property-products',
      path: {
        'state': state,
        'apn': apn,
        'zipCode': zipCode,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Property not found`,
      },
    });
  }

  /**
   * Gets the property products and acknowledgment status as per the payment policy. (Auth)
   * @returns Workspace_Common_Responses_PropertyProductResponse Property Products fetched successfully
   * @throws ApiError
   */
  public getPropertyProductsByPropertyId({
    state,
    propertyId,
  }: {
    state: string,
    propertyId: number,
  }): CancelablePromise<Workspace_Common_Responses_PropertyProductResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/properties/{state}/{propertyId}/products',
      path: {
        'state': state,
        'propertyId': propertyId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Property not found`,
      },
    });
  }

  /**
   * Gets the updated Product Allowance from DB and Cached flag. (Auth)
   * @returns Workspace_Common_Models_PropertyProductUsage Product Allowance received successfully
   * @throws ApiError
   */
  public getPropertyProductUsage({
    requestBody,
  }: {
    requestBody?: Workspace_Common_Requests_ReportGenerationRequest,
  }): CancelablePromise<Array<Workspace_Common_Models_PropertyProductUsage>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/properties/property-product-usage',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Report usage details not found`,
      },
    });
  }

  /**
   * Get ExportCSV pricing and usage details (Auth)
   * @returns Workspace_Common_Responses_PropertyProductResponse Success
   * @returns any Ok
   * @throws ApiError
   */
  public exportProductDetails({
    requestBody,
  }: {
    requestBody?: Array<string> | null,
  }): CancelablePromise<Workspace_Common_Responses_PropertyProductResponse | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/properties/export-product-detail',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get RecodedDoc pricing and usage details (Auth)
   * @returns Workspace_Common_Responses_PropertyProductResponse Recorded doc details fetched successfully
   * @throws ApiError
   */
  public recordedDocProductDetails({
    state,
  }: {
    state: string,
  }): CancelablePromise<Workspace_Common_Responses_PropertyProductResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/properties/recordedDoc-product-detail/{state}',
      path: {
        'state': state,
      },
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Gets the list of US States and counties. (Auth)
   * @returns Workspace_Common_Models_StateCounty States and Counties fetched successfully
   * @throws ApiError
   */
  public getStateCounty(): CancelablePromise<Array<Workspace_Common_Models_StateCounty>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/properties/state-counties',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Gets the list of Land Use Types with DT Codes (Auth)
   * @returns Workspace_Common_Models_LandUseTypes List of Landuse types
   * @throws ApiError
   */
  public getLandUseTypes(): CancelablePromise<Array<Workspace_Common_Models_LandUseTypes>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/properties/landusetypes',
      errors: {
        401: `Unauthorized`,
        404: `LandUseTypes not found`,
      },
    });
  }

}
