import { useState, useEffect } from 'react';
import workspaceAPI, { workspaceAPIv2 } from 'services/workspaceAPI';

/** Defines the interface for the request options. Customize the request */
export interface IRequestOptions<
  TData = any,
  TError = any,
  TRequestData = any
> {
  url: string;
  data?: TRequestData;
  autoFire?: boolean;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  apiVersion?: 'v1' | 'v2';
  onError?: (error: TError, requestData?: TRequestData) => void;
  onSuccess?: (data: TData) => void;
  shouldMakeRequest?: boolean;
}

/** Defines the interface for the hook return value */
interface IBaseResult<TData = any, TError = any> {
  isLoading: boolean;
  isIdle: boolean;
  error?: TError;
  data?: TData;
}

export interface IReturn<TData = any, TError = any, TRequestData = any>
  extends IBaseResult<TData, TError> {
  execute: (config?: {
    requestUrl?: string;
    requestData?: TRequestData;
  }) => Promise<void>;
  clear: () => void;
  isSuccess: boolean;
  isError: boolean;
}

/**
 * @deprecated Refer to `useBaseRequest` that defines base for useQuery and useMutation that supports usage of the generated workspace API client code
 * Send a HTTP request using react hooks. Uses workspaceAPI to send out the request
 */
function useAPIRequest<TData = any, TError = any, TRequestData = any>({
  autoFire = true,
  data,
  url,
  onError,
  onSuccess,
  method = 'GET',
  apiVersion = 'v1',
  shouldMakeRequest = true,
}: IRequestOptions<TData, TError, TRequestData>): IReturn<
  TData,
  TError,
  TRequestData
> {
  const [queryResult, setQueryResult] = useState<
    Partial<IBaseResult<TData, TError>>
  >({
    isIdle: !autoFire as boolean, //if not auto-fire, then we are in idle state till the request is executed
    isLoading: autoFire, //if on auto-fire, then we are loading from the start.
  });

  useEffect(() => {
    if (autoFire) {
      // auto-fire
      executeRequest();
    }
  }, [autoFire]);

  const updateQueryResult = (updatedValues: Partial<IBaseResult>) => {
    setQueryResult((queryResult) => ({ ...queryResult, ...updatedValues }));
  };

  const clearState = () => {
    updateQueryResult({
      isLoading: false,
      isIdle: true,
      error: null,
      data: null,
    });
  };

  const getAPIRequest = (url: string, requestData: any) => {
    const apiService = apiVersion === 'v1' ? workspaceAPI : workspaceAPIv2;

    switch (method) {
      case 'GET':
        return apiService.get(url, requestData ? { data: requestData } : {});
      case 'POST':
        return apiService.post(url, requestData);
      case 'PUT':
        return apiService.put(url, requestData);
      case 'PATCH':
        return apiService.patch(url, requestData);
      case 'DELETE':
        return apiService.delete(url, requestData);
    }
  };

  /* Allows for overriding the url and data for delayed requests */
  const executeRequest = async (config?: {
    requestUrl?: string;
    requestData?: TRequestData;
  }) => {
    if (!shouldMakeRequest) return; // Ability to prevent malformed request
    const { requestUrl, requestData } = config || {};
    updateQueryResult({
      isIdle: false,
      isLoading: true,
      data: null,
      error: null,
    });
    try {
      const result = await getAPIRequest(
        requestUrl || url,
        requestData || data
      );
      updateQueryResult({ isLoading: false, data: result.data, error: null });
      onSuccess && onSuccess(result.data);
    } catch (error) {
      updateQueryResult({ isLoading: false, data: null, error });
      onError && onError(error, requestData || data);
    }
  };

  return {
    ...queryResult,
    execute: executeRequest,
    clear: clearState,
    isSuccess: Boolean(queryResult.data),
    isError: Boolean(queryResult.error),
    isLoading: Boolean(queryResult.isLoading),
    isIdle: Boolean(queryResult.isIdle),
  };
}

export default useAPIRequest;
