/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Disbursements_AddedDisbursementViewModel } from '../models/Workspace_API_ViewModels_Disbursements_AddedDisbursementViewModel';
import type { Workspace_API_ViewModels_Disbursements_AdjustDisbursementViewModel } from '../models/Workspace_API_ViewModels_Disbursements_AdjustDisbursementViewModel';
import type { Workspace_API_ViewModels_Disbursements_BuyerSellerDisbursementViewModel } from '../models/Workspace_API_ViewModels_Disbursements_BuyerSellerDisbursementViewModel';
import type { Workspace_API_ViewModels_Disbursements_DisbursementDetailViewModel } from '../models/Workspace_API_ViewModels_Disbursements_DisbursementDetailViewModel';
import type { Workspace_API_ViewModels_Disbursements_DisbursementHistoryListViewModel } from '../models/Workspace_API_ViewModels_Disbursements_DisbursementHistoryListViewModel';
import type { Workspace_API_ViewModels_Disbursements_DisbursementSummaryViewModel } from '../models/Workspace_API_ViewModels_Disbursements_DisbursementSummaryViewModel';
import type { Workspace_API_ViewModels_Disbursements_DisbursementsViewModel } from '../models/Workspace_API_ViewModels_Disbursements_DisbursementsViewModel';
import type { Workspace_API_ViewModels_Disbursements_HoldDisbursementInformationViewModel } from '../models/Workspace_API_ViewModels_Disbursements_HoldDisbursementInformationViewModel';
import type { Workspace_API_ViewModels_Disbursements_IssueBulkDisbursementsViewModel } from '../models/Workspace_API_ViewModels_Disbursements_IssueBulkDisbursementsViewModel';
import type { Workspace_API_ViewModels_Disbursements_IssuedCheckDisbursementsDeliveryStatusViewModel } from '../models/Workspace_API_ViewModels_Disbursements_IssuedCheckDisbursementsDeliveryStatusViewModel';
import type { Workspace_API_ViewModels_Disbursements_IssuedDisbursementsViewModel } from '../models/Workspace_API_ViewModels_Disbursements_IssuedDisbursementsViewModel';
import type { Workspace_API_ViewModels_Disbursements_ReleaseDisbursementHoldViewModel } from '../models/Workspace_API_ViewModels_Disbursements_ReleaseDisbursementHoldViewModel';
import type { Workspace_API_ViewModels_Disbursements_TransmittedWireDetailViewModel } from '../models/Workspace_API_ViewModels_Disbursements_TransmittedWireDetailViewModel';
import type { Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel } from '../models/Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel';
import type { Workspace_API_ViewModels_Disbursements_UpdatedDisbursementViewModel } from '../models/Workspace_API_ViewModels_Disbursements_UpdatedDisbursementViewModel';
import type { Workspace_API_ViewModels_Disbursements_UpdateDisbursementHoldViewModel } from '../models/Workspace_API_ViewModels_Disbursements_UpdateDisbursementHoldViewModel';
import type { Workspace_API_ViewModels_Disbursements_UpdateDisbursementViewModel } from '../models/Workspace_API_ViewModels_Disbursements_UpdateDisbursementViewModel';
import type { Workspace_API_ViewModels_FeeTransfers_FeeTransferDisbursementViewModel } from '../models/Workspace_API_ViewModels_FeeTransfers_FeeTransferDisbursementViewModel';
import type { Workspace_API_ViewModels_FeeTransfers_IssueFeeTransferViewModel } from '../models/Workspace_API_ViewModels_FeeTransfers_IssueFeeTransferViewModel';
import type { Workspace_Common_Responses_OrderDisbursement } from '../models/Workspace_Common_Responses_OrderDisbursement';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderDisbursementsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all disbursements for an order (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_DisbursementsViewModel Order has disbursements
   * @throws ApiError
   */
  public getDisbursementsAsync({
    orderId,
    siteFileId,
    groupId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_DisbursementsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements',
      path: {
        'orderId': orderId,
      },
      query: {
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get details of the particular disbursement (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_DisbursementDetailViewModel Disbursement details are available
   * @throws ApiError
   */
  public getDisbursementDetailsAsync({
    orderId,
    fileProcessId,
    disbursementId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * A disbursement File Process Id
     */
    fileProcessId: number,
    /**
     * The Id of the disbursement. Optional, used when it's a reliable positive value.
     */
    disbursementId?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_DisbursementDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order or Disbursement Not Found`,
      },
    });
  }

  /**
   * Updates disbursement in FAST/Eclipse (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_UpdatedDisbursementViewModel Disbursement was updated
   * @throws ApiError
   */
  public updateDisbursementAsync({
    orderId,
    fileProcessId,
    requestBody,
    disbursementId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * An Id of the concrete disbursement (for example, Miscellaneous Disbursement Id)
     */
    fileProcessId: number,
    /**
     * The object containing all information needed to update the Disbursement.
     */
    requestBody: Workspace_API_ViewModels_Disbursements_UpdateDisbursementViewModel,
    /**
     * Optional Disbursement Id, needed in certain circumstances
     */
    disbursementId?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_UpdatedDisbursementViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Get active disbursements for an order (Auth)
   * @returns Workspace_Common_Responses_OrderDisbursement Order has active disbursements
   * @throws ApiError
   */
  public getActiveDisbursementsAsync({
    orderId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_OrderDisbursement>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/_active',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User type doesn't have access or user doesn't have knox permission`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Adds disbursement in the Eclipse/FAST, which results in disbursement having positive Id (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_AddedDisbursementViewModel Success
   * @throws ApiError
   */
  public addDisbursementAsync({
    orderId,
    fileProcessId,
    payeeId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the underlying disbursement charge
     */
    fileProcessId: number,
    /**
     * Id of the disbursement payee
     */
    payeeId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_AddedDisbursementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_add',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'payeeId': payeeId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Approves an outgoing wire (Auth)
   * @returns void
   * @throws ApiError
   */
  public approveDisbursementAsync({
    orderId,
    disbursementId,
  }: {
    orderId: number,
    disbursementId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_approve',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        401: `User has not authenticated with 2FA`,
        403: `User does not have permission to approve wire`,
        404: `Order or disbursement Not Found`,
        409: `Last user cannot approve wire or wire is already approved`,
      },
    });
  }

  /**
   * Checks to see if a disbursement can be issued (Auth)
   * @returns any Disbursement can be issued
   * @throws ApiError
   */
  public canDisbursementBeIssuedAsync({
    orderId,
    disbursementId,
  }: {
    orderId: number,
    disbursementId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_canBeIssued',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        400: `Disbursement cannot be issued`,
        404: `Order or disbursement Not Found or External user`,
        409: `Eclipse Exception - Possible overdraft required`,
      },
    });
  }

  /**
   * Issue multiple Wire and/or Check Disbursements (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_IssuedDisbursementsViewModel Issued disbursements
   * @throws ApiError
   */
  public issueDisbursementsAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Issue Bulk Disbursement payload
     */
    requestBody?: Workspace_API_ViewModels_Disbursements_IssueBulkDisbursementsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_IssuedDisbursementsViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/disbursements/_issue',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Disbursement cannot be issued`,
        401: `Unauthorized; Invalid sessions / token`,
        403: `Forbidden; Not an employee user, no Activity Rights for issuing`,
        404: `Order not found`,
        409: `Overdraft required`,
        422: `Invalid overdraft details`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Fetch bulk issued check disbursements status (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_IssuedCheckDisbursementsDeliveryStatusViewModel Status was successfully fetched from eclipse
   * @throws ApiError
   */
  public fetchIssueCheckDisbursementsStatusesAsync({
    orderId,
    disbursementIds,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * Check Disbursements Ids
     */
    disbursementIds?: Array<number> | null,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_IssuedCheckDisbursementsDeliveryStatusViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/_check-delivery-status',
      path: {
        'orderId': orderId,
      },
      query: {
        'disbursementIds': disbursementIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated user`,
        403: `External user`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Print bulk issued check disbursements (Auth)
   * @returns any Status was successfully fetched from eclipse
   * @throws ApiError
   */
  public printIssuedCheckDisbursementsAsync({
    orderId,
    disbursementIds,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * Check Disbursements Ids
     */
    disbursementIds?: Array<number> | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/_check',
      path: {
        'orderId': orderId,
      },
      query: {
        'disbursementIds': disbursementIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated user`,
        403: `External user`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Verifies the current User is able to approve the Disbursement. (Auth)
   * @returns any User is approved (disbursement can be issued)
   * @throws ApiError
   */
  public verifyDisbursementApprovalAsync({
    orderId,
    disbursementId,
  }: {
    orderId: number,
    disbursementId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_canBeApproved',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        400: `Current user same as approver of the wire or disbursement is a check`,
        403: `User does not have permission to approve wire`,
        404: `Order or disbursement Not Found`,
        409: `Last user cannot approve wire or wire is already approved`,
      },
    });
  }

  /**
   * Hold a disbursement (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateHoldDisbursementAsync({
    orderId,
    fileProcessId,
    requestBody,
    disbursementId,
  }: {
    orderId: number,
    /**
     * A disbursement File Process Id
     */
    fileProcessId: number,
    requestBody: Workspace_API_ViewModels_Disbursements_UpdateDisbursementHoldViewModel,
    /**
     * The Id of the disbursement. Optional, used when it's a reliable positive value.
     */
    disbursementId?: number | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_hold',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request.  Until date must be a future date`,
        401: `User is not authorized to hold disbursements`,
        404: `Order or disbursement Not Found or External user`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Hold a disbursement (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_HoldDisbursementInformationViewModel
   * @throws ApiError
   */
  public getHoldDisbursementAsync({
    orderId,
    disbursementId,
  }: {
    orderId: number,
    disbursementId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_HoldDisbursementInformationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_hold',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        400: `Bad Request`,
        401: `User is not authorized to hold disbursements`,
        404: `Order or disbursement Not Found or External user`,
      },
    });
  }

  /**
   * Release disbursement hold (Auth)
   * @returns void
   * @throws ApiError
   */
  public releaseHoldDisbursementAsync({
    orderId,
    fileProcessId,
    requestBody,
    disbursementId,
  }: {
    orderId: number,
    /**
     * A disbursement File Process Id
     */
    fileProcessId: number,
    requestBody: Workspace_API_ViewModels_Disbursements_ReleaseDisbursementHoldViewModel,
    /**
     * The Id of the disbursement. Optional, used when it's a reliable positive value.
     */
    disbursementId?: number | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_release_hold',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `User is not authorized to hold disbursements`,
        404: `Order or disbursement Not Found or External user`,
      },
    });
  }

  /**
   * Transmit outgoing wire (Auth)
   * @returns any Wire Transmitted – Transmit accepted (No Content Returned)
   * @throws ApiError
   */
  public transmitOutgoingWireAsync({
    orderId,
    disbursementId,
    fundId,
  }: {
    orderId: number,
    disbursementId: number,
    fundId?: number | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_transmit',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      query: {
        'fundId': fundId,
      },
      errors: {
        400: `Disbursement not in Issued state`,
        403: `Checks are not allowed when`,
        404: `Not Found - order or disbursement was not found`,
        409: `Request could not be completed due to a conflict with the current state of the resource`,
      },
    });
  }

  /**
   * Checks if a check disbursement can be issued (Auth)
   * @returns any Check disbursement can be issued
   * @throws ApiError
   */
  public canCheckBeIssuedAsync({
    orderId,
    fileProcessId,
    disbursementId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * A file process id
     */
    fileProcessId: number,
    /**
     * Optional disbursement id
     */
    disbursementId?: number | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_canCheckBeIssued',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      errors: {
        400: `Disbursement cannot be issued`,
        401: `Unauthenticated user`,
        404: `Order or disbursement Not Found or External user`,
      },
    });
  }

  /**
   * Adjust disbursement (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public adjustDisbursementAsync({
    orderId,
    disbursementId,
    requestBody,
  }: {
    orderId: number,
    disbursementId: number,
    requestBody: Workspace_API_ViewModels_Disbursements_AdjustDisbursementViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_adjust',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        403: `Disbursement status doesnt allow adjustment.`,
        404: `Not Found - order or disbursement was not found`,
        409: `Eclipse Exception - Possible overdraft required`,
      },
    });
  }

  /**
   * Get the history of activities of a disbursement (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_DisbursementHistoryListViewModel Disbursement has activities
   * @throws ApiError
   */
  public getDisbursementHistoryAsync({
    orderId,
    fileProcessId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * A file process Id
     */
    fileProcessId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_DisbursementHistoryListViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_history',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User type doesn't have access or user doesn't have knox permission`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Fetch transmitted wire details (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_TransmittedWireDetailViewModel Details was successfully fetched
   * @throws ApiError
   */
  public fetchTransmittedWireDetailAsync({
    orderId,
    disbursementId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * A disbursement id
     */
    disbursementId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_TransmittedWireDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/transmission-detail',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Order or wire details Not Found or External user`,
      },
    });
  }

  /**
   * Evaluates if a disbursement can be transmitted (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel Status to identify whether it's possible to Transmit - Error message when not
   * @throws ApiError
   */
  public canBeTransmittedAsync({
    orderId,
    disbursementId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Disbursement identifier
     */
    disbursementId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/transmit-status',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        404: `Order/Disbursement Not Found or External user`,
        409: `Request could not be completed due to a conflict with the current state of the resource`,
      },
    });
  }

  /**
   * Gets the Disbursement Summary info for a specific order (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_DisbursementSummaryViewModel Disbursement Summary successfully retrieved
   * @throws ApiError
   */
  public getDisbursementSummaryAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_DisbursementSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Determines whether an order has any issued Buyer Refund and Seller Proceeds disbursements (Auth)
   * @returns boolean Information about issued Buyer Refund and Seller Proceeds disbursements
   * @throws ApiError
   */
  public issuedBuyerSellerDisbursementsExistAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Record<string, boolean>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/buyer-seller/issued',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Returns issue and split information for Buyer Refund and Seller Proceeds disbursements (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_BuyerSellerDisbursementViewModel Information about issued Buyer Refund and Seller Proceeds disbursements
   * @throws ApiError
   */
  public getBuyerSellerDisbursementsIssueSplitInfoAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Record<string, Workspace_API_ViewModels_Disbursements_BuyerSellerDisbursementViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/buyer-seller/issued-split',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Issue a fee transfer. (Auth)
   * @returns Workspace_API_ViewModels_FeeTransfers_FeeTransferDisbursementViewModel The fee transfer was issued successfully.
   * @throws ApiError
   */
  public issueFeeTransfer({
    orderId,
    requestBody,
  }: {
    /**
     * Order id.
     */
    orderId: number,
    /**
     * Issue fee transfer details.
     */
    requestBody?: Workspace_API_ViewModels_FeeTransfers_IssueFeeTransferViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_FeeTransfers_FeeTransferDisbursementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/disbursements/_issueFeeTransfer',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token.`,
        403: `External user or user cannot access this order or can not issue disbursement.`,
        404: `Order not found.`,
        409: `Overdraft required.`,
      },
    });
  }

}
