import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { spindle, white } from 'config/theme';
import { IconButton } from './LinkIcon';
import { ChevronDownIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import styled from 'styled-components';
import { DropdownContainer } from './Common';
import AccountingDropdown from './AccountingDropdown';
import { useClickOutside } from '@theorem/react';
import { usePrevious } from 'hooks/usePrevious';
import Briefcase from 'icons/Briefcase';

const ESCAPE_KEY_CODE = 27;

export default withRouter(function AccountingNavDropdown(
  props: RouteComponentProps
) {
  const [dropdownHidden, setDropdownHidden] = useState(true);
  const previousPath = usePrevious(props.location.pathname);
  useEffect(() => {
    if (previousPath && previousPath !== props.location.pathname) {
      closeDropdown();
    }
  }, [props.location.pathname, previousPath]);

  const toggleDropdown = () => {
    setDropdownHidden(!dropdownHidden);
  };

  const closeDropdown = () => {
    setDropdownHidden(true);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === ESCAPE_KEY_CODE) {
      closeDropdown();
    }
  };

  const outsideRef = useClickOutside(closeDropdown);

  return (
    <Container
      onKeyDown={handleOnKeyDown}
      aria-labelledby="accounting-dropdown-menu"
      id="dashboard__office_view_icon"
      ref={outsideRef}
    >
      <DropdownHandle
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={!dropdownHidden}
        aria-controls="dropdown-menu-list"
        tabIndex={0}
        id="dashboard__office_view_dropdown_icon"
        role="menuitem"
      >
        <IconButton
          icon={
            <Briefcase
              style={{ height: '20px', width: '20px', marginLeft: '-12px' }}
              fill={white}
            />
          }
          label={
            <React.Fragment>
              Office View <ChevronDownIcon style={{ marginLeft: '6px' }} />
            </React.Fragment>
          }
        />
      </DropdownHandle>

      {!dropdownHidden && (
        <DropdownContainer style={{ width: 300 }}>
          <AccountingDropdown closeDropdown={() => setDropdownHidden(true)} />
        </DropdownContainer>
      )}
    </Container>
  );
});

const Container = styled.div`
  margin-right: 20px;
  position: relative;
`;

const DropdownHandle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${spindle};
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${white};
    fill: ${white};
  }
`;
