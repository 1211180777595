import { Content, DisplaySize, Section } from '.';
import { FlexBox, Column } from 'components/ui/Box';
import { gray8, primaryBlue8 } from 'config/colors';
import { H1_MEDIUM, H2_BOLD, H2_BOOK } from 'config/typography';
import { StyledButton } from 'components/ui/MButton';
import bgLinesTop from 'images/landing-page/Desktop-Lines-Top.png';
import styled from 'styled-components';

export const HeroSection = styled(Section)`
  background-image: url(${bgLinesTop});
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-bottom: 84px;
`;

export const HeroContent = styled(Content).attrs({
  gap: 'xlarge',
  id: 'homePage_hero',
})`
  transition-duration: 0.3s;
  transition-property: gap, padding-left, padding-right;
  transition-timing-function: ease-out;
  will-change: gap, padding-left, padding-right;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    gap: 24px;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const HeroDetails = styled(Column).attrs({
  alignContent: 'start',
  full: true,
  gap: 'xlarge',
  justify: 'between',
})`
  display: grid;
  flex-grow: 1;
  justify-items: flex-start;
  transition-duration: 0.3s;
  transition-property: gap, justify-items;
  transition-timing-function: ease-out;
  will-change: gap, justify-items;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    gap: 24px;
    justify-items: stretch;
  }

  & > ${FlexBox} {
    max-width: 580px;
    transition-duration: 0.3s;
    transition-property: max-width, padding-left, padding-right;
    transition-timing-function: ease-out;
    will-change: max-width, padding-left, padding-right;

    @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
      max-width: 388px;
    }

    @media only screen and (max-width: ${DisplaySize.TABLET}px) {
      max-width: 321px;
    }

    @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
      max-width: none;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  & > ${StyledButton} {
    transition-duration: 0.3s;
    transition-property: grid-column, grid-row, margin-left, margin-right;
    transition-timing-function: ease-out;
    will-change: grid-column, grid-row, margin-left, margin-right;

    @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
      grid-column: 1;
      grid-row: 3;
      margin-left: 24px;
      margin-right: 24px;
    }
  }
`;

export const HeroH1 = styled.h1`
  ${H1_MEDIUM}
  color: ${primaryBlue8};
  font-size: 68px;
  line-height: 80px;
  margin-bottom: 16px;
  transition-duration: 0.3s;
  transition-property: font-size, line-height, margin, padding-left,
    padding-right;
  transition-timing-function: ease-out;
  will-change: font-size, line-height, margin, padding-left, padding-right;

  strong {
    font-weight: 900;
  }

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    font-size: 48px;
    line-height: 60px;
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    font-size: 38px;
    line-height: 60px;
  }

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    font-size: 32px;
    line-height: 35px;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const HeroH2 = styled.h2`
  ${H2_BOLD}
  color: ${primaryBlue8};
  font-size: 46px;
  line-height: 42px;
  max-width: 880px;
  transition-duration: 0.3s;
  transition-property: font-size, line-height;
  transition-timing-function: ease-out;
  will-change: font-size, line-height;

  sup {
    font-size: 11px;
    top: -1.5em;
  }

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    font-size: 26px;
    line-height: 32px;
  }

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const HeroP = styled.p`
  ${H2_BOOK}
  color: ${gray8};
  line-height: 40px;
  transition-duration: 0.3s;
  transition-property: font-size, line-height;
  transition-timing-function: ease-out;
  will-change: font-size, line-height;

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    font-size: 18.5px;
    line-height: 25px;
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
