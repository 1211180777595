import { IProductsState, ProductActions, ProductsTypeKeys } from './types';

export const initialState: IProductsState = {
  products: [],
  fetchingProducts: false,
};

export default (
  state = initialState,
  action: ProductActions
): IProductsState => {
  switch (action.type) {
    case ProductsTypeKeys.FETCH_PRODUCTS:
      return {
        ...state,
        fetchingProducts: true,
      };
    case ProductsTypeKeys.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        fetchingProducts: false,
        products: action.products,
      };
    case ProductsTypeKeys.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        fetchingProducts: false,
      };
    default:
      return state;
  }
};
