import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M15.0864 10.9091C18.0613 10.9091 24 12.36 24 15.2727V17.4545H6.1727V15.2727C6.1727 12.36 12.1114 10.9091 15.0864 10.9091ZM15.0864 8.72727C13.9043 8.72727 12.7707 8.26753 11.9349 7.44919C11.0991 6.63085 10.6295 5.52094 10.6295 4.36364C10.6295 3.20633 11.0991 2.09642 11.9349 1.27808C12.7707 0.459739 13.9043 0 15.0864 0C16.2684 0 17.402 0.459739 18.2378 1.27808C19.0736 2.09642 19.5432 3.20633 19.5432 4.36364C19.5432 5.52094 19.0736 6.63085 18.2378 7.44919C17.402 8.26753 16.2684 8.72727 15.0864 8.72727ZM3.94429 6.09818L6.30641 3.77455L7.88858 5.32364L5.51532 7.63636L7.88858 9.94909L6.30641 11.4982L3.94429 9.17455L1.58217 11.4982L0 9.94909L2.37326 7.63636L0 5.32364L1.58217 3.77455L3.94429 6.09818Z"
      fill={shuttleGrey}
    />
  </svg>
);
