// Adopted and adjusted from: gist.github.com/markhowellsmead/7fa5ad4a7d2eb6245cae13942da78b6a
// tslint rules do not allow for certain things happening in this file (eg. use of `arguments`, regular functions)
// given the cause of renaming this file from .js to .ts was only for keeping the codebase consistent
// and in order to avoid potential issues from transpiled code, tslint will be disabled from this file.
/* tslint:disable */
(function (proto) {
  if (!proto.find) {
    proto.find = function (predicate) {
      /* istanbul ignore next */
      if (this == null) {
        throw new TypeError(
          'Array.prototype.find called on `null` or `undefined`'
        );
      }

      if (typeof predicate !== 'function') {
        throw new TypeError('testing function mock is not a function');
      }

      const list = Object(this);
      const length = list.length >>> 0;
      const thisArg = arguments[1];
      let value;

      for (let i = 0; i < length; i++) {
        value = list[i];
        if (predicate.call(thisArg, value, i, list)) {
          return value;
        }
      }
      return undefined;
    };
  }
})(Array.prototype);
