import React from 'react';
import styled from 'styled-components';
import { blue, white, red, black } from 'config/theme';
import { AppLogoIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import GooglePlayLink from 'components/MobileStoreLink/GooglePlayLink';
import AppStoreLink from 'components/MobileStoreLink/AppStoreLink';

const MobileOnly: React.FC = () => {
  return (
    <Container>
      <ContentBlock>
        <AppLogoIcon />
        <ContentBox>
          <h1>Get the ClarityFirst Mobile App</h1>
          <StoreLinks>
            <AppStoreLink />
            <GooglePlayLink />
          </StoreLinks>
        </ContentBox>
      </ContentBlock>
    </Container>
  );
};
export default MobileOnly;

const Container = styled.div`
  background: ${blue};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentBlock = styled.div`
  text-align: center;
  max-width: 510px;
  margin: 0 16px;
  width: 100%;
`;

const ContentBox = styled.div`
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  background: ${white};
  border-top: 4px solid ${red};
  margin-top: 64px;
  padding: 24px 16px;
  h1 {
    color: ${blue};
    font-size: 32px;
  }
  p {
    font-size: 20px;
    color: ${black};
    margin-bottom: 14px;
  }
  p + p {
    margin-bottom: 56px;
  }
`;

const StoreLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 68px;
  & > a {
    flex: 1;
  }
`;
