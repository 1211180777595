import React from 'react';
import ReactDOM from 'react-dom';
import StyledReactTooltip, {
  IStyledReactTooltipProps,
} from './StyledReactTooltip';
import { isDocumentDefined } from 'utils/browser';

let domDivNode: Element | DocumentFragment;

if (isDocumentDefined()) {
  domDivNode = document.createElement('div');
  document.body.appendChild(domDivNode);
}

function ExternalReactBodyPortal({ children }) {
  return ReactDOM.createPortal(children, domDivNode);
}

// all tooltips will be rendered inside the portal [deals with various IE11 bugs with z-index/modal/rebuild]
function StyledReactTooltipPortal(props: IStyledReactTooltipProps) {
  return (
    <ExternalReactBodyPortal>
      <StyledReactTooltip {...props} />
    </ExternalReactBodyPortal>
  );
}

export default StyledReactTooltipPortal;
