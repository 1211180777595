import { IModalContentPaddingSize, IModalSize } from './types';

const ModalContentPadding = {
  [IModalContentPaddingSize.MD]: '24px',
  [IModalContentPaddingSize.LG]: '40px',
};

export const DEFAULT_PADDING_X = 30;

export const getPadding = (size) => {
  if (typeof size === 'number') {
    return ModalContentPadding[size];
  }
  return `35px ${DEFAULT_PADDING_X}px 30px`;
};

export function getContentPadding(size: IModalSize) {
  switch (size) {
    case IModalSize.MD:
      return '32px';
    case IModalSize.LG:
      return '40px';
    default:
      return '24px';
  }
}
