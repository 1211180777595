import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.12195 9.81818V7.63636H17.7073V9.81818H9.12195ZM9.12195 16.3636V14.1818H13.4146V16.3636H9.12195ZM9.12195 3.27273V1.09091L22 1.09091V3.27273L9.12195 3.27273ZM4.82927 14.1818H7.5122L3.7561 18L0 14.1818H2.68293L2.68293 0H4.82927L4.82927 14.1818Z"
      fill={props.fill || blue}
    />
  </svg>
);
