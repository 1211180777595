import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledReactTooltip } from 'components/ToolTip';
import { truncate } from 'utils/text';

export interface ITruncateProps {
  /**
   * If content is more characters than this, it will be ellipsized ("stuff...").
   * Note that charLimit takes precedence over the `width` prop.
   */
  charLimit?: number;
  /**
   * text content
   */
  children: React.ReactNode;
  /**
   * tooltip content
   */
  tooltipContent?: React.ReactNode;
  /**
   * If true, the mouseover tooltip will be the CF-style tooltip with black background.
   * Otherwise it will be the standard browser-based tooltip that uses the `title` attribute.
   */
  useCFTooltip?: boolean;
  /**
   * If provided (and charLimit is not), content will be displayed in a container of this
   * width in px. Truncation status is determined based on whether or not the length of
   * the content exceeds the specified width in px.
   */
  width?: number;
  /**
   * tooltip delay (default 1000ms)
   */
  delay?: number;
}

export default function Truncate({
  children,
  tooltipContent,
  useCFTooltip,
  charLimit,
  width,
  delay,
}: ITruncateProps) {
  const [isTooLong, setIsTooLong] = useState(
    !!charLimit && typeof children === 'string'
      ? children.length > charLimit
      : false
  );
  const text = isTooLong ? truncate(children as string, charLimit) : children;

  // memoize so `id` doesn't change on every render
  const id = useMemo(
    () =>
      isTooLong && useCFTooltip
        ? // put some random digits here to prevent collision with other elements
          // on the page that may also be using this component
          `truncated-text-${Math.floor(Math.random() * 100000)}`
        : '',
    [isTooLong, useCFTooltip]
  );

  const textRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (typeof charLimit === 'number' || !width) return;
    if (!textRef.current) return;
    if (textRef.current.scrollWidth > width) setIsTooLong(true);
  }, [width]);

  // avoid meaningless attributes in rendered markup
  const spanProps: Record<string, any> = {};
  if (isTooLong) {
    if (useCFTooltip) {
      spanProps['data-for'] = id;
      spanProps['data-tip'] = true;
    } else {
      if (typeof children === 'string') spanProps['title'] = children;
    }
  }

  const delayShow = delay === undefined ? 1000 : delay;

  return (
    <>
      <Text
        {...spanProps}
        ref={textRef}
        width={typeof charLimit === 'number' ? undefined : width}
      >
        {text}
      </Text>
      {useCFTooltip && isTooLong && (
        <StyledReactTooltip
          content={tooltipContent || children}
          delayShow={delayShow}
          id={id}
          maxWidth="800px"
          multiline
        />
      )}
    </>
  );
}

const Text = styled.span.withConfig<{ children: React.ReactNode }>({
  shouldForwardProp: (prop: string) => prop !== 'width',
})<{ width?: number }>`
  ${({ width }) =>
    width
      ? `
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${width}px;
  `
      : ''};
`;
