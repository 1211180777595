import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M24 5.57718L10.2857 19L4 12.8479L5.61143 11.2707L10.2857 15.8345L22.3886 4L24 5.57718Z"
      fill={props.fill || white}
    />
  </svg>
);
