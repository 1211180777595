import React from 'react';
import styled from 'styled-components';
import Close from 'icons/Close';
import { Column, Row } from 'components/ui/Box';
import { IconButton } from 'components/ui/IconButton';
import { gray10, neutralGray7 } from 'config/colors';
import { SMALL_BODY_BOOK } from 'config/typography';

interface IAccordionSnackbarHeaderProps {
  label: string;
  subtext?: React.ReactNode | string;
  onClose?: () => void;
}

const AccordionSnackbarHeader = ({
  label,
  subtext,
  onClose,
}: IAccordionSnackbarHeaderProps) => {
  return (
    <Row justify="between" alignItems="center">
      <Column>
        <HeaderLabel>{label}</HeaderLabel>
        {subtext && subtext}
      </Column>
      {onClose && (
        <IconButton
          data-idx="AccordionSnackbar--Close"
          icon={StyledCloseIcon}
          style={{ padding: '8px', marginRight: '-16px' }}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
      )}
    </Row>
  );
};

const StyledCloseIcon = styled(Close)`
  width: 24px;
  height: 24px;
  fill: ${neutralGray7};
`;

const HeaderLabel = styled.h4`
  ${SMALL_BODY_BOOK}
  color: ${gray10}
`;

export default AccordionSnackbarHeader;
