/** This method helps to avoid SSR errors by checking window availability. */
// eslint-disable-next-line import/no-unused-modules
export const isWindowDefined = (): boolean => typeof window !== 'undefined';
export const isDocumentDefined = (): boolean => typeof document !== 'undefined';

export const isIE11 = (): boolean => {
  return (
    isWindowDefined() &&
    (window as any).MSInputMethodContext &&
    // @ts-ignore
    !!document.documentMode
  );
};

export const isIE10 = (): boolean => {
  return isWindowDefined() && navigator.appVersion.indexOf('MSIE 10') !== -1;
};

export function downloadBlob(blob: Blob, filename: string) {
  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = blobUrl;
    link.download = filename;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  }
}

// DOC: reference in https://stackoverflow.com/a/16245768
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: BlobPart[] = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const formatBytesToSize = (bytes, decimals = 2) => {
  const encodedByte = new TextEncoder().encode(bytes).length;
  if (!+encodedByte) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(encodedByte) / Math.log(k));

  return `${parseFloat((encodedByte / Math.pow(k, i)).toFixed(dm))} ${
    sizes[i]
  }`;
};
