import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 20C28 22.1217 27.1571 24.1566 25.6569 25.6569C24.1566 27.1571 22.1217 28 20 28C17.8783 28 15.8434 27.1571 14.3431 25.6569C12.8429 24.1566 12 22.1217 12 20C12 17.8783 12.8429 15.8434 14.3431 14.3431C15.8434 12.8429 17.8783 12 20 12C20.76 12 21.5 12.11 22.2 12.31L23.77 10.74C22.61 10.26 21.34 10 20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20H28ZM15.91 18.08L14.5 19.5L19 24L29 14L27.59 12.58L19 21.17L15.91 18.08Z"
      fill={props.fill || blue}
    />
  </svg>
);
