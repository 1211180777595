import styled from 'styled-components';
import { blue } from 'config/theme';
import ViewLoader, {
  IStyledViewLoaderProps,
  IViewLoaderProps,
} from 'components/ViewLoader';

interface IBlockLoaderProps {
  height?: number;
  width?: number;
}

const BlockLoader = styled<
  React.FunctionComponent<
    IViewLoaderProps & Omit<IStyledViewLoaderProps, 'height'>
  >
>(ViewLoader)<IBlockLoaderProps>`
  position: inherit;
  background: 0;
  & > p {
    color: ${blue};
    font-style: normal;
  }
  height: ${({ height }) => height || '100'}px;
  ${({ width }) => (width || width === 0 ? `width: ${width}px;` : '')}
`;

BlockLoader.displayName = 'BlockLoader';
export default BlockLoader;
