import React from 'react';
import { lightBlue } from 'config/theme';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6 0C0.712 0 0 0.702632 0 1.57895V9.47368C0 9.89245 0.168571 10.2941 0.468629 10.5902C0.768687 10.8863 1.17565 11.0526 1.6 11.0526H8V15L10.4 12.6316L12.8 15V11.0526H14.4C14.8243 11.0526 15.2313 10.8863 15.5314 10.5902C15.8314 10.2941 16 9.89245 16 9.47368V3.94737V2.36842V1.57895C16 1.16018 15.8314 0.758573 15.5314 0.462463C15.2313 0.166353 14.8243 0 14.4 0H11.2H1.6ZM8 1.57895L10.4 3.15789L12.8 1.57895V4.34211L15.2 5.52632L12.8 6.71053V9.47368L10.4 7.89474L8 9.47368V6.71053L5.6 5.52632L8 4.34211V1.57895ZM1.6 1.57895H5.6V3.15789H1.6V1.57895ZM1.6 4.73684H4V6.31579H1.6V4.73684ZM1.6 7.89474H5.6V9.47368H1.6V7.89474Z"
      fill={props.fill || lightBlue}
    />
  </svg>
);
