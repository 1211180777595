/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_CustomerSupport } from '../models/Workspace_Common_Requests_CustomerSupport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SupportRequestsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Takes the request for the customer support email and forward it to BGP to be sent (Auth)
   * @returns void
   * @throws ApiError
   */
  public submitSupportRequest({
    requestBody,
  }: {
    /**
     * Customer support request properties
     */
    requestBody: Workspace_Common_Requests_CustomerSupport,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/support-requests',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Missing or invalid auth token`,
        404: `Not an external user`,
        422: `Invalid model state`,
      },
    });
  }

}
