import React, { CSSProperties } from 'react';
import {
  IUiLoadingKeys,
  IUiModalKeys,
  IUiSetFooterStyleOverrideAction,
  IUiSetLoadingStateAction,
  IUiSetModalStateAction,
  IUiSetShowSidebarWindowAction,
  UiTypeKeys,
} from './types';
import {
  ToasterIntent,
  IToasterOptions,
  IToastOptions,
} from '../../components/Snackbar/types';

const {
  SET_FOOTER_STYLE,
  SET_LOADING_STATE,
  SET_MODAL_STATE,
  ADD_TOAST,
  REMOVE_TOAST,
  SHOW_SIDEBAR_WINDOW,
} = UiTypeKeys;

export const setLoadingStateAction = (
  loadingKey: IUiLoadingKeys,
  isLoading: boolean
): IUiSetLoadingStateAction => {
  return {
    type: SET_LOADING_STATE,
    loadingKey,
    isLoading,
  };
};

export const setFooterStyleOverrides = (
  style: CSSProperties
): IUiSetFooterStyleOverrideAction => ({
  type: SET_FOOTER_STYLE,
  ...style,
});

export const setShowSidebarWindow = (
  option: boolean = true
): IUiSetShowSidebarWindowAction => ({
  type: SHOW_SIDEBAR_WINDOW,
  payload: option,
});

export const setModalStateAction = (
  modalKey: IUiModalKeys,
  isOpen: boolean
): IUiSetModalStateAction => {
  return {
    type: SET_MODAL_STATE,
    modalKey,
    isOpen,
  };
};

export const addToast = (
  text: string | React.ReactNode,
  intent: ToasterIntent = 'info',
  options?: IToasterOptions | IToastOptions
) => (dispatch) => {
  dispatch({
    type: ADD_TOAST,
    payload: {
      text,
      intent,
      options,
    },
  });
};

export const removeToast = (idsToRemove: string[]) => (dispatch) => {
  dispatch({
    type: REMOVE_TOAST,
    payload: {
      idsToRemove,
    },
  });
};
