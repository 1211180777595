import React from 'react';
import styled from 'styled-components';
import {
  default as MaterialButton,
  ButtonProps,
} from '@material-ui/core/Button';
import { Spinner } from 'components/ui/Spinner/Spinner';
import {
  createColorStyling,
  createHeight,
  createPadding,
  createVariant,
  getLoadingTop,
} from './buttonUtilities';
import { BODY_BUTTON, SMALL_BODY_BUTTON } from 'config/typography';

export type ButtonVariants = 'primary' | 'secondary' | 'ghost' | 'link';
export type ButtonColors = 'blue' | 'white' | 'red';
export type ButtonSizes = 'large' | 'medium' | 'small' | 'x-small';

export type IButtonProps = Omit<
  ButtonProps,
  | 'TouchRippleProps'
  | 'color'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'focusRipple'
  | 'size'
  | 'variant'
> & {
  loading?: boolean;
  size?: ButtonSizes;
} & (
    | {
        variant?: ButtonVariants;
        color?: ButtonColors;
      }
    | {
        color: 'gray';
        variant: 'ghost';
      }
    | {
        color: 'red';
        variant: 'primary' | 'secondary' | 'ghost';
      }
  );

const Button = ({
  children,
  color = 'blue',
  disabled = false,
  loading = false,
  size = 'medium',
  variant = 'primary',
  ...rest
}: IButtonProps & { component?: any }) => {
  return (
    <StyledButton
      color={color}
      disableElevation
      disableFocusRipple
      disableRipple
      disabled={disabled || loading}
      loading={loading}
      originalVariant={variant}
      size={size}
      variant={createVariant(variant)}
      {...rest}
    >
      <span data-testid="mButtonContent" id="mButtonContent">
        {children}
      </span>
      {loading && <Spinner size={24} data-testid="loadingSpinner" />}
    </StyledButton>
  );
};

const PropsBlacklist = {
  color: true,
  loading: true,
  originalVariant: true,
  size: true,
};

export const StyledButton = styled(MaterialButton).withConfig<any>({
  shouldForwardProp: (prop: string) => !PropsBlacklist[prop],
})<IButtonProps>`
  font-family: Circular;
  border-radius: 3px;
  display: flex;
  flex-align: center;
  text-transform: none;

  ${({ size }) => createHeight(size)}
  ${({ size }) =>
    ['medium', 'large'].includes(size) ? BODY_BUTTON : SMALL_BODY_BUTTON}
  ${({ size, startIcon, endIcon }) =>
    createPadding(size, !!startIcon, !!endIcon)}
  ${({ originalVariant, color, loading }) =>
    createColorStyling(originalVariant, color, loading)}

  .spinner {
    margin: 0px;
  }

  .MuiButton-startIcon {
    margin-left: 0px;
  }

  .MuiButton-endIcon {
    margin-right: 0px;
  }

  .MuiButton-label {
    span {
      &[data-testid="mButtonContent"] {
        display: ${({ loading }) => (loading ? 'inherit' : 'contents')};

        &:first-child {
          opacity: ${({ loading }) => (loading ? 0 : 1)};
        }
      }

      &.spinner {
        position: absolute;
        ${({ size }) => getLoadingTop(size)};
      }
    }
  }
`;

export default Button;
