/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse } from '../models/Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AffiliateViewService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets all Affiliate Company belonging to the order (Auth)
   * @returns Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public getAffiliateCompaniesAsync({
    orderId,
  }: {
    /**
     * The Id of the current order
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_FeatureAreas_Affiliates_ViewModels_Responses_AffiliateCompanyResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/affiliate-companies',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid authorization header`,
        403: `External users do not have permission`,
        404: `Not Found`,
      },
    });
  }

}
