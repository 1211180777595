import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { pictonBlue, white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.1719" cy="16.8281" r="16" fill={pictonBlue} />
    <path
      d="M19.5712 8.75781H21.2697V10.3714H22.1189C22.5694 10.3714 23.0014 10.5413 23.3199 10.8439C23.6384 11.1465 23.8174 11.557 23.8174 11.9849V23.2797C23.8174 23.7076 23.6384 24.118 23.3199 24.4206C23.0014 24.7232 22.5694 24.8932 22.1189 24.8932H10.2297C9.28706 24.8932 8.53125 24.1671 8.53125 23.2797V11.9849C8.53125 11.0894 9.28706 10.3714 10.2297 10.3714H11.0789V8.75781H12.7774V10.3714H19.5712V8.75781ZM17.194 13.8745H15.1537V16.782H12.0932V18.7203H15.1537V21.6277H17.194V18.7203H20.2545V16.782H17.194V13.8745Z"
      fill={props.fill || white}
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
