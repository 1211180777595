import { IFileOffices } from 'store/files/types';
import { IApplicationState } from '../index';

export enum UserAcceptedActivityRights {
  CurrentlyNotSupported = 'CurrentlyNotSupported',
}

export enum ActivityRightValue {
  Admin = 'Admin',
  UserManagement = 'UserManagement',
  UniversalWireAccounts = 'UniversalWireAccounts',
  WireAccounts = 'WireAccounts',
  Fast = 'Fast',
  SupportAdmin = 'SupportAdmin',
  HangfireAdmin = 'HangfireAdmin',
  HangfireUser = 'HangfireUser',
  BulkExternalInvite = 'BulkExternalInvite',
  SingleExternalUser = 'SingleExternalUserInvite',
  ResendExternalInvite = 'ResendExternalInvite',
  FeeEntry = 'FeeEntry',
  FileEntry = 'FileEntry',
  IBAEntry = 'IBAEntry',
  EscrowFileEntry = 'EscrowFileEntry',
  EditSSNTIN = 'EditSSNTIN',
  ViewSSNTIN = 'ViewSSNTIN',
  TemplateManagement = 'TemplateManagement',
  EscrowDeposits = 'EscrowDeposits',
  EscrowDepositsRecieptHistory = 'EscrowDepositsRecieptHistory',
  Create1099sEntry = 'Create1099S',
  ViewPrint1099S = 'ViewPrint1099S',
  ApolloTeamManagement = 'ApolloTeamManagement',
  CanAccessOrder = 'CanAccessOrder', //used to determine if employee has access to orders in other offices

  // TODO: Replace this entry's value with the value from the back-end once we have one
  Documents = 'Documents',
  FileEntryActivityLimited = 'FileEntryActivityLimited',
}

export interface ICanAccessFeatureHelper {
  state: IApplicationState;
  featureFlags?: string[];
  acceptedActivityRights?: ActivityRightValue[];
  file?: IFileOffices;
  userAcceptedActivityRights?:
    | ActivityRightValue[]
    | UserAcceptedActivityRights;
}

export interface IPermissionsState {
  activityRightOptions: IActivityRights[];
}

export interface IActivityRights {
  name: string;
  value: string;
  hidden?: boolean;
  key?: string;
}

export enum PermissionsTypeKeys {
  SET_ACTIVITY_RIGHT_OPTIONS = 'permissions/SET_ACTIVITY_RIGHT_OPTIONS',
}

export const EXTERNAL_USER_ACTIVITY_RIGHT_OPTIONS = [];

export const INTERNAL_USER_ACTIVITY_RIGHT_OPTIONS = [
  { name: 'Admin', value: ActivityRightValue.Admin, hidden: false },
  {
    name: 'User Management',
    value: ActivityRightValue.UserManagement,
    hidden: false,
  },
  {
    name: 'Support Admin',
    value: ActivityRightValue.SupportAdmin,
    hidden: false,
  },
  {
    name: 'Hangfire Administrator',
    value: ActivityRightValue.HangfireAdmin,
    hidden: false,
  },
  {
    name: 'Hangfire Read-Only User',
    value: ActivityRightValue.HangfireUser,
    hidden: false,
  },
  {
    name: 'Bulk External Invite',
    value: ActivityRightValue.BulkExternalInvite,
    hidden: false,
  },
  {
    name: 'Single External User',
    value: ActivityRightValue.SingleExternalUser,
    hidden: false,
  },
  {
    name: 'Resend External Invite',
    value: ActivityRightValue.ResendExternalInvite,
    hidden: false,
  },
  {
    name: 'Template Management',
    value: ActivityRightValue.TemplateManagement,
    hidden: false,
  },
  {
    name: 'Apollo Team Management',
    value: ActivityRightValue.ApolloTeamManagement,
    hidden: false,
  },
];
