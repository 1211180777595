import React from 'react';
import styled from 'styled-components';
import { IDesignSystemIconProps } from 'icons/types';

// TODO : ALL LEGACY ICONS SHOULD BE REMOVED FROM THE WORKSPACE
// TEMPORARY FIX TO ALLOW LEGACY ICONS TO BE USED IN THE WORKSPACE

const ToggleOn = (props: IDesignSystemIconProps) => (
  <StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="6 6 12 12"
    fill="currentColor"
    className={props.className}
  >
    <path d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zM7 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
  </StyledSVG>
);

export default ToggleOn;

const StyledSVG = styled.svg`
  vertical-align: middle;
`;
