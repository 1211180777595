/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_HoldFundAllocationViewModel_ } from '../models/Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_HoldFundAllocationViewModel_';
import type { Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_AllocationViewModel_ } from '../models/Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_AllocationViewModel_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HoldFundAllocationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Saves hold fund allocations (Auth)
   * @returns void
   * @throws ApiError
   */
  public saveAllocationsAsync({
    orderId,
    holdFundId,
    requestBody,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the hold fund
     */
    holdFundId: number,
    /**
     * model with collection of allocations to save
     */
    requestBody: Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_UpdateAllocationsViewModel_AllocationViewModel_,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/{holdFundId}/allocations',
      path: {
        'orderId': orderId,
        'holdFundId': holdFundId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

  /**
   * Get allocations for an hold fund (Auth)
   * @returns Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_HoldFundAllocationViewModel_ Allocations were saved
   * @throws ApiError
   */
  public getAllocationsByHoldFundIdAsync({
    orderId,
    holdFundId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the hold fund
     */
    holdFundId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_ChargeAllocations_ViewModels_AllocationsViewModel_HoldFundAllocationViewModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/{holdFundId}/allocations',
      path: {
        'orderId': orderId,
        'holdFundId': holdFundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is not a project order`,
      },
    });
  }

}
