import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  white,
  blue,
  cadetBlue,
  focusShadow3,
  linearGradient,
  insetShadow,
  gray,
} from 'config/theme';
import {
  IButtonProps,
  ButtonSize,
  ButtonIcon,
  IButtonUrlWithState,
  IButtonType,
} from './types';
import Loader from 'react-loaders';

import MdDownload from 'react-icons/lib/md/get-app';
import UploadIcon from 'react-icons/lib/md/file-upload';
import AddIcon from 'react-icons/lib/md/add-circle';
import EditIcon from 'react-icons/lib/md/edit';
import EyeIcon from 'react-icons/lib/md/remove-red-eye';
import MdCancel from 'react-icons/lib/md/cancel';
import {
  DollarIcon,
  PlusIcon,
  LinkIcon,
  QuestionMarkIcon,
  PreviewIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import FileSearch from 'icons/FileSearch';
import { trackEvent } from 'utils/appInsights';
import OpenExternalLink from 'icons/OpenExternalLink';

const loader = <Loader type="ball-clip-rotate" active />;

class Button extends React.Component<IButtonProps> {
  public static defaultProps: Partial<IButtonProps> = {
    type: 'button' as IButtonType,
    size: ButtonSize.SM,
    disabled: false,
  };

  public render() {
    const {
      className,
      type,
      icon,
      children,
      ariaLabel,
      disabled,
      style,
      id,
      data,
      loading,
      dataFor,
    } = this.props;

    return (
      <button
        className={className}
        type={type}
        onClick={this.handleClick}
        disabled={disabled}
        role="button"
        aria-label={ariaLabel}
        style={style}
        id={id}
        data-for={dataFor}
        {...data}
      >
        {!loading && icon ? ButtonIconComponent[icon] : null}
        {loading ? loader : children}
      </button>
    );
  }

  private handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const { dispatch, onClick, to, aiTrackName, aiProperties } = this.props;

    if (aiTrackName) {
      trackEvent(aiTrackName, aiProperties);
    }
    if (onClick) {
      return onClick(ev);
    } else if (to) {
      const { state, url = to as string } = to as IButtonUrlWithState;
      dispatch(push(url, state));
    }
  };
}

const getActiveBackground = (props) => {
  if (props.plain) return 'none';
  if (props.activeBackgroundColor) return props.activeBackgroundColor;
  if (props.ghost) return gray;
  if (props.disabled) return props.backgroundColor || blue;
  return linearGradient;
};

const StyledConnectedButton = styled(connect()(Button))`
  ${({ backgroundColor, ghost, plain }) =>
    plain || ghost
      ? `background: none;`
      : `background-color: ${backgroundColor || blue};`}
  border-color: ${(props) => props.borderColor || 'transparent'};
  border-radius: ${(props) =>
    props.size && props.size > ButtonSize.MD ? '4px' : '3px'};
  border-style: solid;
  border-width: 1px;
  color: ${(props) =>
    props.disabled
      ? props.disabledTextColor || cadetBlue
      : props.textColor || white};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: inline-block;
  text-align: center;
  font-family: 'Circular', sans-serif;
  font-weight: 700;
  font-size: ${(props) => ButtonFontSize[props.size || ButtonSize.SM]};
  min-width: ${(props) => props.minWidth || 'unset'};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  padding: 0 ${(props) => ButtonPadding[props.size || ButtonSize.SM]};
  height: ${(props) => ButtonHeight[props.size || ButtonSize.SM]};
  line-height: ${(props) => ButtonLineHeight[props.size || ButtonSize.SM]};
  text-transform: ${(props) => {
    if (props.capitalize) {
      return 'capitalize';
    }
    if (props.uppercase) {
      return 'uppercase';
    }
    return 'none';
  }};
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
  ${({ backgroundColor, backgroundHoverColor, disabled, plain, ghost }) =>
    plain
      ? `background: none;`
      : ghost
      ? `background: ${backgroundHoverColor || gray};`
      : `background: ${disabled ? backgroundColor || blue : linearGradient};
      background-color: ${
        disabled ? 'none' : backgroundHoverColor || backgroundColor || blue
      };`}
    border-color: ${(props) =>
      props.plain || props.ghost
        ? 'transparent'
        : props.borderHoverColor || props.borderColor || 'transparent'};
    color: ${(props) =>
      props.disabled
        ? props.disabledTextColor || cadetBlue
        : props.textHoverColor || props.textColor || white};
  }

  &:focus {
    box-shadow: ${(props) =>
      props.plain || props.ghost ? 'none' : props.shadow || focusShadow3};
    outline: none;
  }

  &:active {
    background: ${getActiveBackground};
    background-color: ${(props) =>
      props.disabled || props.plain || props.ghost
        ? 'none'
        : props.backgroundHoverColor || props.backgroundColor || blue};
    box-shadow: ${({ plain, ghost }) =>
      plain || ghost ? 'none' : insetShadow};
  }
  svg {
    margin: -3px 6px 0 0;
    vertical-align: middle;
  }
  .ball-clip-rotate > div {
    border-color: white;
    border-width: 3px;
    border-bottom-color: transparent;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
  }


  ${(prop) => {
    // TODO: refactor this to be more flexible
    // and less verbose
    if (prop.secondary && prop.disabled) {
      return `
        background-color: ${white};
        border-color: ${cadetBlue};
        color: ${cadetBlue};

        &:hover {
          background-color: ${white};
          border-color: ${cadetBlue};
          color: ${cadetBlue};
        }
      `;
    }

    // Add default secondary button styles
    if (prop.secondary) {
      return `
        background-color: ${prop.backgroundColor || white};
        border-color: ${prop.borderColor || blue};
        color: ${prop.textColor || blue};

        &:hover {
          background: ${blue};
          border-color: ${blue};
          color: ${white};
        }
        &:focus {
          background-color: ${prop.backgroundColor || white};
          border-color: ${prop.borderColor || blue};
          color: ${prop.textColor || blue};
          outline: none;
        }
        &:active {
          background: ${linearGradient};
          color: ${white};
        }
      `;
    }
  }}
`;
StyledConnectedButton.displayName = 'StyledConnectedButton';

export default StyledConnectedButton;

const ButtonHeight = {
  [ButtonSize.SM]: '32px',
  [ButtonSize.MD]: '40px',
  [ButtonSize.LG]: '48px',
  [ButtonSize.XL]: '56px',
};

const ButtonLineHeight = {
  ...ButtonHeight,
  [ButtonSize.LG]: '47px',
};

const ButtonPadding = {
  [ButtonSize.SM]: '16px',
  [ButtonSize.MD]: '24px',
  [ButtonSize.LG]: '24px',
  [ButtonSize.XL]: '48px',
};

const ButtonFontSize = {
  [ButtonSize.SM]: '14px',
  [ButtonSize.MD]: '14px',
  [ButtonSize.LG]: '16px',
  [ButtonSize.XL]: '18px',
};

const ButtonIconComponent = {
  [ButtonIcon.ADD]: <AddIcon style={{ width: 24, height: 24 }} />,
  [ButtonIcon.DOLLAR]: <DollarIcon style={{ width: 11, height: 19 }} />,
  [ButtonIcon.PLUS]: <PlusIcon style={{ width: 16, height: 16 }} />,
  [ButtonIcon.LINK]: <LinkIcon style={{ width: 21, height: 11 }} />,
  [ButtonIcon.QUESTION]: <QuestionMarkIcon style={{ width: 18, height: 18 }} />,
  [ButtonIcon.PREVIEW]: <PreviewIcon style={{ width: 20, height: 14 }} />,
  [ButtonIcon.EDIT]: <EditIcon style={{ width: 17, height: 17 }} />,
  [ButtonIcon.EYE]: <EyeIcon style={{ width: 20, height: 20 }} />,
  [ButtonIcon.DOWNLOAD]: <MdDownload style={{ width: 27, height: 27 }} />,
  [ButtonIcon.UPLOAD]: <UploadIcon style={{ width: 27, height: 27 }} />,
  [ButtonIcon.CANCEL]: <MdCancel style={{ width: 27, height: 27 }} />,
  [ButtonIcon.OPEN_EXTERNAL_LINK]: (
    <OpenExternalLink style={{ width: 20, height: 20 }} />
  ),
  [ButtonIcon.FILE_SEARCH]: <FileSearch style={{ width: 20, height: 20 }} />,
};

export { ButtonSize, ButtonIcon };
