import React from 'react';
import {
  IIssueCheckDeliveryStatusProgress,
  IIssueCheckDocumentProgressMetaData,
} from 'store/orders/types';
import {
  trackIssueCheckProgress,
  clearIssueCheckProgress,
} from 'store/orders/actions';
import { connect } from 'react-redux';
import { IApplicationState } from 'store';
import workspaceAPI from 'services/workspaceAPI';
import DocumentGenerationProgress from 'components/DocumentGenerationProgress';

interface IProps {
  issueCheckItems: IIssueCheckDocumentProgressMetaData[];
  trackIssueCheckProgress: typeof trackIssueCheckProgress;
  clearIssueCheckProgress: typeof clearIssueCheckProgress;
}

const IssueCheckDocumentGenerationProgress = ({
  issueCheckItems,
  clearIssueCheckProgress,
  trackIssueCheckProgress,
}: IProps) => {
  return (
    <DocumentGenerationProgress
      title="Generating Check Images..."
      selectorKey="issue-check-pdf"
      bannerText="If you were not able to print the check, please adjust the disbursement."
      items={issueCheckItems.map((item) => ({
        ...item,
        id: item.checks.map((x) => x.disbursementId).join('-'),
        name: `CheckImage-${item.checks
          .map((x) => x.disbursementId)
          .join('-')}`,
        description: Object.keys(
          item.checks
            .map((x) => x.payeeName)
            // DOC: creates a map to remove duplicated names
            .reduce((namesMap, x) => {
              namesMap[x] = true;
              return namesMap;
            }, {})
        ).join(', '),
        print: true,
        format: 'pdf',
      }))}
      getItemPendingStatus={(itm) => {
        const item = (itm as unknown) as IIssueCheckDocumentProgressMetaData;
        return (
          item.checks.filter(
            (x) =>
              item.deliveryStatus?.[x.disbursementId].description !== 'Success'
          ).length > 0 && !item.fetching
        );
      }}
      getItemReadyStatus={(itm) => {
        const item = (itm as unknown) as IIssueCheckDocumentProgressMetaData;
        return (
          item.checks.filter(
            (x) =>
              item.deliveryStatus?.[x.disbursementId].description === 'Success'
          ).length === item.checks.length
        );
      }}
      fetchItemProgressStatus={async (itm) => {
        const item = (itm as unknown) as IIssueCheckDocumentProgressMetaData;
        const params = new URLSearchParams();
        item.checks?.forEach((x) =>
          params.append('disbursementIds', `${x.disbursementId}`)
        );
        trackIssueCheckProgress([{ ...item, fetching: true }]);
        const { data } = await workspaceAPI.get<{
          statuses: IIssueCheckDeliveryStatusProgress;
        }>(
          `/orders/${item.orderId}/disbursements/_check-delivery-status?${params}`
        );
        trackIssueCheckProgress([
          { ...item, deliveryStatus: data.statuses, fetching: false },
        ]);
      }}
      // TODO: future PR, is printing multiple checks at the same time the way to go? or document stitching?
      fetchItemBytes={async (itm) => {
        const item = (itm as unknown) as IIssueCheckDocumentProgressMetaData;
        const params = new URLSearchParams();
        item.checks?.forEach((x) =>
          params.append('disbursementIds', `${x.disbursementId}`)
        );
        const fileUrl = `/orders/${item.orderId}/disbursements/_check?${params}`;
        const { data } = await workspaceAPI.get(fileUrl, {
          responseType: 'blob',
        });
        return data;
      }}
      clearItem={(item) =>
        clearIssueCheckProgress(
          (item as unknown) as IIssueCheckDocumentProgressMetaData
        )
      }
      trackItems={(items) =>
        trackIssueCheckProgress(
          (items as unknown) as IIssueCheckDocumentProgressMetaData[]
        )
      }
      errorText="There was an error printing issue check"
      stopOnError
    />
  );
};

function mapStateToProps(state: IApplicationState) {
  return {
    issueCheckItems: state.orders.ui.issueCheckDocumentProgress.items,
  };
}

export default connect(mapStateToProps, {
  trackIssueCheckProgress,
  clearIssueCheckProgress,
})(IssueCheckDocumentGenerationProgress);
