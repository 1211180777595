import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="322"
    height="321"
    viewBox="0 0 322 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M322 160.83C322 249.292 249.92 321 161 321C72.0797 321 0 249.292 0 160.83C0 72.3683 72.0797 0.660156 161 0.660156C249.92 0.660156 322 72.3683 322 160.83Z"
      fill="url(#paint0-linear)"
    />
    <path
      d="M163.49 272.864C225.366 272.864 275.526 278.066 275.526 284.483C275.526 290.9 225.366 296.102 163.49 296.102C101.614 296.102 51.4536 290.9 51.4536 284.483C51.4536 278.066 101.614 272.864 163.49 272.864Z"
      fill="url(#paint1-linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M245.604 257.311H79.3314C77.0888 257.311 75.276 259.113 75.276 261.342V269.404H249.659V261.342C249.659 259.113 247.842 257.311 245.604 257.311Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M245.604 257.311H79.3314C77.0888 257.311 75.276 259.113 75.276 261.342V269.404H249.659V261.342C249.659 259.113 247.842 257.311 245.604 257.311Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M249.659 269.404H75.276C77.9161 274.661 83.322 277.467 89.2307 277.467H235.769C241.634 277.467 247.007 274.681 249.627 269.469L249.659 269.404Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M249.659 269.404H75.276C77.9161 274.661 83.322 277.467 89.2307 277.467H235.769C241.634 277.467 247.007 274.681 249.627 269.469L249.659 269.404Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M237.493 172.653C237.493 168.203 233.859 164.591 229.382 164.591H95.553C91.0758 164.591 87.4422 168.203 87.4422 172.653V257.311H237.493V172.653Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M203.022 164.591H95.553C91.0758 164.591 87.4422 168.203 87.4422 172.653V257.311L203.022 164.591Z"
      fill="white"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.561 212.196C139.41 209.011 139.901 205.299 138.944 201.747L127.4 164.591H95.5546C91.0733 164.591 87.4437 168.199 87.4437 172.653V229.728L129.15 218.618C132.723 217.663 135.711 215.385 137.561 212.196Z"
      fill="#EFEFEF"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.023 235.402C186.174 232.217 185.683 228.504 186.64 224.953L205.149 165.87H230.029C234.51 165.87 238.14 169.478 238.14 173.933V252.934L196.434 241.824C192.861 240.868 189.872 238.591 188.023 235.402Z"
      fill="#D8D8D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M237.493 172.653C237.493 168.203 233.859 164.591 229.382 164.591H95.553C91.0758 164.591 87.4422 168.203 87.4422 172.653V257.311H237.493V172.653Z"
      stroke="#394C66"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.149 204.358C129.68 206.325 128.508 208.353 126.525 208.881L78.3548 221.713C76.3676 222.241 74.3318 221.072 73.8046 219.1L58.4264 162.045C57.8952 160.078 59.0713 158.054 61.0503 157.526L94.3616 148.653L117.753 162.078L129.149 204.358Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.149 204.358C129.68 206.325 128.508 208.353 126.525 208.881L78.3548 221.713C76.3676 222.241 74.3318 221.072 73.8046 219.1L58.4264 162.045C57.8952 160.078 59.0713 158.054 61.0503 157.526L94.3616 148.653L117.753 162.078L129.149 204.358Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.3611 148.652L99.3046 166.991L117.757 162.077L94.3611 148.652Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.3611 148.652L99.3046 166.991L117.757 162.077L94.3611 148.652Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.6326 174.363L90.0808 169.445"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.1032 183.532L109.462 174.111"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.5752 192.701L111.934 183.284"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.0464 201.87L114.41 192.453"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.434 227.563C195.903 229.53 197.075 231.558 199.058 232.086L247.228 244.918C249.216 245.446 251.251 244.277 251.779 242.305L267.157 185.25C267.688 183.283 266.512 181.259 264.533 180.731L231.222 171.858L207.83 185.283L196.434 227.563Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.434 227.563C195.903 229.53 197.075 231.558 199.058 232.086L247.228 244.918C249.216 245.446 251.251 244.277 251.779 242.305L267.157 185.25C267.688 183.283 266.512 181.259 264.533 180.731L231.222 171.858L207.83 185.283L196.434 227.563Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.221 171.857L226.278 190.196L207.825 185.282L231.221 171.857Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.221 171.857L226.278 190.196L207.825 185.282L231.221 171.857Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.3 194.916C243.391 198.133 245.277 201.471 248.513 202.374C251.749 203.273 255.107 201.398 256.016 198.185C256.92 194.972 255.034 191.634 251.802 190.731C248.57 189.824 245.212 191.703 244.3 194.916Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.3 194.916C243.391 198.133 245.277 201.471 248.513 202.374C251.749 203.273 255.107 201.398 256.016 198.185C256.92 194.972 255.034 191.634 251.802 190.731C248.57 189.824 245.212 191.703 244.3 194.916Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.747 230.63L240.233 218.206C239.454 216.077 237.074 214.997 234.953 215.815L229.368 217.964L226.023 206.616C225.135 203.592 221.258 202.705 219.133 205.035L205.701 219.737L244.747 230.63Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.747 230.63L240.233 218.206C239.454 216.077 237.074 214.997 234.953 215.815L229.368 217.964L226.023 206.616C225.135 203.592 221.258 202.705 219.133 205.035L205.701 219.737L244.747 230.63Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="68.2247"
      y="15.7432"
      width="54.2216"
      height="54.2216"
      rx="14"
      transform="rotate(-15 68.2247 15.7432)"
      fill="white"
      stroke="#394C66"
      strokeWidth="2"
    />
    <rect
      x="68.2247"
      y="15.7432"
      width="54.2216"
      height="54.2216"
      rx="14"
      transform="rotate(-15 68.2247 15.7432)"
      fill="white"
      stroke="#394C66"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.041 52.254L78.6698 52.3535C79.7222 56.2813 80.2484 58.2451 81.1487 59.7205C83.2737 63.2029 87.0224 65.3672 91.1007 65.4663C92.8286 65.5083 94.7925 64.9821 98.7202 63.9296L117.713 58.8406C121.641 57.7882 123.604 57.2619 125.08 56.3617C128.562 54.2367 130.727 50.488 130.826 46.4096C130.86 45.0063 130.519 43.4472 129.826 40.8067C129.453 45.3823 126.263 49.4397 121.574 50.696L92.1783 58.5726C86.7647 60.0231 81.2142 57.2406 79.041 52.254Z"
      fill="#D3DFEF"
    />
    <rect
      x="68.2247"
      y="15.7432"
      width="54.2216"
      height="54.2216"
      rx="14"
      transform="rotate(-15 68.2247 15.7432)"
      stroke="#394C66"
      strokeWidth="2"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.1772 21.1328L106.881 15.8531C110.509 14.8811 114.237 17.0338 115.209 20.6612L120.489 40.3655L113.921 42.1254L110.401 28.9892L108.641 22.4212L102.073 24.1811L88.9371 27.7009L87.1772 21.1328Z"
      fill="url(#paint2-linear)"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.9359 27.703L82.3678 29.4629L87.6475 49.1671C88.6195 52.7946 92.3481 54.9473 95.9755 53.9753L115.68 48.6956L113.92 42.1275L94.2156 47.4072L88.9359 27.703Z"
      fill="url(#paint3-linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.921 42.1257L94.2168 47.4054L88.937 27.7012L102.073 24.1813L108.641 22.4214L110.401 28.9895L113.921 42.1257ZM105.374 36.4971L95.5216 39.137L95.9616 40.779L105.814 38.1391L105.374 36.4971ZM94.6416 35.8527L107.778 32.3329L108.218 33.9749L95.0816 37.4947L94.6416 35.8527ZM106.898 29.0497L93.7617 32.5695L94.2017 34.2116L107.338 30.6917L106.898 29.0497Z"
      fill="url(#paint4-linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.908 46.4506C226.282 44.8991 226.529 43.2998 226.529 41.6375C226.529 30.1395 217.155 20.8189 205.589 20.8189C200.837 20.8189 196.504 22.4542 192.988 25.1048C185.305 16.1256 173.877 10.4141 161.09 10.4141C137.962 10.4141 119.21 29.0523 119.21 52.0423C119.21 52.1322 119.225 52.219 119.225 52.3089C118.354 52.1981 117.495 52.0423 116.594 52.0423C105.027 52.0423 95.6541 61.3629 95.6541 72.858C95.6541 84.356 105.027 93.6766 116.594 93.6766H210.822C225.278 93.6766 236.999 82.0258 236.999 67.6555C236.999 58.883 232.603 51.1618 225.908 46.4506Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M210.823 80.1962H116.595C107.403 80.1962 99.6173 74.302 96.7972 66.1195C96.068 68.237 95.6522 70.4982 95.6522 72.8583C95.6522 84.3563 105.029 93.674 116.595 93.674H210.823C225.28 93.674 237 82.0232 237 67.6559C237 65.3168 236.666 63.0615 236.081 60.9141C233.092 72.0137 222.935 80.1962 210.823 80.1962Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.908 46.4506C226.282 44.8991 226.529 43.2998 226.529 41.6375C226.529 30.1395 217.155 20.8189 205.589 20.8189C200.837 20.8189 196.504 22.4542 192.988 25.1048C185.305 16.1256 173.877 10.4141 161.09 10.4141C137.962 10.4141 119.21 29.0523 119.21 52.0423C119.21 52.1322 119.225 52.219 119.225 52.3089C118.354 52.1981 117.495 52.0423 116.594 52.0423C105.027 52.0423 95.6541 61.3629 95.6541 72.858C95.6541 84.356 105.027 93.6766 116.594 93.6766H210.822C225.278 93.6766 236.999 82.0258 236.999 67.6555C236.999 58.883 232.603 51.1618 225.908 46.4506Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.624 128.698C192.152 128.698 193.543 127.824 194.216 126.447C194.889 125.07 194.723 123.426 193.792 122.212L167.981 88.5361C167.223 87.549 166.053 86.9688 164.814 86.9688C163.571 86.9688 162.401 87.549 161.646 88.5361L135.832 122.212C134.901 123.426 134.738 125.07 135.411 126.447C136.084 127.824 137.475 128.698 139 128.698H148.808V169.566C148.808 174.015 152.39 177.62 156.811 177.62H172.816C177.233 177.62 180.819 174.015 180.819 169.566V128.698H190.624Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.001 128.698H143.418L161.644 104.92C162.403 103.93 163.572 103.353 164.815 103.353C166.054 103.353 167.224 103.93 167.983 104.92L186.208 128.698H190.626C192.153 128.698 193.542 127.824 194.218 126.447C194.89 125.07 194.725 123.426 193.793 122.212L167.983 88.5361C167.224 87.549 166.054 86.9688 164.815 86.9688C163.572 86.9688 162.403 87.549 161.644 88.5361L135.833 122.212C134.902 123.426 134.736 125.07 135.412 126.447C136.085 127.824 137.477 128.698 139.001 128.698Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.624 128.698C192.152 128.698 193.543 127.824 194.216 126.447C194.889 125.07 194.723 123.426 193.792 122.212L167.981 88.5361C167.223 87.549 166.053 86.9688 164.814 86.9688C163.571 86.9688 162.401 87.549 161.646 88.5361L135.832 122.212C134.901 123.426 134.738 125.07 135.411 126.447C136.084 127.824 137.475 128.698 139 128.698H148.808V169.566C148.808 174.015 152.39 177.62 156.811 177.62H172.816C177.233 177.62 180.819 174.015 180.819 169.566V128.698H190.624Z"
      stroke="#394C66"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.504 198.305H156.123C154.294 198.305 152.81 196.811 152.81 194.971V189.561C152.81 187.717 154.294 186.227 156.123 186.227H173.504C175.333 186.227 176.817 187.717 176.817 189.561V194.971C176.817 196.811 175.333 198.305 173.504 198.305Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.046 191.759H156.017C154.119 191.759 152.579 191.215 152.579 190.546V188.578C152.579 187.907 154.119 187.364 156.017 187.364H174.046C175.943 187.364 177.483 187.907 177.483 188.578V190.546C177.483 191.215 175.943 191.759 174.046 191.759Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.504 198.305H156.123C154.294 198.305 152.81 196.811 152.81 194.971V189.561C152.81 187.717 154.294 186.227 156.123 186.227H173.504C175.333 186.227 176.817 187.717 176.817 189.561V194.971C176.817 196.811 175.333 198.305 173.504 198.305Z"
      stroke="#394C66"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.502 218.441H158.121C156.292 218.441 154.808 216.947 154.808 215.106V209.693C154.808 207.852 156.292 206.358 158.121 206.358H171.502C173.331 206.358 174.816 207.852 174.816 209.693V215.106C174.816 216.947 173.331 218.441 171.502 218.441Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.399 212.268H158.663C156.922 212.268 155.509 211.543 155.509 210.651V208.026C155.509 207.133 156.922 206.408 158.663 206.408H171.399C173.14 206.408 174.553 207.133 174.553 208.026V210.651C174.553 211.543 173.14 212.268 171.399 212.268Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.502 218.441H158.121C156.292 218.441 154.808 216.947 154.808 215.106V209.693C154.808 207.852 156.292 206.358 158.121 206.358H171.502C173.331 206.358 174.816 207.852 174.816 209.693V215.106C174.816 216.947 173.331 218.441 171.502 218.441Z"
      stroke="#394C66"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0-linear"
        x1="225.732"
        y1="228.052"
        x2="322.351"
        y2="24.3376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48658C" stopOpacity="0.2" />
        <stop offset="1" stopColor="#6B87AD" />
      </linearGradient>
      <linearGradient
        id="paint1-linear"
        x1="163.49"
        y1="296.102"
        x2="163.49"
        y2="272.864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48658C" />
        <stop offset="1" stopColor="#3D577B" />
      </linearGradient>
      <linearGradient
        id="paint2-linear"
        x1="94.2167"
        y1="47.405"
        x2="113.449"
        y2="14.0933"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#75B8C8" />
        <stop offset="1" stopColor="#75B8C8" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint3-linear"
        x1="89.4073"
        y1="55.735"
        x2="108.64"
        y2="22.4234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#77CBB9" />
        <stop offset="1" stopColor="#77CBB9" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint4-linear"
        x1="94.2168"
        y1="47.4054"
        x2="108.641"
        y2="22.4214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#335580" />
        <stop offset="1" stopColor="#335580" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);
