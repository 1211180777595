import { IApplicationState } from '../index';
import { IFeatureFlag } from 'store/features/types';

const FEATURE_FLAG_PATTERN = /^([A-Za-z0-9-]+)\.([A-Za-z0-9_]+)$/;

function parseFeatureFlag(flag: string): IFeatureFlag {
  const matches = flag.match(FEATURE_FLAG_PATTERN);

  if (!matches) {
    throw new Error('Flag must be in the format of `ComponentA.FeatureA`.');
  }

  return {
    component: matches[1],
    feature: matches[2],
  };
}

export function isFeatureEnabled(
  state: IApplicationState,
  featureFlag: string
) {
  let component;
  let feature;
  try {
    const parsedFlag = parseFeatureFlag(featureFlag);
    component = parsedFlag.component;
    feature = parsedFlag.feature;
    const featureComponentsArray = state.auth.session.features;
    const featuresTree = featureComponentsArray.reduce(
      (res, { name, features }) => {
        res[name] = features;
        return res;
      },
      {}
    );
    const componentFeatures = featuresTree[component];
    const enabled = Boolean(
      componentFeatures && componentFeatures.indexOf(feature) > -1
    );
    return enabled;
  } catch (e) {
    return false;
  }
}

export function isGostUser(state: IApplicationState) {
  try {
    const officeIds = state.auth.session.officeIds;
    const gost = Boolean(
      officeIds &&
        (officeIds.indexOf(2091) > -1 || officeIds.indexOf(10540) > -1)
    );
    return gost;
  } catch (e) {
    return false;
  }
}
