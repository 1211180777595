/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartiesAPI_CommonModels_Requests_PartyRequest } from '../models/PartiesAPI_CommonModels_Requests_PartyRequest';
import type { Workspace_API_Forms_BaseNewOrganizationRequest } from '../models/Workspace_API_Forms_BaseNewOrganizationRequest';
import type { Workspace_API_Forms_CreatePropertyTaxCollector } from '../models/Workspace_API_Forms_CreatePropertyTaxCollector';
import type { Workspace_API_ViewModels_EditOrderParties_OrderPartiesResponseModel } from '../models/Workspace_API_ViewModels_EditOrderParties_OrderPartiesResponseModel';
import type { Workspace_API_ViewModels_OrderOrganizations_AdhocContactViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_AdhocContactViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_ExchangeCompanyViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_ExchangeCompanyViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_NewLenderRequestViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_NewLenderRequestViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_NewLoanPartyViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_NewLoanPartyViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_ParticipantRequestViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_ParticipantRequestViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_PrincipalDetailViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_PrincipalDetailViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_RealEstateBrokerAgentViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_RealEstateBrokerAgentViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_UpdateAttorneyRequest } from '../models/Workspace_API_ViewModels_OrderOrganizations_UpdateAttorneyRequest';
import type { Workspace_API_ViewModels_OrderOrganizations_UpdatedAgentResponseViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_UpdatedAgentResponseViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_UpdatedBrokerResponseViewModel } from '../models/Workspace_API_ViewModels_OrderOrganizations_UpdatedBrokerResponseViewModel';
import type { Workspace_API_ViewModels_OrderOrganizations_UpdateExchangeCompanyResponse } from '../models/Workspace_API_ViewModels_OrderOrganizations_UpdateExchangeCompanyResponse';
import type { Workspace_Common_Models_Dto_EditOrderParties_PrincipalDto } from '../models/Workspace_Common_Models_Dto_EditOrderParties_PrincipalDto';
import type { Workspace_Common_Models_Dto_NewBusinessOrganizationRequestDTO } from '../models/Workspace_Common_Models_Dto_NewBusinessOrganizationRequestDTO';
import type { Workspace_Common_Models_Dto_OrderOrganizations_ExchangeCompanyResponseDto } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_ExchangeCompanyResponseDto';
import type { Workspace_Common_Models_Dto_OrderOrganizations_NewBrokerAndAgentBusinessOrganizationRequestDTO } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_NewBrokerAndAgentBusinessOrganizationRequestDTO';
import type { Workspace_Common_Models_Dto_OrderOrganizations_NewExchangeCompanyRequestDTO } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_NewExchangeCompanyRequestDTO';
import type { Workspace_Common_Models_Dto_OrderOrganizations_NewTrusteeRequestDTO } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_NewTrusteeRequestDTO';
import type { Workspace_Common_Models_Dto_OrderOrganizations_UpdateAgentBusinessOrganizationRequestDTO } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_UpdateAgentBusinessOrganizationRequestDTO';
import type { Workspace_Common_Models_Dto_OrderOrganizations_UpdateBrokerBusinessOrganizationRequestDTO } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_UpdateBrokerBusinessOrganizationRequestDTO';
import type { Workspace_Common_Models_Dto_OrderOrganizations_UpdateExchangeCompanyRequestDTO } from '../models/Workspace_Common_Models_Dto_OrderOrganizations_UpdateExchangeCompanyRequestDTO';
import type { Workspace_Common_Requests_BaseExistingOrganizationRequest } from '../models/Workspace_Common_Requests_BaseExistingOrganizationRequest';
import type { Workspace_Common_Requests_PartiesAPI_PartyUpdateRequest } from '../models/Workspace_Common_Requests_PartiesAPI_PartyUpdateRequest';
import type { Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse } from '../models/Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse';
import type { Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_AttorneyResponseViewModel_ } from '../models/Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_AttorneyResponseViewModel_';
import type { Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ExchangeCompanyResponseDto_ } from '../models/Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ExchangeCompanyResponseDto_';
import type { Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ParticipantResponseViewModel_ } from '../models/Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ParticipantResponseViewModel_';
import type { Workspace_Common_Responses_OrderOrganizations_UpdatedPrincipalOrganizationResponse } from '../models/Workspace_Common_Responses_OrderOrganizations_UpdatedPrincipalOrganizationResponse';
import type { Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse } from '../models/Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse';
import type { Workspace_Common_Responses_PartiesAPI_PartyResponse } from '../models/Workspace_Common_Responses_PartiesAPI_PartyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderOrganizationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add a principal (buyer or seller) and principal contacts to an order. (Auth)
   * API can add a principal (buyer or seller) as a business entity, trust/estate, husband/wife or individual to an order. Additionally it can add contacts to a business entity or trust/estate.
   *
   * API is used when you want to add a principal (buyer or seller) to an order. Contacts pertain only to business entity or trust/estate principals and cannot be added as a buyer or seller by themselves.
   *
   * The objects contained within the model allow for a user to; add(import) a business entity or trust/estate from GAB; add a business entity, trust/estate, husband/wife, individual and contacts directly to the order.
   *
   * API is used where a principal needs to be added on the order such as the Order Team area.
   *
   * Requirements: When adding a Business Entity or Trust/Estate directly you must enter an Organization name (shortName). When adding Spouses, Individuals or contacts the first and last name must be entered.
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse Organization has been successfully create
   * @throws ApiError
   */
  public addOrganizationContactToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * PrincipalDto in a body
     */
    requestBody: Workspace_Common_Models_Dto_EditOrderParties_PrincipalDto,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/principal',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * New method since front end needs to be stable for Demo. Retrieves all parties (Order Principals and Non-Principal Organizations) of the Organization from File Address book endpoint.. (Auth)
   * @returns Workspace_API_ViewModels_EditOrderParties_OrderPartiesResponseModel Buyer Detail Response
   * @throws ApiError
   */
  public getOrderBusinessPartiesAsync({
    orderId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_EditOrderParties_OrderPartiesResponseModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/organizations/business-parties',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External users do not have permission`,
        404: `Order / File not found`,
      },
    });
  }

  /**
   * retrieve Order Buyer Organization. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_PrincipalDetailViewModel Buyer Detail Response
   * @throws ApiError
   */
  public getOrganizationByBusinessPartyId({
    orderId,
    principalId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Principal ID
     */
    principalId: number,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_PrincipalDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/organizations/buyers/{principalId}',
      path: {
        'orderId': orderId,
        'principalId': principalId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order / File not found`,
      },
    });
  }

  /**
   * retrieve Order Buyer Organization. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_PrincipalDetailViewModel Buyer Detail Response
   * @throws ApiError
   */
  public getSellerDetailsByPrincipalId({
    orderId,
    principalId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Principal ID
     */
    principalId: number,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_PrincipalDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/organizations/seller/{principalId}',
      path: {
        'orderId': orderId,
        'principalId': principalId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order / File not found`,
      },
    });
  }

  /**
   * Add an Adhoc organization (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addNewAdHocOrganization({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Base Organization Form Model
     */
    requestBody: Workspace_API_Forms_BaseNewOrganizationRequest,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/adhoc',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Add existing organization as related party on Order.
   * (Outside Escrow Company, Seller's Attorney, Buyer's Attorney, Outside Title Company, Miscellaneous Disbursement), PayoffLender No updates to GAB (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse order parties Response
   * @throws ApiError
   */
  public addPartiesToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Existing GAB organization
     */
    requestBody: Workspace_Common_Requests_BaseExistingOrganizationRequest,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/party',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order / File not found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Add Property Tax collector to Order (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addPropertyTaxCollector({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Property Tax Collector Model
     */
    requestBody: Workspace_API_Forms_CreatePropertyTaxCollector,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/property-tax-collector',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Add real estate broker to Order,
   * Add Real Estate Agent to the order, with broker as reference to Agent (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addRealEstateBrokerAgent({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Real Estate Broker View Model
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_RealEstateBrokerAgentViewModel,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/broker-agent',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Adds an adhoc contact to an existing organization (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addAdhocContactAsync({
    orderId,
    organizationId,
    requestBody,
  }: {
    /**
     * The Id of the current order
     */
    orderId: number,
    /**
     * The organization Id
     */
    organizationId: number,
    /**
     * The contact details to be added
     */
    requestBody?: Workspace_API_ViewModels_OrderOrganizations_AdhocContactViewModel,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/{organizationId}/adhoc-contact',
      path: {
        'orderId': orderId,
        'organizationId': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid authorization header`,
        403: `External users do not have permission`,
        404: `Not Found`,
        422: `Contact details validation failed`,
      },
    });
  }

  /**
   * Add new attorney to an organization (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addNewAttorneyToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * organizationForm
     */
    requestBody: Workspace_Common_Models_Dto_NewBusinessOrganizationRequestDTO,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/attorney',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Updates new attorney to an organization (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_AttorneyResponseViewModel_ OrderOrganizationResponse
   * @throws ApiError
   */
  public editAttorneyOfOrderAsync({
    orderId,
    attorneyId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Attorney ID
     */
    attorneyId: number,
    /**
     * organizationForm
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_UpdateAttorneyRequest,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_AttorneyResponseViewModel_> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/organizations/attorney/{attorneyId}',
      path: {
        'orderId': orderId,
        'attorneyId': attorneyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add new Broker organization to the Gab Queue and then it to order. (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addNewBrokerToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * organizationForm
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_NewBrokerAndAgentBusinessOrganizationRequestDTO,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/broker',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add new Real Estate Agent to the Gab Queue and then it to order.
   * Add AdHoc Real Estate Agent to the Broker for an Order (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addNewAgentToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * New Agent Form
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_NewBrokerAndAgentBusinessOrganizationRequestDTO,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/realEstateAgent',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add Exchange company to a Principal, (Auth)
   * @returns Workspace_Common_Models_Dto_OrderOrganizations_ExchangeCompanyResponseDto OrderOrganizationResponse
   * @throws ApiError
   */
  public addExchangeCompanyAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Exchange Company View Model
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_ExchangeCompanyViewModel,
  }): CancelablePromise<Workspace_Common_Models_Dto_OrderOrganizations_ExchangeCompanyResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/exchange-company',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update exchange company to an order.
   * Required: Name and RoleType
   * Note:
   * If address is provided(Line 1, City, State) organization will be sent to GAB.
   * If address is missing one of following fields (Line 1, City, State) organization will be added as AdHoc exchange company to an order. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_UpdateExchangeCompanyResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public updateExchangeCompanyToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * exchangeCompanyForm
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_UpdateExchangeCompanyRequestDTO,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_UpdateExchangeCompanyResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/organizations/exchange-company',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add Exchange company to a Principal, (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ExchangeCompanyResponseDto_ OrderOrganizationResponse
   * @throws ApiError
   */
  public addImportedExchangeCompanyAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Exchange Company View Model
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_ExchangeCompanyViewModel,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ExchangeCompanyResponseDto_> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/gab-exchange-company',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Add exchange company to an order.
   * Required: Name and RoleType
   * Note:
   * If address is provided(Line 1, City, State) organization will be sent to GAB.
   * If address is missing one of following fields (Line 1, City, State) organization will be added as AdHoc exchange company to an order. (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse OrderOrganizationResponse
   * @throws ApiError
   */
  public addNewExchangeCompanyToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * exchangeCompanyForm
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_NewExchangeCompanyRequestDTO,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/new-exchange-company',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * This method will update the principal details to the order. (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_UpdatedPrincipalOrganizationResponse Principal details updated successfully
   * @throws ApiError
   */
  public updatePrincipal({
    orderId,
    principalId,
    requestBody,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * Principal Id
     */
    principalId: number,
    /**
     * Principal details to be updated
     */
    requestBody: Workspace_Common_Models_Dto_EditOrderParties_PrincipalDto,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_UpdatedPrincipalOrganizationResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/organizations/update-principal/{principalId}',
      path: {
        'orderId': orderId,
        'principalId': principalId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Updates real estate broker to an organization (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_UpdatedBrokerResponseViewModel Details updated successfully
   * @throws ApiError
   */
  public updateRealEstateBroker({
    orderId,
    brokerId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Attorney ID
     */
    brokerId: number,
    /**
     * update form
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_UpdateBrokerBusinessOrganizationRequestDTO,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_UpdatedBrokerResponseViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/organizations/broker/{brokerId}',
      path: {
        'orderId': orderId,
        'brokerId': brokerId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Updates real estate agent to an organization (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_UpdatedAgentResponseViewModel Details updated successfully
   * @throws ApiError
   */
  public updateRealEstateAgent({
    orderId,
    brokerId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Broker ID
     */
    brokerId: number,
    /**
     * update form
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_UpdateAgentBusinessOrganizationRequestDTO,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_UpdatedAgentResponseViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/organizations/agent/{brokerId}',
      path: {
        'orderId': orderId,
        'brokerId': brokerId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add new lender to an order.
   * Required: Name and RoleType
   * Note:
   * If address is provided(Line 1, City, State) organization will be sent to GAB. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel LenderResponseViewModel
   * @throws ApiError
   */
  public addNewLenderToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * lenderForm
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_NewLenderRequestViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/lenders',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add new trustee to a lender.
   * Note:
   * If address is provided(Line 1, City, State) organization will be sent to GAB. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel LenderResponseViewModel
   * @throws ApiError
   */
  public addNewTrusteeToLender({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * trusteeForm
     */
    requestBody: Workspace_Common_Models_Dto_OrderOrganizations_NewTrusteeRequestDTO,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/trustees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add new loan party to a lender.
   * Note:
   * If address is provided(Line 1, City, State) organization will be sent to GAB. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel LenderResponseViewModel
   * @throws ApiError
   */
  public addNewLoanPartyToLender({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * newLoanPartyForm
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_NewLoanPartyViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/new-loan-related-parties',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add an existing organization as trustee to an order.
   * No updates to GAB. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel LenderResponseViewModel
   * @throws ApiError
   */
  public addImportedTrusteeToLenders({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Existing GAB organization
     */
    requestBody: Workspace_Common_Requests_BaseExistingOrganizationRequest,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/import-trustee',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add an existing organization as new loan related party to an order.
   * No updates to GAB. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel LenderResponseViewModel
   * @throws ApiError
   */
  public addImportedNewLoanRelatedPartyToLender({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Existing GAB organization
     */
    requestBody: Workspace_Common_Requests_BaseExistingOrganizationRequest,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/import-new-loan-related-parties',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add an existing organization as Payoff and Assumption lenders to an order.
   * No updates to GAB. (Auth)
   * @returns Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel LenderResponseViewModel
   * @throws ApiError
   */
  public addImportedLendersToOrder({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Existing GAB organization
     */
    requestBody: Workspace_Common_Requests_BaseExistingOrganizationRequest,
  }): CancelablePromise<Workspace_API_ViewModels_OrderOrganizations_LenderResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/import-lenders',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Add new outside title company to the order.
   * Note:
   * If address is provided(Line 1, City, State) organization will be sent to GAB. (Auth)
   * @returns Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ParticipantResponseViewModel_ LenderResponseViewModel
   * @throws ApiError
   */
  public addNewOutsideTitleCompany({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * New Participant form
     */
    requestBody: Workspace_API_ViewModels_OrderOrganizations_ParticipantRequestViewModel,
  }): CancelablePromise<Workspace_Common_Responses_OrderOrganizations_OrderOrganizationResponseWithPayload_ParticipantResponseViewModel_> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/outside-title',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request`,
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Gets list of parties for an order (Auth)
   * @returns Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse List of parties.
   * @throws ApiError
   */
  public getBusinessPartiesAsync({
    orderId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
  }): CancelablePromise<Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/organizations/business-parties/all',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External users do not have permission`,
        404: `Order was not found.`,
        500: `Internal server error.`,
      },
    });
  }

  /**
   * Adds Parties to the order. (Auth)
   * The endpoint is same for all Parties and for all 3 flows - Imported GAB, New GRQ Request and AdHoc Request.
   *
   * For Imported GAB, request needs to provide Id for attribute Business Organization.
   *
   * For GRQ Request, request needs to provide Type and Address requirements (line1, City, State, Zip) are mandatory.
   *
   * For AdHoc request, Organization type is mandatory.
   *
   * The 3 flows are differentaited by flags provided by Salesforce APIs- AdhocFlag and AdHocWithoutGERQ. Set AdhocFlag to true for GRQ request, and AdHocWithoutGERQ for Adhoc request.
   *
   * Contacts are optional. Gab contacts can be provided by supplying Id. For AdHoc contacts Adhoc flag (provided by Salesforce) for that contact must be set to True.
   *
   * Primary GAB contact can also be set by marking the contact using Salesforce flag - PrimaryContact.
   * @returns Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse List of parties.
   * @throws ApiError
   */
  public createSalesForcePartyAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Party request.
     */
    requestBody?: PartiesAPI_CommonModels_Requests_PartyRequest,
  }): CancelablePromise<Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/organizations/parties',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `User is unauthorized.`,
        403: `User is not able to access order.`,
        404: `Order was not found.`,
        409: `Conflict`,
        500: `Internal Server Error.`,
      },
    });
  }

  /**
   * Deletes party from the order. (Auth)
   * The endpoint is to delete the Party from the order.
   *
   * Order Id, PartyId, Role Type Id and Salesforce Id need to be provided for successful delete in all locations (Fast and Salesforce).
   * @returns Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse Party is deleted.
   * @throws ApiError
   */
  public deletePartyAsync({
    orderId,
    partyId,
    databaseId,
    roleType,
    salesforceId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * partyId identifier.
     */
    partyId: number,
    /**
     * databaseId identifier.
     */
    databaseId: string,
    /**
     * roleType identifier.
     */
    roleType: number,
    /**
     * salesforceId identifier.
     */
    salesforceId: string,
  }): CancelablePromise<Workspace_Common_Responses_PartiesAPI_PartiesDetailResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/organizations/parties',
      path: {
        'orderId': orderId,
      },
      query: {
        'partyId': partyId,
        'databaseId': databaseId,
        'roleType': roleType,
        'salesforceId': salesforceId,
      },
      errors: {
        401: `User is unauthorized.`,
        403: `User is not able to access order.`,
        404: `Order was not found.`,
        500: `Internal Server Error.`,
      },
    });
  }

  /**
   * Updates partie details of an organization (Auth)
   * @returns Workspace_Common_Responses_PartiesAPI_PartyResponse PartiesDetailResponse
   * @throws ApiError
   */
  public updatePartyAsync({
    orderId,
    id,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * PartyID in Database
     */
    id: string,
    /**
     * attorneyForm
     */
    requestBody?: Workspace_Common_Requests_PartiesAPI_PartyUpdateRequest,
  }): CancelablePromise<Workspace_Common_Responses_PartiesAPI_PartyResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/organizations/{id}/parties',
      path: {
        'orderId': orderId,
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

}
