/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_Region } from '../models/Workspace_Common_Models_Region';
import type { Workspace_Common_Responses_EmployeeList } from '../models/Workspace_Common_Responses_EmployeeList';
import type { Workspace_Common_Responses_RegionsList } from '../models/Workspace_Common_Responses_RegionsList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RegionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve all regions (Auth)
   * @returns Workspace_Common_Responses_RegionsList Regions successfully retrieved
   * @throws ApiError
   */
  public getRegions(): CancelablePromise<Workspace_Common_Responses_RegionsList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/regions',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Retrieve a region by ID (Auth)
   * @returns Workspace_Common_Models_Region Region successfully retrieved
   * @throws ApiError
   */
  public getRegionById({
    id,
    withOffices = false,
  }: {
    /**
     * a region ID
     */
    id: number,
    /**
     * Include offices in result.
     */
    withOffices?: boolean,
  }): CancelablePromise<Workspace_Common_Models_Region> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/regions/{id}',
      path: {
        'id': id,
      },
      query: {
        'withOffices': withOffices,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Region does not exist`,
      },
    });
  }

  /**
   * Retrieve employees by region and category
   * category=underwriter returns Underwriters
   * category=sales returns Sales representatives (Auth)
   * @returns Workspace_Common_Responses_EmployeeList Underwriters successfully retrieved
   * @throws ApiError
   */
  public getEmployeesByCategoryAsync({
    id,
    category = '',
  }: {
    /**
     * A ClarityFirst region ID
     */
    id: number,
    /**
     * category=underwriter|sales
     */
    category?: string | null,
  }): CancelablePromise<Workspace_Common_Responses_EmployeeList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/regions/{id}/employees',
      path: {
        'id': id,
      },
      query: {
        'category': category,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `No qualified employee found`,
      },
    });
  }

}
