/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_AllocationDto } from '../models/Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_AllocationDto';
import type { Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto } from '../models/Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto';
import type { Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Enums_PartyAllocationChargeAction } from '../models/Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Enums_PartyAllocationChargeAction';
import type { Workspace_Common_Models_Enums_PartyType } from '../models/Workspace_Common_Models_Enums_PartyType';
import type { WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_ManualAllocationViewModel } from '../models/WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_ManualAllocationViewModel';
import type { WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_VestedInterestViewModel } from '../models/WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_VestedInterestViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PartyAllocationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return the main party allocation object for all parties of the type (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_AllocationDto Party Allocation Object
   * @throws ApiError
   */
  public getPartyAllocationsAsync({
    orderId,
    partyType,
  }: {
    /**
     * Project file id
     */
    orderId: number,
    /**
     * Buyer/Seller type
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_AllocationDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/party-allocations/{partyType}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Adds/Updates vested interest records (Auth)
   * @returns any Add/Update received
   * @throws ApiError
   */
  public addOrUpdateVestedInterestAsync({
    orderId,
    partyType,
    requestBody,
  }: {
    /**
     * Project file id
     */
    orderId: number,
    /**
     * Party type of all of the business parties
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
    /**
     * Request body
     */
    requestBody: Array<WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_VestedInterestViewModel>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/party-allocations/{partyType}/vested-interest',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Adds a new manual allocation for a specific project charge and party (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto Add/Update/Remove successfully performed
   * @throws ApiError
   */
  public createManualAllocationAsync({
    orderId,
    partyId,
    requestBody,
  }: {
    /**
     * Project File id
     */
    orderId: number,
    /**
     * The FileBusinessPartyId
     */
    partyId: number,
    /**
     * Request body
     */
    requestBody: WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_ManualAllocationViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/party-allocations/{partyId}/charges',
      path: {
        'orderId': orderId,
        'partyId': partyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Updates or removes manual allocation
   * If the value sent for both charge and credit is zero, the whole
   * line item will get removed
   * If either charge or credit is included, the line item will get
   * updated to those new values (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto Add/Update/Remove successfully performed
   * @throws ApiError
   */
  public updateOrDeleteManualAllocation({
    orderId,
    partyId,
    partyChargeId,
    requestBody,
  }: {
    /**
     * Project File id
     */
    orderId: number,
    /**
     * The FileBusinessPartyId
     */
    partyId: number,
    /**
     * The specific charge to update
     */
    partyChargeId: number,
    /**
     * Request body
     */
    requestBody: WorkspaceAPI_FeatureAreas_SettlementStatementPartyAllocation_ViewModels_ManualAllocationViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/party-allocations/{partyId}/charges/{partyChargeId}',
      path: {
        'orderId': orderId,
        'partyId': partyId,
        'partyChargeId': partyChargeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found or charge not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Clears or refreshes a specific charge for the entire row of parties (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto clear or refresh performed
   * @throws ApiError
   */
  public clearOrRefreshChargeLinesAsync({
    orderId,
    partyType,
    projectChargeId,
    chargeAction,
    chargePosition,
  }: {
    /**
     * Project File id
     */
    orderId: number,
    /**
     * The party type that the action is taken against
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
    /**
     * The specific charge to update
     */
    projectChargeId: number,
    /**
     * The action to take for that charge
     */
    chargeAction: Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Enums_PartyAllocationChargeAction,
    /**
     * Position for that charge
     */
    chargePosition?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatementPartyAllocation_Dto_ChargeDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/party-allocations/{partyType}/charges/{projectChargeId}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
        'projectChargeId': projectChargeId,
      },
      query: {
        'chargeAction': chargeAction,
        'chargePosition': chargePosition,
      },
      errors: {
        400: `Invalid Charge Position`,
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found or charge not found`,
        422: `Validation failed, missing or incorrect charge action`,
      },
    });
  }

}
