import { AllocationsAction, AllocationsTypeKeys } from './types';

export const initialState = {
  showChargesAllocationsModal: undefined,
  showProrationsAdjustmentsAllocationsModal: undefined,
  showHoldFundsAllocationsModal: undefined,
};

export default (state = initialState, action: AllocationsAction) => {
  switch (action.type) {
    case AllocationsTypeKeys.CHARGES_SHOW_MODAL:
      return {
        ...state,
        showChargesAllocationsModal: action.payload,
      };
    case AllocationsTypeKeys.CHARGES_HIDE_MODAL:
      return {
        ...state,
        showChargesAllocationsModal: undefined,
      };
    case AllocationsTypeKeys.PRORATIONS_ADJUSTMENTS_SHOW_MODAL:
      return {
        ...state,
        showProrationsAdjustmentsAllocationsModal: action.payload,
      };
    case AllocationsTypeKeys.PRORATIONS_ADJUSTMENTS_HIDE_MODAL:
      return {
        ...state,
        showProrationsAdjustmentsAllocationsModal: undefined,
      };
    case AllocationsTypeKeys.HOLDFUNDS_SHOW_MODAL:
      return {
        ...state,
        showHoldFundsAllocationsModal: action.payload,
      };
    case AllocationsTypeKeys.HOLDFUNDS_HIDE_MODAL:
      return {
        ...state,
        showHoldFundsAllocationsModal: undefined,
      };
    default:
      return state;
  }
};
