import React from 'react';
import { trackEvent } from './appInsights';
import { prefixKeys } from './objects';

enum perfTrack {
  AI = 'CF_PERF',
  MEASURE = 'measure',
}

interface IWindowResource {
  name: string;
  rawName?: string;
  redirect: number;
  dns: number;
  tcp: number;
  secureConnection: number;
  response: number;
  fetch: number;
  request: number;
  start: number;
}
const IDRegex = /\/\d+/g;
export const generateProcessingModel = (n) => {
  if (!n) return {};
  return {
    promptForUnload: n.unloadEventEnd - n.unloadEventStart,
    redirect: n.redirectEnd - n.redirectStart,
    appCache: n.domainLookupStart - n.fetchStart,
    dns: n.domainLookupEnd - n.domainLookupStart,
    tcp: n.connectEnd - n.connectStart,
    request: n.responseStart - n.requestStart,
    response: n.responseEnd - n.responseStart,
    processing: n.domComplete - n.domInteractive,
    load: n.loadEventEnd - n.loadEventStart,
  };
};

export const startTracking = (start) => {
  if (performance === undefined) return;
  const startMarks = performance.getEntriesByName(start);
  if (startMarks?.length === 0) {
    performance.mark(start);
  }
};

export const refactorResourceURL = (name: string) => {
  if (!name) return;
  const ownHost = window.location.hostname;
  const url = new URL(name);
  const host = url.hostname;
  if (ownHost !== host) return name;
  const path = url.pathname;
  return path.replace(IDRegex, '/[id]');
};

export const measurePerformance = (
  componentName: string,
  measureName: string,
  start: string,
  end: string
) => {
  if (performance === undefined) return;
  // Current location (sending in PascalCase)
  const RawPageViewUrl = window?.location?.pathname;
  const PageViewUrl = RawPageViewUrl.replace(IDRegex, '/[id]');
  // JS Exec
  performance.mark(end);
  performance.measure(measureName, start, end);
  const measure = performance.getEntriesByType(perfTrack.MEASURE)?.[0];
  const execution = measure?.duration;
  // Resources
  const resourceListEntries = performance.getEntriesByType('resource');
  const resources = calculateResourceBlockers(resourceListEntries);
  // Navigation
  const navigationEntry = performance.getEntriesByType('navigation')?.[0];
  const processingModel = generateProcessingModel(navigationEntry);
  // Paint + Contentful
  const paintEntries = performance.getEntriesByType('paint');
  const paintMetrics = { firstPaint: 0, firstContentfulPaint: 0 };
  paintEntries?.forEach((o) => {
    if (!o || !o.startTime) return;
    const duration = o.startTime;
    switch (o.name) {
      case 'first-paint': {
        paintMetrics.firstPaint = duration;
        break;
      }
      case 'first-contentful-paint': {
        paintMetrics.firstContentfulPaint = duration;
        break;
      }
    }
  });

  // Send to AI
  const { dns, load, processing, request, response, tcp } = processingModel;
  const { firstPaint, firstContentfulPaint } = paintMetrics;

  const customDimensions = {
    RawPageViewUrl,
    PageViewUrl,
    componentName,
    resources,
  };

  const metrics = {
    firstPaint,
    firstContentfulPaint,
    execution,
    dns,
    load,
    processing,
    request,
    response,
    tcp,
  };

  const customMetrics = prefixKeys(perfTrack.AI + '_', metrics);
  trackEvent(perfTrack.AI, customDimensions, customMetrics);
};

export const calculateResourceBlockers = (resources) => {
  if (
    performance === undefined ||
    resources === undefined ||
    resources.length <= 0
  ) {
    return;
  }
  const results: IWindowResource[] = [];
  resources?.forEach((r) => {
    if (!r) return;
    const item: IWindowResource = {
      name: refactorResourceURL(r.name) || '',
      redirect: r.redirectEnd - r.redirectStart,
      dns: r.domainLookupEnd - r.domainLookupStart,
      tcp: r.connectEnd - r.connectStart,
      secureConnection:
        r.secureConnectionStart > 0
          ? r.connectEnd - r.secureConnectionStart
          : 0,
      response: r.responseEnd - r.responseStart,
      fetch: r.fetchStart > 0 ? r.responseEnd - r.fetchStart : 0,
      request: r.requestStart > 0 ? r.responseStart - r.requestStart : 0,
      start: r.startTime > 0 ? r.responseEnd - r.startTime : 0,
    };
    if (r.name !== item.name) {
      item.rawName = r.name;
    }
    results.push(item);
  });

  return results;
};

export const clearPerformanceTrackers = (measureName, start, end) => {
  if (performance === undefined) return;
  performance.clearResourceTimings();
  performance.clearMarks(start);
  performance.clearMarks(end);
  performance.clearMeasures(measureName);
};

interface IHOCState {
  start: string;
  allLoaded: boolean;
  measureName: string;
  end: string;
}

export const withPerfTrack = (
  componentName: string,
  componentStates?: string[]
) => {
  return function HOCFactory(WrappedComponent: any) {
    return class HOCFactory extends WrappedComponent<any, IHOCState> {
      constructor(props) {
        super(props);
        this.state = {
          start: componentName + '-start',
          allLoaded: false,
          measureName: 'measure-' + componentName,
          end: componentName + '-end',
        };
        startTracking(this.state.start);
      }

      public componentDidMount() {
        const { measureName, start, end, allLoaded } = this.state;
        const hasComponentStates =
          componentStates && componentStates.length > 0;
        if (!hasComponentStates && !allLoaded) {
          measurePerformance(componentName, measureName, start, end);
          this.setState({ allLoaded: true });
        }
      }
      public componentDidUpdate() {
        const { measureName, start, end, allLoaded } = this.state;
        let asyncReady = true;
        (componentStates || []).forEach((cState) => {
          if (this.props[cState]) {
            asyncReady = false;
          }
        });
        if (asyncReady && !allLoaded) {
          this.setState({ allLoaded: true });
          measurePerformance(componentName, measureName, start, end);
        }
      }

      public componentWillUnmount() {
        const { measureName, start, end } = this.state;
        this.setState({ allLoaded: false });
        clearPerformanceTrackers(measureName, start, end);
      }

      public render() {
        return <WrappedComponent {...this.props} />;
      }
    };
  };
};
