import { Page } from 'components/LandingPage';
import { Redirect } from 'react-router-dom';
import Cards from 'components/LandingPage/Cards';
import Features from 'components/LandingPage/Features';
import Footer from 'components/LandingPage/Footer';
import Header from 'components/LandingPage/Header';
import Hero from 'components/LandingPage/Hero';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPerfTrack } from 'utils/withPerfTrack';

interface IPublicLandingPageProps {
  location: any;
}

class PublicLandingPage extends React.Component<IPublicLandingPageProps> {
  public componentDidMount() {
    // It's possible to have arrived here by multiple means. `/employee/signed-out`,
    // '/customer/signed-out', or intended navigation to some path but authentication
    // failed. Whatever the reason, change the URL to just show '/', to reflect
    // what the user is actually seeing.
    if (location.pathname !== '/') {
      history.replaceState(null, '', `/${location.search}`);
    }
  }

  public render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (from && from.pathname !== '/') return <Redirect to={from} />;

    return (
      <Page id="homePage">
        <Header location={location} />
        <Hero />
        <Cards />
        <Features />
        <Footer />
      </Page>
    );
  }
}

export default compose<any>(
  connect(() => null),
  withPerfTrack('PublicLandingPage', [])
)(PublicLandingPage);
