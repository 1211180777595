export const AFTER_LOGIN_REDIRECT_PATH_KEY = 'afterLoginRedirectPath';
export const AFTER_LOGIN_REDIRECT_PATH_EXPIRY_KEY =
  'afterLoginRedirectPathExpiry';
export const AFTER_LOGIN_REDIRECT_TIMEOUT = 10; // in minutes
const ONE_MINUTE_IN_MS = 60000;
export const URL_REDIRECT_PATTERNS = [/^\/order\/\d+\/documents\?dl=\d+$/];

const setAfterLoginRedirectPath = (path: string): void => {
  const now = new Date();
  const expiresAt = new Date(
    now.getTime() + AFTER_LOGIN_REDIRECT_TIMEOUT * ONE_MINUTE_IN_MS
  );
  localStorage.setItem(AFTER_LOGIN_REDIRECT_PATH_KEY, path);
  localStorage.setItem(
    AFTER_LOGIN_REDIRECT_PATH_EXPIRY_KEY,
    expiresAt.toISOString()
  );
};

const getAfterLoginRedirectPath = (): string | null => {
  return localStorage.getItem(AFTER_LOGIN_REDIRECT_PATH_KEY);
};

const clearAfterLoginRedirectPath = (): void => {
  localStorage.removeItem(AFTER_LOGIN_REDIRECT_PATH_KEY);
  localStorage.removeItem(AFTER_LOGIN_REDIRECT_PATH_EXPIRY_KEY);
};

const shouldRedirectAfterLogin = (path: string): boolean => {
  return URL_REDIRECT_PATTERNS.some((regExp) => regExp.test(path));
};

const afterLoginRedirectPathExpired = (): boolean => {
  const now = new Date();
  const localStoredExpiresAt = localStorage.getItem(
    AFTER_LOGIN_REDIRECT_PATH_EXPIRY_KEY
  );
  if (!localStoredExpiresAt) return false;
  const expiresAt = new Date(localStoredExpiresAt);
  return now > expiresAt;
};

export {
  afterLoginRedirectPathExpired,
  clearAfterLoginRedirectPath,
  getAfterLoginRedirectPath,
  setAfterLoginRedirectPath,
  shouldRedirectAfterLogin,
};
