import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import Footer from 'components/Footer';
import { EmailVerification } from 'components/svg';
import { Row, Column } from 'components/ui/Box';
import { default as Card } from 'components/ui/Card';
import { default as Heading } from 'components/ui/Heading';
import {
  requestEmailVerificationCode,
  processEmailCode,
} from 'store/users/actions';
import { isInternalUser } from 'store/auth/getters';
import { toast } from 'components/Snackbar';
import store, { IApplicationState } from 'store';
import InputVerificationCode from 'components/InputVerificationCode';
import { updateSession } from 'store/auth/actions';
import { red8 } from 'config/colors';

interface IReduxStateProps {
  email: string;
  isAuthenticated: boolean;
  csrfToken: string;
  initCodeValue: string;
  emailCode: string;
  isExternalUser: boolean;
}
interface IReduxDispatchProps {
  sendEmail(
    email: string,
    isAuthenticated: boolean,
    isExternalUser?: boolean
  ): Promise<void>;
  sendEmailCodeForValidation(emailCode: string): Promise<string>;
  processCode(emailCode: string): Promise<void>;
}

function SendVerificationEmail({
  email,
  isAuthenticated,
  isExternalUser,
  sendEmail,
  sendEmailCodeForValidation,
}: IReduxStateProps & IReduxDispatchProps) {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isValid, setValid] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const decrementTimer = () => setTimeRemaining(timeRemaining - 1);
  const startTimer = () => setTimeRemaining(10);
  const codeLength = 6;
  const resetRef = useRef<any>(null);

  const errorMessage = (
    <small>! This code is incorrect. Please try again!</small>
  );

  const handleReset = () => {
    setValid(true);
    if (resetRef && resetRef.current) {
      resetRef.current.resetValue();
    }
  };

  const handleCompleted = async (emailCode: string) => {
    if (emailCode.length == codeLength) {
      const response = await sendEmailCodeForValidation(emailCode);
      if (response == 'failure') {
        setValid(false);
      } else if (response == 'success') {
        setShowContent(false);
        store.dispatch(push('/dashboard'));
      }
    }
  };

  useEffect(() => {
    if (timeRemaining === 0) return;
    setTimeout(decrementTimer, 1000);
  }, [timeRemaining]);

  useEffect(() => {
    sendEmail(email, isAuthenticated, isExternalUser)
      .then(() => {
        setShowContent(true);
        startTimer();
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setShowContent(false);
        }
      });
  }, [email]);

  const onResend = async () => {
    handleReset();
    sendEmail(email, isAuthenticated, isExternalUser).then(startTimer);
  };

  return (
    <Container>
      {isExternalUser && showContent && (
        <>
          <Main>
            <Card>
              <CardContent>
                <EmailVerification />
                <Title>Please check your email for a code</Title>
                <Body>
                  <>
                    <InputVerificationCode
                      value={''}
                      length={codeLength}
                      separatorSymbol={'-'}
                      separatorLocations={[2]}
                      autoFocus={true}
                      id={'email-code'}
                      onCompleted={handleCompleted}
                      isValid={isValid}
                      ref={resetRef}
                    />
                  </>
                  <Paragraph className="is-error">
                    {!isValid && errorMessage}
                  </Paragraph>
                  <Paragraph>
                    For your security, ClarityFirst periodically requests email
                    verification from <strong>{email}</strong>.
                  </Paragraph>
                  {timeRemaining > 0 ? (
                    <Paragraph>Resend in {timeRemaining} seconds...</Paragraph>
                  ) : (
                    <Paragraph>
                      No email?{' '}
                      <a
                        onClick={onResend}
                        id="auth_verify-email-code__resend-email"
                      >
                        Send a new code
                      </a>
                    </Paragraph>
                  )}
                  <Paragraph>
                    <small>
                      Check your spam folder and whitelist
                      clarityfirst@firstam.com if you are having trouble. Need
                      more assistance? Call (833) 627-2255
                    </small>
                  </Paragraph>
                </Body>
              </CardContent>
            </Card>
          </Main>
          <div>
            <StyledFooter />
          </div>
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: IApplicationState) => {
  const { auth } = state;
  return {
    email: state.auth.session.email,
    isAuthenticated: state.auth.session.authenticated,
    csrfToken: state.auth.session.csrfToken,
    isExternalUser: !isInternalUser(auth),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  async sendEmail(
    email: string,
    isAuthenticated: boolean,
    isExternalUser: boolean
  ) {
    try {
      if (!isExternalUser) {
        store.dispatch(push('/dashboard'));
        return;
      }

      const response = await dispatch(requestEmailVerificationCode());
      const data = response.data;

      if (data) {
        dispatch(
          updateSession({
            email: email,
            csrfToken: data.csrfToken,
            authenticated: data.authenticated,
          })
        );
      }

      toast.success(`Email sent to ${email}. Please check your inbox.`);
      return response;
    } catch (error) {
      // if the user is already verified, in the response we will get a 422 and will redirect to dashboard.
      if (error.response && error.response.status === 422) {
        store.dispatch(push('/dashboard'));
        return error.response;
      }

      if (isAuthenticated) {
        toast.error(
          'There was an unexpected error in sending the verification email. Please contact ClarityFirst customer service.'
        );
      } else {
        // since the customer will always have to verify email
        const signOutPath = '/customer/signout';
        toast(
          'The email could not be resent because your session has expired. Please click this message to sign in and try again.',
          'error',
          {
            autoClose: false,
            onClick: () => dispatch(push(signOutPath)),
          }
        );
      }
    }
  },
  async sendEmailCodeForValidation(inputCode: string) {
    try {
      const response: string = await dispatch(processEmailCode(inputCode));
      return response;
    } catch (error) {
      toast.error(`There was an error: ${error}`);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendVerificationEmail);

const Container = styled(Column).attrs({})`
  width: 100vw;
  height: 100vh;
`;

const Main = styled(Row).attrs({
  alignItems: 'center',
  justify: 'center',
})`
  flex-grow: 1;
`;

const CardContent = styled(Column).attrs({
  alignItems: 'center',
})`
  padding: 20px 30px 50px 30px;
  width: 415px;
`;

const Title = styled(Heading).attrs({
  cf: true,
  level: 2,
})`
  font-weight: normal;
  margin-bottom: 20px;
  margin-top: 0px;
`;

const Body = styled.div`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  max-width: 100%;
`;

const Paragraph = styled.p`
  margin-bottom: 26px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.is-error {
    color: ${red8};
  }
`;

const StyledFooter = styled(Footer)`
  max-width: 100vw;
`;
