import { DisplaySize } from '.';
import { Row } from 'components/ui/Box';
import styled from 'styled-components';

export const HeaderLinks = styled(Row).attrs({ gap: 'xsmall' })`
  a {
    transition-duration: 0.3s;
    transition-property: font-size, line-height;
    transition-timing-function: ease-out;
    will-change: font-size, line-height;
  }

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    a {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    display: none;
  }
`;
