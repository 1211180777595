import {
  BillingAndPaymentsTypeKeys,
  BillingAndPaymentsAction,
  IBillingAndPaymentsState,
} from './types';

export const initialState: IBillingAndPaymentsState = {
  invoiceDetail: null,
  invoiceTransactions: [],
  pagination: null,
  paymentSession: null,
  error: null,
};

export default (
  state: IBillingAndPaymentsState = initialState,
  action: BillingAndPaymentsAction
): IBillingAndPaymentsState => {
  switch (action.type) {
    case BillingAndPaymentsTypeKeys.ERROR:
      return {
        ...state,
        error: action.error,
      };

    case BillingAndPaymentsTypeKeys.CLEAR: {
      const clearPaymentSession =
        action.options && action.options.clearPaymentSession;
      const paymentSession = clearPaymentSession ? null : state.paymentSession;

      return {
        ...initialState,
        paymentSession,
      };
    }

    case BillingAndPaymentsTypeKeys.GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: action.data,
      };

    case BillingAndPaymentsTypeKeys.GET_INVOICE_TRANSACTION:
      return {
        ...state,
        invoiceTransactions: action.invoiceTransactions,
        pagination: action.pagination,
      };

    case BillingAndPaymentsTypeKeys.SET_PAYMENT_SESSION:
      return {
        ...state,
        paymentSession: action.data,
      };

    case BillingAndPaymentsTypeKeys.GET_LATEST_INVOICE:
      return {
        ...state,
        invoiceDetail: action.data,
      };

    default:
      return state;
  }
};
