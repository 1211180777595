import React from 'react';
import styled from 'styled-components';
import {
  IModalSize,
  IModalContentPaddingSize,
  IModalProps,
  IDefaultModalProps,
} from './types';
import CloseIcon from 'react-icons/lib/md/cancel';
import Button from 'components/ui/Button';
import generateRandomID from 'utils/randomId';

import {
  headerZIndex,
  white,
  noOverflowClassName,
  focusShadow,
  extremeBlack,
  blue,
  trout,
} from 'config/theme';
import { gray6, primaryBlue4 } from 'config/colors';
import { getPadding } from './helpers';

const ESC_KEY_CODE = 27;
const ENTER_KEY_CODE = 13;

export default class Modal extends React.Component<IModalProps> {
  public static defaultProps: IDefaultModalProps = {
    closeOnOutsideClick: false,
    padding: '50px',
    radius: '3px',
    showCloseButton: true,
    size: IModalSize.MD,
    closeButtonDisabled: false,
  };

  public containerRef: HTMLElement | null = null;
  private id: string = generateRandomID();

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false);
    this.toggleBodyClass(!!this.props.open);

    if (this.containerRef) {
      this.containerRef.focus();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
    this.toggleBodyClass(false);
  }

  public componentDidUpdate() {
    this.toggleBodyClass(!!this.props.open);
  }

  public render() {
    const {
      children,
      className,
      containerStyle,
      id,
      maxWidth,
      open,
      padding,
      radius,
      showCloseButton,
      size,
      dataFor,
      noOverflowScroll,
      backgroundColor,
      closeButtonDisabled,
    } = this.props;

    if (!open) {
      return null;
    }

    return (
      <Overlay
        onClick={this.handleOverlayClick}
        data-for={dataFor ? `${dataFor}-overlay` : undefined}
        noOverflowScroll={noOverflowScroll}
        backgroundColor={backgroundColor}
      >
        <Wrapper className={className} id={id}>
          <Container
            id={id ? id : `modal-container`}
            data-testid={id ? id : `modal-container`}
            style={containerStyle}
            size={size}
            padding={padding}
            radius={radius}
            maxWidth={maxWidth}
            ref={(node) => (this.containerRef = node)}
            data-for={dataFor}
          >
            {showCloseButton && (
              <StyledCloseIcons
                onClick={(e) =>
                  !closeButtonDisabled && this.handleCloseButtonClick(e)
                }
                tabIndex="0"
                className="modal-close-icon"
                data-for={dataFor ? `${dataFor}-close-btn` : undefined}
                data-idx="Modal__Close"
                disabled={closeButtonDisabled}
                id={id ? `${id}-close-icon` : undefined}
              />
            )}
            {children}
          </Container>
        </Wrapper>
      </Overlay>
    );
  }

  private handleOverlayClick = (e: any) => {
    if (!this.containerRef) return;
    const { onClose, closeOnOutsideClick } = this.props;

    if (!!closeOnOutsideClick && !this.containerRef.contains(e.target)) {
      onClose?.(e);
    }
  };

  private handleKeyPress = (e: any) => {
    const { closeOnEsc, open, onClose, onEnter } = this.props;
    if (!open || e.defaultPrevented) return;

    const { keyCode } = e;
    if (closeOnEsc && keyCode === ESC_KEY_CODE) {
      onClose?.(e);
    }
    if (onEnter && keyCode === ENTER_KEY_CODE) {
      onEnter?.();
    }
  };

  private handleCloseButtonClick = (e: any) => {
    this.props.onClose?.(e);
  };

  private toggleBodyClass = (open: boolean) => {
    if (this.props.noOverflowScroll) return;
    if (open) {
      document.body.classList.add(`${noOverflowClassName}-${this.id}`);
    } else {
      document.body.classList.remove(`${noOverflowClassName}-${this.id}`);
    }
  };
}

const Overlay = styled.div<{
  noOverflowScroll?: boolean;
  backgroundColor?: string;
}>`
  visibility: visible;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'rgba(0, 0, 0, 0.6)'};
  z-index: ${headerZIndex * 10};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  ${({ noOverflowScroll }) => (noOverflowScroll ? '' : 'overflow-y: scroll;')}
  display: flex;
  // IE 11 Bugfix
  align-items: flex-start;
`;
Overlay.displayName = 'Overlay';

const Wrapper = styled.div`
  display: flex;
  margin: auto;
  padding: 20px 0;
`;

interface IContainerProps {
  size?: IModalSize;
  padding?: string;
  radius?: string;
  maxWidth?: string;
  containerStyle?: React.CSSProperties;
}

const Container = styled.div.attrs({ role: 'dialog' })<IContainerProps>`
  background: ${white};
  border-radius: ${(props) => props.radius};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.12);
  margin: auto 0;
  max-width: ${(props) =>
    props.maxWidth ||
    (typeof props.size === 'number' ? ModalWidth[props.size] : IModalSize.MD)};
  min-height: 200px;
  padding: ${(props) => props.padding};
  position: relative;
`;

const StyledCloseIcons = styled(CloseIcon)<{ disabled: boolean }>`
  background: ${white};
  border-radius: 50%;
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  height: 34px;
  width: 34px;
  position: absolute;
  top: -17px;
  right: -17px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  color: ${({ disabled }) => (disabled ? gray6 : trout)};
  &:focus {
    box-shadow: ${focusShadow};
    outline: none;
  }
`;
StyledCloseIcons.displayName = 'StyledCloseIcons';

const ModalWidth = {
  [IModalSize.XXS]: '400px',
  [IModalSize.XS]: '465px',
  [IModalSize.SM]: '600px',
  [IModalSize.MD]: '900px',
  [IModalSize.LG]: '1200px',
};

interface IModalContentPadding {
  size?: IModalContentPaddingSize;
  showWarning?: boolean;
}

const ModalContent = styled.div<IModalContentPadding>`
  color: ${extremeBlack};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  white-space: pre-wrap;
  padding: ${(props) => getPadding(props.size)};
  font-weight: 400;

  p {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
ModalContent.displayName = 'ModalContent';

const ModalTitle = styled.div<{ color?: string; padding?: string }>`
  border-bottom: 1px solid ${primaryBlue4};
  padding: ${({ padding }) => padding || '17px 20px'};
  color: ${({ color }) => color || blue};
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
`;
ModalTitle.displayName = 'ModalTitle';

const ModalActions = styled.div<{ padding?: string }>`
  border-top: 1px solid ${primaryBlue4};
  padding: ${({ padding }) => padding || '20px 20px 20px'};
  text-align: right;
  width: 100%;
  ${Button} {
    height: 40px;
    line-height: 24px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export { IModalSize, ModalContent, ModalTitle, ModalActions };
