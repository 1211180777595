/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserDevicesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Registers a user's device with the given token. (Auth)
   * @returns any Device registered successfully
   * @throws ApiError
   */
  public register({
    token,
  }: {
    /**
     * the token to register.
     */
    token: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/_me/devices/{token}',
      path: {
        'token': token,
      },
      errors: {
        401: `Invalid authorization header`,
      },
    });
  }

  /**
   * Unregister a user's device with the given token. (Auth)
   * @returns void
   * @throws ApiError
   */
  public unregister({
    token,
  }: {
    /**
     * the token to unregister.
     */
    token: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/users/_me/devices/{token}',
      path: {
        'token': token,
      },
      errors: {
        401: `Invalid authorization header`,
        404: `Device with that token was not previously registered`,
      },
    });
  }

}
