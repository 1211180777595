/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHomeownerAssociationChargeViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHomeownerAssociationChargeViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_HomeownerAssociationChargeDetailViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_HomeownerAssociationChargeDetailViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_SingleChargeAllocatableLinesViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_SingleChargeAllocatableLinesViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HomeownerAssociationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Adds new escrow charge process (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel Escrow charge process was created
   * @throws ApiError
   */
  public addChargeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * An object containing all information needed to create escrow charge process
     */
    requestBody: Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHomeownerAssociationChargeViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/homeowner-association',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Adds new escrow charge process with full allocation to given site (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel Escrow charge process was created
   * @throws ApiError
   */
  public addChargeWithFullAllocationAsync({
    orderId,
    siteFileId,
    requestBody,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Site Id which will be allocated
     */
    siteFileId: number,
    /**
     * An object containing all information needed to create escrow charge process
     */
    requestBody: Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHomeownerAssociationChargeViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/homeowner-association/site/{siteFileId}',
      path: {
        'orderId': orderId,
        'siteFileId': siteFileId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Updates existing escrow charge process (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel Escrow charge process was updated
   * @throws ApiError
   */
  public updateChargeAsync({
    orderId,
    chargeId,
    requestBody,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the escrow charge process
     */
    chargeId: number,
    /**
     * An object containing all information needed to update escrow charge process
     */
    requestBody: Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHomeownerAssociationChargeViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ChargeIdAndTypeViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/charges/homeowner-association/{chargeId}',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order or Charge Not Found`,
      },
    });
  }

  /**
   * Deletes existing escrow charge process (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteChargeAsync({
    orderId,
    chargeId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * A particular escrow charge process Id
     */
    chargeId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/charges/homeowner-association/{chargeId}',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Gets escrow charge process details (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_HomeownerAssociationChargeDetailViewModel Escrow charge process was found
   * @throws ApiError
   */
  public getChargeAsync({
    orderId,
    chargeId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    chargeId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_HomeownerAssociationChargeDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/homeowner-association/{chargeId}',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Gets list of allocatable charge lines (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_SingleChargeAllocatableLinesViewModel Success
   * @throws ApiError
   */
  public getAllocatableLinesAsync({
    orderId,
    chargeId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Id of the charge
     */
    chargeId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_SingleChargeAllocatableLinesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/homeowner-association/{chargeId}/allocatable-lines',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }

}
