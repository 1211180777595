import { IPagination } from 'store/common/types';
import { SiteType } from 'components/SiteCard/types';
import { ActivityRightValue } from 'store/permissions/types';
import { IFilters } from 'store/searchFilters/types';
import { ISorting } from 'store/searchSorting/types';
import { IOrderBusinessParty } from 'store/orders/types';

export enum SearchTypeKeys {
  REQUEST = 'search/REQUEST',
  SUCCESS = 'search/SUCCESS',
  ERROR = 'search/ERROR',
  CLEAR = 'search/CLEAR',
  SET_QUERY = 'search/SET_QUERY',
  SET_TYPE = 'search/SET_TYPE',
  SET_PAGE = 'search/SET_PAGE',
  SET_SITETYPE = 'search/SET_SITETYPE',
}

export interface ISearchState {
  aggregations: Aggregations;
  countByFileType: ICountByFileType;
  error: null | string;
  loading: boolean;
  page: number;
  pagination: Pagination;
  query: string;
  results: ISearchResult[];
  searchType: SearchType;
}

export interface ICountByFileType {
  project: string;
  single: string;
  site: string;
  total: string;
}

export interface ISearchRequestAction {
  type: SearchTypeKeys.REQUEST;
  searchType: SearchType;
  query: string;
}

export interface ISearchSuccessAction {
  type: SearchTypeKeys.SUCCESS;
  results: ISearchResult[];
  pagination: Pagination;
  aggregations: Aggregations;
  countByFileType: ICountByFileType;
}

export interface ISearchErrorAction {
  type: SearchTypeKeys.ERROR;
  error: string;
}

export interface ISearchClearAction {
  type: SearchTypeKeys.CLEAR;
}

export interface ISearchSetTypeAction {
  type: SearchTypeKeys.SET_TYPE;
  searchType: SearchType;
}

export interface ISearchSetQueryAction {
  type: SearchTypeKeys.SET_QUERY;
  query: string;
}

export interface ISearchSetPageAction {
  type: SearchTypeKeys.SET_PAGE;
  page: number;
}

export type SearchAction =
  | ISearchRequestAction
  | ISearchSuccessAction
  | ISearchErrorAction
  | ISearchClearAction
  | ISearchSetTypeAction
  | ISearchSetQueryAction
  | ISearchSetPageAction;

export enum SearchType {
  All = '_all',
  Files = 'files',
  Documents = 'documents',
  Locations = 'locations',
  Parties = 'parties',
  APNs = 'apns',
  Offices = 'offices',
}

export type Pagination = null | IPagination;

interface IBucket {
  states?: IAggregation[];
  cities?: IAggregation[];
}

export interface IAggregation {
  key: string;
  docCount: number;
  buckets?: IBucket;
}

export interface IAggregations {
  status: IAggregation;
  liabilityMin: IAggregation;
  liabilityMax: IAggregation;
  closeDate: IAggregation;
  offices: IAggregation[];
}

type Aggregations = null | IAggregations;

// TODO: Move this to File types
export interface ISearchResult {
  id: string;
  name: string;
  address: string;
  fileNumber: string;
  apn: string;
  businessPartyAndEmployees: IOrderBusinessParty[];
  status: string;
  fileID: null | string;
  fileType: SiteType;
  liability: number;
  closeDate: string;
  openDate: string;
  estimatedSettlementDate: null | string;
  messagesCount: number;
  usersCount: number;
  projectFile?: {
    fileId: number;
    fileNumber: string;
  };
  propertiesCount: number;
  loanLiability?: number;
  ownerLiability?: number;
  transactionType?: string;
  loanAmounts?: Array<{
    liabilityAmount?: number;
  }>;
  addresses: Array<{
    city?: string;
    county?: string;
    state?: string;
    zip?: string;
    street?: string;
  }>;
  businessParties: any;
  buyers: any;
  sellers: any;
  offices: any;
  fastFileUrl: null | string;
  isCloseDateEstimated: boolean;
  invoices: IInvoiceSearchResult[];
  deposits: IDepositCheckSearchResult[];
  disbursements: IDisbursementCheckSearchResult[];
  _meta?: {
    highlights?: Array<{
      field: string;
      value: string;
    }>;
    permissions: ActivityRightValue[];
    projectFileData?: {
      cityCount: number;
      countyCount: number;
      isCloseDateEstimated?: boolean;
      latestClosingDate: string | null;
      orderTypes: string[];
      stateCount: number;
      statuses: {
        closed?: number;
        open?: number;
      };
      totalLiability: number;
    };
  };
}

export interface ISearchURLParams {
  type: SearchType;
  query: string;
  page: number | null;
  filters: IFilters;
  sorting: ISorting;
}

export interface IInvoiceSearchResult {
  invoiceNumber: string;
}

export interface IDepositCheckSearchResult {
  checkNumber: string;
}

export interface IDisbursementCheckSearchResult {
  checkNumber: string;
}

export interface INavigateSearchParams {
  type?: SearchType;
  query?: string;
  page?: number;
  filters?: IFilters;
  sorting?: ISorting;
}

export enum SearchLoadingKeys {
  searchRequestLoading = 'searchRequestLoading',
}

export enum BorrowerTransactionTypes {
  Refinance = 'Refinance',
  SecondMortgage = 'Second Mortgage',
  EquityLoan = 'Equity Loan',
  MtgModwEndorsement = 'Mtg Mod w/Endorsement',
  MtgModwIncreasedLiability = 'Mtg Mod w/Increased Liability',
  ConstructionFinance = 'Construction Finance',
  ConstructionDisbursement = 'Construction Disbursement',
  MortgageModification = 'Mortgage Modification',
}
