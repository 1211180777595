import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IApplicationState, Dispatch } from 'store';
import { getLatestAppNotificationsAndResetCounter } from 'store/appNotifications/actions';
import { IAppNotification } from 'store/appNotifications/types';
import { extremeBlack, linkWater, lightBlue } from 'config/theme';
import {
  NotificationRow,
  NotificationRowTheme,
  NotificationsBlankSlate,
  NotificationsBlankSlateTheme,
} from './index';

interface INotificationsDropdownProps {
  dispatch: Dispatch;
  loading: boolean;
  notifications: IAppNotification[];
  onNotificationClick: () => void;
  onViewAllClick: () => void;
}

export class NotificationsDropdown extends React.Component<
  INotificationsDropdownProps
> {
  public componentDidMount() {
    this.props.dispatch(getLatestAppNotificationsAndResetCounter());
  }

  public render() {
    const {
      loading,
      notifications,
      onNotificationClick,
      onViewAllClick,
    } = this.props;
    const isEmpty = notifications.length === 0;

    return (
      <Container>
        <Header>
          <Title>Notifications</Title>
        </Header>
        <NotificationsList>
          {!loading && isEmpty && (
            <NotificationsBlankSlate
              theme={NotificationsBlankSlateTheme.SMALL}
            />
          )}
          {notifications.map((n: IAppNotification, idx: number) => (
            <NotificationRow
              key={`dropdownNotificationRow${idx}${n.id}`}
              notification={n}
              theme={NotificationRowTheme.SMALL}
              onClick={onNotificationClick}
            />
          ))}
        </NotificationsList>
        <Footer>
          {isEmpty ? (
            <span>View All</span>
          ) : (
            <Link to="/notifications" onClick={onViewAllClick}>
              View All
            </Link>
          )}
        </Footer>
      </Container>
    );
  }
}

function mapStateToProps(state: IApplicationState) {
  const {
    appNotifications: { latest },
    ui: { loaders },
  } = state;

  return {
    loading: loaders.appNotificationsDropdownLoading,
    notifications: latest.notifications,
  };
}

export default connect(mapStateToProps)(NotificationsDropdown);

const Container = styled.div`
  max-height: 660px;
`;

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${linkWater};
  display: flex;
  height: 50px;
  padding: 20px 17px;
`;

const Footer = styled.div`
  align-items: center;
  color: ${lightBlue};
  border-top: 1px solid ${linkWater};
  display: flex;
  height: 50px;
  justify-content: center;
  & > a {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
  }
  & > span {
    opacity: 0.3;
  }
`;

const Title = styled.div`
  color: ${extremeBlack};
  font-size: 14px;
  font-weight: 350;
  line-height: 18px;
`;

const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
`;
