/* eslint @typescript-eslint/no-empty-function: 0 */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type ScrollTarget = HTMLElement | null | undefined;

interface IScrollToProps extends RouteComponentProps {
  children: React.ReactNode | React.ReactNodeArray;
}

export const ScrollTopContext = React.createContext<
  (targetElement: HTMLElement | null) => void
>(() => {});
export const ScrollToTopPageConfigContext = React.createContext<{
  setShouldScrollToTop: (value: boolean) => void;
}>({
  setShouldScrollToTop: () => {},
});

class ScrollToTop extends React.Component<IScrollToProps> {
  public static readonly contextType = ScrollTopContext;

  public state = {
    shouldScrollToTop: true,
  };

  public targetElement: ScrollTarget = undefined;
  public setTargetElement = (targetElement: HTMLElement | null): void => {
    this.targetElement = targetElement;
  };

  public componentDidUpdate(prevProps) {
    const { targetElement } = this;

    if (!this.state.shouldScrollToTop) {
      return;
    }

    if (this.props.location === prevProps.location) {
      return;
    } else if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.targetElement = undefined;
    }

    if (targetElement === null) {
      return;
    } else if (targetElement) {
      const headerElement =
        document.getElementById('orderHeader') ||
        document.getElementById('accountingHeader') ||
        document.querySelector('[data-component=HeaderContainer]');
      const headerBottomY = headerElement?.getBoundingClientRect().bottom || 0;
      const targetElementY =
        targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo(0, targetElementY - headerBottomY);
    } else {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return (
      <ScrollTopContext.Provider value={this.setTargetElement}>
        <ScrollToTopPageConfigContext.Provider
          value={{
            setShouldScrollToTop: (value) =>
              this.setState({ shouldScrollToTop: value }),
          }}
        >
          {this.props.children}
        </ScrollToTopPageConfigContext.Provider>
      </ScrollTopContext.Provider>
    );
  }
}

export default withRouter(ScrollToTop);
