/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Order1099s_Order1099sSellerRecordViewModel } from '../models/Workspace_API_ViewModels_Order1099s_Order1099sSellerRecordViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class Order1099SCustomerESignService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Method to return the signature url (Auth)
   * @returns string Successfully started the workflow and returned the external form url
   * @throws ApiError
   */
  public getSigningUrlAsync({
    code,
  }: {
    /**
     * The ClarityFirst Request Code
     */
    code: string | null,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/1099s-customer-e-sign/{code}/url',
      path: {
        'code': code,
      },
      errors: {
        400: `Unable to retrieve workflow url`,
        401: `Invalid Authorization header`,
        410: `E-Signature already completed`,
      },
    });
  }

  /**
   * Given a 1099-S seller request code, will update the ClarityFirst database with information
   * of the completed e-signature. (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public signerCompleteAsync({
    code,
  }: {
    /**
     * The 1099-S seller request code
     */
    code: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/1099s-customer-e-sign/{code}/signer-complete',
      path: {
        'code': code,
      },
    });
  }

  /**
   * This endpoint checks an Order1099s record for a given Request Code. (Auth)
   * @returns any 1099s record exits and can be fetched.
   * @throws ApiError
   */
  public checkOrder1099SCode({
    code,
  }: {
    /**
     * The Request Code of the 1099s Association.
     */
    code: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'HEAD',
      url: '/ncs/v1/1099s-customer-e-sign/{code}',
      path: {
        'code': code,
      },
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to the 1099s record.`,
        404: `1099s record does not exist or not ready for verification.`,
        409: `2FA required.`,
        410: `1099s action is already completed`,
      },
    });
  }

  /**
   * Sends a verification email for the given 1099s record using guid code
   * @returns void
   * @throws ApiError
   */
  public sendVerification({
    code,
  }: {
    /**
     * The Request Code of the 1099s record.
     */
    code: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/1099s-customer-e-sign/{code}/_send-verification',
      path: {
        'code': code,
      },
      errors: {
        403: `User is not permitted to access this 1099s record`,
        404: `1099s not found`,
        409: `1099s not in the correct status`,
      },
    });
  }

  /**
   * Method to return the Incomplete E-Sign Requests for an user (Auth)
   * @returns Workspace_API_ViewModels_Order1099s_Order1099sSellerRecordViewModel
   * @throws ApiError
   */
  public getIncompleteESignRequestsAsync(): CancelablePromise<Array<Workspace_API_ViewModels_Order1099s_Order1099sSellerRecordViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/1099s-customer-e-sign/incomplete-requests',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
      },
    });
  }

}
