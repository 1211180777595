import { ResourceTypeKeys, IResourceState } from './types';

export const initialState: IResourceState = {
  showClarityFirstVideo: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ResourceTypeKeys.SET_CLARITYFIRST_VIDEO:
      return {
        ...state,
        showClarityFirstVideo: action.showClarityFirstVideo,
      };
    default:
      return state;
  }
};
