import React from 'react';

export default () => (
  <svg
    width="154"
    height="166"
    viewBox="0 0 154 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9847 1.00244H108.231L136.729 29.5471V133.037C136.729 135.008 135.136 136.604 133.168 136.604H36.9847C35.0169 136.604 33.4238 135.008 33.4238 133.037V4.57237C33.4238 2.60123 35.0169 1.00244 36.9847 1.00244Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.729 29.5476L108.231 1.00293H36.9847C35.0169 1.00293 33.4209 2.59876 33.4209 4.56991V18.8437C33.4209 16.8726 35.0169 15.2738 36.9847 15.2738H108.231V25.9806C108.231 27.9518 109.827 29.5476 111.794 29.5476H136.729Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5503 1H108.586L137 29.6286V133.423C137 135.399 135.412 137 133.45 137H37.5503C35.5884 137 34 135.399 34 133.423V4.58043C34 2.60349 35.5884 1 37.5503 1Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.729 29.5476L108.23 1V25.9807C108.23 27.9518 109.826 29.5476 111.794 29.5476H136.729Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137 30L109 1V26.3765C109 28.3789 110.568 30 112.501 30H137Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51 20C51 19.4477 51.4477 19 52 19H96C96.5523 19 97 19.4477 97 20C97 20.5523 96.5523 21 96 21H52C51.4477 21 51 20.5523 51 20Z"
      fill="#9BABC2"
    />
    <path
      d="M51 32C51 31.4477 51.4477 31 52 31H96C96.5523 31 97 31.4477 97 32C97 32.5523 96.5523 33 96 33H52C51.4477 33 51 32.5523 51 32Z"
      fill="#9BABC2"
    />
    <path
      d="M48 44C48 43.4477 48.4477 43 49 43H119C119.552 43 120 43.4477 120 44C120 44.5523 119.552 45 119 45H49C48.4477 45 48 44.5523 48 44Z"
      fill="#9BABC2"
    />
    <path
      d="M48 56C48 55.4477 48.4477 55 49 55H119C119.552 55 120 55.4477 120 56C120 56.5523 119.552 57 119 57H49C48.4477 57 48 56.5523 48 56Z"
      fill="#9BABC2"
    />
    <rect x="83" y="67" width="37" height="2" rx="1" fill="#9BABC2" />
    <rect x="93" y="79" width="27" height="2" rx="1" fill="#9BABC2" />
    <rect x="97" y="91" width="22" height="2" rx="1" fill="#9BABC2" />
    <rect x="97" y="103" width="21" height="2" rx="1" fill="#9BABC2" />
    <rect x="97" y="115" width="22" height="2" rx="1" fill="#9BABC2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 127C27.7809 149.995 51.7013 166 79.5 166C107.299 166 131.219 149.995 142 127H17Z"
      fill="#F5F7FA"
    />
    <rect x="3" y="126" width="6" height="2" rx="1" fill="#9BABC2" />
    <rect x="148" y="126" width="6" height="2" rx="1" fill="#9BABC2" />
    <rect x="11" y="126" width="135" height="2" rx="1" fill="#9BABC2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4501 147.417C36.4501 148.743 43.5722 149.817 52.3589 149.817C61.1455 149.817 68.2676 148.743 68.2676 147.417C68.2676 146.09 61.1455 145.015 52.3589 145.015C43.5722 145.015 36.4501 146.09 36.4501 147.417Z"
      fill="#020202"
      fillOpacity="0.15"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6124 132.159L25.3638 138.954L33.2393 131.029L26.4866 124.235L18.6124 132.159Z"
      fill="#ADC4D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6124 132.159L25.3638 138.954L33.2393 131.029L26.4866 124.235L18.6124 132.159Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86337 145.744C0.378456 148.246 0.378456 152.3 2.86337 154.801C5.34829 157.301 9.37806 157.301 11.863 154.801L25.3617 141.216C25.9823 140.59 25.9823 139.576 25.3617 138.95L18.6117 132.159C17.9911 131.534 16.984 131.534 16.3621 132.159L2.86337 145.744Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6123 132.159L21.9873 135.554C21.3654 134.93 20.3583 134.93 19.7377 135.554L6.23899 149.139C4.35098 151.041 3.90527 153.833 4.88477 156.163C4.15113 155.85 3.46088 155.402 2.86397 154.8C0.379055 152.3 0.379055 148.245 2.86397 145.744L16.3627 132.159C16.9833 131.533 17.9917 131.533 18.6123 132.159Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86337 145.744C0.378456 148.246 0.378456 152.3 2.86337 154.801C5.34829 157.301 9.37806 157.301 11.863 154.801L25.3617 141.216C25.9823 140.59 25.9823 139.576 25.3617 138.95L18.6117 132.159C17.9911 131.534 16.984 131.534 16.3621 132.159L2.86337 145.744Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9529 104.99C18.9529 123.558 33.9097 138.611 52.3612 138.611C70.8114 138.611 85.7695 123.558 85.7695 104.99C85.7695 86.4212 70.8114 71.3689 52.3612 71.3689C33.9097 71.3689 18.9529 86.4212 18.9529 104.99Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.3602 77.7726C34.9829 77.7726 20.7163 91.1273 19.1123 108.192C19.0137 107.136 18.9519 106.071 18.9519 104.99C18.9519 86.4208 33.9101 71.3685 52.3602 71.3685C70.8117 71.3685 85.7686 86.4208 85.7686 104.99C85.7686 106.071 85.7068 107.136 85.6082 108.192C84.0041 91.1273 69.7362 77.7726 52.3602 77.7726Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9529 104.99C18.9529 123.558 33.9097 138.611 52.3612 138.611C70.8114 138.611 85.7695 123.558 85.7695 104.99C85.7695 86.4212 70.8114 71.3689 52.3612 71.3689C33.9097 71.3689 18.9529 86.4212 18.9529 104.99Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1116 104.99C26.1116 119.58 37.8643 131.407 52.361 131.407C66.8576 131.407 78.6104 119.58 78.6104 104.99C78.6104 90.4012 66.8576 78.5735 52.361 78.5735C37.8643 78.5735 26.1116 90.4012 26.1116 104.99Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.361 78.5737C66.8576 78.5737 78.6104 90.4002 78.6104 104.991C78.6104 112.285 75.6718 118.889 70.9216 123.67L33.8004 86.3116C38.5506 81.5297 45.1126 78.5737 52.361 78.5737Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1116 104.99C26.1116 119.58 37.8643 131.407 52.361 131.407C66.8576 131.407 78.6104 119.58 78.6104 104.99C78.6104 90.4012 66.8576 78.5735 52.361 78.5735C37.8643 78.5735 26.1116 90.4012 26.1116 104.99Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.8937 115.459C38.0531 114.619 38.0531 113.26 38.8937 112.42L44.984 106.34C45.4043 105.92 45.4043 105.239 44.984 104.818L38.8937 98.7382C38.0531 97.8991 38.0531 96.5385 38.8937 95.6982L42.7004 91.8992C43.5409 91.059 44.9039 91.059 45.7456 91.8992L51.8359 97.9791C52.2562 98.3986 52.9388 98.3986 53.3591 97.9791L59.4494 91.8992C60.29 91.059 61.6541 91.059 62.4946 91.8992L66.3014 95.6982C67.143 96.5385 67.143 97.8991 66.3014 98.7382L60.211 104.818C59.7907 105.239 59.7907 105.92 60.211 106.34L66.3014 112.42C67.143 113.26 67.143 114.619 66.3014 115.459L62.4946 119.258C61.6541 120.099 60.29 120.099 59.4494 119.258L53.3591 113.179C52.9388 112.759 52.2562 112.759 51.8359 113.179L45.7456 119.258C44.9039 120.099 43.5409 120.099 42.7004 119.258L38.8937 115.459Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.7456 91.8991L51.8359 97.9789C52.2573 98.3985 52.9388 98.3985 53.3591 97.9789L59.4494 91.8991C60.2911 91.0589 61.6541 91.0589 62.4946 91.8991L66.3014 95.6981C67.143 96.5384 67.143 97.899 66.3014 98.738L60.211 104.818C59.7907 105.239 59.7907 105.92 60.211 106.34L66.3014 112.419C67.143 113.258 67.143 114.619 66.3014 115.459L63.9272 117.829L40.3263 94.2691L42.7004 91.8991C43.5409 91.0589 44.905 91.0589 45.7456 91.8991Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.8936 115.459C38.0531 114.619 38.0531 113.26 38.8936 112.42L44.984 106.34C45.4043 105.92 45.4043 105.239 44.984 104.818L38.8936 98.7382C38.0531 97.8991 38.0531 96.5385 38.8936 95.6982L42.7004 91.8992C43.5409 91.059 44.9039 91.059 45.7456 91.8992L51.8359 97.9791C52.2562 98.3986 52.9388 98.3986 53.3591 97.9791L59.4494 91.8992C60.29 91.059 61.6541 91.059 62.4946 91.8992L66.3014 95.6982C67.143 96.5385 67.143 97.8991 66.3014 98.7382L60.211 104.818C59.7907 105.239 59.7907 105.92 60.211 106.34L66.3014 112.42C67.143 113.26 67.143 114.619 66.3014 115.459L62.4946 119.258C61.6541 120.099 60.29 120.099 59.4494 119.258L53.3591 113.179C52.9388 112.759 52.2562 112.759 51.8359 113.179L45.7456 119.258C44.9039 120.099 43.5409 120.099 42.7004 119.258L38.8936 115.459Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
