import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="28"
    height="25"
    viewBox="0 0 28 25"
    fill={props.fill || '#345580'}
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M14.6562 6.11484V1.71484L9.03125 7.21484L14.6562 12.7148V8.31484C16.4465 8.31484 18.1634 9.0102 19.4292 10.2479C20.6951 11.4857 21.4062 13.1644 21.4062 14.9148C21.4062 16.6653 20.6951 18.344 19.4292 19.5817C18.1634 20.8195 16.4465 21.5148 14.6562 21.5148C12.866 21.5148 11.1491 20.8195 9.88328 19.5817C8.61741 18.344 7.90625 16.6653 7.90625 14.9148H5.65625C5.65625 17.2487 6.60446 19.4871 8.29229 21.1374C9.98012 22.7877 12.2693 23.7148 14.6562 23.7148C17.0432 23.7148 19.3324 22.7877 21.0202 21.1374C22.708 19.4871 23.6562 17.2487 23.6562 14.9148C23.6562 12.5809 22.708 10.3426 21.0202 8.6923C19.3324 7.04198 17.0432 6.11484 14.6562 6.11484Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
