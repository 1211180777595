import React from 'react';
import styled from 'styled-components';
import { lighterBlue, trout } from 'config/theme';
import Heading from './Heading';
import { Row as GridRow, Col as GridColumn } from 'react-styled-flexboxgrid';
import { Column } from './Box';
import { primaryBlue2 } from 'config/colors';

interface IComponentWithChildren {
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
}

export const CONTAINER_PADDING = 24;
export const SECTION_BORDER = `1px solid ${lighterBlue}`;

export function CardHeader(
  props: {
    hasBottomBorder?: boolean;
    style?: React.CSSProperties;
  } & IComponentWithChildren
) {
  return (
    <StyledHeader
      level={5}
      cf
      className={props.className}
      hasBottomBorder={props.hasBottomBorder}
      style={props.style}
    >
      {props.children}
    </StyledHeader>
  );
}

export function CardSection(
  props: {
    title: React.ReactNode;
    withoutBorder?: boolean;
    titleContent?: React.ReactNode;
  } & IComponentWithChildren
) {
  return (
    <StyledSection
      className={props.className}
      withoutBorder={props.withoutBorder}
    >
      <GridRow>
        <GridColumn xs={true} sm={true} md={true} lg={true} xl={true}>
          <SectionTitle level={6} cf>
            {props.title}
          </SectionTitle>
          {props.titleContent}
        </GridColumn>
        <GridColumn xs={8} sm={8} md={8} lg={8} xl={8}>
          {props.children}
        </GridColumn>
      </GridRow>
    </StyledSection>
  );
}

export function ReadonlyCardColumn(
  props: { label: string; noMargin?: boolean } & IComponentWithChildren
) {
  return (
    <LabeledColumn className={props.className} noMargin={props.noMargin}>
      <label>{props.label}</label>
      {props.children}
    </LabeledColumn>
  );
}

const LabeledColumn = styled(Column).attrs({ justify: 'start' })<{
  noMargin?: boolean;
}>`
  margin-bottom: ${(props) => (props.noMargin ? '0' : '50px')};
  width: 50%;
  overflow-wrap: anywhere;

  label {
    color: ${trout};
    font-size: 12px;
    line-height: 18px;
  }

  :not(label) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledHeader = styled(Heading)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: ${CONTAINER_PADDING}px;
  ${({ hasBottomBorder }) =>
    hasBottomBorder ? `border-bottom: 1px solid ${primaryBlue2}` : ''}
`;

const StyledSection = styled.section<{ withoutBorder?: boolean }>`
  border-top: ${({ withoutBorder }) =>
    withoutBorder ? 'none' : SECTION_BORDER};
  padding: ${CONTAINER_PADDING}px;
  ${({ withoutBorder }) =>
    withoutBorder
      ? `
    padding-top: 0;
    `
      : ''}
`;

const SectionTitle = styled(Heading)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 440px;
  display: inline;
`;

export default styled.div<{ borderRadius?: string }>`
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  ${({ borderRadius }) =>
    !borderRadius
      ? ''
      : `
    border-radius: ${borderRadius};
  `}
`;
