import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="100%"
    height="20"
    viewBox="8 0 700 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M52 11.5001H756.5V92C756.5 95 755 95.5 753.5 95.5H2.9041C1.30022 95.5 0.5 94.1569 0.5 92.5V11.5001H29.6893L41 0.5L52 11.5001Z"
      fill="#F5F7FA"
    />
    <path d="M0 12H30L41 1L52 12H757" stroke="#C6D1E0" />
  </svg>
);
