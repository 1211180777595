import { ISearchClearAction } from 'store/search/types';

export enum SortingTypeKeys {
  SET = 'sorting/SET',
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortingField {
  ESTIMATED_OPEN_DATE = 'openDate',
  ESTIMATED_CLOSE_DATE = 'closeDate',
  FILE_NUMBER = 'number',
  FILE_STATUS = 'status',
  DEFAULT = '',
}

export interface ISorting {
  direction: SortingDirection;
  field: SortingField;
}

export interface ISortingSet {
  type: SortingTypeKeys.SET;
  sorting: ISorting;
}

export type SortingAction = ISortingSet | ISearchClearAction;
