export interface IAllocatableItemChargeBase {
  id: number;
  description: string;
  buyerCharge?: number;
  sellerCharge?: number;
}

export interface ISites {
  fileNumber: string;
  fileName: string;
  buyerChargeAmount: number;
  sellerChargeAmount: number;
  fileId: number;
  buyerCreditAmount: number;
  sellerCreditAmount: number;
  buyerCharge?: { dollar: number; percentage: number };
  sellerCharge?: { dollar: number; percentage: number };
  buyerCredit?: { dollar: number; percentage: number };
  sellerCredit?: { dollar: number; percentage: number };
}
interface IAllocatableItemBase extends IAllocatableItemChargeBase {
  buyerCredit?: number;
  sellerCredit?: number;
}

interface IAllocatableItem extends IAllocatableItemBase {
  typeId: number;
  typeDescription: string;
  allocatableAmount?: number;
  totalCharge?: number;
  remainingCharge?: number;
  feeId?: number;
  allocations?: IChargeLineAllocation[];
}

export interface IAllocatedItem extends IAllocatableItemBase {
  allocatableAmount?: number;
}

export interface IChargeLine extends IAllocatableItem {
  chargeId: number;
  businessPartyName: string;
  businessOrganizationId?: number;
  remainingAmount?: number;
  allocated?: number;
  amount?: number;
  remaining?: number;
  sites?: ISites[];
  charges?: IChargeLineAllocation[];
  lender?: string;
}

export interface IHoldFund extends IAllocatableItemChargeBase {
  heldFrom: string;
}
export interface IManageAllocationAllocatableLine extends IAllocatableItem {
  allocatedTotal: number;
  remainingAmount: number;
  allocatableAmount: number;
  sites?: ISites[];
}

export type IManageAllocationChargeLine = IChargeLine &
  IManageAllocationAllocatableLine;

export interface IAllocationBase {
  siteFileId: number;
  buyerChargeAmount: number;
  buyerChargePercentage: number;
  sellerChargeAmount: number;
  sellerChargePercentage: number;
}

interface IAllocation extends IAllocationBase {
  buyerCreditAmount: number;
  buyerCreditPercentage: number;
  sellerCreditAmount: number;
  sellerCreditPercentage: number;
}

export interface IChargeLineAllocation extends IAllocation {
  chargeId?: number;
  chargeLineId?: number;
  remainingAmount?: number;
}

export interface IAdjustmentAllocation extends IAllocation {
  adjustmentId?: number;
}

export interface IProrationAllocation extends IAllocation {
  prorationId?: number;
}

export interface IHoldFundAllocation extends IAllocation {
  holdFundId?: number;
}

export type ProrationOrAdjustmentType = 'proration' | 'adjustment';
export interface IProrationOrAdjustmentAllocatableItem
  extends IAllocatableItem {
  itemType?: ProrationOrAdjustmentType;
}

export interface IManageAllocationProrationLine
  extends IManageAllocationAllocatableLine {
  fromDate: string;
  toDate: string;
  duePeriod: string;
  amount: number;
  sites?: ISites[];
}

export enum JobStatus {
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  SUCCESS = 'Completed',
  SCHEDULED = 'Scheduled',
}

export enum JobResultStatus {
  FAILED = 'Fail',
  SUCCESS = 'Success',
}
