import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || neutralGray7}
      d="M22.9642 2.96634L21.0181 1.02027L18.4496 3.58882L16.527 1.46318L16.3728 7.46119L22.3711 7.61533L20.3956 5.53489L22.9642 2.96634ZM14.8002 2.40007C12.6684 1.77822 10.3902 1.88367 8.32492 2.69979C6.25963 3.5159 4.52476 4.99625 3.39385 6.9074C2.26295 8.81855 1.80036 11.0518 2.07904 13.2549C2.35771 15.458 3.36179 17.5057 4.93295 19.0751C6.5041 20.6444 8.55294 21.6462 10.7564 21.9224C12.9598 22.1985 15.1925 21.7334 17.1024 20.6003C19.0122 19.4672 20.4906 17.7307 21.3044 15.6644C22.1181 13.5982 22.221 11.3199 21.5967 9.1888L19.6746 9.75185C20.1738 11.4561 20.0916 13.2781 19.4408 14.9305C18.79 16.5829 17.6078 17.9716 16.0804 18.8777C14.5531 19.7839 12.7676 20.1559 11.0055 19.935C9.24335 19.7142 7.60487 18.9131 6.3484 17.658C5.09193 16.403 4.28896 14.7654 4.0661 13.0036C3.84324 11.2417 4.21318 9.45576 5.11757 7.92739C6.02197 6.39903 7.40937 5.21518 9.06099 4.56252C10.7126 3.90987 12.5345 3.82554 14.2394 4.32283L14.8002 2.40007ZM12.2292 11.351C10.8913 11.0233 10.4611 10.6844 10.4611 10.1566C10.4611 9.55105 11.0564 9.12882 12.0524 9.12882C13.1015 9.12882 13.4905 9.60104 13.5259 10.2955H14.8285C14.7872 9.33993 14.1683 8.46216 12.9365 8.17882V6.96216H11.1683V8.16216C10.0249 8.39549 9.10547 9.09549 9.10547 10.1677C9.10547 11.451 10.2312 12.0899 11.8756 12.4622C13.3491 12.7955 13.6438 13.2844 13.6438 13.801C13.6438 14.1844 13.355 14.7955 12.0524 14.7955C10.8383 14.7955 10.3609 14.2844 10.296 13.6288H8.99938C9.07011 14.8455 10.0367 15.5288 11.1683 15.7566V16.9622H12.9365V15.7677C14.0858 15.5622 14.9994 14.9344 14.9994 13.7955C14.9994 12.2177 13.5672 11.6788 12.2292 11.351Z"
    />
  </svg>
);
