/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_Models_BulkInvitations_ValidateEmailsResponse } from '../models/Workspace_API_Models_BulkInvitations_ValidateEmailsResponse';
import type { Workspace_Common_Requests_InvitationsData } from '../models/Workspace_Common_Requests_InvitationsData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BulkInvitationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This endpoint reads file and verifies that its contents are valid (Auth)
   * @returns Workspace_API_Models_BulkInvitations_ValidateEmailsResponse Ok. File has been parsed and validated.
   * @throws ApiError
   */
  public validateEmailsAsync(): CancelablePromise<Workspace_API_Models_BulkInvitations_ValidateEmailsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/bulk-invitations/parse',
      errors: {
        400: `Bad Request. File can not be found in request or file corrupted.`,
        403: `Forbidden. User doesn't have right to create new accounts.`,
        404: `Not Found. User does not exist.`,
        422: `Unprocessable Entity. File can not be parsed.`,
      },
    });
  }

  /**
   * This endpoint submits emails to background processing (Auth)
   * @returns any Accepted. Request has been accepted for further processing.
   * @throws ApiError
   */
  public sendInvitationsAsync({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_InvitationsData,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/bulk-invitations/invite',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request. File can not be found in request or file corrupted.`,
        403: `Forbidden. User doesn't have right to create new accounts.`,
        502: `Bad Gateway. Background process did not accept the job`,
      },
    });
  }

}
