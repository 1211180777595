import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || neutralGray7}
      d="M16.6666 6.66675H9.99992C9.08325 6.66675 8.34159 7.41675 8.34159 8.33341L8.33325 21.6667C8.33325 22.5834 9.07492 23.3334 9.99159 23.3334H19.9999C20.9166 23.3334 21.6666 22.5834 21.6666 21.6667V11.6667L16.6666 6.66675ZM18.3333 20.0001H11.6666V18.3334H18.3333V20.0001ZM18.3333 16.6667H11.6666V15.0001H18.3333V16.6667ZM15.8333 12.5001V7.91675L20.4166 12.5001H15.8333Z"
    />
  </svg>
);
