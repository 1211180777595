import React from 'react';

export default () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.5 184C140.675 184 170 181.314 170 178C170 174.686 140.675 172 104.5 172C68.3253 172 39 174.686 39 178C39 181.314 68.3253 184 104.5 184Z"
      fill="#CBD6E4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.1584 16.0024H136.074L164.841 44.8155V149.278C164.841 151.268 163.233 152.879 161.246 152.879H64.1584C62.1722 152.879 60.5641 151.268 60.5641 149.278V19.6059C60.5641 17.6162 62.1722 16.0024 64.1584 16.0024Z"
      fill="#F8FBFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M164.841 44.8161L136.075 16.003H64.1587C62.1725 16.003 60.5614 17.6138 60.5614 19.6035V34.0115C60.5614 32.0218 62.1725 30.408 64.1587 30.408H136.075V41.2155C136.075 43.2052 137.686 44.8161 139.672 44.8161H164.841Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.1584 16.0024H136.074L164.841 44.8155V149.278C164.841 151.268 163.233 152.879 161.246 152.879H64.1584C62.1722 152.879 60.5641 151.268 60.5641 149.278V19.6059C60.5641 17.6162 62.1722 16.0024 64.1584 16.0024Z"
      stroke="#9BABC2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M164.841 44.8161L136.075 16V41.2155C136.075 43.2052 137.686 44.8161 139.672 44.8161H164.841Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M164.841 44.8161L136.075 16V41.2155C136.075 43.2052 137.686 44.8161 139.672 44.8161H164.841Z"
      stroke="#9BABC2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112.702 124.061C112.702 146.938 94.1882 165.487 71.3509 165.487C48.5135 165.487 30 146.938 30 124.061C30 101.185 48.5135 82.6391 71.3509 82.6391C94.1882 82.6391 112.702 101.185 112.702 124.061Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112.702 124.062C112.702 146.938 94.1882 165.487 71.3509 165.487C48.5135 165.487 30 146.938 30 124.062C30 101.185 48.5135 92.6392 71.3509 92.6392C94.1882 92.6392 112.702 101.185 112.702 124.062Z"
      fill="#E6EDF7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112.702 124.061C112.702 146.938 94.1882 165.487 71.3509 165.487C48.5135 165.487 30 146.938 30 124.061C30 101.185 48.5135 82.6391 71.3509 82.6391C94.1882 82.6391 112.702 101.185 112.702 124.061Z"
      stroke="#9BABC2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.713 124.061C103.713 141.966 89.2253 156.478 71.3491 156.478C53.4758 156.478 38.9884 141.966 38.9884 124.061C38.9884 106.157 53.4758 91.6448 71.3491 91.6448C89.2253 91.6448 103.713 106.157 103.713 124.061Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M71.3503 100.649C87.6959 100.649 101.171 112.796 103.366 128.564C103.571 127.087 103.714 125.593 103.714 124.063C103.714 106.158 89.2236 91.6431 71.3503 91.6431C53.4771 91.6431 38.9897 106.158 38.9897 124.063C38.9897 125.593 39.1296 127.087 39.3381 128.564C41.5298 112.796 55.0077 100.649 71.3503 100.649Z"
      fill="#F0F0F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.713 124.061C103.713 141.966 89.2253 156.478 71.3491 156.478C53.4758 156.478 38.9884 141.966 38.9884 124.061C38.9884 106.157 53.4758 91.6448 71.3491 91.6448C89.2253 91.6448 103.713 106.157 103.713 124.061Z"
      stroke="#9BABC2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M75 35C75 34.4477 75.4477 34 76 34H121C121.552 34 122 34.4477 122 35C122 35.5523 121.552 36 121 36H76C75.4477 36 75 35.5523 75 35Z"
      fill="#9BABC2"
    />
    <path
      d="M75 47C75 46.4477 75.4477 46 76 46H121C121.552 46 122 46.4477 122 47C122 47.5523 121.552 48 121 48H76C75.4477 48 75 47.5523 75 47Z"
      fill="#9BABC2"
    />
    <path
      d="M75 60C75 59.4477 75.4477 59 76 59H148C148.552 59 149 59.4477 149 60C149 60.5523 148.552 61 148 61H76C75.4477 61 75 60.5523 75 60Z"
      fill="#9BABC2"
    />
    <path
      d="M75 72C75 71.4477 75.4477 71 76 71H148C148.552 71 149 71.4477 149 72C149 72.5523 148.552 73 148 73H76C75.4477 73 75 72.5523 75 72Z"
      fill="#9BABC2"
    />
    <rect x="110" y="84" width="39" height="2" rx="1" fill="#9BABC2" />
    <rect x="120" y="97" width="29" height="2" rx="1" fill="#9BABC2" />
    <rect x="125" y="109" width="24" height="2" rx="1" fill="#9BABC2" />
    <rect x="126" y="122" width="23" height="2" rx="1" fill="#9BABC2" />
    <rect x="125" y="134" width="24" height="2" rx="1" fill="#9BABC2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.7651 145.695H58.8076C57.9049 145.695 57.174 144.926 57.174 143.978V121.75C57.174 120.801 57.9049 120.034 58.8076 120.034H83.7651C84.6677 120.034 85.4005 120.801 85.4005 121.75V143.98C85.4005 144.926 84.6695 145.695 83.7651 145.695Z"
      fill="#BECFE6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.5208 120.034H59.0554C58.0164 120.034 57.174 120.896 57.174 121.959V127.733C57.174 126.67 58.0164 125.807 59.0554 125.807H83.5208C84.5598 125.807 85.4005 126.67 85.4005 127.733V121.959C85.4005 120.896 84.5598 120.034 83.5208 120.034Z"
      fill="#E8F4FA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.7651 145.695H58.8076C57.9049 145.695 57.174 144.926 57.174 143.978V121.75C57.174 120.801 57.9049 120.034 58.8076 120.034H83.7651C84.6677 120.034 85.4005 120.801 85.4005 121.75V143.98C85.4005 144.926 84.6695 145.695 83.7651 145.695Z"
      stroke="#9BABC2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.74 120.034V114.546C59.74 107.657 64.9093 102.072 71.288 102.072C77.6633 102.072 82.8344 107.657 82.8344 114.546V120.034H78.2158V114.546C78.2158 110.412 75.1142 107.06 71.288 107.06C67.4601 107.06 64.3585 110.412 64.3585 114.546V120.034H59.74Z"
      fill="#E8F4FA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.74 120.034V114.546C59.74 107.657 64.9093 102.072 71.288 102.072C77.6633 102.072 82.8344 107.657 82.8344 114.546V120.034H78.2158V114.546C78.2158 110.412 75.1142 107.06 71.288 107.06C67.4601 107.06 64.3585 110.412 64.3585 114.546V120.034H59.74Z"
      stroke="#9BABC2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.1363 131.324C75.1363 129.341 73.4134 127.733 71.2893 127.733C69.161 127.733 67.4381 129.341 67.4381 131.324C67.4381 132.755 68.342 133.981 69.6388 134.558V136.456C69.6388 137.308 70.3771 137.997 71.2893 137.997C72.1993 137.997 72.9377 137.308 72.9377 136.456V134.558C74.2324 133.981 75.1363 132.755 75.1363 131.324Z"
      fill="#9BABC2"
    />
  </svg>
);
