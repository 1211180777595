import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white, black, blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.3711" cy="16.8281" r="16" fill={blue} />
    <circle cx="16.3711" cy="16.8281" r="16" fill={black} fillOpacity="0.18" />
    <path
      d="M20.5315 14.7219H12.2082V13.0739H20.5315V14.7219ZM20.5315 18.0179H12.2082V16.3699H20.5315V18.0179ZM18.0345 21.314H12.2082V19.6659H18.0345V21.314ZM16.3699 9.77789C16.5906 9.77789 16.8023 9.86471 16.9584 10.0192C17.1145 10.1738 17.2022 10.3834 17.2022 10.6019C17.2022 10.8204 17.1145 11.03 16.9584 11.1846C16.8023 11.3391 16.5906 11.4259 16.3699 11.4259C16.1491 11.4259 15.9374 11.3391 15.7813 11.1846C15.6252 11.03 15.5375 10.8204 15.5375 10.6019C15.5375 10.3834 15.6252 10.1738 15.7813 10.0192C15.9374 9.86471 16.1491 9.77789 16.3699 9.77789ZM22.1962 9.77789H18.717C18.3674 8.82205 17.4519 8.12988 16.3699 8.12988C15.2878 8.12988 14.3723 8.82205 14.0227 9.77789H10.5436C10.1021 9.77789 9.67866 9.95152 9.36647 10.2606C9.05429 10.5696 8.87891 10.9888 8.87891 11.4259V22.962C8.87891 23.399 9.05429 23.8182 9.36647 24.1273C9.67866 24.4363 10.1021 24.61 10.5436 24.61H22.1962C22.6376 24.61 23.0611 24.4363 23.3732 24.1273C23.6854 23.8182 23.8608 23.399 23.8608 22.962V11.4259C23.8608 10.9888 23.6854 10.5696 23.3732 10.2606C23.0611 9.95152 22.6376 9.77789 22.1962 9.77789Z"
      fill={props.fill || white}
    />
  </svg>
);
