import {
  IGABOrganizationsHash,
  IOrderAllBusinessParties,
  IOrderOrganizationState,
  IOrganizationContactsHash,
  ISetEntityTypesHash,
  OrderEOPActionTypes,
  OrderOrganizationAction,
  OrderOrganizationTypeKeys,
} from './types';

export const initialState: IOrderOrganizationState = {
  allOrganizations: {} as IGABOrganizationsHash,
  organizationContacts: {} as IOrganizationContactsHash,
  entityTypes: {} as ISetEntityTypesHash,
  gabOrganizationContacts: [],
  organizationData: {
    organizationId: 0,
    organizationName: '',
    fastId: 0,
    principalType: { id: 0 },
  },
  gabOrganizationAddress: {},
  partiesData: {} as IOrderAllBusinessParties,
  partiesSalesforce: {} as IOrderAllBusinessParties,
};

export default (
  state = initialState,
  action: OrderOrganizationAction
): IOrderOrganizationState => {
  switch (action.type) {
    case OrderOrganizationTypeKeys.SET_ENTITY_TYPES: {
      return {
        ...state,
        entityTypes: action.entityTypes,
      };
    }
    case OrderOrganizationTypeKeys.SET_ALL_ORGANIZATIONS:
      return {
        ...state,
        allOrganizations: action.organizations,
      };

    case OrderOrganizationTypeKeys.SET_ORGANIZATION_CONTACTS:
      return {
        ...state,
        organizationContacts: action.contacts,
      };

    case OrderEOPActionTypes.SET_GAB_ORGANIZATION_CONTACTS:
      return {
        ...state,
        gabOrganizationContacts: action.contacts,
      };
    case OrderEOPActionTypes.RESET_GAB_ORGANIZATION_CONTACTS:
      return {
        ...state,
        gabOrganizationContacts: [],
      };

    case OrderOrganizationTypeKeys.SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationData: action.organizationData,
      };

    case OrderOrganizationTypeKeys.RESET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationData: {
          organizationId: 0,
          organizationName: '',
          fastId: 0,
          principalType: { id: 0 },
        },
      };

    case OrderEOPActionTypes.SET_GAB_ORGANIZATION_ADDRESS:
      return {
        ...state,
        gabOrganizationAddress: action.address,
      };

    case OrderEOPActionTypes.RESET_GAB_ORGANIZATION_ADDRESS:
      return {
        ...state,
        gabOrganizationAddress: {},
      };

    case OrderOrganizationTypeKeys.SET_ALL_BUSINESS_PARTIES:
      return {
        ...state,
        partiesData: action.partiesData,
      };

    case OrderOrganizationTypeKeys.SET_ALL_PARTIES_SALESFORCE:
      return {
        ...state,
        partiesSalesforce: action.partiesSalesforce,
      };

    default:
      return state;
  }
};
