export interface IRequest {
  fileId?: string;
  fileNumber?: string;
}

export interface IFastWindowState {
  fastWindow: any;
  authenticated: boolean;
  pendingRequests: null | IRequest[];
}

export enum FastWindowTypeKeys {
  AUTHENTICATED = 'fastWindow/AUTHENTICATED',
  SET_WINDOW = 'fastWindow/SET_WINDOW',
  SET_REQUEST = 'fastWindow/SET_REQUEST',
}

export interface IFastWindowSetRequestAction {
  type: FastWindowTypeKeys.SET_REQUEST;
  pendingRequests: object[];
}

export interface IFastWindowSetAuthenticatedAction {
  type: FastWindowTypeKeys.AUTHENTICATED;
  authenticated: boolean;
}

export interface IFastWindowSetWindowAction {
  type: FastWindowTypeKeys.SET_WINDOW;
  fastWindow: Window;
}

export type FastWindowAction =
  | IFastWindowSetAuthenticatedAction
  | IFastWindowSetWindowAction
  | IFastWindowSetRequestAction;
