import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || 'currentColor'}
      d="M6.27648 3.66667C6.02344 3.66667 5.77083 3.5297 5.68576 3.25488L4.42188 0.504883C4.29688 0.274821 4.46441 0 4.72005 0H11.286C11.5386 0 11.7062 0.274821 11.6237 0.550537L10.2747 3.25488C10.1927 3.5297 9.93663 3.66667 9.68403 3.66667H6.27648ZM0 12.4672C0 8.79875 2.69488 5.81958 6.0204 5.81958H9.97656C13.2622 5.81958 16 8.79875 16 12.4672V19.8005C16 20.9929 15.0742 22 13.9779 22H2.02214C0.886285 22 0 20.9929 0 19.8005V12.4672ZM6.63281 12.2219C6.63281 12.8029 7.01519 13.1753 8.20443 13.5361C9.39366 13.9031 10.6667 14.4894 10.6667 16.2252C10.6667 17.4775 9.8546 18.1803 8.8329 18.3942V19.7083H7.26128V18.3826C6.25521 18.1319 5.39627 17.38 5.33333 16.0417H6.47526C6.5434 16.7632 6.96788 17.3254 8.04731 17.3254C9.20486 17.3254 9.46181 16.6531 9.46181 16.2314C9.46181 15.663 9.19965 15.125 7.89019 14.758C6.42839 14.3489 5.42752 13.6462 5.42752 12.2345C5.42752 11.0546 6.24479 10.2847 7.26128 10.0287V8.70833H8.8329V10.0466C9.92795 10.3582 10.4779 11.3241 10.5148 12.375H9.35677C9.32552 11.6114 8.9796 11.0913 8.04731 11.0913C7.16189 11.0913 6.63281 11.5559 6.63281 12.2219Z"
    />
  </svg>
);
