import { ContactServiceDeskErrorMessage } from 'components/Error/ContactServiceDeskErrorMessage';
import { ErrorCode, ErrorPageWithGraphic } from 'components/ErrorPage';
import { Title } from 'components/ErrorPage/ErrorPageWithGraphic';
import { gray8 } from 'config/colors';
import { H3_MEDIUM } from 'config/typography';
import { UnregisterCallback } from 'history';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

interface IErrorBoundaryState {
  readonly error?: Error;
}

interface IErrorBoundaryProps extends RouteComponentProps {
  children: React.ReactNode | React.ReactNodeArray;
  isInternalUser?: boolean;
}

class ErrorBoundaryComponent extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public readonly state: IErrorBoundaryState = { error: undefined };
  private stopWatchingHistory: UnregisterCallback;

  public static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return { error };
  }

  public componentDidMount() {
    this.stopWatchingHistory = this.props.history.listen(() => {
      if (this.state.error) {
        this.setState({ error: undefined });
      }
    });
  }

  public componentWillUnmount() {
    this.stopWatchingHistory?.();
  }

  public render() {
    if (!this.state.error) {
      return this.props.children;
    }

    // isInternalUser: Intended to be use to alter error message and remove callToAction if user is internal.
    return (
      <StyledErrorPageWithGraphic
        statusCode={ErrorCode.UNKNOWN_ERROR}
        showCode={false}
        title="Something unexpected happened"
        description={
          <ContactServiceDeskErrorMessage
            messagePrefix={`${
              this.props.isInternalUser
                ? 'We are unable to load the content in this page'
                : 'We are unable to load this page'
            }`}
            callToAction={
              <>
                {this.props.isInternalUser ? (
                  <>
                    Please try to{' '}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.reload();
                      }}
                    >
                      refresh the page
                    </a>{' '}
                    or{' '}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.goBack();
                      }}
                    >
                      go back
                    </a>{' '}
                    and repeat your action. If this happens again, please
                  </>
                ) : null}
              </>
            }
          />
        }
      />
    );
  }
}

const StyledErrorPageWithGraphic = styled(ErrorPageWithGraphic)`
  ${Title} {
    ${H3_MEDIUM};
  }
  span {
    color: ${gray8};
  }
`;

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
