import Cookies from 'js-cookie';

interface ICookieAttributes {
  expires?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: string;
}

export const readCookie = (name: string): string => {
  return Cookies.get(name) || '';
};

export const writeCookie = (
  name: string,
  value: string,
  attributes: ICookieAttributes = {}
): void => {
  Cookies.set(name, value, attributes);
};
