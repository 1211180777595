import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M6.76923 2.4375H7.61539V1.625H6.76923V2.4375ZM8.46154 2.4375H9.30769V1.625H8.46154V2.4375ZM6.76923 4.0625H7.61539V3.25H6.76923V4.0625ZM8.46154 4.0625H9.30769V3.25H8.46154V4.0625ZM6.76923 5.6875H7.61539V4.875H6.76923V5.6875ZM8.46154 5.6875H9.30769V4.875H8.46154V5.6875ZM6.76923 7.3125H7.61539V6.5H6.76923V7.3125ZM8.46154 7.3125H9.30769V6.5H8.46154V7.3125ZM6.76923 8.9375H7.61539V8.125H6.76923V8.9375ZM8.46154 8.9375H9.30769V8.125H8.46154V8.9375ZM10.1538 0H5.92308C5.69391 0 5.49559 0.0804029 5.32812 0.241211C5.16066 0.402019 5.07692 0.592447 5.07692 0.8125V3.25H0.846154C0.616986 3.25 0.418671 3.3304 0.251202 3.49121C0.0837332 3.65202 0 3.84245 0 4.0625V12.1875C0 12.4076 0.0837332 12.598 0.251202 12.7588C0.418671 12.9196 0.616986 13 0.846154 13H10.1538C10.383 13 10.5813 12.9196 10.7488 12.7588C10.9163 12.598 11 12.4076 11 12.1875V0.8125C11 0.592447 10.9163 0.402019 10.7488 0.241211C10.5813 0.0804029 10.383 0 10.1538 0ZM3.38462 12.1875H2.53846V10.5625H3.38462V12.1875ZM4.23077 12.1875V10.5625V9.75H3.38462H2.53846H1.69231V10.5625V12.1875H0.846154V4.0625H5.07692V12.1875H4.23077ZM8.46154 12.1875H7.61539V10.5625H8.46154V12.1875ZM10.1538 12.1875H9.30769V10.5625V9.75H8.46154H7.61539H6.76923V12.1875H5.92308V4.0625V0.8125H10.1538V12.1875ZM1.69231 5.6875H2.53846V4.875H1.69231V5.6875ZM3.38462 5.6875H4.23077V4.875H3.38462V5.6875ZM1.69231 7.3125H2.53846V6.5H1.69231V7.3125ZM3.38462 7.3125H4.23077V6.5H3.38462V7.3125ZM1.69231 8.9375H2.53846V8.125H1.69231V8.9375ZM3.38462 8.9375H4.23077V8.125H3.38462V8.9375Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
