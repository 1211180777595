import {
  IAddNewProgressAction,
  IClearAllProgressItemsAction,
  IProgressAction,
  IProgressActionMetaData,
  IRemoveProgressAction,
  ISetFailProgressAction,
  ISetSuccessProgressAction,
  IToggleIsExpandedProgressItemsAction,
  MultipleProgressEnum,
} from './types';

// input to be input multiple array
export const setAddNewProgressItem = (
  progressItemToAdd: IProgressActionMetaData
): IAddNewProgressAction => ({
  type: MultipleProgressEnum.SET_ADD_NEW_PROGRESS_ITEM,
  progressItemMetaData: progressItemToAdd,
});

export const setSuccessProgressItem = (
  ProgressItem: IProgressActionMetaData
): ISetSuccessProgressAction => ({
  type: MultipleProgressEnum.SET_SUCCESS_PROGRESS_ITEM,
  progressItemMetaData: ProgressItem,
});

export const setFailProgressItem = (
  ProgressItem: IProgressActionMetaData
): ISetFailProgressAction => ({
  type: MultipleProgressEnum.SET_FAIL_PROGRESS_ITEM,
  progressItemMetaData: ProgressItem,
});

export const removeProgressItem = (
  ProgressItem: IProgressActionMetaData
): IRemoveProgressAction => ({
  type: MultipleProgressEnum.REMOVE_PROGRESS_ITEM,
  progressItemMetaData: ProgressItem,
});

export const clearAllProgressItems = (
  clearAllItems: IProgressAction
): IClearAllProgressItemsAction => ({
  type: MultipleProgressEnum.CLEAR_ALL_PROGRESS_ITEMS,
  clearAllItems: clearAllItems,
});

export const toggleProgressItems = (
  toggleProgressBar: IProgressAction
): IToggleIsExpandedProgressItemsAction => ({
  type: MultipleProgressEnum.TOGGLE_IS_EXPANDED_PROGRESS_ITEMS,
  toggleProgressBar: toggleProgressBar,
});
