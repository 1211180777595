import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M10.3967 17.1316C10.3967 17.609 10.1977 18.0669 9.84346 18.4044C9.48922 18.742 9.00878 18.9316 8.50781 18.9316C8.00685 18.9316 7.5264 18.742 7.17217 18.4044C6.81793 18.0669 6.61892 17.609 6.61892 17.1316H10.3967ZM8.50781 0.931641C8.7583 0.931641 8.99852 1.02646 9.17564 1.19524C9.35275 1.36403 9.45226 1.59295 9.45226 1.83164V2.80364C12.1345 3.23564 14.1745 5.45864 14.1745 8.13164V13.5316L17.0078 16.2316H0.0078125L2.84115 13.5316V8.13164C2.84115 5.45864 4.88115 3.23564 7.56337 2.80364V1.83164C7.56337 1.59295 7.66287 1.36403 7.83999 1.19524C8.01711 1.02646 8.25733 0.931641 8.50781 0.931641Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
