import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="11"
    viewBox="0 0 22 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M2.65619 5.5858C2.65619 3.77882 4.12501 2.30999 5.93199 2.30999H10.1588V0.302246H5.93199C3.01547 0.302246 0.648438 2.66928 0.648438 5.5858C0.648438 8.50232 3.01547 10.8693 5.93199 10.8693H10.1588V8.8616H5.93199C4.12501 8.8616 2.65619 7.39277 2.65619 5.5858ZM6.9887 6.64251H15.4424V4.52909H6.9887V6.64251ZM16.4991 0.302246H12.2722V2.30999H16.4991C18.3061 2.30999 19.7749 3.77882 19.7749 5.5858C19.7749 7.39277 18.3061 8.8616 16.4991 8.8616H12.2722V10.8693H16.4991C19.4156 10.8693 21.7826 8.50232 21.7826 5.5858C21.7826 2.66928 19.4156 0.302246 16.4991 0.302246Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
