import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M4.93043 8.91923C6.53043 12.0637 9.1082 14.6414 12.2526 16.2414L14.6971 13.797C15.0082 13.4859 15.4415 13.397 15.8304 13.5192C17.0749 13.9303 18.4082 14.1526 19.7971 14.1526C20.0918 14.1526 20.3744 14.2696 20.5828 14.478C20.7911 14.6864 20.9082 14.969 20.9082 15.2637V19.1526C20.9082 19.4472 20.7911 19.7299 20.5828 19.9382C20.3744 20.1466 20.0918 20.2637 19.7971 20.2637C14.7874 20.2637 9.98298 18.2736 6.44063 14.7312C2.89828 11.1889 0.908203 6.38443 0.908203 1.37478C0.908203 1.0801 1.02527 0.797482 1.23364 0.589109C1.44201 0.380735 1.72463 0.263672 2.01931 0.263672H5.9082C6.20289 0.263672 6.4855 0.380735 6.69388 0.589109C6.90225 0.797482 7.01931 1.0801 7.01931 1.37478C7.01931 2.76367 7.24154 4.09701 7.65265 5.34145C7.77487 5.73034 7.68598 6.16367 7.37487 6.47478L4.93043 8.91923Z"
      fill="#ADB8C7"
    />
  </svg>
);
