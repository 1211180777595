import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M17.4 1.85838L15.708 0.166382L8.99998 6.87438L2.29198 0.166382L0.599976 1.85838L7.30798 8.56638L0.599976 15.2744L2.29198 16.9664L8.99998 10.2584L15.708 16.9664L17.4 15.2744L10.692 8.56638L17.4 1.85838Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
