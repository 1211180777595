import { Content, DisplaySize, Section } from '.';
import { H1_MEDIUM, H4_NORMAL } from 'config/typography';
import { secondaryBlue7, white } from 'config/colors';
import styled from 'styled-components';

export const CardsSection = styled(Section).attrs({
  $color: secondaryBlue7,
})`
  padding-bottom: 71px;
  padding-top: 71px;
  transition-duration: 0.3s;
  transition-property: padding-bottom, padding-top;
  transition-timing-function: ease-out;
  will-change: padding-bottom, padding-top;

  ${Content} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  svg {
    height: 55px;
    transition-duration: 0.3s;
    transition-property: height, width;
    transition-timing-function: ease-out;
    width: 55px;
    will-change: height, width;
  }

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    padding-bottom: 66px;
    padding-top: 66px;

    svg {
      height: 33px;
      width: 33px;
    }
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    padding-bottom: 40px;
    padding-top: 40px;

    svg {
      height: 25px;
      width: 25px;
    }
  }

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    padding-bottom: 46px;
    padding-top: 46px;

    ${Content} {
      display: flex;
      flex-direction: column;
    }

    svg {
      height: 25px;
      width: 25px;
    }
  }
`;

export const CardsContent = styled(Content)`
  flex-direction: row;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    flex-direction: column;
  }
`;

export const CardTitle = styled.h2`
  ${H1_MEDIUM}
  color: ${white};
  font-size: 36px;
  margin: 0;

  strong {
    display: block;
  }

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    font-size: 22px;
    line-height: 25px;
  }

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    font-size: 22px;
    line-height: 25px;

    strong {
      display: inline;
    }
  }
`;

export const CardContent = styled.p`
  ${H4_NORMAL}
  color: ${white};
  font-weight: 450;
  margin: 0;

  @media only screen and (max-width: ${DisplaySize.LAPTOP}px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media only screen and (max-width: ${DisplaySize.TABLET}px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
