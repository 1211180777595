import { AxiosError } from 'axios';
import { IOrderBusinessParty, IOrderPartyContact } from 'store/orders/types';

export enum InvoiceTypesKeys {
  REQUEST_START = 'user/REQUEST_START',
  REQUEST_SUCCESS = 'user/REQUEST_SUCCESS',
  REQUEST_ERROR = 'user/REQUEST_ERROR',
  SET_ERROR = 'user/SET_ERROR',
  RESET_ERROR = 'user/RESET_ERROR',
  REQUEST_START_EMAIL_INVOICE_DOCUMENT_CHECKBOX = 'user/REQUEST_START_EMAIL_INVOICE_DOCUMENT_CHECKBOX_START',
  REQUEST_SUCCESS_EMAIL_INVOICE_DOCUMENT_CHECKBOX = 'user/REQUEST_START_EMAIL_INVOICE_DOCUMENT_CHECKBOX_SUCCESS',
  REQUEST_ERROR_EMAIL_INVOICE_DOCUMENT_CHECKBOX = 'user/REQUEST_START_EMAIL_INVOICE_DOCUMENT_CHECKBOX_ERROR',
  REQUEST_HISTORY_LOADING = 'user/REQUEST_HISTORY_LOADING',
  REQUEST_HISTORY_SUCCESS = 'user/REQUEST_HISTORY_SUCCESS',
  EMAIL_INVOICE_REQUEST_SUCCESS = 'user/EMAIL_INVOICE_REQUEST_SUCCESS',
  EMAIL_INVOICE_REQUEST_START = 'user/EMAIL_INVOICE_REQUEST_START',
  EMAIL_INVOICE_REQUEST_ERROR = 'user/EMAIL_INVOICE_REQUEST_ERROR',
  REQUEST_CREATE_INVOICE_SUCCESS = 'user/REQUEST_CREATE_INVOICE_SUCCESS',
  REQUEST_CREATE_INVOICE_START = 'user/REQUEST_CREATE_INVOICE_START',
  REQUEST_CANCEL_FINALIZE_INVOICE_START = 'user/REQUEST_CANCEL_FINALIZE_INVOICE_START',
  REQUEST_CANCEL_FINALIZE_INVOICE_SUCCESS = 'user/REQUEST_CANCEL_FINALIZE_INVOICE_SUCCESS',
  REQUEST_FETCH_AVAILABLE_FEE_START = 'user/REQUEST_FETCH_AVAILABLE_FEE_START',
  REQUEST_FETCH_AVILABLE_FEE_SUCCESS = 'user/REQUEST_FETCH_AVILABLE_FEE_SUCCESS',
  REQUEST_UPDATE_FEE_START = 'user/REQUEST_UPDATE_FEE_START',
  REQUEST_UPDATE_FEE_SUCCESS = 'user/REQUEST_UPDATE_FEE_SUCCESS',
  RESET_INVOICE = 'user/RESET_INVOICE',
  REQUEST_INVOICE_FEE_START = 'user/REQUEST_INVOICE_FEE_START',
  REQUEST_INVOICE_FEE_SUCCESS = 'user/REQUEST_INVOICE_FEE_SUCCESS',
  REQUEST_PAYMENT_HISTORY_SUCCESS = 'user/REQUEST_PAYMENT_HISTORY_SUCCESS',
  REQUEST_PAYMENT_HISTORY_START = 'user/REQUEST_PAYMENT_HISTORY_START',
  REQUEST_PAYMENT_HISTORY_ERROR = 'user/REQUEST_PAYMENT_HISTORY_ERROR',
  REQUEST_DELETE_INVOICE_START = 'user/REQUEST_DELETE_INVOICE_START',
  REQUEST_DELETE_INVOICE_SUCCESS = 'user/REQUEST_DELETE_INVOICE_SUCCESS ',
  REQUEST_FILE_SUCCESS = 'user/REQUEST_FILE_SUCCESS ',
  REQUEST_DOCUMENT_SUCCESS = 'user/REQUEST_DOCUMENT_SUCCESS ',
  REQUEST_DOCUMENT_ERROR = 'user/REQUEST_DOCUMENT_ERROR ',
  REQUEST_DOCUMENT_CONTENT_START = 'user/REQUEST_DOCUMENT_CONTENT_START',
}

interface IInvoiceFetchRequestAction {
  type: InvoiceTypesKeys.REQUEST_START;
  fileId: number;
}
interface IInvoiceEmailDocumentCheckboxRequestActionSuccess {
  type: InvoiceTypesKeys.REQUEST_SUCCESS_EMAIL_INVOICE_DOCUMENT_CHECKBOX;
  result: any;
}
interface IInvoiceEmailDocumentCheckboxRequestActionStart {
  type: InvoiceTypesKeys.REQUEST_START_EMAIL_INVOICE_DOCUMENT_CHECKBOX;
}
interface IInvoiceEmailDocumentCheckboxRequestActionError {
  type: InvoiceTypesKeys.REQUEST_ERROR_EMAIL_INVOICE_DOCUMENT_CHECKBOX;
  error: AxiosError;
}
interface IInvoiceSubmitMailRequestActionSuccess {
  type: InvoiceTypesKeys.EMAIL_INVOICE_REQUEST_SUCCESS;
  success: any;
}
interface IInvoiceSubmitMailRequestActionStart {
  type: InvoiceTypesKeys.EMAIL_INVOICE_REQUEST_START;
}
interface IInvoiceSubmitMailRequestActionError {
  type: InvoiceTypesKeys.EMAIL_INVOICE_REQUEST_ERROR;
  error: AxiosError;
}

export interface IInvoiceMailRequest {
  carbonCopyEmailAddresses: string[];
  fromEmail: string;
  fromName: string;
  toEmailAddresses: string[];
  subjectLine: string;
  message: string;
  emailAttachmentFileTypeId: number;
  buyerSellerLenderFormatFlag: boolean;
  disclosureSimultaneousPolicyAmountFlag: boolean;
  disclosureTitlePremiumAdjustmentAmountFlag: boolean;
  paymentToInfoFlag: boolean;
}

interface IInvoiceFetchRequestSuccessAction {
  type: InvoiceTypesKeys.REQUEST_SUCCESS;
  invoices: any;
  invoiceDetails: IInvoiceDetails;
  invoiceFee: IFees[];
  invoiceTotalAmount: number;
  invoiceUpdatedStatus: string;
  invoiceGostStatus: string | null;
}
interface IInvoiceFetchHistorySuccessAction {
  type: InvoiceTypesKeys.REQUEST_HISTORY_SUCCESS;
  invoiceHistory: IInvoiceHistory[];
}
interface IInvoiceHistoryLoadingAction {
  type: InvoiceTypesKeys.REQUEST_HISTORY_LOADING;
  invoiceHistoryLoading: boolean;
}
interface IInvoiceFetchRequestErrorAction {
  type: InvoiceTypesKeys.REQUEST_ERROR;
  error: AxiosError;
}
interface IInvoiceCreateInvoiceRequestStartAction {
  type: InvoiceTypesKeys.REQUEST_CREATE_INVOICE_START;
  fileId: number;
}
interface IInvoiceCreateInvoiceRequestSucessAction {
  type: InvoiceTypesKeys.REQUEST_CREATE_INVOICE_SUCCESS;
  invoiceCreateNew: IInvoiceCreateNew;
}
interface IInvoiceFinalizeSuccessAction {
  type: InvoiceTypesKeys.REQUEST_CANCEL_FINALIZE_INVOICE_SUCCESS;
  invoiceDetails: IInvoiceDetails;
}
interface IInvoiceDeleteSuccessAction {
  type: InvoiceTypesKeys.REQUEST_DELETE_INVOICE_SUCCESS;
  invoices: IInvoices[];
}
interface IInvoiceSelectFeeRequestStartAction {
  type: InvoiceTypesKeys.REQUEST_FETCH_AVAILABLE_FEE_START;
  fileId: number;
}
interface IInvoiceSelectFeeRequestSucessAction {
  type: InvoiceTypesKeys.REQUEST_FETCH_AVILABLE_FEE_SUCCESS;
  invoiceAvailableFee: IFees[];
}

interface IInvoiceUpdateFeeStartAction {
  type: InvoiceTypesKeys.REQUEST_UPDATE_FEE_START;
}
interface IInvoiceUpdateFeeSuccessAction {
  type: InvoiceTypesKeys.REQUEST_UPDATE_FEE_SUCCESS;
}
interface IInvoiceFeeStartAction {
  type: InvoiceTypesKeys.REQUEST_INVOICE_FEE_START;
}
interface IInvoicePaymentHistoryStartAction {
  type: InvoiceTypesKeys.REQUEST_PAYMENT_HISTORY_START;
}
interface IInvoicePaymentHistorySuccessAction {
  type: InvoiceTypesKeys.REQUEST_PAYMENT_HISTORY_SUCCESS;
  invoicePaymentHistory: IPaymentHistory[];
}
interface IInvoicePaymentHistoryErrorAction {
  type: InvoiceTypesKeys.REQUEST_PAYMENT_HISTORY_ERROR;
  error: AxiosError;
}
interface IInvoiceFileSuccessAction {
  type: InvoiceTypesKeys.REQUEST_FILE_SUCCESS;
  invoiceFileDetails: IInvoiceFileDetails;
}
interface IInvoiceFeeSuccessAction {
  type: InvoiceTypesKeys.REQUEST_INVOICE_FEE_SUCCESS;
  invoiceFee: IFees[];
  invoiceTotalAmount: number;
  invoiceUpdatedStatus: string;
  invoiceGostStatus: string | null;
}
export interface IInvoiceDocumentContentStart {
  type: InvoiceTypesKeys.REQUEST_DOCUMENT_CONTENT_START;
  invoiceDocument: IInvoiceDocument;
}
export interface IInvoiceDocumentDetails {
  type: InvoiceTypesKeys.REQUEST_DOCUMENT_SUCCESS;
  invoiceDocument: IInvoiceDocument;
}
interface IInvoiceDocumentDetailsErrorAction {
  type: InvoiceTypesKeys.REQUEST_DOCUMENT_ERROR;
  error: AxiosError;
  invoiceDocument: IInvoiceDocument;
}
interface IInvoiceResetAction {
  type: InvoiceTypesKeys.RESET_INVOICE;
}

export interface IImageDocumentRequest {
  publishFlag: boolean;
  draftFlag: boolean;
}

interface IInvoiceSetErrorAction {
  type: InvoiceTypesKeys.SET_ERROR;
  error: AxiosError;
}

interface IInvoiceResetErrorAction {
  type: InvoiceTypesKeys.RESET_ERROR;
}

export interface IInvoiceState {
  loading: boolean;
  error: AxiosError | null;
  invoices: IInvoices[];
  invoiceDetails: IInvoiceDetails;
  invoiceHistory: IInvoiceHistory[];
  invoiceCreateNew: any;
  invoiceAvailableFeeLoading: boolean;
  invoiceUpdateFeeLoading: boolean;
  invoiceAvailableFee: IFees[];
  invoiceFee: IFees[];
  invoiceFeeLoading: boolean;
  invoiceTotalAmount: number | null;
  invoiceUpdatedStatus: string | undefined;
  invoicePaymentHistory: IPaymentHistory[];
  invoiceHistoryLoading: boolean;
  invoiceFileDetails: IInvoiceFileDetails;
  loadingPaymentHistory: boolean;
  invoiceGostStatus: string | null;
  invoiceDocument: IInvoiceDocument;
}

export interface IInvoices {
  id: number;
  partyName: string;
  invoiceNumber: number;
  totalWithoutTax: number;
  taxAmount: number;
  balanceAmount: number;
  invoiceStatusDescription: string;
  invoiceDates: IInvoiceDates;
  paymentStatus: string;
  approvalStatus: string;
  fileId: number;
}

export type InvoicesAction =
  | IInvoiceFetchRequestAction
  | IInvoicePaymentHistoryErrorAction
  | IInvoicePaymentHistoryStartAction
  | IInvoicePaymentHistorySuccessAction
  | IInvoiceFetchRequestSuccessAction
  | IInvoiceEmailDocumentCheckboxRequestActionError
  | IInvoiceEmailDocumentCheckboxRequestActionStart
  | IInvoiceEmailDocumentCheckboxRequestActionSuccess
  | IInvoiceFetchRequestErrorAction
  | IInvoiceFetchHistorySuccessAction
  | IInvoiceHistoryLoadingAction
  | IInvoiceSubmitMailRequestActionSuccess
  | IInvoiceSubmitMailRequestActionStart
  | IInvoiceSubmitMailRequestActionError
  | IInvoiceCreateInvoiceRequestStartAction
  | IInvoiceCreateInvoiceRequestSucessAction
  | IInvoiceFinalizeSuccessAction
  | IInvoiceDeleteSuccessAction
  | IInvoiceCreateInvoiceRequestSucessAction
  | IInvoiceSelectFeeRequestStartAction
  | IInvoiceSelectFeeRequestSucessAction
  | IInvoiceUpdateFeeStartAction
  | IInvoiceUpdateFeeSuccessAction
  | IInvoiceFeeStartAction
  | IInvoiceFeeSuccessAction
  | IInvoiceResetAction
  | IInvoiceSetErrorAction
  | IInvoiceResetErrorAction
  | IInvoiceFileSuccessAction
  | IInvoiceDocumentDetails
  | IInvoiceDocumentDetailsErrorAction
  | IInvoiceDocumentContentStart;

export interface IInvoiceDetails {
  id: number;
  approvalStatus: string;
  party: IParty;
  invoiceNumber: number;
  totalAmountWithoutTax: number;
  taxAmount: number;
  balanceAmount: number;
  status: IStatus;
  invoiceDates: IInvoiceDates;
  comments: string;
  fees: IFees[];
  lenderCharge: number;
  totalCharge: number;
  paymentStatus?: string;
  fileId: number;
  type: InvoiceType;
}

export enum InvoiceType {
  ProjectOrSingle = 0,
  Site = 1,
}

export enum EmailAttachmentFileType {
  Doc = 1,
  Pdf = 2,
  Rtf = 3,
}

interface IParty {
  id?: number;
  name?: string;
}
interface IStatus {
  description?: string;
  id?: number;
}
interface IInvoiceDates {
  invoiceExportDate?: Date | string | null;
  invoiceStatusDate?: Date | string | null;
}
export interface IFees {
  feeId?: number;
  sequenceNumber: number;
  description: string;
  buyerChargeAmount?: number;
  sellerChargeAmount?: number;
  buyerSalesTaxAmount?: number;
  sellerSalesTaxAmount?: number;
  feeType?: string;
  lenderCharge?: number;
  totalCharge?: number;
  buyerCharge?: number;
  id?: number;
  invoiceId?: number;
  sellerCharge?: number;
  allocatedAmount?: number;
}

export interface IPaymentHistory {
  payorName: string;
  paidAmount: string;
  balanceAmount: string;
  documentNumber: string | number;
  action: string;
  actionDate: string | Date;
  reason: string;
}

export interface IInvoiceHistory {
  serviceFileFeeId: number;
  feeDescription: string;
  feeAmount: number;
  actionDescription: string;
  actionDate: Date;
  modifiedBy: string;
  firstName: string;
  lastName: string;
}

export interface IInvoiceCreateNew {
  businessPartyId: number;
  payeeName: string;
  payeeId: number;
  invoiceNotes: string;
}

export interface IInvoiceFileDetails {
  fileNumber: number;
  loanNumber: number | null;
  referenceNumber: string | null;
  buyerName: string | null;
  sellerName: string | null;
  streetAddress: string | null;
  businessPartyAndEmployees: IOrderBusinessParty[];
  contacts: IOrderPartyContact[];
}
export interface IAdditionalOption {
  buyerSellerLenderFormatFlag: boolean;
  paymentToInfoFlag: boolean;
  disclosureSimultaneousPolicyAmountFlag: boolean;
  disclosureTitlePremiumAdjustmentAmountFlag: boolean;
}

export interface IInvoiceDocument {
  documentContent: Blob;
  documentContentLoading: boolean;
}
export interface IInvoiceFeeReorder {
  id: string;
  content: IFees;
}
