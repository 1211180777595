/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_BulkUpdate_CheckDuplicatePartiesViewModel } from '../models/Workspace_API_ViewModels_BulkUpdate_CheckDuplicatePartiesViewModel';
import type { Workspace_API_ViewModels_BulkUpdate_DuplicatePartiesViewModel } from '../models/Workspace_API_ViewModels_BulkUpdate_DuplicatePartiesViewModel';
import type { Workspace_API_ViewModels_BulkUpdate_UpdateSalesPricesAndLiabilitiesViewModel } from '../models/Workspace_API_ViewModels_BulkUpdate_UpdateSalesPricesAndLiabilitiesViewModel';
import type { Workspace_API_ViewModels_BulkUpdate_UpdateSiteFilesOrderTypeViewModel } from '../models/Workspace_API_ViewModels_BulkUpdate_UpdateSiteFilesOrderTypeViewModel';
import type { Workspace_API_ViewModels_BulkUpdate_UpdateSiteOrderPartiesViewModel } from '../models/Workspace_API_ViewModels_BulkUpdate_UpdateSiteOrderPartiesViewModel';
import type { Workspace_API_ViewModels_BulkUpdate_UpdateSiteOrdersProductsViewModel } from '../models/Workspace_API_ViewModels_BulkUpdate_UpdateSiteOrdersProductsViewModel';
import type { Workspace_API_ViewModels_Loans_NewLoanAmountAllocationViewModel } from '../models/Workspace_API_ViewModels_Loans_NewLoanAmountAllocationViewModel';
import type { Workspace_Common_Responses_BulkUpdateBackgroundJob } from '../models/Workspace_Common_Responses_BulkUpdateBackgroundJob';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderBulkUpdatesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Scehdules a job to update the order type for a list of site files (Auth)
   * @returns Workspace_Common_Responses_BulkUpdateBackgroundJob Successfully patched Eclipse and Clarity First.
   * @throws ApiError
   */
  public startBulkUpdateSiteOrderTypeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The project File id
     */
    orderId: number,
    /**
     * The order type and the list of site files where this will be updated
     */
    requestBody?: Workspace_API_ViewModels_BulkUpdate_UpdateSiteFilesOrderTypeViewModel,
  }): CancelablePromise<Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/order-type',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Updates the FileProducts collection for a list of site orders in CF and the FileData.Products resource in Eclipse (Auth)
   * @returns Workspace_Common_Responses_BulkUpdateBackgroundJob Successfully patched Eclipse and Clarity First.
   * @throws ApiError
   */
  public updateSiteOrdersProductsAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The project file Id
     */
    orderId: number,
    /**
     * The collection of site orders with products to be updated
     */
    requestBody: Array<Workspace_API_ViewModels_BulkUpdate_UpdateSiteOrdersProductsViewModel>,
  }): CancelablePromise<Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/products',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `There are no items in the collection`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Updates the sales prices and owner liabilities for a list of site orders in a project file in Clarity First and Eclipse. (Auth)
   * @returns Workspace_Common_Responses_BulkUpdateBackgroundJob Successfully updated Eclipse and Clarity First.
   * @throws ApiError
   */
  public updateSiteOrdersSalesPricesAndLiabilitiesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The project file Id
     */
    orderId: number,
    /**
     * The collection of site orders with sales prices and owner liabilities to be updated
     */
    requestBody: Array<Workspace_API_ViewModels_BulkUpdate_UpdateSalesPricesAndLiabilitiesViewModel>,
  }): CancelablePromise<Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/sales-prices-and-liabilities',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `There are no items in the collection`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Updates the business parties for a list of site orders in a project file. (Auth)
   * @returns Workspace_Common_Responses_BulkUpdateBackgroundJob Successfully updated.
   * @throws ApiError
   */
  public updateSiteOrdersPartiesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The project file Id
     */
    orderId: number,
    /**
     * The collection of site orders with parties to be updated
     */
    requestBody: Workspace_API_ViewModels_BulkUpdate_UpdateSiteOrderPartiesViewModel,
  }): CancelablePromise<Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/parties',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `There are no items in the collection`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Get Lated Job Status For List of Site Files and Job Id. (Auth)
   * @returns Workspace_Common_Responses_BulkUpdateBackgroundJob Successfully updated Eclipse and Clarity First.
   * @throws ApiError
   */
  public getBulkUpdatesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The project file Id
     */
    orderId: number,
    /**
     * The collection of site orders with job ids previously submitted
     */
    requestBody: Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>,
  }): CancelablePromise<Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/get-bulk-updates',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `There are no items in the collection`,
        403: `A user without permission to edit tries to edit.`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Bulk update new loan allocation (Auth)
   * @returns Workspace_Common_Responses_BulkUpdateBackgroundJob Successfully updated.
   * @throws ApiError
   */
  public newLoanAllocationAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The project file Id
     */
    orderId: number,
    /**
     * The collection of site orders with parties to be updated
     */
    requestBody: Workspace_API_ViewModels_Loans_NewLoanAmountAllocationViewModel,
  }): CancelablePromise<Array<Workspace_Common_Responses_BulkUpdateBackgroundJob>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/new-loan-allocation',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `There are no items in the collection`,
        404: `File isn't found in Clarity First`,
      },
    });
  }

  /**
   * Check for duplicate business parties between a project file and a given list of site files (Auth)
   * @returns Workspace_API_ViewModels_BulkUpdate_DuplicatePartiesViewModel Successfully checked and returned duplicate parties - if any.
   * @throws ApiError
   */
  public checkDuplicates({
    orderId,
    requestBody,
  }: {
    /**
     * The project file Id
     */
    orderId: number,
    /**
     * Model containing business parties and site-files to dedupe
     */
    requestBody?: Workspace_API_ViewModels_BulkUpdate_CheckDuplicatePartiesViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_BulkUpdate_DuplicatePartiesViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/bulk-updates/site-orders/duplicate-parties',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad request - site-files or business-parties invalid`,
        404: `Project file not found in Clarity First`,
      },
    });
  }

}
