import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M6.79082 0.478516H15.2092V11.5896H22L11 22.4785L-2.1409e-07 11.5896H6.79082V0.478516Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
