/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Deposits_GenerateReceiptProgressViewModel } from '../models/Workspace_API_ViewModels_Deposits_GenerateReceiptProgressViewModel';
import type { Workspace_API_ViewModels_Escrow_DepositDetailViewModel } from '../models/Workspace_API_ViewModels_Escrow_DepositDetailViewModel';
import type { Workspace_API_ViewModels_Escrow_IncomingFundAllocationViewModel } from '../models/Workspace_API_ViewModels_Escrow_IncomingFundAllocationViewModel';
import type { Workspace_API_ViewModels_Escrow_IncomingFundsViewModel } from '../models/Workspace_API_ViewModels_Escrow_IncomingFundsViewModel';
import type { Workspace_API_ViewModels_Escrow_IncomingFundViewModel } from '../models/Workspace_API_ViewModels_Escrow_IncomingFundViewModel';
import type { Workspace_API_ViewModels_Escrow_PostedFileBalanceSummaryViewModel } from '../models/Workspace_API_ViewModels_Escrow_PostedFileBalanceSummaryViewModel';
import type { Workspace_API_ViewModels_Escrow_ReturnViewModel } from '../models/Workspace_API_ViewModels_Escrow_ReturnViewModel';
import type { Workspace_API_ViewModels_Escrow_TransferIncomingFundViewModel } from '../models/Workspace_API_ViewModels_Escrow_TransferIncomingFundViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EscrowService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve all incoming funds for the specified office (Auth)
   * @returns Workspace_API_ViewModels_Escrow_IncomingFundsViewModel Incoming funds for the specified office successfully retrieved
   * @throws ApiError
   */
  public getIncomingFundsByOffice({
    officeId,
    year,
    month,
    getPendingWiresOnly = false,
  }: {
    /**
     * The Id of the office to get the incoming funds for
     */
    officeId: number,
    /**
     * The year to get the incoming funds
     */
    year?: number | null,
    /**
     * The month to get the incoming funds
     */
    month?: number | null,
    /**
     * Optional Flag to indicate to retrieve pending wires only
     */
    getPendingWiresOnly?: boolean,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_IncomingFundsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/escrow/incoming-funds/{officeId}',
      path: {
        'officeId': officeId,
      },
      query: {
        'year': year,
        'month': month,
        'getPendingWiresOnly': getPendingWiresOnly,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the office`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Split an Incoming Fund into one or more Deposits (Auth)
   * @returns Workspace_API_ViewModels_Escrow_DepositDetailViewModel Incoming funds is successfuly receipted and returned the Deposit Details
   * @throws ApiError
   */
  public receiptIncomingFunds({
    incomingFundId,
    requestBody,
  }: {
    /**
     * Incoming FundId to Deposit
     */
    incomingFundId: number,
    /**
     * Incoming Fund Allocations
     */
    requestBody: Array<Workspace_API_ViewModels_Escrow_IncomingFundAllocationViewModel>,
  }): CancelablePromise<Array<Workspace_API_ViewModels_Escrow_DepositDetailViewModel>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/escrow/incoming-funds/{incomingFundId}/receipt',
      path: {
        'incomingFundId': incomingFundId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to create deposit`,
        404: `Resource not found or Receipting Incoming Funds was unsuccessful`,
      },
    });
  }

  /**
   * Exclude an Incoming Fund (Auth)
   * @returns void
   * @throws ApiError
   */
  public excludeIncomingFund({
    incomingFundId,
    officeFastId,
  }: {
    /**
     * Incoming FundId to exclude
     */
    incomingFundId: number,
    /**
     * Office id related to the given incoming fund id.
     */
    officeFastId?: number | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/escrow/incoming-funds/{incomingFundId}/_exclude',
      path: {
        'incomingFundId': incomingFundId,
      },
      query: {
        'officeFastId': officeFastId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Recover an Incoming Fund (Auth)
   * @returns void
   * @throws ApiError
   */
  public recoverIncomingFund({
    incomingFundId,
    officeFastId,
  }: {
    /**
     * Incoming FundId to recover
     */
    incomingFundId: number,
    /**
     * Office id related to the given incoming fund id.
     */
    officeFastId?: number | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/escrow/incoming-funds/{incomingFundId}/_recover',
      path: {
        'incomingFundId': incomingFundId,
      },
      query: {
        'officeFastId': officeFastId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Return an Incoming Fund (Auth)
   * @returns void
   * @throws ApiError
   */
  public returnIncomingFund({
    incomingFundId,
    requestBody,
  }: {
    /**
     * Incoming FundId to return
     */
    incomingFundId: number,
    /**
     * Metadata related to the return action
     */
    requestBody?: Workspace_API_ViewModels_Escrow_ReturnViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/escrow/incoming-funds/{incomingFundId}/_return',
      path: {
        'incomingFundId': incomingFundId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Transfer an Incoming Fund (Auth)
   * @returns void
   * @throws ApiError
   */
  public transferIncomingFund({
    incomingFundId,
    requestBody,
  }: {
    /**
     * Incoming FundId to transfer
     */
    incomingFundId: number,
    /**
     * Information about the Transfer
     */
    requestBody?: Workspace_API_ViewModels_Escrow_TransferIncomingFundViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/escrow/incoming-funds/{incomingFundId}/_transfer',
      path: {
        'incomingFundId': incomingFundId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns void
   * @throws ApiError
   */
  public validateOrderNumber({
    incomingFundId,
    officeFastId,
    orderNumber,
  }: {
    incomingFundId: number,
    officeFastId: number,
    orderNumber: string | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/escrow/incoming-funds/{incomingFundId}/offices/{officeFastId}/orders/{orderNumber}',
      path: {
        'incomingFundId': incomingFundId,
        'officeFastId': officeFastId,
        'orderNumber': orderNumber,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns Workspace_API_ViewModels_Deposits_GenerateReceiptProgressViewModel Success
   * @throws ApiError
   */
  public getDepositReceiptProgress({
    requestBody,
  }: {
    requestBody: Array<Workspace_API_ViewModels_Deposits_GenerateReceiptProgressViewModel>,
  }): CancelablePromise<Array<Workspace_API_ViewModels_Deposits_GenerateReceiptProgressViewModel>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/escrow/deposits/receipt-progress',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Get details for an Incoming Fund (Auth)
   * @returns Workspace_API_ViewModels_Escrow_IncomingFundViewModel Success
   * @throws ApiError
   */
  public getIncomingFundDetails({
    officeFastId,
    incomingFundId,
  }: {
    /**
     * The Id of the office to get the incoming fund detail
     */
    officeFastId: number,
    /**
     * Incoming Fund Id to fetch the details
     */
    incomingFundId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_IncomingFundViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/escrow/offices/{officeFastId}/incoming-funds/{incomingFundId}',
      path: {
        'officeFastId': officeFastId,
        'incomingFundId': incomingFundId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get posted file balance summary for an order (Auth)
   * @returns Workspace_API_ViewModels_Escrow_PostedFileBalanceSummaryViewModel Success
   * @throws ApiError
   */
  public getPostedFileBalanceSummaryAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_PostedFileBalanceSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/escrow/{orderId}/posted-file-balance-summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
