import React from 'react';
import { Row } from 'components/ui/Box';

interface IProgressRowProps {
  finishedUploading: boolean;
}

const rowHeight: number = 70;

const ProgressRow = (
  props: IProgressRowProps & {
    children: React.ReactNode;
  }
) => {
  return (
    <div>
      <Row
        justify="between"
        alignItems="center"
        style={{
          height: rowHeight,
          padding: 16,
        }}
      >
        <div>{props.children}</div>
      </Row>
    </div>
  );
};

export default ProgressRow;
