import React from 'react';
import { AppNotificationType } from 'store/appNotifications/types';
import Truncate from 'react-truncate-markup';
import { StyledOrderNumber } from 'components/MultisiteSubHeader/Common';

interface INotificationDecriptionProps {
  notificationType: AppNotificationType;
  fileDisplayName: string;
  fileNumber: string;
  subject?: string | null; // for milestone-related notifications
  relatedIds?: number[];
}

// Currently this only handles bold.
// We can consider moving to a full-fledged markdown parser libary if the need arises.
function parseText(message: string) {
  const splitMessage = message.split('**'); // odd-numbered elements are bold
  return (
    <span>
      {splitMessage.map((chunk, index) =>
        index % 2 == 0 ? (
          <span key={index}>{chunk}</span>
        ) : (
          // We have added this function to handle the Order Number font face change.
          parseOrderNumberText(chunk, index)
        )
      )}
    </span>
  );
}

function parseOrderNumberText(orderNumber: string, index: number) {
  const splitText = orderNumber.split('Order ');
  return (
    <span key={index}>
      <strong>Order </strong>
      <StyledOrderNumber>{splitText[1]}</StyledOrderNumber>
    </span>
  );
}

export const NotificationDescription = (
  props: INotificationDecriptionProps
) => {
  const {
    notificationType,
    relatedIds,
    fileDisplayName,
    fileNumber,
    subject,
  } = props;

  const countDocument = relatedIds != null ? relatedIds.length : 0;

  const getNotificationDescription = () => {
    switch (notificationType) {
      case AppNotificationType.ADD_WIRE_INSTRUCTION:
        return 'Provide wire instructions for';
      case AppNotificationType.ADDED_TO_ORDER:
        return "You've been added to";
      case AppNotificationType.VERIFY_WIRE_INSTRUCTION:
        return 'Verify wire instructions for';
      case AppNotificationType.SHARED_DOCUMENTS:
        return `${
          countDocument > 1
            ? `${countDocument} documents for`
            : 'A document for'
        }`;
      case AppNotificationType.VERIFIED_WIRE_INSTRUCTION:
        return 'Wire instructions verified for';
      case AppNotificationType.ADDED_WIRE_INSTRUCTION:
        return 'Wire instructions provided for';
      case AppNotificationType.MILESTONE_DIGEST:
        return parseText(subject || '');
      case AppNotificationType.CUSTOMER_DOCUMENT_UPLOADS:
        return '';
      case AppNotificationType.MILESTONE_UPDATE:
        return parseText(subject || '');
      case AppNotificationType.INVOICE_CREATED:
        return `Approval needed for an invoice for`;
      case AppNotificationType.INVOICE_APPROVED:
        return 'Invoice for';
      case AppNotificationType.INVOICE_REJECTED:
        return 'Invoice for';
      case AppNotificationType.ORDER10999S_UPDATES:
        return 'Provide 1099s information for';
      case AppNotificationType.PSFINVOICE_UPDATES:
        return 'You have a new Property Search Invoice.';
      case AppNotificationType.ORDER_CHECKLIST:
        return parseText(subject || '');
      default:
        return '';
    }
  };

  const getOrderName = () => {
    let orderName = (
      <>
        <strong>Order</strong>{' '}
        <StyledOrderNumber>{fileNumber}</StyledOrderNumber>
      </>
    );

    if (fileDisplayName === '' && notificationType !== 'AddedToOrder') {
      orderName = (
        <React.Fragment>
          <strong>Order</strong>{' '}
          <StyledOrderNumber>{fileNumber}</StyledOrderNumber>
        </React.Fragment>
      );
    }
    if (fileDisplayName !== '' && notificationType === 'AddedToOrder') {
      orderName = (
        <React.Fragment>
          <strong>{fileDisplayName} (Order </strong>
          <StyledOrderNumber>{fileNumber}</StyledOrderNumber>
          <strong>)</strong>
        </React.Fragment>
      );
    }
    if (fileDisplayName !== '' && notificationType !== 'AddedToOrder') {
      orderName = (
        <React.Fragment>
          <strong>{fileDisplayName} (Order </strong>
          <StyledOrderNumber>{fileNumber}</StyledOrderNumber>
          <strong>)</strong>
        </React.Fragment>
      );
    }
    if (notificationType === AppNotificationType.INVOICE_APPROVED) {
      orderName = (
        <React.Fragment>
          <strong>Order</strong>{' '}
          <StyledOrderNumber>{fileNumber}</StyledOrderNumber> has been approved
        </React.Fragment>
      );
    }
    if (notificationType === AppNotificationType.INVOICE_REJECTED) {
      orderName = (
        <React.Fragment>
          <strong>Order</strong>{' '}
          <StyledOrderNumber>{fileNumber}</StyledOrderNumber> has been rejected
        </React.Fragment>
      );
    }
    return orderName;
  };

  const getNotificationTrailingText = () => {
    let trailingText = <span>.</span>;

    if (notificationType === AppNotificationType.SHARED_DOCUMENTS) {
      trailingText =
        countDocument > 1 ? (
          <span>{` `}have been shared with you.</span>
        ) : (
          <span>{` `}has been shared with you.</span>
        );
    }

    return trailingText;
  };

  const isMilestoneNotification =
    notificationType === AppNotificationType.MILESTONE_DIGEST ||
    notificationType === AppNotificationType.MILESTONE_UPDATE;

  const isOrderChecklistNotification =
    notificationType === AppNotificationType.ORDER_CHECKLIST;

  return (
    <Truncate lines={2} ellipsis="…">
      <div className="truncated">
        {isMilestoneNotification || isOrderChecklistNotification ? (
          getNotificationDescription()
        ) : (
          <>
            {getNotificationDescription()}
            {subject ? subject : ''} {fileNumber ? getOrderName() : ''}
            {fileNumber ? getNotificationTrailingText() : ''}
          </>
        )}
      </div>
    </Truncate>
  );
};
