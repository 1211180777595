import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M17.1111 1.93707H12.8333L11.6111 0.714844H5.5L4.27778 1.93707H0V4.38151H17.1111V1.93707ZM1.22222 20.2704C1.22222 20.9187 1.47976 21.5405 1.93818 21.9989C2.39661 22.4573 3.01836 22.7148 3.66667 22.7148H13.4444C14.0928 22.7148 14.7145 22.4573 15.1729 21.9989C15.6313 21.5405 15.8889 20.9187 15.8889 20.2704V5.60373H1.22222V20.2704Z"
      fill={props.fill || shuttleGrey}
    />
  </svg>
);
