import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z"
      fill={props.fill || neutralGray7}
    />
  </svg>
);
