import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M11 0.714844C9.55546 0.714844 8.12506 0.999367 6.79048 1.55217C5.4559 2.10497 4.24327 2.91522 3.22183 3.93667C1.15893 5.99957 0 8.79746 0 11.7148C0 14.6322 1.15893 17.4301 3.22183 19.493C4.24327 20.5145 5.4559 21.3247 6.79048 21.8775C8.12506 22.4303 9.55546 22.7148 11 22.7148C13.9174 22.7148 16.7153 21.5559 18.7782 19.493C20.8411 17.4301 22 14.6322 22 11.7148C22 10.2703 21.7155 8.83991 21.1627 7.50533C20.6099 6.17074 19.7996 4.95811 18.7782 3.93667C17.7567 2.91522 16.5441 2.10497 15.2095 1.55217C13.8749 0.999367 12.4445 0.714844 11 0.714844ZM16.5 12.8148H5.5V10.6148H16.5V12.8148Z"
      fill={props.fill || '#505963'}
    />
  </svg>
);
