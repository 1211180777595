import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M10.83 6.00524L14 9.11534C14 9.06613 14 9.00708 14 8.95787C14 8.17478 13.6839 7.42377 13.1213 6.87004C12.5587 6.31632 11.7956 6.00524 11 6.00524C10.94 6.00524 10.89 6.00524 10.83 6.00524ZM6.53 6.79261L8.08 8.31813C8.03 8.52482 8 8.7315 8 8.95787C8 9.74096 8.31607 10.492 8.87868 11.0457C9.44129 11.5994 10.2044 11.9105 11 11.9105C11.22 11.9105 11.44 11.881 11.65 11.8318L13.2 13.3573C12.53 13.6821 11.79 13.8789 11 13.8789C9.67392 13.8789 8.40215 13.3605 7.46447 12.4376C6.52678 11.5147 6 10.263 6 8.95787C6 8.18034 6.2 7.45203 6.53 6.79261ZM1 1.34992L3.28 3.59392L3.73 4.03682C2.08 5.31629 0.78 6.98945 0 8.95787C1.73 13.2786 6 16.3394 11 16.3394C12.55 16.3394 14.03 16.0442 15.38 15.5127L15.81 15.9261L18.73 18.8L20 17.55L2.27 0.0999756L1 1.34992ZM11 4.03682C12.3261 4.03682 13.5979 4.55528 14.5355 5.47816C15.4732 6.40104 16 7.65273 16 8.95787C16 9.58777 15.87 10.198 15.64 10.7491L18.57 13.6329C20.07 12.4026 21.27 10.7885 22 8.95787C20.27 4.63719 16 1.57629 11 1.57629C9.6 1.57629 8.26 1.82234 7 2.26524L9.17 4.38129C9.74 4.16476 10.35 4.03682 11 4.03682Z"
      fill={props.fill || '#C4C4C4'}
    />
  </svg>
);
