import {
  IFastWindowState,
  IRequest,
  FastWindowTypeKeys,
  FastWindowAction,
} from './types';

export const initialState: IFastWindowState = {
  fastWindow: null,
  authenticated: false,
  pendingRequests: [] as IRequest[],
};

export default (state = initialState, action: FastWindowAction) => {
  switch (action.type) {
    case FastWindowTypeKeys.AUTHENTICATED:
      return { ...state, authenticated: action.authenticated };
    case FastWindowTypeKeys.SET_WINDOW:
      return { ...state, fastWindow: action.fastWindow };
    case FastWindowTypeKeys.SET_REQUEST:
      return { ...state, pendingRequests: action.pendingRequests };
    default:
      return state;
  }
};
