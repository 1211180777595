import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { History } from 'history';

import authReducer from './auth/reducers';
import pageReducer from './page/reducers';
import searchReducer from './search/reducers';
import usersReducer from './users/reducers';
import officesReducer from './offices/reducers';
import regionsReducer from './regions/reducers';
import wireInstructionsReducer from './wireInstructions/reducers';
import notificationsReducer from './notifications/reducers';
import groupsReducer from './groups/reducers';
import featuresReducer from './features/reducers';
import filtersReducer from './searchFilters/reducers';
import sortingReducer from './searchSorting/reducers';
import fastWindowReducer from './fastWindow/reducers';
import filesReducer from './files/reducers';
import universalWireAccountsReducer from './universalWireAccounts/reducers';
import uiReducer from './ui/reducers';
import ordersReducer from './orders/reducers';
import checklistsReducer from './checklists/reducers';

import googleMapReducer from './googleMap/reducers';
import propertySearchReducer from './propertySearch/reducers';
import dashboardReducer from './dashboard/reducers';
import permissionsReducer from './permissions/reducers';
import propertyFarmingReducer from './propertyFarming/reducers';
import helpPageReducer from './faq/reducers';
import settingsReducer from './settings/reducers';
import billingAndPaymentsReducer from './billingAndPayments/reducers';
import appNotificationsReducer from './appNotifications/reducers';
import milestonesReducer from './milestones/reducers';
import resourceReducer from './resources/reducers';
import bulkInvitationsReducer from './bulkInvitations/reducers';
import productsReducer from './products/reducers';
import lookupsReducer from './lookups/reducers';
import orderOrganizationReducer from './orderOrganization/reducers';
import invoiceReducer from './invoice/reducers';
import allocationsReducer from './allocations/reducers';
import documentsReducer from './documents/reducers';
import progressBarReducer from './ProgressBar/reducers';
import { IApplicationState } from './types';

export const reducers = (appHistory: History): Reducer<IApplicationState> =>
  combineReducers({
    router: connectRouter(appHistory),
    auth: authReducer,
    page: pageReducer,
    search: searchReducer,
    form: formReducer,
    users: usersReducer,
    offices: officesReducer,
    regions: regionsReducer,
    wireInstructions: wireInstructionsReducer,
    notifications: notificationsReducer,
    groups: groupsReducer,
    features: featuresReducer,
    filters: filtersReducer,
    sorting: sortingReducer,
    files: filesReducer,
    fastSystem: fastWindowReducer,
    universalWireAccounts: universalWireAccountsReducer,
    ui: uiReducer,
    orders: ordersReducer,
    checklists: checklistsReducer,
    googleMap: googleMapReducer,
    propertySearch: propertySearchReducer,
    dashboard: dashboardReducer,
    permissions: permissionsReducer,
    propertyFarming: propertyFarmingReducer,
    helpPage: helpPageReducer,
    settings: settingsReducer,
    billingPayments: billingAndPaymentsReducer,
    appNotifications: appNotificationsReducer,
    milestones: milestonesReducer,
    resources: resourceReducer,
    bulkInvitations: bulkInvitationsReducer,
    products: productsReducer,
    lookups: lookupsReducer,
    orderOrganizations: orderOrganizationReducer,
    invoice: invoiceReducer,
    allocations: allocationsReducer,
    documents: documentsReducer,
    progressBar: progressBarReducer,
  });
