import React from 'react';
import { ProgressBarKeys } from 'store/ProgressBar/types';
import { ProgressBarCore } from './ProgressBarCore';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'store';

// Use case for this reusable component: To show a progress bar for a long running task, such as a report generation.
// The progress bar will be sticky and will be visible on all pages of the application.
// Can be triggered across the application, using a key, and by dispatching actions to the store.
// the key should be added to the ProgressBarKeys enum in the store/ProgressBar/types.ts file.

// ProgressBarDisplay is the main component to be imported.
// Below is an example code snippet of how to reutilize the component.
// Place this in App component with all the sticky Progress components.
{
  /*
  export const BareBoneExampleProgress = () => {
 
    const renderProgressHeaderBareBoneExample = (props) => {
        return <>Progress Head</>;
    };
    // pass the props from the calling method.
    const renderProgressRowBareBoneExample = () => {
        return <CustomRowComponent></CustomRowComponent>;
    };
    return (
        <ProgressBarDisplay
            identifier={'BareBone'}
            pollingDelay={3000}
            renderProgressHeader={renderProgressHeaderBareBoneExample}
            renderProgressRow={renderProgressRowBareBoneExample}
        />
    );
  }
*/
}

// To dispatch actions from any component in the workspace, use the dispatch method to the store.
// setAddNewMultipleProgress is the action to add a new progress item to the progress bar. Should be of type IProgressData.
// send the key which you added along with the necessary properties for polling. Type - IProgressData.
// Code Snippet below.
{
  /* 
          dispatch(setAddNewMultipleProgress({ identifier: 'TrustExtract', progressItem: progressItemToAdd }));
  */
}

// **************************************************************************
// A custom progress header and progress row component can be created and passed to the ProgressBarDisplay component,
// to specify how to display as per the Developers needs.

interface IProgressBarDisplayProps {
  identifier: ProgressBarKeys;
  pollingDelay: number;
  renderProgressHeader: () => React.ReactNode;
  renderProgressRows: () => React.ReactNode;
}

// This is the main component to be imported.
const ProgressBarDisplay: React.FC<IProgressBarDisplayProps> = ({
  identifier,
  pollingDelay = 3000,
  renderProgressHeader,
  renderProgressRows,
}) => {
  const progressItems = useSelector(
    (state: IApplicationState) => state.progressBar.progressData[identifier]
  );
  return (
    <ProgressBarCore
      progressBarItems={progressItems?.progressDataItems}
      isExpanded={progressItems?.isExpanded}
      progressHeader={renderProgressHeader}
      progressRows={renderProgressRows}
      pollingDelay={pollingDelay}
      identifier={identifier}
    />
  );
};

export default ProgressBarDisplay;
