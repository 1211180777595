import { Attribute, AttributesContainer } from '../Attribute';
import { RibbonCard } from 'components/svg/legacyIcons_DO_NOT_USE';
import { Row } from 'components/ui/Box';
import { SiteType } from '../types';
import SiteTypeTag from '../SiteTypeTag';
import { slugify } from 'utils/text';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  blueLagoon,
  extremeBlack,
  lightBlue,
  lighterBlue,
  trout,
} from 'config/theme';
import Highlight from '../Highlight';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StyledOrderNumber } from 'components/MultisiteSubHeader/Common';

interface ILeftContentProps {
  fileNumber: string;
  leftHighlightIcon?: ReactNode;
  multiSiteId: number | null;
  multiSiteNumber: string | null;
  multiSitesClosedCount?: number;
  multiSitesOpenCount?: number;
  name: string;
  siteType: SiteType;
}

const LeftContent = ({
  fileNumber,
  leftHighlightIcon,
  multiSiteId,
  multiSiteNumber,
  name,
  siteType,
}: ILeftContentProps) => {
  const isMultisite = siteType === SiteType.MultisiteProject;

  return (
    <Container>
      <Row alignItems="center">
        <FileNumber
          alignItems="center"
          className="site-card-number__value"
          gap="xsmall"
          justify="center"
        >
          {leftHighlightIcon}
          <Highlight text={fileNumber} />
        </FileNumber>

        {siteType === SiteType.MultisiteSite &&
          multiSiteId &&
          multiSiteNumber && (
            <WrappedMultisiteSiteMatches
              multiSiteId={multiSiteId}
              multiSiteNumber={multiSiteNumber}
            />
          )}

        {siteType === SiteType.MultisiteProject && (
          <SiteTagContainer>
            <SiteTypeTag siteType={SiteType.MultisiteProject} />
          </SiteTagContainer>
        )}
      </Row>

      <AttributesSpacingContainer>
        <Attribute
          classSelectorName="site-card-name"
          color={extremeBlack}
          iconFillColor={lightBlue}
          iconSvgComponent={RibbonCard}
          iconTooltipId={slugify(`Order Name ${fileNumber}`)}
          iconTooltipText={isMultisite ? 'Multisite Name' : 'Order Name'}
          lineHeight="16px"
          maxWidth={245}
          valueText={name}
          valueTextSize={14}
        />
      </AttributesSpacingContainer>
    </Container>
  );
};
LeftContent.displayName = 'LeftContent';

export default LeftContent;

const Container = styled.div`
  align-items: start;
  border-right: 1px solid ${lighterBlue};
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 46px;
  width: 290px;
`;

const FileNumber = styled(Row)`
  color: ${extremeBlack};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  font-family: 'kanitmedium';
`;

interface IMultisiteSiteMatchesProps extends RouteComponentProps {
  multiSiteId: number;
  multiSiteNumber: string;
}

const MultisiteSiteMatches = ({
  history: { push },
  multiSiteId,
  multiSiteNumber,
}: IMultisiteSiteMatchesProps) => {
  return (
    <TagInfo className="site-card-multisite-info__container">
      Part of{' '}
      <MultisiteLink
        onClick={(e) => {
          // Stop any parent onClick handlers from being called
          e.preventDefault();

          push(`/order/${multiSiteId}/orders`);
        }}
      >
        Multisite <StyledOrderNumber>{multiSiteNumber}</StyledOrderNumber>
      </MultisiteLink>
    </TagInfo>
  );
};
const WrappedMultisiteSiteMatches = withRouter(MultisiteSiteMatches);

export const MultisiteLink = styled.span`
  color: ${blueLagoon};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
MultisiteLink.displayName = 'MultisiteLink';

const TagInfo = styled.div`
  color: ${trout};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.004em;
  line-height: 16px;
  margin-left: 8px;
`;
TagInfo.displayName = 'TagInfo';

const SiteTagContainer = styled.div`
  margin-left: 8px;
`;
SiteTagContainer.displayName = 'SiteTagContainer';

const AttributesSpacingContainer = styled(AttributesContainer)`
  margin-top: 8px;
`;
AttributesSpacingContainer.displayName = 'AttributesSpacingHelper';
