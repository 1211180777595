import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M9.9 5.52632V11.1742L15.081 14.2579L15.95 12.8432L11.55 10.2237V5.52632H9.9ZM11.55 0C7.667 0 4.301 2.12211 2.497 5.27211L0 2.76316V9.94737H7.15L4.125 6.90789C5.456 4.12263 8.25 2.21053 11.55 2.21053C13.738 2.21053 15.8365 3.08388 17.3836 4.63846C18.9308 6.19303 19.8 8.30149 19.8 10.5C19.8 12.6985 18.9308 14.807 17.3836 16.3615C15.8365 17.9161 13.738 18.7895 11.55 18.7895C7.953 18.7895 4.917 16.4795 3.784 13.2632H1.474C2.684 17.7174 6.721 21 11.55 21C17.314 21 22 16.3026 22 10.5C22 9.12112 21.7297 7.75574 21.2045 6.48182C20.6794 5.2079 19.9096 4.05039 18.9393 3.07538C17.9689 2.10036 16.8169 1.32694 15.549 0.799265C14.2812 0.271591 12.9223 0 11.55 0Z"
      fill={props.fill || shuttleGrey}
    />
  </svg>
);
