export enum TrustExtractStatus {
  Successful = 'Successful',
  Pending = 'Pending',
}

export enum TrustExtractType {
  Manual = 'Manual',
  Automatic = 'Automatic',
}

export interface ITrustAccountingExtract {
  extractId: number;
  cutOffDateTime: string;
  extractDateTime: string;
  extractCompleteDateTime: string;
  transmissionDateTime: string;
  status: TrustExtractStatus;
  extractType: TrustExtractType;
}

export interface IGeneratePreviewResponse {
  eventDeliveryLogId: number;
  deliveryStatus: string;
}

export interface ITrustAccountingFetchResponse {
  hasPendingExtract: boolean;
  pageCount: number;
  pageSize: number;
  pageNumber: number;
  officeCutOff: string;
  officeTimeZone: string;
  records: ITrustAccountingExtract[];
}
