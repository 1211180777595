export enum SortingField {
  AMOUNT = 'amount',
  ORIGINATOR = 'originator',
  TYPE = 'incomingFundType',
  STATUS = 'status',
  NOTES = 'originatorToBeneficiaryInformation',
  ACCOUNT = 'accountDescription',
  RECEIVED = 'receivedDate',
}

export enum ReturnReason {
  Reason1 = 'Return reason 1',
  Reason2 = 'Return reason 2',
  Reason3 = 'Return reason 3',
}

export enum TransferReason {
  Reason1 = 'Transfer reason 1',
  Reason2 = 'Transfer reason 2',
  Reason3 = 'Transfer reason 3',
}

export enum MenuOption {
  IncomingWires = 'Incoming Wires',
  IncomingFunds = 'Incoming Funds',
}
