import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { lightBlue, white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.375" cy="16.8281" r="16" fill={lightBlue} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3221 10.3753H21.4729V8.76172H19.7744V10.3753H12.9805V8.76172H11.2821V10.3753H10.4328C9.49019 10.3753 8.73438 11.1014 8.73438 11.9888V23.2836C8.73438 23.7115 8.91332 24.1219 9.23184 24.4245C9.55037 24.7271 9.98238 24.8971 10.4328 24.8971H22.3221C22.7726 24.8971 23.2046 24.7271 23.5231 24.4245C23.8416 24.1219 24.0206 23.7115 24.0206 23.2836V11.9888C24.0206 11.5609 23.8416 11.1505 23.5231 10.8479C23.2046 10.5453 22.7726 10.3753 22.3221 10.3753ZM20.3799 13.7553L21.5915 14.9063L14.802 21.3563L11.1672 17.9033L12.3788 16.7522L14.802 19.0543L20.3799 13.7553Z"
      fill={props.fill || white}
    />
  </svg>
);
