import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M12.3297 1.37037L11.1312 0.171875L6.37969 4.92338L1.62819 0.171875L0.429688 1.37037L5.18119 6.12187L0.429688 10.8734L1.62819 12.0719L6.37969 7.32037L11.1312 12.0719L12.3297 10.8734L7.57819 6.12187L12.3297 1.37037Z"
      fill={props.fill}
    />
  </svg>
);
