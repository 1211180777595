import React from 'react';
// eslint-disable-next-line import/named
import styled, { CSSProp } from 'styled-components';
import MTooltip from './MTooltip';
import StyledReactTooltip from './StyledReactTooltip';
import StyledReactTooltipPortal from './StyledReactTooltipPortal';

interface IToolTipProps {
  className?: string;
  effect?: 'float' | 'solid';
  maxWidth: string;
  minWidth?: string;
  multiline?: boolean;
  place?: 'top' | 'right' | 'bottom' | 'left';
  tooltipEnabled?: boolean;
  style?: React.CSSProperties;
  text: string;
  toolTipId: string;
  tooltip?: React.ReactNode;
  displayPointerCursor?: boolean;
  darkerShadow?: boolean;
  type?: 'dark' | 'light';
  displayPortal?: boolean;
  css?: CSSProp;
}

interface IToolTipState {
  isToolTipVisible: boolean;
}

class ToolTip extends React.Component<IToolTipProps, IToolTipState> {
  public contentRef: HTMLElement | null = null;

  constructor(props) {
    super(props);

    this.contentRef = null;
    this.state = {
      isToolTipVisible: false,
    };
  }

  public componentDidMount() {
    const overflow = !!this.contentRef && this.isTextOverflow(this.contentRef);

    if (overflow !== this.state.isToolTipVisible) {
      this.setState({ isToolTipVisible: overflow });
    }
  }

  public render() {
    const {
      className,
      effect,
      maxWidth,
      minWidth,
      multiline,
      place,
      tooltipEnabled = true,
      style,
      text,
      toolTipId,
      tooltip,
      type,
      displayPointerCursor,
      darkerShadow,
      displayPortal,
      css,
    } = this.props;

    const { isToolTipVisible } = this.state;
    const displayTooltip = isToolTipVisible && tooltipEnabled;
    const Tooltip = displayPortal
      ? StyledReactTooltipPortal
      : StyledReactTooltip;
    return (
      <Container>
        <Content
          className={className}
          data-for={toolTipId}
          data-tip=""
          ref={(node) => (this.contentRef = node)}
          style={{
            ...(style || {}),
            cursor:
              displayTooltip || displayPointerCursor ? 'pointer' : 'default',
            maxWidth,
            minWidth,
          }}
          css={css}
        >
          {text}
        </Content>

        {displayTooltip && (
          <Tooltip
            effect={effect || 'float'}
            getContent={() => tooltip || text}
            id={toolTipId}
            multiline={multiline}
            place={place || 'bottom'}
            type={type || 'dark'}
            textAlign="center"
            darkerShadow={darkerShadow}
          />
        )}
      </Container>
    );
  }

  private isTextOverflow = (element: HTMLElement) => {
    return parseInt(this.props.maxWidth, 10) < element.scrollWidth;
  };
}

export default ToolTip;
export { StyledReactTooltip, MTooltip };

const Container = styled.div`
  font-weight: 400;
  font-size: 12px;

  .__react_component_tooltip {
    font-family: 'Circular';
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
  }
`;

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
`;
Content.displayName = 'Content';
