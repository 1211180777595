import randomId from './randomId';

export const getByPath = (
  object: object,
  path: string,
  silent?: boolean
): any => {
  if (object.constructor !== Object) {
    throw new Error('Only objects can be allowed as arguments');
  }

  return getObjectPathFromString(path).reduce((memo, key) => {
    return silent ? (memo ? memo[key] : null) : memo[key];
  }, object);
};

const OBJECT_PATH_VALID_CHARS = /^[a-zA-Z0-9\[?\]?\.]+$/;
const OBJECT_PATH_INVALID_CHARS_ERROR =
  'Only letters, number, braces and dot characters are allowed';
const OBJECT_PATH_VALID_PATTERN = /\[([^\]]+)\]+/g;
const OBJECT_PATH_INVALID_PATTERN_ERROR =
  'Path must be of `a.b[0].c[1]` format';

export const getObjectPathFromString = (path: string): string[] => {
  if (!path) {
    return [];
  } else if (!OBJECT_PATH_VALID_CHARS.test(path)) {
    throw new Error(OBJECT_PATH_INVALID_CHARS_ERROR);
  } else if (/\[\]/.test(path) && !OBJECT_PATH_VALID_PATTERN.test(path)) {
    throw new Error(OBJECT_PATH_INVALID_PATTERN_ERROR);
  }

  return path.split(/\W+/).filter((p) => p);
};

export const toLower = (obj: object, excludedKeys: string[]) => {
  if (obj.constructor !== Object) {
    throw new Error('Only objects can be allowed as the first argument');
  }
  for (const prop in obj) {
    if (!excludedKeys.includes(prop)) {
      if (obj[prop] !== null) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].toLowerCase();
        }
        if (typeof obj[prop] === 'object') {
          toLower(obj[prop], excludedKeys);
        }
      }
    }
  }
  return obj;
};

export const toParams = (params: object): string => {
  return Object.keys(params)
    .map(
      (k: string): string =>
        params[k] && `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    )
    .filter((p: string) => p)
    .join('&');
};

export const prefixKeys = (prefix: string, data: {}) => {
  return Object.keys(data).reduce((result, key) => {
    result[prefix + key] = data[key];
    return result;
  }, {});
};

type WithSurrogateKey<T> = T & { __surrogateKey?: string };

/**
 * Adds a property with a unique id to an object, or to each element of an array.
 * Useful for tracking identity on objects without a natural key like an id or when the id can be duplicated.
 * @param obj An object or array to add add surrogate keys to.
 * @param recursive If true, it will traverse the object to add a key to its children and each object property.
 */
export const addSurrogateKey = <T>(obj: T, recursive: boolean = true): void => {
  if (!obj || typeof obj !== 'object') return;
  if (Array.isArray(obj)) {
    for (const value of obj) {
      addSurrogateKey(value, recursive);
    }
  }
  const casted = obj as WithSurrogateKey<T>;
  if (typeof casted.__surrogateKey === 'undefined') {
    casted.__surrogateKey = randomId();
  }

  if (recursive) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        addSurrogateKey(obj[key]);
      }
    }
  }
};

export const getSurrogateKey = <T>(value: T) =>
  (value as WithSurrogateKey<T>)?.__surrogateKey;
