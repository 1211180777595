import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

// Like the default check, but slightly wider checkmark, used in toasters
export default (props: ISVGProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0016 0.599609C4.83156 0.599609 0.601562 4.82961 0.601562 9.99961C0.601562 15.1696 4.83156 19.3996 10.0016 19.3996C15.1716 19.3996 19.4016 15.1696 19.4016 9.99961C19.4016 4.82961 15.1716 0.599609 10.0016 0.599609ZM8.12156 14.6992L3.42156 9.99924L4.73756 8.68324L8.12156 12.0672L15.2656 4.92324L16.5816 6.23924L8.12156 14.6992Z"
      fill={props.fill || white}
    />
  </svg>
);
