import { AxiosError, AxiosResponse, AxiosPromise } from 'axios';
import { trackEvent } from 'utils/appInsights';

interface IPollerOptions {
  interval: number;
  onError?: (error: AxiosError) => any;
  onComplete?: (response: AxiosResponse) => any;
  maxRetryCount?: number; // Sets the count of max auto retries for failure.
}

export default class Poller {
  private axios: () => AxiosPromise;
  private options: IPollerOptions;
  private timeout?: number;
  private retryCount: number;
  private isApplicationLoaded: boolean; // to find if application loaded successfully at least once.

  public constructor(axios: any, options: IPollerOptions) {
    this.axios = axios;
    this.options = options;
    this.retryCount = 0;
    this.isApplicationLoaded = false;
  }

  public start() {
    this.startPoll();
  }

  public stop() {
    window.clearTimeout(this.timeout);
  }

  private startPoll() {
    const { interval, onComplete, onError, maxRetryCount = 0 } = this.options;
    const that = this;
    this.axios()
      .then((response: AxiosResponse) => {
        onComplete?.(response);
        that.isApplicationLoaded = true;
        that.retryCount = 0;
        that.timeout = window.setTimeout(() => {
          that.startPoll();
        }, interval);
      })
      .catch((error: AxiosError) => {
        trackEvent('SessionErrorTesting', {
          errorMessage: error?.message,
          errorCode: error?.code,
          errorResponse: error?.response?.data,
          errorStatus: error?.response?.status,
          errorStatusText: error?.response?.statusText,
        });
        // check for error code and message to retry only for network errors and 5xx series error codes.
        if (
          (error?.code == 'ECONNABORTED' ||
            error?.message == 'Network Error' ||
            error?.response?.status?.toString()?.startsWith('5')) &&
          that.isApplicationLoaded &&
          that.retryCount < maxRetryCount
        ) {
          that.retryCount++;
          that.timeout = window.setTimeout(() => {
            that.startPoll();
          }, interval + (that.retryCount - 1) * interval);
          return;
        } else {
          return onError && onError(error);
        }
      });
  }
}
