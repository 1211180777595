import { IPermissionsState, PermissionsTypeKeys } from './types';

export const initialState: IPermissionsState = {
  activityRightOptions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PermissionsTypeKeys.SET_ACTIVITY_RIGHT_OPTIONS:
      return {
        ...state,
        activityRightOptions: action.activityRightOptions,
      };
    default:
      return state;
  }
};
