import { IRoleType } from 'store/orderOrganization/types';

/**
 * @see https://eval07.aws.wsinterfaces.firstam.net/eclipse/swagger/ui/index#!/Lookups/Lookups_GetRoleTypes
 */

type EnumRoleTypes = {
  [key in RoleTypeKeys]: Required<IRoleType>;
};

// The underscore is to avoid showing two import suggestions.
export const _RoleTypes: EnumRoleTypes = {
  AssociatedParty: {
    id: 774,
    description: 'Associated Party',
    objectCd: 'ASSOTDPTY',
  },
  AssumptionLender: {
    id: 673,
    description: 'Assumption Lender',
    objectCd: 'AssmpLdr',
  },
  Attorney: {
    id: 86,
    description: 'Attorney',
    objectCd: 'ATTY',
  },
  AttorneyParalegal: {
    id: 2225,
    description: 'Attorney Paralegal',
    objectCd: 'ATNPLGL',
  },
  AttorneyCoCounsel: {
    id: 703,
    description: 'Attorney: Co-Counsel',
    objectCd: 'COCOUNSEL',
  },
  AttorneyLocal: {
    id: 704,
    description: 'Attorney: Local',
    objectCd: 'LOCAL',
  },
  AttorneyPrimary: {
    id: 702,
    description: 'Attorney: Primary',
    objectCd: 'PRIMARY',
  },
  BrokerDisbursementPayee: {
    id: 681,
    description: 'Broker Disbursement Payee',
    objectCd: 'BrkDsbPay',
  },
  BusinessSource: {
    id: 690,
    description: 'Business Source',
    objectCd: 'BusSource',
  },
  Buyer: {
    id: 113,
    description: 'Buyer',
    objectCd: 'BUYER',
  },
  BuyersAttorney: {
    id: 322,
    description: "Buyer's Attorney",
    objectCd: 'BUYERATTY',
  },
  BuyersBroker: {
    id: 323,
    description: "Buyer's Broker",
    objectCd: 'BUYERBROK',
  },
  BuyersReBrokerTransactionCoordinator: {
    id: 2368,
    description: "Buyer's RE Broker Transaction Coordinator",
    objectCd: 'BTRNSCORD',
  },
  BuyersRealEstateAgent: {
    id: 685,
    description: "Buyer's Real Estate Agent",
    objectCd: 'BuyerAgent',
  },
  DirectedBy: {
    id: 691,
    description: 'Directed By',
    objectCd: 'DirectedBy',
  },
  EscrowAssistant: {
    id: 238,
    description: 'Escrow Assistant',
    objectCd: 'ESCROWASST',
  },
  EscrowOfficer: {
    id: 79,
    description: 'Escrow Officer',
    objectCd: 'ESCROWOFFR',
  },
  EscrowOwningOffice: {
    id: 1187,
    description: 'Escrow Owning Office',
    objectCd: 'ESCOWNOFF',
  },
  ExchBuyerAgent: {
    id: 1976,
    description: 'Exch Buyer Agent',
    objectCd: 'EXCHBYAG',
  },
  ExchCpa: {
    id: 1983,
    description: 'Exch CPA',
    objectCd: 'EXCHCPA',
  },
  ExchCreditTo: {
    id: 1980,
    description: 'Exch Credit To',
    objectCd: 'EXCHCT',
  },
  //Not in /eclipse/api/lookups/roleTypes/ (supposedly data bug)
  ExchangeCompany: {
    id: 1501, //1307 There are 2 ids for Exchange Company, in different data sources
    description: 'Exchange Company',
    objectCd: 'EXCGCMPNY',
  },
  ExchEscrowOfficer: {
    id: 1984,
    description: 'Exch Escrow Officer',
    objectCd: 'EXCHESCO',
  },
  ExchTaxpayerAgent: {
    id: 2030,
    description: 'Exch Taxpayer Agent',
    objectCd: 'EXCHTXPAG',
  },
  ExchTaxPayerRep: {
    id: 1986,
    description: 'Exch TaxPayer Rep',
    objectCd: 'EXCHTXPRR',
  },
  ExchangeAssistant: {
    id: 1973,
    description: 'Exchange Assistant',
    objectCd: 'EXCHA',
  },
  ExchangeOfficer: {
    id: 1972,
    description: 'Exchange Officer',
    objectCd: 'EXCHO',
  },
  HazardInsuranceUnderwriter: {
    id: 678,
    description: 'Hazard Insurance Underwriter',
    objectCd: 'HInsrUW',
  },
  HoaManagementCompany: {
    id: 357,
    description: 'HOA - Management Company',
    objectCd: 'HMOWNMGMT',
  },
  HoldFundsEntry: {
    id: 355,
    description: 'Hold Funds - Entry',
    objectCd: 'HOLDFUNDS',
  },
  HomeWarranty: {
    id: 96,
    description: 'Home Warranty',
    objectCd: 'HPP',
  },
  HomeownerAssociation: {
    id: 93,
    description: 'Homeowner Association',
    objectCd: 'HOA',
  },
  InspectionAndRepair: {
    id: 94,
    description: 'Inspection and Repair',
    objectCd: 'INSPECT',
  },
  InsuranceAgent: {
    id: 677,
    description: 'Insurance Agent',
    objectCd: 'InsrAgent',
  },
  InvoiceToParty: {
    id: 1916,
    description: 'Invoice To Party',
    objectCd: 'INVTOPARTY',
  },
  Lender3rdPartyPayee: {
    id: 771,
    description: 'Lender - 3rd Party Payee',
    objectCd: 'LENDER3PTY',
  },
  LendersAttorney: {
    id: 680,
    description: "Lender's Attorney",
    objectCd: 'LenderAtt',
  },
  LenderFunding: {
    id: 3219,
    description: 'Lender: Funding',
    objectCd: 'LENDFUNDNG',
  },
  LenderLoanOfficer: {
    id: 3220,
    description: 'Lender: Loan Officer',
    objectCd: 'LENDLOANOF',
  },
  LenderMobileBanker: {
    id: 990,
    description: 'Lender: Mobile Banker',
    objectCd: 'LENDERMB',
  },
  LenderMortgageCentre: {
    id: 988,
    description: 'Lender: Mortgage Centre',
    objectCd: 'LENDERMC',
  },
  LenderOriginatingBranch: {
    id: 987,
    description: 'Lender: Originating Branch',
    objectCd: 'LENDEROB',
  },
  LenderProcessor: {
    id: 3221,
    description: 'Lender: Processor',
    objectCd: 'LENDPROCES',
  },
  LenderSigningLocation: {
    id: 989,
    description: 'Lender: Signing Location',
    objectCd: 'LENDERSL',
  },
  Lessor: {
    id: 676,
    description: 'Lessor',
    objectCd: 'Lessor',
  },
  LoanOfficer: {
    id: 3220,
    description: 'Loan Officer',
    objectCd: 'LONOFFR',
  },
  LoanProcessor: {
    id: 3221,
    description: 'Loan Processor',
    objectCd: 'LNPRSCR',
  },
  MiscGuarantorCovenantor: {
    id: 991,
    description: 'Misc: Guarantor/Covenantor',
    objectCd: 'MISCGC',
  },
  MiscLenderAssignment: {
    id: 992,
    description: 'Misc: Lender Assignment',
    objectCd: 'MISCLA',
  },
  MiscLenderPolicy: {
    id: 993,
    description: 'Misc: Lender Policy',
    objectCd: 'MISCLP',
  },
  Miscellaneous: {
    id: 679,
    description: 'Miscellaneous',
    objectCd: 'MISC',
  },
  MiscNonTitledBorrower: {
    id: 1009,
    description: 'Misc: Non-Titled Borrower',
    objectCd: 'MISCNTB',
  },
  MortgageBroker: {
    id: 670,
    description: 'Mortgage Broker',
    objectCd: 'MortgBrkr',
  },
  MortgageBroker3rdPartyPayee: {
    id: 772,
    description: 'Mortgage Broker - 3rd Party Payee',
    objectCd: 'MORTGB3PTY',
  },
  MortgageBrokerOfficer: {
    id: 2369,
    description: 'Mortgage Broker Officer',
    objectCd: 'BMORTGAGO',
  },
  MortgageBrokerProcessor: {
    id: 2370,
    description: 'Mortgage Broker Processor',
    objectCd: 'BMORTGAGP',
  },
  NewLender: {
    id: 688,
    description: 'Lender',
    objectCd: 'NewLdr',
  },
  NewLoan1Lender: {
    id: 366,
    description: 'New Loan 1 Lender',
    objectCd: 'NL1LNDRA',
  },
  NewLoan1MortgageBroker: {
    id: 367,
    description: 'New Loan 1 Mortgage Broker',
    objectCd: 'NL1MBRKRB',
  },
  NewLoan2Lender: {
    id: 368,
    description: 'New Loan 2 Lender',
    objectCd: 'NL2LNDRC',
  },
  NewLoan2MortgageBroker: {
    id: 369,
    description: 'New Loan 2 Mortgage Broker',
    objectCd: 'NL2MBRKRD',
  },
  OtherReBrokerTransactionCoordinator: {
    id: 2367,
    description: 'Other RE Broker Transaction Coordinator',
    objectCd: 'TRNSCORD',
  },
  OtherRealEstateAgent: {
    id: 687,
    description: 'Other Real Estate Agent',
    objectCd: 'OthREAgnt',
  },
  OtherRealEstateBroker: {
    id: 675,
    description: 'Other Real Estate Broker',
    objectCd: 'OthREBrkr',
  },
  OutsideEscrowCompany: {
    id: 90,
    description: 'Outside Escrow Company',
    objectCd: 'ESCROW',
  },
  OutsideTitleCompany: {
    id: 317,
    description: 'Outside Title Company',
    objectCd: 'ATTYTITLE',
  },
  PayoffLender: {
    id: 674,
    description: 'Payoff Lender',
    objectCd: 'PayOffLdr',
  },
  RecordingCharges3rdPartyPayee: {
    id: 813,
    description: 'Recording Charges 3rd Party Payee',
    objectCd: 'RECCHG3PTY',
  },
  SalesRep: {
    id: 812,
    description: 'Sales Rep',
    objectCd: 'SALESREP',
  },
  SearchVendor: {
    id: 1680,
    description: 'Search Vendor',
    objectCd: 'SRCHVENDR',
  },
  Seller: {
    id: 114,
    description: 'Seller',
    objectCd: 'SELLER',
  },
  SellersAttorney: {
    id: 325,
    description: "Seller's Attorney",
    objectCd: 'SELLATTY',
  },
  SellersBroker: {
    id: 326,
    description: "Seller's Broker",
    objectCd: 'SELLBROKER',
  },
  SellersReBrokerTransactionCoordinator: {
    id: 2371,
    description: "Seller's RE Broker Transaction Coordinator",
    objectCd: 'BMORTGAGTC',
  },
  SellersRealEstateAgent: {
    id: 686,
    description: "Seller's Real Estate Agent",
    objectCd: 'SellAgent',
  },
  SplitEntity: {
    id: 776,
    description: 'Split Entity',
    objectCd: 'SplitEnt',
  },
  Survey: {
    id: 97,
    description: 'Survey',
    objectCd: 'SURVEY',
  },
  TaxCollector: {
    id: 99,
    description: 'Tax Collector',
    objectCd: 'TAXCOLL',
  },
  TaxPayer: {
    id: 1981,
    description: 'Tax Payer',
    objectCd: 'EXCHPTP',
  },
  TitleAssistant: {
    id: 237,
    description: 'Title Assistant',
    objectCd: 'TITLEASST',
  },
  TitleOfficer: {
    id: 78,
    description: 'Title Officer',
    objectCd: 'TITLEOFFR',
  },
  TitleOwningOffice: {
    id: 1188,
    description: 'Title Owning Office',
    objectCd: 'TITOWNOFF',
  },
  Trustee: {
    id: 92,
    description: 'Trustee',
    objectCd: 'TRUSTEE',
  },
  UtilityCompany: {
    id: 95,
    description: 'Utility Company',
    objectCd: 'UTILITY',
  },
  //Extra
  HusbandWife: {
    id: 49,
    description: 'Husband/Wife',
    objectCd: '',
  },
  Borrower: {
    id: 99999,
    description: 'Borrower',
    objectCd: '',
  },
};

type RoleTypeKeys =
  | 'AssociatedParty'
  | 'AssumptionLender'
  | 'Attorney'
  | 'AttorneyParalegal'
  | 'AttorneyCoCounsel'
  | 'AttorneyLocal'
  | 'AttorneyPrimary'
  | 'BrokerDisbursementPayee'
  | 'BusinessSource'
  | 'Buyer'
  | 'BuyersAttorney'
  | 'BuyersBroker'
  | 'BuyersReBrokerTransactionCoordinator'
  | 'BuyersRealEstateAgent'
  | 'DirectedBy'
  | 'EscrowAssistant'
  | 'EscrowOfficer'
  | 'EscrowOwningOffice'
  | 'ExchBuyerAgent'
  | 'ExchCpa'
  | 'ExchCreditTo'
  | 'ExchangeCompany'
  | 'ExchEscrowOfficer'
  | 'ExchTaxpayerAgent'
  | 'ExchTaxPayerRep'
  | 'ExchangeAssistant'
  | 'ExchangeOfficer'
  | 'HazardInsuranceUnderwriter'
  | 'HoaManagementCompany'
  | 'HoldFundsEntry'
  | 'HomeWarranty'
  | 'HomeownerAssociation'
  | 'InspectionAndRepair'
  | 'InsuranceAgent'
  | 'InvoiceToParty'
  | 'Lender3rdPartyPayee'
  | 'LendersAttorney'
  | 'LenderFunding'
  | 'LenderLoanOfficer'
  | 'LenderMobileBanker'
  | 'LenderMortgageCentre'
  | 'LenderOriginatingBranch'
  | 'LenderProcessor'
  | 'LenderSigningLocation'
  | 'Lessor'
  | 'LoanOfficer'
  | 'LoanProcessor'
  | 'Miscellaneous'
  | 'MiscGuarantorCovenantor'
  | 'MiscLenderAssignment'
  | 'MiscLenderPolicy'
  | 'MiscNonTitledBorrower'
  | 'MortgageBroker'
  | 'MortgageBroker3rdPartyPayee'
  | 'MortgageBrokerOfficer'
  | 'MortgageBrokerProcessor'
  | 'NewLender'
  | 'NewLoan1Lender'
  | 'NewLoan1MortgageBroker'
  | 'NewLoan2Lender'
  | 'NewLoan2MortgageBroker'
  | 'OtherReBrokerTransactionCoordinator'
  | 'OtherRealEstateAgent'
  | 'OtherRealEstateBroker'
  | 'OutsideEscrowCompany'
  | 'OutsideTitleCompany'
  | 'PayoffLender'
  | 'RecordingCharges3rdPartyPayee'
  | 'SalesRep'
  | 'SearchVendor'
  | 'Seller'
  | 'SellersAttorney'
  | 'SellersBroker'
  | 'SellersReBrokerTransactionCoordinator'
  | 'SellersRealEstateAgent'
  | 'SplitEntity'
  | 'Survey'
  | 'TaxCollector'
  | 'TaxPayer'
  | 'TitleAssistant'
  | 'TitleOfficer'
  | 'TitleOwningOffice'
  | 'Trustee'
  | 'UtilityCompany'
  //extra
  | 'Borrower'
  | 'HusbandWife';
