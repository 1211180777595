/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_GroupComponentFeature } from '../models/Workspace_Common_Models_GroupComponentFeature';
import type { Workspace_Common_Models_SessionComponentsResponse } from '../models/Workspace_Common_Models_SessionComponentsResponse';
import type { Workspace_Common_Responses_FeatureComponent } from '../models/Workspace_Common_Responses_FeatureComponent';
import type { Workspace_Common_Responses_FeatureStatus } from '../models/Workspace_Common_Responses_FeatureStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FeaturesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieves all enabled features of the current user (Auth)
   * @returns Workspace_Common_Models_SessionComponentsResponse Features successfully retrieved
   * @throws ApiError
   */
  public getEnabledUserFeatures(): CancelablePromise<Workspace_Common_Models_SessionComponentsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/features',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Determines whether a feature is enabled for the current user (Auth)
   * @returns Workspace_Common_Responses_FeatureStatus Feature exists and is either enabled or disabled
   * @throws ApiError
   */
  public isFeatureEnabled({
    componentName,
    featureName,
  }: {
    /**
     * a component name
     */
    componentName: string,
    /**
     * a feature name
     */
    featureName: string,
  }): CancelablePromise<Workspace_Common_Responses_FeatureStatus> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/features/{componentName}/{featureName}',
      path: {
        'componentName': componentName,
        'featureName': featureName,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Component or feature doesn't exist`,
      },
    });
  }

  /**
   * Get features by component and user Id (Auth)
   * @returns Workspace_Common_Models_GroupComponentFeature Features successfully retrieved
   * @throws ApiError
   */
  public getUserGroupFeatures({
    componentId,
    userId,
  }: {
    /**
     * component Id
     */
    componentId: number,
    /**
     * user Id
     */
    userId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_GroupComponentFeature>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/features/{componentId}/users/{userId}',
      path: {
        'componentId': componentId,
        'userId': userId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get all features of a component by Id (Auth)
   * @returns Workspace_Common_Responses_FeatureComponent Features successfully retrieved
   * @throws ApiError
   */
  public getFeaturesByComponentId({
    componentId,
  }: {
    /**
     * Component Id
     */
    componentId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_FeatureComponent>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/features/components/{componentId}',
      path: {
        'componentId': componentId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}
