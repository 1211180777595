/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SearchService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/search/files',
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync1(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/search/files',
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync2({
    fileId,
  }: {
    fileId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/search/files/{fileId}',
      path: {
        'fileId': fileId,
      },
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync3({
    fileId,
  }: {
    fileId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/search/files/{fileId}',
      path: {
        'fileId': fileId,
      },
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync4(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/search/files',
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync5(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v2/search/files',
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync6({
    fileId,
  }: {
    fileId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v2/search/files/{fileId}',
      path: {
        'fileId': fileId,
      },
    });
  }

  /**
   * Proxy calls to Iris API, check Iris API for details of exposed endpoints (Auth)
   * This endpoint forwards the request to the corresponding Iris Search or SearchById endpoints (v1 or v2)
   * @returns any Successful Iris response. See Iris documentation about available options.
   * @throws ApiError
   */
  public searchAsync7({
    fileId,
  }: {
    fileId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v2/search/files/{fileId}',
      path: {
        'fileId': fileId,
      },
    });
  }

}
