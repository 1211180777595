import { fileTypeForExtension } from 'components/svg/FileType';

const useGetFileTypeForExtension = ({
  fileName,
  extension,
}: {
  fileName: string;
  extension?: string;
}) => {
  const derivedExtension = fileName?.match(/\.([a-zA-Z0-9]+)$/)?.[1];
  const extensionValidated = derivedExtension ? derivedExtension : extension;
  return fileTypeForExtension(extensionValidated);
};

export default useGetFileTypeForExtension;
