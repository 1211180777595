import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="194"
    height="198"
    viewBox="0 0 194 198"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194 100.922C194 154.219 150.573 197.422 97 197.422C43.4269 197.422 0 154.219 0 100.922C0 47.6249 43.4269 4.42188 97 4.42188C150.573 4.42188 194 47.6249 194 100.922Z"
      fill="url(#paint0-linear)"
    />
    <path
      d="M98.5 168.421C135.779 168.421 166 171.555 166 175.421C166 179.287 135.779 182.421 98.5 182.421C61.2208 182.421 31 179.287 31 175.421C31 171.555 61.2208 168.421 98.5 168.421Z"
      fill="url(#paint1-linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.973 159.05H47.796C46.4448 159.05 45.3527 160.135 45.3527 161.479V166.336H150.416V161.479C150.416 160.135 149.321 159.05 147.973 159.05Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.973 159.05H47.796C46.4448 159.05 45.3527 160.135 45.3527 161.479V166.336H150.416V161.479C150.416 160.135 149.321 159.05 147.973 159.05Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.416 166.336H45.3527C46.9433 169.503 50.2002 171.194 53.7602 171.194H142.047C145.581 171.194 148.818 169.515 150.396 166.375L150.416 166.336Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.416 166.336H45.3527C46.9433 169.503 50.2002 171.194 53.7602 171.194H142.047C145.581 171.194 148.818 169.515 150.396 166.375L150.416 166.336Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.086 108.045C143.086 105.364 140.896 103.188 138.199 103.188H57.5692C54.8717 103.188 52.6825 105.364 52.6825 108.045V159.05H143.086V108.045Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.317 103.188H57.5692C54.8717 103.188 52.6825 105.364 52.6825 108.045V159.05L122.317 103.188Z"
      fill="white"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.8781 131.869C83.9923 129.95 84.288 127.713 83.7113 125.573L76.7563 103.188H57.5701C54.8703 103.188 52.6835 105.361 52.6835 108.045V142.432L77.8107 135.738C79.9633 135.162 81.764 133.79 82.8781 131.869Z"
      fill="#EFEFEF"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.281 145.85C112.167 143.931 111.871 141.694 112.448 139.554L123.599 103.958H138.589C141.289 103.958 143.476 106.132 143.476 108.816V156.413L118.348 149.719C116.196 149.143 114.395 147.771 113.281 145.85Z"
      fill="#D8D8D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.086 108.045C143.086 105.364 140.896 103.188 138.199 103.188H57.5692C54.8717 103.188 52.6825 105.364 52.6825 108.045V159.05H143.086V108.045Z"
      stroke="#6A778B"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.8103 127.147C78.1303 128.332 77.4242 129.554 76.2294 129.872L47.2076 137.603C46.0103 137.921 44.7838 137.217 44.4661 136.029L35.201 101.654C34.881 100.469 35.5895 99.2499 36.7819 98.9317L56.8514 93.5859L70.9445 101.674L77.8103 127.147Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.8103 127.147C78.1303 128.332 77.4242 129.554 76.2294 129.872L47.2076 137.603C46.0103 137.921 44.7838 137.217 44.4661 136.029L35.201 101.654C34.881 100.469 35.5895 99.2499 36.7819 98.9317L56.8514 93.5859L70.9445 101.674L77.8103 127.147Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.8511 93.585L59.8295 104.634L70.9466 101.673L56.8511 93.585Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.8511 93.585L59.8295 104.634L70.9466 101.673L56.8511 93.585Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.1576 109.075L54.2723 106.112"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.646 114.599L65.9494 108.923"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.1354 120.124L67.4388 114.45"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.6243 125.647L68.9301 119.974"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.349 141.128C118.028 142.314 118.735 143.535 119.929 143.854L148.951 151.584C150.148 151.903 151.375 151.198 151.693 150.011L160.958 115.636C161.278 114.451 160.569 113.231 159.377 112.913L139.307 107.567L125.214 115.655L118.349 141.128Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.349 141.128C118.028 142.314 118.735 143.535 119.929 143.854L148.951 151.584C150.148 151.903 151.375 151.198 151.693 150.011L160.958 115.636C161.278 114.451 160.569 113.231 159.377 112.913L139.307 107.567L125.214 115.655L118.349 141.128Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.307 107.566L136.329 118.615L125.212 115.654L139.307 107.566Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.307 107.566L136.329 118.615L125.212 115.654L139.307 107.566Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.187 121.459C146.639 123.398 147.775 125.409 149.725 125.953C151.675 126.494 153.698 125.365 154.245 123.429C154.79 121.493 153.654 119.482 151.707 118.938C149.759 118.392 147.736 119.524 147.187 121.459Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.187 121.459C146.639 123.398 147.775 125.409 149.725 125.953C151.675 126.494 153.698 125.365 154.245 123.429C154.79 121.493 153.654 119.482 151.707 118.938C149.759 118.392 147.736 119.524 147.187 121.459Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.456 142.977L144.737 135.491C144.267 134.209 142.833 133.558 141.555 134.051L138.191 135.346L136.175 128.509C135.64 126.687 133.304 126.153 132.024 127.557L123.932 136.414L147.456 142.977Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.456 142.977L144.737 135.491C144.267 134.209 142.833 133.558 141.555 134.051L138.191 135.346L136.175 128.509C135.64 126.687 133.304 126.153 132.024 127.557L123.932 136.414L147.456 142.977Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="38.2247"
      y="10.3995"
      width="35.4356"
      height="35.4356"
      rx="10"
      transform="rotate(-15 38.2247 10.3995)"
      fill="white"
      stroke="#394C66"
      strokeWidth="2"
    />
    <rect
      x="37"
      y="9.69238"
      width="37.4356"
      height="37.4356"
      rx="11"
      transform="rotate(-15 37 9.69238)"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.0177 34.4747L44.7704 34.541C46.3286 40.3562 52.3059 43.8072 58.1211 42.2491L70.7675 38.8605C76.0098 37.4558 79.3308 32.4598 78.7927 27.2287C78.4071 30.1194 76.3313 32.6352 73.3386 33.4371L53.7652 38.6818C50.1606 39.6477 46.4648 37.795 45.0177 34.4747Z"
      fill="#D3DFEF"
    />
    <rect
      x="38.2247"
      y="10.3995"
      width="35.4356"
      height="35.4356"
      rx="10"
      transform="rotate(-15 38.2247 10.3995)"
      stroke="#6A778B"
      strokeWidth="2"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.4351 13.752L63.5553 10.2364C65.9706 9.58921 68.4533 11.0226 69.1005 13.438L72.6161 26.5582L68.2427 27.73L65.899 18.9832L64.7271 14.6098L60.3537 15.7817L51.6069 18.1254L50.4351 13.752Z"
      fill="url(#paint2-linear)"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.6061 18.127L47.2327 19.2988L50.7482 32.419C51.3954 34.8344 53.8781 36.2678 56.2935 35.6206L69.4137 32.105L68.2418 27.7316L55.1216 31.2472L51.6061 18.127Z"
      fill="url(#paint3-linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.2426 27.7297L55.1224 31.2452L51.6068 18.125L60.3536 15.7813L64.727 14.6095L65.8989 18.9829L68.2426 27.7297ZM62.5513 23.9824L55.9912 25.7402L56.2842 26.8335L62.8443 25.0758L62.5513 23.9824ZM55.4053 23.5539L64.1521 21.2102L64.445 22.3035L55.6982 24.6472L55.4053 23.5539ZM63.5662 19.0238L54.8194 21.3675L55.1123 22.4609L63.8591 20.1172L63.5662 19.0238Z"
      fill="url(#paint4-linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.907 31.419C139.146 30.4269 139.304 29.404 139.304 28.341C139.304 20.9878 133.31 15.0271 125.912 15.0271C122.874 15.0271 120.103 16.0729 117.854 17.768C112.941 12.0257 105.632 8.37305 97.4548 8.37305C82.6643 8.37305 70.6715 20.2925 70.6715 34.9951C70.6715 35.0525 70.6812 35.1081 70.6812 35.1655C70.1243 35.0947 69.5752 34.9951 68.999 34.9951C61.6019 34.9951 55.6074 40.9558 55.6074 48.307C55.6074 55.6602 61.6019 61.6209 68.999 61.6209H129.259C138.504 61.6209 146 54.17 146 44.98C146 39.3698 143.189 34.4319 138.907 31.419Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.26 53.0002H68.9991C63.1203 53.0002 58.1413 49.2307 56.3378 43.9979C55.8715 45.3521 55.6056 46.7982 55.6056 48.3075C55.6056 55.6607 61.602 61.6195 68.9991 61.6195H129.26C138.505 61.6195 146 54.1686 146 44.9805C146 43.4846 145.786 42.0423 145.412 40.6689C143.501 47.7674 137.005 53.0002 129.26 53.0002Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.907 31.419C139.146 30.4269 139.304 29.404 139.304 28.341C139.304 20.9878 133.31 15.0271 125.912 15.0271C122.874 15.0271 120.103 16.0729 117.854 17.768C112.941 12.0257 105.632 8.37305 97.4548 8.37305C82.6643 8.37305 70.6715 20.2925 70.6715 34.9951C70.6715 35.0525 70.6812 35.1081 70.6812 35.1655C70.1243 35.0947 69.5752 34.9951 68.999 34.9951C61.6019 34.9951 55.6074 40.9558 55.6074 48.307C55.6074 55.6602 61.6019 61.6209 68.999 61.6209H129.259C138.504 61.6209 146 54.17 146 44.98C146 39.3698 143.189 34.4319 138.907 31.419Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.513 81.5621C115.433 81.5621 116.272 81.0357 116.677 80.2059C117.082 79.3761 116.982 78.3856 116.421 77.6542L100.871 57.3652C100.414 56.7705 99.7089 56.4209 98.9622 56.4209C98.2135 56.4209 97.5088 56.7705 97.0536 57.3652L81.5011 77.6542C80.9401 78.3856 80.8422 79.3761 81.2475 80.2059C81.6528 81.0357 82.4913 81.5621 83.4097 81.5621H89.3192V106.185C89.3192 108.865 91.4774 111.037 94.1407 111.037H103.784C106.445 111.037 108.605 108.865 108.605 106.185V81.5621H114.513Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.4106 81.5621H86.0719L97.0524 67.2365C97.5096 66.6397 98.2144 66.2921 98.963 66.2921C99.7097 66.2921 100.414 66.6397 100.872 67.2365L111.852 81.5621H114.514C115.434 81.5621 116.27 81.0357 116.678 80.2059C117.083 79.3761 116.983 78.3856 116.422 77.6542L100.872 57.3652C100.414 56.7705 99.7097 56.4209 98.963 56.4209C98.2144 56.4209 97.5096 56.7705 97.0524 57.3652L81.5019 77.6542C80.9409 78.3856 80.8411 79.3761 81.2484 80.2059C81.6537 81.0357 82.4922 81.5621 83.4106 81.5621Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.513 81.5621C115.433 81.5621 116.272 81.0357 116.677 80.2059C117.082 79.3761 116.982 78.3856 116.421 77.6542L100.871 57.3652C100.414 56.7705 99.7089 56.4209 98.9622 56.4209C98.2135 56.4209 97.5088 56.7705 97.0536 57.3652L81.5011 77.6542C80.9401 78.3856 80.8422 79.3761 81.2475 80.2059C81.6528 81.0357 82.4913 81.5621 83.4097 81.5621H89.3192V106.185C89.3192 108.865 91.4774 111.037 94.1407 111.037H103.784C106.445 111.037 108.605 108.865 108.605 106.185V81.5621H114.513Z"
      stroke="#6A778B"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.198 123.499H93.7264C92.6244 123.499 91.73 122.599 91.73 121.49V118.231C91.73 117.12 92.6244 116.222 93.7264 116.222H104.198C105.3 116.222 106.194 117.12 106.194 118.231V121.49C106.194 122.599 105.3 123.499 104.198 123.499Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.524 119.555H93.6622C92.519 119.555 91.5912 119.228 91.5912 118.824V117.638C91.5912 117.234 92.519 116.907 93.6622 116.907H104.524C105.668 116.907 106.595 117.234 106.595 117.638V118.824C106.595 119.228 105.668 119.555 104.524 119.555Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.198 123.499H93.7264C92.6244 123.499 91.73 122.599 91.73 121.49V118.231C91.73 117.12 92.6244 116.222 93.7264 116.222H104.198C105.3 116.222 106.194 117.12 106.194 118.231V121.49C106.194 122.599 105.3 123.499 104.198 123.499Z"
      stroke="#6A778B"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.992 135.63H94.9303C93.8283 135.63 92.9338 134.73 92.9338 133.621V130.36C92.9338 129.251 93.8283 128.351 94.9303 128.351H102.992C104.094 128.351 104.989 129.251 104.989 130.36V133.621C104.989 134.73 104.094 135.63 102.992 135.63Z"
      fill="#D3DFEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.93 131.911H95.2567C94.2078 131.911 93.3564 131.475 93.3564 130.937V129.355C93.3564 128.817 94.2078 128.381 95.2567 128.381H102.93C103.979 128.381 104.83 128.817 104.83 129.355V130.937C104.83 131.475 103.979 131.911 102.93 131.911Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.992 135.63H94.9303C93.8283 135.63 92.9338 134.73 92.9338 133.621V130.36C92.9338 129.251 93.8283 128.351 94.9303 128.351H102.992C104.094 128.351 104.989 129.251 104.989 130.36V133.621C104.989 134.73 104.094 135.63 102.992 135.63Z"
      stroke="#6A778B"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0-linear"
        x1="136"
        y1="141.422"
        x2="194.211"
        y2="18.6872"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="#9AB5DB" stopOpacity="0.45" />
      </linearGradient>
      <linearGradient
        id="paint1-linear"
        x1="98.5"
        y1="182.421"
        x2="98.5"
        y2="168.421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="#9AB5DB" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint2-linear"
        x1="55.1223"
        y1="31.2454"
        x2="67.9284"
        y2="9.06465"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#75B8C8" />
        <stop offset="1" stopColor="#75B8C8" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint3-linear"
        x1="51.92"
        y1="36.7923"
        x2="64.726"
        y2="14.6115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#77CBB9" />
        <stop offset="1" stopColor="#77CBB9" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint4-linear"
        x1="55.1224"
        y1="31.2452"
        x2="64.727"
        y2="14.6094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#335580" />
        <stop offset="1" stopColor="#335580" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);
