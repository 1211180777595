import { GroupsTypeKeys, GroupsAction, IGroupsState } from './types';

export const initialState: IGroupsState = {
  error: null,
  resultsById: {},
  loading: false,
};

export default (state = initialState, action: GroupsAction) => {
  switch (action.type) {
    case GroupsTypeKeys.FETCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GroupsTypeKeys.FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        resultsById: action.resultsById,
        error: null,
      };
    case GroupsTypeKeys.FETCH_ALL_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        resultsById: {},
      };
    case GroupsTypeKeys.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        resultsById: {
          ...state.resultsById,
          ...action.result,
        },
      };
    case GroupsTypeKeys.REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case GroupsTypeKeys.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GroupsTypeKeys.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
