import axios from 'axios';

import {
  reqInterceptAppInsights,
  reqInterceptCSRF,
  reqInterceptIE11,
  reqInterceptSyncSS,
  resInterceptSyncSS,
  handleInterceptedCacheError,
} from './interceptors';

interface IApiProps {
  method: string;
  url: string;
  params?: object;
  headers?: object;
}

const defaultHeaders = {
  common: {
    'Content-type': 'application/json',
    Pragma: 'no-cache',
  },
};

if (typeof localStorage !== 'undefined') {
  const sourceHeader = localStorage.getItem('X-SOURCE-ID');
  if (sourceHeader) {
    defaultHeaders['X-SOURCE-ID'] = sourceHeader;
  }
}

export const api = ({
  method,
  url,
  params,
  headers = defaultHeaders,
}: IApiProps) =>
  axios({
    method,
    url: `${AppConfig.workspaceBackendUrl}${url}`,
    data: params,
    headers,
    withCredentials: true,
  });

// This is referenced by `__template__/request.template` that is used by the openapi code generator.
// Please do not delete - if contracts are modified, ensure the template reflects those changes.
// eslint-disable-next-line import/no-unused-modules
export const apiServiceFactory = (baseURL?: string) => {
  const instance = axios.create({
    baseURL,
    headers: defaultHeaders,
    withCredentials: true,
  });

  instance.interceptors.request.use(reqInterceptAppInsights);
  instance.interceptors.request.use(reqInterceptCSRF);
  instance.interceptors.request.use(reqInterceptIE11);
  if (process.env.NODE_ENV !== 'test') {
    instance.interceptors.request.use(reqInterceptSyncSS);
    instance.interceptors.response.use(
      resInterceptSyncSS,
      handleInterceptedCacheError
    );
  }

  return instance;
};

// DEPRECATED: All workspace API clients should use `workspaceAPI/client.ts`
// Please do not use this moving forward
export default apiServiceFactory(AppConfig.workspaceBackendUrl);
export const workspaceAPIv2 = apiServiceFactory(
  AppConfig.workspaceBackendV2Url
);

export const uploadDocAPI = (headers: object = defaultHeaders) => {
  const uploadDocAPIResult = axios.create({
    baseURL: AppConfig.workspaceBackendUrl,
    headers,
    withCredentials: true,
  });

  // Add header for CSRF token
  uploadDocAPIResult.interceptors.request.use((config) => {
    reqInterceptCSRF(config);
    return config;
  });

  return uploadDocAPIResult;
};

export const downloadDocAPI = (headers: object = defaultHeaders) => {
  const downloadDocAPIResult = axios.create({
    baseURL: AppConfig.workspaceBackendUrl,
    headers,
    withCredentials: true,
    responseType: 'blob',
  });

  // Add header for CSRF token
  downloadDocAPIResult.interceptors.request.use((config) => {
    reqInterceptCSRF(config);
    return config;
  });

  return downloadDocAPIResult;
};

export const parseCSVFile = (config) => {
  const parseCSVFileResult = axios.create({
    baseURL: AppConfig.workspaceBackendUrl,
    headers: config.headers,
    withCredentials: true,
    responseType: 'application/json',
    onUploadProgress: config.onUploadProgress,
  });

  // Add header for CSRF token
  parseCSVFileResult.interceptors.request.use((config) => {
    reqInterceptCSRF(config);
    return config;
  });

  return parseCSVFileResult;
};

export const saveOrOpenBlob = (data, fileName, contentType) => {
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    return (window.navigator as any).msSaveOrOpenBlob(data, fileName);
  } else {
    const file = new Blob([data], { type: contentType });
    const fileURL = URL.createObjectURL(file);
    return window.open(fileURL, '_blank', 'location=no');
  }
};

export function downloadBlob(blob: Blob, filename: string) {
  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = blobUrl;
    link.download = filename;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  }
}

export interface IJsonPatchOperation {
  op: 'remove' | 'replace' | 'copy' | 'move' | 'test' | 'add';
  path: string;
  value?: any;
}

export type JsonPatch = IJsonPatchOperation[];
