import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M8.44092 8.64149H7.03842V4.43398H8.44092V8.64149ZM8.44092 11.4465H7.03842V10.044H8.44092V11.4465ZM7.73967 0.927734C6.81878 0.927734 5.9069 1.10912 5.05611 1.46153C4.20531 1.81394 3.43226 2.33048 2.78109 2.98165C1.46599 4.29675 0.727173 6.0804 0.727173 7.94024C0.727173 9.80007 1.46599 11.5837 2.78109 12.8988C3.43226 13.55 4.20531 14.0665 5.05611 14.4189C5.9069 14.7714 6.81878 14.9527 7.73967 14.9527C9.5995 14.9527 11.3832 14.2139 12.6983 12.8988C14.0134 11.5837 14.7522 9.80007 14.7522 7.94024C14.7522 7.01934 14.5708 6.10746 14.2184 5.25667C13.866 4.40587 13.3494 3.63282 12.6983 2.98165C12.0471 2.33048 11.274 1.81394 10.4232 1.46153C9.57245 1.10912 8.66057 0.927734 7.73967 0.927734Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
