import { default as Button, ButtonVariants } from 'components/ui/MButton';
import { Column } from 'components/ui/Box';
import { DisplaySize, ExternalLink } from '.';
import {
  HeroContent,
  HeroDetails,
  HeroH1,
  HeroH2,
  HeroP,
  HeroSection,
} from './Hero.styles';
import LandingPageVideo from './LandingPageVideo';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

const LandingPageHero: FunctionComponent = () => {
  const [buttonType, setButtonType] = useState('secondary');

  const handleResize = useCallback(() => {
    if (window.innerWidth <= DisplaySize.MOBILE) {
      setButtonType('primary');
    } else {
      setButtonType('secondary');
    }
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeroSection>
      <HeroContent>
        <HeroH1>
          <strong>Focus on the deal,</strong> not the distractions.
        </HeroH1>

        <HeroDetails>
          <Column alignContent="start" gap="small">
            <HeroH2>
              Welcome to ClarityFirst<sup>&reg;</sup>.
            </HeroH2>
            <HeroP>
              Commercial property transactions are complex and challenging.
              ClarityFirst makes the process more efficient, transparent, and
              secure by combining innovative technology and our First American
              Title team of experts to get your deal done.
            </HeroP>
          </Column>

          <Button
            component={ExternalLink}
            href="https://ncsmarketing.firstam.com/clarityfirst/?utm_source=cf&utm_medium=web&utm_campaign=request_account&utm_content=video"
            title="Learn More"
            variant={buttonType as ButtonVariants}
          >
            Learn More
          </Button>

          <LandingPageVideo />
        </HeroDetails>
      </HeroContent>
    </HeroSection>
  );
};

export default LandingPageHero;
