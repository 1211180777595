import { Store, AnyAction } from 'redux';
import { IApplicationState } from './types';

let _store: Store<IApplicationState, AnyAction> & {
  dispatch: unknown;
};

export const injectStore = (
  store: Store<IApplicationState, AnyAction> & {
    dispatch: unknown;
  }
) => {
  _store = store;
};

export const getSessionInStore = () => {
  if (_store && _store.getState) {
    return _store.getState().auth.session || { csrfToken: '' };
  }
};
