import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M2.48124 0.447266C1.99256 0.447266 1.5239 0.641393 1.17835 0.986942C0.832799 1.33249 0.638672 1.80116 0.638672 2.28983V13.3453C0.638672 13.8339 0.832799 14.3026 1.17835 14.6481C1.5239 14.9937 1.99256 15.1878 2.48124 15.1878H6.16638V17.9517C6.16638 18.196 6.26344 18.4303 6.43622 18.6031C6.60899 18.7759 6.84332 18.873 7.08766 18.873H7.54831C7.77863 18.873 8.00895 18.7808 8.19321 18.6058L11.602 15.1878H17.2218C17.7105 15.1878 18.1791 14.9937 18.5247 14.6481C18.8702 14.3026 19.0644 13.8339 19.0644 13.3453V2.28983C19.0644 1.26721 18.2352 0.447266 17.2218 0.447266H2.48124ZM2.48124 2.28983H17.2218V13.3453H10.8465L8.00895 16.1828V13.3453H2.48124V2.28983ZM10.0266 3.67176C9.20662 3.67176 8.55251 3.83759 8.05501 4.16926C7.54831 4.50092 7.29035 5.05369 7.33641 5.68938C-1.01043 5.68938 4.84894 5.68938 9.15134 5.68938C9.15134 5.43142 9.24347 5.23795 9.39088 5.10897C9.57513 4.97999 9.77782 4.9155 10.0266 4.9155C10.3122 4.9155 10.5609 4.9892 10.7267 5.15503C10.8926 5.31165 10.9755 5.51433 10.9755 5.79072C10.9755 6.04868 10.9018 6.279 10.7728 6.47247C10.6162 6.67515 10.4227 6.84098 10.1832 6.96996C9.70411 7.26477 9.39088 7.52273 9.19741 7.74384C9.02236 7.96495 8.93023 8.27819 8.93023 8.73883H10.7728C10.7728 8.48087 10.8189 8.27818 10.9018 8.11235C10.9847 7.95574 11.1413 7.81754 11.3809 7.67935C11.8046 7.48588 12.1547 7.22792 12.4219 6.89626C12.6891 6.55538 12.8273 6.19608 12.8273 5.79072C12.8273 5.14582 12.5785 4.6299 12.081 4.24296C11.5835 3.86523 10.8926 3.67176 10.0266 3.67176ZM8.93023 9.66011V11.5027H10.7728V9.66011H8.93023Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
