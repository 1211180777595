/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ChargeDisbursementsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ChargeDisbursementsViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ChargesViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ChargesViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_OrderChargesAllocatableLinesViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_OrderChargesAllocatableLinesViewModel';
import type { Workspace_Common_Models_Enums_AllocationType } from '../models/Workspace_Common_Models_Enums_AllocationType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChargesListService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets list of all disbursements for a particular charge (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ChargeDisbursementsViewModel Success
   * @throws ApiError
   */
  public getChargeDisbursementsAsync({
    orderId,
    chargeId,
    roleTypeId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * A charge id
     */
    chargeId: number,
    /**
     * A role type id used to filter charge disbursements
     */
    roleTypeId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ChargeDisbursementsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/{chargeId}/disbursements',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      query: {
        'roleTypeId': roleTypeId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Determines whether an order has at least one Real Estate Broker ECP with Buyer Broker and Seller Broker type (Auth)
   * @returns boolean Success
   * @throws ApiError
   */
  public buyerSellerBrokersExistAsync({
    orderId,
  }: {
    /**
     * An order id
     */
    orderId: number,
  }): CancelablePromise<Record<string, boolean>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/brokers/buyer-seller',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets list of all disbursable charges for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ChargesViewModel Success
   * @throws ApiError
   */
  public getChargesAsync({
    orderId,
    siteFileId,
    groupId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ChargesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges',
      path: {
        'orderId': orderId,
      },
      query: {
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Determines whether an order has at least one business party with Lender and Mortgage Broker role (Auth)
   * @returns boolean Success
   * @throws ApiError
   */
  public lenderMortgageBrokerExistAsync({
    orderId,
  }: {
    /**
     * An order id
     */
    orderId: number,
  }): CancelablePromise<Record<string, boolean>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/parties/lender-mortgage-broker',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets list of allocatable charge lines for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_OrderChargesAllocatableLinesViewModel Success
   * @throws ApiError
   */
  public getAllocatableChargeLinesAsync({
    orderId,
    allocationType,
    siteFileId,
    groupId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    allocationType?: Workspace_Common_Models_Enums_AllocationType,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_OrderChargesAllocatableLinesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/allocatable-lines',
      path: {
        'orderId': orderId,
      },
      query: {
        'AllocationType': allocationType,
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }

}
