import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="154"
    height="28"
    viewBox="0 0 154 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <g opacity="0.75">
      <path
        d="M109.38 4.32621C110.091 4.32621 110.749 4.37821 111.328 4.56024L111.46 3.28604C110.854 3.13002 110.091 3 109.354 3C106.616 3 104.984 4.32621 104.984 7.29067V21.6189H106.564V9.63103H110.854L110.986 8.30482H106.564V7.23866C106.564 5.13233 107.485 4.32621 109.38 4.32621Z"
        fill="white"
      />
      <path
        d="M112.895 5.44438H114.475V3.31205H112.895V5.44438ZM112.895 21.6189H114.475V8.30482H112.895V21.6189Z"
        fill="white"
      />
      <path
        d="M122.35 7.94076C120.27 7.94076 119.165 8.95492 118.639 10.6192L118.507 8.30482H117.112V21.6189H118.691V12.1794C119.086 10.5672 120.297 9.31898 122.324 9.31898C123.061 9.31898 123.719 9.449 124.35 9.68304L124.456 8.40884C123.903 8.12279 123.219 7.94076 122.35 7.94076Z"
        fill="white"
      />
      <path
        d="M130.336 20.6047C128.626 20.6047 126.967 20.0586 125.651 19.0965L125.52 20.5267C126.836 21.4889 128.652 21.9309 130.336 21.9309C133.363 21.9309 135.285 20.5527 135.285 18.1083C135.285 15.898 133.785 15.0139 131.205 14.3118L130.126 14.0257C128.415 13.5576 127.388 13.0636 127.388 11.5553C127.388 10.1251 128.362 9.31898 130.442 9.31898C131.968 9.31898 133.495 9.70904 134.864 10.4892L135.022 9.13695C133.785 8.43484 132.258 7.99277 130.494 7.99277C127.599 7.99277 125.835 9.26697 125.835 11.6073C125.835 13.7397 127.362 14.8318 129.494 15.3779L130.626 15.664C132.758 16.2361 133.758 16.8081 133.758 18.2384C133.758 19.7726 132.6 20.6047 130.336 20.6047Z"
        fill="white"
      />
      <path
        d="M139.079 17.9263V9.63103H144.133L144.291 8.30482H139.079V4.61225L137.5 4.87229V18.1083C137.5 20.7087 139.158 21.9309 141.501 21.9309C142.343 21.9309 143.317 21.7229 144.08 21.2548L144.212 19.9286C143.449 20.2927 142.606 20.5527 141.632 20.5527C140.395 20.5527 139.079 19.9026 139.079 17.9263Z"
        fill="white"
      />
    </g>
    <path
      d="M40.6517 22.0344C41.6519 22.0344 42.8364 21.8264 43.9419 21.3063L44.2051 18.1598C43.1786 18.6799 42.0467 18.9139 40.9675 18.9139C39.0461 18.9139 37.3088 17.7438 37.3088 14.9353C37.3088 12.1789 38.9671 11.0087 41.0465 11.0087C42.0994 11.0087 43.0996 11.2167 43.9682 11.6328L44.2841 8.69436C43.2312 8.14827 42.0204 7.88823 40.678 7.88823C36.256 7.88823 33.4922 10.6447 33.4922 14.9613C33.4922 19.252 36.2296 22.0344 40.6517 22.0344Z"
      fill="white"
    />
    <path d="M45.9639 21.6184H49.8595V3.31152H45.9639V21.6184Z" fill="white" />
    <path
      d="M64.0512 21.6184C63.8933 20.5002 63.7617 19.408 63.7617 18.2378V12.4649C63.7617 9.50048 62.1824 7.91424 57.9973 7.91424C56.0758 7.91424 54.2069 8.25229 52.575 8.9544L52.2854 11.9969C54.0753 11.1907 55.8652 10.8787 57.5761 10.8787C59.3133 10.8787 59.8398 11.2948 59.8398 12.0489C59.8398 12.751 59.4976 13.011 58.4447 13.2451L56.1021 13.7651C52.5223 14.5713 51.4695 16.0275 51.4695 18.1338C51.4695 20.4742 53.2067 22.0084 55.9705 22.0084C57.734 22.0084 59.1554 21.2543 60.0767 20.1621L60.3136 21.6184H64.0512ZM57.0497 19.226C55.8915 19.226 55.1019 18.7579 55.1019 17.7178C55.1019 16.6516 55.8915 16.2095 57.4445 15.8195L58.7606 15.5074C59.2344 15.4034 59.6555 15.2474 59.9977 15.0913V17.9518C59.3133 18.7839 58.1815 19.226 57.0497 19.226Z"
      fill="white"
    />
    <path
      d="M72.6093 7.88823C71.2932 7.88823 70.1614 8.48632 69.5823 10.1506L69.3717 8.3043H65.9499V21.6184H69.6876V12.803C70.1614 11.6068 71.1089 10.9307 72.583 10.9307C73.1357 10.9307 73.6358 11.0347 74.1096 11.2167L74.4518 8.3303C73.9517 8.04426 73.3463 7.88823 72.6093 7.88823Z"
      fill="white"
    />
    <path
      d="M75.5059 6.51002H79.2436V3.31152H75.5059V6.51002ZM75.5059 21.6184H79.2436V8.3043H75.5059V21.6184Z"
      fill="white"
    />
    <path
      d="M85.305 16.7816V11.0347H89.3322L89.5954 8.3043H85.305V4.76775L81.4094 5.31383V17.2237C81.4094 20.3442 83.3045 21.9304 86.4631 21.9304C87.3844 21.9304 88.3583 21.7744 89.2532 21.4623L89.5428 18.4979C88.8057 18.7839 88.0951 18.8879 87.2528 18.8879C86.042 18.8879 85.305 18.2898 85.305 16.7816Z"
      fill="white"
    />
    <path
      d="M100.013 8.3043L96.9067 18.1598C96.8277 17.6657 96.7224 17.1977 96.5645 16.7296L93.6954 8.3043H89.8261L94.5114 20.3182C94.7746 21.0203 94.9588 21.6444 94.9588 22.1904C94.9588 22.4765 94.9325 22.6585 94.8536 22.9186C94.5377 23.8027 93.7744 24.2188 92.5899 24.2188C92.1951 24.2188 91.8002 24.1668 91.4317 24.0887L91.1159 26.7672C91.7213 26.9232 92.3267 27.0012 92.8268 27.0012C95.301 27.0012 97.0383 26.013 98.4596 22.2945L103.698 8.3043H100.013Z"
      fill="white"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46582 3.83691H17.8621C20.3283 3.83691 22.3276 5.83616 22.3276 8.30235V21.6987H17.8621V12.7678V8.30235H13.3967H4.46582V3.83691Z"
      fill="url(#paint0_linear)"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46544 8.30273H0V21.699C0 24.1652 1.99924 26.1645 4.46544 26.1645H17.8617V21.699H4.46544V8.30273Z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8621 21.699H4.46582V8.30273H13.3967H17.8621V12.7682V21.699ZM13.3983 16.6758H6.7002V17.7921H13.3983V16.6758ZM6.7002 14.4434H15.6311V15.5597H6.7002V14.4434ZM15.6311 12.209H6.7002V13.3253H15.6311V12.209Z"
      fill="url(#paint2_linear)"
    />
    <path
      opacity="0.75"
      d="M153.999 3.92999C154.013 4.72386 153.791 5.50393 153.36 6.17085C152.929 6.83777 152.309 7.36136 151.58 7.67492C150.85 7.98847 150.044 8.07782 149.263 7.93156C148.483 7.7853 147.763 7.41007 147.197 6.85365C146.63 6.29724 146.242 5.58482 146.082 4.80714C145.922 4.02946 145.997 3.22172 146.298 2.4868C146.598 1.75188 147.111 1.12304 147.77 0.680376C148.429 0.237713 149.205 0.00125954 149.999 0.0011359C150.522 -0.0114735 151.042 0.0810076 151.529 0.273094C152.015 0.465181 152.458 0.752962 152.831 1.11937C153.204 1.48578 153.499 1.92336 153.7 2.40616C153.901 2.88896 154.003 3.40714 153.999 3.92999ZM153.293 3.92999C153.293 3.27857 153.1 2.64177 152.738 2.10014C152.376 1.5585 151.862 1.13634 151.26 0.887054C150.658 0.637765 149.996 0.57254 149.357 0.699626C148.718 0.826713 148.131 1.1404 147.67 1.60103C147.209 2.06165 146.896 2.64852 146.769 3.28743C146.641 3.92633 146.707 4.58858 146.956 5.19041C147.205 5.79225 147.628 6.30665 148.169 6.66856C148.711 7.03047 149.348 7.22364 149.999 7.22364C150.437 7.24372 150.875 7.17228 151.283 7.01386C151.692 6.85545 152.063 6.61355 152.373 6.30361C152.683 5.99366 152.925 5.62249 153.084 5.21379C153.242 4.8051 153.314 4.36785 153.293 3.92999ZM150.776 4.32993L151.788 6.02381H150.917L150.07 4.49462H149.293V6.02381H148.494V1.81264H150.117C150.302 1.78881 150.49 1.80535 150.669 1.86113C150.847 1.9169 151.011 2.0106 151.15 2.13577C151.288 2.26094 151.398 2.41462 151.472 2.58626C151.545 2.7579 151.581 2.94343 151.576 3.1301C151.614 3.40813 151.555 3.69077 151.409 3.93064C151.264 4.1705 151.04 4.35301 150.776 4.44756V4.32993ZM149.293 3.76531H150.094C150.182 3.77563 150.271 3.76668 150.355 3.73908C150.439 3.71148 150.517 3.66589 150.582 3.60547C150.646 3.54505 150.697 3.47124 150.731 3.38916C150.765 3.30707 150.78 3.21868 150.776 3.1301C150.776 2.75368 150.517 2.54195 150.094 2.54195H149.293V3.76531Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.46575"
        y1="21.6985"
        x2="22.3273"
        y2="3.83694"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-7.47018e-05"
        y1="26.1643"
        x2="17.8615"
        y2="8.30276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="4.46584"
        y1="21.699"
        x2="17.8622"
        y2="8.30272"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);
