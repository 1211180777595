import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="11"
    height="19"
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M5.8792 8.31016C3.64934 7.72016 2.93224 7.11016 2.93224 6.16016C2.93224 5.07016 3.92438 4.31016 5.5845 4.31016C7.33303 4.31016 7.98136 5.16016 8.0403 6.41016H10.2112C10.1425 4.69016 9.11102 3.11016 7.05798 2.60016V0.410156H4.11102V2.57016C2.20533 2.99016 0.672911 4.25016 0.672911 6.18016C0.672911 8.49016 2.54914 9.64016 5.28981 10.3102C7.7456 10.9102 8.23676 11.7902 8.23676 12.7202C8.23676 13.4102 7.75543 14.5102 5.5845 14.5102C3.56093 14.5102 2.76525 13.5902 2.63755 12.4102H0.496094C0.613972 14.6002 2.22497 15.8302 4.11102 16.2402V18.4102H7.05798V16.2602C8.9735 15.9102 10.4961 14.7602 10.4961 12.7102C10.4961 9.87016 8.10906 8.91016 5.8792 8.31016Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
