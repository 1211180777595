// There is a discrepency between how these are labeled in the code vs. what
// they are called when using the application, I've added annotations to help.
export enum SiteType {
  MultisiteProject = 'Project', // Project Order
  MultisiteSite = 'Site', // Site Order
  SingleSite = 'Single', // Single Order
}

export enum SiteStatus {
  Cancelled = 'Cancelled', // should probably be deprecated
  Canceled = 'Canceled',
  Closed = 'Closed',
  Open = 'Open',
  OpenInError = 'Open In Error',
  Pending = 'Pending',
  Reserved = 'Reserved',
}

export const getEnabledSiteTypes = (
  isMultisiteProjectEnabled: boolean
): SiteType[] => {
  const siteTypes = [SiteType.SingleSite];
  if (isMultisiteProjectEnabled) {
    siteTypes.push(SiteType.MultisiteProject);
  }
  return siteTypes;
};
