import React from 'react';
import styled from 'styled-components';
import { blizzardBlue } from 'config/theme';
import { lineHeightFix } from 'utils/styles';

interface IHighlightProps {
  classSelectorName?: string;
  text: string;
}

const Highlight = ({ classSelectorName, text }: IHighlightProps) =>
  /class='hlsearch'/.test(text) ? (
    <HighLightWrapper
      className={
        classSelectorName
          ? `${classSelectorName}__highlight-wrapper`
          : undefined
      }
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <span
      className={
        classSelectorName ? `${classSelectorName}__wrapper` : undefined
      }
    >
      {text}
    </span>
  );
Highlight.displayName = 'Highlight';

export default Highlight;

const HighLightWrapper = styled.span`
  em.hlsearch {
    background: ${blizzardBlue};
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    font-style: normal;
    padding: 4px 4px 6px 4px;
    ${() => lineHeightFix('0.8em')}
  }
`;
HighLightWrapper.displayName = 'HighLightWrapper';
