import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="138"
    height="124"
    viewBox="0 0 138 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.784 105.784H9.19385C4.66969 105.784 1.00195 102.116 1.00195 97.5919V9.52905C1.00195 5.00179 4.66969 1.33716 9.19385 1.33716H119.784C124.309 1.33716 127.976 5.00179 127.976 9.52905V97.5919C127.976 102.116 124.309 105.784 119.784 105.784Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.294 1H9.195C6.36507 1 3.87337 2.43358 2.39945 4.61188C1.5182 5.91824 1 7.49456 1 9.19189V97.2548C1 98.7038 1.40959 100.044 2.07053 101.223L102.294 1Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.784 105.784H9.19385C4.66969 105.784 1.00195 102.116 1.00195 97.5919V9.52905C1.00195 5.00179 4.66969 1.33716 9.19385 1.33716H119.784C124.309 1.33716 127.976 5.00179 127.976 9.52905V97.5919C127.976 102.116 124.309 105.784 119.784 105.784Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.784 1.33691H9.19385C4.66969 1.33691 1.00195 5.00465 1.00195 9.52881V25.9126H127.976V9.52881C127.976 5.00465 124.309 1.33691 119.784 1.33691Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.784 1.33691H9.19385C4.66969 1.33691 1.00195 5.00465 1.00195 9.52881V25.9126H127.976V9.52881C127.976 5.00465 124.309 1.33691 119.784 1.33691Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect x="30" y="42" width="54" height="3" rx="1.5" fill="#9BABC2" />
    <circle
      cx="18.5"
      cy="43.5"
      r="5.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M23 42.7543C23 43.4131 20.7782 42.5383 18.4931 42.5383C16.208 42.5383 14 43.4131 14 42.7543C14 40.9383 16.2383 39 18.5234 39C20.8085 39 23 40.9383 23 42.7543Z"
      fill="#E8F4FA"
    />
    <rect x="30" y="64" width="33" height="3" rx="1.5" fill="#9BABC2" />
    <circle
      cx="18.5"
      cy="65.5"
      r="5.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M23 64.7543C23 65.4131 20.7782 64.5383 18.4931 64.5383C16.208 64.5383 14 65.4131 14 64.7543C14 62.9383 16.2383 61 18.5234 61C20.8085 61 23 62.9383 23 64.7543Z"
      fill="#E8F4FA"
    />
    <rect x="30" y="86" width="18" height="3" rx="1.5" fill="#9BABC2" />
    <circle
      cx="18.5"
      cy="87.5"
      r="5.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M23 86.7543C23 87.4131 20.7782 86.5383 18.4931 86.5383C16.208 86.5383 14 87.4131 14 86.7543C14 84.9383 16.2383 83 18.5234 83C20.8085 83 23 84.9383 23 86.7543Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.309 13.6248C116.309 15.1431 115.078 16.3739 113.56 16.3739C112.041 16.3739 110.811 15.1431 110.811 13.6248C110.811 12.1066 112.041 10.8757 113.56 10.8757C115.078 10.8757 116.309 12.1066 116.309 13.6248Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.309 13.6248C116.309 15.1431 115.078 16.3739 113.56 16.3739C112.041 16.3739 110.811 15.1431 110.811 13.6248C110.811 12.1066 112.041 10.8757 113.56 10.8757C115.078 10.8757 116.309 12.1066 116.309 13.6248Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.167 87.2443C136.167 106.984 120.23 122.989 100.572 122.989C80.913 122.989 64.9766 106.984 64.9766 87.2443C64.9766 67.5047 80.913 51.5026 100.572 51.5026C120.23 51.5026 136.167 67.5047 136.167 87.2443Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.167 87.2443C136.167 106.984 120.23 122.989 100.572 122.989C80.913 122.989 64.9766 106.984 64.9766 87.2443C64.9766 67.5047 80.913 51.5026 100.572 51.5026C120.23 51.5026 136.167 67.5047 136.167 87.2443Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.429 87.2443C128.429 102.693 115.958 115.215 100.57 115.215C85.1847 115.215 72.7139 102.693 72.7139 87.2443C72.7139 71.7955 85.1847 59.2733 100.57 59.2733C115.958 59.2733 128.429 71.7955 128.429 87.2443Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.571 67.0424C114.641 67.0424 126.241 77.5235 128.13 91.1293C128.307 89.8552 128.43 88.5657 128.43 87.2453C128.43 71.7965 115.956 59.2717 100.571 59.2717C85.1856 59.2717 72.7148 71.7965 72.7148 87.2453C72.7148 88.5657 72.8353 89.8552 73.0148 91.1293C74.9014 77.5235 86.5032 67.0424 100.571 67.0424Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.429 87.2443C128.429 102.693 115.958 115.215 100.57 115.215C85.1847 115.215 72.7139 102.693 72.7139 87.2443C72.7139 71.7955 85.1847 59.2733 100.57 59.2733C115.958 59.2733 128.429 71.7955 128.429 87.2443Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.2256 71.3703C97.2256 69.4402 98.7902 67.8756 100.72 67.8756C102.65 67.8756 104.215 69.4402 104.215 71.3703V93.3809C104.215 95.311 102.65 96.8756 100.72 96.8756C98.7902 96.8756 97.2256 95.311 97.2256 93.3809V71.3703Z"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M98.2236 71.3718C98.2236 69.9932 99.3412 68.8756 100.72 68.8756C102.098 68.8756 103.216 69.9932 103.216 71.3718V74.8756L98.2236 77.8756V71.3718Z"
      fill="#E8F4FA"
    />
    <ellipse
      cx="100.72"
      cy="103.376"
      rx="3.49468"
      ry="3.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M103.216 102.958C103.216 103.376 102.098 102.851 100.72 102.851C99.3412 102.851 98.2236 103.376 98.2236 102.958C98.2236 101.808 99.3412 100.876 100.72 100.876C102.098 100.876 103.216 101.808 103.216 102.958Z"
      fill="#E8F4FA"
    />
  </svg>
);
