import React from 'react';

export default () => (
  <svg
    width="192"
    height="106"
    viewBox="0 0 192 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.845 45.3203C190.845 61.4926 177.758 74.6051 161.613 74.6051C145.469 74.6051 132.381 61.4926 132.381 45.3203C132.381 29.148 145.469 16.0376 161.613 16.0376C177.758 16.0376 190.845 29.148 190.845 45.3203Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.845 44.6133C190.845 60.7857 177.758 73.8981 161.613 73.8981C145.469 73.8981 132.381 60.7857 132.381 44.6133C132.381 28.441 145.469 22.4 161.613 22.4C177.758 22.4 190.845 28.441 190.845 44.6133Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.845 45.3203C190.845 61.4926 177.758 74.6051 161.613 74.6051C145.469 74.6051 132.381 61.4926 132.381 45.3203C132.381 29.148 145.469 16.0376 161.613 16.0376C177.758 16.0376 190.845 29.148 190.845 45.3203Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.499 45.3175C184.499 57.9744 174.257 68.2337 161.62 68.2337C148.985 68.2337 138.743 57.9744 138.743 45.3175C138.743 32.6605 148.985 22.4012 161.62 22.4012C174.257 22.4012 184.499 32.6605 184.499 45.3175Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.621 28.7664C173.176 28.7664 182.702 37.3534 184.253 48.5005C184.399 47.4566 184.5 46.4001 184.5 45.3183C184.5 32.6613 174.256 22.4 161.621 22.4C148.986 22.4 138.744 32.6613 138.744 45.3183C138.744 46.4001 138.843 47.4566 138.991 48.5005C140.54 37.3534 150.068 28.7664 161.621 28.7664Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.499 45.3162C184.499 57.9732 174.257 68.2324 161.62 68.2324C148.985 68.2324 138.743 57.9732 138.743 45.3162C138.743 32.6592 148.985 22.4 161.62 22.4C174.257 22.4 184.499 32.6592 184.499 45.3162Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.941 57.5973H145.76V49.7799L152.851 43.7204L159.941 49.7799V57.5973Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.626 57.9497H150.868V54.5477C150.868 53.5045 151.709 52.6586 152.747 52.6586C153.785 52.6586 154.626 53.5045 154.626 54.5477V57.9497Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.941 57.5973H145.76V49.7799L152.851 43.7204L159.941 49.7799V57.5973Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.167 51.5275C159.821 51.5275 159.475 51.4088 159.194 51.1677L152.103 50.994L146.96 51.1677C145.441 50.994 145.378 51.6324 144.842 50.994C144.304 50.3581 144.381 49.4048 145.013 48.8638L152.103 42.8043C152.664 42.3233 153.49 42.3233 154.05 42.8043L161.14 48.8638C161.773 49.4048 161.849 50.3581 161.312 50.994C161.015 51.3451 160.591 51.5275 160.167 51.5275Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.626 57.3113H150.868V53.9093C150.868 52.866 151.746 52.6586 152.783 52.6586C153.822 52.6586 154.626 52.866 154.626 53.9093V57.3113Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.167 50.889C159.821 50.889 159.168 50.889 159.168 50.889H152.783H151.945H147.356C147.356 50.889 145.38 50.9915 144.842 50.3555C144.304 49.7196 144.381 48.7663 145.013 48.2253L152.103 42.1659C152.664 41.6849 153.489 41.6849 154.05 42.1659L161.14 48.2253C161.773 48.7663 161.849 49.7196 161.312 50.3555C161.015 50.7066 160.591 50.889 160.167 50.889Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.614 31.59H159.959C159.522 31.59 159.168 31.8762 159.168 32.2258V57.1303C159.168 57.4813 159.522 57.7661 159.959 57.7661H175.614C176.051 57.7661 176.405 57.4813 176.405 57.1303V32.2258C176.405 31.8762 176.051 31.59 175.614 31.59Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.223 31.59H159.988C159.535 31.59 159.168 31.8762 159.168 32.2258V57.1303C159.168 57.4813 159.535 57.7661 159.988 57.7661H176.223C176.676 57.7661 177.044 57.4813 177.044 57.1303V32.2258C177.044 31.8762 176.676 31.59 176.223 31.59Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M161.721 37.9745H174.49V39.2513H161.721V37.9745Z" fill="#9BABC2" />
    <path
      d="M164.914 36.6976C164.914 36.345 165.199 36.0591 165.552 36.0591C165.905 36.0591 166.19 36.345 166.19 36.6976V37.9745H164.914V36.6976Z"
      fill="#9BABC2"
    />
    <path
      d="M170.021 36.6976C170.021 36.345 170.307 36.0591 170.66 36.0591C171.012 36.0591 171.298 36.345 171.298 36.6976V37.9745H170.021V36.6976Z"
      fill="#9BABC2"
    />
    <path d="M161.721 44.3589H174.49V45.6358H161.721V44.3589Z" fill="#9BABC2" />
    <path
      d="M164.914 43.082C164.914 42.7294 165.199 42.4436 165.552 42.4436C165.905 42.4436 166.19 42.7294 166.19 43.082V44.3589H164.914V43.082Z"
      fill="#9BABC2"
    />
    <path
      d="M170.021 43.082C170.021 42.7294 170.307 42.4436 170.66 42.4436C171.012 42.4436 171.298 42.7294 171.298 43.082V44.3589H170.021V43.082Z"
      fill="#9BABC2"
    />
    <path d="M161.721 51.3817H174.49V52.6586H161.721V51.3817Z" fill="#9BABC2" />
    <path
      d="M164.914 50.1048C164.914 49.7522 165.199 49.4664 165.552 49.4664C165.905 49.4664 166.19 49.7522 166.19 50.1048V51.3817H164.914V50.1048Z"
      fill="#9BABC2"
    />
    <path
      d="M170.021 50.1048C170.021 49.7522 170.307 49.4664 170.66 49.4664C171.012 49.4664 171.298 49.7522 171.298 50.1048V51.3817H170.021V50.1048Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6245 46.1117C62.6245 63.1582 48.8293 76.9794 31.8123 76.9794C14.7952 76.9794 1 63.1582 1 46.1117C1 29.0652 14.7952 15.2461 31.8123 15.2461C48.8293 15.2461 62.6245 29.0652 62.6245 46.1117Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6245 45.3665C62.6245 62.413 48.8293 76.2343 31.8123 76.2343C14.7952 76.2343 1 62.413 1 45.3665C1 28.32 14.7952 21.9524 31.8123 21.9524C48.8293 21.9524 62.6245 28.32 62.6245 45.3665Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6245 46.1117C62.6245 63.1582 48.8293 76.9794 31.8123 76.9794C14.7952 76.9794 1 63.1582 1 46.1117C1 29.0652 14.7952 15.2461 31.8123 15.2461C48.8293 15.2461 62.6245 29.0652 62.6245 46.1117Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.9351 46.1087C55.9351 59.4498 45.1399 70.2637 31.8196 70.2637C18.5015 70.2637 7.7063 59.4498 7.7063 46.1087C7.7063 32.7676 18.5015 21.9538 31.8196 21.9538C45.1399 21.9538 55.9351 32.7676 55.9351 46.1087Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8204 28.663C44.0002 28.663 54.0409 37.7141 55.6763 49.4637C55.8294 48.3635 55.9359 47.2498 55.9359 46.1096C55.9359 32.7684 45.1385 21.9524 31.8204 21.9524C18.5023 21.9524 7.70715 32.7684 7.70715 46.1096C7.70715 47.2498 7.81144 48.3635 7.96677 49.4637C9.59991 37.7141 19.6429 28.663 31.8204 28.663Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.9351 46.1073C55.9351 59.4485 45.1399 70.2623 31.8196 70.2623C18.5015 70.2623 7.7063 59.4485 7.7063 46.1073C7.7063 32.7662 18.5015 21.9524 31.8196 21.9524C45.1399 21.9524 55.9351 32.7662 55.9351 46.1073Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.2053 37.9368H36.4558C36.1279 37.9368 35.8618 38.1515 35.8618 38.414V57.1056C35.8618 57.369 36.1279 57.5828 36.4558 57.5828H48.2053C48.5332 57.5828 48.7994 57.369 48.7994 57.1056V38.414C48.7994 38.1515 48.5332 37.9368 48.2053 37.9368Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.6625 37.9368H36.4778C36.1377 37.9368 35.8618 38.1515 35.8618 38.414V57.1056C35.8618 57.369 36.1377 57.5828 36.4778 57.5828H48.6625C49.0026 57.5828 49.2785 57.369 49.2785 57.1056V38.414C49.2785 38.1515 49.0026 37.9368 48.6625 37.9368Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7785 42.7285H47.3619V43.6868H37.7785V42.7285Z"
      fill="#9BABC2"
    />
    <path
      d="M40.1743 41.7702C40.1743 41.5055 40.3888 41.291 40.6534 41.291C40.9181 41.291 41.1326 41.5055 41.1326 41.7702V42.7285H40.1743V41.7702Z"
      fill="#9BABC2"
    />
    <path
      d="M44.0076 41.7702C44.0076 41.5055 44.2222 41.291 44.4868 41.291C44.7514 41.291 44.966 41.5055 44.966 41.7702V42.7285H44.0076V41.7702Z"
      fill="#9BABC2"
    />
    <path
      d="M37.7785 47.5202H47.3619V48.4785H37.7785V47.5202Z"
      fill="#9BABC2"
    />
    <path
      d="M40.1743 46.5618C40.1743 46.2972 40.3888 46.0827 40.6534 46.0827C40.9181 46.0827 41.1326 46.2972 41.1326 46.5618V47.5202H40.1743V46.5618Z"
      fill="#9BABC2"
    />
    <path
      d="M44.0076 46.5618C44.0076 46.2972 44.2222 46.0827 44.4868 46.0827C44.7514 46.0827 44.966 46.2972 44.966 46.5618V47.5202H44.0076V46.5618Z"
      fill="#9BABC2"
    />
    <path
      d="M37.7785 52.7911H47.3619V53.7494H37.7785V52.7911Z"
      fill="#9BABC2"
    />
    <path
      d="M40.1743 51.8327C40.1743 51.5681 40.3888 51.3536 40.6534 51.3536C40.9181 51.3536 41.1326 51.5681 41.1326 51.8327V52.7911H40.1743V51.8327Z"
      fill="#9BABC2"
    />
    <path
      d="M44.0076 51.8327C44.0076 51.5681 44.2222 51.3536 44.4868 51.3536C44.7514 51.3536 44.966 51.5681 44.966 51.8327V52.7911H44.0076V51.8327Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9045 31.8666H23.2519C22.859 31.8666 22.5411 32.1337 22.5411 32.4623V57.4511C22.5411 57.7797 22.859 58.0457 23.2519 58.0457H35.9045C36.2974 58.0457 36.6153 57.7797 36.6153 57.4511V32.4623C36.6153 32.1337 36.2974 31.8666 35.9045 31.8666Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9045 31.8666H23.2519C22.859 31.8666 22.5411 32.1337 22.5411 32.4623V57.4511C22.5411 57.7797 22.859 58.0457 23.2519 58.0457H35.9045C36.2974 58.0457 36.6153 57.7797 36.6153 57.4511V32.4623C36.6153 32.1337 36.2974 31.8666 35.9045 31.8666Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5944 35.5491H30.0108C30.3159 35.5491 30.5633 35.7965 30.5633 36.1016C30.5633 36.4067 30.3159 36.654 30.0108 36.654H22.5944V35.5491Z"
      fill="#9BABC2"
    />
    <path
      d="M22.5944 38.8638H26.0264C26.3315 38.8638 26.5788 39.1112 26.5788 39.4163C26.5788 39.7214 26.3315 39.9687 26.0264 39.9687H22.5944V38.8638Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7393 58.3153H14.8392V51.7553L20.7898 46.6705L26.7393 51.7553V58.3153Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7393 58.3153H14.8392V51.7553L20.7898 46.6705L26.7393 51.7553V58.3153Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8255 52.6032C26.5356 52.6032 26.2447 52.5036 26.009 52.3013L20.876 47.9136L15.7429 52.3013C15.2143 52.7563 14.4156 52.6913 13.9651 52.1555C13.5137 51.6219 13.5783 50.8219 14.109 50.368L20.0585 45.2831C20.5298 44.8795 21.2221 44.8795 21.6924 45.2831L27.6419 50.368C28.1736 50.8219 28.2372 51.6219 27.7868 52.1555C27.5376 52.4501 27.181 52.6032 26.8255 52.6032Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8255 52.6032C26.5356 52.6032 26.2447 52.5036 26.009 52.3013L20.876 47.9136L15.7429 52.3013C15.2143 52.7563 14.4156 52.6913 13.9651 52.1555C13.5137 51.6219 13.5783 50.8219 14.109 50.368L20.0585 45.2831C20.5298 44.8795 21.2221 44.8795 21.6924 45.2831L27.6419 50.368C28.1736 50.8219 28.2372 51.6219 27.7868 52.1555C27.5376 52.4501 27.181 52.6032 26.8255 52.6032Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4093 58.3H19.2552V55.4451C19.2552 54.5697 19.9611 53.8599 20.8318 53.8599C21.7034 53.8599 22.4093 54.5697 22.4093 55.4451V58.3Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4093 58.3H19.2552V55.4451C19.2552 54.5697 19.9611 53.8599 20.8318 53.8599C21.7034 53.8599 22.4093 54.5697 22.4093 55.4451V58.3Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.9511 8.72389C118.043 8.72389 135.359 24.8308 137.305 45.4124C137.425 44.1405 137.5 42.8558 137.5 41.5504C137.5 19.1559 119.345 1 96.9511 1C74.5558 1 56.4021 19.1559 56.4021 41.5504C56.4021 42.8558 56.4771 44.1405 56.5968 45.4124C58.5436 24.8308 75.8596 8.72389 96.9511 8.72389Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.83 102.986C118.83 104.537 109.651 105.795 98.3286 105.795C87.0058 105.795 77.8271 104.537 77.8271 102.986C77.8271 101.432 87.0058 100.174 98.3286 100.174C109.651 100.174 118.83 101.432 118.83 102.986Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.9043 96.3703C46.9043 97.0259 40.8805 97.5572 33.4496 97.5572C26.0187 97.5572 19.9949 97.0259 19.9949 96.3703C19.9949 95.7141 26.0187 95.1828 33.4496 95.1828C40.8805 95.1828 46.9043 95.7141 46.9043 96.3703Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.494 96.3703C177.494 97.0259 171.47 97.5572 164.039 97.5572C156.608 97.5572 150.585 97.0259 150.585 96.3703C150.585 95.7141 156.608 95.1828 164.039 95.1828C171.47 95.1828 177.494 95.7141 177.494 96.3703Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.913 74.3188L129.718 82.5151L120.16 72.956L128.356 64.7613L137.913 74.3188Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.913 74.3188L129.718 82.5151L120.16 72.956L128.356 64.7613L137.913 74.3188Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.5 41.5509C137.5 63.9454 119.346 82.1014 96.9507 82.1014C74.557 82.1014 56.4017 63.9454 56.4017 41.5509C56.4017 19.1564 74.557 1.00048 96.9507 1.00048C119.346 1.00048 137.5 19.1564 137.5 41.5509Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.5 41.5509C137.5 63.9454 119.346 82.1014 96.9507 82.1014C74.557 82.1014 56.4017 63.9454 56.4017 41.5509C56.4017 19.1564 74.557 1.00048 96.9507 1.00048C119.346 1.00048 137.5 19.1564 137.5 41.5509Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.811 41.5509C128.811 59.1466 114.548 73.4119 96.9509 73.4119C79.3558 73.4119 65.091 59.1466 65.091 41.5509C65.091 23.9551 79.3558 9.68979 96.9509 9.68979C114.548 9.68979 128.811 23.9551 128.811 41.5509Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.4224 64.0797C61.98 51.6369 61.98 31.4654 74.4224 19.0226C86.8648 6.57977 107.037 6.57977 119.48 19.0226L74.4224 64.0797Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.811 41.5509C128.811 59.1466 114.548 73.4119 96.9509 73.4119C79.3558 73.4119 65.091 59.1466 65.091 41.5509C65.091 23.9551 79.3558 9.68979 96.9509 9.68979C114.548 9.68979 128.811 23.9551 128.811 41.5509Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.2958 19.3449C81.3271 19.3449 80.5404 20.1316 80.5404 21.1019V68.8241C85.3389 71.7158 90.9402 73.4121 96.9515 73.4121C101.41 73.4121 105.65 72.4897 109.504 70.8364V21.1019C109.504 20.1316 108.717 19.3449 107.749 19.3449H82.2958Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.504 21.1012C109.504 20.1326 108.717 19.3442 107.749 19.3442H82.296C81.3273 19.3442 80.5406 20.1326 80.5406 21.1012V57.9653L109.504 28.9991V21.1012Z"
      fill="white"
    />
    <path
      d="M81.7281 24.3829H93.5999C94.037 24.3829 94.3914 24.7373 94.3914 25.1744C94.3914 25.6115 94.037 25.9658 93.5999 25.9658H81.7281V24.3829Z"
      fill="#9BABC2"
    />
    <path
      d="M81.7281 30.7145H87.2683C87.7054 30.7145 88.0598 31.0689 88.0598 31.506C88.0598 31.9431 87.7054 32.2974 87.2683 32.2974H81.7281V30.7145Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.692 18.8428C81.7234 18.8428 80.9367 19.6295 80.9367 20.5998V68.3219C85.7352 71.2136 91.3364 72.91 97.3478 72.91C101.806 72.91 106.046 71.9876 109.9 70.3343V20.5998C109.9 19.6295 109.114 18.8428 108.145 18.8428H82.692Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.435 65.6883C111.435 64.6223 112.3 63.7573 113.366 63.7573H117.228C117.912 63.7573 118.485 64.134 118.828 64.6718C121.962 61.7051 124.508 58.1272 126.236 54.1025H99.8495V73.2654C103.995 72.892 107.902 71.7142 111.435 69.9061V65.6883Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.814 49.3339L116.977 43.4309C115.93 42.8612 114.664 42.8612 113.617 43.4309L97.5686 52.1713V54.1023H126.235C126.891 52.5735 127.402 50.9728 127.814 49.3339Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.802 49.7081L116.965 43.8051C115.918 43.2353 114.653 43.2353 113.606 43.8051L97.5572 52.5455V54.4765H126.224C126.88 52.9477 127.39 51.347 127.802 49.7081Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.227 63.7571H113.366C112.3 63.7571 111.435 64.622 111.435 65.6881V69.9059C114.151 68.5143 116.634 66.7493 118.828 64.6715C118.486 64.1337 117.912 63.7571 117.227 63.7571Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.227 63.7571H113.366C112.3 63.7571 111.435 64.622 111.435 65.6881V69.9059C114.151 68.5143 116.634 66.7493 118.828 64.6715C118.486 64.1337 117.912 63.7571 117.227 63.7571Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.399 65.9423C111.399 64.8857 112.255 64.0283 113.31 64.0283H117.133C117.811 64.0283 118.378 64.4016 118.717 64.9347C121.819 61.994 124.339 58.4475 126.049 54.4581H99.9315V73.453C104.035 73.0828 107.902 71.9154 111.399 70.1232V65.9423Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.6414 41.102C71.6712 41.102 70.886 41.8872 70.886 42.8574V59.8372C76.1282 67.2962 84.4742 72.4013 94.0569 73.2662V42.8574C94.0569 41.8872 93.2702 41.102 92.3015 41.102H72.6414Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.6938 41.8325C93.6938 40.9005 92.9159 40.1435 91.9545 40.1435H72.4743C71.5129 40.1435 70.735 40.9005 70.735 41.8325V59.7099C71.788 61.164 72.9502 62.5398 74.2404 63.7927L93.6938 44.9018V41.8325Z"
      fill="white"
    />
    <path
      d="M71.4393 52.8752H93.5999V54.4581H71.4393V52.8752Z"
      fill="#9BABC2"
    />
    <path
      d="M71.4393 46.5436H93.5999V48.1265H71.4393V46.5436Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.5623 67.1213C83.1241 70.1787 88.5295 72.1574 94.3914 72.6615V67.1213H78.5623Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.6646 67.1213H94.3914V60.7897H72.2307C74.0932 63.2003 76.2672 65.3213 78.6646 67.1213Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.3914 60.7897V42.5024C94.3914 41.6752 93.5869 41.0034 92.5926 41.0034H72.4466C71.4524 41.0034 70.6478 41.6752 70.6478 42.5024V59.0918C71.1368 59.671 71.6339 60.246 72.1719 60.7897H94.3914Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.3965 44.9607C75.3965 44.5236 75.7509 44.1692 76.188 44.1692C76.6251 44.1692 76.9795 44.5236 76.9795 44.9607V46.5436H75.3965V44.9607Z"
      fill="#9BABC2"
    />
    <path
      d="M81.7281 44.9607C81.7281 44.5236 82.0825 44.1692 82.5196 44.1692C82.9567 44.1692 83.3111 44.5236 83.3111 44.9607V46.5436H81.7281V44.9607Z"
      fill="#9BABC2"
    />
    <path
      d="M88.0598 44.9607C88.0598 44.5236 88.4141 44.1692 88.8512 44.1692C89.2883 44.1692 89.6427 44.5236 89.6427 44.9607V46.5436H88.0598V44.9607Z"
      fill="#9BABC2"
    />
    <path
      d="M75.3965 51.2923C75.3965 50.8552 75.7509 50.5008 76.188 50.5008C76.6251 50.5008 76.9795 50.8552 76.9795 51.2923V52.8752H75.3965V51.2923Z"
      fill="#9BABC2"
    />
    <path
      d="M81.7281 51.2923C81.7281 50.8552 82.0825 50.5008 82.5196 50.5008C82.9567 50.5008 83.3111 50.8552 83.3111 51.2923V52.8752H81.7281V51.2923Z"
      fill="#9BABC2"
    />
    <path
      d="M88.0598 51.2923C88.0598 50.8552 88.4141 50.5008 88.8512 50.5008C89.2883 50.5008 89.6427 50.8552 89.6427 51.2923V52.8752H88.0598V51.2923Z"
      fill="#9BABC2"
    />
    <path
      d="M75.3965 58.4154C75.3965 57.9783 75.7509 57.6239 76.188 57.6239C76.6251 57.6239 76.9795 57.9783 76.9795 58.4154V59.9983H75.3965V58.4154Z"
      fill="#9BABC2"
    />
    <path
      d="M81.7281 58.4154C81.7281 57.9783 82.0825 57.6239 82.5196 57.6239C82.9567 57.6239 83.3111 57.9783 83.3111 58.4154V59.9983H81.7281V58.4154Z"
      fill="#9BABC2"
    />
    <path
      d="M88.0598 58.4154C88.0598 57.9783 88.4141 57.6239 88.8512 57.6239C89.2883 57.6239 89.6427 57.9783 89.6427 58.4154V59.9983H88.0598V58.4154Z"
      fill="#9BABC2"
    />
    <path
      d="M81.7281 64.747C81.7281 64.3099 82.0825 63.9555 82.5196 63.9555C82.9567 63.9555 83.3111 64.3099 83.3111 64.747V66.3299H81.7281V64.747Z"
      fill="#9BABC2"
    />
    <path
      d="M88.0598 64.747C88.0598 64.3099 88.4141 63.9555 88.8512 63.9555C89.2883 63.9555 89.6427 64.3099 89.6427 64.747V66.3299H88.0598V64.747Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.028 90.7044C160.044 93.7205 160.044 98.6102 157.028 101.628C154.012 104.642 149.121 104.642 146.105 101.628L129.721 85.2418C128.967 84.4869 128.967 83.2661 129.721 82.5113L137.913 74.3182C138.667 73.565 139.889 73.565 140.644 74.3182L157.028 90.7044Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.914 74.3185L133.817 78.415C134.57 77.6618 135.794 77.6618 136.548 78.415L152.933 94.7996C155.223 97.0912 155.764 100.458 154.575 103.27C155.466 102.894 156.303 102.351 157.028 101.627C160.044 98.612 160.046 93.7192 157.028 90.7046L140.644 74.3185C139.889 73.5637 138.667 73.5637 137.914 74.3185Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.028 90.7044C160.044 93.7205 160.044 98.6102 157.028 101.628C154.012 104.642 149.121 104.642 146.105 101.628L129.721 85.2418C128.967 84.4869 128.967 83.2661 129.721 82.5113L137.913 74.3182C138.667 73.565 139.889 73.565 140.644 74.3182L157.028 90.7044Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
