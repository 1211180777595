import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17.1H2V6.65H16V17.1ZM16 1.9H15V0H13V1.9H5V0H3V1.9H2C0.89 1.9 0 2.755 0 3.8V17.1C0 17.6039 0.210714 18.0872 0.585786 18.4435C0.960859 18.7998 1.46957 19 2 19H16C16.5304 19 17.0391 18.7998 17.4142 18.4435C17.7893 18.0872 18 17.6039 18 17.1V3.8C18 3.29609 17.7893 2.81282 17.4142 2.4565C17.0391 2.10018 16.5304 1.9 16 1.9ZM13.53 9.557L12.47 8.55L7.59 13.186L5.47 11.172L4.41 12.179L7.59 15.2L13.53 9.557Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
