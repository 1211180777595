import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import styled from 'styled-components';
import { blue, gray, headerHeight, black } from 'config/theme';

export default () => (
  <BreadcrumbContainer>
    <Breadcrumbs
      container="div"
      separator={<BreadcrumbSeparator>/</BreadcrumbSeparator>}
      item={BreadcrumbLink}
      finalItem="span"
    />
  </BreadcrumbContainer>
);

const BreadcrumbContainer = styled.nav`
  align-items: center;
  color: ${blue};
  display: flex;
  font-size: 0.8em;

  > div > * {
    display: inline-block;
    height: ${headerHeight}px;
    line-height: ${headerHeight}px;
  }
`;

interface IBreadcrumbLink {
  to: string;
  children: React.ReactNode;
  className?: string;
  current?: boolean;
}

export const BreadcrumbLink = styled(
  ({ to, children, className }: IBreadcrumbLink) => (
    <NavLink to={to} className={className}>
      {children}
    </NavLink>
  )
)<{ current?: boolean }>`
  color: ${(props) => (props.current ? gray : blue)};
  pointer-events: ${(props) => (props.current ? 'none' : 'auto')};
  text-decoration: none;
`;

const BreadcrumbSeparator = styled.span`
  display: inline-block;
  margin: 0 7px;
`;

interface IBreadcrumbItem {
  to: string;
  text: string;
}

interface IBreadcrumbItems {
  items: IBreadcrumbItem[];
}

export const BreadcrumbsItems = (props: IBreadcrumbItems) => (
  <Fragment>
    {props.items.map((item, idx) => {
      const itemColor = props.items.length - 1 === idx ? black : blue;
      return (
        <BreadcrumbsItem
          key={`breadcrumbs-item-${idx}`}
          to={item.to}
          style={{ color: itemColor }}
        >
          {item.text}
        </BreadcrumbsItem>
      );
    })}
  </Fragment>
);
