import { CSSProperties } from 'react';
import { FileLoadingKeys } from 'store/files/types';
import { UniversalWireInstructionLoadingKeys } from 'store/universalWireAccounts/types';
import { WireInstructionLoadingKeys } from 'store/wireInstructions/types';
import { DashboardLoadingKeys } from 'store/dashboard/types';
import { OrderLoadingKeys, OrderModalKeys } from 'store/orders/types';
import { OrganizationLoadingKeys } from 'store/orderOrganization/types';
import { AuthLoadingKeys } from 'store/auth/types';
import { AppNotificationsLoadingKeys } from 'store/appNotifications/types';
import { BillingAndPaymentsLoadingKeys } from 'store/billingAndPayments/types';
import {
  ToasterIntent,
  IToasterOptions,
  IToastOptions,
} from '../../components/Snackbar/types';
import { BulkInvitationsLoadingKeys } from 'store/bulkInvitations/types';

export enum UiTypeKeys {
  SET_FOOTER_STYLE = 'SET_FOOTER_STYLE',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_MODAL_STATE = 'SET_MODAL_STATE',
  ADD_TOAST = 'ui/ADD_TOAST',
  REMOVE_TOAST = 'ui/REMOVE_TOAST',
  CANCEL = 'CANCEL-NAVIGATION',
  CONFIRM = 'CONFIRM-NAVIGATION',
  SHOW_SIDEBAR_WINDOW = 'SHOW_SIDEBAR_WINDOW',
}

export interface IUiState {
  loaders: IUiLoaders;
  modals: IUiModals;
  toasts: IToastState;
  footerStyleOverrides?: CSSProperties;
  showSidebarWindow?: boolean;
}

export type IUiAction =
  | IUiSetFooterStyleOverrideAction
  | IUINavigationModalCancelAction
  | IUINavigationModalConfirmAction
  | IUiSetLoadingStateAction
  | IUiSetModalStateAction
  | IUiAddToastAction
  | IUiRemoveToastAction
  | IUiSetShowSidebarWindowAction;

export type IUiLoadingKeys =
  | AuthLoadingKeys
  | FileLoadingKeys
  | WireInstructionLoadingKeys
  | DashboardLoadingKeys
  | OrderLoadingKeys
  | UniversalWireInstructionLoadingKeys
  | AppNotificationsLoadingKeys
  | BillingAndPaymentsLoadingKeys
  | BulkInvitationsLoadingKeys
  | OrganizationLoadingKeys;

export enum ModalKey {
  SessionTimeoutModal = 'session_timeout_modal',
}
export enum NavigationPromptKey {
  NavigationPromptModal = 'navigation_prompt_modal',
}

export type IUiModalKeys = OrderModalKeys | ModalKey | NavigationPromptKey;

export type IUiLoaders = {
  [key in IUiLoadingKeys]: boolean;
};

export type IUiModals = {
  [key in IUiModalKeys]: boolean;
};

export interface IUiSetLoadingStateAction {
  type: UiTypeKeys.SET_LOADING_STATE;
  loadingKey: IUiLoadingKeys;
  isLoading: boolean;
}

export interface IUiSetModalStateAction {
  type: UiTypeKeys.SET_MODAL_STATE;
  modalKey: IUiModalKeys;
  isOpen: boolean;
}

export interface IUiSetFooterStyleOverrideAction extends CSSProperties {
  type: UiTypeKeys.SET_FOOTER_STYLE;
}

export interface IUiSetShowSidebarWindowAction {
  type: UiTypeKeys.SHOW_SIDEBAR_WINDOW;
  payload: boolean;
}

interface IUiAddToastAction {
  type: UiTypeKeys.ADD_TOAST;
  payload: {
    text: string;
    intent: ToasterIntent;
    options?: IToasterOptions;
  };
}

interface IUINavigationModalCancelAction {
  type: UiTypeKeys.CANCEL;
}

interface IUINavigationModalConfirmAction {
  type: UiTypeKeys.CONFIRM;
}

interface IUiRemoveToastAction {
  type: UiTypeKeys.REMOVE_TOAST;
  payload: {
    idsToRemove: string[];
  };
}

export interface IToastState {
  [toastId: string]: {
    id: string;
    text: string;
    intent: ToasterIntent;
    options: IToasterOptions | IToastOptions;
  };
}
