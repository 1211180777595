import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0006 31.8695C24.5791 31.8695 31.5332 24.9164 31.5332 16.3371C31.5332 7.75781 24.5791 0.804688 16.0006 0.804688C7.42215 0.804688 0.468384 7.75781 0.468384 16.3371C0.468384 24.9164 7.42215 31.8695 16.0006 31.8695ZM23.7707 12.4077L13.6222 22.5582L8.2316 17.1675L10.4032 14.9974L13.6222 18.2005L21.5995 10.2376L23.7707 12.4077Z"
      fill={props.fill || white}
    />
  </svg>
);
