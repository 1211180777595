/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Eclipse_SDK_DTO_ImageFileExtensionType } from '../models/Eclipse_SDK_DTO_ImageFileExtensionType';
import type { System_IO_MemoryStream } from '../models/System_IO_MemoryStream';
import type { Workspace_Common_Models_CustomerDocumentStatusType } from '../models/Workspace_Common_Models_CustomerDocumentStatusType';
import type { Workspace_Common_Models_Dto_CustomerDocumentUploading_CustomerDocumentDto } from '../models/Workspace_Common_Models_Dto_CustomerDocumentUploading_CustomerDocumentDto';
import type { Workspace_Common_Models_FileDocumentSortPropertyType } from '../models/Workspace_Common_Models_FileDocumentSortPropertyType';
import type { Workspace_Common_Models_SortDirectionType } from '../models/Workspace_Common_Models_SortDirectionType';
import type { Workspace_Common_Responses_CustomerUploadedDocumentList } from '../models/Workspace_Common_Responses_CustomerUploadedDocumentList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerDocumentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Fetches the document content by order and FAST document identifiers. (Auth)
   * @returns binary Document was successfully fetched.
   * @throws ApiError
   */
  public downloadCustomerDocumentContentAsync({
    orderId,
    documentId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Document identifier on FAST side.
     */
    documentId: number,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/{documentId}/download',
      path: {
        'orderId': orderId,
        'documentId': documentId,
      },
      errors: {
        403: `User does not have sufficient permissions to download customer document.`,
        404: `Document or file was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Fetches the document content by order and FAST document identifiers. (Auth)
   * @returns System_IO_MemoryStream Document was successfully fetched.
   * @throws ApiError
   */
  public previewCustomerDocumentContentAsync({
    orderId,
    documentId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Document identifier on FAST side.
     */
    documentId: number,
  }): CancelablePromise<System_IO_MemoryStream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/{documentId}/preview',
      path: {
        'orderId': orderId,
        'documentId': documentId,
      },
      errors: {
        403: `User does not have sufficient permissions to preview customer document.`,
        404: `Document or file was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updates the review details for the customer document. (Auth)
   * @returns boolean Customer document was successfully updated.
   * @throws ApiError
   */
  public updateCustomerDocument({
    orderId,
    customerDocumentId,
    formData,
  }: {
    /**
     * File identifier.
     */
    orderId: number,
    /**
     * Identity value of customer document.
     */
    customerDocumentId: number,
    formData?: {
      DisplayName?: string | null;
      DocumentStatus: Workspace_Common_Models_CustomerDocumentStatusType;
      Type?: string | null;
      SubType?: string | null;
      AdditionalInfo?: string | null;
      Notes?: string | null;
      IsDraftState?: boolean;
      'DocumentType.Id'?: number;
      'DocumentType.Description'?: string | null;
      'DocumentType.SubTypes'?: Array<string> | null;
    },
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/{customerDocumentId}/review',
      path: {
        'orderId': orderId,
        'customerDocumentId': customerDocumentId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        403: `User does not have sufficient permissions to update customer document.`,
        404: `Customer document was not found.`,
        422: `Validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Upload a customer document to temporary storage. (Auth)
   * @returns any Document successfully uploaded.
   * @throws ApiError
   */
  public uploadCustomerDocument({
    orderId,
    formData,
  }: {
    /**
     * The order identifier for which the customer document will be uploaded.
     */
    orderId: number,
    formData?: {
      DisplayName?: string | null;
      File: Blob;
      CustomerDocumentId?: number | null;
      Notes?: string | null;
      Secured?: boolean;
      FileType?: Eclipse_SDK_DTO_ImageFileExtensionType;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/upload',
      path: {
        'orderId': orderId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Files did not pass antimalware scan.`,
        403: `User does not have sufficient permissions. For example, an internal user tries to access this endpoint.`,
        404: `Order was not found.`,
        422: `Validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * For a given orderId, retrieve the related customer uploaded documents. (Auth)
   * @returns Workspace_Common_Responses_CustomerUploadedDocumentList Documents response.
   * @throws ApiError
   */
  public getCustomerUploadedDocuments({
    orderId,
    paginateResponse = false,
    page,
    sortProperty,
    sortDirection,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Boolean value to get response in paginated form.
     */
    paginateResponse?: boolean,
    /**
     * Integer value for page number.
     */
    page?: number,
    /**
     * The property on which to sort.
     */
    sortProperty?: Workspace_Common_Models_FileDocumentSortPropertyType,
    /**
     * The direction in which to sort.
     */
    sortDirection?: Workspace_Common_Models_SortDirectionType,
  }): CancelablePromise<Workspace_Common_Responses_CustomerUploadedDocumentList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/customerDocuments',
      path: {
        'orderId': orderId,
      },
      query: {
        'paginateResponse': paginateResponse,
        'page': page,
        'sortProperty': sortProperty,
        'sortDirection': sortDirection,
      },
      errors: {
        400: `Invalid page number.`,
        403: `User does not have sufficient permissions to get list of customer documents.`,
        404: `Order was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Gets the customer uploaded document details. (Auth)
   * @returns Workspace_Common_Models_Dto_CustomerDocumentUploading_CustomerDocumentDto Details for the requested customer document.
   * @throws ApiError
   */
  public getCustomerDocumentDetails({
    orderId,
    customerDocumentId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Customer document identifier.
     */
    customerDocumentId: number,
  }): CancelablePromise<Workspace_Common_Models_Dto_CustomerDocumentUploading_CustomerDocumentDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/{customerDocumentId}',
      path: {
        'orderId': orderId,
        'customerDocumentId': customerDocumentId,
      },
      errors: {
        403: `Customer document access is restricted.`,
        404: `Customer document was not found.`,
        422: `Validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Cancel customer document submission. (Auth)
   * @returns void
   * @throws ApiError
   */
  public cancelCustomerDocumentSubmissionAsync({
    orderId,
    customerDocumentId,
  }: {
    /**
     * Order identifier to which customer document belongs.
     */
    orderId: number,
    /**
     * Customer document identifier.
     */
    customerDocumentId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/{customerDocumentId}/cancelled',
      path: {
        'orderId': orderId,
        'customerDocumentId': customerDocumentId,
      },
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User does not have sufficient permissions. For example, an internal user tries to access this endpoint.`,
        404: `Customer document does not exist.`,
        409: `Customer document concurrency update causing conflicts.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * To check if Customer Has access to CDU based on the ROle
   * The restricted Roles are - SearchVendor, Outside Title Company ,Outside Title CompanyContact and Search Vendor Contact (Auth)
   * @returns boolean Success
   * @throws ApiError
   */
  public canAccessCustomerDocumentAsync({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/customerDocuments/CanAccessCustomerDocumentUpload',
      path: {
        'orderId': orderId,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        422: `Client Error`,
        500: `Server Error`,
      },
    });
  }

}
