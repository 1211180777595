import { brickRed, havelockBlue, lightGray } from 'config/theme';
import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

interface IProgressBarProps extends ISVGProps {
  isError: boolean;
  progress: number; //number 0 - 100
  trailColor: string;
  trailWidth: number;
  strokeColor: string;
  strokeWidth: number;
  strokeWidthTrail: number;
  strokeLinecap: 'butt' | 'round' | 'square' | 'inherit';
  transition: string;
}

const defaultProps: IProgressBarProps = {
  isError: false,
  progress: 0,
  trailColor: lightGray,
  trailWidth: 1,
  strokeColor: havelockBlue,
  strokeWidth: 1,
  strokeLinecap: 'square',
  strokeWidthTrail: 1,
  transition: 'stroke-dashoffset 0.9s ease 0s, stroke 0.9s ease',
};

export default ({
  isError,
  progress,
  trailColor,
  trailWidth,
  strokeWidth,
  strokeLinecap,
  transition,
}: IProgressBarProps = defaultProps) => {
  const center = strokeWidth / 2;
  const right = 100 - center;
  const path =
    strokeLinecap !== 'butt'
      ? `M ${center},${center} L ${right},${center}`
      : `M ${0},${center} L ${100},${center}`;

  return (
    <svg
      className="progress-line"
      viewBox={`0 0 100 ${strokeWidth}`}
      preserveAspectRatio="none"
      height={'5px'}
      width={'100%'}
    >
      <path
        className="progress-line-trail"
        d={path}
        strokeLinecap={strokeLinecap}
        stroke={trailColor}
        strokeWidth={trailWidth}
        fillOpacity="0"
      />

      <path
        className="progress-line-path"
        d={path}
        strokeLinecap={strokeLinecap}
        stroke={isError ? brickRed : havelockBlue}
        strokeWidth={strokeWidth}
        fillOpacity="0"
        style={{
          strokeDasharray: '100px, 100px',
          strokeDashoffset: `${100 - progress}px`,
          transition: transition,
        }}
      />
    </svg>
  );
};
