import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M2.46189 0.4375L8.64817 6.62348L14.8345 0.4375L16.7348 2.35125L8.64817 10.4375L0.561523 2.35125L2.46189 0.4375Z"
      fill={props.fill || blue}
    />
  </svg>
);
