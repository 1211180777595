import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default ({ style }: ISVGProps) => (
  <svg
    width="190"
    height="140"
    viewBox="0 0 190 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect y="108" width="6" height="2" rx="1" fill="#9BABC2" />
    <rect x="184" y="108" width="6" height="2" rx="1" fill="#9BABC2" />
    <path
      d="M8 109C8 108.448 8.44772 108 9 108H181C181.552 108 182 108.448 182 109C182 109.552 181.552 110 181 110H9C8.44772 110 8 109.552 8 109Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 134.002C160 137.315 132.018 140 97.5013 140C62.9822 140 35 137.315 35 134.002C35 130.688 62.9822 128 97.5013 128C132.018 128 160 130.688 160 134.002Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.217 126.749C166.217 128.523 164.787 129.964 163.022 129.964H81.8292C80.0639 129.964 78.6345 128.523 78.6345 126.749V4.21771C78.6345 2.44111 80.0639 1 81.8292 1H148.92C149.766 1 150.579 1.34058 151.177 1.94549L165.28 16.1355C165.881 16.7404 166.217 17.5588 166.217 18.4102V126.749Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.6339 13.8617C78.6339 12.0876 80.0634 10.6465 81.8287 10.6465H159.827L151.179 1.94392C150.578 1.34156 149.768 1.00098 148.919 1.00098H81.8287C80.0634 1.00098 78.6339 2.44209 78.6339 4.21615V13.8617Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166 126.784C166 128.558 164.58 130 162.826 130H82.1735C80.4199 130 79 128.558 79 126.784V4.21869C79 2.44155 80.4199 1 82.1735 1H148.818C149.658 1 150.466 1.34068 151.061 1.94578L165.069 16.14C165.666 16.7451 166 17.5638 166 18.4155V126.784Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 80.5C160 91.2674 151.27 100 140.503 100C129.73 100 121 91.2674 121 80.5C121 69.7298 129.73 61 140.503 61C151.27 61 160 69.7298 160 80.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.502 69.125C149.876 69.125 157.707 75.7437 159.575 84.5639C159.855 83.2526 159.999 81.8933 159.999 80.5C159.999 69.7298 151.272 61 140.502 61C129.729 61 121.002 69.7298 121.002 80.5C121.002 81.8933 121.149 83.2526 121.426 84.5639C123.294 75.7437 131.125 69.125 140.502 69.125Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 80.5C160 91.2674 151.27 100 140.503 100C129.73 100 121 91.2674 121 80.5C121 69.7298 129.73 61 140.503 61C151.27 61 160 69.7298 160 80.5Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.376 86.0478C131.888 85.5599 131.888 84.7704 132.376 84.2825L135.913 80.7519C136.157 80.5082 136.157 80.1125 135.913 79.8682L132.376 76.3376C131.888 75.8503 131.888 75.0602 132.376 74.5723L134.587 72.3662C135.075 71.8782 135.866 71.8782 136.355 72.3662L139.892 75.8968C140.136 76.1404 140.532 76.1404 140.776 75.8968L144.313 72.3662C144.801 71.8782 145.593 71.8782 146.081 72.3662L148.292 74.5723C148.781 75.0602 148.781 75.8503 148.292 76.3376L144.755 79.8682C144.511 80.1125 144.511 80.5082 144.755 80.7519L148.292 84.2825C148.781 84.7704 148.781 85.5599 148.292 86.0478L146.081 88.2539C145.593 88.7419 144.801 88.7419 144.313 88.2539L140.776 84.7233C140.532 84.4797 140.136 84.4797 139.892 84.7233L136.355 88.2539C135.866 88.7419 135.075 88.7419 134.587 88.2539L132.376 86.0478Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.355 72.3659L139.892 75.8966C140.136 76.1402 140.532 76.1402 140.776 75.8966L144.313 72.3659C144.802 71.878 145.593 71.878 146.081 72.3659L148.292 74.5721C148.781 75.06 148.781 75.8501 148.292 76.3374L144.755 79.868C144.511 80.1123 144.511 80.508 144.755 80.7517L148.292 84.2823C148.781 84.7695 148.781 85.5597 148.292 86.0476L146.913 87.4239L133.208 73.7423L134.587 72.3659C135.075 71.878 135.867 71.878 136.355 72.3659Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.376 86.0478C131.888 85.5599 131.888 84.7704 132.376 84.2825L135.913 80.7519C136.157 80.5082 136.157 80.1125 135.913 79.8682L132.376 76.3376C131.888 75.8503 131.888 75.0602 132.376 74.5723L134.587 72.3662C135.075 71.8782 135.866 71.8782 136.355 72.3662L139.892 75.8968C140.136 76.1404 140.532 76.1404 140.776 75.8968L144.313 72.3662C144.801 71.8782 145.593 71.8782 146.081 72.3662L148.292 74.5723C148.781 75.0602 148.781 75.8503 148.292 76.3376L144.755 79.8682C144.511 80.1125 144.511 80.5082 144.755 80.7519L148.292 84.2825C148.781 84.7704 148.781 85.5599 148.292 86.0478L146.081 88.2539C145.593 88.7419 144.801 88.7419 144.313 88.2539L140.776 84.7233C140.532 84.4797 140.136 84.4797 139.892 84.7233L136.355 88.2539C135.866 88.7419 135.075 88.7419 134.587 88.2539L132.376 86.0478Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101 37C101 36.4477 101.448 36 102 36H140C140.552 36 141 36.4477 141 37C141 37.5523 140.552 38 140 38H102C101.448 38 101 37.5523 101 37Z"
      fill="#9BABC2"
    />
    <path
      d="M101 25C101 24.4477 101.448 24 102 24H128C128.552 24 129 24.4477 129 25C129 25.5523 128.552 26 128 26H102C101.448 26 101 25.5523 101 25Z"
      fill="#9BABC2"
    />
    <path
      d="M101 49C101 48.4477 101.448 48 102 48H121C121.552 48 122 48.4477 122 49C122 49.5523 121.552 50 121 50H102C101.448 50 101 49.5523 101 49Z"
      fill="#9BABC2"
    />
    <path
      d="M101 61C101 60.4477 101.448 60 102 60H115C115.552 60 116 60.4477 116 61C116 61.5523 115.552 62 115 62H102C101.448 62 101 61.5523 101 61Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.242 14.1548C150.242 15.7687 151.543 17.0777 153.147 17.0777H165.906C165.749 16.7346 165.552 16.4092 165.282 16.1347L151.179 1.94478C150.909 1.67282 150.583 1.47203 150.242 1.31445V14.1548Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 14.2186C150 15.7544 151.246 17 152.781 17H165C164.85 16.6735 164.661 16.3639 164.403 16.1027L150.897 2.59981C150.639 2.34102 150.327 2.14995 150 2V14.2186Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.964 120.318H24.9773C23.5832 120.318 22.4518 119.179 22.4518 117.776V107.978C22.4518 102.363 26.975 97.8114 32.5539 97.8114H111.388C116.967 97.8114 121.49 102.363 121.49 107.978V117.776C121.49 119.179 120.359 120.318 118.964 120.318Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.49 109.503C121.49 103.047 116.287 97.8116 109.873 97.8116H34.0692C27.6543 97.8116 22.4518 103.047 22.4518 109.503V117.392C22.4518 117.601 22.5275 117.784 22.5705 117.977C23.1589 112.08 28.0509 107.457 34.0692 107.457H109.873C115.891 107.457 120.783 112.08 121.374 117.977C121.414 117.784 121.49 117.601 121.49 117.392V109.503Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.9295 56.0134H57.0123C56.2925 56.0134 55.6055 55.8634 54.9691 55.6219C56.5324 58.9032 60.7904 70.3558 60.7904 97.8106H83.1514C83.1514 70.3558 87.4094 58.9032 88.9752 55.6219C88.3363 55.8634 87.6518 56.0134 86.9295 56.0134Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.0129 56.0134C56.2906 56.0134 55.6062 55.8634 54.9697 55.6219C55.394 56.5141 56.0178 58.034 56.6997 60.2859H87.2408C87.9252 58.034 88.549 56.5141 88.9733 55.6219C88.3343 55.8634 87.6499 56.0134 86.9302 56.0134H57.0129Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.165 127.965C113.771 129.16 112.66 129.965 111.41 129.965H32.5328C31.2827 129.965 30.1715 129.16 29.7775 127.965L27.2444 120.317H116.698L114.165 127.965Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.475 120H24.5245C23.131 120 22 118.887 22 117.516V107.938C22 102.45 26.5215 98 32.0982 98H110.902C116.479 98 121 102.45 121 107.938V117.516C121 118.887 119.869 120 118.475 120Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.9547 56.3897H57.0428C56.3231 56.3897 55.6363 56.2404 55 56C56.563 59.2666 60.8203 70.668 60.8203 98H83.1772C83.1772 70.668 87.4345 59.2666 89 56C88.3612 56.2404 87.6769 56.3897 86.9547 56.3897Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.451 127.927C114.055 129.165 112.937 130 111.679 130H32.3207C31.063 130 29.9449 129.165 29.5486 127.927L27 120H117L114.451 127.927Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M61 87H83V89H61V87Z" fill="#9BABC2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.6867 56.0135H56.2567C53.466 56.0135 51.2056 53.7362 51.2056 50.9302V35.3754C51.2056 32.5695 53.466 30.2921 56.2567 30.2921H87.6867C90.4749 30.2921 92.7378 32.5695 92.7378 35.3754V50.9302C92.7378 53.7362 90.4749 56.0135 87.6867 56.0135Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.9293 30.2932H57.012C53.8046 30.2932 51.2033 32.911 51.2033 36.1389V44.1756C51.2033 40.9477 53.8046 38.3324 57.012 38.3324H86.9293C90.1367 38.3324 92.738 40.9477 92.738 44.1756V36.1389C92.738 32.911 90.1367 30.2932 86.9293 30.2932Z"
      fill="#DAEDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.8921 57H56.1079C53.2858 57 51 54.6095 51 51.664V35.336C51 32.3905 53.2858 30 56.1079 30H87.8921C90.7116 30 93 32.3905 93 35.336V51.664C93 54.6095 90.7116 57 87.8921 57Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
