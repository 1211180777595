/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccessControlService } from './services/AccessControlService';
import { AdjustmentAllocationsService } from './services/AdjustmentAllocationsService';
import { AdjustmentsService } from './services/AdjustmentsService';
import { AffiliateCompanyService } from './services/AffiliateCompanyService';
import { AffiliateViewService } from './services/AffiliateViewService';
import { AttorneyBuyerService } from './services/AttorneyBuyerService';
import { AttorneySellerService } from './services/AttorneySellerService';
import { BankService } from './services/BankService';
import { BillingService } from './services/BillingService';
import { BulkInvitationService } from './services/BulkInvitationService';
import { ChargeAllocationsService } from './services/ChargeAllocationsService';
import { ChargesListService } from './services/ChargesListService';
import { ChecklistDocumentService } from './services/ChecklistDocumentService';
import { ChecklistsTemplatesService } from './services/ChecklistsTemplatesService';
import { ClosingGroupsService } from './services/ClosingGroupsService';
import { ComponentsService } from './services/ComponentsService';
import { CurrentUserService } from './services/CurrentUserService';
import { CustomerDocumentsService } from './services/CustomerDocumentsService';
import { DocumentsService } from './services/DocumentsService';
import { EmailOrderAssociationService } from './services/EmailOrderAssociationService';
import { EmployeeChecklistService } from './services/EmployeeChecklistService';
import { EscrowService } from './services/EscrowService';
import { EventsService } from './services/EventsService';
import { FarmsService } from './services/FarmsService';
import { FeaturesService } from './services/FeaturesService';
import { FileNotesService } from './services/FileNotesService';
import { FilesService } from './services/FilesService';
import { FractionalSettlementStatementService } from './services/FractionalSettlementStatementService';
import { GroupsService } from './services/GroupsService';
import { HoldFundAllocationsService } from './services/HoldFundAllocationsService';
import { HoldFundsService } from './services/HoldFundsService';
import { HoldFundsDisbursementService } from './services/HoldFundsDisbursementService';
import { HomeownerAssociationService } from './services/HomeownerAssociationService';
import { IbaService } from './services/IbaService';
import { LoanAllocationService } from './services/LoanAllocationService';
import { LoansService } from './services/LoansService';
import { LookupsService } from './services/LookupsService';
import { MiscellaneousChargesService } from './services/MiscellaneousChargesService';
import { MiscellaneousDisbursementService } from './services/MiscellaneousDisbursementService';
import { NotificationsService } from './services/NotificationsService';
import { OfficesService } from './services/OfficesService';
import { Order1099SService } from './services/Order1099SService';
import { Order1099SCustomerESignService } from './services/Order1099SCustomerESignService';
import { OrderBulkUpdatesService } from './services/OrderBulkUpdatesService';
import { OrderChecklistService } from './services/OrderChecklistService';
import { OrderChecklistTaskDocumentsService } from './services/OrderChecklistTaskDocumentsService';
import { OrderCoInsuranceService } from './services/OrderCoInsuranceService';
import { OrderDepositsService } from './services/OrderDepositsService';
import { OrderDisbursementsService } from './services/OrderDisbursementsService';
import { OrderDisbursementSplitsService } from './services/OrderDisbursementSplitsService';
import { OrderDocumentsService } from './services/OrderDocumentsService';
import { OrderEmployeesService } from './services/OrderEmployeesService';
import { OrderInvoicesService } from './services/OrderInvoicesService';
import { OrderMilestonesService } from './services/OrderMilestonesService';
import { OrderOrganizationsService } from './services/OrderOrganizationsService';
import { OrderOverdraftsService } from './services/OrderOverdraftsService';
import { OrderPackagesService } from './services/OrderPackagesService';
import { OrderPartiesService } from './services/OrderPartiesService';
import { OrderPropertiesService } from './services/OrderPropertiesService';
import { OrderRecordingTaxFeePayeesService } from './services/OrderRecordingTaxFeePayeesService';
import { OrdersService } from './services/OrdersService';
import { OrderServicesService } from './services/OrderServicesService';
import { OrderTaxesAndFeesService } from './services/OrderTaxesAndFeesService';
import { OrderTaxesAndFeesPayeesService } from './services/OrderTaxesAndFeesPayeesService';
import { OrderTaxesAndFeesSplitFeesService } from './services/OrderTaxesAndFeesSplitFeesService';
import { OrderWireInstructionsService } from './services/OrderWireInstructionsService';
import { OrganizationsService } from './services/OrganizationsService';
import { OutsideEscrowCompanyService } from './services/OutsideEscrowCompanyService';
import { OutsideTitleCompanyService } from './services/OutsideTitleCompanyService';
import { PartiesService } from './services/PartiesService';
import { PartyAllocationService } from './services/PartyAllocationService';
import { PersistentClosingGroupsService } from './services/PersistentClosingGroupsService';
import { PricingPoliciesService } from './services/PricingPoliciesService';
import { PropertiesService } from './services/PropertiesService';
import { PropertyTaxCheckService } from './services/PropertyTaxCheckService';
import { ProrationAllocationsService } from './services/ProrationAllocationsService';
import { ProrationsService } from './services/ProrationsService';
import { PublicRecordsService } from './services/PublicRecordsService';
import { RealEstateBrokerService } from './services/RealEstateBrokerService';
import { RegionsService } from './services/RegionsService';
import { ReportsService } from './services/ReportsService';
import { SearchService } from './services/SearchService';
import { SettlementStatementsService } from './services/SettlementStatementsService';
import { SettlementStatementsCommentsService } from './services/SettlementStatementsCommentsService';
import { SettlementStatementsExternalService } from './services/SettlementStatementsExternalService';
import { SupportRequestsService } from './services/SupportRequestsService';
import { TaskResponsiblePartyOptionsService } from './services/TaskResponsiblePartyOptionsService';
import { TaxesAndFeesService } from './services/TaxesAndFeesService';
import { TeamOrderAssignmentsService } from './services/TeamOrderAssignmentsService';
import { TemplateInstanceService } from './services/TemplateInstanceService';
import { TemplatesService } from './services/TemplatesService';
import { TrustExtractService } from './services/TrustExtractService';
import { UserDevicesService } from './services/UserDevicesService';
import { UsersService } from './services/UsersService';
import { UtilityService } from './services/UtilityService';
import { WireInstructionFilesService } from './services/WireInstructionFilesService';
import { WireInstructionRequestsService } from './services/WireInstructionRequestsService';
import { WireInstructionsService } from './services/WireInstructionsService';
import { WireInstructionVerificationsService } from './services/WireInstructionVerificationsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class WorkspaceClient {

  public readonly accessControl: AccessControlService;
  public readonly adjustmentAllocations: AdjustmentAllocationsService;
  public readonly adjustments: AdjustmentsService;
  public readonly affiliateCompany: AffiliateCompanyService;
  public readonly affiliateView: AffiliateViewService;
  public readonly attorneyBuyer: AttorneyBuyerService;
  public readonly attorneySeller: AttorneySellerService;
  public readonly bank: BankService;
  public readonly billing: BillingService;
  public readonly bulkInvitation: BulkInvitationService;
  public readonly chargeAllocations: ChargeAllocationsService;
  public readonly chargesList: ChargesListService;
  public readonly checklistDocument: ChecklistDocumentService;
  public readonly checklistsTemplates: ChecklistsTemplatesService;
  public readonly closingGroups: ClosingGroupsService;
  public readonly components: ComponentsService;
  public readonly currentUser: CurrentUserService;
  public readonly customerDocuments: CustomerDocumentsService;
  public readonly documents: DocumentsService;
  public readonly emailOrderAssociation: EmailOrderAssociationService;
  public readonly employeeChecklist: EmployeeChecklistService;
  public readonly escrow: EscrowService;
  public readonly events: EventsService;
  public readonly farms: FarmsService;
  public readonly features: FeaturesService;
  public readonly fileNotes: FileNotesService;
  public readonly files: FilesService;
  public readonly fractionalSettlementStatement: FractionalSettlementStatementService;
  public readonly groups: GroupsService;
  public readonly holdFundAllocations: HoldFundAllocationsService;
  public readonly holdFunds: HoldFundsService;
  public readonly holdFundsDisbursement: HoldFundsDisbursementService;
  public readonly homeownerAssociation: HomeownerAssociationService;
  public readonly iba: IbaService;
  public readonly loanAllocation: LoanAllocationService;
  public readonly loans: LoansService;
  public readonly lookups: LookupsService;
  public readonly miscellaneousCharges: MiscellaneousChargesService;
  public readonly miscellaneousDisbursement: MiscellaneousDisbursementService;
  public readonly notifications: NotificationsService;
  public readonly offices: OfficesService;
  public readonly order1099S: Order1099SService;
  public readonly order1099SCustomerESign: Order1099SCustomerESignService;
  public readonly orderBulkUpdates: OrderBulkUpdatesService;
  public readonly orderChecklist: OrderChecklistService;
  public readonly orderChecklistTaskDocuments: OrderChecklistTaskDocumentsService;
  public readonly orderCoInsurance: OrderCoInsuranceService;
  public readonly orderDeposits: OrderDepositsService;
  public readonly orderDisbursements: OrderDisbursementsService;
  public readonly orderDisbursementSplits: OrderDisbursementSplitsService;
  public readonly orderDocuments: OrderDocumentsService;
  public readonly orderEmployees: OrderEmployeesService;
  public readonly orderInvoices: OrderInvoicesService;
  public readonly orderMilestones: OrderMilestonesService;
  public readonly orderOrganizations: OrderOrganizationsService;
  public readonly orderOverdrafts: OrderOverdraftsService;
  public readonly orderPackages: OrderPackagesService;
  public readonly orderParties: OrderPartiesService;
  public readonly orderProperties: OrderPropertiesService;
  public readonly orderRecordingTaxFeePayees: OrderRecordingTaxFeePayeesService;
  public readonly orders: OrdersService;
  public readonly orderServices: OrderServicesService;
  public readonly orderTaxesAndFees: OrderTaxesAndFeesService;
  public readonly orderTaxesAndFeesPayees: OrderTaxesAndFeesPayeesService;
  public readonly orderTaxesAndFeesSplitFees: OrderTaxesAndFeesSplitFeesService;
  public readonly orderWireInstructions: OrderWireInstructionsService;
  public readonly organizations: OrganizationsService;
  public readonly outsideEscrowCompany: OutsideEscrowCompanyService;
  public readonly outsideTitleCompany: OutsideTitleCompanyService;
  public readonly parties: PartiesService;
  public readonly partyAllocation: PartyAllocationService;
  public readonly persistentClosingGroups: PersistentClosingGroupsService;
  public readonly pricingPolicies: PricingPoliciesService;
  public readonly properties: PropertiesService;
  public readonly propertyTaxCheck: PropertyTaxCheckService;
  public readonly prorationAllocations: ProrationAllocationsService;
  public readonly prorations: ProrationsService;
  public readonly publicRecords: PublicRecordsService;
  public readonly realEstateBroker: RealEstateBrokerService;
  public readonly regions: RegionsService;
  public readonly reports: ReportsService;
  public readonly search: SearchService;
  public readonly settlementStatements: SettlementStatementsService;
  public readonly settlementStatementsComments: SettlementStatementsCommentsService;
  public readonly settlementStatementsExternal: SettlementStatementsExternalService;
  public readonly supportRequests: SupportRequestsService;
  public readonly taskResponsiblePartyOptions: TaskResponsiblePartyOptionsService;
  public readonly taxesAndFees: TaxesAndFeesService;
  public readonly teamOrderAssignments: TeamOrderAssignmentsService;
  public readonly templateInstance: TemplateInstanceService;
  public readonly templates: TemplatesService;
  public readonly trustExtract: TrustExtractService;
  public readonly userDevices: UserDevicesService;
  public readonly users: UsersService;
  public readonly utility: UtilityService;
  public readonly wireInstructionFiles: WireInstructionFilesService;
  public readonly wireInstructionRequests: WireInstructionRequestsService;
  public readonly wireInstructions: WireInstructionsService;
  public readonly wireInstructionVerifications: WireInstructionVerificationsService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.accessControl = new AccessControlService(this.request);
    this.adjustmentAllocations = new AdjustmentAllocationsService(this.request);
    this.adjustments = new AdjustmentsService(this.request);
    this.affiliateCompany = new AffiliateCompanyService(this.request);
    this.affiliateView = new AffiliateViewService(this.request);
    this.attorneyBuyer = new AttorneyBuyerService(this.request);
    this.attorneySeller = new AttorneySellerService(this.request);
    this.bank = new BankService(this.request);
    this.billing = new BillingService(this.request);
    this.bulkInvitation = new BulkInvitationService(this.request);
    this.chargeAllocations = new ChargeAllocationsService(this.request);
    this.chargesList = new ChargesListService(this.request);
    this.checklistDocument = new ChecklistDocumentService(this.request);
    this.checklistsTemplates = new ChecklistsTemplatesService(this.request);
    this.closingGroups = new ClosingGroupsService(this.request);
    this.components = new ComponentsService(this.request);
    this.currentUser = new CurrentUserService(this.request);
    this.customerDocuments = new CustomerDocumentsService(this.request);
    this.documents = new DocumentsService(this.request);
    this.emailOrderAssociation = new EmailOrderAssociationService(this.request);
    this.employeeChecklist = new EmployeeChecklistService(this.request);
    this.escrow = new EscrowService(this.request);
    this.events = new EventsService(this.request);
    this.farms = new FarmsService(this.request);
    this.features = new FeaturesService(this.request);
    this.fileNotes = new FileNotesService(this.request);
    this.files = new FilesService(this.request);
    this.fractionalSettlementStatement = new FractionalSettlementStatementService(this.request);
    this.groups = new GroupsService(this.request);
    this.holdFundAllocations = new HoldFundAllocationsService(this.request);
    this.holdFunds = new HoldFundsService(this.request);
    this.holdFundsDisbursement = new HoldFundsDisbursementService(this.request);
    this.homeownerAssociation = new HomeownerAssociationService(this.request);
    this.iba = new IbaService(this.request);
    this.loanAllocation = new LoanAllocationService(this.request);
    this.loans = new LoansService(this.request);
    this.lookups = new LookupsService(this.request);
    this.miscellaneousCharges = new MiscellaneousChargesService(this.request);
    this.miscellaneousDisbursement = new MiscellaneousDisbursementService(this.request);
    this.notifications = new NotificationsService(this.request);
    this.offices = new OfficesService(this.request);
    this.order1099S = new Order1099SService(this.request);
    this.order1099SCustomerESign = new Order1099SCustomerESignService(this.request);
    this.orderBulkUpdates = new OrderBulkUpdatesService(this.request);
    this.orderChecklist = new OrderChecklistService(this.request);
    this.orderChecklistTaskDocuments = new OrderChecklistTaskDocumentsService(this.request);
    this.orderCoInsurance = new OrderCoInsuranceService(this.request);
    this.orderDeposits = new OrderDepositsService(this.request);
    this.orderDisbursements = new OrderDisbursementsService(this.request);
    this.orderDisbursementSplits = new OrderDisbursementSplitsService(this.request);
    this.orderDocuments = new OrderDocumentsService(this.request);
    this.orderEmployees = new OrderEmployeesService(this.request);
    this.orderInvoices = new OrderInvoicesService(this.request);
    this.orderMilestones = new OrderMilestonesService(this.request);
    this.orderOrganizations = new OrderOrganizationsService(this.request);
    this.orderOverdrafts = new OrderOverdraftsService(this.request);
    this.orderPackages = new OrderPackagesService(this.request);
    this.orderParties = new OrderPartiesService(this.request);
    this.orderProperties = new OrderPropertiesService(this.request);
    this.orderRecordingTaxFeePayees = new OrderRecordingTaxFeePayeesService(this.request);
    this.orders = new OrdersService(this.request);
    this.orderServices = new OrderServicesService(this.request);
    this.orderTaxesAndFees = new OrderTaxesAndFeesService(this.request);
    this.orderTaxesAndFeesPayees = new OrderTaxesAndFeesPayeesService(this.request);
    this.orderTaxesAndFeesSplitFees = new OrderTaxesAndFeesSplitFeesService(this.request);
    this.orderWireInstructions = new OrderWireInstructionsService(this.request);
    this.organizations = new OrganizationsService(this.request);
    this.outsideEscrowCompany = new OutsideEscrowCompanyService(this.request);
    this.outsideTitleCompany = new OutsideTitleCompanyService(this.request);
    this.parties = new PartiesService(this.request);
    this.partyAllocation = new PartyAllocationService(this.request);
    this.persistentClosingGroups = new PersistentClosingGroupsService(this.request);
    this.pricingPolicies = new PricingPoliciesService(this.request);
    this.properties = new PropertiesService(this.request);
    this.propertyTaxCheck = new PropertyTaxCheckService(this.request);
    this.prorationAllocations = new ProrationAllocationsService(this.request);
    this.prorations = new ProrationsService(this.request);
    this.publicRecords = new PublicRecordsService(this.request);
    this.realEstateBroker = new RealEstateBrokerService(this.request);
    this.regions = new RegionsService(this.request);
    this.reports = new ReportsService(this.request);
    this.search = new SearchService(this.request);
    this.settlementStatements = new SettlementStatementsService(this.request);
    this.settlementStatementsComments = new SettlementStatementsCommentsService(this.request);
    this.settlementStatementsExternal = new SettlementStatementsExternalService(this.request);
    this.supportRequests = new SupportRequestsService(this.request);
    this.taskResponsiblePartyOptions = new TaskResponsiblePartyOptionsService(this.request);
    this.taxesAndFees = new TaxesAndFeesService(this.request);
    this.teamOrderAssignments = new TeamOrderAssignmentsService(this.request);
    this.templateInstance = new TemplateInstanceService(this.request);
    this.templates = new TemplatesService(this.request);
    this.trustExtract = new TrustExtractService(this.request);
    this.userDevices = new UserDevicesService(this.request);
    this.users = new UsersService(this.request);
    this.utility = new UtilityService(this.request);
    this.wireInstructionFiles = new WireInstructionFilesService(this.request);
    this.wireInstructionRequests = new WireInstructionRequestsService(this.request);
    this.wireInstructions = new WireInstructionsService(this.request);
    this.wireInstructionVerifications = new WireInstructionVerificationsService(this.request);
  }
}

