import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Modal, { ModalActions } from 'components/ui/Modal';
import { linkWater, blue, extremeBlack, athensGray } from 'config/theme';
import Button, { ButtonSize } from 'components/ui/Button';
import { pluralize } from 'utils/text';

const THRESHOLD = 120; // in seconds

interface IModalContentsProps {
  completed: boolean;
  total: number; // total remaining time in seconds
  isOpen: boolean;
  loading: boolean;
  manuallyGetSession: (
    refresh?: boolean,
    broadcastUpdate?: boolean
  ) => Promise<void>;
  minutes: number;
  onSignOutClick: () => void;
  setModalState: (isVisible: boolean) => void;
  seconds: number;
}

const ModalContents = ({
  completed,
  isOpen,
  loading,
  total,
  minutes,
  seconds,
  manuallyGetSession,
  onSignOutClick,
  setModalState,
}: IModalContentsProps) => {
  useEffect(() => {
    if (completed) {
      onSignOutClick();
    }
  }, [completed]);

  const isIdle = useIdle(THRESHOLD / 2);

  const mins = minutes < 1 ? '' : `${minutes} ${pluralize(minutes, 'min')}`;
  const timer = `${mins} ${seconds} sec`;
  const thresholdPassed = THRESHOLD > total;

  const handleClose = () =>
    manuallyGetSession().then(() => setModalState(false));

  useEffect(() => {
    if (!thresholdPassed) return setModalState(false);
    if (isOpen) return;

    if (!isIdle) {
      manuallyGetSession();
    } else {
      setModalState(true);
    }
  }, [thresholdPassed, isIdle, isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <Helmet defer={false}>
        <title>{timer} until sign out</title>
      </Helmet>

      <Modal
        closeOnEsc
        closeOnOutsideClick
        maxWidth="486px"
        onClose={handleClose}
        open
        padding="0"
        showCloseButton
      >
        <Title>Session Timeout Warning</Title>
        <Contents>
          <p>
            You have been inactive for a while. For your security,{' '}
            <b>you will be automatically signed out</b> of ClarityFirst in:
          </p>
          <Countdown>{timer}</Countdown>
        </Contents>
        <ButtonsContainer>
          <Button
            disabled={loading}
            onClick={handleClose}
            secondary
            size={ButtonSize.LG}
            style={{ marginRight: 12 }}
            type="button"
          >
            Stay signed in
          </Button>
          <Button
            disabled={loading}
            onClick={onSignOutClick}
            size={ButtonSize.LG}
            type="button"
          >
            Sign out now
          </Button>
        </ButtonsContainer>
      </Modal>
    </>
  );
};

export default ModalContents;

// t: idle duration in seconds
function useIdle(t: number) {
  const [idle, setIdle] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleInteraction = () => {
      setIdle(false);

      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIdle(true);
      }, t * 1000);
    };

    document.addEventListener('mousemove', handleInteraction, false);
    document.addEventListener('keydown', handleInteraction, false);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      document.removeEventListener('mousemove', handleInteraction, false);
      document.removeEventListener('keydown', handleInteraction, false);
    };
  }, [t]);

  return idle;
}

const Title = styled.h1`
  border-bottom: 1px solid ${linkWater};
  color: ${blue};
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding: 16px 24px;
`;

const Contents = styled.div`
  color: ${extremeBlack};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  padding: 44px 39px 48px;
  letter-spacing: 0.005em;
`;

const ButtonsContainer = styled(ModalActions)`
  border-top: 1px solid ${athensGray};
  padding: 16px 24px;
`;

const Countdown = styled.p`
  color: ${blue};
  font-size: 32px;
  font-weight: bold;
  margin: 30px 0px 0px;
`;
Countdown.displayName = 'Countdown';
