/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_FileNotes_CreateNoteViewModel } from '../models/Workspace_API_ViewModels_FileNotes_CreateNoteViewModel';
import type { Workspace_API_ViewModels_FileNotes_FileNotesViewModel } from '../models/Workspace_API_ViewModels_FileNotes_FileNotesViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FileNotesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Given an order id and note id, if the order id has any file notes this method will return those file notes otherwise empty result (Auth)
   * @returns Workspace_API_ViewModels_FileNotes_FileNotesViewModel Order has file notes
   * @throws ApiError
   */
  public getFileNotesAsync({
    orderId,
    noteTypeId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * The default id is zero for which it will return all the notes or for specific note id it will return that specific category note
     */
    noteTypeId?: number,
  }): CancelablePromise<Workspace_API_ViewModels_FileNotes_FileNotesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/file-notes',
      path: {
        'orderId': orderId,
      },
      query: {
        'noteTypeId': noteTypeId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id and model the note will be added to order (Auth)
   * @returns void
   * @throws ApiError
   */
  public addFileNotesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * The model contain note type id and note which you want to add to order
     */
    requestBody: Workspace_API_ViewModels_FileNotes_CreateNoteViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/file-notes',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Model validation failed or note get added`,
      },
    });
  }

  /**
   * Given an order id, file note id and model the comment will be added to the existing note (Auth)
   * @returns void
   * @throws ApiError
   */
  public appendFileNotesAsync({
    orderId,
    fileNoteId,
    requestBody,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * The note Id for which comment will be added
     */
    fileNoteId: number,
    /**
     * The model contain note type id and note which you want to comment to the existing note
     */
    requestBody: Workspace_API_ViewModels_FileNotes_CreateNoteViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/file-notes/{fileNoteId}/comment',
      path: {
        'orderId': orderId,
        'fileNoteId': fileNoteId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Model validation failed or commomet not get added`,
      },
    });
  }

}
