import { GroupIcon, ShieldIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import { white } from 'config/colors';
import { CardsContent, CardsSection } from './Cards.styles';
import Card, { ICard } from './Card';
import React, { FunctionComponent } from 'react';
import SearchLarge from 'icons/SearchLarge';

const cards: ICard[] = [
  {
    icon: <SearchLarge fill={white} />,
    text:
      'Live view of your deals — milestones, documents, and service team — anytime, anywhere, on any device.',
    title: `Complete Transparency`,
    titleEmphasizeIndex: 2,
  },
  {
    icon: <GroupIcon stroke={white} />,
    text:
      'Complete transactions faster and more accurately with technology that connects everyone in real-time.',
    title: 'Increase Efficiency',
    titleEmphasizeIndex: 2,
  },
  {
    icon: <ShieldIcon fill={white} />,
    text:
      'Secure digital platform with multifactor authentication, encryption technology, and state-of-the-art wire verification.',
    title: 'Trusted Security',
    titleEmphasizeIndex: 2,
  },
];

const LandingPageCards: FunctionComponent = () => {
  return (
    <CardsSection>
      <CardsContent gap="large">
        {cards.map((card: ICard) => (
          <Card key={card.title} card={card} />
        ))}
      </CardsContent>
    </CardsSection>
  );
};

export default LandingPageCards;
