import { OrderDocumentsService } from 'services/__generated__';
import { UnboxPromise } from 'utils/types';

export type ApiRequestStatusUnion = 'loading' | 'success' | 'error' | 'idle';

export enum DocumentTypeKeys {
  CREATE_START = 'document/CREATE_START',
  CREATE_ERROR = 'document/CREATE_ERROR',
  CREATE_SUCCESS = 'document/CREATE_SUCCESS',
  CREATE_RESET = 'document/CREATE_RESET',
  SINGLE_DOWNLOAD_START = 'document/SINGLE_DOWNLOAD_START',
  SINGLE_DOWNLOAD_ERROR = 'document/SINGLE_DOWNLOAD_ERROR',
  SINGLE_DOWNLOAD_SUCCESS = 'document/SINGLE_DOWNLOAD_SUCCESS',
  SINGLE_DOWNLOAD_CLEAR = 'document/SINGLE_DOWNLOAD_CLEAR',
  DOCUMENT_COPY_START = 'document/DOCUMENT_COPY_START',
  DOCUMENT_COPY_ERROR = 'document/DOCUMENT_COPY_ERROR',
  DOCUMENT_COPY_SUCCESS = 'document/DOCUMENT_COPY_SUCCESS',
  DOCUMENT_COPY_CLEAR = 'document/DOCUMENT_COPY_CLEAR',
}

export type CreateStateUnion = 'loading' | 'error' | 'completed';

export interface ICreateDocumentState {
  name: string;
  status: CreateStateUnion;
  type: string;
  templateId: string;
  documentId?: string;
}

export type CreateDocumentStateMap = Record<string, ICreateDocumentState>;

export interface IDocumentState {
  createUi: {
    fileId?: string;
    trackDocumentCreate: CreateDocumentStateMap;
    status: ApiRequestStatusUnion;
  };
  downloadUi: Record<string, IDownloadDocumentState>;
  copyUi: Record<
    string,
    {
      fileId?: string;
      sourceFileId?: string;
      status: ApiRequestStatusUnion;
      documents: ICopyDocumentState[];
      errorMessages?: string;
    }
  >;
}

export interface IDocumentCreateStartAction {
  type: DocumentTypeKeys.CREATE_START;
  trackDocumentCreate: CreateDocumentStateMap;
  fileId: string;
}

export interface IDocumentCreateErrorAction {
  type: DocumentTypeKeys.CREATE_ERROR;
}

export interface IDocumentCreateSuccessAction {
  type: DocumentTypeKeys.CREATE_SUCCESS;
  trackDocumentUpdate: Array<
    Pick<ICreateDocumentState, 'status' | 'documentId' | 'name'>
  >;
}

export interface IDocumentCreateResetAction {
  type: DocumentTypeKeys.CREATE_RESET;
}

export type DocumentCreateActions =
  | IDocumentCreateStartAction
  | IDocumentCreateErrorAction
  | IDocumentCreateSuccessAction
  | IDocumentCreateResetAction;

export type DownloadStateUnion = 'loading' | 'error' | 'success';
export interface IDownloadDocumentState {
  name: string;
  status: DownloadStateUnion;
  extension: string;
  size: number;
  documentId: string;
  id: string;
}

export interface ICopyDocumentState {
  extension: string;
  name: string;
  sourceId: string;
  newDocumentId?: string | null;
  status?: ApiRequestStatusUnion;
}

export interface ISingleDocumentDownloadStartAction {
  type: DocumentTypeKeys.SINGLE_DOWNLOAD_START;
  document: Omit<IDownloadDocumentState, 'status'>;
}

export interface ISingleDocumentDownloadErrorAction {
  type: DocumentTypeKeys.SINGLE_DOWNLOAD_ERROR;
  id: string;
}

export interface ISingleDocumentDownloadSuccessAction {
  type: DocumentTypeKeys.SINGLE_DOWNLOAD_SUCCESS;
  id: string;
}

export interface ISingleDocumentDownloadClearAction {
  type: DocumentTypeKeys.SINGLE_DOWNLOAD_CLEAR;
  id: string;
}

export type SingleDocumentDownloadActions =
  | ISingleDocumentDownloadStartAction
  | ISingleDocumentDownloadErrorAction
  | ISingleDocumentDownloadSuccessAction
  | ISingleDocumentDownloadClearAction;

//
export interface IDocumentCopyStartAction {
  type: DocumentTypeKeys.DOCUMENT_COPY_START;
  input: {
    documents: Array<Omit<ICopyDocumentState, 'status'>>;
    fileId: string;
    sourceFileId: string;
  };
  requestId: string;
}

export interface IDocumentCopyErrorAction {
  type: DocumentTypeKeys.DOCUMENT_COPY_ERROR;
  requestId: string;
  errorMessages?: string;
}

export interface IDocumentCopySuccessAction {
  type: DocumentTypeKeys.DOCUMENT_COPY_SUCCESS;
  requestId: string;
  copyResult: DocumentCopyResult;
}

export interface IDocumentCopyClearAction {
  type: DocumentTypeKeys.DOCUMENT_COPY_CLEAR;
  requestId: string;
}

export type DocumentCopyActions =
  | IDocumentCopyStartAction
  | IDocumentCopyErrorAction
  | IDocumentCopySuccessAction
  | IDocumentCopyClearAction;

export type DocumentCopyResult = UnboxPromise<
  ReturnType<OrderDocumentsService['copyDocumentAsync']>
>;
