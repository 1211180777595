import React from 'react';

import styled from 'styled-components';
import CloseIcon from '../svg/legacyIcons_DO_NOT_USE/Close';
import { focusShadow, blue, white } from '../../config/theme';
import { ICloseContainerProps, ICloseToastButton } from './types';

export const CloseToastButton = ({ closeToast, type }: ICloseToastButton) => (
  <CloseContainer
    onClick={closeToast}
    tabIndex={1}
    isWarning={type === 'warning'}
    id="close-toast-button-icon"
  >
    <CloseIcon />
  </CloseContainer>
);

const CloseContainer = styled.span<ICloseContainerProps>`
  height: 32px;
  width: 32px;
  margin-top: 8px;
  margin-right: 8px;
  margin-left: 15px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isWarning }) => (isWarning ? blue : white)};

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: ${focusShadow};
  }
`;
