/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_TaxesAndFees_FeeConfigurationsViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_FeeConfigurationsViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_FeeTemplatesViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_FeeTemplatesViewModel';
import type { Workspace_Common_Models_Enums_FeeType } from '../models/Workspace_Common_Models_Enums_FeeType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaxesAndFeesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Allows to get the fees filtered by a category. (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_FeeTemplatesViewModel The items was retrieved successfully
   * @throws ApiError
   */
  public getFeeTemplatesAsync({
    feeType,
  }: {
    /**
     * The fee type category to fetch the data.
     */
    feeType?: Workspace_Common_Models_Enums_FeeType,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_FeeTemplatesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/taxes-and-fees/{feeType}',
      query: {
        'feeType': feeType,
      },
      errors: {
        401: `Unauthenticated user`,
        404: `Fees Not Found or External user`,
      },
    });
  }

  /**
   * Returns the fee configuration for the requested fee ids (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_FeeConfigurationsViewModel Success
   * @throws ApiError
   */
  public getFeeConfiguration({
    feeIds,
  }: {
    /**
     * The
     */
    feeIds: Array<number>,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_FeeConfigurationsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/taxes-and-fees/config',
      query: {
        'feeIds': feeIds,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}
