/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Eclipse_SDK_DTO_ImageFileExtensionType } from '../models/Eclipse_SDK_DTO_ImageFileExtensionType';
import type { System_IO_MemoryStream } from '../models/System_IO_MemoryStream';
import type { Workspace_API_ViewModels_Order1099s_AddRecordDetailsViewModel } from '../models/Workspace_API_ViewModels_Order1099s_AddRecordDetailsViewModel';
import type { Workspace_API_ViewModels_Order1099s_GetRecordDocumentsViewModel } from '../models/Workspace_API_ViewModels_Order1099s_GetRecordDocumentsViewModel';
import type { Workspace_API_ViewModels_Order1099s_Order1099sRecordDocumentViewModel } from '../models/Workspace_API_ViewModels_Order1099s_Order1099sRecordDocumentViewModel';
import type { Workspace_API_ViewModels_Order1099s_Order1099sSummaryViewModel } from '../models/Workspace_API_ViewModels_Order1099s_Order1099sSummaryViewModel';
import type { Workspace_API_ViewModels_Order1099s_RecordExtractionDetailsViewModel } from '../models/Workspace_API_ViewModels_Order1099s_RecordExtractionDetailsViewModel';
import type { Workspace_API_ViewModels_Order1099s_RecordImageDocumentOptionsViewModel } from '../models/Workspace_API_ViewModels_Order1099s_RecordImageDocumentOptionsViewModel';
import type { Workspace_API_ViewModels_Order1099s_RequestDocumentationViewModel } from '../models/Workspace_API_ViewModels_Order1099s_RequestDocumentationViewModel';
import type { Workspace_API_ViewModels_Order1099s_Update1099sRecordsViewModel } from '../models/Workspace_API_ViewModels_Order1099s_Update1099sRecordsViewModel';
import type { Workspace_API_ViewModels_Order1099s_UpdatePropertyDescriptionViewModel } from '../models/Workspace_API_ViewModels_Order1099s_UpdatePropertyDescriptionViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class Order1099SService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Given an order id, this method will return 1099s Summary for the order (Auth)
   * @returns Workspace_API_ViewModels_Order1099s_Order1099sSummaryViewModel Order has 1099s data
   * @throws ApiError
   */
  public get1099SSummaryAsync({
    orderId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Order1099s_Order1099sSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/1099s',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id and record id, this method will return Extraction Details for a record (Auth)
   * @returns Workspace_API_ViewModels_Order1099s_RecordExtractionDetailsViewModel Successfully fetched seller or ad-hoc record
   * @throws ApiError
   */
  public get1099SRecordExtractionDetailsAsync({
    orderId,
    recordId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * The record Id
     */
    recordId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Order1099s_RecordExtractionDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id and record id, will delete the record from the 1099-S (Auth)
   * @returns any Record was deleted from the 1099-S
   * @throws ApiError
   */
  public delete1099SRecordAsync({
    orderId,
    recordId,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The record id to delete
     */
    recordId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given and order id and Workspace.API.ViewModels.Order1099s.UpdatePropertyDescriptionViewModel, will update the property description
   * type header information on the 1099-S (Auth)
   * @returns void
   * @throws ApiError
   */
  public update1099SHeaderAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * A view model containing the updated property description information
     */
    requestBody: Workspace_API_ViewModels_Order1099s_UpdatePropertyDescriptionViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/1099s/header',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given and order id and Workspace.API.ViewModels.Order1099s.Update1099sRecordsViewModel, will update the payee record
   * (Seller or Ad-hoc) information. (Auth)
   * @returns void
   * @throws ApiError
   */
  public update1099SRecordsAsync({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_ViewModels_Order1099s_Update1099sRecordsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/1099s/records',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        409: `An invalid record id or ready to extract is not correct.`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given an order id and list of record details, will add the records to the 1099-S (Auth)
   * @returns any Records were added to the 1099-S
   * @throws ApiError
   */
  public add1099SRecordDetailsAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * A view model containing a list of record details to be added to the 1099-S
     */
    requestBody: Workspace_API_ViewModels_Order1099s_AddRecordDetailsViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/1099s/records',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id and record id, will void the record from the 1099-S (Auth)
   * @returns any Record was voided from the 1099-S
   * @throws ApiError
   */
  public void1099SRecordAsync({
    orderId,
    recordId,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The record id to void
     */
    recordId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}/void',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given an order id and record id, will generate a memory stream of the preview document (Auth)
   * @returns System_IO_MemoryStream Successfully fetched the preview document
   * @throws ApiError
   */
  public fetch1099SPreviewDocumentAsync({
    orderId,
    recordId,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The record id
     */
    recordId: number,
  }): CancelablePromise<System_IO_MemoryStream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/1099s/preview/{recordId}',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found or Record Id does not belong to order`,
      },
    });
  }

  /**
   * Given an order id and Workspace.API.ViewModels.Order1099s.RequestDocumentationViewModel, will send a notification to the specified
   * user's email (Auth)
   * @returns void
   * @throws ApiError
   */
  public requestDocumentation({
    orderId,
    recordId,
    requestBody,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The record id
     */
    recordId: number,
    /**
     * A Workspace.API.ViewModels.Order1099s.RequestDocumentationViewModel that contains information about the user
     */
    requestBody: Workspace_API_ViewModels_Order1099s_RequestDocumentationViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}/documentation',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id, record id and Workspace.API.ViewModels.Order1099s.Order1099sRecordUploadDocumentViewModel, will upload
   * and create association for 1099s record. (Auth)
   * @returns any Successfully created document and association
   * @throws ApiError
   */
  public uploadRecordDocumentAsync({
    orderId,
    recordId,
    formData,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The record id
     */
    recordId: number,
    formData?: {
      /**
       * Gets or sets File
       */
      File: Blob;
      FileType?: Eclipse_SDK_DTO_ImageFileExtensionType;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}/upload-record-document',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given an order id and record id, will delete the document association with the 1099-S record. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteRecordDocumentAsync({
    orderId,
    recordId,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The record id
     */
    recordId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}/document',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given and order id, record id and Workspace.API.ViewModels.Order1099s.Order1099sRecordDocumentViewModel, will update
   * the record and document association (Auth)
   * @returns void
   * @throws ApiError
   */
  public update1099SRecordDocumentAsync({
    orderId,
    recordId,
    requestBody,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
    /**
     * The record id
     */
    recordId: number,
    /**
     * A view model containing the record and document information
     */
    requestBody: Workspace_API_ViewModels_Order1099s_Order1099sRecordDocumentViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}/document',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given an order id, record id and Workspace.API.ViewModels.Order1099s.RecordImageDocumentOptionsViewModel, generates an image
   * document for the 1099-S record (Auth)
   * @returns void
   * @throws ApiError
   */
  public addImageDocumentAsync({
    orderId,
    recordId,
    requestBody,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The 1099-S record id
     */
    recordId: number,
    /**
     * The Workspace.API.ViewModels.Order1099s.RecordImageDocumentOptionsViewModel
     */
    requestBody: Workspace_API_ViewModels_Order1099s_RecordImageDocumentOptionsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/1099s/{recordId}/image-document',
      path: {
        'orderId': orderId,
        'recordId': recordId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order Not Found`,
        422: `Validation failed due to incorrect request`,
      },
    });
  }

  /**
   * Given an order id, will return a Workspace.API.ViewModels.Order1099s.GetRecordDocumentsViewModel containing a list of all
   * Escrow: Signed Instructions Workspace.Common.Models.FileDocument objects on the order (Auth)
   * @returns Workspace_API_ViewModels_Order1099s_GetRecordDocumentsViewModel A Workspace.API.ViewModels.Order1099s.GetRecordDocumentsViewModel containing a list
   * of Workspace.Common.Models.FileDocument objects
   * @throws ApiError
   */
  public getRecordDocuments({
    orderId,
  }: {
    /**
     * The order id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Order1099s_GetRecordDocumentsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/1099s/record-documents',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid authorization header`,
        403: `User is forbidden to access the order`,
        404: `Order not found`,
      },
    });
  }

}
