import { CardContent, CardTitle } from './Cards.styles';
import { Column, Row } from 'components/ui/Box';
import React, { FunctionComponent, ReactElement, useMemo } from 'react';

export interface ICard {
  icon: ReactElement;
  text: string;
  title: string;
  titleEmphasizeIndex?: number;
}

interface IProps {
  card: ICard;
}

const LandingPageCard: FunctionComponent<IProps> = ({
  card: { icon, text, title, titleEmphasizeIndex },
}) => {
  const emphasizedTitle: JSX.Element | null = useMemo(() => {
    const words: string[] = title.split(' ');

    if (!titleEmphasizeIndex || words.length <= 1) return null;

    const titlePrefix = words.slice(0, titleEmphasizeIndex - 1).join(' ');
    const emphasizedWord = words[titleEmphasizeIndex - 1];
    const titleSuffix = words.slice(titleEmphasizeIndex).join(' ');

    return (
      <>
        {titlePrefix}
        {titlePrefix && emphasizedWord && ' '}
        {emphasizedWord && <strong>{emphasizedWord}</strong>}
        {titleSuffix}
      </>
    );
  }, [title, titleEmphasizeIndex]);

  return (
    <Column alignItems="start" gap="small">
      <Row alignItems="end" gap="small">
        {icon}
        <CardTitle>{emphasizedTitle ?? title}</CardTitle>
      </Row>
      <CardContent>{text}</CardContent>
    </Column>
  );
};

export default LandingPageCard;
