import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { blue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6904 10H19.9391C18.7357 10 17.7513 10.9921 17.7513 12.2038V18.8152C17.7513 20.0268 18.7357 21.019 19.9391 21.019H28.6904C29.8938 21.019 30.8782 20.0268 30.8782 18.8152V12.2038C30.8782 10.9921 29.8938 10 28.6904 10ZM28.6904 18.8152H19.9391V14.4076H28.6904V18.8152Z"
      fill={props.fill || blue}
    />
    <path
      d="M15.5635 15.5095H13.3756V23.2228C13.3756 24.4344 14.3601 25.4266 15.5635 25.4266H25.4087V23.2228H15.5635V15.5095Z"
      fill={props.fill || blue}
    />
    <path
      d="M9 19.9171H11.1878V27.6304H21.033V29.8342H11.1878C9.98441 29.8342 9 28.842 9 27.6304V19.9171Z"
      fill={props.fill || blue}
    />
  </svg>
);
