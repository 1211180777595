import { AxiosError } from 'axios';
import { IWireInstructions } from 'store/wireInstructions/types';
import { ISearchResult } from 'store/search/types';
import { IUser } from 'store/users/types';
import { IOrganizationAddress } from 'store/orderOrganization/types';

export enum FilesTypeKeys {
  ENTITY = '[file]',
  REQUEST = 'file/REQUEST',
  SUCCESS = 'file/SUCCESS',
  ERROR = 'file/ERROR',
  CLEAR_ERROR = 'file/CLEAR_ERROR',
  RECEIVE_WIRE_INSTRUCTIONS_RESULTS = 'file/RECEIVE_WIRE_INSTRUCTIONS_RESULTS',
  RECEIVE_FILE_RESULTS = 'file/RECEIVE_FILE_RESULTS',
  SET_FILE_WIRE_INSTRUCTIONS = 'SET_FILE_WIRE_INSTRUCTIONS',
  SET_FILE_WIRE_INSTRUCTIONS_RESET = 'SET_FILE_WIRE_INSTRUCTIONS_RESET',
  SET_FILE_ORGANIZATIONS = 'SET_FILE_ORGANIZATIONS',
  SET_FILE_ORGANIZATION_CONTACTS = 'SET_FILE_ORGANIZATION_CONTACTS',
}

export interface IFilesState {
  loading: boolean;
  requestType: FileRequestTypes;
  error: null | AxiosError;
  fileWireInstructions: IWireInstructions[];
  file: ISearchResult | null;
  fileContacts: IFileContact[];
  fileOrganizations: IFileOrganizationsHash;
}

export type FilesAction =
  | IFileRequestAction
  | IFileSuccessAction
  | IFileErrorAction
  | IFileClearErrorAction
  | IFileWireInstructionsResultsAction
  | IFileReceiveResultsAction
  | ISetFileWireInstructionsResetAction
  | ISetFileOrganizationsAction
  | ISetFileOrganizationContactsAction;

export interface IFileRequestAction {
  type: FilesTypeKeys.REQUEST;
  requestType: FileRequestTypes;
}

export interface IFileSuccessAction {
  type: FilesTypeKeys.SUCCESS;
}

export interface IFileErrorAction {
  type: FilesTypeKeys.ERROR;
  error: AxiosError;
}

export interface IFileWireInstructionsResultsAction {
  type: FilesTypeKeys.RECEIVE_WIRE_INSTRUCTIONS_RESULTS;
  wireInstructions: IWireInstructions[];
}

export interface IFileReceiveResultsAction {
  type: FilesTypeKeys.RECEIVE_FILE_RESULTS;
  file: ISearchResult;
}

export interface IFetchFileWireInstructionsAction {
  entity: string;
  fileId: string;
  query?: string;
  page?: number;
}

export interface ISetFileWireInstructionsResetAction {
  type: FilesTypeKeys.SET_FILE_WIRE_INSTRUCTIONS_RESET;
}

export interface IFileContact {
  name: string;
  phone: string;
  email: string;
  type: string;
}

export enum FileRequestTypes {
  NONE = 'NONE',
  FILE = 'FILE',
  FILE_WIRE_INSTRUCTIONS = 'FILE_WIRE_INSTRUCTIONS',
  OPEN_FAST = 'OPEN_FAST',
  CONTACTS = 'CONTACTS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  ORGANIZATION_CONTACTS = 'ORGANIZATION_CONTACTS',
}

export enum FileLoadingKeys {
  fileLoading = 'fileLoading',
  fileOrganizationsLoading = 'fileOrganizationsLoading',
  fileOrganizationContactsLoading = 'fileOrganizationContactsLoading',
  fileWireInstructionsLoading = 'fileWireInstructionsLoading',
}

export enum IFileOrganizationType {
  Organization = 'Organization',
  Seller = 'Seller',
  Buyer = 'Buyer',
}

export enum IFileOrganizationLenderRoleTypes {
  Lender = 688,
  PayoffLender = 674,
  AssumptionLender = 673,
}

export interface IFileOrganizationAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
  county: string;
}

export interface IFileOrganizationContacts {
  userId?: number | null;
  gabPartyId?: number | null;
  adhocContactId?: number | null;
  isMainContact?: boolean;
  name: string;
  roleId?: string | number;
  roleCode?: string;
  roleDescription?: string;
  email: string;
  phone: string;
  cellular?: string;
  address?: IFileOrganizationAddress;
  type?: IFileOrganizationType;
}

export interface ITransactionParty {
  address: IOrganizationAddress;
  email: string | null;
  id: number;
  name: string;
  phone: string | null;
  roleDescription: string | null;
  roleId: number | null;
  roleName: string | null;
  type: IFileOrganizationType;
}

export interface IFileOrganizations {
  borrowerTransactionFastId?: number;
  businessPartyName: string;
  businessPartyRoleFastId?: number;
  businessPartyRoleId: number;
  city: string;
  email: string;
  employeeId?: number;
  gabCode?: string | null;
  id: string | number;
  key?: string;
  name: string;
  officerId?: number | null;
  officerRole?: string | null;
  is1099SRecordExist: boolean;
  organizationContacts?: IFileOrganizationContacts[];
  organizationId: number;
  organizationName1?: string | null;
  organizationName2?: string | null;
  partyId: number;
  phone: string;
  phoneNumber: string;
  relatedBusinessPartyId?: number | null;
  role: string;
  sequenceNumber: number;
  sourceType: string;
  state: string;
  transactionParty: ITransactionParty;
  user: IUser | null;
  userId: number;
}

export interface IFileOrganizationsHash {
  [key: string]: IFileOrganizations;
}

export interface IFetchFileOrganizationsAction {
  fileId: string | number;
  includeNameSeparation?: boolean;
}

export interface IFetchFileOrganizationContactsAction {
  fileId: string;
  type: IFileOrganizationType;
  id: string;
  key?: string;
}

export interface ISetFileOrganizationsAction {
  type: FilesTypeKeys.SET_FILE_ORGANIZATIONS;
  organizations: IFileOrganizationsHash;
}

export interface ISetFileOrganizationContactsAction {
  type: FilesTypeKeys.SET_FILE_ORGANIZATION_CONTACTS;
  key: string;
  organizationContacts: IFileOrganizationContacts[];
}

export interface IOffice {
  businessUnitId: number;
}

export interface IFileOffices {
  offices: IOffice[];
}

export interface IFileClearErrorAction {
  type: FilesTypeKeys.CLEAR_ERROR;
}
