import { AxiosError } from 'axios';

export enum FeaturesTypeKeys {
  REQUEST_START = 'features/REQUEST_START',
  REQUEST_SUCCESS = 'features/REQUEST_SUCCESS',
  FETCH_COMPONENTS = 'features/FETCH_COMPONENTS',
  FETCH_FEATURES = 'features/FETCH_FEATURES',
  FETCH_ERROR = 'features/FETCH_ERROR',
  TOGGLE_FEATURE_GROUP = 'features/TOGGLE_FEATURE_GROUP',
  FETCH_USER_FEATURES = 'features/FETCH_USER_FEATURES',
  FETCH_COMPONENT_FEATURES = 'features/FETCH_COMPONENT_FEATURES',
  FETCH_FEATURE_GROUPS = 'features/FETCH_FEATURE_GROUPS',
  TOGGLE_GROUP_UNDER_FEATURE = 'features/TOGGLE_GROUP_UNDER_FEATURE',
}

export interface IFeaturesState {
  error: string | null | AxiosError;
  loading: boolean;
  components: IFeatureComponent[];
  features: IFeatureToggle[];
  component: IFeatureComponent;
  feature: IFeatureToggle;
  userFeatures: IFeatureToggle[];
  componentFeatures: IFeatureComponent[];
  featureGroups: IFeatureToggle[];
}

export interface IFeaturesRequestStart {
  type: FeaturesTypeKeys.REQUEST_START;
}

export interface IFetchSuccessAction {
  type: FeaturesTypeKeys.REQUEST_SUCCESS;
}

export interface IFetchErrorAction {
  type: FeaturesTypeKeys.FETCH_ERROR;
  error: AxiosError | string;
}

export interface IFetchComponentsAction {
  type: FeaturesTypeKeys.FETCH_COMPONENTS;
  payload: IFeatureComponent[];
}

export interface IFetchFeaturesAction {
  type: FeaturesTypeKeys.FETCH_FEATURES;
  payload: IFeatureComponent[];
}

export interface IFetchUserFeaturesAction {
  type: FeaturesTypeKeys.FETCH_USER_FEATURES;
  payload: IFeatureComponent[];
}

export interface IFetchFeatureToggleGroupAction {
  type: FeaturesTypeKeys.TOGGLE_FEATURE_GROUP;
  payload: {
    id: number;
    isEnabled: boolean;
  };
}

export interface IFetchComponentFeaturesAction {
  type: FeaturesTypeKeys.FETCH_COMPONENT_FEATURES;
  payload: IFeatureComponent[];
}

export interface IFetchFeatureGroupsAction {
  type: FeaturesTypeKeys.FETCH_FEATURE_GROUPS;
  payload: IFeatureToggle[];
}

export interface IFetchToggleGroupUnderFeatureAction {
  type: FeaturesTypeKeys.TOGGLE_GROUP_UNDER_FEATURE;
  payload: {
    id: number;
    isEnabled: boolean;
  };
}

export type FeaturesAction =
  | IFeaturesRequestStart
  | IFetchSuccessAction
  | IFetchErrorAction
  | IFetchComponentsAction
  | IFetchFeaturesAction
  | IFetchUserFeaturesAction
  | IFetchFeatureToggleGroupAction
  | IFetchComponentFeaturesAction
  | IFetchFeatureGroupsAction
  | IFetchToggleGroupUnderFeatureAction;

export interface IFeatureComponent {
  name: string;
  id?: number;
  features?: string[];
}

export interface IFeatureComponentTree {
  [name: string]: string[];
}

export interface IFeatureToggle {
  id?: number;
  name: string;
  isEnabled?: boolean;
}

export enum OfficeViewFeatures {
  ExcludeRecoverWire = 'OfficeView.ExcludeRecoverWire',
  IncomingWires = 'OfficeView.IncomingWires',
  IncomingWiresEMD = 'OfficeView.IncomingWiresEMD',
  OutgoingWires = 'OfficeView.OutgoingWires',
  OutgoingWireDetails = 'OfficeView.OutgoingWireDetails',
  ReceiptOrder = 'OfficeView.ReceiptOrder',
  ReturnWire = 'OfficeView.ReturnWire',
  TransferFunds = 'OfficeView.TransferFunds',
  TransmitWire = 'OfficeView.TransmitWire',
  TransferReturnDetails = 'OfficeView.TransferReturnDetails',
  ApproveTransferReturn = 'OfficeView.ApproveTransferReturn',
  RejectTransferReturn = 'OfficeView.RejectTransferReturn',
  BulkTransmitWires = 'OfficeView.BulkTransmitWires',
  BulkTransmitTransferReturn = 'OfficeView.BulkTransmitTransferReturn',
  TransmitTransferReturn = 'OfficeView.TransmitTransferReturn',
  TrustExtractManualBuild = 'OfficeView.TrustExtractManualBuild',
  TrustExtractOverview = 'OfficeView.TrustExtractOverview',
  DepositList = 'OfficeView.DepositList',
}

export enum KnoxFeatures {
  MultipleDocumentsUpload = 'Knox.MultipleDocumentsUpload',
  PayeeNameCheck = 'Knox.PayeeNameCheck',
  SameOrderReuse = 'Knox.SameOrderReuse',
  SearchDocs = 'Knox.SearchDocs',
  UpdateDocsAndNotes = 'Knox.UpdateDocsAndNotes',
  ImprovedDuplicateFlow = 'Knox.ImprovedDuplicateFlow',
  SAFEValidation = 'Knox.SAFEValidation',
  IndependentPhoneVerification = 'Knox.IndependentPhoneVerification',
}

export enum LiveDealsFeatures {
  AddOrderContact = 'LiveDeals.AddOrderContact',
  AddRemoveEmployees = 'LiveDeals.AddRemoveEmployees',
  AddRemoveEmployeesPt2 = 'LiveDeals.AddRemoveEmployeesPt2',
  Affiliate = 'LiveDeals.Affiliate',
  Dashboard = 'LiveDeals.Dashboard',
  DocDisplayName = 'LiveDeals.DocDisplayName',
  DocMultiDownload = 'LiveDeals.DocMultiDownload',
  DocMultiShare = 'LiveDeals.DocMultiShare',
  DocNotifications = 'LiveDeals.DocNotifications',
  DocSharing = 'LiveDeals.DocSharing',
  Documents = 'LiveDeals.Documents',
  EagleProPackages = 'LiveDeals.EagleProPackages',
  EditClosingDate = 'LiveDeals.EditClosingDate',
  EditLenderLiability = 'LiveDeals.EditLenderLiability',
  EditLoanAmount = 'LiveDeals.EditLoanAmount',
  EditOrderHideNonEditableToolTips = 'LiveDeals.EditOrderHideNonEditableToolTips',
  EditOrderName = 'LiveDeals.EditOrderName',
  EditOrderParties = 'LiveDeals.EditOrderParties',
  EditOrderType = 'LiveDeals.EditOrderType',
  EditOwnerLiability = 'LiveDeals.EditOwnerLiability',
  EditProducts = 'LiveDeals.EditProducts',
  EditPropertyLocation = 'LiveDeals.EditPropertyLocation',
  EditPropertyType = 'LiveDeals.EditPropertyType',
  EditSalesPrice = 'LiveDeals.EditSalesPrice',
  EditServiceType = 'LiveDeals.EditServiceType',
  EditStatus = 'LiveDeals.EditStatus',
  EOPContactsSection = 'LiveDeals.EOPContactsSection',
  EOPLenderSection = 'LiveDeals.EOPLenderSection',
  OrderPage = 'LiveDeals.OrderPage',
  OrderSearch = 'LiveDeals.OrderSearch',
  OrderPartiesAccess = 'LiveDeals.OrderPartiesAccess',
  ViewDocuments = 'LiveDeals.ViewDocuments',
  ChangeOwningOffice = 'LiveDeals.ChangeOwningOffice',
  FileNotes = 'LiveDeals.FileNotes',
  ManageProductionOffice = 'LiveDeals.ManageProductionOffice',
  ManageProductionOfficeContacts = 'LiveDeals.ManageProductionOfficeContacts',
  EditKeyDates = 'LiveDeals.EditKeyDates',
  EditOrderPartiesOverview = 'LiveDeals.EditOrderPartiesOverview',
  UpdateSalesPriceDescription = 'LiveDeals.UpdateSalesPriceDescription',
}

export enum DepositsFeatures {
  AchDeposit = 'Deposits.ACHDeposit',
  LinkToOverdraft = 'Deposits.LinkToOverdraft',
  ViewCheckImage = 'Deposits.ViewCheckImage',
  DocUpload = 'Deposits.DocUpload',
  IncomingFunds = 'Deposits.IncomingFunds',
  NewDeposits = 'Deposits.NewDeposits',
}

export enum DisbursementsFeatures {
  IssueWire = 'Disbursements.IssueWire',
  BulkIssue = 'Disbursements.BulkIssue',
}

export enum SettlementStatementFeatures {
  ExportMultipleSS = 'SettlementStatement.ExportMultipleSS',
  Grouping = 'SettlementStatement.Grouping',
  CFSSPlatform = 'SettlementStatement.CFSSPlatform',
  CFSSPlatformOverride = 'SettlementStatement.CFSSPlatformOverride',
  SingleOrderDynamicEditing = 'SettlementStatement.SingleOrderDynamicEditing',
  FractionalEnhance2024 = 'SettlementStatement.FractionalEnhance2024',
}

export enum ChargesFeatures {
  AddAddress = 'Charges.AddAddress',
  HoldFundDisbursements = 'Charges.HoldFundDisbursements',
  ImportContact = 'Charges.ImportContact',
  OutsideTitleFeesTaxes = 'Charges.OutsideTitleFeesTaxes',
  ShowAddress = 'Charges.ShowAddress',
  ViewCharge = 'Charges.ViewCharge',
}

export enum DocumentsFeatures {
  Edit = 'Documents.Edit',
  NewEvents = 'Documents.NewEvents',
  Remove = 'Documents.Remove',
  Uploads = 'Documents.Uploads',
  ViewExtendedFormats = 'Documents.ViewExtendedFormats',
  ShareFromViewer = 'Documents.ShareFromViewer',
  CDUReview = 'Documents.CDU_Review',
  DMS2Integration = 'Documents.DMS2Integration',
  DMS2DataElementIndexing = 'Documents.DataElementIndexing',
  MultisiteCustUpload = 'Documents.MultisiteCustUpload',
}

export enum EventsFeatures {
  Documents = 'Events.Documents',
  OrderHistory = 'Events.OrderHistory',
}

export enum ProrationsFeatures {
  CreateProrationMiscellaneous = 'Prorations.CreateProrationMiscellaneous',
  CreateProrationRent = 'Prorations.CreateProrationRent',
  CreateProrationTax = 'Prorations.CreateProrationTax',
  Overview = 'Prorations.Overview',
  ReCalculate = 'Prorations.Recalculate',
}
export enum AdjustmentsFeatures {
  CreateAdjustmentMiscellaneous = 'Adjustments.CreateAdjustmentMiscellaneous',
  CreateAdjustmentOffSet = 'Adjustments.CreateAdjustmentOffSet',
  Overview = 'Adjustments.Overview',
}

export enum PlatformFeatures {
  SessionTimeout = 'Platform.SessionTimeout',
  SettingsPage = 'Platform.SettingsPage',
  Notifications = 'Platform.Notifications',
  Resources = 'Platform.Resources',
  WhatsNewInternal = 'Platform.WhatsNewInternal',
  WhatsNewExternal = 'Platform.WhatsNewExternal',
  LearnIt = 'Platform.LearnIt',
  EmailSendOnBehalf = 'Platform.EmailSendOnBehalf',
  VerificationPhoneNumberRequired = 'Platform.VerificationPhoneNumberRequired',
}

export enum FlightPlansFeatures {
  DailyDigestNotifications = 'FlightPlans.DailyDigestNotifications',
  DailyUpdatesNotifications = 'FlightPlans.DailyUpdatesNotifications',
  MilestoneUpdatesNotifications = 'FlightPlans.MilestoneUpdatesNotifications',
  OrderProgress = 'FlightPlans.OrderProgress',
}

export enum MultisiteFeatures {
  AddRemoveSites = 'Multisite.AddRemoveSites',
  CGSSConfigs = 'Multisite.CGSSConfigs',
  ClosingGroups = 'Multisite.ClosingGroups',
  ClosingGroupBalance = 'Multisite.ClosingGroupBalance',
  CreateCGMiscDisbSubgroup = 'Multisite.CreateCGMiscDisbSubgroup',
  CreateMiscDisbSubgroup = 'Multisite.CreateMiscDisbSubgroup',
  EscrowFeatures = 'Multisite.EscrowFeatures',
  SiteEscrowFeatures = 'Multisite.SiteEscrowFeatures',
  FractionalWithEclipse = 'Multisite.FSSwithEclipse',
  FSSNativeCF = 'Multisite.FSSNativeCF',
  FSSNativeCFClearAndRefresh = 'Multisite.FSSNativeCFClearAndRefresh',
  MultisiteMVP = 'Multisite.MultisiteMVP',
  SettlementStatement = 'Multisite.SettlementStatement',
  SharingSettlementStatement = 'Multisite.SharingSettlementStatement',
  SitesMap = 'Multisite.SitesMap',
  SyncSettlementStatement = 'Multisite.SyncSettlementStatement',
  SettlementStatementDynamicEditing = 'Multisite.SettlementStatementDynamicEditing',
  CommentingSettlementStatement = 'Multisite.CommentingSettlementStatement',
}

export enum AllocationsFeatures {
  Deposits = 'Allocations.Deposits',
  Loans = 'Allocations.Loans',
  Fees = 'Allocations.Fees',
  Prorations = 'Allocations.Prorations',
  Adjustments = 'Allocations.Adjustments',
  HoldFunds = 'Allocations.HoldFunds',
  Charges = 'Allocations.Charges',
  AssumptionLoan = 'Allocations.AssumptionLoan',
  Allocations2024 = 'Allocations.Allocations2024',
}

export enum TaxesAndFeesFeatures {
  FeeEntry = 'TaxesFees.FeeEntry',
  FeeRecap = 'TaxesFees.FeeRecap',
  Overview = 'TaxesFees.Overview',
  SalesTaxOverride = 'TaxesFees.SalesTaxOverride',
  AddServiceFee = 'TaxesFees.AddServiceFee',
  AddInterofficeFee = 'TaxesFees.AddInterofficeFee',
  AddTitleEscrow = 'TaxesFees.AddTitleEscrow',
  AddRecordingTax = 'TaxesFees.AddRecordingTax',
  Splits = 'TaxesFees.Splits',
  BulkEdit = 'TaxesFees.BulkEdit',
  RateCalculator = 'TaxesFees.FACCRateCalculator',
}

export enum InvoicingFeatures {
  InvoicesHistory = 'Invoicing.InvoicesHistory',
  EmailInvoice = 'Invoicing.EmailInvoice',
  InvoicesOverview = 'Invoicing.InvoicesOverview',
  PrintInvoices = 'Invoicing.PrintInvoices',
  CreateInvoice = 'Invoicing.CreateInvoice',
  SearchInvoices = 'Invoicing.SearchInvoices',
  CancelInvoices = 'Invoicing.CancelInvoices',
  FinalizeInvoice = 'Invoicing.FinalizeInvoice',
  ApproveInvoice = 'Invoicing.ApproveInvoice',
  SiteFileInvoices = 'Invoicing.SiteFileInvoices',
}

export enum ChecklistsFeatures {
  CustomerChecklistTemplates = 'Checklists.CustomerChecklistTemplates',
  CustomerChecklistOrders = 'Checklists.CustomerChecklistOrders',
  EmployeeChecklist = 'Checklists.EmployeeChecklist',
}

export enum CoInsuranceFeatures {
  Overview = 'CoInsurance.Overview',
}

export enum IncomeRecognitionFeatures {
  Overview = 'IncomeRecognition.Overview',
}

export enum MultisiteBulkUpdatesFeatures {
  OrderType = 'MultisiteBulkUpdates.OrderType',
  Products = 'MultisiteBulkUpdates.Products',
  LoanAllocations = 'MultisiteBulkUpdates.LoanAllocations',
  SalesPrices = 'MultisiteBulkUpdates.SalesPrices',
  BusinessParties = 'MultisiteBulkUpdates.BusinessParties',
}

export enum ApolloFeatures {
  Relay = 'Apollo.Relay',
}

export enum Order1099sFeatures {
  Overview = '1099-S.Overview',
  CustomerInputEsign = '1099-S.CustomerInputEsign',
  ImageDoc = '1099-S.ImageDoc',
}

export enum PropertyManagementFeatures {
  ViewPropertiesList = 'PropertyManagement.ViewPropertiesList',
  AddProperty = 'PropertyManagement.AddProperty',
  EditProperty = 'PropertyManagement.EditProperty',
  RemoveProperty = 'PropertyManagement.RemoveProperty',
  FASTSearch = 'PropertyManagement.FASTSearch',
  PropertySearch = 'PropertyManagement.PropertySearch',
}

export enum SDNFeatures {
  ViewSDN = 'SDN.ViewSDN',
  ViewSDNDocs = 'SDN.ViewSDNDocs',
  ClearSDNHit = 'SDN.ClearSDNHit',
}

export enum MilestonesFeatures {
  ShowEarnestMoney = 'Milestones.ShowEarnestMoney',
}

export interface IFeatureFlag {
  component: string;
  feature: string;
}

type EditOrderFeatureToggles =
  | LiveDealsFeatures.EditOrderName
  | LiveDealsFeatures.EditClosingDate
  | LiveDealsFeatures.EditLoanAmount
  | LiveDealsFeatures.EditLenderLiability
  | LiveDealsFeatures.EditProducts
  | LiveDealsFeatures.EditPropertyLocation
  | LiveDealsFeatures.EditSalesPrice
  | LiveDealsFeatures.EditOwnerLiability
  | LiveDealsFeatures.EditOrderType
  | LiveDealsFeatures.EditPropertyType
  | LiveDealsFeatures.EditServiceType
  | LiveDealsFeatures.EditStatus
  | LiveDealsFeatures.EditOrderHideNonEditableToolTips
  | LiveDealsFeatures.EditOrderParties
  | LiveDealsFeatures.EditOrderPartiesOverview;

export type IEditOrderFeatures = Record<
  EditOrderFeatureToggles,
  {
    canEdit: boolean;
    isFeatureEnabled: boolean;
  }
>;
