import styled from 'styled-components';
import {
  headerZIndex,
  white,
  shuttleGrey,
  blue,
  nepal,
  lighterBlue,
} from 'config/theme';
import React, { useEffect, useState } from 'react';
import { Dispatch, IApplicationState } from 'store';
import { Row } from 'components/ui/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IDepositReceiptProgressMetaData } from 'store/orders/types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ChevronIcon from 'react-icons/lib/md/chevron-left';
import CloseIcon from 'react-icons/lib/md/close';
import { ReadonlyFileName } from 'components/forms/FileField/ReadonlyFileName';
import { formatCurrency } from 'utils/amount';
import workspaceAPI from 'services/workspaceAPI';
import { Location } from 'history';
import {
  trackGenerateReceiptProgress,
  clearGenerateReceiptProgress,
} from 'store/orders/actions';
import { StyledReactTooltip } from 'components/ToolTip';
import useInterval from 'hooks/useInterval';
import { SMALL_BODY_MEDIUM } from 'config/typography';

interface IConnectedProps {
  dispatch: Dispatch;
  location: Location;
  receiptsMetaData: IDepositReceiptProgressMetaData[];
}

const headerHeight = 56;
const rowHeight = 63;
const maxVisibleRows = 4;
const domIdPrefix = 'deposit-receipt-generation-progress';
const disabledViewToolTipId = 'receipt-progress-disabled-view';
const retryDelay = 10000; //ms

const DepositReceiptGenerationProgress = ({
  dispatch,
  receiptsMetaData,
}: IConnectedProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [delay, setDelay] = useState<number | null>(retryDelay);

  const getReceiptGenerationProgress = async () => {
    const request = receiptsMetaData.filter((x) => x.documentId === 0);
    if (request.length) {
      const { data } = await workspaceAPI.post<
        IDepositReceiptProgressMetaData[]
      >(`escrow/deposits/receipt-progress`, request);
      receiptsMetaData.forEach((item1) => {
        const itemFromArr2 = data.find(
          (item2) => item2.depositId == item1.depositId
        );
        if (itemFromArr2) {
          item1.orderId = itemFromArr2.orderId;
        }
      });
      dispatch(trackGenerateReceiptProgress(data));
    } else {
      setDelay(null);
    }
  };

  useEffect(() => {
    setDelay(retryDelay);
  }, [receiptsMetaData.length]);

  useInterval(() => {
    if (receiptsMetaData.length) {
      getReceiptGenerationProgress();
    }
  }, delay);

  if (!receiptsMetaData.length) {
    return null;
  }

  const inProgress = receiptsMetaData.some((x) => x.documentId === 0);

  return (
    <FixedContainer>
      <ProgressHeader
        inProgress={inProgress}
        isExpanded={isExpanded}
        onClose={() => dispatch(clearGenerateReceiptProgress(null))}
        onToggleExpanded={() => setIsExpanded(!isExpanded)}
      />
      {inProgress && isExpanded && <LinearProgress />}
      <div style={{ overflowY: 'auto', maxHeight: rowHeight * maxVisibleRows }}>
        {isExpanded &&
          receiptsMetaData.map(
            (receiptMetadata: IDepositReceiptProgressMetaData, i) => (
              <ProgressRow
                receiptMetadata={receiptMetadata}
                key={`receipt-${i}`}
                viewerUrl={`/order/${receiptMetadata.orderId}/documents/${receiptMetadata.documentId}/view`}
                dispatch={dispatch}
              />
            )
          )}
      </div>
    </FixedContainer>
  );
};

const ProgressHeader = ({
  inProgress,
  isExpanded,
  onClose,
  onToggleExpanded,
}) => {
  return (
    <StyledProgressHeader inProgress={inProgress}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header>Generating Receipts{inProgress ? '...' : ''}</Header>
        {isExpanded && (
          <Subheader>
            {inProgress
              ? 'Once complete, receipts will be available under Documents'
              : 'Receipts are ready to view below or from Documents'}
          </Subheader>
        )}
      </div>
      <ButtonContainer
        onClick={
          inProgress
            ? onToggleExpanded
            : !isExpanded
            ? onToggleExpanded
            : onClose
        }
        id={`${domIdPrefix}__button__close`}
      >
        {isExpanded ? (
          inProgress ? (
            <ExpandedChevron />
          ) : (
            <StyledCloseIcon />
          )
        ) : (
          <CollapsedChevron />
        )}
      </ButtonContainer>
    </StyledProgressHeader>
  );
};

const ProgressRow = ({ dispatch, receiptMetadata, viewerUrl }) => {
  const { representingType, amount, documentId } = receiptMetadata;
  const fileName = `${representingType} Receipt ${formatCurrency(amount)}`;
  const onClick = documentId
    ? () => {
        dispatch(clearGenerateReceiptProgress(receiptMetadata));
      }
    : undefined;

  return (
    <Row
      key={receiptMetadata.documentId}
      justify="between"
      alignItems="center"
      style={{
        height: rowHeight,
        padding: 16,
        borderBottom: `2px solid ${lighterBlue}`,
      }}
    >
      <ReadonlyFileName
        fileName={fileName + '.pdf'}
        displayName={fileName}
        maxWidth={230}
      />
      <ViewReceipt
        inProgress={!documentId}
        href={documentId ? viewerUrl : null}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
        data-for={disabledViewToolTipId}
        data-tip={!documentId ? 'Receipt not ready to view yet' : ''}
      >
        View
      </ViewReceipt>
      <StyledReactTooltip id={disabledViewToolTipId} />
    </Row>
  );
};

const mapStateToProps = (
  state: IApplicationState
): Omit<IConnectedProps, 'dispatch' | 'location'> => ({
  receiptsMetaData: state.orders.ui.depositReceiptsProgress.receiptsMetaData,
});

export default withRouter(
  connect(mapStateToProps)(DepositReceiptGenerationProgress)
);

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${rowHeight * maxVisibleRows + headerHeight}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  margin-left: 16px;
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
`;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })<{
  inProgress: boolean;
}>`
  height: ${({ inProgress }) =>
    inProgress ? headerHeight - 4 : headerHeight}px;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  align-items: center;
  ${({ inProgress }) =>
    inProgress ? '' : `border-bottom: 2px solid ${lighterBlue};`}
`;

const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;

  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${shuttleGrey};
    }
  }
`;

const Header = styled.div`
  ${SMALL_BODY_MEDIUM}
`;

const Subheader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

const StyledChevron = styled(ChevronIcon)`
  width: 30px;
  height: 30px;
  color: ${shuttleGrey};
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;
  height: 30px;
  color: ${shuttleGrey};
`;

const CollapsedChevron = styled(StyledChevron)`
  transform: rotate(90deg);
`;

const ExpandedChevron = styled(StyledChevron)`
  transform: rotate(270deg);
`;

const ViewReceipt = styled.a<{ inProgress: boolean }>`
  color: ${(props) => (props.inProgress ? nepal : blue)};
  width: 66px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;

  && {
    text-decoration: none;
  }

  &:hover {
    ${({ inProgress }) =>
      inProgress ? '' : `background-color: rgba(52, 85, 128, 0.14);`}
    border-radius: 3px;
  }
`;
