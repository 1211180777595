/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel';
import type { Workspace_API_ViewModels_ClosingGroups_AddSitesToGroupViewModel } from '../models/Workspace_API_ViewModels_ClosingGroups_AddSitesToGroupViewModel';
import type { Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel } from '../models/Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel';
import type { Workspace_API_ViewModels_ClosingGroups_CreateClosingGroupViewModel } from '../models/Workspace_API_ViewModels_ClosingGroups_CreateClosingGroupViewModel';
import type { Workspace_API_ViewModels_ClosingGroups_ListClosingGroupsViewModel } from '../models/Workspace_API_ViewModels_ClosingGroups_ListClosingGroupsViewModel';
import type { Workspace_API_ViewModels_ClosingGroups_UpdateClosingGroupViewModel } from '../models/Workspace_API_ViewModels_ClosingGroups_UpdateClosingGroupViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateClosingGroupGeneralInformationViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateClosingGroupGeneralInformationViewModel';
import type { Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto } from '../models/Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto';
import type { Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType } from '../models/Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType';
import type { Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto } from '../models/Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClosingGroupsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the Settlement Statement Header for a particular Closing Group (Auth)
   * @returns Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto Header data successfully retrieved
   * @throws ApiError
   */
  public getCommercialHeader({
    orderId,
    closingGroupId,
  }: {
    /**
     * Order id to provide the order id to the filters applied to the controller
     */
    orderId: number,
    /**
     * Identifier for the Closing Group for which the Settlement Statement Header is to be retrieved
     */
    closingGroupId: number,
  }): CancelablePromise<Workspace_Common_Models_Dto_ClosingGroups_ClosingGroupCommercialHeaderDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/groups/{closingGroupId}/settlement-statement/header',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order or Closing Group could not be found`,
      },
    });
  }

  /**
   * Updates properties and parties for Closing Groups settlement statements (Auth)
   * @returns any Data successfully updated
   * @throws ApiError
   */
  public patchGeneralInformation({
    orderId,
    closingGroupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Identifier for the Closing Group to be updated
     */
    closingGroupId: number,
    /**
     * Data used in the update process
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateClosingGroupGeneralInformationViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/groups/{closingGroupId}/header',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Endpoint received invalid payload`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order or Closing Group could not be found`,
      },
    });
  }

  /**
   * Gets the settlement statement for a closing group (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Settlement Statement successfully retrieved
   * @throws ApiError
   */
  public getSettlementStatement({
    orderId,
    closingGroupId,
    statementType,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * The type of Settlement Statement to filter by
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Settlement statement/order not found`,
      },
    });
  }

  /**
   * Creates a Settlement Statement (Auth)
   * @returns any Settlement statement was created.
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Success
   * @throws ApiError
   */
  public createSettlementStatementAsync({
    orderId,
    closingGroupId,
    statementType,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * The type of Settlement Statement to create
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
  }): CancelablePromise<any | Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      errors: {
        400: `The endpoint was called with invalid data`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Closing group/Order not found`,
        409: `Settlement statement already exists`,
      },
    });
  }

  /**
   * Exports a closing group settlement statement by its Id, the related Order Id and specific Type (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel Settlement Statement successfully exported
   * @throws ApiError
   */
  public exportClosingGroupSettlementStatementByIdAndType({
    orderId,
    closingGroupId,
    statementType,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * The type of Settlement Statement to filter by
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
    /**
     * Export settings
     */
    requestBody?: Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}/export',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieves the export settings (print and signature options) for a settlement statement (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel Setting successfully retrieved
   * @throws ApiError
   */
  public getExportSettingsAsync({
    orderId,
    closingGroupId,
    statementType,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Closing group identifier
     */
    closingGroupId: number,
    /**
     * Settlement statement type
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/groups/{closingGroupId}/settlement-statement/{statementType}/export/settings',
      path: {
        'orderId': orderId,
        'closingGroupId': closingGroupId,
        'statementType': statementType,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement print/signature options not found`,
      },
    });
  }

  /**
   * Get all closing groups in an order
   * @returns Workspace_API_ViewModels_ClosingGroups_ListClosingGroupsViewModel All closing groups in the order
   * @throws ApiError
   */
  public listOrderClosingGroups({
    orderId,
  }: {
    /**
     * The order ID
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_ClosingGroups_ListClosingGroupsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/order/{orderId}/groups',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token.`,
      },
    });
  }

  /**
   * Add a closing group to a project file and add specified sites after creating it.
   * @returns Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel Closing group added successfully.
   * @throws ApiError
   */
  public addClosingGroupAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID (Project File ID).
     */
    orderId: number,
    /**
     * The closing group.
     */
    requestBody?: Workspace_API_ViewModels_ClosingGroups_CreateClosingGroupViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/order/{orderId}/groups',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token.`,
        409: `Closing group with the same name already exists.`,
        422: `Model with validation errors.`,
      },
    });
  }

  /**
   * Update an existing closing group.
   * @returns Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel The closing group was updated successfully.
   * @throws ApiError
   */
  public updateClosingGroupAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order id.
     */
    orderId: number,
    /**
     * Closing group details
     */
    requestBody?: Workspace_API_ViewModels_ClosingGroups_UpdateClosingGroupViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/order/{orderId}/groups',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token.`,
        403: `External user or user cannot access this order.`,
        404: `Closing group or order not found.`,
        409: `Closing group with the same name already exists.`,
        422: `Model with validation errors.`,
      },
    });
  }

  /**
   * Get closing group details with order list
   * @returns Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel Success
   * @throws ApiError
   */
  public getClosingGroupDetails({
    orderId,
    groupId,
  }: {
    /**
     * Order ID (Project File ID)
     */
    orderId: number,
    /**
     * Closing Group ID
     */
    groupId: number,
  }): CancelablePromise<Workspace_API_ViewModels_ClosingGroups_ClosingGroupViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/order/{orderId}/groups/{groupId}',
      path: {
        'orderId': orderId,
        'groupId': groupId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete a closing group, unlinking any sites from it
   * @returns void
   * @throws ApiError
   */
  public deleteClosingGroupAsync({
    orderId,
    groupId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * The closing group ID
     */
    groupId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/order/{orderId}/groups/{groupId}',
      path: {
        'orderId': orderId,
        'groupId': groupId,
      },
      errors: {
        400: `Unable to delete the closing group.`,
        401: `Invalid Authorization header/token.`,
      },
    });
  }

  /**
   * Add sites to a closing group
   * @returns Workspace_API_ViewModels_ClosingGroups_AddSitesToGroupViewModel The sites were successfully added to the closing group
   * @throws ApiError
   */
  public addSitesToClosingGroupAsync({
    orderId,
    groupId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * The closing group ID
     */
    groupId: number,
    /**
     * The IDs of the site files to be added to the group
     */
    requestBody?: Array<number> | null,
  }): CancelablePromise<Workspace_API_ViewModels_ClosingGroups_AddSitesToGroupViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/order/{orderId}/groups/{groupId}/sites',
      path: {
        'orderId': orderId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Unable to add the sites.`,
        401: `Invalid Authorization header/token.`,
      },
    });
  }

  /**
   * Remove sites from a closing group
   * @returns Workspace_API_ViewModels_ClosingGroups_AddSitesToGroupViewModel The sites were successfully removed from the closing group
   * @throws ApiError
   */
  public removeSitesFromGroupAsync({
    orderId,
    groupId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * The closing group ID
     */
    groupId: number,
    /**
     * The IDs of the site files to be removed from the group
     */
    requestBody?: Array<number> | null,
  }): CancelablePromise<Workspace_API_ViewModels_ClosingGroups_AddSitesToGroupViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/order/{orderId}/groups/{groupId}/remove-sites',
      path: {
        'orderId': orderId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Unable to remove the requested sites.`,
        401: `Invalid Authorization header/token.`,
      },
    });
  }

}
