import React from 'react';
import styled from 'styled-components';
import ContentContainer from 'components/ContentContainer';
import { blue, headerHeight, minPageWidth, white } from 'config/theme';

export default ({ children }) => (
  <HeaderContainer data-js-header-container>
    <ContentContainer>{children}</ContentContainer>
  </HeaderContainer>
);

const HeaderContainer = styled.header`
  background-color: ${blue};
  color: ${white};
  height: ${headerHeight}px;
  width: 100%;
  min-width: ${minPageWidth}px;
  ${ContentContainer} {
    display: flex;
    align-items: center;
  }
`;
HeaderContainer.displayName = 'HeaderContainer';
