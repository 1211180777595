import Box from '@material-ui/core/Box';
import { CLARITYFIRST_VIDEO_LINK } from 'store/resources/constants';
import { DisplaySize } from '.';
import React from 'react';
import styled from 'styled-components';

export default () => {
  return (
    <VideoPlayer style={{ aspectRatio: '700/395' }}>
      <iframe
        allow="autoplay; fullscreen"
        allowFullScreen
        src={CLARITYFIRST_VIDEO_LINK}
      />
    </VideoPlayer>
  );
};

const VideoPlayer = styled(Box)`
  border-radius: 6px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
  max-width: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${DisplaySize.MOBILE}px) {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }

  iframe {
    border-radius: 6px;
    border-style: none;
    height: 100%;
    vertical-align: middle;
    width: 100%;
  }
`;
