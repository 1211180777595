import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="184"
    height="184"
    viewBox="0 0 184 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.9996 30.3831L134.133 45.0803C136.738 45.9884 138.483 48.4444 138.483 51.2053V96.3241C138.483 127.673 91.9996 144.969 91.9996 144.969C91.9996 144.969 45.5166 127.673 45.5166 96.3241V51.2053C45.5166 48.4444 47.2613 45.9884 49.8665 45.0803L91.9996 30.3831Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.9996 144.969C91.9996 144.969 45.5166 127.673 45.5166 96.3241V51.2053C45.5166 48.4444 47.2613 45.9884 49.8665 45.0803L91.9996 30.3831V144.969Z"
      fill="#CCDAED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.9996 30.3831L134.133 45.0803C136.738 45.9884 138.483 48.4444 138.483 51.2053V96.3241C138.483 127.673 91.9996 144.969 91.9996 144.969C91.9996 144.969 45.5166 127.673 45.5166 96.3241V51.2053C45.5166 48.4444 47.2613 45.9884 49.8665 45.0803L91.9996 30.3831Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.647 103.052H65.3495C63.4973 103.052 61.9971 101.541 61.9971 99.6791V69.3209C61.9971 67.4589 63.4973 65.9478 65.3495 65.9478H117.647C119.497 65.9478 120.999 67.4589 120.999 69.3209V99.6791C120.999 101.541 119.497 103.052 117.647 103.052Z"
      fill="#FBFDFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.312 65.9478H65.6847C63.6481 65.9478 61.9971 67.6006 61.9971 69.6394V74.3194L89.4968 92.1214C90.7154 92.9084 92.2826 92.9084 93.5012 92.1214L120.999 74.3194V69.6394C120.999 67.6006 119.348 65.9478 117.312 65.9478Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.647 103.052H65.3495C63.4973 103.052 61.9971 101.541 61.9971 99.6791V69.3209C61.9971 67.4589 63.4973 65.9478 65.3495 65.9478H117.647C119.497 65.9478 120.999 67.4589 120.999 69.3209V99.6791C120.999 101.541 119.497 103.052 117.647 103.052Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.9971 69.6623V69.9477L89.4968 87.8576C90.7154 88.6528 92.2826 88.6528 93.5012 87.8576L120.999 69.9477V69.6623C120.999 67.611 119.348 65.948 117.312 65.948H65.6847C63.6481 65.948 61.9971 67.611 61.9971 69.6623Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.349 70.2059L120.999 69.798V69.5233C120.999 67.5488 119.348 65.948 117.312 65.948H65.6847C63.6481 65.948 61.9971 67.5488 61.9971 69.5233V69.798L62.6474 70.2059H120.349Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.9971 69.6623V69.9477L89.4968 87.8576C90.7154 88.6528 92.2826 88.6528 93.5012 87.8576L120.999 69.9477V69.6623C120.999 67.611 119.348 65.948 117.312 65.948H65.6847C63.6481 65.948 61.9971 67.611 61.9971 69.6623Z"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.6475 103.052L84.5038 84.8042"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M117.35 103.052L98.4937 84.8042"
      stroke="#9BABC2"
      strokeWidth="1.92151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.999 156.5C128.999 158.434 112.432 160 91.999 160C71.5658 160 54.999 158.434 54.999 156.5C54.999 154.566 71.5658 153 91.999 153C112.432 153 128.999 154.566 128.999 156.5Z"
      fill="#231F20"
      fillOpacity="0.15"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.999 116.597C144.999 129.629 134.478 140.195 121.499 140.195C108.52 140.195 97.999 129.629 97.999 116.597C97.999 103.565 108.52 93 121.499 93C134.478 93 144.999 103.565 144.999 116.597Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.999 116.597C144.999 129.629 134.478 140.195 121.499 140.195C108.52 140.195 97.999 129.629 97.999 116.597C97.999 103.565 108.52 93 121.499 93C134.478 93 144.999 103.565 144.999 116.597Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.89 116.597C139.89 126.796 131.657 135.063 121.498 135.063C111.34 135.063 103.107 126.796 103.107 116.597C103.107 106.397 111.34 98.1301 121.498 98.1301C131.657 98.1301 139.89 106.397 139.89 116.597Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.499 103.259C130.788 103.259 138.446 110.179 139.693 119.162C139.81 118.321 139.891 117.469 139.891 116.597C139.891 106.398 131.656 98.1292 121.499 98.1292C111.341 98.1292 103.108 106.398 103.108 116.597C103.108 117.469 103.187 118.321 103.306 119.162C104.551 110.179 112.211 103.259 121.499 103.259Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.89 116.597C139.89 126.796 131.657 135.063 121.498 135.063C111.34 135.063 103.107 126.796 103.107 116.597C103.107 106.397 111.34 98.1301 121.498 98.1301C131.657 98.1301 139.89 106.397 139.89 116.597Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.128 128.605H113.867C113.193 128.605 112.646 128.059 112.646 127.384V115.786C112.646 115.112 113.193 114.565 113.867 114.565H129.128C129.802 114.565 130.349 115.112 130.349 115.786V127.384C130.349 128.059 129.802 128.605 129.128 128.605Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.128 114.565H113.867C113.193 114.565 112.646 115.112 112.646 115.786V117.923C112.646 117.249 113.193 116.702 113.867 116.702H129.128C129.802 116.702 130.349 117.249 130.349 117.923V115.786C130.349 115.112 129.802 114.565 129.128 114.565Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.128 128.605H113.867C113.193 128.605 112.646 128.059 112.646 127.384V115.786C112.646 115.112 113.193 114.565 113.867 114.565H129.128C129.802 114.565 130.349 115.112 130.349 115.786V127.384C130.349 128.059 129.802 128.605 129.128 128.605Z"
      stroke="#9BABC2"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.999 120.391C122.999 119.623 122.328 119 121.499 119C120.671 119 119.999 119.623 119.999 120.391C119.999 120.844 120.237 121.243 120.599 121.497V124.166C120.599 124.627 121.002 125 121.499 125C121.996 125 122.399 124.627 122.399 124.166V121.497C122.761 121.243 122.999 120.844 122.999 120.391Z"
      fill="#F8FBFF"
    />
    <path
      d="M123.749 120.391C123.749 119.156 122.687 118.25 121.499 118.25C120.311 118.25 119.249 119.156 119.249 120.391C119.249 120.964 119.487 121.468 119.849 121.839V124.166C119.849 125.093 120.642 125.75 121.499 125.75C122.356 125.75 123.149 125.093 123.149 124.166V121.839C123.511 121.468 123.749 120.964 123.749 120.391Z"
      stroke="#9BABC2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.518 109.377C128.518 105.5 125.375 102.357 121.498 102.357C117.62 102.357 114.478 105.5 114.478 109.377V114.566H117.53V109.377C117.53 107.186 119.306 105.409 121.498 105.409C123.689 105.409 125.465 107.186 125.465 109.377V114.566H128.518V109.377Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.498 104.493C124.818 104.493 127.619 106.705 128.518 109.734V109.377C128.518 105.5 125.375 102.357 121.498 102.357C117.62 102.357 114.478 105.5 114.478 109.377V109.734C115.376 106.705 118.177 104.493 121.498 104.493Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.518 109.377C128.518 105.5 125.375 102.357 121.498 102.357C117.62 102.357 114.478 105.5 114.478 109.377V114.566H117.53V109.377C117.53 107.186 119.306 105.409 121.498 105.409C123.689 105.409 125.465 107.186 125.465 109.377V114.566H128.518V109.377Z"
      stroke="#9BABC2"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
