import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="512"
    height="351"
    viewBox="0 0 512 351"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M28.9434 249.978H483.051"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.74707 249.978H17.3018"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M494.699 249.978H509.254"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M354.914 247.926C354.914 255.038 349.185 260.805 342.106 260.805H176.834C169.76 260.805 164.025 255.038 164.025 247.926V31.6669C164.025 24.5543 169.76 18.7822 176.834 18.7822H342.106C349.185 18.7822 354.914 24.5543 354.914 31.6669V247.926Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M354.914 31.6642C354.914 24.5516 349.185 18.7854 342.106 18.7854H176.834C169.76 18.7854 164.025 24.5516 164.025 31.6642V50.9825C164.025 43.8698 169.76 38.1036 176.834 38.1036H342.106C349.185 38.1036 354.914 43.8698 354.914 50.9825V31.6642Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M354.914 247.926C354.914 255.038 349.185 260.805 342.106 260.805H176.834C169.76 260.805 164.025 255.038 164.025 247.926V31.6669C164.025 24.5543 169.76 18.7822 176.834 18.7822H342.106C349.185 18.7822 354.914 24.5543 354.914 31.6669V247.926Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M330.017 229.329C330.017 232.882 327.152 235.774 323.612 235.774H195.321C191.787 235.774 188.923 232.882 188.923 229.329V50.2605C188.923 46.7071 191.787 43.8269 195.321 43.8269H323.612C327.152 43.8269 330.017 46.7071 330.017 50.2605V229.329Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M330.017 229.329C330.017 232.882 327.152 235.774 323.612 235.774H195.321C191.787 235.774 188.923 232.882 188.923 229.329V50.2605C188.923 46.7071 191.787 43.8269 195.321 43.8269H323.612C327.152 43.8269 330.017 46.7071 330.017 50.2605V229.329Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 143.971H255.322"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M296.817 143.971H305.125"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 160.659H255.322"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M296.817 160.659H305.125"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 177.352H255.322"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M296.817 177.352H305.125"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 194.045H255.322"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M296.817 194.045H305.125"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 210.738H255.322"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M296.817 210.738H305.125"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M230.425 77.2043H305.12"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 93.8979H305.122"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 110.585H305.122"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.817 127.278H305.122"
      stroke="#BECFE8"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M280.223 33.5685V22.961C280.223 11.4344 270.931 2.09729 259.474 2.09729C248.016 2.09729 238.724 11.4344 238.724 22.961V33.5685H220.228C216.689 33.5685 213.824 36.4545 213.824 40.0137V52.1725C213.824 55.7259 216.689 58.6119 220.228 58.6119H298.725C302.259 58.6119 305.123 55.7259 305.123 52.1725V40.0137C305.123 36.4545 302.259 33.5685 298.725 33.5685H280.223Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.472 13.6623C270.609 13.6623 279.622 22.496 280.128 33.5659H280.221V22.9643C280.221 11.4319 270.935 2.09473 259.472 2.09473C248.014 2.09473 238.729 11.4319 238.729 22.9643V33.5659H238.822C239.328 22.496 248.34 13.6623 259.472 13.6623Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M232.354 41.9206H213.817V40.0122C213.817 36.4529 216.688 33.5669 220.227 33.5669H240.651C240.651 38.1799 236.936 41.9206 232.354 41.9206Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M286.58 41.9206H305.117V40.0122C305.117 36.4529 302.247 33.5669 298.713 33.5669H278.284C278.284 38.1799 281.999 41.9206 286.58 41.9206Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M280.223 33.5685V22.961C280.223 11.4344 270.931 2.09729 259.474 2.09729C248.016 2.09729 238.724 11.4344 238.724 22.961V33.5685H220.228C216.689 33.5685 213.824 36.4545 213.824 40.0137V52.1725C213.824 55.7259 216.689 58.6119 220.228 58.6119H298.725C302.259 58.6119 305.123 55.7259 305.123 52.1725V40.0137C305.123 36.4545 302.259 33.5685 298.725 33.5685H280.223Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M299.563 193.291L260.236 215.881C257.168 217.649 253.255 216.578 251.503 213.487L241.384 195.685C239.626 192.6 240.697 188.666 243.766 186.898L283.092 164.307C286.161 162.545 290.073 163.617 291.825 166.702L301.944 184.504C303.702 187.589 302.631 191.528 299.563 193.291Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M260.854 196.308L282.47 183.886"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.837 310.765C177.264 308.184 177.304 305.052 175.954 302.429C174.591 299.801 172.018 298.039 169.096 297.728C152.59 295.978 133.617 286.266 123.312 275.694C121.379 273.703 118.637 272.755 115.895 273.112C113.165 273.475 110.749 275.108 109.398 277.532L100.485 293.513C98.4818 297.084 99.2037 301.551 102.208 304.32C114.608 315.735 134.153 326.407 151.257 331.073C158.075 332.928 165.288 329.879 168.717 323.662L175.837 310.765Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.837 310.765C177.264 308.184 177.304 305.052 175.954 302.429C174.591 299.801 172.018 298.039 169.096 297.728C152.59 295.978 133.617 286.266 123.312 275.694C121.379 273.703 118.637 272.755 115.895 273.112C113.165 273.475 110.749 275.108 109.398 277.532L100.485 293.513C98.4818 297.084 99.2037 301.551 102.208 304.32C114.608 315.735 134.153 326.407 151.257 331.073C158.075 332.928 165.288 329.879 168.717 323.662L175.837 310.765Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.84 309.993C137.028 312.305 132.888 311.872 130.595 309.044C128.307 306.211 128.726 302.055 131.544 299.742C134.361 297.436 138.501 297.869 140.783 300.697C143.083 303.53 142.652 307.68 139.84 309.993Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.269 192.284C131.695 189.702 131.742 186.565 130.385 183.948C129.023 181.319 126.449 179.563 123.527 179.247C107.016 177.491 88.0483 167.785 77.7435 157.201C75.8164 155.222 73.0743 154.274 70.3322 154.631C67.5901 154.994 65.1857 156.633 63.835 159.051L54.9158 175.026C52.9131 178.603 53.635 183.07 56.6449 185.839C69.0398 197.254 88.5897 207.926 105.689 212.591C112.506 214.447 119.725 211.397 123.148 205.18L130.269 192.284Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.269 192.284C131.695 189.702 131.742 186.565 130.385 183.948C129.023 181.319 126.449 179.563 123.527 179.247C107.016 177.491 88.0483 167.785 77.7435 157.201C75.8164 155.222 73.0743 154.274 70.3322 154.631C67.5901 154.994 65.1857 156.633 63.835 159.051L54.9158 175.026C52.9131 178.603 53.635 183.07 56.6449 185.839C69.0398 197.254 88.5897 207.926 105.689 212.591C112.506 214.447 119.725 211.397 123.148 205.18L130.269 192.284Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.2832 191.513C91.4712 193.825 87.3318 193.392 85.0322 190.564C82.7442 187.731 83.1692 183.575 85.9812 181.262C88.799 178.956 92.9383 179.383 95.2263 182.211C97.526 185.05 97.0952 189.206 94.2832 191.513Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M387.591 241.277C385.297 239.416 384.097 236.524 384.377 233.585C384.662 230.641 386.397 228.036 388.988 226.648C403.642 218.81 417.632 202.676 423.25 188.989C424.303 186.419 426.492 184.511 429.165 183.809C431.837 183.118 434.684 183.733 436.832 185.471L451.049 196.927C454.228 199.492 455.229 203.905 453.465 207.605C446.211 222.855 432.064 240.101 417.934 250.866C412.299 255.146 404.474 255.034 398.984 250.562L387.591 241.277Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M454.33 339.338C454.33 345.469 367.803 350.436 261.056 350.436C154.322 350.436 67.7822 345.469 67.7822 339.338C67.7822 333.207 154.322 328.233 261.056 328.233C367.803 328.233 454.33 333.207 454.33 339.338Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M387.591 241.277C385.297 239.416 384.097 236.524 384.377 233.585C384.662 230.641 386.397 228.036 388.988 226.648C403.642 218.81 417.632 202.676 423.25 188.989C424.303 186.419 426.492 184.511 429.165 183.809C431.837 183.118 434.684 183.733 436.832 185.471L451.049 196.927C454.228 199.492 455.229 203.905 453.465 207.605C446.211 222.855 432.064 240.101 417.934 250.866C412.299 255.146 404.474 255.034 398.984 250.562L387.591 241.277Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M420.682 227.025C424.146 228.103 427.831 226.153 428.902 222.664C429.974 219.175 428.035 215.47 424.565 214.398C421.095 213.315 417.416 215.265 416.339 218.754C415.268 222.249 417.206 225.937 420.682 227.025Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M323.001 286.474C322.104 283.664 319.857 281.487 317.021 280.691C314.186 279.889 311.147 280.573 308.923 282.511C296.365 293.417 276.35 300.705 261.632 301.033C258.861 301.086 256.299 302.45 254.692 304.71C253.085 306.969 252.625 309.849 253.458 312.495L258.954 329.97C260.176 333.88 263.914 336.403 267.978 336.093C284.745 334.793 305.849 327.757 320.934 318.39C326.942 314.655 329.655 307.279 327.489 300.512L323.001 286.474Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M323.001 286.474C322.104 283.664 319.857 281.487 317.021 280.691C314.186 279.889 311.147 280.573 308.923 282.511C296.365 293.417 276.35 300.705 261.632 301.033C258.861 301.086 256.299 302.45 254.692 304.71C253.085 306.969 252.625 309.849 253.458 312.495L258.954 329.97C260.176 333.88 263.914 336.403 267.978 336.093C284.745 334.793 305.849 327.757 320.934 318.39C326.942 314.655 329.655 307.279 327.489 300.512L323.001 286.474Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M297.826 312.359C297.576 316 294.438 318.746 290.816 318.494C287.195 318.237 284.465 315.081 284.715 311.44C284.971 307.799 288.109 305.053 291.731 305.299C295.352 305.551 298.088 308.712 297.826 312.359Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M381.776 301.349C381.776 294.945 376.612 289.758 370.249 289.758H331.824C325.455 289.758 320.297 294.945 320.297 301.349C320.297 307.748 325.455 312.934 331.824 312.934H370.249C376.612 312.934 381.776 307.748 381.776 301.349Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M331.822 300.303H370.247C374.724 300.303 378.595 302.873 380.505 306.62C381.314 305.039 381.774 303.248 381.774 301.345C381.774 294.947 376.61 289.754 370.247 289.754H331.822C325.453 289.754 320.301 294.947 320.301 301.345C320.301 303.248 320.755 305.039 321.558 306.62C323.468 302.873 327.345 300.303 331.822 300.303Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M381.776 301.349C381.776 294.945 376.612 289.758 370.249 289.758H331.824C325.455 289.758 320.297 294.945 320.297 301.349C320.297 307.748 325.455 312.934 331.824 312.934H370.249C376.612 312.934 381.776 307.748 381.776 301.349Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M416.993 324.526C416.993 318.121 411.829 312.935 405.471 312.935H367.041C360.672 312.935 355.514 318.121 355.514 324.526C355.514 330.924 360.672 336.111 367.041 336.111H405.471C411.829 336.111 416.993 330.924 416.993 324.526Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M367.042 323.485H405.472C409.944 323.485 413.821 326.049 415.725 329.801C416.534 328.221 416.994 326.43 416.994 324.527C416.994 318.123 411.83 312.93 405.472 312.93H367.042C360.673 312.93 355.521 318.123 355.521 324.527C355.521 326.43 355.975 328.221 356.778 329.801C358.688 326.049 362.565 323.485 367.042 323.485Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M416.993 324.526C416.993 318.121 411.829 312.935 405.471 312.935H367.041C360.672 312.935 355.514 318.121 355.514 324.526C355.514 330.924 360.672 336.111 367.041 336.111H405.471C411.829 336.111 416.993 330.924 416.993 324.526Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M355.517 324.526C355.517 318.121 350.353 312.935 343.99 312.935H305.565C299.196 312.935 294.038 318.121 294.038 324.526C294.038 330.924 299.196 336.111 305.565 336.111H343.99C350.353 336.111 355.517 330.924 355.517 324.526Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M305.566 323.485H343.997C348.468 323.485 352.345 326.049 354.249 329.801C355.058 328.221 355.518 326.43 355.518 324.527C355.518 318.123 350.354 312.93 343.997 312.93H305.566C299.197 312.93 294.045 318.123 294.045 324.527C294.045 326.43 294.499 328.221 295.302 329.801C297.212 326.049 301.089 323.485 305.566 323.485Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M355.517 324.526C355.517 318.121 350.353 312.935 343.99 312.935H305.565C299.196 312.935 294.038 318.121 294.038 324.526C294.038 330.924 299.196 336.111 305.565 336.111H343.99C350.353 336.111 355.517 330.924 355.517 324.526Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M216.547 301.349C216.547 294.945 211.383 289.758 205.019 289.758H166.595C160.226 289.758 155.067 294.945 155.067 301.349C155.067 307.748 160.226 312.934 166.595 312.934H205.019C211.383 312.934 216.547 307.748 216.547 301.349Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.596 300.303H205.02C209.497 300.303 213.369 302.873 215.278 306.62C216.088 305.039 216.548 303.248 216.548 301.345C216.548 294.947 211.384 289.754 205.02 289.754H166.596C160.227 289.754 155.074 294.947 155.074 301.345C155.074 303.248 155.528 305.039 156.332 306.62C158.241 302.873 162.119 300.303 166.596 300.303Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M216.547 301.349C216.547 294.945 211.383 289.758 205.019 289.758H166.595C160.226 289.758 155.067 294.945 155.067 301.349C155.067 307.748 160.226 312.934 166.595 312.934H205.019C211.383 312.934 216.547 307.748 216.547 301.349Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.924 324.526C231.924 318.121 226.76 312.935 220.396 312.935H181.972C175.603 312.935 170.444 318.121 170.444 324.526C170.444 330.924 175.603 336.111 181.972 336.111H220.396C226.76 336.111 231.924 330.924 231.924 324.526Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M181.973 323.485H220.397C224.874 323.485 228.746 326.049 230.655 329.801C231.465 328.221 231.925 326.43 231.925 324.527C231.925 318.123 226.761 312.93 220.397 312.93H181.973C175.604 312.93 170.451 318.123 170.451 324.527C170.451 326.43 170.905 328.221 171.709 329.801C173.618 326.049 177.496 323.485 181.973 323.485Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.924 324.526C231.924 318.121 226.76 312.935 220.396 312.935H181.972C175.603 312.935 170.444 318.121 170.444 324.526C170.444 330.924 175.603 336.111 181.972 336.111H220.396C226.76 336.111 231.924 330.924 231.924 324.526Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.431 324.526C170.431 318.121 165.273 312.935 158.91 312.935H120.485C114.116 312.935 108.952 318.121 108.952 324.526C108.952 330.924 114.116 336.111 120.485 336.111H158.91C165.273 336.111 170.431 330.924 170.431 324.526Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.486 323.485H158.911C163.382 323.485 167.259 326.049 169.169 329.801C169.978 328.221 170.432 326.43 170.432 324.527C170.432 318.123 165.274 312.93 158.911 312.93H120.486C114.117 312.93 108.959 318.123 108.959 324.527C108.959 326.43 109.419 328.221 110.222 329.801C112.132 326.049 116.003 323.485 120.486 323.485Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.431 324.526C170.431 318.121 165.273 312.935 158.91 312.935H120.485C114.116 312.935 108.952 318.121 108.952 324.526C108.952 330.924 114.116 336.111 120.485 336.111H158.91C165.273 336.111 170.431 330.924 170.431 324.526Z"
      stroke="#9BABC2"
      strokeWidth="3.89508"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
