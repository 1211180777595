import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const AppInsights = new ApplicationInsights({
  config: {
    instrumentationKey: AppConfig.appinsightsInstrumentationkey,
    autoTrackPageVisitTime: true,
    enableAjaxPerfTracking: true,
    ...(typeof window !== 'undefined' &&
      window?.location?.hostname && {
        enableCorsCorrelation: false,
        correlationHeaderDomains: [window?.location?.hostname],
      }),
  },
});

export default AppInsights;
