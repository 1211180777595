import React from 'react';

export function usePrevious<T>(prevValue: T): T | undefined {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = prevValue;
  });

  return ref.current;
}
