import React from 'react';
import { H5_MEDIUM, SUB_TEXT_BOOK, SUB_TEXT_BOLD } from 'config/typography';

export default function FARegion() {
  return (
    <div style={{ margin: '16px 0' }}>
      <div css={H5_MEDIUM}>National Commercial Services Division</div>
      <div style={{ marginTop: 4 }}>
        <span css={SUB_TEXT_BOOK}>Region BUID: </span>
        <span css={SUB_TEXT_BOLD}>529</span>
      </div>
    </div>
  );
}
