import {
  ChecklistAction,
  IChecklistState,
  ChecklistTypeKeys,
  IChecklistConcurrency,
  OrderChecklistLoadingTypeKeys,
  ILoadingType,
} from './types';

export const initialState: IChecklistState = {
  templateName: '',
  orderType: '',
  serviceType: [],
  visibleTo: [],
  responsibleParty: [],
  tasks: [],
  description: '',
  isChecklistCurrent: {
    isChecklistTaskUpdated: false,
  } as IChecklistConcurrency,
  checklistId: null,
  isLoading: {
    isChecklistDragDropLoading: false,
    isChecklistNameLoading: false,
    isTaskNameLoading: false,
    isDueDateLoading: false,
    isPriorityLoading: false,
    isStatusLoading: false,
    isResponsiblePartyLoading: false,
  },
};

export default (
  state = initialState,
  action: ChecklistAction
): IChecklistState => {
  switch (action.type) {
    case ChecklistTypeKeys.SET_TEMPLATE_NAME:
      return {
        ...state,
        templateName: action.templateName,
      };
    case ChecklistTypeKeys.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case ChecklistTypeKeys.SET_SERVICE_TYPE:
      return {
        ...state,
        serviceType: action.serviceType,
      };
    case ChecklistTypeKeys.SET_VISIBLE_TO:
      return {
        ...state,
        visibleTo: action.visibleTo,
      };
    case ChecklistTypeKeys.SET_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      };
    case ChecklistTypeKeys.SET_RESPONSIBLE_PARTY:
      return {
        ...state,
        responsibleParty: action.responsibleParty,
      };
    case ChecklistTypeKeys.SET_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case ChecklistTypeKeys.CHECKLIST_TASK_UPDATED:
      return {
        ...state,
        isChecklistCurrent: {
          isChecklistTaskUpdated:
            action.isChecklistCurrent.isChecklistTaskUpdated,
        },
      };
    case ChecklistTypeKeys.SET_CHECKLIST_ID:
      return {
        ...state,
        checklistId: action.checklistId,
      };
    case OrderChecklistLoadingTypeKeys.SET_CHECKLIST_NAME_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.CHECKLIST_LOADING]:
            action.isLoading.isChecklistNameLoading,
        },
      };
    case OrderChecklistLoadingTypeKeys.SET_TASK_NAME_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.TASK_NAME_LOADING]: action.isLoading.isTaskNameLoading,
        },
      };
    case OrderChecklistLoadingTypeKeys.SET_DUE_DATE_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.DUE_DATE_LOADING]: action.isLoading.isDueDateLoading,
        },
      };
    case OrderChecklistLoadingTypeKeys.SET_PRIORITY_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.PRIORITY_LOADING]: action.isLoading.isPriorityLoading,
        },
      };
    case OrderChecklistLoadingTypeKeys.SET_STATUS_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.STATUS_LOADING]: action.isLoading.isStatusLoading,
        },
      };
    case OrderChecklistLoadingTypeKeys.SET_RESPONSIBILE_PARTY_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.RESPONSIBILE_PARTY_LOADING]:
            action.isLoading.isResponsiblePartyLoading,
        },
      };
    case OrderChecklistLoadingTypeKeys.SET_CHECKLIST_DRAG_DROP_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [ILoadingType.CHECKLIST_DRAG_DROP_LOADING]:
            action.isLoading.isChecklistDragDropLoading,
        },
      };
    default:
      return state;
  }
};
