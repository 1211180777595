import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width={117}
    height={158}
    viewBox="0 0 117 158"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.718 134.788H10.6293C5.31151 134.788 1 130.468 1 125.14V15.3923C1 10.0641 5.31151 5.7442 10.6293 5.7442H105.718C111.036 5.7442 115.348 10.0641 115.348 15.3923V125.14C115.348 130.468 111.036 134.788 105.718 134.788Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.006 5.84299H10.6557C5.32336 5.84299 1 10.1802 1 15.5296V28.8487C1 23.4992 5.32336 19.1621 10.6557 19.1621H106.006C111.339 19.1621 115.662 23.4992 115.662 28.8487V15.5296C115.662 10.1802 111.339 5.84299 106.006 5.84299Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.194 135.274H11.0879C5.77017 135.274 1.45866 130.954 1.45866 125.626L1.63582 14.9181C1.63582 9.5899 5.94733 5.26996 11.2651 5.26996H106.371C111.688 5.26996 116 9.5899 116 14.9181L115.823 125.626C115.823 130.954 111.511 135.274 106.194 135.274Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113 154C113 156.208 88.8227 158 59 158C29.1773 158 5 156.208 5 154C5 151.792 29.1773 150 59 150C88.8227 150 113 151.792 113 154Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2757 124.504H87.9008H102.384C103.717 124.504 104.798 123.422 104.798 122.083V19.1625C104.798 17.8258 103.717 16.7409 102.384 16.7409H14.2757C12.9432 16.7409 11.8618 17.8258 11.8618 19.1625V122.083C11.8618 123.422 12.9432 124.504 14.2757 124.504Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.384 16.7409H14.2757C12.9432 16.7409 11.8618 17.6635 11.8618 18.8041V30.6654C11.8618 29.5248 12.9432 28.6021 14.2757 28.6021H102.384C103.717 28.6021 104.798 29.5248 104.798 30.6654V18.8041C104.798 17.6635 103.717 16.7409 102.384 16.7409Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2673 124.825H87.6354H102.068C103.396 124.825 104.474 123.738 104.474 122.394V19.1727C104.474 17.8311 103.396 16.656 102.068 16.656H14.2673C12.9395 16.656 11.8618 17.8311 11.8618 19.1727V122.394C11.8618 123.738 12.9395 124.825 14.2673 124.825Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.8664 110.301H96.35V95.7712H81.8664V110.301Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.8664 110.301H96.35V95.7712H81.8664V110.301Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.8664 82.1254H96.35V67.5955H81.8664V82.1254Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.8664 82.1254H96.35V67.5955H81.8664V82.1254Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.3504 47.2619V53.316H81.8668V38.7861H90.3156"
      fill="white"
    />
    <path
      d="M96.3504 47.2619V53.316H81.8668V38.7861H90.3156"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.3197 49.3217L63.9674 51.9128L65.0802 46.9581L61.2783 43.609L66.3186 43.1392L68.3197 38.4751L70.3209 43.1392L75.3612 43.609L71.5592 46.9581L72.6721 51.9128L68.3197 49.3217Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.3197 49.3217L63.9674 51.9128L65.0802 46.9581L61.2783 43.609L66.3186 43.1392L68.3197 38.4751L70.3209 43.1392L75.3612 43.609L71.5592 46.9581L72.6721 51.9128L68.3197 49.3217Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4327 49.3217L43.0804 51.9128L44.1932 46.9581L40.3913 43.609L45.4315 43.1392L47.4327 38.4751L49.4339 43.1392L54.4742 43.609L50.6722 46.9581L51.785 51.9128L47.4327 49.3217Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4327 49.3217L43.0804 51.9128L44.1932 46.9581L40.3913 43.609L45.4315 43.1392L47.4327 38.4751L49.4339 43.1392L54.4742 43.609L50.6722 46.9581L51.785 51.9128L47.4327 49.3217Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5463 49.3217L22.194 51.9128L23.3068 46.9581L19.5049 43.609L24.5452 43.1392L26.5463 38.4751L28.5475 43.1392L33.5878 43.609L29.7858 46.9581L30.8986 51.9128L26.5463 49.3217Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5463 49.3217L22.194 51.9128L23.3068 46.9581L19.5049 43.609L24.5452 43.1392L26.5463 38.4751L28.5475 43.1392L33.5878 43.609L29.7858 46.9581L30.8986 51.9128L26.5463 49.3217Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4327 78.4406L43.0804 81.0317L44.1932 76.077L40.3913 72.7279L45.4315 72.2581L47.4327 67.594L49.4339 72.2581L54.4742 72.7279L50.6722 76.077L51.785 81.0317L47.4327 78.4406Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4327 78.4406L43.0804 81.0317L44.1932 76.077L40.3913 72.7279L45.4315 72.2581L47.4327 67.594L49.4339 72.2581L54.4742 72.7279L50.6722 76.077L51.785 81.0317L47.4327 78.4406Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5463 78.4406L22.194 81.0317L23.3068 76.077L19.5049 72.7279L24.5452 72.2581L26.5463 67.594L28.5475 72.2581L33.5878 72.7279L29.7858 76.077L30.8986 81.0317L26.5463 78.4406Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5463 78.4406L22.194 81.0317L23.3068 76.077L19.5049 72.7279L24.5452 72.2581L26.5463 67.594L28.5475 72.2581L33.5878 72.7279L29.7858 76.077L30.8986 81.0317L26.5463 78.4406Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5463 106.289L22.194 108.881L23.3068 103.926L19.5049 100.577L24.5452 100.107L26.5463 95.4429L28.5475 100.107L33.5878 100.577L29.7858 103.926L30.8986 108.881L26.5463 106.289Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5463 106.289L22.194 108.881L23.3068 103.926L19.5049 100.577L24.5452 100.107L26.5463 95.4429L28.5475 100.107L33.5878 100.577L29.7858 103.926L30.8986 108.881L26.5463 106.289Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3226 10.6866C67.3371 10.4832 67.383 10.2895 67.383 10.0812C67.383 5.06595 63.33 1 58.3307 1C53.3315 1 49.2785 5.06595 49.2785 10.0812C49.2785 10.2895 49.3243 10.4832 49.3388 10.6866H38.4158C37.0833 10.6866 36.0018 11.7715 36.0018 13.1083V22.7949C36.0018 24.1316 37.0833 25.2165 38.4158 25.2165H78.2457C79.5782 25.2165 80.6596 24.1316 80.6596 22.7949V13.1083C80.6596 11.7715 79.5782 10.6866 78.2457 10.6866H67.3226Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.2461 10.6866H67.323C67.3351 10.4832 67.3834 10.2895 67.3834 10.0812C67.3834 5.06595 63.3304 1 58.3311 1C53.3318 1 49.2788 5.06595 49.2788 10.0812C49.2788 10.2895 49.3271 10.4832 49.3392 10.6866H38.4161C37.0836 10.6866 36.0022 11.7715 36.0022 13.1083V16.7407H80.66V13.1083C80.66 11.7715 79.5786 10.6866 78.2461 10.6866Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3226 10.6866C67.3371 10.4832 67.383 10.2895 67.383 10.0812C67.383 5.06595 63.33 1 58.3307 1C53.3315 1 49.2785 5.06595 49.2785 10.0812C49.2785 10.2895 49.3243 10.4832 49.3388 10.6866H38.4158C37.0833 10.6866 36.0018 11.7715 36.0018 13.1083V22.7949C36.0018 24.1316 37.0833 25.2165 38.4158 25.2165H78.2457C79.5782 25.2165 80.6596 24.1316 80.6596 22.7949V13.1083C80.6596 11.7715 79.5782 10.6866 78.2457 10.6866H67.3226Z"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.4879 44.8403L89.1088 48.4727L97.5575 38.7861"
      stroke="#9BABC2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
