/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { System_IO_MemoryStream } from '../models/System_IO_MemoryStream';
import type { Workspace_API_ViewModels_Invoices_AvailableInvoiceFeesViewModel } from '../models/Workspace_API_ViewModels_Invoices_AvailableInvoiceFeesViewModel';
import type { Workspace_API_ViewModels_Invoices_CreateAndRemoveInvoiceFeesViewModel } from '../models/Workspace_API_ViewModels_Invoices_CreateAndRemoveInvoiceFeesViewModel';
import type { Workspace_API_ViewModels_Invoices_CreateInvoiceViewModel } from '../models/Workspace_API_ViewModels_Invoices_CreateInvoiceViewModel';
import type { Workspace_API_ViewModels_Invoices_EmailDeliveryOptionsViewModel } from '../models/Workspace_API_ViewModels_Invoices_EmailDeliveryOptionsViewModel';
import type { Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceResponseViewModel } from '../models/Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceResponseViewModel';
import type { Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceViewModel } from '../models/Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceApprovalStatusViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceApprovalStatusViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceCommentViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceCommentViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceDeliveryViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceDeliveryViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceFeesSequenceViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceFeesSequenceViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceFileAddressBookPartyViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceFileAddressBookPartyViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceHistoryViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceHistoryViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceNoteViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceNoteViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceStatusViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceStatusViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoicesViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoicesViewModel';
import type { Workspace_API_ViewModels_Invoices_InvoiceViewModel } from '../models/Workspace_API_ViewModels_Invoices_InvoiceViewModel';
import type { Workspace_API_ViewModels_Invoices_OrderDetailsViewModel } from '../models/Workspace_API_ViewModels_Invoices_OrderDetailsViewModel';
import type { Workspace_API_ViewModels_Invoices_PaymentDetailsViewModel } from '../models/Workspace_API_ViewModels_Invoices_PaymentDetailsViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderInvoicesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Given an order id, this method will return a list of invoices on the order (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoicesViewModel Order has Invoices
   * @throws ApiError
   */
  public getInvoicesAsync({
    orderId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoicesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Creates a new invoice (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceViewModel Success
   * @throws ApiError
   */
  public createInvoice({
    orderId,
    requestBody,
  }: {
    orderId: number,
    requestBody: Workspace_API_ViewModels_Invoices_CreateInvoiceViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/invoices',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Not a valid business Party`,
        404: `Order Not Found or No permission to order`,
      },
    });
  }

  /**
   * Given an order id and invoice id, will return the details of an invoice (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel Order has Invoices
   * @throws ApiError
   */
  public getInvoiceDetailsAsync({
    orderId,
    invoiceId,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * invoice id for which details will be fetched
     */
    invoiceId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/invoice-detail/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Forbidden`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get all invoice history for an order (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceHistoryViewModel Order has Invoices history
   * @throws ApiError
   */
  public getInvoicesHistoryAsync({
    orderId,
    invoiceId,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * invoice id for which invoice history will be fetched
     */
    invoiceId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceHistoryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/history/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Internal user return`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get available invoice fees /  fees that have not been assigned to an invoice (Auth)
   * @returns Workspace_API_ViewModels_Invoices_AvailableInvoiceFeesViewModel Order has Invoices
   * @throws ApiError
   */
  public getAvailableInvoiceFeesAsync({
    orderId,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_AvailableInvoiceFeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/available-fees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * For a given order and invoice, returns the contents of the invoice preview pdf (Auth)
   * @returns System_IO_MemoryStream Invoice preview pdf was generated
   * @throws ApiError
   */
  public getEmailInvoicePreviewAsync({
    orderId,
    invoiceId,
    buyerSellerLenderFormatFlag = false,
    paymentToInfoFlag = false,
    disclosureSimultaneousPolicyAmountFlag = false,
    disclosureTitlePremiumAdjustmentAmountFlag = false,
  }: {
    orderId: number,
    invoiceId: number,
    buyerSellerLenderFormatFlag?: boolean,
    paymentToInfoFlag?: boolean,
    disclosureSimultaneousPolicyAmountFlag?: boolean,
    disclosureTitlePremiumAdjustmentAmountFlag?: boolean,
  }): CancelablePromise<System_IO_MemoryStream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/preview/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      query: {
        'buyerSellerLenderFormatFlag': buyerSellerLenderFormatFlag,
        'paymentToInfoFlag': paymentToInfoFlag,
        'disclosureSimultaneousPolicyAmountFlag': disclosureSimultaneousPolicyAmountFlag,
        'disclosureTitlePremiumAdjustmentAmountFlag': disclosureTitlePremiumAdjustmentAmountFlag,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the order`,
        404: `Order or invoice not found`,
      },
    });
  }

  /**
   * Provides email capability using the order / file Id and invoice Id. (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceDeliveryViewModel Email is processing
   * @throws ApiError
   */
  public emailInvoiceAsync({
    orderId,
    invoiceId,
    requestBody,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * An invoice Id
     */
    invoiceId: number,
    /**
     * Email delivery options
     */
    requestBody: Workspace_API_ViewModels_Invoices_EmailDeliveryOptionsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceDeliveryViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/invoices/email-invoice/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the order`,
        404: `Order or invoice Not Found`,
      },
    });
  }

  /**
   * Provides image document generation capability using the order / file Id and invoice Id. (Auth)
   * @returns Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceResponseViewModel Image document generation is processing
   * @throws ApiError
   */
  public imageDocumentInvoiceAsync({
    orderId,
    invoiceId,
    requestBody,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * An invoice Id
     */
    invoiceId: number,
    /**
     * Image document generation options
     */
    requestBody: Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_ImageDocumentInvoiceResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/invoices/image-document-invoice/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the order`,
        404: `Order or invoice Not Found`,
      },
    });
  }

  /**
   * Cancel or Finalized a invoice for an order (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceStatusViewModel Invoice got cancelled or Finalized
   * @throws ApiError
   */
  public finalizeAndCancelInvoiceAsync({
    orderId,
    invoiceId,
    invoiceStatus,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * invoice id for which invoice will be cancelled or Finalized
     */
    invoiceId: number,
    /**
     * About InvoiceStatus we want change
     */
    invoiceStatus: string,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceStatusViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/invoices/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      query: {
        'invoiceStatus': invoiceStatus,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
        403: `Return Forbidden`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Given an order id and invoice id, this method will delete the invoice as long as there are no fees associated with the invoice (Auth)
   * @returns boolean Invoice Deleted Successfully
   * @throws ApiError
   */
  public deleteInvoiceAsync({
    orderId,
    invoiceId,
  }: {
    /**
     * The order Id
     */
    orderId: number,
    /**
     * The invoice Id
     */
    invoiceId: number,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/invoices/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Add a new invoice fee (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel Success
   * @throws ApiError
   */
  public addInvoiceFees({
    orderId,
    invoiceId,
    requestBody,
  }: {
    orderId: number,
    invoiceId: number,
    requestBody: Workspace_API_ViewModels_Invoices_CreateAndRemoveInvoiceFeesViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/invoices/add-fees/{invoiceId}',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Not a valid business Party`,
        404: `Order Not Found or No permission to order`,
      },
    });
  }

  /**
   * Removes invoice fees (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel Invoice fee(s) gets removed successfully
   * @throws ApiError
   */
  public removeInvoiceFees({
    orderId,
    invoiceId,
    requestBody,
  }: {
    orderId: number,
    invoiceId: number,
    requestBody: Workspace_API_ViewModels_Invoices_CreateAndRemoveInvoiceFeesViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceDetailsViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/invoices/{invoiceId}/_remove-fees',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Not a valid business Party`,
        404: `Order Not Found or No permission to order`,
      },
    });
  }

  /**
   * Addition Of Comment To A invoice (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceNoteViewModel Invoice Comment Got Added
   * @throws ApiError
   */
  public invoiceCommentAsync({
    orderId,
    invoiceId,
    requestBody,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * invoice id for which comment will be added
     */
    invoiceId: number,
    /**
     * Invoice Comment You Want to add
     */
    requestBody: Workspace_API_ViewModels_Invoices_InvoiceCommentViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_InvoiceNoteViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/invoices/{invoiceId}/comments',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Return Forbidden`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get file details (Auth)
   * @returns Workspace_API_ViewModels_Invoices_OrderDetailsViewModel Order Found
   * @throws ApiError
   */
  public getFileAsync({
    orderId,
    partyId,
    invoiceId,
  }: {
    /**
     * An order Id/file Id
     */
    orderId: number,
    /**
     * A party Id
     */
    partyId: number,
    /**
     * The invoice id
     */
    invoiceId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_OrderDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/file-detail/{partyId}/{invoiceId}',
      path: {
        'orderId': orderId,
        'partyId': partyId,
        'invoiceId': invoiceId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Get all business parties associated with file. (Auth)
   * @returns Workspace_API_ViewModels_Invoices_InvoiceFileAddressBookPartyViewModel Order Found
   * @throws ApiError
   */
  public getFileBusinessPartiesAsync({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_Invoices_InvoiceFileAddressBookPartyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/file-business-party',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * It will return all the payment details of A Invoice.If Invoice is available in notes area (Auth)
   * @returns Workspace_API_ViewModels_Invoices_PaymentDetailsViewModel Invoice Payment Details Found
   * @throws ApiError
   */
  public getInvoicePaymentDetailsAsync({
    orderId,
    invoiceId,
  }: {
    orderId: number,
    invoiceId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Invoices_PaymentDetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/invoices/{invoiceId}/payment-details',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the order`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Addition Of Approval Status To A Invoice (Auth)
   * @returns void
   * @throws ApiError
   */
  public setInvoiceApprovalStatus({
    orderId,
    requestBody,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * Invoice Approval Status you want to add
     */
    requestBody: Workspace_API_ViewModels_Invoices_InvoiceApprovalStatusViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/invoices/set-status',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Return BadRequest`,
        401: `Invalid Authorization header`,
        403: `Return Forbidden`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Update the sequence of the Fees for a given InvoiceId (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateInvoiceFeesSequenceAsync({
    orderId,
    invoiceId,
    requestBody,
  }: {
    /**
     * An order Id/File Id
     */
    orderId: number,
    /**
     * An InvoiceId
     */
    invoiceId: number,
    /**
     * invoiceFeesSequenceViewModel
     */
    requestBody: Array<Workspace_API_ViewModels_Invoices_InvoiceFeesSequenceViewModel>,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/invoices/{invoiceId}/fee-sequence',
      path: {
        'orderId': orderId,
        'invoiceId': invoiceId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Return Forbidden`,
        404: `Order Not Found`,
      },
    });
  }

}
