/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Disbursements_DisbursementSplitDetailViewModel } from '../models/Workspace_API_ViewModels_Disbursements_DisbursementSplitDetailViewModel';
import type { Workspace_API_ViewModels_Disbursements_GetSplitAmountsViewModel } from '../models/Workspace_API_ViewModels_Disbursements_GetSplitAmountsViewModel';
import type { Workspace_API_ViewModels_Disbursements_SplitAmountsViewModel } from '../models/Workspace_API_ViewModels_Disbursements_SplitAmountsViewModel';
import type { Workspace_API_ViewModels_Disbursements_SplitDisbursementViewModel } from '../models/Workspace_API_ViewModels_Disbursements_SplitDisbursementViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderDisbursementSplitsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Splits disbursement (Auth)
   * @returns void
   * @throws ApiError
   */
  public splitDisbursementAsync({
    orderId,
    fileProcessId,
    requestBody,
    disbursementId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * A disbursement File Process Id
     */
    fileProcessId: number,
    /**
     * The object containing all information needed to split the Disbursement
     */
    requestBody: Workspace_API_ViewModels_Disbursements_SplitDisbursementViewModel,
    /**
     * The Id of the disbursement. Optional, used when it's a reliable positive value.
     */
    disbursementId?: number | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_split',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Disbursement is already split`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Returns details of a particular disbursement split (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_DisbursementSplitDetailViewModel Disbursement split details
   * @throws ApiError
   */
  public getDisbursementSplitDetailsAsync({
    orderId,
    fileProcessId,
    disbursementId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * A disbursement File Process Id
     */
    fileProcessId: number,
    /**
     * The Id of the master disbursement.
     */
    disbursementId?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_DisbursementSplitDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/disbursements/{fileProcessId}/_split',
      path: {
        'orderId': orderId,
        'fileProcessId': fileProcessId,
      },
      query: {
        'disbursementId': disbursementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Updates disbursement splits (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateDisbursementSplitsAsync({
    orderId,
    disbursementId,
    requestBody,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The Id of the disbursement.
     */
    disbursementId: number,
    /**
     * The object containing all information needed to update disbursement splits
     */
    requestBody: Workspace_API_ViewModels_Disbursements_SplitDisbursementViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_split',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Disbursement is not split`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Reverts disbursement split (Auth)
   * @returns void
   * @throws ApiError
   */
  public revertDisbursementSplitsAsync({
    orderId,
    disbursementId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * The Id of the master disbursement.
     */
    disbursementId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/disbursements/{disbursementId}/_split',
      path: {
        'orderId': orderId,
        'disbursementId': disbursementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Disbursement is not split`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Returns split amounts (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_SplitAmountsViewModel Success
   * @throws ApiError
   */
  public getDisbursementSplitAmountsAsync({
    requestBody,
  }: {
    /**
     * Parameters to calculate split amounts
     */
    requestBody: Workspace_API_ViewModels_Disbursements_GetSplitAmountsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_SplitAmountsViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/disbursements/_split-amounts',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}
