import React from 'react';

export default () => (
  <svg
    width="172"
    height="190"
    viewBox="0 0 172 190"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.15"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.121 184.272C157.121 186.94 122.477 189.104 79.7418 189.104C37.0069 189.104 2.3623 186.94 2.3623 184.272C2.3623 181.604 37.0069 179.438 79.7418 179.438C122.477 179.438 157.121 181.604 157.121 184.272Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.8424 92.4616C66.8424 75.5609 53.1288 61.8589 36.2137 61.8589C19.2986 61.8589 5.58496 75.5609 5.58496 92.4616V97.293H13.6433L13.6513 121.453H55.5586L55.5692 97.293H66.8424V92.4616Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.8424 92.4616C66.8424 75.5609 53.1288 61.8589 36.2137 61.8589C19.2986 61.8589 5.58496 75.5609 5.58496 92.4616V97.293H13.6433L13.6513 121.453H55.5586L55.5692 97.293H66.8424V92.4616Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6017 10.3154C45.2848 10.3154 53.9466 18.9699 53.9466 29.6439V32.221C55.7729 32.6051 57.1694 34.1472 57.1694 36.0867C57.1694 38.1676 55.57 39.8243 53.5407 40.0351C51.7171 48.8149 43.931 55.4151 34.6017 55.4151C23.9186 55.4151 15.2568 46.7607 15.2568 36.0867V29.6439C15.2568 18.9699 23.9186 10.3154 34.6017 10.3154Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6017 10.3154C45.2848 10.3154 53.9466 18.9699 53.9466 29.6439V32.221C55.7729 32.6051 57.1694 34.1472 57.1694 36.0867C57.1694 38.1676 55.57 39.8243 53.5407 40.0351C51.7171 48.8149 43.931 55.4151 34.6017 55.4151C23.9186 55.4151 15.2568 46.7607 15.2568 36.0867V29.6439C15.2568 18.9699 23.9186 10.3154 34.6017 10.3154Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6494 121.455V171.522C14.1728 171.434 14.7094 171.386 15.2622 171.386H28.1587L29.4297 140.96C29.5205 138.169 31.8088 135.952 34.607 135.952C37.4026 135.952 39.6909 138.169 39.779 140.96L40.7536 171.578C41.3704 171.453 42.0112 171.386 42.6654 171.386H55.562V121.455H13.6494Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6494 121.455V171.522C14.1728 171.434 14.7094 171.386 15.2622 171.386H28.1587L29.4297 140.96C29.5205 138.169 31.8088 135.952 34.607 135.952C37.4026 135.952 39.6909 138.169 39.779 140.96L40.7536 171.578C41.3704 171.453 42.0112 171.386 42.6654 171.386H55.562V121.455H13.6494Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.0514 63.178L44.8245 63.5328L34.6033 79.5745L24.7373 64.0797C28.2859 62.6284 32.1548 61.8574 36.2161 61.8574C39.2787 61.8574 42.2452 62.3083 45.0514 63.178Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.0514 63.178L44.8245 63.5328L34.6033 79.5745L24.7373 64.0797C28.2859 62.6284 32.1548 61.8574 36.2161 61.8574C39.2787 61.8574 42.2452 62.3083 45.0514 63.178Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.019 29.6455C35.6839 29.6455 34.6025 30.726 34.6025 32.0626C34.6025 33.3965 35.6839 34.4769 37.019 34.4769C38.354 34.4769 39.4381 33.3965 39.4381 32.0626C39.4381 30.726 38.354 29.6455 37.019 29.6455Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1271 29.6455C22.7921 29.6455 21.708 30.726 21.708 32.0626C21.708 33.3965 22.7921 34.4769 24.1271 34.4769C25.4622 34.4769 26.5436 33.3965 26.5436 32.0626C26.5436 30.726 25.4622 29.6455 24.1271 29.6455Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0527 38.5035C41.0527 39.8374 42.1341 40.9179 43.4692 40.9179C44.8042 40.9179 45.8883 39.8374 45.8883 38.5035C45.8883 37.1669 44.8042 36.0864 43.4692 36.0864C42.1341 36.0864 41.0527 37.1669 41.0527 38.5035Z"
      fill="#E6EDF7"
    />
    <path
      d="M36.2177 44.2876C33.2886 46.2378 29.473 46.2378 26.5439 44.2876"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6436 24.8124C13.6436 13.2473 23.0263 3.87256 34.6012 3.87256C46.1761 3.87256 55.5588 13.2473 55.5588 24.8124V31.2552C49.8314 29.7105 46.411 26.1996 44.2749 21.5896C35.242 27.6643 23.3173 26.4237 13.6436 24.8124Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6436 24.8124C13.6436 13.2473 23.0263 3.87256 34.6012 3.87256C46.1761 3.87256 55.5588 13.2473 55.5588 24.8124V31.2552C49.8314 29.7105 46.411 26.1996 44.2749 21.5896C35.242 27.6643 23.3173 26.4237 13.6436 24.8124Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.4982 18.3691C47.4982 18.3691 46.2513 20.4527 44.2754 21.5919"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.5625 97.294V87.6284"
      stroke="#1A1816"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6436 97.294V87.6284"
      stroke="#1A1816"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58496 181.05C5.58496 175.711 9.91586 171.384 15.2587 171.384H28.1553V177.827C28.1553 179.607 26.7108 181.05 24.9298 181.05H5.58496Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58496 181.05C5.58496 175.711 9.91586 171.384 15.2587 171.384H28.1553V177.827C28.1553 179.607 26.7108 181.05 24.9298 181.05H5.58496Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9883 181.05C32.9883 175.711 37.3192 171.384 42.6621 171.384H55.5586V177.827C55.5586 179.607 54.1141 181.05 52.3332 181.05H32.9883Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9883 181.05C32.9883 175.711 37.3192 171.384 42.6621 171.384H55.5586V177.827C55.5586 179.607 54.1141 181.05 52.3332 181.05H32.9883Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.3347 73.1334C46.9491 73.1334 39.9855 75.289 34.6025 79.5762L44.8237 63.5345L45.048 63.1797C46.3056 63.5345 47.4965 63.9534 48.6579 64.4682C48.8849 64.7564 49.1412 65.0792 49.3682 65.434C50.786 67.5603 51.9796 70.2654 52.3347 73.1334Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.3347 73.1334C46.9491 73.1334 39.9855 75.289 34.6025 79.5762L44.8237 63.5345L45.048 63.1797C46.3056 63.5345 47.4965 63.9534 48.6579 64.4682C48.8849 64.7564 49.1412 65.0792 49.3682 65.434C50.786 67.5603 51.9796 70.2654 52.3347 73.1334Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6059 79.5763C29.2203 75.2891 22.2567 73.1335 16.8711 73.1335C17.1301 71.0392 17.8403 69.041 18.7428 67.3016C20.6119 66.0131 22.6118 64.9166 24.7399 64.0815L34.6059 79.5763Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6059 79.5763C29.2203 75.2891 22.2567 73.1335 16.8711 73.1335C17.1301 71.0392 17.8403 69.041 18.7428 67.3016C20.6119 66.0131 22.6118 64.9166 24.7399 64.0815L34.6059 79.5763Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6025 79.5762V121.453"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4946 115.568L14.1272 119.477C12.5145 119.76 10.9471 118.788 10.4798 117.22L2.02364 88.73C1.55637 87.1506 2.35473 85.4726 3.87669 84.8403L24.8423 76.1218C26.6099 75.3855 28.6285 76.3459 29.1732 78.1814L39.0312 111.481C39.5759 113.327 38.3904 115.238 36.4946 115.568Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52285 90.1053L26.8689 80.9814C28.0064 80.5172 29.1919 80.7253 30.1024 81.3282L29.1732 78.1828C28.6285 76.3447 26.6099 75.3843 24.8423 76.1233L3.87669 84.8417C2.35473 85.4767 1.55637 87.1521 2.02364 88.7314L2.86205 91.5513C3.23587 90.935 3.78858 90.4041 4.52285 90.1053Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4946 115.568L14.1272 119.477C12.5145 119.76 10.9471 118.788 10.4798 117.22L2.02364 88.73C1.55637 87.1506 2.35473 85.4726 3.87669 84.8403L24.8423 76.1218C26.6099 75.3855 28.6285 76.3459 29.1732 78.1814L39.0312 111.481C39.5759 113.327 38.3904 115.238 36.4946 115.568Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.559 97.2935V99.8706C55.559 105.556 50.9317 110.179 45.2417 110.179C42.4862 110.179 39.8935 109.107 37.9443 107.159L35.3677 104.582C34.3023 103.515 32.8845 102.931 31.3786 102.931C29.87 102.931 28.4521 103.515 27.3894 104.582C25.1866 106.783 25.1866 110.355 27.3894 112.553L29.9687 115.131C34.0487 119.207 39.4743 121.453 45.2417 121.453C57.153 121.453 66.8428 111.769 66.8428 99.8706V97.2935H55.559Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.559 97.2935V99.8706C55.559 105.556 50.9317 110.179 45.2417 110.179C42.4862 110.179 39.8935 109.107 37.9443 107.159L35.3677 104.582C34.3023 103.515 32.8845 102.931 31.3786 102.931C29.87 102.931 28.4521 103.515 27.3894 104.582C25.1866 106.783 25.1866 110.355 27.3894 112.553L29.9687 115.131C34.0487 119.207 39.4743 121.453 45.2417 121.453C57.153 121.453 66.8428 111.769 66.8428 99.8706V97.2935H55.559Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47867 108.414C12.5039 107.672 14.3569 104.617 13.612 101.595C12.8697 98.5721 9.81244 96.7233 6.78721 97.4649L4.43753 98.0412C2.70997 98.4654 1.65261 100.21 2.07716 101.936L3.23064 106.629C3.43357 107.459 3.95958 108.174 4.69118 108.616C5.42012 109.059 6.29858 109.193 7.12899 108.99L9.47867 108.414Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47867 108.414C12.5039 107.672 14.3569 104.617 13.612 101.595C12.8697 98.5721 9.81244 96.7233 6.78721 97.4649L4.43753 98.0412C2.70997 98.4654 1.65261 100.21 2.07716 101.936L3.23064 106.629C3.43357 107.459 3.95958 108.174 4.69118 108.616C5.42012 109.059 6.29858 109.193 7.12899 108.99L9.47867 108.414Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4194 87.6299C9.53024 87.6299 8.80664 88.3502 8.80664 89.2413C8.80664 90.1296 9.53024 90.8526 10.4194 90.8526C11.3085 90.8526 12.0321 90.1296 12.0321 89.2413C12.0321 88.3502 11.3085 87.6299 10.4194 87.6299Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.899 89.24C153.899 74.1161 141.63 61.8574 126.493 61.8574C111.359 61.8574 99.0898 74.1161 99.0898 89.24V94.8772H110.379L110.374 121.454H145.838V98.9056H153.899V89.24Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.819 11.9273C116.819 17.2629 112.489 21.5928 107.146 21.5928C101.806 21.5928 97.4746 17.2629 97.4746 11.9273C97.4746 6.58894 101.806 2.26172 107.146 2.26172C112.489 2.26172 116.819 6.58894 116.819 11.9273Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.819 11.9273C116.819 17.2629 112.489 21.5928 107.146 21.5928C101.806 21.5928 97.4746 17.2629 97.4746 11.9273C97.4746 6.58894 101.806 2.26172 107.146 2.26172C112.489 2.26172 116.819 6.58894 116.819 11.9273Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.101 10.3154C117.418 10.3154 108.756 18.9699 108.756 29.6439V32.221C106.932 32.6051 105.533 34.1472 105.533 36.0867C105.533 38.1676 107.135 39.8243 109.162 40.0351C110.986 48.8149 118.772 55.4151 128.101 55.4151C138.784 55.4151 147.446 46.7607 147.446 36.0867V29.6439C147.446 18.9699 138.784 10.3154 128.101 10.3154Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.101 10.3154C117.418 10.3154 108.756 18.9699 108.756 29.6439V32.221C106.932 32.6051 105.533 34.1472 105.533 36.0867C105.533 38.1676 107.135 39.8243 109.162 40.0351C110.986 48.8149 118.772 55.4151 128.101 55.4151C138.784 55.4151 147.446 46.7607 147.446 36.0867V29.6439C147.446 18.9699 138.784 10.3154 128.101 10.3154Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.373 121.453L110.375 108.568H110.373L110.37 121.453H110.373Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.898 89.24C153.898 74.1161 141.629 61.8574 126.492 61.8574C111.358 61.8574 99.0889 74.1161 99.0889 89.24V94.8771H110.378L110.373 121.454H145.837V98.9056H153.898V89.24Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.683 29.6455C124.348 29.6455 123.264 30.726 123.264 32.0626C123.264 33.3965 124.348 34.4769 125.683 34.4769C127.018 34.4769 128.099 33.3965 128.099 32.0626C128.099 30.726 127.018 29.6455 125.683 29.6455Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.582 29.6455C137.247 29.6455 136.166 30.726 136.166 32.0626C136.166 33.3965 137.247 34.4769 138.582 34.4769C139.918 34.4769 141.002 33.3965 141.002 32.0626C141.002 30.726 139.918 29.6455 138.582 29.6455Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.656 38.5035C121.656 39.8374 120.572 40.9179 119.239 40.9179C117.904 40.9179 116.82 39.8374 116.82 38.5035C116.82 37.1669 117.904 36.0864 119.239 36.0864C120.572 36.0864 121.656 37.1669 121.656 38.5035Z"
      fill="#E6EDF7"
    />
    <path
      d="M126.492 44.2876C129.421 46.2378 133.237 46.2378 136.166 44.2876"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.958 171.386L141.002 153.668H115.206L116.253 171.386H139.958Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.958 171.386L141.002 153.668H115.206L116.253 171.386H139.958Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.101 153.668V171.386"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.039 153.669C142.753 153.669 144.166 152.33 144.259 150.62L145.834 121.455H110.37L111.948 150.62C112.042 152.33 113.454 153.669 115.168 153.669H141.039Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.039 153.669C142.753 153.669 144.166 152.33 144.259 150.62L145.834 121.455H110.37L111.948 150.62C112.042 152.33 113.454 153.669 115.168 153.669H141.039Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.671 181.05C150.671 175.711 146.34 171.384 140.997 171.384H128.101V181.05H150.671Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.671 181.05C150.671 175.711 146.34 171.384 140.997 171.384H128.101V181.05H150.671Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.782 181.05C138.782 175.711 134.451 171.384 129.108 171.384H116.212V177.827C116.212 179.607 117.656 181.05 119.437 181.05H138.782Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.781 181.05C138.781 175.711 134.45 171.384 129.108 171.384H116.211V177.827C116.211 179.607 117.655 181.05 119.436 181.05H138.781Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.369 87.6299V95.6841"
      stroke="#1A1816"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.998 73.1327L142.611 77.9642L128.102 89.2411L137.356 64.1128C140.515 65.4654 143.351 67.3969 145.77 69.7819L145.834 69.91L140.998 73.1327Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.998 73.1327L142.611 77.9642L128.102 89.2411L137.356 64.1128C140.515 65.4654 143.351 67.3969 145.77 69.7819L145.834 69.91L140.998 73.1327Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.595 77.9659L115.207 73.1345L110.369 69.9117L112.919 65.4671C114.657 64.4694 116.494 63.6637 118.462 63.0527L128.104 89.2428L113.595 77.9659Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.595 77.9659L115.207 73.1345L110.369 69.9117L112.919 65.4671C114.657 64.4694 116.494 63.6637 118.462 63.0527L128.104 89.2428L113.595 77.9659Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.355 64.1132C134.034 62.6646 130.325 61.8589 126.491 61.8589C123.684 61.8589 121.006 62.2777 118.459 63.0514L122.173 73.1331H134.034L137.355 64.1132Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.355 64.1132C134.034 62.6646 130.325 61.8589 126.491 61.8589C123.684 61.8589 121.006 62.2777 118.459 63.0514L122.173 73.1331H134.034L137.355 64.1132Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.172 73.1333L128.099 89.2417L134.032 73.1333H122.172Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.172 73.1333L128.099 89.2417L134.032 73.1333H122.172Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.102 87.6299V121.455"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.043 116.193L125.621 110.582C124.321 109.886 123.835 108.267 124.535 106.97L144.168 70.541C144.868 69.2444 146.486 68.7589 147.783 69.4579L158.207 75.0656L136.043 116.193Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.043 116.193L125.621 110.582C124.321 109.886 123.835 108.267 124.535 106.97L144.168 70.541C144.868 69.2444 146.486 68.7589 147.783 69.4579L158.207 75.0656L136.043 116.193Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.345 120.228C149.501 121.794 147.544 122.381 145.976 121.538L136.041 116.192L158.206 75.0645L168.141 80.4108C168.894 80.8163 169.455 81.5019 169.7 82.321C169.946 83.14 169.858 84.0204 169.452 84.7727L150.345 120.228Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.345 120.228C149.501 121.794 147.544 122.381 145.976 121.538L136.041 116.192L158.206 75.0645L168.141 80.4108C168.894 80.8163 169.455 81.5019 169.7 82.321C169.946 83.14 169.858 84.0204 169.452 84.7727L150.345 120.228Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.616 77.0122C146.753 77.0122 145.92 77.4711 145.482 78.2821C144.85 79.4586 145.29 80.9232 146.468 81.5582C146.831 81.7529 147.223 81.8463 147.611 81.8463C148.47 81.8463 149.303 81.3848 149.741 80.5738C150.377 79.3999 149.936 77.9326 148.759 77.3003C148.396 77.1056 148.003 77.0122 147.616 77.0122Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.389 83.8848C159.527 83.8848 158.694 84.3463 158.259 85.1573C157.626 86.3312 158.064 87.7958 159.238 88.4281C159.604 88.6255 159.997 88.7189 160.384 88.7189C161.246 88.7189 162.079 88.2573 162.515 87.4463C163.15 86.2725 162.71 84.8078 161.535 84.1729C161.169 83.9781 160.776 83.8848 160.389 83.8848Z"
      fill="#9BABC2"
    />
    <path
      d="M127.852 100.811L140.625 107.686"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M140.629 107.683L153.403 114.555"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.172 93.0925C157.403 91.6599 153.999 92.7431 152.567 95.5096C151.136 98.2735 152.218 101.675 154.987 103.105L157.133 104.215C157.892 104.61 158.778 104.684 159.593 104.425C160.407 104.167 161.085 103.596 161.478 102.835L163.702 98.5456C164.519 96.9662 163.899 95.0214 162.321 94.205L160.172 93.0925Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.172 93.0925C157.403 91.6599 153.999 92.7431 152.567 95.5096C151.136 98.2735 152.218 101.675 154.987 103.105L157.133 104.215C157.892 104.61 158.778 104.684 159.593 104.425C160.407 104.167 161.085 103.596 161.478 102.835L163.702 98.5456C164.519 96.9662 163.899 95.0214 162.321 94.205L160.172 93.0925Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.374 94.877V99.7111C110.374 101.931 112.181 103.737 114.403 103.737H135.36C138.471 103.737 141.002 106.263 141.002 109.374C141.002 112.482 138.471 115.011 135.36 115.011H114.403C105.96 115.011 99.0898 108.144 99.0898 99.7111V94.877H110.374Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.374 94.877V99.7111C110.374 101.931 112.181 103.737 114.403 103.737H135.36C138.471 103.737 141.002 106.263 141.002 109.374C141.002 112.482 138.471 115.011 135.36 115.011H114.403C105.96 115.011 99.0898 108.144 99.0898 99.7111V94.877H110.374Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.758 32.2229C113.594 28.0344 115.206 18.3715 115.206 18.3715C115.206 18.3715 126.49 26.423 147.448 29.6457V34.76C148.476 32.1775 149.058 29.371 149.058 26.423C149.058 13.9696 138.957 3.87451 126.49 3.87451C114.026 3.87451 103.92 13.9696 103.92 26.423C103.92 29.5364 104.553 32.5003 105.696 35.1975C106.053 33.7008 107.228 32.543 108.758 32.2229Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.758 32.2229C113.594 28.0344 115.206 18.3715 115.206 18.3715C115.206 18.3715 126.49 26.423 147.448 29.6457V34.76C148.476 32.1775 149.058 29.371 149.058 26.423C149.058 13.9696 138.957 3.87451 126.49 3.87451C114.026 3.87451 103.92 13.9696 103.92 26.423C103.92 29.5364 104.553 32.5003 105.696 35.1975C106.053 33.7008 107.228 32.543 108.758 32.2229Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.536 36.0867C105.536 34.1472 104.14 32.6051 102.311 32.221V29.6439C102.311 18.9672 93.6519 10.3154 82.9661 10.3154C72.2831 10.3154 63.6212 18.9672 63.6212 29.6439V32.221C61.7976 32.6051 60.3984 34.1472 60.3984 36.0867C60.3984 38.1676 62.0005 39.8216 64.0298 40.0324C65.8508 48.8149 73.6368 55.4151 82.9661 55.4151C92.2981 55.4151 100.084 48.8149 101.905 40.0324C103.934 39.8216 105.536 38.1676 105.536 36.0867Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.536 36.0867C105.536 34.1472 104.14 32.6051 102.311 32.221V29.6439C102.311 18.9672 93.6519 10.3154 82.9661 10.3154C72.2831 10.3154 63.6212 18.9672 63.6212 29.6439V32.221C61.7976 32.6051 60.3984 34.1472 60.3984 36.0867C60.3984 38.1676 62.0005 39.8216 64.0298 40.0324C65.8508 48.8149 73.6368 55.4151 82.9661 55.4151C92.2981 55.4151 100.084 48.8149 101.905 40.0324C103.934 39.8216 105.536 38.1676 105.536 36.0867Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.7121 29.6455C74.377 29.6455 73.293 30.726 73.293 32.0626C73.293 33.3965 74.377 34.4769 75.7121 34.4769C77.0471 34.4769 78.1312 33.3965 78.1312 32.0626C78.1312 30.726 77.0471 29.6455 75.7121 29.6455Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.2182 29.6455C88.8831 29.6455 87.8018 30.726 87.8018 32.0626C87.8018 33.3965 88.8831 34.4769 90.2182 34.4769C91.5532 34.4769 92.6373 33.3965 92.6373 32.0626C92.6373 30.726 91.5532 29.6455 90.2182 29.6455Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.9027 38.5035C74.9027 39.8374 73.8186 40.9179 72.4836 40.9179C71.1485 40.9179 70.0645 39.8374 70.0645 38.5035C70.0645 37.1669 71.1485 36.0864 72.4836 36.0864C73.8186 36.0864 74.9027 37.1669 74.9027 38.5035Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.86 38.5035C95.86 39.8374 94.7759 40.9179 93.4409 40.9179C92.1058 40.9179 91.0244 39.8374 91.0244 38.5035C91.0244 37.1669 92.1058 36.0864 93.4409 36.0864C94.7759 36.0864 95.86 37.1669 95.86 38.5035Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.919 24.8134C103.919 13.0349 94.1869 3.52678 82.3183 3.88427C70.8475 4.22842 62.0068 14.2248 62.0068 25.6911V31.2562C65.1576 29.1433 67.1868 26.4061 68.5272 23.2901C69.6807 20.6009 72.3935 18.9789 75.2906 19.411C80.4038 20.1714 85.5197 20.1714 90.633 19.411C93.53 18.9789 96.2429 20.6009 97.399 23.2927C98.7367 26.4088 100.766 29.1433 103.919 31.2562V24.8134Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.919 24.8134C103.919 13.0349 94.1869 3.52678 82.3183 3.88427C70.8475 4.22842 62.0068 14.2248 62.0068 25.6911V31.2562C65.1576 29.1433 67.1868 26.4061 68.5272 23.2901C69.6807 20.6009 72.3935 18.9789 75.2906 19.411C80.4038 20.1714 85.5197 20.1714 90.633 19.411C93.53 18.9789 96.2429 20.6009 97.399 23.2927C98.7367 26.4088 100.766 29.1433 103.919 31.2562V24.8134Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.4711 171.386H99.0838C100.846 171.386 102.496 171.858 103.919 172.68V121.455H62.0068V172.68C63.4273 171.858 65.0801 171.386 66.8424 171.386H68.4551C71.9263 171.386 74.9702 173.214 76.6764 175.959L77.7871 140.96C77.8779 138.169 80.1689 135.949 82.9618 135.949C85.7574 135.949 88.0483 138.169 88.1364 140.96L89.2499 175.959C90.9561 173.214 94 171.386 97.4711 171.386Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.4711 171.386H99.0838C100.846 171.386 102.496 171.858 103.919 172.68V121.455H62.0068V172.68C63.4273 171.858 65.0801 171.386 66.8424 171.386H68.4551C71.9263 171.386 74.9702 173.214 76.6764 175.959L77.7871 140.96C77.8779 138.169 80.1689 135.949 82.9618 135.949C85.7574 135.949 88.0483 138.169 88.1364 140.96L89.2499 175.959C90.9561 173.214 94 171.386 97.4711 171.386Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9655 61.8589C64.2695 61.8589 49.1113 77.0015 49.1113 95.6843V100.516C49.1113 108.292 54.6598 114.802 62.0079 116.299V140.781H72.5281C76.9498 140.781 80.9923 138.29 82.9762 134.338C84.9467 138.287 88.9813 140.781 93.3923 140.781H103.923V116.299C111.271 114.799 116.82 108.292 116.82 100.516V95.6843C116.82 77.0015 101.662 61.8589 82.9655 61.8589Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9655 61.8589C64.2695 61.8589 49.1113 77.0015 49.1113 95.6843V100.516C49.1113 108.292 54.6598 114.802 62.0079 116.299V140.781H72.5281C76.9498 140.781 80.9923 138.29 82.9762 134.338C84.9467 138.287 88.9813 140.781 93.3923 140.781H103.923V116.299C111.271 114.799 116.82 108.292 116.82 100.516V95.6843C116.82 77.0015 101.662 61.8589 82.9655 61.8589Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9622 102.124L96.3688 64.6148C92.2569 62.8433 87.7257 61.8589 82.9622 61.8589C78.1988 61.8589 73.6676 62.8433 69.5557 64.6148L82.9622 102.124Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9622 102.124L96.3688 64.6148C92.2569 62.8433 87.7257 61.8589 82.9622 61.8589C78.1988 61.8589 73.6676 62.8433 69.5557 64.6148L82.9622 102.124Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.0221 73.9888L82.9638 61.8608L72.9082 73.9888"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.669 181.05C105.82 181.05 106.885 180.436 107.462 179.438C108.036 178.441 108.038 177.213 107.462 176.216C105.79 173.329 102.664 171.384 99.0829 171.384H97.4702C93.8922 171.384 90.7656 173.329 89.0941 176.216C88.5147 177.213 88.5147 178.443 89.0914 179.441C89.6681 180.439 90.7335 181.05 91.8843 181.05H104.669Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.669 181.05C105.82 181.05 106.885 180.436 107.462 179.438C108.036 178.441 108.038 177.213 107.462 176.216C105.79 173.329 102.664 171.384 99.0829 171.384H97.4702C93.8922 171.384 90.7656 173.329 89.0941 176.216C88.5147 177.213 88.5147 178.443 89.0914 179.441C89.6681 180.439 90.7335 181.05 91.8843 181.05H104.669Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.0428 181.05C75.1936 181.05 76.259 180.436 76.833 179.438C77.4098 178.441 77.4125 177.213 76.8357 176.216C75.1642 173.329 72.0375 171.384 68.4569 171.384H66.8442C63.2663 171.384 60.1396 173.329 58.4681 176.216C57.8887 177.213 57.8887 178.443 58.4654 179.441C59.0422 180.439 60.1075 181.05 61.2584 181.05H74.0428Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.0428 181.05C75.1936 181.05 76.259 180.436 76.833 179.438C77.4098 178.441 77.4125 177.213 76.8357 176.216C75.1642 173.329 72.0375 171.384 68.4569 171.384H66.8442C63.2663 171.384 60.1396 173.329 58.4681 176.216C57.8887 177.213 57.8887 178.443 58.4654 179.441C59.0422 180.439 60.1075 181.05 61.2584 181.05H74.0428Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9622 61.8589L77.4404 86.6804L82.9622 102.124L88.484 86.6804L82.9622 61.8589Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9622 61.8589L77.4404 86.6804L82.9622 102.124L88.484 86.6804L82.9622 61.8589Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.9658 116.623V134.34"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.9658 102.125V103.736"
      stroke="#1A1816"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9658 102.125L100.698 81.1857L97.4751 74.7429H103.923L102.004 67.7185C100.241 66.518 98.3536 65.4828 96.3724 64.6265L82.9658 102.125Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9658 102.125L100.698 81.1857L97.4751 74.7429H103.923L102.004 67.7185C100.241 66.518 98.3536 65.4828 96.3724 64.6265L82.9658 102.125Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9645 102.125L69.5605 64.6265C67.5767 65.4828 65.6916 66.518 63.924 67.7185L62.0068 74.7429H68.4551L65.2323 81.1857L82.9645 102.125Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9645 102.125L69.5605 64.6265C67.5767 65.4828 65.6916 66.518 63.924 67.7185L62.0068 74.7429H68.4551L65.2323 81.1857L82.9645 102.125Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.5194 116.623H65.2355C64.1301 116.623 63.054 116.511 62.0127 116.297"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.0068 87.6299V100.516C62.0068 102.295 63.4514 103.738 65.2323 103.738H76.5162"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.698 103.737C101.883 103.737 102.919 103.096 103.48 102.144C103.491 101.074 103.2 99.991 102.575 99.028C100.882 96.4135 97.3894 95.6638 94.7727 97.3552L86.9707 103.168L87.3392 103.737H100.698Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.698 103.737C101.883 103.737 102.919 103.096 103.48 102.144C103.491 101.074 103.2 99.991 102.575 99.028C100.882 96.4135 97.3894 95.6638 94.7727 97.3552L86.9707 103.168L87.3392 103.737H100.698Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.919 87.6299V100.516C103.919 102.295 102.474 103.738 100.693 103.738H76.5127C72.9588 103.738 70.0645 106.627 70.0645 110.181C70.0645 113.732 72.9588 116.624 76.5127 116.624H100.693C101.801 116.624 102.883 116.512 103.927 116.298"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.262 104.542C66.146 104.542 63.6201 107.065 63.6201 110.179C63.6201 113.292 66.146 115.818 69.262 115.818H74.904V104.542H69.262Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.262 104.542C66.146 104.542 63.6201 107.065 63.6201 110.179C63.6201 113.292 66.146 115.818 69.262 115.818H74.904V104.542H69.262Z"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M87.9208 44.0737C84.9917 46.0239 81.1762 46.0239 78.2471 44.0737"
      stroke="#9BABC2"
      strokeWidth="3.17869"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
