import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouterState } from 'connected-react-router';
import enhanceWithClickOutside from 'react-click-outside';
import { spindle, white } from 'config/theme';
import { ActivityRightValue } from 'store/permissions/types';
import { canUserAccessFeature } from 'store/permissions/helpers';
import { UserType } from 'store/auth/types';
import {
  ChevronDownIcon,
  UserIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { IconButton } from '../LinkIcon';
import AccountMenu from './AccountMenu';
import { IApplicationState } from 'store';
import { isFeatureEnabled } from 'store/features/helpers';
import { PlatformFeatures } from 'store/features/types';
import workspaceAPI from 'services/workspaceAPI';
import { AxiosResponse } from 'axios';
import billingUrls from 'config/urls/billing';

interface IAccountDropdownProps {
  name: string;
  userType: UserType;
  canUserAccessAdminMenu: boolean;
  router: RouterState;
  isResourcesFeatureEnabled: boolean;
}

interface IAccountDropdownState {
  accountDropdownHidden: boolean;
  isBillingEnabled: boolean;
}

class AccountDropdown extends React.Component<
  IAccountDropdownProps,
  IAccountDropdownState
> {
  public accountDropdownNode: HTMLDivElement | null = null;
  public accountDropdownTimeoutId = null;
  public state = {
    accountDropdownHidden: true,
    isBillingEnabled: false,
  };

  public componentDidUpdate(prevProps) {
    const { router: prevRouter } = prevProps;
    const { router } = this.props;

    if (prevRouter !== router) this.setState({ accountDropdownHidden: true });
  }

  public componentDidMount(): void {
    const { userType } = this.props;
    if (userType === UserType.External) {
      this.getUserBillingAccess();
    }
  }

  public render() {
    const {
      name,
      userType,
      canUserAccessAdminMenu,
      isResourcesFeatureEnabled,
    } = this.props;
    const { accountDropdownHidden, isBillingEnabled } = this.state;

    return (
      <Container
        ref={(node) => (this.accountDropdownNode = node)}
        onKeyDown={this.handleOnKeyDown}
        aria-labelledby="user-dropdown-menu"
      >
        <DropdownHandle
          onClick={this.toggleAccountDropdown}
          aria-haspopup="true"
          aria-expanded={!accountDropdownHidden}
          aria-controls="dropdown-menu-list"
          tabIndex={0}
          id="accountBadge"
          role="menuitem"
        >
          <IconButton
            icon={<UserIcon style={{ height: '16px', marginLeft: '-12px' }} />}
            label={
              <React.Fragment>
                Account <ChevronDownIcon style={{ marginLeft: '6px' }} />
              </React.Fragment>
            }
          />
        </DropdownHandle>
        <AccountMenu
          hidden={accountDropdownHidden}
          name={name}
          userType={userType}
          canUserAccessAdminMenu={canUserAccessAdminMenu}
          isResourcesFeatureEnabled={isResourcesFeatureEnabled}
          isBillingEnabled={isBillingEnabled}
        />
      </Container>
    );
  }

  private toggleAccountDropdown = () => {
    this.setState({ accountDropdownHidden: !this.state.accountDropdownHidden });
  };

  private handleOnKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.setState({ accountDropdownHidden: true });
    }
  };

  private getUserBillingAccess = () => {
    workspaceAPI
      .get(billingUrls.canAccessBilling())
      .then((resp: AxiosResponse) =>
        this.setState({ isBillingEnabled: resp.data })
      )
      .catch(() => this.setState({ isBillingEnabled: false }));
  };

  // @ts-ignore /* tslint:disable:no-unused-variable */
  private handleClickOutside = () => {
    this.setState({ accountDropdownHidden: true });
  };
}

export function mapStateToProps(state: IApplicationState) {
  const {
    router,
    auth: { session },
  } = state;
  let name;

  if (session.authenticated) {
    name = `${session.firstName} ${session.lastName}`;
  }

  const canUserAccessAdminMenu: boolean = canUserAccessFeature({
    state,
    acceptedActivityRights: [
      ActivityRightValue.Admin,
      ActivityRightValue.UserManagement,
      ActivityRightValue.SupportAdmin,
      ActivityRightValue.BulkExternalInvite,
    ],
  });

  const isResourcesFeatureEnabled = isFeatureEnabled(
    state,
    PlatformFeatures.Resources
  );

  return {
    canUserAccessAdminMenu,
    name,
    router,
    userType: session.type,
    isResourcesFeatureEnabled: isResourcesFeatureEnabled,
  };
}

export default withRouter(
  connect(mapStateToProps)(enhanceWithClickOutside(AccountDropdown))
);

const Container = styled.div`
  position: relative;
`;

const DropdownHandle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${spindle};
  cursor: pointer;
  &:hover {
    color: ${white};
    fill: ${white};
  }
`;
