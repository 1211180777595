import {
  IChecklistComments,
  IChecklistDocument,
  IChecklistResponsibleParties,
  IOrderChecklistTaskStatus,
} from 'views/OrderChecklist/types';

export enum ChecklistTypeKeys {
  SET_TEMPLATE_NAME = 'checklist/SET_TEMPLATE_NAME',
  SET_ORDER_TYPE = 'checklist/SET_ORDER_TYPE',
  SET_SERVICE_TYPE = 'checklist/SET_SERVICE_TYPE',
  SET_VISIBLE_TO = 'checklist/SET_VISIBLE_TO',
  SET_RESPONSIBLE_PARTY = `checklist/SET_RESPONSIBLE_PARTY`,
  SET_DESCRIPTION = 'checklist/SET_DESCRIPTION',
  SET_TASKS = 'checklist/SET_TASKS',
  CHECKLIST_TASK_UPDATED = 'checklist/CHECKLIST_TASK_UPDATED',
  SET_CHECKLIST_ID = 'checklist/SET_CHECKLIST_ID',
}

export enum OrderChecklistLoadingTypeKeys {
  SET_CHECKLIST_DRAG_DROP_LOADING = 'checklist/SET_CHECKLIST_DRAG_DROP_LOADING',
  SET_CHECKLIST_NAME_LOADING = 'checklist/SET_CHECKLIST_NAME_LOADING',
  SET_TASK_NAME_LOADING = 'checklist/SET_TASK_NAME_LOADING',
  SET_DUE_DATE_LOADING = 'checklist/SET_DUE_DATE_LOADING',
  SET_PRIORITY_LOADING = 'checklist/SET_PRIORITY_LOADING',
  SET_STATUS_LOADING = 'checklist/SET_STATUS_LOADING',
  SET_RESPONSIBILE_PARTY_LOADING = 'checklist/SET_RESPONSIBILE_PARTY_LOADING',
}

export enum ILoadingType {
  CHECKLIST_DRAG_DROP_LOADING = 'isChecklistDragDropLoading',
  CHECKLIST_LOADING = 'isChecklistNameLoading',
  TASK_NAME_LOADING = 'isTaskNameLoading',
  DUE_DATE_LOADING = 'isDueDateLoading',
  PRIORITY_LOADING = 'isPriorityLoading',
  STATUS_LOADING = 'isStatusLoading',
  RESPONSIBILE_PARTY_LOADING = 'isResponsiblePartyLoading',
}

// eslint-disable-next-line import/no-unused-modules
export type ChecklistAction =
  | IChecklistSetTemplateName
  | IChecklistSetOrderType
  | IChecklistSetServiceType
  | IChecklistSetDescription
  | IChecklistSetResponsibleParty
  | IChecklistSetVisibleTo
  | IChecklistIsTaskUpdated
  | IChecklistSetChecklistId
  | IOrderChecklistsSetTasks
  | IOrderSetChecklistTaskLoadingState;

export interface IChecklistState {
  templateName: string | null;
  orderType: string | null;
  responsibleParty: IChecklistResponsibleParty[];
  serviceType: IServiceType[];
  visibleTo: any[];
  tasks: IChecklistTasks[];
  description: string | null;
  isChecklistCurrent: IChecklistConcurrency;
  checklistId: number | null;
  isLoading: IOrderChecklistTaskLoadingState;
}

// eslint-disable-next-line import/no-unused-modules
export interface IChecklistSetTemplateName {
  type: ChecklistTypeKeys.SET_TEMPLATE_NAME;
  templateName: string | null;
}

export interface IChecklistSetOrderType {
  type: ChecklistTypeKeys.SET_ORDER_TYPE;
  orderType: string | null;
}

export interface IChecklistSetServiceType {
  type: ChecklistTypeKeys.SET_SERVICE_TYPE;
  serviceType: IServiceType[];
}
export interface IChecklistSetVisibleTo {
  type: ChecklistTypeKeys.SET_VISIBLE_TO;
  visibleTo: any[];
}

// eslint-disable-next-line import/no-unused-modules
export interface IChecklistSetResponsibleParty {
  type: ChecklistTypeKeys.SET_RESPONSIBLE_PARTY;
  responsibleParty: IChecklistResponsibleParty[];
}

export interface IOrderChecklistsSetTasks {
  type: ChecklistTypeKeys.SET_TASKS;
  tasks: IChecklistTasks[];
}

export interface IChecklistSetDescription {
  type: ChecklistTypeKeys.SET_DESCRIPTION;
  description: string | null;
}

export interface IChecklistIsTaskUpdated {
  type: ChecklistTypeKeys.CHECKLIST_TASK_UPDATED;
  isChecklistCurrent: {
    isChecklistTaskUpdated: boolean;
  };
}

export interface IChecklistSetChecklistId {
  type: ChecklistTypeKeys.SET_CHECKLIST_ID;
  checklistId: number | null;
}

export interface IOrderSetChecklistTaskLoadingState {
  type:
    | OrderChecklistLoadingTypeKeys.SET_CHECKLIST_DRAG_DROP_LOADING
    | OrderChecklistLoadingTypeKeys.SET_CHECKLIST_NAME_LOADING
    | OrderChecklistLoadingTypeKeys.SET_TASK_NAME_LOADING
    | OrderChecklistLoadingTypeKeys.SET_DUE_DATE_LOADING
    | OrderChecklistLoadingTypeKeys.SET_PRIORITY_LOADING
    | OrderChecklistLoadingTypeKeys.SET_STATUS_LOADING
    | OrderChecklistLoadingTypeKeys.SET_RESPONSIBILE_PARTY_LOADING;
  isLoading: {
    [key in keyof IOrderChecklistTaskLoadingState]: boolean;
  };
}

interface IServiceType {
  value: number;
  label: string;
  isDisabled?: boolean;
  tooltipContent?: string;
}

interface IChecklistResponsibleParty {
  name: string;
  label: string;
  value: number;
  valueId?: number;
  partyID?: number;
  email?: string;
  taskstatus?: string;
  sourceType?: string;
}

export interface IChecklistTasks {
  id: number;
  name: string;
  status: IOrderChecklistTaskStatus;
  priority: boolean;
  dueDate: string;
  isNameEditable: boolean;
  documents: IChecklistDocument[];
  comments: IChecklistComments[];
  responsibleParties: IChecklistResponsibleParties[];
  responsiblePartyVersion: string;
  sequenceNo: number;
  unreadCommentCount: number;
  sequenceVersion: string;
  canDelete: boolean;
  taskVersion: string;
}
export interface IChecklistConcurrency {
  isChecklistTaskUpdated: boolean;
}

interface IOrderChecklistTaskLoadingState {
  [ILoadingType.CHECKLIST_DRAG_DROP_LOADING]?: boolean;
  [ILoadingType.CHECKLIST_LOADING]?: boolean;
  [ILoadingType.TASK_NAME_LOADING]?: boolean;
  [ILoadingType.DUE_DATE_LOADING]?: boolean;
  [ILoadingType.PRIORITY_LOADING]?: boolean;
  [ILoadingType.STATUS_LOADING]?: boolean;
  [ILoadingType.RESPONSIBILE_PARTY_LOADING]?: boolean;
}
