import React from 'react';
import styled from 'styled-components';
import {
  CalendarPlusIcon,
  CalendarCheckmarkIcon,
  CCardIcon,
  MoneyBagIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { havelockBlue, lightBlue, trout } from 'config/theme';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/numbers';
import { AttributesContainer, Attribute } from '../Attribute';

interface ILeftContentProps {
  estimatedCloseDate: string;
  lenderLiability: number;
  openDate: string | null;
  ownerLiability: number;
}

const LeftContent = ({
  estimatedCloseDate,
  lenderLiability,
  openDate,
  ownerLiability,
}: ILeftContentProps) => (
  <Container>
    <AttributesContainer direction="column">
      <Attribute
        classSelectorName="site-card-open-date"
        iconFillColor={havelockBlue}
        iconHeight={19}
        iconSvgComponent={CalendarPlusIcon}
        iconWidth={18}
        labelText="Opened"
        labelTextSize={14}
        valueText={formatDate(openDate)}
        valueTextSize={16}
      />
      <Attribute
        classSelectorName="site-card-estimated-closing"
        iconFillColor={lightBlue}
        iconHeight={19}
        iconSvgComponent={CalendarCheckmarkIcon}
        iconWidth={18}
        labelText="Est. Closing"
        labelTextSize={14}
        valueText={formatDate(estimatedCloseDate)}
        valueTextSize={16}
      />

      <Attribute
        classSelectorName="site-card-lender-liability"
        iconFillColor={trout}
        iconHeight={18}
        iconSvgComponent={CCardIcon}
        iconWidth={18}
        labelText="Lender Liability"
        labelTextSize={14}
        valueText={formatMoney(lenderLiability)}
        valueTextSize={16}
      />

      <Attribute
        classSelectorName="site-card-owner-liability"
        iconFillColor={trout}
        iconHeight={20}
        iconSvgComponent={MoneyBagIcon}
        iconWidth={15}
        labelText="Owner Liability"
        labelTextSize={14}
        valueText={formatMoney(ownerLiability)}
        valueTextSize={16}
      />
    </AttributesContainer>
  </Container>
);
LeftContent.displayName = 'LeftContent';

export default LeftContent;

const Container = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  flex: 0 0 290px;
  height: 100%;
`;
