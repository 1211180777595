/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_CurrentUser_RegionsAndOfficesViewModel } from '../models/Workspace_API_ViewModels_CurrentUser_RegionsAndOfficesViewModel';
import type { Workspace_Common_Models_SessionResponse } from '../models/Workspace_Common_Models_SessionResponse';
import type { Workspace_Common_Models_User } from '../models/Workspace_Common_Models_User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CurrentUserService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve information about the current user given in the Authorization header
   * @returns Workspace_Common_Models_SessionResponse User successfully retrieved
   * @throws ApiError
   */
  public getLoggedInUserAsync({
    refresh = false,
  }: {
    /**
     * Boolean for whether or not to refresh the session timeout of the current user
     */
    refresh?: boolean,
  }): CancelablePromise<Workspace_Common_Models_SessionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/_me',
      query: {
        'refresh': refresh,
      },
    });
  }

  /**
   * Puts current user information from mobile. (Auth)
   * This endpoint is for mobile only. It runs user synchronization operations and returns current user information.
   * @returns Workspace_Common_Models_SessionResponse User successfully retrieved and updated
   * @throws ApiError
   */
  public putCurrentUserAsync(): CancelablePromise<Workspace_Common_Models_SessionResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/_me',
      errors: {
        401: `Invalid Authorization header`,
        404: `User does not exist`,
      },
    });
  }

  /**
   * Sync WS DB with Mesh information (Auth)
   * @returns Workspace_Common_Models_User User successfully retrieved and updated
   * @throws ApiError
   */
  public syncEmployeeAsync({
    q,
  }: {
    /**
     * Email address used to find the user.
     */
    q: string,
  }): CancelablePromise<Workspace_Common_Models_User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/_me/sync',
      query: {
        'q': q,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `User does not exist`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns Workspace_API_ViewModels_CurrentUser_RegionsAndOfficesViewModel Success
   * @throws ApiError
   */
  public getRegionsAndOffices({
    activityRightId,
  }: {
    activityRightId: number,
  }): CancelablePromise<Workspace_API_ViewModels_CurrentUser_RegionsAndOfficesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/_me/regions/{activityRightId}',
      path: {
        'activityRightId': activityRightId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}
