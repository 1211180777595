import { IAuthState, UserType } from './types';
import { IUser } from 'store/users/types';

export function isInternalUser(authState: IAuthState): boolean {
  const {
    session: { type: userType },
  } = authState;
  return userType === UserType.Internal;
}

export function isCurrentUser(authState: IAuthState, user?: IUser): boolean {
  const email = authState && authState.session && authState.session.email;
  const userEmail = user && user.email;
  return email && userEmail ? email === userEmail : false;
}
