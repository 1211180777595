import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M9.4464 11.0346H3.56403V3.54792H9.4464V11.0346ZM9.4464 2.4784H3.56403C3.28038 2.4784 3.00834 2.59108 2.80777 2.79165C2.60719 2.99223 2.49451 3.26427 2.49451 3.54792V11.0346C2.49451 11.3182 2.60719 11.5903 2.80777 11.7908C3.00834 11.9914 3.28038 12.1041 3.56403 12.1041H9.4464C9.73006 12.1041 10.0021 11.9914 10.2027 11.7908C10.4032 11.5903 10.5159 11.3182 10.5159 11.0346V3.54792C10.5159 3.26427 10.4032 2.99223 10.2027 2.79165C10.0021 2.59108 9.73006 2.4784 9.4464 2.4784V2.4784ZM7.84212 0.339355H1.42499C1.14134 0.339355 0.869298 0.452037 0.668724 0.652611C0.46815 0.853185 0.355469 1.12522 0.355469 1.40888V8.89553H1.42499V1.40888H7.84212V0.339355Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
