import { FarmShapeType } from 'store/propertySearch/constants';

export enum PropertySearchTypeKeys {
  ENTITY = '[propertySearch]',
  // Generic actions used to update current request state
  REQUEST = 'propertySearch/REQUEST',
  SUCCESS = 'propertySearch/SUCCESS',
  ERROR = 'propertySearch/ERROR',
  // Store different types of data received via XHR
  SET_PROPERTY_DETAILS = 'propertySearch/SET_PROPERTY_DETAILS',
  SET_SELECTED_PROPERTY_DETAIL = 'propertySearch/SET_SELECTED_PROPERTY_DETAIL',
  SET_HOVERED_PROPERTY_DETAIL = 'propertySearch/SET_HOVERED_PROPERTY_DETAIL',
  SET_PARENT_DETAILS = 'propertySearch/SET_PARENT_DETAILS',
  GET_PROPERTY_DOCUMENTS = 'propertySearch/documents/GET_PROPERTY_DOCUMENTS',
  SET_PROPERTY_DOCUMENTS = 'propertySearch/documents/SET_PROPERTY_DOCUMENTS',
  RECEIVE_PROPERTY_DOCUMENT_DOWNLOAD_RESULT = 'propertysearch/RECEIVE_PROPERTY_DOCUMENT_DOWNLOAD_RESULT',
  GET_PROPERTY_DOCUMENTS_DATA = 'propertySearch/documents/GET_PROPERTY_DOCUMENTS_DATA',
  UPDATE_ACTIVE_INDEX = 'propertySearch/UPDATE_ACTIVE_INDEX',
  SET_CHECKED_PROPERTY_IDS = 'propertySearch/SET_CHECKED_PROPERTY_IDS',
  SET_ACTUAL_RESULTS_COUNT = 'propertySearch/SET_ACTUAL_RESULTS_COUNT',
  GET_STATES_COUNTIES = 'propertySearch/GET_STATES_COUNTIES',
  SET_PROPERTY_SEARCH_REQUEST = 'propertySearch/SET_PROPERTY_SEARCH_REQUEST',
  GET_LAND_USE_TYPES = 'propertySearch/GET_LAND_USE_TYPES',
  SET_PROPERTY_SEARCH_FILTERS = 'propertySearch/SET_PROPERTY_SEARCH_FILTERS',
  SET_USER_ACCEPTED_PAYMENT_POLICY = 'propertySearch/SET_USER_ACCEPTED_PAYMENT_POLICY',
  SET_ADVANCED_SEARCH_FIELDS = 'propertySearch/SET_ADVANCED_SEARCH_FIELDS',
  SET_CMT_DOCUMENT_PROFILES_BY_STATE = 'propertySearch/SET_PUBLIC_RECORDS_CMT_DOCUMENTS_BY_STATE',
  SET_PROPERTY_SEARCH_CLEARED = 'propertySearch/SET_PROPERTY_SEARCH_CLEARED',
}

export enum SortingField {
  PROPERTY_ADDRESS = 'address',
  PROPERTY_OWNER = 'owner',
  PROPERTY_APN = 'apn',
  DEFAULT = '',
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPropertySearchState {
  loading: boolean;
  error: Error | null;
  propertyDetails: IPropertyDetailsResponse | null;
  selectedPropertyDetail: IPropertyDetails | null;
  hoveredPropertyDetail: IPropertyDetails | null;
  parentDetails: IParentDetails;
  propertyDocuments: IPropertyDocument[];
  propertyDocumentData: IPropertyDocumentData | null;
  activeIndex: number;
  checkedPropertyIds: string[];
  actualResultsCount: number;
  statesCounties: IStateCounty[];
  propertySearchRequest: IPropertySearchRequest | null;
  landUseTypes: ILandUseTypes[];
  filters: IPropertyFilters | null;
  isUserAcceptedPaymentPolicy: boolean;
  advancedSearchFields: IAdvancedSearchFields | null;
  cmtDocumentProfilesByState: ICmtDocumentProfilesByState[];
}

export type PropertySearchActions =
  | IPropertySearchSuccessAction
  | IPropertySearchErrorAction
  | IPropertySearchRequestAction
  | IPropertySearchSetPropertyDetails
  | IPropertySearchSetSelectedPropertyDetail
  | IPropertySearchSetHoveredPropertyDetail
  | IPropertySearchSetParentDetails
  | IPropertyDocumentsAction
  | IPropertyDocumentsDataAction
  | IUpdateActiveIndexAction
  | ISetCheckedPropertyIds
  | ISetActualResultsCount
  | ISetPropertySearchRequest
  | IGetLandUseTypes
  | ISetPropertySearchFilters
  | IGetStatesCounties
  | ISetUserAcceptedPaymentPolicy
  | ISetAdvancedSearchFields
  | ISetPublicRecordsCmtDocumentProfilesByState;

interface IPropertySearchSuccessAction {
  type: PropertySearchTypeKeys.SUCCESS;
}

interface IPropertySearchErrorAction {
  type: PropertySearchTypeKeys.ERROR;
  error: Error;
}

interface IPropertySearchRequestAction {
  type: PropertySearchTypeKeys.REQUEST;
}

interface IPropertyDocumentsAction {
  type:
    | PropertySearchTypeKeys.GET_PROPERTY_DOCUMENTS
    | PropertySearchTypeKeys.SET_PROPERTY_DOCUMENTS;
  documents: IPropertyDocument[];
}

interface IPropertySearchSetPropertyDetails {
  type: PropertySearchTypeKeys.SET_PROPERTY_DETAILS;
  details: IPropertyDetailsResponse;
}

interface IPropertySearchSetSelectedPropertyDetail {
  type: PropertySearchTypeKeys.SET_SELECTED_PROPERTY_DETAIL;
  selectedPropertyDetail: IPropertyDetails;
}

interface IPropertySearchSetHoveredPropertyDetail {
  type: PropertySearchTypeKeys.SET_HOVERED_PROPERTY_DETAIL;
  hoveredPropertyDetail: IPropertyDetails;
}

interface IPropertySearchSetParentDetails {
  type: PropertySearchTypeKeys.SET_PARENT_DETAILS;
  parentDetails: IParentDetails;
}

interface IPropertyDocumentsDataAction {
  type: PropertySearchTypeKeys.GET_PROPERTY_DOCUMENTS_DATA;
  propertyDocumentData: IPropertyDocumentData;
}

interface IUpdateActiveIndexAction {
  type: PropertySearchTypeKeys.UPDATE_ACTIVE_INDEX;
  activeIndex: number;
}

export interface ISetCheckedPropertyIds {
  type: PropertySearchTypeKeys.SET_CHECKED_PROPERTY_IDS;
  checkedPropertyIds: string[];
}

export interface ISetActualResultsCount {
  type: PropertySearchTypeKeys.SET_ACTUAL_RESULTS_COUNT;
  actualResultsCount: number;
}

interface IGetStatesCounties {
  type: PropertySearchTypeKeys.GET_STATES_COUNTIES;
  statesCounties: IStateCounty[];
}

export interface ISetPropertySearchRequest {
  type: PropertySearchTypeKeys.SET_PROPERTY_SEARCH_REQUEST;
  request: IPropertySearchRequest | null;
}

interface IGetLandUseTypes {
  type: PropertySearchTypeKeys.GET_LAND_USE_TYPES;
  landUseTypes: ILandUseTypes[];
}

export interface ISetPropertySearchFilters {
  type: PropertySearchTypeKeys.SET_PROPERTY_SEARCH_FILTERS;
  filters: IPropertyFilters | null;
}

export interface ISetUserAcceptedPaymentPolicy {
  type: PropertySearchTypeKeys.SET_USER_ACCEPTED_PAYMENT_POLICY;
  isUserAcceptedPaymentPolicy: boolean;
}

interface ISetAdvancedSearchFields {
  type: PropertySearchTypeKeys.SET_ADVANCED_SEARCH_FIELDS;
  advancedSearchFields: IAdvancedSearchFields;
}

interface ISetPublicRecordsCmtDocumentProfilesByState {
  type: PropertySearchTypeKeys.SET_CMT_DOCUMENT_PROFILES_BY_STATE;
  cmtDocumentProfilesByState: ICmtDocumentProfilesByState[];
}

export interface IParentDetails {
  zoom: number | null;
  lat: number | null;
  lng: number | null;
  bounds: any;
  address: string;
}

export interface IPropertySearchRequest {
  fullAddress: string | null;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  types: string[] | null;
  county: string | null;
  locationType: google.maps.GeocoderLocationType | null;
  spatialType?: string;
  farmShapes?: IFarmShape[];
  searchType?: number;
  apn?: string;
  owner1LastName?: string;
  owner1FirstNameMiddleInitial?: string;
  streetNumber?: string;
  streetName?: string;
  unit?: string;
  filters?: IPropertyFiltersRequest | null;
  propertyIds?: string[];
}

export interface IFarmShape {
  id?: number;
  shapeType: FarmShapeType;
  shapes: string;
}

export interface IPropertyDetailsResponse {
  maxResultsCount: number;
  referenceId: number;
  orderItemId: number;
  litePropertyList: IPropertyDetails[];
}

export interface IPropertyDetails {
  sequenceId: string;
  propertyId: string;
  apn: string;
  latitude: string;
  longitude: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  streetName: string;
  streetType: string;
  streetNumber: string;
  streetDir: string;
  streetPostDir: string;
  sqFoot: string;
  yearBuilt: string;
  unit: string;
  landUseDescription: string;
  lotSize: string;
  zone: string;
  owner: string;
  country: string;
  isSelected: boolean;
  parcelWKT: string;
}

export interface IPropertyDocument {
  id: number;
  propertyProductName: string;
  productPrice: number;
  monthlyAllowanceThreshold: number;
  isCombinedReport: boolean;
  isPriceApplicable: boolean;
  isProductAvailable: boolean;
  propertyProductUsageDetail: IPropertyProductUsageDetail;
  includeInCombinedReport: boolean;
}

export interface IPropertyProductUsageDetail {
  monthToDateUsage: number;
  productId: number;
  isCached: boolean;
  monthlyAllowanceThreshold?: number;
  availableAllowance?: number;
  shouldAlertUserOnAvailableAllowance?: boolean;
}

interface IApnDetails {
  apn: string;
  zipCode: string;
  stateCode?: string;
  county?: string;
  stateFips?: string;
  countyFips?: string;
}

interface IAddressDetail {
  streetName: string;
  streetNumber: string;
  preDirection?: string;
  streetType?: string;
  postDirection?: string;
  unitNumber?: string;
  stateCode?: string;
  stateFips?: string;
  county?: string;
  countyFips?: string;
  city: string;
  zipCode: string;
}

interface IPropertyInfo {
  apnDetails: IApnDetails;
  addressDetail: IAddressDetail;
}

export interface ICombinedReportOptions {
  hasPropertyProfile: boolean;
  hasTaxMap: boolean;
  hasTransactionHistory: boolean;
  hasComparableSales: boolean;
}

interface IReportOptions {
  isDocumentIncluded: boolean;
  combinedReportOptions?: ICombinedReportOptions;
  ComparableSalesOptions?: IComparableSalesOptions;
}

interface IComparableSalesOptions {
  numberMonthsBack: number;
  livingAreaDiffPercentage: number;
  prMaxDistance: number;
  totalNumberOfBedrooms: IRange;
  totalNumberOfbathrooms: IRange;
}

interface IRange {
  low: number;
  high: number;
}

export interface IRecordedDocumentDownloadRequest {
  referenceId: string;
  documentDetail: IDocumentDetail;
  apnDetail?: IApnDetails;
  addressDetail?: IAddressDetail;
}

interface IDocumentDetail {
  documentNumber: string;
  documentNumberCmtId: string;
  countyFips: number;
  state: string | null;
}

export interface IPropertyDocumentDownloadRequest {
  reportId: number;
  propertyId?: number;
  propertyInfo?: IPropertyInfo;
  reportOptions?: IReportOptions;
}

export interface IPropertyDocumentData {
  propertyAddressData?: IPropertyAddressData;
  propertyProfileData?: IPropertyProfileData;
  taxMapData?: ITaxMapData;
  comparableSalesData?: IComparableSalesData;
  transactionHistoryData?: ITransactionHistoryData;
}

interface IComparableSalesData {
  comparablePropertiesSummary: IComparablePropertiesSummary;
  comparablesStatisticsData: IComparablesStatisticsData;
  comparableProperties: IComparableProperty[];
  comparableSalesMapAsBase64: string;
  reportStatus?: string;
}

interface IComparableProperty {
  id: number;
  distanceFromSubject: string;
  locationInformation: ILocationInformation;
  situsAddress: ISitusAddress;
  propertyCharacteristics: IPropertyCharacteristics;
  lastMarketSaleInformation: ILastMarketSaleInformation;
}

interface IComparablePropertiesSummary {
  salePrice: IComparablePropertiesStatistics;
  loanAmount: IComparablePropertiesStatistics;
  bedrooms: IComparablePropertiesStatistics;
  bathrooms: IComparablePropertiesStatistics;
  livingArea: IComparablePropertiesStatistics;
  pricePerSqFt: IComparablePropertiesStatistics;
}

interface IComparablePropertiesStatistics {
  id: number;
  label: string;
  average: string;
  high: string;
  low: string;
}

interface IComparablesStatisticsData {
  id: number;
  apn: string;
  propertyAddress: string;
  salePrice: string;
  yearBuilt: string;
  bedrooms: string;
  baths: string;
  livingArea: string;
  recordingDate: string;
  distanceFromSubject: string;
}

interface ITaxMapData {
  assessorMapsAsBase64: string[];
  reportStatus?: string;
}

interface IPropertyAddressData {
  propertyAddress: string;
  streetAddress: string;
  cityStateZip: string;
  countyFips: string;
}

interface IPropertyProfileData {
  propertyDetailsData: IPropertyDetailsData;
  recordedDocumentUri: string;
  reportStatus?: string;
}

interface IPropertyDetailsData {
  subjectProperty: ISubjectProperty;
  ownerInformation: IOwnerInformation;
  locationInformation: ILocationInformation;
  siteInformation: ISiteInformation;
  propertyCharacteristics: IPropertyCharacteristics;
  taxInformation: ITaxInformation;
  lastMarketSaleInformation: ILastMarketSaleInformation;
  priorSaleInformation: IPriorSaleInformation;
  processedData: IProcessedData;
}

interface ISubjectProperty {
  situsAddress: ISitusAddress;
}

interface ISitusAddress {
  county: string;
  city: string;
  situsCarrierRoute: string;
  state: string;
  streetAddress: string;
  zip9: string;
}

interface IOwnerInformation {
  ownerNames: string;
  ownerVestingInfo: IOwnerVestingInfo;
}

interface IOwnerVestingInfo {
  vestingOwner: string;
}

interface ILocationInformation {
  legalDescription: string;
  apn: string;
  alternateAPN: string;
  subdivision: string;
  censusTract: string;
  tractNumber: string;
  legalLot: string;
  legalBlock: string;
  countyFips: string;
}

interface ISiteInformation {
  zoning: string;
  landUse: string;
}

interface IPropertyCharacteristics {
  grossArea: string;
  livingArea: string;
  totalRooms: string;
  bedrooms: string;
  baths: string;
  firePlaceIndicator: string;
  numberOfStories: string;
  garageArea: string;
  basementArea: string;
  pool: string;
  airConditioning: string;
  heatType: string;
  style: string;
  quality: string;
  yearBuilt: string;
}

interface ITaxInformation {
  totalTaxableValue: string;
  propertyTax: string;
  taxExemption: string;
  marketValue: string;
  marketLandValue: string;
  marketImprovValue: string;
  marketImprovValuePercent: string;
}

interface ILastMarketSaleInformation {
  deedType: string;
  salePrice: string;
  sellerName: string;
  lender: string;
  recordingDate: string;
  transferDocumentCmt: string;
  transferDocumentNumberCmtId: string;
}

interface IPriorSaleInformation {
  priorDeedType: string;
  priorSaleDate: string;
  priorSalePrice: string;
  priorDocNumber: string;
}

interface IProcessedData {
  propertyAddress: string;
  mailingAddress: string;
  taxYearArea: string;
  mapCoord: string;
  ownerPhone: string;
  yearBuilt: string;
  lotSize: string;
  units: string;
  bathrooms: string;
  parking: string;
  saleRecDate: string;
  transferDocumentNumber: string;
  pricePerSquareFoot: string;
  secondMortgageAmount: string;
  firstMortgageAmount: string;
  firstMortgageType: string;
  recordingDate: string;
  improvements: string;
}

export interface IComparableSalesSearchReportFilters {
  radiusFromSubjectProperty: string;
  areaVariance: string;
  monthsBack: string;
  minBedRooms: string;
  maxBedRooms: string;
  minBathRooms: string;
  maxBathRooms: string;
}

interface ITransactionHistoryData {
  transactions: ITransaction[];
  reportStatus?: string;
}

export interface ITransaction {
  id: string;
  type: string;
  typeDescription: string;
  extendedType: string;
  extendedTypeDescription: string;
  txDate: string;
  docType: string;
  buyerBorrower: string;
  sellerLender: string;
  amount: string;
  docId: string;
  cmtIdentifier: string;
  titleCompany: string;
  buyerName: string;
  sellerName: string;
  saleDate: string;
  saleType: string;
  lender: string;
  borrower1: string;
  borrower2: string;
  borrower3: string;
  borrower4: string;
  loanAmount: string;
  transactionType: string;
  mortgageLoanType: string;
  mortgageTerm: string;
  mortageRateType: string;
  mortgageRate: string;
}

export interface IStateCounty {
  stateName: string;
  fips: string;
  stateCode: string;
  county: ICounty[];
}

export interface ICounty {
  countyName: string;
  fips: string;
}

export interface ILandUseTypes {
  id: string;
  landUseTypeDesc: string;
  propertyUseTypes: IPropertyUseTypes[];
}

export interface IPropertyUseTypes {
  id: string;
  landUseTypeID: string;
  propertyUseTypeValue: string;
  propertyUseTypeDTCode: string;
}

export interface IDropDownField {
  fieldName: string;
  dropDownOptions: IDropDownFieldData[];
}

interface IDropDownFieldData {
  name: string;
  value: string;
}

interface IFilterRange {
  min?: string;
  max?: string;
}

interface ILastSaleDate {
  monthsBack?: string;
  dateRange?: IFilterRange;
}

export interface IPropertyFilters {
  landUseOption?: string;
  saleDate?: ILastSaleDate;
  residential?: string;
  residentialIncomeAndOthers?: string;
  vacantLand?: string;
  isCommercial?: boolean;
  isAgricultural?: boolean;
  isVacantLand?: boolean;
  isIndustrial?: boolean;
  isRecreational?: boolean;
  isGovernmentPublicUse?: boolean;
  isTransportationAndCommunication?: boolean;
  beds?: IFilterRange;
  baths?: IFilterRange;
  stories?: IFilterRange;
  noOfUnits?: IFilterRange;
  salePrice?: IFilterRange;
  sqFt?: IFilterRange;
  lotSize?: IFilterRange;
  pool?: string;
  ownerOccupied?: string;
}

export interface IPropertyFiltersRequest {
  landUseTypes: string[];
  saleDate?: ILastSaleDate;
  beds?: IFilterRange;
  baths?: IFilterRange;
  stories?: IFilterRange;
  noOfUnits?: IFilterRange;
  salePrice?: IFilterRange;
  sqFt?: IFilterRange;
  lotSize?: IFilterRange;
  pool?: string;
  ownerOccupied?: string;
}

export interface IFilterModalFields {
  landUseType?: string;
  residentialProperties?: string;
  residentialIncomeAndOther?: string;
  isCommercial?: boolean;
  isAgricultural?: boolean;
  isVacantLand?: boolean;
  vacantLandTypes?: string;
  isIndustrial?: boolean;
  isRecreational?: boolean;
  isGovernmentalPublicUse?: boolean;
  isTransportationAndCommunication?: boolean;
  lastSaleDate?: string;
  monthsBack?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  minBedsCount?: string;
  maxBedsCount?: string;
  minBathsCount?: string;
  maxBathsCount?: string;
  minStoriesCount?: string;
  maxStoriesCount?: string;
  minNumberOfUnitsCount?: string;
  maxNumberOfUnitsCount?: string;
  minSalePriceCount?: string;
  maxSalePriceCount?: string;
  minSquareFeetCount?: string;
  maxSquareFeetCount?: string;
  minLotSizeCount?: string;
  maxLotSizeCount?: string;
  poolPreference?: string;
  propertyOccupiedType?: string;
}

export interface IAdvancedSearchFields {
  state?: string;
  county?: string;
  zipCode?: string;
}

export interface ICmtDocumentProfilesByState {
  countyFips: string;
  cmtPortfolios: ICmtDocumentProfile[];
}

export interface ICmtDocumentProfile {
  stateFips?: string;
  countyFips?: string;
  subCountyId?: string;
  indexType?: string;
  beginDate?: string;
  endDate?: string;
  firstDocument?: string;
  lastDocument?: string;
  documentType?: string;
  docTabbingFormat?: string;
  docFieldNames?: string;
  docRangeInfo?: string;
  documentId?: string;
  documentDescription?: string;
  companyType?: string;
}

export interface IProductPaymentAcknowledgement {
  propertyProducts?: IPropertyDocument[];
  userAcceptedPaymentPolicy: boolean;
}

export interface IPaymentPolicyFields {
  title: string;
  policyDescriptionTitle: string;
  content: string;
  pricingTitle: string;
  propertyProductPricesResponse?: IPropertyProductPricesResponse[];
}

interface IPropertyProductPricesResponse {
  productName?: string;
  pricingStates?: string[];
  price?: string;
}

export interface IColumnProps {
  widthPercent?: number;
  flexDirection?: string;
}
