import { AxiosError } from 'axios';
import {
  InvoiceTypesKeys,
  IInvoiceState,
  InvoicesAction,
  IInvoiceDetails,
  IInvoiceCreateNew,
  IFees,
  IInvoiceHistory,
  IInvoices,
  IPaymentHistory,
  IInvoiceFileDetails,
  IInvoiceDocument,
} from './types';

export const initialState: IInvoiceState = {
  loading: false,
  invoiceAvailableFeeLoading: false,
  invoiceUpdateFeeLoading: false,
  error: null,
  invoices: [] as IInvoices[],
  invoiceDetails: {} as IInvoiceDetails,
  invoiceHistory: [] as IInvoiceHistory[],
  invoiceCreateNew: {} as IInvoiceCreateNew,
  invoiceAvailableFee: [] as IFees[],
  invoiceFee: [] as IFees[],
  invoiceFeeLoading: false,
  invoiceTotalAmount: null,
  invoiceUpdatedStatus: undefined,
  invoicePaymentHistory: [] as IPaymentHistory[],
  invoiceHistoryLoading: false,
  invoiceFileDetails: {} as IInvoiceFileDetails,
  loadingPaymentHistory: false,
  invoiceGostStatus: null,
  invoiceDocument: {} as IInvoiceDocument,
};

export default (state = initialState, action: InvoicesAction) => {
  switch (action.type) {
    case InvoiceTypesKeys.REQUEST_START:
      return {
        ...state,
        loading: true,
        invoiceHistory: [] as IInvoiceHistory[],
      };
    case InvoiceTypesKeys.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoices: action.invoices,
        invoiceDetails: action.invoiceDetails,
        invoiceFee: action.invoiceFee,
        invoiceTotalAmount: action.invoiceTotalAmount,
        invoiceUpdatedStatus: action.invoiceUpdatedStatus,
        invoiceGostStatus: action.invoiceGostStatus,
      };
    case InvoiceTypesKeys.REQUEST_HISTORY_LOADING: {
      return {
        ...state,
        invoiceHistoryLoading: true,
      };
    }
    case InvoiceTypesKeys.REQUEST_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        invoiceHistory: action.invoiceHistory,
        invoiceHistoryLoading: false,
      };
    }
    case InvoiceTypesKeys.REQUEST_PAYMENT_HISTORY_START: {
      return {
        ...state,
        loadingPaymentHistory: true,
      };
    }
    case InvoiceTypesKeys.REQUEST_PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        loadingPaymentHistory: false,
        invoicePaymentHistory: action.invoicePaymentHistory,
      };
    }
    case InvoiceTypesKeys.REQUEST_PAYMENT_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        invoicePaymentHistory: [],
      };
    }
    case InvoiceTypesKeys.REQUEST_START_EMAIL_INVOICE_DOCUMENT_CHECKBOX: {
      return {
        ...state,
        loading: true,
      };
    }
    case InvoiceTypesKeys.REQUEST_SUCCESS_EMAIL_INVOICE_DOCUMENT_CHECKBOX: {
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    }
    case InvoiceTypesKeys.REQUEST_ERROR_EMAIL_INVOICE_DOCUMENT_CHECKBOX: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case InvoiceTypesKeys.EMAIL_INVOICE_REQUEST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case InvoiceTypesKeys.EMAIL_INVOICE_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case InvoiceTypesKeys.EMAIL_INVOICE_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case InvoiceTypesKeys.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        invoiceAvailableFeeLoading: false,
        invoiceUpdateFeeLoading: false,
        invoiceHistoryLoading: false,
      };
    case InvoiceTypesKeys.REQUEST_CREATE_INVOICE_START:
      return {
        ...state,
        invoiceHistory: [] as IInvoiceHistory[],
      };
    case InvoiceTypesKeys.REQUEST_CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoiceCreateNew: action.invoiceCreateNew,
      };
    case InvoiceTypesKeys.REQUEST_CANCEL_FINALIZE_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceDetails: action.invoiceDetails,
      };
    case InvoiceTypesKeys.REQUEST_FETCH_AVAILABLE_FEE_START:
      return {
        ...state,
        invoiceAvailableFeeLoading: true,
        invoiceAvailableFee: [] as IFees[],
      };
    case InvoiceTypesKeys.REQUEST_FETCH_AVILABLE_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceAvailableFeeLoading: false,
        error: null,
        invoiceAvailableFee: action.invoiceAvailableFee,
      };
    case InvoiceTypesKeys.REQUEST_UPDATE_FEE_START:
      return {
        ...state,
        invoiceUpdateFeeLoading: true,
      };
    case InvoiceTypesKeys.REQUEST_UPDATE_FEE_SUCCESS:
      return {
        ...state,
        invoiceUpdateFeeLoading: false,
      };
    case InvoiceTypesKeys.REQUEST_INVOICE_FEE_START:
      return {
        ...state,
        invoiceFeeLoading: true,
        invoiceFee: [],
        invoiceTotalAmount: null,
        invoiceGostStatus: null,
      };
    case InvoiceTypesKeys.REQUEST_INVOICE_FEE_SUCCESS:
      return {
        ...state,
        invoiceFeeLoading: false,
        invoiceFee: action.invoiceFee,
        invoiceTotalAmount: action.invoiceTotalAmount,
        invoiceUpdatedStatus: action.invoiceUpdatedStatus,
        invoiceGostStatus: action.invoiceGostStatus,
      };
    case InvoiceTypesKeys.RESET_INVOICE:
      return {
        ...state,
        error: {} as AxiosError,
        invoices: [] as IInvoices[],
        invoiceDetails: {} as IInvoiceDetails,
        invoiceHistory: [] as IInvoiceHistory[],
        invoiceCreateNew: {} as IInvoiceCreateNew,
        invoiceAvailableFee: [] as IFees[],
        invoicePaymentHistory: [] as IPaymentHistory[],
        invoiceFileDetails: {} as IInvoiceFileDetails,
        invoiceDocument: {} as IInvoiceDocument,
      };
    case InvoiceTypesKeys.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case InvoiceTypesKeys.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case InvoiceTypesKeys.REQUEST_DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.invoices,
      };
    case InvoiceTypesKeys.REQUEST_FILE_SUCCESS:
      return {
        ...state,
        invoiceFileDetails: action.invoiceFileDetails,
      };
    case InvoiceTypesKeys.REQUEST_DOCUMENT_CONTENT_START:
      return {
        ...state,
        invoiceDocument: action.invoiceDocument,
      };
    case InvoiceTypesKeys.REQUEST_DOCUMENT_SUCCESS:
      return {
        ...state,
        invoiceDocument: action.invoiceDocument,
      };
    case InvoiceTypesKeys.REQUEST_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.error,
        invoiceDocument: action.invoiceDocument,
      };
    default:
      return state;
  }
};
