import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { blue, white, spindle } from 'config/theme';

interface INavIconProps {
  activeOn: RegExp;
  icon: React.ReactNode;
  label: string | React.ReactNode;
  to: string;
  domId: string;
}

export default ({ activeOn, domId, label, icon, to }: INavIconProps) => {
  return (
    <Link
      to={to}
      isActive={(_, loc) => activeOn.test(loc.pathname)}
      activeStyle={{ color: white, background: blue }}
      role="menuitem"
      id={domId}
    >
      <IconButton icon={icon} label={label} />
    </Link>
  );
};

interface IIconButtonProps {
  label: string | React.ReactNode;
  icon: React.ReactNode;
}

export const IconButton = ({ icon, label }: IIconButtonProps) => (
  <Container>
    <Icon>{icon}</Icon>
    <Text>{label}</Text>
  </Container>
);

const Container = styled.div`
  align-items: center;
  color: ${spindle};
  display: flex;
  flex-direction: column;
  position: relative;
  &:hover {
    color: ${white};
    fill: ${white};

    & * {
      fill: ${white};
    }
  }
  &:after {
    background: rgba(255, 255, 255, 0.7);
    content: '';
    height: 3px;
    display: none;
    width: 100%;
    position: absolute;
    bottom: -8px;
    right: 0;
    left: 0;
  }
`;

const Link = styled(NavLink)`
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    ${Container} {
      color: ${white};
      &:after {
        display: block;
      }
      & * {
        fill: ${white};
      }
    }
  }
`;

const Icon = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
  & * {
    fill: ${spindle};
  }
`;

const Text = styled.div`
  align-items: center;
  line-height: 18px;
  font-size: 12px;
  display: flex;
  margin-top: 1px;
`;
