import { IWireInstructions } from 'store/wireInstructions/types';
import {
  FilesTypeKeys,
  FileRequestTypes,
  FilesAction,
  IFilesState,
  IFileContact,
  IFileOrganizationsHash,
} from './types';

export const initialState: IFilesState = {
  loading: false,
  requestType: FileRequestTypes.NONE,
  error: null,
  fileWireInstructions: [] as IWireInstructions[],
  file: null,
  fileContacts: [] as IFileContact[],
  fileOrganizations: {} as IFileOrganizationsHash,
};

export default (state = initialState, action: FilesAction) => {
  switch (action.type) {
    case FilesTypeKeys.REQUEST:
      return {
        ...state,
        loading: true,
        requestType: action.requestType,
      };
    case FilesTypeKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        requestType: FileRequestTypes.NONE,
        error: null,
      };
    case FilesTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        requestType: FileRequestTypes.NONE,
        error: action.error,
      };
    case FilesTypeKeys.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case FilesTypeKeys.RECEIVE_WIRE_INSTRUCTIONS_RESULTS:
      return {
        ...state,
        fileWireInstructions: action.wireInstructions,
      };
    case FilesTypeKeys.SET_FILE_WIRE_INSTRUCTIONS_RESET:
      return {
        ...state,
        fileWireInstructions: [],
        loading: false,
      };
    case FilesTypeKeys.RECEIVE_FILE_RESULTS:
      return {
        ...state,
        file: action.file,
      };
    case FilesTypeKeys.SET_FILE_ORGANIZATIONS:
      return {
        ...state,
        fileOrganizations: action.organizations,
      };
    case FilesTypeKeys.SET_FILE_ORGANIZATION_CONTACTS:
      return {
        ...state,
        fileOrganizations: {
          ...state.fileOrganizations,
          [action.key]: {
            ...state.fileOrganizations[action.key],
            organizationContacts: action.organizationContacts,
          },
        },
      };
    default:
      return state;
  }
};
