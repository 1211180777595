import { lightBlue } from 'config/theme';
import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <rect
      x="1.24316"
      y="1.06396"
      width="15.345"
      height="19.8106"
      rx="1.24138"
      stroke={props.fill || lightBlue}
      strokeWidth="1.5"
    />
    <path
      d="M7.27159 4.51473C7.27159 4.17194 6.9937 3.89404 6.6509 3.89404H4.65389C4.3111 3.89404 4.0332 4.17193 4.0332 4.51473V6.10344C4.0332 6.44623 4.3111 6.72413 4.65389 6.72413H6.6509C6.9937 6.72413 7.27159 6.44623 7.27159 6.10344V4.51473Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M4.0332 8.75986C4.0332 8.41706 4.3111 8.13917 4.65389 8.13917H6.6509C6.9937 8.13917 7.27159 8.41706 7.27159 8.75986V10.3486C7.27159 10.6914 6.9937 10.9693 6.6509 10.9693H4.65389C4.3111 10.9693 4.0332 10.6914 4.0332 10.3486V8.75986Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M7.27159 13.005C7.27159 12.6622 6.9937 12.3843 6.6509 12.3843H4.65389C4.3111 12.3843 4.0332 12.6622 4.0332 13.005V14.5937C4.0332 14.9365 4.3111 15.2144 4.65389 15.2144H6.6509C6.9937 15.2144 7.27159 14.9365 7.27159 14.5937V13.005Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M10.5598 4.51473C10.5598 4.17194 10.8377 3.89404 11.1805 3.89404H13.1775C13.5203 3.89404 13.7982 4.17193 13.7982 4.51473V6.10344C13.7982 6.44623 13.5203 6.72413 13.1775 6.72413H11.1805C10.8377 6.72413 10.5598 6.44623 10.5598 6.10344V4.51473Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M13.7982 8.75986C13.7982 8.41706 13.5203 8.13917 13.1775 8.13917H11.1805C10.8377 8.13917 10.5598 8.41706 10.5598 8.75986V10.3486C10.5598 10.6914 10.8377 10.9693 11.1805 10.9693H13.1775C13.5203 10.9693 13.7982 10.6914 13.7982 10.3486V8.75986Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M10.5598 13.005C10.5598 12.6622 10.8377 12.3843 11.1805 12.3843H13.1775C13.5203 12.3843 13.7982 12.6622 13.7982 13.005V14.5937C13.7982 14.9365 13.5203 15.2144 13.1775 15.2144H11.1805C10.8377 15.2144 10.5598 14.9365 10.5598 14.5937V13.005Z"
      fill={props.fill || lightBlue}
    />
    <rect
      x="7.52051"
      y="16.6294"
      width="2.79"
      height="4.95265"
      rx="0.62069"
      fill={props.fill || lightBlue}
    />
  </svg>
);
