/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_Events_EventsViewModel_IEvent_ } from '../models/Workspace_API_ViewModels_Events_EventsViewModel_IEvent_';
import type { Workspace_API_ViewModels_Events_SearchEventsViewModel } from '../models/Workspace_API_ViewModels_Events_SearchEventsViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   *  (Auth)
   * @returns Workspace_API_ViewModels_Events_EventsViewModel_IEvent_ Success
   * @throws ApiError
   */
  public searchEvents({
    requestBody,
  }: {
    requestBody?: Workspace_API_ViewModels_Events_SearchEventsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Events_EventsViewModel_IEvent_> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/events/_search',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
      },
    });
  }

}
