import React from 'react';
import styled from 'styled-components';
import { black, trout } from 'config/theme';

interface IOrderTypesProps {
  count: number;
  name: string;
  nameFontSize?: string;
}

const OrderTypes = ({ count, name, nameFontSize }: IOrderTypesProps) => {
  return (
    <>
      <Name nameFontSize={nameFontSize}>{name}</Name>
      {count > 0 && <Count>+{count} more</Count>}
    </>
  );
};
OrderTypes.displayName = 'OrderTypes';

export default OrderTypes;

const Name = styled.span<{ nameFontSize }>`
  color: ${black};
  font-size: ${({ nameFontSize }) => nameFontSize || '13px'};
  margin-right: 4px;
`;

const Count = styled.span`
  color: ${trout};
  font-size: 12px;
`;
