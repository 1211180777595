import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { catskillWhite, headerZIndex, extremeBlack } from 'config/theme';
import Button, { ButtonSize } from 'components/ui/Button';
import { writeCookie, readCookie } from 'utils/cookies';
import { useWindowSize } from 'hooks/useWindowSize';

// Cookie Name: Clarity First: Privacy Policy Banner Cookie
const cookieName = AppConfig.ccpaCookieName || 'CFPPBC';
const header = AppConfig.ccpaHeader || 'PRIVACY NOTICE';
const preText =
  AppConfig.ccpaPreText ||
  'This website uses cookies and similar technologies to manage your sessions, manage content, and improve your website experience. To learn more about these technologies, your options, and about other categories of personal information we collect through this website and how we may use it, please see our';
const postText =
  AppConfig.ccpaPostText ||
  '. This notice is effective for your use of this website for the next 14 days.';
const link =
  AppConfig.ccpaLink ||
  'https://www.firstam.com/privacy-policy/#type-of-information';
const linkText = AppConfig.ccpaLinkText || 'privacy policy';
const buttonText = AppConfig.ccpaButtonText || 'Got it';
// Cookie Expiration: in days
const cookieExpire = Number(AppConfig.ccpaExpireDays) || 14;

export const PolicyBanner: React.FC<{}> = ({ children: footer }): any => {
  const [displayBanner, setBanner] = useState(false);
  const [footerOffsetPadding, setPadding] = useState(0);
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const windowSize = useWindowSize();
  useEffect(() => {
    const hasCookie = readCookie(cookieName);
    if (!hasCookie) {
      // if cookie isn't available display the banner
      setBanner(true);
    }
    const offsetPadding = bannerRef.current
      ? bannerRef.current.clientHeight
      : 0;
    // set padding to footer based on banner width [dynamic + responsive]
    setPadding(offsetPadding);
  }, [windowSize, displayBanner]);

  const onAccept = () => {
    writeCookie(cookieName, 'true', { expires: cookieExpire });
    setBanner(false);
  };

  return (
    <>
      {displayBanner && (
        <BannerWrapper ref={bannerRef}>
          <Banner>
            <Content>
              <b>{header}</b>
              <br />
              {preText}{' '}
              <a href={link} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
              {postText}
            </Content>
            <Button onClick={onAccept} size={ButtonSize.MD}>
              {buttonText}
            </Button>
          </Banner>
        </BannerWrapper>
      )}
      <FooterWrapper extraPadding={footerOffsetPadding}>{footer}</FooterWrapper>
    </>
  );
};

const FooterWrapper = styled.div<{ extraPadding?: number }>`
  ${({ extraPadding }) =>
    extraPadding &&
    `
    padding-bottom: ${extraPadding}px;
  `}
`;

const Content = styled.div`
  color: ${extremeBlack};
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.0025em;
  width: 100%;
  max-width: 988px;
  padding-right: 20px;
  align-self: center;
`;
const Banner = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  ${Button} {
    margin-top: 14px;
    min-width: 87px;
  }
  @media only screen and (min-width: 768px) {
    max-width: 1254px;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    min-height: 80px;
    padding: 20px;
    align-items: center;
    ${Button} {
      margin-top: 0;
      margin-left: auto;
    }
  }
`;
const BannerWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: ${headerZIndex * 15};
  width: 100%;
  background: ${catskillWhite};
  display: flex;
  align-items: center;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.2);
`;
