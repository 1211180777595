/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_CreateEmailOrderAssignmentRequest } from '../models/Workspace_Common_Requests_CreateEmailOrderAssignmentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailOrderAssociationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   *  (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public createEmailOrderAssignment({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_CreateEmailOrderAssignmentRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/email-order-associations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        422: `Client Error`,
        500: `Server Error`,
      },
    });
  }

}
