import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    style={props.style}
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42 22C42 33.0457 33.0457 42 22 42C10.9543 42 2 33.0457 2 22C2 10.9543 10.9543 2 22 2C33.0457 2 42 10.9543 42 22Z"
      stroke="#ADB8C7"
      strokeWidth="4"
      fill={props.fill || white}
    />
  </svg>
);
