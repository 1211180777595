/* eslint-disable import/no-unused-modules */

// https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { Dispatch, IApplicationState } from 'store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => Dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IApplicationState> = useSelector;
