import React, { useState, useEffect } from 'react';
import * as router from 'connected-react-router';
import Countdown from 'react-countdown';
import { connect } from 'react-redux';
import { IApplicationState } from 'store';
import { manuallyGetSession } from 'store/auth/actions';
import { broadcastLogOut } from 'store/auth/channels';
import { isInternalUser } from 'store/auth/getters';
import { AuthLoadingKeys } from 'store/auth/types';
import { getLoadingState } from 'store/ui/selectors';
import randomId from 'utils/randomId';
import ModalContents from './ModalContents';
import { setModalStateAction } from 'store/ui/actions';
import { ModalKey } from 'store/ui/types';

interface ISessionTimeoutModalProps extends ReturnType<typeof mapStateToProps> {
  manuallyGetSession: (
    refresh?: boolean,
    broadcastUpdate?: boolean
  ) => Promise<void>;
  setModalState: (isVisible: boolean) => void;
  signOut: (path: string) => void;
}

export const SessionTimeoutModal = ({
  isOpen,
  authRefreshQueued,
  loading,
  manuallyGetSession,
  sessionTimeoutUtc,
  setModalState,
  signOut,
  signOutPath,
}: ISessionTimeoutModalProps) => {
  const [sessionTimerId, setSessionTimerId] = useState(randomId());

  useEffect(() => {
    setSessionTimerId(randomId());
  }, [sessionTimeoutUtc]);

  // We don't want to render these components if we have no end date
  // Or auth token is queued to be refreshed
  if (!sessionTimeoutUtc || authRefreshQueued) return null;

  return (
    <Countdown
      date={sessionTimeoutUtc}
      key={sessionTimerId}
      renderer={({ total, minutes, seconds, completed }) => (
        <ModalContents
          loading={loading}
          manuallyGetSession={manuallyGetSession}
          setModalState={setModalState}
          onSignOutClick={() => {
            signOut(signOutPath);
            broadcastLogOut(signOutPath);
          }}
          total={total / 1000}
          minutes={minutes}
          seconds={seconds}
          completed={completed}
          isOpen={isOpen}
        />
      )}
    />
  );
};

function mapStateToProps(state: IApplicationState) {
  const loading = getLoadingState(
    state.ui.loaders,
    AuthLoadingKeys.authSessionTimeoutLoading
  );
  const signOutPath = isInternalUser(state.auth)
    ? '/employee/signout'
    : '/customer/signout';

  const timeoutStr = state.auth.session.sessionTimeoutUtc;
  const sessionTimeoutUtc = timeoutStr
    ? Date.parse(`${timeoutStr}.000z`) // - (29 * 60 + 30) * 1000
    : null;

  const isOpen = state.ui.modals.session_timeout_modal;

  return {
    loading,
    signOutPath,
    sessionTimeoutUtc,
    isOpen,
    authRefreshQueued: state.auth.refresh,
  };
}

const mapDispatchToProps = (dispatch) => ({
  signOut: (path: string) => dispatch(router.push(path)),
  setModalState: (isVisible: boolean) =>
    dispatch(setModalStateAction(ModalKey.SessionTimeoutModal, isVisible)),
  manuallyGetSession: (refresh, broadcastUpdate) =>
    dispatch(manuallyGetSession(refresh, broadcastUpdate)),
});

const ConnectedSessionTimeoutModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionTimeoutModal);
ConnectedSessionTimeoutModal.displayName = 'ConnectedSessionTimeoutModal';
export default ConnectedSessionTimeoutModal;
