/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Responses_Package } from '../models/Workspace_Common_Responses_Package';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderPackagesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get the latest package for an order. (Auth)
   * @returns Workspace_Common_Responses_Package Package successfully retrieved
   * @throws ApiError
   */
  public getLatestPackage({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Workspace_Common_Responses_Package> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/packages/_latest',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Package not found`,
      },
    });
  }

}
