/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_UpdateInvoicePaymentInformationRequest } from '../models/Workspace_Common_Models_UpdateInvoicePaymentInformationRequest';
import type { Workspace_Common_Requests_CreatePaymentTokenRequest } from '../models/Workspace_Common_Requests_CreatePaymentTokenRequest';
import type { Workspace_Common_Responses_InvoiceDetail } from '../models/Workspace_Common_Responses_InvoiceDetail';
import type { Workspace_Common_Responses_InvoiceTransaction } from '../models/Workspace_Common_Responses_InvoiceTransaction';
import type { Workspace_Common_Responses_Mesh_PaymentReceiptReturnValues } from '../models/Workspace_Common_Responses_Mesh_PaymentReceiptReturnValues';
import type { Workspace_Common_Responses_Mesh_PaymentTokenResponse } from '../models/Workspace_Common_Responses_Mesh_PaymentTokenResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BillingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets invoices by user Id (Auth)
   * @returns Workspace_Common_Responses_InvoiceDetail Invoice(s) retrieved successfully
   * @throws ApiError
   */
  public getInvoices(): CancelablePromise<Array<Workspace_Common_Responses_InvoiceDetail>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/invoice',
      errors: {
        403: `User doesn't have access to billing functions.`,
        404: `No unpaid invoice found`,
      },
    });
  }

  /**
   * Update Invoice Payment Information (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateInvoicePaymentInformation({
    id,
    requestBody,
  }: {
    id: number,
    requestBody: Workspace_Common_Models_UpdateInvoicePaymentInformationRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/billing/invoice/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        403: `User doesn't have access to billing functions.`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * Gets invoice by Invoice Id (Auth)
   * @returns Workspace_Common_Responses_InvoiceDetail Invoice retrieved successfully
   * @throws ApiError
   */
  public getInvoice({
    id,
  }: {
    id: number,
  }): CancelablePromise<Workspace_Common_Responses_InvoiceDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/invoice/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `User doesn't have access to billing functions.`,
        404: `No invoice found`,
      },
    });
  }

  /**
   * Gets latest unpaid invoice for the current external user (Auth)
   * @returns Workspace_Common_Responses_InvoiceDetail Invoice retrieved successfully
   * @throws ApiError
   */
  public getLatestUnpaidInvoice(): CancelablePromise<Workspace_Common_Responses_InvoiceDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/unpaid-invoice',
      errors: {
        403: `User doesn't have access to billing functions.`,
      },
    });
  }

  /**
   * To check if Customer Has access to PSF Billing and Payments
   * based on the invoices available for them (Auth)
   * @returns boolean boolean flag returned successfully
   * @throws ApiError
   */
  public canAccessPsfBillingAndPaymentsAsync(): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/canAccessBilling',
      errors: {
        403: `User doesn't have access to billing functions.`,
      },
    });
  }

  /**
   * Gets all transactions by Invoice Id
   * <param name="id">Invoice Id</param><param name="page">a page number</param><param name="size">items per page</param> (Auth)
   * @returns Workspace_Common_Responses_InvoiceTransaction Transactions retrieved successfully
   * @throws ApiError
   */
  public getTransactionsByInvoiceId({
    id,
    page = 1,
    size = 10,
  }: {
    id: number,
    page?: number,
    size?: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_InvoiceTransaction>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/invoice-transaction/{id}',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'size': size,
      },
      errors: {
        403: `User doesn't have access to billing functions.`,
        404: `No transaction found`,
      },
    });
  }

  /**
   * Gets invoice all transactions by Invoice Id (Auth)
   * @returns Workspace_Common_Responses_InvoiceTransaction Transactions retrieved successfully
   * @throws ApiError
   */
  public getInvoiceTransactions({
    id,
  }: {
    /**
     * Invoice Id
     */
    id: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_InvoiceTransaction>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/invoice-transactions/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `User doesn't have access to billing functions.`,
      },
    });
  }

  /**
   * Create a payment token (Auth)
   * @returns Workspace_Common_Responses_Mesh_PaymentTokenResponse Payment token returned successfully
   * @throws ApiError
   */
  public createPaymentTokenAsync({
    requestBody,
  }: {
    /**
     * Request to create payment token
     */
    requestBody: Workspace_Common_Requests_CreatePaymentTokenRequest,
  }): CancelablePromise<Workspace_Common_Responses_Mesh_PaymentTokenResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/billing/customer/payment-token',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `User doesn't have access to billing functions.`,
        422: `Validation failed.`,
        500: `Token creation failed.`,
      },
    });
  }

  /**
   * Gets payment receipt by token (Auth)
   * @returns Workspace_Common_Responses_Mesh_PaymentReceiptReturnValues Receipt retrieved successfully
   * @throws ApiError
   */
  public getPaymentReceiptByTokenAsync({
    token,
  }: {
    token: string | null,
  }): CancelablePromise<Workspace_Common_Responses_Mesh_PaymentReceiptReturnValues> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/payment-receipt/{token}',
      path: {
        'token': token,
      },
      errors: {
        403: `User doesn't have access to billing functions.`,
        422: `Validation failed, null or empty token.`,
        500: `Payment receipt retrieval failed.`,
      },
    });
  }

  /**
   * Generates Invoice PDF passing invoice data to Document Service. (Auth)
   * @returns binary PDF Generated Successfully
   * @throws ApiError
   */
  public generateInvoicePdfAsync({
    id,
    requestBody,
  }: {
    /**
     * Invoice Id
     */
    id: number,
    /**
     * User Time Zone offset from UTC.
     */
    requestBody?: string | null,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/billing/generate-billing-pdf/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `User doesn't have access to Billing Functions.`,
        404: `No Transaction Found / Invalid Params / No Access to the receipt`,
        500: `Server Error`,
      },
    });
  }

  /**
   * Gets latest invoice by user Id (Auth)
   * @returns Workspace_Common_Responses_InvoiceDetail Invoice(s) retrieved successfully
   * @throws ApiError
   */
  public getLatestInvoice(): CancelablePromise<Workspace_Common_Responses_InvoiceDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/billing/get-latest-invoice',
      errors: {
        403: `User doesn't have access to billing functions.`,
      },
    });
  }

}
