import ProgressPopup from 'components/ProgressPopup/ProgressPopup';
import React, { useEffect } from 'react';
import AlertCircle from 'icons/AlertCircle';
import CheckCircle from 'icons/CheckCircle';
import { green8, red8 } from 'config/colors';
import { JobStatus } from 'components/MultiSiteBulkUpdates/Shared';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'store';
import client from 'services/workspaceAPI/client';
import useQuery from 'hooks/api/useQuery';
import {
  ITrustAccountingFetchResponse,
  TrustExtractStatus,
} from 'components/Accounting/TrustAccounting/types';
import { toast } from 'components/Snackbar';
import useInterval from 'hooks/useInterval';
import { setBuildTrustExtractProgress } from 'store/offices/actions';

const trustExtractApi = client.trustExtract;

const getStatusHeaderAndIcon = ({ pollingList, inProgress, onClose }) => {
  if (inProgress) {
    return {
      icon: null,
      title: 'Building extract, this process can be lengthy...',
      onClose,
    };
  } else {
    const failedUpdates = pollingList.filter(
      ({ state }) => state === JobStatus.FAILED
    ).length;
    if (failedUpdates === 0) {
      // if there are no failed status
      return {
        icon: <CheckCircle fill={green8} />,
        title: 'Build Extract is ready to Transmit',
        onClose,
      };
    } else {
      return {
        icon: <AlertCircle fill={red8} />,
        title: 'Build Extract failed to complete',
        onClose,
      };
    }
  }
};

const BuildTrustExtractStatusProgress = () => {
  const { items: pollingList } =
    useSelector(
      (state: IApplicationState) => state.offices.ui.buildTrustExtractProgress
    ) || {};
  const inProgress = !!pollingList.find(
    ({ state }) => state === JobStatus.PROCESSING
  );
  const [delay, setDelay] = React.useState<number | null>(null);

  const [autoRefresh, setAutoRefresh] = React.useState(false);

  const { officeId } = pollingList[0] || {};

  const getTrustExtractsRequest = () => {
    setAutoRefresh(true);
    return trustExtractApi.getTrustExtractsAsync({
      officeId,
    });
  };

  useEffect(() => {
    setDelay(inProgress ? 10 * 1000 : null);
  }, [inProgress]);

  const {
    isLoading,
    data: trustExtractResponse,
    refetch: fetchTrustExtracts,
  } = useQuery<ITrustAccountingFetchResponse>(getTrustExtractsRequest, {
    autoFire: false,
    onError: () =>
      toast.error('There was an error fetching build extracts status.'),
  });

  useInterval(() => fetchTrustExtracts(), delay);

  const dispatch = useDispatch();

  const updateBuildExtractItems = (items, shouldReFetchTrustExtracts) => {
    dispatch(setBuildTrustExtractProgress(items, shouldReFetchTrustExtracts));
  };

  React.useEffect(() => {
    if (!trustExtractResponse || isLoading) return;
    const pendingTrustExtract = trustExtractResponse.records.find(
      (record) => record.status === TrustExtractStatus.Pending
    );
    if (pendingTrustExtract && pollingList.length && autoRefresh) {
      updateBuildExtractItems(
        pollingList.map((item) => {
          if (item.officeId === officeId)
            return { ...item, state: JobStatus.SUCCESS };
          return item;
        }),
        true
      );
      setAutoRefresh(false);
    }
  }, [trustExtractResponse, isLoading, officeId]);

  useEffect(() => {
    if (pollingList.length && !inProgress) {
      const timeout = setTimeout(
        () => updateBuildExtractItems([], false),
        7000
      );
      return () => clearTimeout(timeout);
    }
  }, [pollingList.length, inProgress]);

  if (pollingList.length <= 0) return null;

  return (
    <ProgressPopup
      pollingList={[]}
      inProgress={inProgress}
      progressHeaderProps={getStatusHeaderAndIcon({
        inProgress,
        pollingList,
        onClose: () => updateBuildExtractItems([], false),
      })}
    />
  );
};

export default BuildTrustExtractStatusProgress;
