import React from 'react';
import { SMALL_BODY_MEDIUM } from 'config/typography';
import styled from 'styled-components';
import { Row } from 'components/ui/Box';
import { neutralGray7, primaryBlue2 } from 'config/colors';
import ChevronIcon from 'icons/ChevronIcon';

const headerHeight: number = 56;

const domIdPrefix: string = 'disbursement-issue-progress';

const ProgressHeader = (props: {
  isExpanded: boolean;
  onToggleExpanded: () => void;
}) => (
  <StyledProgressHeader>
    <div>{'Processing Request...'}</div>
    <ButtonContainer
      onClick={props.onToggleExpanded}
      id={`${domIdPrefix}__button__close`}
    >
      {props.isExpanded ? <ExpandedChevron /> : <CollapsedChevron />}
    </ButtonContainer>
  </StyledProgressHeader>
);

export default ProgressHeader;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })`
  ${SMALL_BODY_MEDIUM};
  height: ${headerHeight}px;
  padding: 19px 0 18px 16px;
  border-bottom: 2px solid ${primaryBlue2};
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
  width: 40px;
  height: 40px;

  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${neutralGray7};
    }
  }
`;

const StyledChevron = styled(ChevronIcon)`
  color: ${neutralGray7};
  height: 40px;
  width: 40px;
  padding-left: 14px;
`;

const CollapsedChevron = styled(StyledChevron)`
  transform: rotate(180deg);
  margin-top: -10px;
`;

const ExpandedChevron = styled(StyledChevron)`
  margin-top: 4px;
`;
