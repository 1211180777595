import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M21.6425 5.10616C22.1191 4.62956 22.1191 3.83523 21.6425 3.38307L18.7829 0.523466C18.3308 0.0468655 17.5365 0.0468655 17.0599 0.523466L14.8113 2.75982L19.394 7.34252L21.6425 5.10616ZM0 17.5833V22.166H4.5827L18.0986 8.63789L13.5159 4.0552L0 17.5833Z"
      fill={props.fill || shuttleGrey}
    />
  </svg>
);
