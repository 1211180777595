import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17.1V5.7H2V17.1H16ZM13 0H15V1.9H16C16.5304 1.9 17.0391 2.10018 17.4142 2.4565C17.7893 2.81282 18 3.29609 18 3.8V17.1C18 17.6039 17.7893 18.0872 17.4142 18.4435C17.0391 18.7998 16.5304 19 16 19H2C0.89 19 0 18.145 0 17.1V3.8C0 2.7455 0.89 1.9 2 1.9H3V0H5V1.9H13V0ZM8 7.6H10V10.45H13V12.35H10V15.2H8V12.35H5V10.45H8V7.6Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
