import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    style={props.style}
    width="103"
    height="103"
    viewBox="0 0 103 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="51.468" cy="51.5422" r="51.4485" fill="#56C568" />
    <path
      d="M74.5195 36.9134L42.291 68.457L27.5195 53.9995L31.3064 50.2932L42.291 61.018L70.7327 33.207L74.5195 36.9134Z"
      fill={props.fill || white}
    />
  </svg>
);
