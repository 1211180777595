import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 14">
      <g id="Frame 14">
        <g id="Group 13">
          <g id="Vector">
            <path
              fill={props.fill || neutralGray7}
              d="M19.684 16.7941C19.8175 16.8058 19.9727 16.8783 20.1218 17.0262C20.2709 17.174 20.3441 17.3278 20.3559 17.4602C20.3668 17.5827 20.3291 17.7212 20.1838 17.8653C19.843 18.203 19.2618 18.5778 18.5703 19.0237L18.5297 19.0498L18.0805 18.6045L18.1069 18.5643C18.5566 17.8787 18.9347 17.3025 19.2754 16.9648C19.4207 16.8207 19.5605 16.7832 19.684 16.7941ZM18.9941 19.5102C19.6433 19.0921 20.2589 18.6913 20.6379 18.3155C20.9055 18.0503 21.0249 17.7306 20.9957 17.404C20.9674 17.0872 20.8031 16.8011 20.576 16.5759C20.3488 16.3507 20.0602 16.1879 19.7407 16.1598C19.4112 16.1309 19.0888 16.2493 18.8212 16.5145C18.4421 16.8903 18.0379 17.5005 17.6161 18.1441L16.9426 17.4764C16.4409 16.9791 15.6275 16.9791 15.1259 17.4764L14.6717 17.9267C14.4209 18.1753 14.4209 18.5785 14.6717 18.8272L14.3311 19.1649C14.0175 19.4757 14.0175 19.9797 14.3311 20.2905L16.829 22.7669C17.1426 23.0777 17.6509 23.0777 17.9645 22.7669L18.3051 22.4292C18.5559 22.6779 18.9626 22.6779 19.2134 22.4292L19.6677 21.9789C20.1693 21.4816 20.1693 20.6753 19.6677 20.1779L18.9941 19.5102ZM15.1259 19.2774L17.8509 21.9789L17.5103 22.3167C17.4476 22.3788 17.3459 22.3788 17.2832 22.3167L14.7852 19.8402C14.7225 19.7781 14.7225 19.6773 14.7852 19.6151L15.1259 19.2774Z"
            />
            <path d="M4.9726 18.6L4.9726 2.95556L16.8082 2.95556L16.8082 14.6889H18.7808V2.95556C18.7808 1.87553 17.8977 1 16.8082 1H4.9726C3.88316 1 3 1.87553 3 2.95556V18.6C3 19.68 3.88317 20.5556 4.9726 20.5556H12.863V18.6H4.9726Z" />
          </g>
          <path
            id="Line 4"
            d="M7 17H12"
            stroke="#345580"
            strokeLinecap="square"
          />
          <path
            id="Line 3"
            d="M7 15H14"
            stroke="#345580"
            strokeLinecap="square"
          />
          <path
            id="Vector_2"
            d="M11.2299 7.95C9.89194 7.655 9.46169 7.35 9.46169 6.875C9.46169 6.33 10.057 5.95 11.053 5.95C12.1022 5.95 12.4912 6.375 12.5265 7H13.8291C13.7878 6.14 13.169 5.35 11.9371 5.095V4H10.169V5.08C9.02554 5.29 8.10609 5.92 8.10609 6.885C8.10609 8.04 9.23183 8.615 10.8762 8.95C12.3497 9.25 12.6444 9.69 12.6444 10.155C12.6444 10.5 12.3556 11.05 11.053 11.05C9.8389 11.05 9.36149 10.59 9.29666 10H8C8.07073 11.095 9.03733 11.71 10.169 11.915V13H11.9371V11.925C13.0864 11.74 14 11.175 14 10.15C14 8.73 12.5678 8.245 11.2299 7.95Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
