import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M16.8635 9.54199H10.0322V16.3733H7.75513V9.54199H0.923828V7.26489H7.75513V0.433594H10.0322V7.26489H16.8635V9.54199Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
