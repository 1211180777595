import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.16602H14.5L9 1.66602V7.16602ZM2 0.166016H10L16 6.16602V18.166C16 18.6964 15.7893 19.2052 15.4142 19.5802C15.0391 19.9553 14.5304 20.166 14 20.166H2C0.89 20.166 0 19.266 0 18.166V2.16602C0 1.05602 0.89 0.166016 2 0.166016ZM11 16.166V14.166H2V16.166H11ZM14 12.166V10.166H2V12.166H14Z"
      fill={props.fill || shuttleGrey}
    />
  </svg>
);
