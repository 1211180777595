import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71429 2.07143H9.42857C9.49628 2.07143 9.56302 2.07095 9.62854 2.07047C10.3993 2.0649 11 2.06056 11 2.85714V9.92857C11 10.7929 10.2929 11.5 9.42857 11.5H1.57143C0.707143 11.5 0 10.7929 0 9.92857V2.07143C0 1.20714 0.707143 0.5 1.57143 0.5H3.14286L4.71429 2.07143ZM8.228 9.14286L6.28571 8.06312L4.34343 9.14286L4.85571 7.10786L3.14286 5.73865L5.40257 5.55917L6.28571 3.64286L7.16886 5.55917L9.42857 5.73865L7.71257 7.10786L8.228 9.14286Z"
      fill="#345580"
    />
  </svg>
);
