import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || neutralGray7}
      d="M16.2939 14.5786H15.3905L15.0703 14.2699C16.191 12.9663 16.8656 11.2739 16.8656 9.43283C16.8656 5.32763 13.538 2.00001 9.43282 2.00001C5.32762 2.00001 2 5.32763 2 9.43283C2 13.538 5.32762 16.8656 9.43282 16.8656C11.2739 16.8656 12.9663 16.191 14.2699 15.0703L14.5786 15.3905V16.2939L20.2962 22L22 20.2962L16.2939 14.5786ZM9.43282 14.5786C6.58548 14.5786 4.28702 12.2802 4.28702 9.43283C4.28702 6.58549 6.58548 4.28703 9.43282 4.28703C12.2802 4.28703 14.5786 6.58549 14.5786 9.43283C14.5786 12.2802 12.2802 14.5786 9.43282 14.5786Z"
    />
  </svg>
);
