import {
  IDealStatus,
  IDefaultLow,
  IEstimatedCloseDate,
  ILocation,
  IOwningOffice,
  IQueryValue,
  IRangeProps,
  IRangeValue,
  ISiteType,
} from 'store/searchFilters/types';

class SearchFilter {
  public static selectURLParams(
    queryString: string,
    filterName: string
  ): string[][] {
    const params = new URLSearchParams(queryString);

    return params.getAll('filter').reduce((acc, param) => {
      const [name, ...values] = param.split(',');
      if (filterName === name) {
        acc.push(values);
      }
      return acc;
    }, [] as string[][]);
  }

  public static queryValueToParam(key: string, { query }: IQueryValue): string {
    return query ? `${key},${query}` : '';
  }

  public static rangeValueToParam(
    key: string,
    value: IRangeValue,
    [low, high]: IRangeProps,
    defaultLow?: IDefaultLow
  ): string {
    if (!value[low] && !value[high]) {
      return '';
    }

    const lowVal = value[low] || defaultLow || '';
    const highVal = value[high];

    return highVal ? `${key},${lowVal},${highVal}` : `${key},${lowVal}`;
  }
}

export class DealStatusFilter extends SearchFilter {
  public static toURLParams(dealStatus: IDealStatus): string[] {
    return Object.keys(dealStatus).reduce((params, name) => {
      if (dealStatus[name]) {
        params.push(`status,${this.normalizeParams(name)}`);
      }
      return params;
    }, [] as string[]);
  }

  public static fromURLParams(queryString: string): IDealStatus {
    const dealStatus: IDealStatus = {
      Open: false,
      Closed: false,
      Reserved: false,
      Cancelled: false,
      OpenInError: false,
    };

    this.selectURLParams(queryString, 'status').forEach(
      ([key]) => (dealStatus[this.normalizeParams(key, false)] = true)
    );

    return dealStatus;
  }

  private static normalizeParams = (value, toHuman = true): string => {
    return toHuman
      ? value === 'OpenInError'
        ? 'Open In Error'
        : value
      : value === 'Open In Error'
      ? 'OpenInError'
      : value;
  };
}

export class LocationFilter extends SearchFilter {
  public static toURLParams(value: ILocation) {
    const filter = this.queryValueToParam('location', value);
    return filter ? [filter] : [];
  }

  public static fromURLParams(queryString: string): ILocation {
    const value = this.selectURLParams(queryString, 'location')[0];

    return {
      query: (value && value[0]) || null,
    };
  }
}

export class FileTypeFilter extends SearchFilter {
  public static toURLParams(value: ISiteType) {
    const filter = this.queryValueToParam('fileType', value);
    return filter ? [filter] : [];
  }

  public static fromURLParams(queryString: string): ILocation {
    const value = this.selectURLParams(queryString, 'fileType')[0];

    return {
      query: (value && value[0]) || null,
    };
  }
}

export class EstimatedCloseDateFilter extends SearchFilter {
  public static dateToString(date: Date): string {
    return [
      date.getFullYear(),
      `0${date.getMonth() + 1}`.slice(-2),
      `0${date.getDate()}`.slice(-2),
    ].join('-');
  }

  public static toURLParams(value: IEstimatedCloseDate) {
    const range = {
      start: value.start ? this.dateToString(value.start) : null,
      end: value.end ? this.dateToString(value.end) : null,
    };
    const filter = this.rangeValueToParam('closeDate', range, ['start', 'end']);

    return filter ? [filter] : [];
  }

  public static fromURLParams(queryString: string): IEstimatedCloseDate {
    const closeDate: IEstimatedCloseDate = { start: null, end: null };
    const value = this.selectURLParams(queryString, 'closeDate')[0];

    if (value) {
      const [start, end] = value;
      try {
        closeDate.start = start ? this.parseLocalDate(start) : null;
        closeDate.end = end ? this.parseLocalDate(end) : null;
      } catch (e) {
        // We do nothing in our catch block
      }
    }

    return closeDate;
  }

  public static parseLocalDate(date: string) {
    const [year, month, day] = date.split(/\D/).map((p) => Number(p));

    return new Date(year, month - 1, day, 0, 0, 0);
  }
}

export class OwningOfficeFilter extends SearchFilter {
  public static toURLParams(value: IOwningOffice) {
    if (!value.query) {
      return [];
    }

    return [
      this.queryValueToParam('office,Title Owning', value),
      this.queryValueToParam('office,Escrow Owning', value),
    ];
  }

  public static fromURLParams(queryString: string): IOwningOffice {
    const value = this.selectURLParams(queryString, 'office')[0];

    return {
      query: (value && value[1]) || null,
    };
  }
}
