import { AxiosError } from 'axios';

export enum ApiTypeKeys {
  API_REQUEST = 'API_REQUEST',
  API_SUCCESS = 'API_SUCCESS',
  API_ERROR = 'API_ERROR',
}
interface IMetaApiRequest {
  method: string;
  url: string;
  params: object;
  entity: string;
}
interface IApiRequestPayload {
  data: object | null;
  meta: IMetaApiRequest;
}

interface IMetaApiSuccess {
  entity: string;
}

interface IApiSuccessPayload {
  data: any;
  meta: IMetaApiSuccess;
}

interface IMetaApiError {
  entity: string;
}

interface IApiErrorPayload {
  data: AxiosError;
  meta: IMetaApiError;
}
export interface IApiRequestAction {
  type: string;
  payload: IApiRequestPayload;
}

export interface IApiSuccessAction {
  type: string;
  payload: IApiSuccessPayload;
}

export interface IApiError {
  type: string;
  payload: IApiErrorPayload;
}
