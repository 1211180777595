/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Responses_GetTaskResponsiblePartyOptionsResponse } from '../models/Workspace_Common_Responses_GetTaskResponsiblePartyOptionsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaskResponsiblePartyOptionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   *  (Auth)
   * @returns Workspace_Common_Responses_GetTaskResponsiblePartyOptionsResponse Success
   * @throws ApiError
   */
  public getResponsibleParties({
    orderId,
    checklistId,
    employeeChecklistId,
  }: {
    orderId: number,
    checklistId: string,
    employeeChecklistId?: number,
  }): CancelablePromise<Workspace_Common_Responses_GetTaskResponsiblePartyOptionsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/employee-checklists/{checklistId}/tasks/responsible-party-options',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
      },
      query: {
        'employeeChecklistId': employeeChecklistId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}
