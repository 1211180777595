/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_CreateInterOfficeFeesViewModel } from '../models/Workspace_API_ViewModels_CreateInterOfficeFeesViewModel';
import type { Workspace_API_ViewModels_CreateServiceFeeViewModel } from '../models/Workspace_API_ViewModels_CreateServiceFeeViewModel';
import type { Workspace_API_ViewModels_Deposits_FeeReportProgressViewModel } from '../models/Workspace_API_ViewModels_Deposits_FeeReportProgressViewModel';
import type { Workspace_API_ViewModels_InterOfficeFeesViewModel } from '../models/Workspace_API_ViewModels_InterOfficeFeesViewModel';
import type { Workspace_API_ViewModels_InterOfficeFeeViewModel } from '../models/Workspace_API_ViewModels_InterOfficeFeeViewModel';
import type { Workspace_API_ViewModels_RecordingFeesAndTaxesViewModel } from '../models/Workspace_API_ViewModels_RecordingFeesAndTaxesViewModel';
import type { Workspace_API_ViewModels_ServiceFeesViewModel } from '../models/Workspace_API_ViewModels_ServiceFeesViewModel';
import type { Workspace_API_ViewModels_ServiceFeeViewModel } from '../models/Workspace_API_ViewModels_ServiceFeeViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_AllocatableFeeAndTaxViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_AllocatableFeeAndTaxViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_CreatedAndUpdateFeesViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_CreatedAndUpdateFeesViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_CreateFeeAndTaxViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_CreateFeeAndTaxViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_CreateFeesViewModel_OrderFeeTaxViewModel_ } from '../models/Workspace_API_ViewModels_TaxesAndFees_CreateFeesViewModel_OrderFeeTaxViewModel_';
import type { Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsSummaryViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsSummaryViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_FeeSummaryViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_FeeSummaryViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_TitleEscrowFeesViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_TitleEscrowFeesViewModel';
import type { Workspace_API_ViewModels_TaxesAndFees_UpdatePaymentDetailsViewModel } from '../models/Workspace_API_ViewModels_TaxesAndFees_UpdatePaymentDetailsViewModel';
import type { Workspace_API_ViewModels_UpdateFeeStatusViewModel } from '../models/Workspace_API_ViewModels_UpdateFeeStatusViewModel';
import type { Workspace_API_ViewModels_UpdateInterOfficeFeesViewModel } from '../models/Workspace_API_ViewModels_UpdateInterOfficeFeesViewModel';
import type { Workspace_API_ViewModels_UpdateServiceFeeViewModel } from '../models/Workspace_API_ViewModels_UpdateServiceFeeViewModel';
import type { Workspace_Common_Models_Dto_TaxesAndFees_TransferFeesAndTaxesDto } from '../models/Workspace_Common_Models_Dto_TaxesAndFees_TransferFeesAndTaxesDto';
import type { Workspace_Common_Models_Dto_TaxesAndFees_UpdateFeeAndTaxDto } from '../models/Workspace_Common_Models_Dto_TaxesAndFees_UpdateFeeAndTaxDto';
import type { Workspace_Common_Models_Enums_FeeType } from '../models/Workspace_Common_Models_Enums_FeeType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderTaxesAndFeesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Fetches a list of Service Fees from an Order (Auth)
   * @returns Workspace_API_ViewModels_ServiceFeesViewModel Success
   * @throws ApiError
   */
  public getServiceFeesAsync({
    orderId,
  }: {
    /**
     * The order for fetch the service fees from
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_ServiceFeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/service-fees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Adds a batch of Service Fees to an Order (Auth)
   * @returns Workspace_API_ViewModels_ServiceFeesViewModel Success
   * @throws ApiError
   */
  public createServiceFeeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order to create the fees for
     */
    orderId: number,
    /**
     * List of Services fees to create
     */
    requestBody: Array<Workspace_API_ViewModels_CreateServiceFeeViewModel>,
  }): CancelablePromise<Workspace_API_ViewModels_ServiceFeesViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/service-fees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates a Single Service Fee (Auth)
   * @returns Workspace_API_ViewModels_ServiceFeeViewModel Success
   * @throws ApiError
   */
  public updateServiceFeeAsync({
    orderId,
    feeId,
    requestBody,
  }: {
    /**
     * The order the Fee belongs to
     */
    orderId: number,
    /**
     * The Id for the Fee to Update
     */
    feeId: number,
    /**
     * The Fee details to update
     */
    requestBody: Workspace_API_ViewModels_UpdateServiceFeeViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_ServiceFeeViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/service-fees/{feeId}',
      path: {
        'orderId': orderId,
        'feeId': feeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Fetches a List of InterOffice Fees (Auth)
   * @returns Workspace_API_ViewModels_InterOfficeFeesViewModel Success
   * @throws ApiError
   */
  public getInterOfficeFeesAsync({
    orderId,
  }: {
    /**
     * The order the fees belong to
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_InterOfficeFeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/inter-office-fees',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Creates a new interoffice fee (Auth)
   * @returns Workspace_API_ViewModels_InterOfficeFeesViewModel Success
   * @throws ApiError
   */
  public createInterOfficeFeeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order in which to create the fee
     */
    orderId: number,
    /**
     * List of interoffice fee data
     */
    requestBody: Array<Workspace_API_ViewModels_CreateInterOfficeFeesViewModel>,
  }): CancelablePromise<Workspace_API_ViewModels_InterOfficeFeesViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/inter-office-fees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates an Interoffice Fee (Auth)
   * @returns Workspace_API_ViewModels_InterOfficeFeeViewModel Success
   * @throws ApiError
   */
  public updateInterOfficeFeeAsync({
    orderId,
    feeId,
    requestBody,
  }: {
    /**
     * The order the Fee belongs to
     */
    orderId: number,
    /**
     * The ID of the Fee
     */
    feeId: number,
    /**
     * The Fee details to update
     */
    requestBody: Workspace_API_ViewModels_UpdateInterOfficeFeesViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_InterOfficeFeeViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/inter-office-fees/{feeId}',
      path: {
        'orderId': orderId,
        'feeId': feeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Allows to get the summary of the fees for a given order. (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_FeeSummaryViewModel The fee summary obtained.
   * @throws ApiError
   */
  public fetchFeeSummaryAsync({
    orderId,
  }: {
    /**
     * The order id to check for.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_FeeSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user.`,
        403: `User doesn't have the required activity right.`,
        404: `Order or Fee Not Found or External user.`,
      },
    });
  }

  /**
   * Gets Title and Escrow Fees for an Order (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_TitleEscrowFeesViewModel Success
   * @throws ApiError
   */
  public getTitleEscrowFeesAsync({
    orderId,
    withTemplate = true,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Wether template fees should be include or not
     */
    withTemplate?: boolean,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_TitleEscrowFeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/title-escrow-fees',
      path: {
        'orderId': orderId,
      },
      query: {
        'withTemplate': withTemplate,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets Title and Escrow Fees Allocations for an Order (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_TitleEscrowFeesViewModel Success
   * @throws ApiError
   */
  public getTitleEscrowFeesAllocationsAsync({
    orderId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_TitleEscrowFeesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/title-escrow-fees/allocations',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets Recording Fees and Taxes (Auth)
   * @returns Workspace_API_ViewModels_RecordingFeesAndTaxesViewModel Success
   * @throws ApiError
   */
  public getRecordingFeesAndTaxesAsync({
    orderId,
    withTemplate = true,
  }: {
    /**
     * The order to fetch from
     */
    orderId: number,
    /**
     * Whether template fees should be included or not
     */
    withTemplate?: boolean,
  }): CancelablePromise<Workspace_API_ViewModels_RecordingFeesAndTaxesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/recording-fees-taxes',
      path: {
        'orderId': orderId,
      },
      query: {
        'withTemplate': withTemplate,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets Recording Fees and Taxes Allocations (Auth)
   * @returns Workspace_API_ViewModels_RecordingFeesAndTaxesViewModel Success
   * @throws ApiError
   */
  public getRecordingFeesAndTaxesAllocationsAsync({
    orderId,
  }: {
    /**
     * The order to fetch from
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_RecordingFeesAndTaxesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/recording-fees-taxes/allocations',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Bulk creates a batch of Title, Escrow or Recording taxes fees (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_CreateFeesViewModel_OrderFeeTaxViewModel_ Success
   * @throws ApiError
   */
  public createFees({
    orderId,
    requestBody,
  }: {
    /**
     * Selected Order
     */
    orderId: number,
    /**
     * List of Fees to Create
     */
    requestBody: Array<Workspace_API_ViewModels_TaxesAndFees_CreateFeeAndTaxViewModel>,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_CreateFeesViewModel_OrderFeeTaxViewModel_> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Bulk updates a batch of Fees (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_CreatedAndUpdateFeesViewModel Success
   * @throws ApiError
   */
  public updateFeesAndTaxesAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order Id where the fees belong to
     */
    orderId: number,
    /**
     * List of Fees to Update
     */
    requestBody?: Array<Workspace_Common_Models_Dto_TaxesAndFees_UpdateFeeAndTaxDto> | null,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_CreatedAndUpdateFeesViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Cancel a fee. (Auth)
   * @returns void
   * @throws ApiError
   */
  public changeFeeStatusAsync({
    orderId,
    feeType,
    requestBody,
    cancelReason,
  }: {
    /**
     * Order id which fee belongs to.
     */
    orderId: number,
    /**
     * Fee type.
     */
    feeType: Workspace_Common_Models_Enums_FeeType,
    /**
     * The view model containing a single status and list of fees to update to that status
     */
    requestBody: Workspace_API_ViewModels_UpdateFeeStatusViewModel,
    /**
     * Cancellation reason when status is "Pending".
     */
    cancelReason?: string | null,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/{feeType}',
      path: {
        'orderId': orderId,
        'feeType': feeType,
      },
      query: {
        'cancelReason': cancelReason,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order or Fee Not Found or External user`,
      },
    });
  }

  /**
   * Updates the Payment Details for a specific Fee (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateFeePaymentDetailsAsync({
    orderId,
    feeType,
    feeId,
    requestBody,
  }: {
    /**
     * Order id which fee belongs to.
     */
    orderId: number,
    /**
     * Fee type, must be either TitleFee, EscrowFee or RecordingFeeAndTax
     */
    feeType: Workspace_Common_Models_Enums_FeeType,
    /**
     * Specific Id for the Fee
     */
    feeId: number,
    /**
     * The view model containing a single status and list of fees to update to that status
     */
    requestBody: Workspace_API_ViewModels_TaxesAndFees_UpdatePaymentDetailsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/{feeType}/{feeId}/payment-details',
      path: {
        'orderId': orderId,
        'feeType': feeType,
        'feeId': feeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order or Fee Not Found or External user`,
      },
    });
  }

  /**
   * Bulk transfers a batch of Fees (Auth)
   * @returns void
   * @throws ApiError
   */
  public transferFeesAndTaxesAsync({
    orderId,
    feeType,
    requestBody,
  }: {
    /**
     * The order Id where the fees belong to
     */
    orderId: number,
    /**
     * The Type of Fee for all entries e.g. InterOffice, Service e.t.c.
     */
    feeType: Workspace_Common_Models_Enums_FeeType,
    /**
     * List of Fees to Update
     */
    requestBody?: Workspace_Common_Models_Dto_TaxesAndFees_TransferFeesAndTaxesDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/{feeType}/_transfer',
      path: {
        'orderId': orderId,
        'feeType': feeType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Removes a Fee (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeFeeAsync({
    orderId,
    feeId,
    feeType,
  }: {
    /**
     * The order the Fee belongs to
     */
    orderId: number,
    /**
     * The ID of the fee
     */
    feeId: number,
    /**
     * The type of Fee (i.e., Service, InterOffice, etc.)
     */
    feeType: Workspace_Common_Models_Enums_FeeType,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/{feeType}/{feeId}',
      path: {
        'orderId': orderId,
        'feeId': feeId,
        'feeType': feeType,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public generateFeesReportAsync({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/report',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns Workspace_API_ViewModels_Deposits_FeeReportProgressViewModel Success
   * @throws ApiError
   */
  public getFeesReportAsync({
    orderId,
    jobId,
  }: {
    orderId: number,
    jobId?: number,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_FeeReportProgressViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/report',
      path: {
        'orderId': orderId,
      },
      query: {
        'jobId': jobId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * This endpoint allocates fees for site files. (Auth)
   * @returns void
   * @throws ApiError
   */
  public allocateFees({
    orderId,
    feeId,
    requestBody,
  }: {
    /**
     * a multisite orderId
     */
    orderId: number,
    /**
     * The fee id
     */
    feeId: number,
    /**
     * Allocation list
     */
    requestBody?: Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/{feeId}/allocations',
      path: {
        'orderId': orderId,
        'feeId': feeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * This generates an excel report of fee allocations. (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public generateReport({
    orderId,
  }: {
    /**
     * a multisite orderId
     */
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/allocations/report',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets all fee allocations for the order (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsSummaryViewModel Success
   * @throws ApiError
   */
  public getFeesAllocationsAsync({
    orderId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_TaxesAndFees_FeeAllocationsSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/allocations',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the fee information for the given fee ids (Auth)
   * @returns Workspace_API_ViewModels_TaxesAndFees_AllocatableFeeAndTaxViewModel Success
   * @throws ApiError
   */
  public getFeesByIds({
    orderId,
    recording,
    title,
    escrow,
  }: {
    /**
     * The order id
     */
    orderId: number,
    /**
     * The recording and taxes fees to get the info
     */
    recording?: Array<number> | null,
    /**
     * The title fees to get the info
     */
    title?: Array<number> | null,
    /**
     * The escrow fees to get the info
     */
    escrow?: Array<number> | null,
  }): CancelablePromise<Array<Workspace_API_ViewModels_TaxesAndFees_AllocatableFeeAndTaxViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/taxes-and-fees/fees',
      path: {
        'orderId': orderId,
      },
      query: {
        'recording': recording,
        'title': title,
        'escrow': escrow,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}
