/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableProrationsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableProrationsViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ProrationDetailViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ProrationDetailViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsDetailViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsDetailViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsRecalculationSummariesViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsRecalculationSummariesViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_UpdateProrationsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_UpdateProrationsViewModel';
import type { Workspace_Common_Models_Enums_AllocationType } from '../models/Workspace_Common_Models_Enums_AllocationType';
import type { Workspace_Common_Models_Enums_ProrationType } from '../models/Workspace_Common_Models_Enums_ProrationType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProrationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets all prorations for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsViewModel Order prorations
   * @throws ApiError
   */
  public getProrationsAsync({
    orderId,
    siteFileId,
    groupId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/prorations',
      path: {
        'orderId': orderId,
      },
      query: {
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Updates an proration (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateProrationAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Type of the proration
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_ProrationDetailViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/charges/prorations',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found or proration not found`,
        409: `Validation error`,
      },
    });
  }

  /**
   * Gets list of allocatable prorations (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableProrationsViewModel Success
   * @throws ApiError
   */
  public getAllocatableAsync({
    orderId,
    prorationType,
    allocationType,
    siteFileId,
    groupId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    prorationType?: Workspace_Common_Models_Enums_ProrationType,
    allocationType?: Workspace_Common_Models_Enums_AllocationType,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableProrationsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/prorations/allocatable',
      path: {
        'orderId': orderId,
      },
      query: {
        'ProrationType': prorationType,
        'AllocationType': allocationType,
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Updates prorations for an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateProrationsAsync({
    orderId,
    prorationType,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Type of the proration
     */
    prorationType?: Workspace_Common_Models_Enums_ProrationType,
    /**
     * Edited prorations
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_UpdateProrationsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/prorations/{prorationType}',
      path: {
        'orderId': orderId,
      },
      query: {
        'prorationType': prorationType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Gets details of all prorations of the specified type for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsDetailViewModel Order prorations
   * @throws ApiError
   */
  public getProrationsDetailsAsync({
    orderId,
    prorationType,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    /**
     * Proration type (Tax, Rent or Miscellaneous)
     */
    prorationType?: Workspace_Common_Models_Enums_ProrationType,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/prorations/{prorationType}',
      path: {
        'orderId': orderId,
      },
      query: {
        'prorationType': prorationType,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Create prorations for an order with full allocation to given site (Auth)
   * @returns void
   * @throws ApiError
   */
  public createProrationsAsync({
    orderId,
    siteFileId,
    prorationType,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Site Id which will be allocated
     */
    siteFileId: number,
    /**
     * Type of the proration
     */
    prorationType?: Workspace_Common_Models_Enums_ProrationType,
    /**
     * Prorations to create
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_UpdateProrationsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/prorations/{prorationType}/site/{siteFileId}',
      path: {
        'orderId': orderId,
        'siteFileId': siteFileId,
      },
      query: {
        'prorationType': prorationType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Gets recalculation summary of all prorations (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsRecalculationSummariesViewModel Order prorations
   * @throws ApiError
   */
  public getProrationsRecalculationSummaryAsync({
    orderId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ProrationsRecalculationSummariesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/prorations/recalculation',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Order is Site file`,
      },
    });
  }

  /**
   * Gets a single proration  by id (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_ProrationDetailViewModel Proration returned
   * @throws ApiError
   */
  public getProrationAsync({
    orderId,
    prorationId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Proration Id
     */
    prorationId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_ProrationDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/prorations/{prorationId}',
      path: {
        'orderId': orderId,
        'prorationId': prorationId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

}
