/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentsViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableAdjustmentsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableAdjustmentsViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_UpdateAdjustmentsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_UpdateAdjustmentsViewModel';
import type { Workspace_Common_Models_Enums_AdjustmentChargeType } from '../models/Workspace_Common_Models_Enums_AdjustmentChargeType';
import type { Workspace_Common_Models_Enums_AllocationType } from '../models/Workspace_Common_Models_Enums_AllocationType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdjustmentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets all adjustments for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentsViewModel Order adjustments
   * @throws ApiError
   */
  public getAdjustmentsAsync({
    orderId,
    siteFileId,
    groupId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments',
      path: {
        'orderId': orderId,
      },
      query: {
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Gets list of allocatable adjustments (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableAdjustmentsViewModel Success
   * @throws ApiError
   */
  public getAllocatableAsync({
    orderId,
    adjustmentType,
    allocationType,
    siteFileId,
    groupId,
  }: {
    /**
     * Id of the order
     */
    orderId: number,
    adjustmentType?: Workspace_Common_Models_Enums_AdjustmentChargeType,
    allocationType?: Workspace_Common_Models_Enums_AllocationType,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableAdjustmentsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/allocatable',
      path: {
        'orderId': orderId,
      },
      query: {
        'AdjustmentType': adjustmentType,
        'AllocationType': allocationType,
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Updates adjustments for an order (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateAdjustmentsAsync({
    orderId,
    adjustmentType,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Type of the adjustment
     */
    adjustmentType?: Workspace_Common_Models_Enums_AdjustmentChargeType,
    /**
     * Updated adjustments
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_UpdateAdjustmentsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/{adjustmentType}',
      path: {
        'orderId': orderId,
      },
      query: {
        'adjustmentType': adjustmentType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Create adjustments for an order with full allocation to given site (Auth)
   * @returns void
   * @throws ApiError
   */
  public createAdjustmentsAsync({
    orderId,
    siteFileId,
    adjustmentType,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Site Id which will be allocated
     */
    siteFileId: number,
    /**
     * Type of the adjustment
     */
    adjustmentType?: Workspace_Common_Models_Enums_AdjustmentChargeType,
    /**
     * Adjustments to create
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_UpdateAdjustmentsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/{adjustmentType}/site/{siteFileId}',
      path: {
        'orderId': orderId,
        'siteFileId': siteFileId,
      },
      query: {
        'adjustmentType': adjustmentType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Gets an adjustment using adjustment id and type (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentViewModel Adjustment returned
   * @throws ApiError
   */
  public getAdjustmentByIdAsync({
    orderId,
    adjustmentId,
    adjustmentType,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * adjustment Id
     */
    adjustmentId: number,
    /**
     * Type of the adjustment
     */
    adjustmentType?: Workspace_Common_Models_Enums_AdjustmentChargeType,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/{adjustmentType}/{adjustmentId}',
      path: {
        'orderId': orderId,
        'adjustmentId': adjustmentId,
      },
      query: {
        'adjustmentType': adjustmentType,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Updates an adjustment (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateAdjustmentAsync({
    orderId,
    adjustmentId,
    adjustmentType,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    adjustmentId: string,
    /**
     * Type of adjustment
     */
    adjustmentType?: Workspace_Common_Models_Enums_AdjustmentChargeType,
    /**
     * Type of the adjustment
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_AdjustmentViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/adjustments/{adjustmentType}/{adjustmentId}',
      path: {
        'orderId': orderId,
        'adjustmentId': adjustmentId,
      },
      query: {
        'adjustmentType': adjustmentType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Validation error`,
      },
    });
  }

}
