import { red5, redGhost1, redGhost2 } from 'config/colors';
import {
  blue,
  blueHover,
  bluePressed,
  catskillWhite,
  ghostButtonHoverBackground,
  ghostButtonPressedBackground,
  insetShadow,
  kashmirBlue,
  persianRed,
  persianRedDisabled,
  persianRedHover,
  placeholderColor,
  sanJuan,
  shadow3,
  trout,
  white,
  whiteHover,
  whitePressed,
} from 'config/theme';
import { ButtonVariants, ButtonColors, ButtonSizes } from './index';

export const createVariant = (variant: ButtonVariants) => {
  switch (variant) {
    case 'primary':
      return 'contained';

    case 'secondary':
      return 'outlined';

    case 'ghost':
      return 'text';

    case 'link':
      return 'text';
  }
};

export const createColorStyling = (
  variant: ButtonVariants,
  color: ButtonColors | 'gray',
  loading: boolean
) => {
  if (color === 'blue') {
    if (variant === 'primary') {
      return `
        color: ${white};
        background-color: ${blue};

        &:hover {
          background-color: ${kashmirBlue};
          box-shadow: ${shadow3};
        }

        &:active {
          background-color: ${sanJuan};
          box-shadow: ${insetShadow};
        }

        &[disabled] {
          color: ${white};
          background-color: ${blue};
          opacity: 40%;
        }

        .spinner {
          border-color: ${white};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            background-color: ${sanJuan};
            box-shadow: ${insetShadow};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'secondary') {
      return `
        color: ${blue};
        background-color: transparent;
        border: 1px solid ${blue};

        &:hover {
          color: ${white};
          background-color: ${blue};
        }

        &:active {
          color: ${white};
          background-color: ${sanJuan};
        }

        &[disabled] {
          color: ${blue};
          background-color: transparent;
          border: 1px solid ${blue};
          opacity: 40%;
        }

        .spinner {
          border-color: ${white};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            opacity: 100%;
            background-color: ${sanJuan};
            box-shadow: ${insetShadow};
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'ghost') {
      return `
        color: ${blue};
        background-color: transparent;

        &:hover {
          background-color: ${blueHover};
        }

        &:active {
          background-color: ${bluePressed};
        }

        &[disabled] {
          color: ${blue};
          background-color: transparent;
          opacity: 40%;
        }

        .spinner {
          border-color: ${blue};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            background-color: ${bluePressed};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'link') {
      return `
        padding: 0;
        width: fit-content;
        min-width: 0;
        color: ${blue};
        background-color: transparent;

        &:hover {
          background-color: transparent;
          text-decoration: underline;
        }

        &:active {
          background-color: transparent;
          text-decoration: underline;
        }

        &[disabled] {
          color: ${blue};
          opacity: 40%;
        }

        .spinner {
          border-color: ${blue};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            color: ${bluePressed};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }
  }

  if (color === 'white') {
    if (variant === 'primary') {
      return `
        color: ${blue};
        background-color: ${white};

        &:hover {
          background-color: ${catskillWhite};
          box-shadow: ${shadow3};
        }

        &:active {
          background-color: ${catskillWhite};
          box-shadow: ${insetShadow};
        }

        &[disabled] {
          color: ${blue};
          background-color: ${white};
          opacity: 40%;
        }

        .spinner {
          border-color: ${blue};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            background-color: ${catskillWhite};
            box-shadow: ${insetShadow};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'secondary') {
      return `
        color: ${white};
        background-color: transparent;
        border: 1px solid ${white};

        &:hover {
          color: ${blue};
          background-color: ${white};
        }

        &:active {
          color: ${blue};
          background-color: transparent;
          border: unset;
        }

        &[disabled] {
          color: ${white};
          background-color: transparent;
          border: 1px solid ${white};
          opacity: 40%;
        }

        .spinner {
          border-color: ${blue};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            border: 1px solid transparent;
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'ghost') {
      return `
        color: ${white};
        background-color: transparent;

        &:hover {
          background-color: ${whiteHover};
        }

        &:active {
          background-color: ${whitePressed};
        }

        &[disabled] {
          color: ${white};
          background-color: transparent;
          opacity: 40%;
        }

        .spinner {
          border-color: ${white};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }
  }

  if (color === 'red') {
    if (variant === 'primary') {
      return `
        color: ${white};
        background-color: ${persianRed};

        &:hover {
          background-color: ${persianRedHover};
          box-shadow: ${shadow3};
        }

        &:active {
          box-shadow: ${insetShadow};
        }

        &[disabled] {
          color: ${white};
          background-color: ${persianRedDisabled};
        }

        .spinner {
          border-color: ${white};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            background-color: ${persianRed};
            box-shadow: ${insetShadow};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'secondary') {
      return `
        color: ${persianRed};
        background-color: transparent;
        border: 1px solid ${persianRed};

        &:hover {
          color: ${white};
          background-color: ${persianRed};
        }

        &:active {
          color: ${white};
          background-color: ${persianRed};
          box-shadow: ${insetShadow};
        }

        &[disabled] {
          color: ${persianRedDisabled};
          background-color: transparent;
          border: 1px solid ${persianRedDisabled};
          opacity: 40%;
        }

        .spinner {
          border-color: ${white};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            background-color: ${persianRed};
            border: 1px solid ${persianRed};
            box-shadow: ${insetShadow};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    if (variant === 'ghost') {
      return `
        color: ${persianRed};
        background-color: transparent;

        &:hover {
          background-color: ${redGhost1};
        }

        &:active {
          background-color: ${redGhost2};
        }

        &[disabled] {
          color: ${red5};
          background-color: transparent;
        }

        .spinner {
          border-color: ${persianRed};
          border-right-color: transparent;
        }

        ${
          loading
            ? `
          &[disabled] {
            background-color: ${redGhost2};
            opacity: 100%;
          }
          `
            : ''
        }
      `;
    }

    throw new Error(
      'The red button color variant may only be used with a primary, secondary, or ghost variant button'
    );
  }

  if (color === 'gray') {
    if (variant === 'ghost') {
      return `
        color: ${trout};
        background-color: transparent;

        &:hover {
          background-color: ${ghostButtonHoverBackground};
        }

        &:active {
          background-color: ${ghostButtonPressedBackground};
        }

        &[disabled] {
          color: ${placeholderColor};
        }

        .spinner {
          border-color: ${trout};
          border-right-color: transparent;
        }
      `;
    }

    throw new Error(
      'The button gray color variant may only be used with a ghost variant button'
    );
  }
};

export const createPadding = (
  size: ButtonSizes,
  hasStartIcon: boolean,
  hasEndIcon: boolean
) => {
  const leftOffset = hasStartIcon ? 8 : 0;
  const rightOffset = hasEndIcon ? 8 : 0;

  switch (size) {
    case 'large':
    case 'medium':
      return `
        padding-left: ${24 - leftOffset}px;
        padding-right: ${24 - rightOffset}px;
        padding-top: 0px;
        padding-bottom: 0px;
      `;

    case 'small':
    case 'x-small':
      return `
        padding-left: ${16 - leftOffset}px;
        padding-right: ${16 - rightOffset}px;
        padding-top: 0px;
        padding-bottom: 0px;
      `;
  }
};

export const MEDIUM_BUTTON_HEIGHT = 40;
export const createHeight = (size: ButtonSizes) => {
  switch (size) {
    case 'large':
      return 'height: 48px;';

    case 'medium':
      return `height: ${MEDIUM_BUTTON_HEIGHT}px;`;

    case 'small':
      return 'height: 36px;';

    case 'x-small':
      return 'height: 32px;';
  }
};

export const getLoadingTop = (size: ButtonSizes) => {
  switch (size) {
    case 'large':
      return 'top: 12px;';

    case 'medium':
      return `top: 10px;`;

    default:
      return 'top: 6px;';
  }
};
