/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_TransactionPartyType } from '../models/Workspace_Common_Models_TransactionPartyType';
import type { Workspace_Common_Responses_TransactionPartyContact } from '../models/Workspace_Common_Responses_TransactionPartyContact';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PartiesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets a unique list of contacts for an organization on a file. (Auth)
   * @returns Workspace_Common_Responses_TransactionPartyContact File has transaction party contacts
   * @throws ApiError
   */
  public getTransactionPartyContactsAsync({
    fileId,
    type,
    fastId,
  }: {
    /**
     * FAST File ID
     */
    fileId: number,
    /**
     * Transaction party type
     */
    type: Workspace_Common_Models_TransactionPartyType,
    /**
     * FAST id for the transaction party type. If type is an organization, fast id is the organization id, else its the principal id
     */
    fastId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_TransactionPartyContact>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/files/{fileId}/{type}s/{fastId}/contacts',
      path: {
        'fileId': fileId,
        'type': type,
        'fastId': fastId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `There are no contacts.`,
      },
    });
  }

}
