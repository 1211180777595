import React from 'react';
import styled from 'styled-components';
import googlePlayBadge from 'images/googlePlayBadge.png';

const redirect = () => {
  const specs = 'noopener, noreferrer';
  const baseURL = 'https://play.google.com/store/apps/details?id=';
  window.open(baseURL + AppConfig.playStoreId, 'windowOpenTab', specs);
};

const GooglePlayLink: React.FC = () => {
  return (
    <a onClick={redirect}>
      <GooglePlayLinkImage alt="google store icon" src={googlePlayBadge} />
    </a>
  );
};
export default GooglePlayLink;

const GooglePlayLinkImage = styled.img`
  max-height: 40px;
`;
