import { SearchTypeKeys } from 'store/search/types';
import {
  FiltersTypeKeys,
  FiltersAction,
  IFilters,
} from 'store/searchFilters/types';

export const initialState: IFilters = {
  siteType: {
    query: null,
  },
  dealStatus: {
    Open: false,
    Closed: false,
    Reserved: false,
    Cancelled: false,
    OpenInError: false,
  },
  location: {
    query: null,
  },
  estimatedCloseDate: {
    start: null,
    end: null,
  },
  owningOffice: {
    query: null,
  },
};

export default (state = initialState, action: FiltersAction) => {
  switch (action.type) {
    case FiltersTypeKeys.SET:
      return {
        ...state,
        ...action.filters,
      };
    case SearchTypeKeys.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
