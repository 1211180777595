import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5.00049H10V4.00049H7V5.00049ZM7 10.0005H10V9.00049H7V10.0005ZM10 14.0005H2C0.9 14.0005 0 13.1005 0 12.0005V2.00049C0 0.900488 0.9 0.000488281 2 0.000488281H10C11.1 0.000488281 12 0.900488 12 2.00049V12.0005C12 13.1005 11.1 14.0005 10 14.0005ZM2 6.00049H6V2.00049H2V6.00049ZM3 3.00049H5V5.00049H3V3.00049ZM2 12.0005H6V8.00049H2V12.0005ZM3 9.00049H5V11.0005H3V9.00049Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
