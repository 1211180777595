import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Dispatch, IApplicationState } from 'store';
import {
  IInProgressDocumentUpload,
  isInProgressUpload,
  ICompletedDocumentUpload,
  OrderTypeKeys,
} from 'store/orders/types';
import {
  forestGreen,
  headerZIndex,
  lighterBlue,
  singedSienna,
  persianRed,
  shuttleGrey,
  white,
} from 'config/theme';
import { pluralize, truncate } from 'utils/text';
import ChevronIcon from 'react-icons/lib/md/chevron-left';
import CloseIcon from 'react-icons/lib/md/close';
import SuccessIcon from 'react-icons/lib/md/check-circle';
import ErrorIcon from 'react-icons/lib/md/error';
import { clearActiveDocumentUploads } from 'store/orders/actions';
import { isInternalUser } from 'store/auth/getters';
import { Location } from 'history';
import { Row } from 'components/ui/Box';
import { ReadonlyFileName } from 'components/forms/FileField/ReadonlyFileName';
import BlockLoader from 'components/BlockLoader';
import UploadProgressLoader from 'components/UploadProgressLoader';
import { generateInternalDocumentName } from 'utils/orderDocuments';
import { gray8 } from 'config/colors';
interface IConnectedProps {
  dispatch: Dispatch;
  location: Location;
  isExternal: boolean;
  inProgressUploads: IInProgressDocumentUpload[];
  finishedUploads: ICompletedDocumentUpload[];
}

const headerHeight = 56;
const rowHeight = 70;
const maxVisibleRows = 4;
const domIdPrefix = 'doc-upload-progress';

const ProgressHeader = (props: {
  isExpanded: boolean;
  uploadIsComplete: boolean;
  onToggleExpanded: () => void;
  onClose: () => void;
  totalUploads: number;
}) => (
  <StyledProgressHeader>
    <div>
      {props.totalUploads} {pluralize(props.totalUploads, 'Document')}{' '}
      {props.uploadIsComplete ? 'Uploaded' : 'Uploading'}
      {props.uploadIsComplete ? '' : '...'}
    </div>
    <ButtonContainer
      onClick={
        props.isExpanded && props.uploadIsComplete
          ? props.onClose
          : props.onToggleExpanded
      }
      id={`${domIdPrefix}__button__close`}
    >
      {props.isExpanded ? (
        props.uploadIsComplete ? (
          <StyledCloseIcon />
        ) : (
          <ExpandedChevron />
        )
      ) : (
        <CollapsedChevron />
      )}
    </ButtonContainer>
  </StyledProgressHeader>
);

type IProgressRowProps = (
  | IInProgressDocumentUpload
  | ICompletedDocumentUpload
) & {
  finishedUploading: boolean;
  hasAnyErrors: boolean;
  error?: any;
};

const ProgressRow = (props: IProgressRowProps) => {
  const virusScanError = props.error?.response?.data;
  const hasError = !isInProgressUpload(props) && props.error;
  const iconStyle = { height: 23, width: 23 };
  const subText = props.metadata.displayName
    ? generateInternalDocumentName(props)
    : props.file.name;

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function formatFileSizeToString(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  const formatErrorMsg = () => {
    if (virusScanError && typeof virusScanError === 'string') {
      return removeLastPeriod(virusScanError);
    } else if (virusScanError && virusScanError.errors?.fileName[0]) {
      return removeLastPeriod(virusScanError.errors?.fileName[0]);
    } else {
      return 'Upload failed Please try again';
    }
  };

  const removeLastPeriod = (str: string): string => {
    const lastPeriodIndex = str.lastIndexOf('.');
    return lastPeriodIndex !== -1 ? str.slice(0, lastPeriodIndex) : str;
  };

  return (
    <div>
      <Row
        justify="between"
        alignItems="center"
        style={{
          height: rowHeight,
          padding: 16,
        }}
      >
        <ReadonlyFileName
          fileName={props.file.name}
          displayName={props.metadata.displayName || props.metadata.subType}
          subText={truncate(subText, 20)}
          subTextStyleOverrides={{
            fontSize: !props.metadata.displayName ? '14px' : '',
            fontWeight: !props.metadata.displayName ? '500' : '',
            color: props.metadata.displayName ? gray8 : '',
          }}
          maxWidth={180}
        />
        <div>
          {hasError ? (
            <Row alignItems="center">
              <ErrorContainer>{formatErrorMsg()}</ErrorContainer>
              <ErrorIcon style={{ ...iconStyle, color: persianRed }} />
            </Row>
          ) : isInProgressUpload(props) ? (
            formatFileSizeToString(props.file.size)
          ) : (
            <SuccessIcon style={{ ...iconStyle, color: forestGreen }} />
          )}
        </div>
      </Row>
    </div>
  );
};

const OrderDocumentUploadProgress = (
  props: IConnectedProps & IProgressRowProps
) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const hasError = !isInProgressUpload(props) && props.error;
  const [animateOut, setAnimateOut] = useState(false);
  const hasAnyErrors = !!props.finishedUploads.filter((u) => !!u.error).length;

  useEffect(() => {
    if (props.isExternal) {
      if (props.inProgressUploads.length) return;
      if (!props.finishedUploads.length) return;

      if (hasAnyErrors) setTimeout(() => null, 7000);
      else {
        setTimeout(() => {
          setAnimateOut(true);
        }, 7000);
        setTimeout(() => {
          setAnimateOut(false);
          props.dispatch({
            type: OrderTypeKeys.UI_REMOVE_SUCCESSFUL_UPLOADS,
          });
        }, 10000);
      }
    }
    if (
      !props.inProgressUploads.length &&
      !hasAnyErrors &&
      props.finishedUploads.length
    ) {
      setTimeout(function () {
        props.dispatch(clearActiveDocumentUploads());
      }, 2500);
    }
  }, [props.inProgressUploads, props.finishedUploads, props.dispatch]);

  if (!props.inProgressUploads.length && !props.finishedUploads.length) {
    return null;
  }

  return (
    <FixedContainer className={animateOut ? 'animate-out-z' : undefined}>
      <ProgressHeader
        isExpanded={isExpanded}
        uploadIsComplete={!props.inProgressUploads.length}
        totalUploads={
          props.inProgressUploads.length + props.finishedUploads.length
        }
        onToggleExpanded={() => setIsExpanded(!isExpanded)}
        onClose={() => props.dispatch(clearActiveDocumentUploads())}
      />
      <div
        style={{
          overflowY: 'auto',
          maxHeight: rowHeight * maxVisibleRows,
          overflowX: 'hidden',
        }}
      >
        {isExpanded &&
          props.inProgressUploads.map((u, i) => (
            <div
              key={i}
              style={{
                marginBottom: '13px',
                borderBottom: `1px solid ${lighterBlue}`,
                ...(hasError && { backgroundColor: singedSienna }),
              }}
            >
              <ProgressRow
                {...u}
                finishedUploading={props.inProgressUploads.length === 0}
                hasAnyErrors={hasAnyErrors}
                key={`upload-${i}`}
              />
              <UploadProgressLoader value={u.progress} />
            </div>
          ))}

        {isExpanded &&
          props.finishedUploads.map((u, i) => (
            <div
              key={i}
              style={{
                marginBottom: '11px',
                borderBottom: `1px solid ${lighterBlue}`,
                ...(hasError && { backgroundColor: singedSienna }),
              }}
            >
              <ProgressRow
                finishedUploading={props.inProgressUploads.length === 0}
                hasAnyErrors={hasAnyErrors}
                {...u}
                key={`upload-${props.inProgressUploads.length + 1 + i}`}
              />
              <div style={{ padding: '2px' }}></div>
            </div>
          ))}
      </div>
    </FixedContainer>
  );
};

const mapStateToProps = (
  state: IApplicationState
): Omit<IConnectedProps, 'dispatch' | 'location'> => ({
  inProgressUploads: state.orders.ui.multiDocumentsUpload.inProgressUploads,
  finishedUploads: state.orders.ui.multiDocumentsUpload.finishedUploads,
  isExternal: !isInternalUser(state.auth),
});

export default withRouter(
  connect(mapStateToProps)(OrderDocumentUploadProgress as any)
);

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${rowHeight * maxVisibleRows + headerHeight}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
  transform: translateX(0);
  transition: transform 5s ease;
  &.animate-out-z {
    transform: translateX(100vw);
  }
`;

const ButtonContainer = styled.div`
  margin-right: 8px;
  width: 40px;
  height: 40px;

  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${shuttleGrey};
    }
  }
`;

const StyledChevron = styled(ChevronIcon)`
  width: 30px;
  height: 30px;
  color: ${shuttleGrey};
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;
  height: 30px;
  color: ${shuttleGrey};
`;

const CollapsedChevron = styled(StyledChevron)`
  transform: rotate(90deg);
`;

const ExpandedChevron = styled(StyledChevron)`
  transform: rotate(270deg);
`;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })`
  height: ${headerHeight}px;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 19px;
  padding: 19px 0 18px 16px;
  border-bottom: 2px solid ${lighterBlue};
  align-items: center;
`;

const ErrorContainer = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: right;
  color: ${persianRed};
  width: 146px;
  margin-right: 9px;
`;

export const SmallBlockLoader = styled(BlockLoader)`
  & .ball-clip-rotate > div {
    height: 20px;
    width: 20px;
  }
`;

OrderDocumentUploadProgress.displayName = 'OrderDocumentUploadProgress';
ProgressRow.displayName = 'ProgressRow';
ButtonContainer.displayName = 'ButtonContainer';
CollapsedChevron.displayName = 'CollapsedChevron';
ExpandedChevron.displayName = 'ExpandedChevron';
StyledCloseIcon.displayName = 'StyledCloseIcon';
