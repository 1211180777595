import {
  ISettingsAction,
  ISettingsState,
  SettingsTypeKeys,
  INotificationPreference,
} from './types';

export const initialState: ISettingsState = {
  notificationsPreferences: [],
};

export default (state = initialState, action: ISettingsAction) => {
  switch (action.type) {
    case SettingsTypeKeys.SET_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        notificationsPreferences: action.notificationsPreferences,
      };

    case SettingsTypeKeys.SET_NOTIFICATION_PREFERENCE: {
      const notificationsPreferences = state.notificationsPreferences.map(
        (np: INotificationPreference) => {
          if (np.type === action.notificationsPreference.type) {
            return action.notificationsPreference;
          } else {
            return { ...np };
          }
        }
      );

      return {
        ...state,
        notificationsPreferences,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
