import React, { useState, useEffect } from 'react';
import { Row } from 'components/ui/Box';
import Loadable from 'components/ui/Loadable';
import {
  BuildingsIcon,
  ChevronDownIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { primaryBlue8, neutralGrayGhost2 } from 'config/colors';
import {
  SMALL_BODY_BOOK,
  SUB_TEXT_BOOK,
  SUB_TEXT_BOLD,
} from 'config/typography';
import { IOfficeHash } from 'store/offices/types';
import FAOfficeDropdown from './FAOfficeDropdown';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

interface IFAOfficeProps {
  disabled?: boolean;
  offices: IOfficeHash;
  officeId: number | string | null;
  officeLoading: boolean;
  setOfficeId: (officeId: number) => void;
}

export default function FAOffice({
  disabled,
  offices,
  officeId,
  setOfficeId,
  officeLoading,
}: IFAOfficeProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [id, setId] = useState<string>('');
  const loading =
    officeId === null || Object.keys(offices).length === 0 || officeLoading;
  const office = officeId !== null ? offices[officeId] : null;

  const url = window.location.pathname;

  useEffect(() => {
    if (url.includes('customer-uploads')) {
      setId('Documents_E_OfficeVw_ExternalUploads_change_office');
    }
  }, [url]);

  const handleClickAway = () => {
    setShowDropdown(false);
  };

  return (
    <Container
      disabled={disabled}
      id={id}
      loading={loading}
      menuIsOpen={showDropdown}
      onClick={
        showDropdown || loading || disabled
          ? undefined
          : () => setShowDropdown(true)
      }
    >
      <BuildingsIcon fill={primaryBlue8} />
      <div style={{ marginLeft: 12 }}>
        <Loadable
          loading={loading}
          height={20}
          width={loading ? 300 : undefined}
        >
          {office && (
            <Row alignItems="center">
              <div css={SMALL_BODY_BOOK}>{office.name}</div>
              {!disabled && <ChevronDownIcon style={{ marginLeft: 10 }} />}
            </Row>
          )}
        </Loadable>
        <div style={{ marginTop: 6 }}>
          <Loadable loading={loading} height={18} width={100}>
            {office && (
              <>
                <OfficeBUID>
                  Office BUID: <span css={SUB_TEXT_BOLD}>{office.fastId}</span>
                </OfficeBUID>
              </>
            )}
          </Loadable>
        </div>
      </div>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
        <div data-testid={'FA_Office_Dropdown'} id={`FA_Office_Dropdown`}>
          <FAOfficeDropdown
            close={() => setShowDropdown(false)}
            offices={offices}
            selectedId={officeId}
            setOfficeId={setOfficeId}
            show={showDropdown}
          />
        </div>
      </ClickAwayListener>
    </Container>
  );
}

const Container = styled(Row).withConfig<{
  disabled?: boolean;
  loading: boolean;
  menuIsOpen: boolean;
}>({
  shouldForwardProp: (prop) => prop !== 'loading',
})`
  background-color: ${({ menuIsOpen }) =>
    menuIsOpen ? neutralGrayGhost2 : 'inherit'};
  border-radius: 3px;
  margin: 10px 0;
  padding: 10px 12px;
  position: relative;

  ${({ loading, disabled }) =>
    loading || disabled
      ? ''
      : `
    &:hover {
      background-color: ${neutralGrayGhost2};
      cursor: pointer;
    }
  `};
`;

const OfficeBUID = styled.span`
  ${SUB_TEXT_BOOK};
  white-space: nowrap;
`;
