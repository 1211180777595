import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M16.7147 11.4928L15.6257 12.606C14.7545 13.4772 14.21 14.2153 14.21 16.0303H11.79V15.4253C11.79 14.0822 12.3345 12.8722 13.2057 12.001L14.7061 10.4764C15.1538 10.0408 15.42 9.43579 15.42 8.77029C15.42 7.42719 14.331 6.35029 13 6.35029C12.3582 6.35029 11.7427 6.60526 11.2888 7.05909C10.835 7.51293 10.58 8.12847 10.58 8.77029H8.16002C8.16002 7.48665 8.66995 6.25557 9.57763 5.3479C10.4853 4.44022 11.7164 3.93029 13 3.93029C14.2837 3.93029 15.5147 4.44022 16.4224 5.3479C17.3301 6.25557 17.84 7.48665 17.84 8.77029C17.84 9.83509 17.4044 10.791 16.7147 11.4928ZM14.21 20.8703H11.79V18.4503H14.21V20.8703ZM13 0.300293C11.411 0.300293 9.83759 0.613269 8.36955 1.22135C6.90152 1.82943 5.56762 2.72071 4.44403 3.8443C2.17484 6.11349 0.900024 9.19117 0.900024 12.4003C0.900024 15.6094 2.17484 18.6871 4.44403 20.9563C5.56762 22.0799 6.90152 22.9712 8.36955 23.5792C9.83759 24.1873 11.411 24.5003 13 24.5003C16.2091 24.5003 19.2868 23.2255 21.556 20.9563C23.8252 18.6871 25.1 15.6094 25.1 12.4003C25.1 5.70899 19.655 0.300293 13 0.300293Z"
      fill="#345580"
    />
  </svg>
);
