/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Eclipse_SDK_DTO_ImageFileExtensionType } from '../models/Eclipse_SDK_DTO_ImageFileExtensionType';
import type { System_IO_MemoryStream } from '../models/System_IO_MemoryStream';
import type { Workspace_API_ViewModels_Deposits_AdjustDepositViewModel } from '../models/Workspace_API_ViewModels_Deposits_AdjustDepositViewModel';
import type { Workspace_API_ViewModels_Deposits_AdjustWithOverdraftViewModel } from '../models/Workspace_API_ViewModels_Deposits_AdjustWithOverdraftViewModel';
import type { Workspace_API_ViewModels_Deposits_AllocationViewModel } from '../models/Workspace_API_ViewModels_Deposits_AllocationViewModel';
import type { Workspace_API_ViewModels_Deposits_CanDepositBeLinkedViewModel } from '../models/Workspace_API_ViewModels_Deposits_CanDepositBeLinkedViewModel';
import type { Workspace_API_ViewModels_Deposits_CreateDepositViewModel } from '../models/Workspace_API_ViewModels_Deposits_CreateDepositViewModel';
import type { Workspace_API_ViewModels_Deposits_DepositAllocationViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositAllocationViewModel';
import type { Workspace_API_ViewModels_Deposits_DepositMasterAllocationViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositMasterAllocationViewModel';
import type { Workspace_API_ViewModels_Deposits_DepositMilestoneUpdateEarnestMoneyModel } from '../models/Workspace_API_ViewModels_Deposits_DepositMilestoneUpdateEarnestMoneyModel';
import type { Workspace_API_ViewModels_Deposits_DepositMilestoneViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositMilestoneViewModel';
import type { Workspace_API_ViewModels_Deposits_DepositOverdraftLinksViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositOverdraftLinksViewModel';
import type { Workspace_API_ViewModels_Deposits_DepositsViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositsViewModel';
import type { Workspace_API_ViewModels_Deposits_DepositViewModel } from '../models/Workspace_API_ViewModels_Deposits_DepositViewModel';
import type { Workspace_API_ViewModels_Deposits_Summary_SummaryViewModel } from '../models/Workspace_API_ViewModels_Deposits_Summary_SummaryViewModel';
import type { Workspace_API_ViewModels_Deposits_UpdateDepositDetailsViewModel } from '../models/Workspace_API_ViewModels_Deposits_UpdateDepositDetailsViewModel';
import type { Workspace_Common_Models_Dto_Escrow_AllocatedLevel } from '../models/Workspace_Common_Models_Dto_Escrow_AllocatedLevel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderDepositsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve all deposits for an order (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositsViewModel content was successfully fetched from eclipse
   * @throws ApiError
   */
  public getDeposits({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_DepositsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `Order or deposit Not Found`,
      },
    });
  }

  /**
   * Retrieve all deposits for an order to populate for manage Milestone page (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositMilestoneViewModel Deposit data content was successfully fetched for milestones page
   * @throws ApiError
   */
  public getDepositsForMilestones({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_Deposits_DepositMilestoneViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/deposit-milestones',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Retrieve the calculated money for EMD. Returns amount only if show EMD toggle is turned On. (Auth)
   * @returns any Earnest money was successfully calculated from Deposit data.
   * @throws ApiError
   */
  public getTotalEarnestMoney({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/total-earnestmoney',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Deposit Detail (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositViewModel content was successfully fetched from eclipse
   * @throws ApiError
   */
  public getDepositDetails({
    orderId,
    depositId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_DepositViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `Order or deposit Not Found`,
      },
    });
  }

  /**
   * Cancels a Deposit (Auth)
   * @returns void
   * @throws ApiError
   */
  public cancelDeposit({
    orderId,
    depositId,
    cancelReason,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
    /**
     * Reason to cancel the Deposit
     */
    cancelReason: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      query: {
        'cancelReason': cancelReason,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user`,
        409: `Adjust a Deposit requires an Overdraft or the
        Deposit doesn't have the correct info to adjust`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Adjusts a Deposit (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositViewModel Adjustment was successful
   * @throws ApiError
   */
  public adjustDeposit({
    orderId,
    depositId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
    /**
     * Deposit info to adjust
     */
    requestBody: Workspace_API_ViewModels_Deposits_AdjustDepositViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_DepositViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order or Deposit Not Found or External user`,
        409: `Adjust a Deposit requires an Overdraft or the
        Deposit doesn't have the correct info to adjust`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Update deposit details (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateDepositDetails({
    orderId,
    depositId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
    /**
     * UpdateDepositDetailsViewModel
     */
    requestBody: Workspace_API_ViewModels_Deposits_UpdateDepositDetailsViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user`,
        422: `Request validation failed or Deposit doesn't have the correct info to adjust`,
      },
    });
  }

  /**
   * Generate Receipt (Auth)
   * @returns number content was successfully fetched from eclipse
   * @throws ApiError
   */
  public generateDepositReceiptAsync({
    orderId,
    depositId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/generate-deposit-receipt',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `Order or deposit Not Found`,
      },
    });
  }

  /**
   * Create a manual Deposit (Auth)
   * @returns number Manual Deposit was successful
   * @throws ApiError
   */
  public createDepositAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * CreateDepositViewModel
     */
    requestBody: Workspace_API_ViewModels_Deposits_CreateDepositViewModel,
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/deposits/manual',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user or Manual Deposit was unsuccessful`,
      },
    });
  }

  /**
   * Update earnest money for deposit. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateDepositEarnestMoney({
    orderId,
    depositId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier. This is the ClarityFirst DB Deposit Id
     */
    depositId: number,
    /**
     * Deposit update earnest money model
     */
    requestBody: Workspace_API_ViewModels_Deposits_DepositMilestoneUpdateEarnestMoneyModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/deposit-milestone',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user`,
        422: `Request validation failed or Deposit doesn't have the correct info to adjust`,
      },
    });
  }

  /**
   * Fetch information on Deposit Link to Overdraft status (Auth)
   * @returns Workspace_API_ViewModels_Deposits_CanDepositBeLinkedViewModel Status was successfully fetched
   * @throws ApiError
   */
  public canBeLinkedAsync({
    orderId,
    depositId,
  }: {
    /**
     * An order Id
     */
    orderId: number,
    /**
     * A deposit id
     */
    depositId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_CanDepositBeLinkedViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/_canBeLinked',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Redis key not found or External user`,
      },
    });
  }

  /**
   * Fetch Deposit allocation (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositAllocationViewModel Deposit allocation amount
   * @throws ApiError
   */
  public getDepositAllocationAsync({
    orderId,
    depositId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
    /**
     * The Deposit Id
     */
    depositId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_DepositAllocationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/allocation',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
        403: `Forbidden`,
        404: `No File permissions`,
      },
    });
  }

  /**
   * Update Deposit allocation to Overdraft(s) (Auth)
   * @returns void
   * @throws ApiError
   */
  public linkDepositToOverdraftsAsync({
    orderId,
    depositId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
    /**
     * The Deposit Overdraft links
     */
    requestBody: Workspace_API_ViewModels_Deposits_DepositOverdraftLinksViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/_link',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header`,
        403: `Forbidden`,
        404: `No File permissions`,
        422: `No File permissions`,
      },
    });
  }

  /**
   * Adjusts a Deposit with an Overdraft for a negative File balance (Auth)
   * @returns void
   * @throws ApiError
   */
  public adjustDepositWithOverdraftAsync({
    orderId,
    depositId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
    /**
     * Deposit details and Overdraft info to adjust the Deposit
     */
    requestBody: Workspace_API_ViewModels_Deposits_AdjustWithOverdraftViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/adjust-with-overdraft',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order or Deposit Not Found or External user`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Fetch issued check deposit file contents (Auth)
   * @returns System_IO_MemoryStream content was successfully fetched from eclipse
   * @throws ApiError
   */
  public fetchIssueCheckDepositContentAsync({
    orderId,
    depositId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Deposit identifier
     */
    depositId: number,
  }): CancelablePromise<System_IO_MemoryStream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/_check',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `Order or deposit Not Found`,
      },
    });
  }

  /**
   * Allocates part of a Multisite's deposit, into the individual site files. (Auth)
   * @returns void
   * @throws ApiError
   */
  public allocateDeposit({
    orderId,
    depositFastId,
    requestBody,
  }: {
    /**
     * Id of the multise file that owns the deposit
     */
    orderId: number,
    /**
     * Fast id of the deposit being allocated
     */
    depositFastId: number,
    /**
     * Contains the allocations made to each site file.
     */
    requestBody?: Workspace_API_ViewModels_Deposits_AllocationViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositFastId}/allocations',
      path: {
        'orderId': orderId,
        'depositFastId': depositFastId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
        409: `Conflict`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets the Deposit Summary info for a specific order (Auth)
   * @returns Workspace_API_ViewModels_Deposits_Summary_SummaryViewModel Deposit Summary successfully retrieved
   * @throws ApiError
   */
  public getDepositSummaryAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_Summary_SummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Returns the list of deposits with it's allocated sites for the master allocation view (Auth)
   * @returns Workspace_API_ViewModels_Deposits_DepositMasterAllocationViewModel Success
   * @throws ApiError
   */
  public getMasterAllocations({
    orderId,
    siteFileId,
    groupId,
    allocationType,
  }: {
    /**
     * Id of the site file
     */
    orderId: number,
    /**
     * To filter deposits allocated to specified sites
     */
    siteFileId?: Array<number> | null,
    /**
     * To filter deposits by closing group id
     */
    groupId?: number | null,
    /**
     * To filter by allocation level of deposits
     */
    allocationType?: Workspace_Common_Models_Dto_Escrow_AllocatedLevel,
  }): CancelablePromise<Workspace_API_ViewModels_Deposits_DepositMasterAllocationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/deposits/allocations',
      path: {
        'orderId': orderId,
      },
      query: {
        'siteFileId': siteFileId,
        'groupId': groupId,
        'allocationType': allocationType,
      },
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }

  /**
   * Returns the deposit document location (Auth)
   * @returns any Document successfully uploaded
   * @throws ApiError
   */
  public uploadDepositDocumentAsync({
    orderId,
    depositId,
    documentId,
    formData,
  }: {
    /**
     * The order id for which the deposit document will be uploaded
     */
    orderId: number,
    /**
     * The deposit id for which the document will be uploaded
     */
    depositId: number,
    /**
     * If the existing document will be uploaded then their will be document id otherwise document id will be zero
     */
    documentId?: number,
    formData?: {
      DisplayName?: string | null;
      Type: string;
      SubType: string;
      File: Blob;
      Notes?: string | null;
      SdnHitId?: number | null;
      FileType?: Eclipse_SDK_DTO_ImageFileExtensionType;
      'DocumentType.Id'?: number;
      'DocumentType.Description'?: string | null;
      'DocumentType.SubTypes'?: Array<string> | null;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/upload',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      query: {
        'documentId': documentId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        409: `Document already exists`,
        422: `Validation Failed`,
      },
    });
  }

  /**
   * For a given orderId and documentId, remove the document (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeDepositDocumentAsync({
    orderId,
    depositId,
    documentId,
  }: {
    /**
     * Order ID
     */
    orderId: number,
    /**
     * Deposit Id
     */
    depositId: number,
    /**
     * Document ID
     */
    documentId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/deposits/{depositId}/remove-document',
      path: {
        'orderId': orderId,
        'depositId': depositId,
      },
      query: {
        'documentId': documentId,
      },
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have permission to perform action`,
        404: `The given deposit id and document id doesn't exist or order not found`,
      },
    });
  }

}
