import LinearProgress from '@material-ui/core/LinearProgress';
import { JobStatus } from 'components/MultiSiteBulkUpdates/Shared';
import { StyledReactTooltip } from 'components/ToolTip';
import { Row } from 'components/ui/Box';
import { primaryBlue2, white } from 'config/colors';
import { shuttleGrey, headerZIndex } from 'config/theme';

import { SMALL_BODY_MEDIUM } from 'config/typography';
import Close from 'icons/Close';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const HEADER_HEIGHT = 56;
const ROW_HEIGHT = 52;
const MAX_VISIBLE_ROWS = 3;
const DOM_ID_PREFIX = 'progress_popup__popover-progress';
const CHEVRON_ICON_TOOLTIP_ID = 'progress_popup__chevron-icon-tooltip-id';

interface IProgressPopupProps {
  progressHeaderProps: {
    title: string;
    icon?: React.ReactNode;
    onClose: () => void;
  };
  inProgress: boolean;
  pollingList: Array<{ jobId: string; state: JobStatus; itemId: number }>;
  progressRow?: JSX.Element;
  shouldExpandOnFailItem?: boolean;
}

const ProgressHeader = ({
  inProgress,
  isExpanded,
  onClose,
  title,
  icon,
  isExpandable,
}: {
  inProgress: boolean;
  isExpandable: boolean;
  isExpanded: boolean;
  title: string;
  icon?: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <StyledProgressHeader inProgress={inProgress}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header>
          {icon} {title}
        </Header>
      </div>
      {!inProgress && (
        <ButtonContainer
          onClick={onClose}
          id={`${DOM_ID_PREFIX}__button__close`}
        >
          <StyledCloseIcon />
        </ButtonContainer>
      )}
      {isExpandable && (
        <StyledReactTooltip
          id={CHEVRON_ICON_TOOLTIP_ID}
          key={isExpanded ? 'expanded' : 'collapsed'}
          getContent={() =>
            isExpanded ? 'Minimize window' : 'Maximize window'
          }
        />
      )}
    </StyledProgressHeader>
  );
};

const ProgressPopup = ({
  progressHeaderProps,
  inProgress,
  pollingList,
  progressRow,
  shouldExpandOnFailItem = true,
}: IProgressPopupProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandable = !!progressRow;

  useEffect(() => {
    if (
      shouldExpandOnFailItem &&
      isExpandable &&
      pollingList.find(({ state }) => state === JobStatus.FAILED)
    ) {
      setIsExpanded(true);
    }
  }, [pollingList, shouldExpandOnFailItem, isExpandable]);

  return (
    <FixedContainer>
      <ProgressHeader
        {...progressHeaderProps}
        inProgress={inProgress}
        isExpanded={isExpanded}
        isExpandable={isExpandable}
      />
      {inProgress && <LinearProgress />}
      {isExpanded && isExpandable && { progressRow }}
    </FixedContainer>
  );
};

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${ROW_HEIGHT * MAX_VISIBLE_ROWS + HEADER_HEIGHT}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  margin-left: 16px;
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
`;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })<{
  inProgress: boolean;
}>`
  height: ${({ inProgress }) =>
    inProgress ? HEADER_HEIGHT - 4 : HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  padding: 10px 8px 10px 14px;
  align-items: center;
  ${({ inProgress }) =>
    inProgress ? '' : `border-bottom: 2px solid ${primaryBlue2};`}
`;

const ButtonContainer = styled.div`
  min-width: 40px;
  min-height: 40px;

  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${shuttleGrey};
    }
  }
`;

const Header = styled.div`
  ${SMALL_BODY_MEDIUM}
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const StyledCloseIcon = styled(Close)`
  width: 26px;
  height: 30px;
  color: ${shuttleGrey};
`;

export default ProgressPopup;
