import React, { CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';
import { IBoxProps, Row } from './Box';

function getDuration(size: number) {
  if (size < 50) return 40;
  if (size < 100) return 30;
  if (size < 200) return 20;
  if (size < 400) return 10;
  return 5;
}

interface ISkeletonProps {
  width?: number;
  backgroundColor?: string;
  foregroundColor?: string;
}

const animateSkeleton = keyframes`
  from {
    background-postion-x: 0vw;
  }
  to {
    background-position-x: 100vw;
  }
`;

const Skeleton = styled.div<ISkeletonProps>`
  // make duration a function of width (if provided):
  // the wider the element, the faster the animation
  animation-duration: ${({ width }: ISkeletonProps) =>
    width ? getDuration(width) : 20}s;
  animation-name: ${animateSkeleton};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: repeating-linear-gradient(
    to right,
    ${({ backgroundColor }: ISkeletonProps) =>
        backgroundColor ? `${backgroundColor} 0%,` : `#e7eef4 0%,`}
      ${({ foregroundColor }: ISkeletonProps) =>
        foregroundColor ? `${foregroundColor} 50%,` : `#f9faff 50%,`}
      ${({ backgroundColor }: ISkeletonProps) =>
        backgroundColor ? `${backgroundColor} 100%` : `#e7eef4 100%`}
  );
  border-radius: 3px;
  width: 100%;
  height: 100%;
`;

const Container = styled(Row)<{ height?: number; width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${({ height }) =>
    height
      ? // if provided, set to the given height
        `height: ${height}px;`
      : // otherwise, fill the height of the flex container
        `align-self: stretch;`}
`;
interface ILoadableProps extends IBoxProps {
  height?: number;
  loading: boolean;
  style?: CSSProperties;
  width?: number;
  backgroundColor?: string;
  foregroundColor?: string;
  children: React.ReactNode | undefined;
}

export default function Loadable({
  backgroundColor,
  children,
  foregroundColor,
  height,
  loading,
  style,
  width,
  ...boxProps
}: ILoadableProps) {
  return (
    <Container height={height} style={style} width={width} {...boxProps}>
      {loading ? (
        <Skeleton
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          width={width}
        />
      ) : (
        children
      )}
    </Container>
  );
}
