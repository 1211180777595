import {
  NotificationsTypeKeys,
  ITileProps,
  INotificationsShowAction,
  INotificationsHideAction,
} from './types';

export function showNotification(
  tileProps: ITileProps,
  autohide: boolean = false
): INotificationsShowAction {
  return {
    type: NotificationsTypeKeys.SHOW,
    tileProps,
    autohide,
  };
}

export function hideNotification(): INotificationsHideAction {
  return {
    type: NotificationsTypeKeys.HIDE,
  };
}
