import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'store';
import { setForceHideNav } from 'store/page/actions';
import { manuallyGetSession } from 'store/auth/actions';
import { ErrorPageWithGraphic, ErrorCode } from 'components/ErrorPage';
import { trout } from 'config/theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface INotFoundProps {
  dispatch: Dispatch;
}

// Since 404 pages could have any url we cannot match by location in order to hide
// the navigation (as we do in the Header component).
// What we do in this case is set a `forceHideNav` flag in the app state whenever the
// view opens or closes, which will in turn hide or show the navigation.
export class NotFound extends React.Component<INotFoundProps> {
  public componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setForceHideNav(true));
    // We want to check if the user has a valid session so we can redirect them
    // if not.
    dispatch(manuallyGetSession(false, false));
  }

  public componentWillUnmount() {
    this.props.dispatch(setForceHideNav(false));
  }

  public render() {
    return (
      <ErrorPageWithGraphic
        statusCode={ErrorCode.NOT_FOUND}
        title="Oops! The page you're looking for doesn't exist."
        titleSize="24px"
        titleStyles={{
          lineHeight: '32px',
          marginTop: 18,
          marginBottom: 12,
        }}
        statusCodeStyles={{
          fontSize: 16,
          fontWeight: 350,
          lineHeight: '26px',
        }}
        description={
          <DescriptionContainer>
            This page may have moved or the address is incorrect.
            <br />
            You can go
            <a tabIndex={0} onClick={this.handleBackButtonClick}>
              Back
            </a>
            , or jump to the
            <Link to="/dashboard">Dashboard</Link>.
          </DescriptionContainer>
        }
      />
    );
  }

  private handleBackButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.history.go(-1);
  };
}

const connected = connect()(NotFound);
connected.displayName = 'NotFound';
export default connected;

const DescriptionContainer = styled.div`
  line-height: 26px;
  color: ${trout};

  a {
    margin-left: 3px;
  }
`;
