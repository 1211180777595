/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Eclipse_SDK_DTO_ImageFileExtensionType } from '../models/Eclipse_SDK_DTO_ImageFileExtensionType';
import type { System_IO_MemoryStream } from '../models/System_IO_MemoryStream';
import type { Workspace_API_Forms_UserDocumentsTarget } from '../models/Workspace_API_Forms_UserDocumentsTarget';
import type { Workspace_API_ViewModels_Documents_CopyDocumentsViewModel } from '../models/Workspace_API_ViewModels_Documents_CopyDocumentsViewModel';
import type { Workspace_API_ViewModels_Documents_DmsEnabledViewModel } from '../models/Workspace_API_ViewModels_Documents_DmsEnabledViewModel';
import type { Workspace_API_ViewModels_Documents_PublishDocumentsModel } from '../models/Workspace_API_ViewModels_Documents_PublishDocumentsModel';
import type { Workspace_API_ViewModels_OrderDocuments_AddEStampViewModel } from '../models/Workspace_API_ViewModels_OrderDocuments_AddEStampViewModel';
import type { Workspace_API_ViewModels_Templates_DmsResponseViewModel_Boolean_ } from '../models/Workspace_API_ViewModels_Templates_DmsResponseViewModel_Boolean_';
import type { Workspace_API_ViewModels_Templates_DmsResponseViewModel_CreateImageResponse_ } from '../models/Workspace_API_ViewModels_Templates_DmsResponseViewModel_CreateImageResponse_';
import type { Workspace_API_ViewModels_Templates_DmsResponseViewModel_DocumentCopyResponse_ } from '../models/Workspace_API_ViewModels_Templates_DmsResponseViewModel_DocumentCopyResponse_';
import type { Workspace_Common_Models_FileBusinessPartyEmployee } from '../models/Workspace_Common_Models_FileBusinessPartyEmployee';
import type { Workspace_Common_Models_FileDocumentSortPropertyType } from '../models/Workspace_Common_Models_FileDocumentSortPropertyType';
import type { Workspace_Common_Models_SortDirectionType } from '../models/Workspace_Common_Models_SortDirectionType';
import type { Workspace_Common_Requests_DocumentConvertRequest } from '../models/Workspace_Common_Requests_DocumentConvertRequest';
import type { Workspace_Common_Requests_DocumentSplitRequestViewModel } from '../models/Workspace_Common_Requests_DocumentSplitRequestViewModel';
import type { Workspace_Common_Requests_DocumentUpdateRequest } from '../models/Workspace_Common_Requests_DocumentUpdateRequest';
import type { Workspace_Common_Responses_DocumentList } from '../models/Workspace_Common_Responses_DocumentList';
import type { Workspace_Common_Responses_DocumentSplitResponseViewModel } from '../models/Workspace_Common_Responses_DocumentSplitResponseViewModel';
import type { Workspace_Common_Responses_OrderDocument } from '../models/Workspace_Common_Responses_OrderDocument';
import type { Workspace_API_ViewModels_Documents_GeneratePolicyNumberModel } from '../models/Workspace_API_ViewModels_Documents_GeneratePolicyNumberModel';
import type { Workspace_API_ViewModels_Templates_DmsResponseViewModel_String_ } from '../models/Workspace_API_ViewModels_Templates_DmsResponseViewModel_String_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderDocumentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add estamp to document (Auth)
   * @returns number E-stamp added succesfully
   * @throws ApiError
   */
  public addEStamp({
    documentId,
    orderId,
    requestBody,
  }: {
    /**
     * id of the document
     */
    documentId: number;
    orderId: string;
    /**
     * details of the estamp to be added
     */
    requestBody?: Workspace_API_ViewModels_OrderDocuments_AddEStampViewModel;
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}/estamp',
      path: {
        documentId: documentId,
        orderId: orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Document to add e-stamp not found`,
        422: `Request not valid`,
      },
    });
  }

  /**
   * For a given orderId and documentId, retrieve the document (Auth)
   * @returns Workspace_Common_Responses_OrderDocument Document response
   * @throws ApiError
   */
  public getDocument({
    orderId,
    documentId,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * Document ID
     */
    documentId: number;
  }): CancelablePromise<Workspace_Common_Responses_OrderDocument> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Document or file not found`,
      },
    });
  }

   /**
   * For a given orderId and documentId, retrieve the document (Auth)
   * @returns Workspace_Common_Responses_OrderDocument Document response
   * @throws ApiError
   */
    public getDocumentAllType({
      orderId,
      documentId,
    }: {
      /**
       * Order ID
       */
      orderId: number,
      /**
       * Document ID
       */
      documentId: number,
    }): CancelablePromise<Workspace_Common_Responses_OrderDocument> {
      return this.httpRequest.request({
        method: 'GET',
        url: '/ncs/v1/orders/{orderId}/documents/{documentId}/all-type',
        path: {
          'orderId': orderId,
          'documentId': documentId,
        },
        errors: {
          401: `Invalid Authorization header`,
          404: `Document or file not found`,
        },
      });
    }
  

  /**
   * For a given orderId and documentId, remove the document (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeDocument({
    orderId,
    documentId,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * Document ID
     */
    documentId: number;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      errors: {
        401: `Unauthorized`,
        403: `External Users do not have permission`,
        404: `Document or file not found`,
      },
    });
  }

  /**
   * For a given orderId and documentId, Update the document (Auth)
   * @returns any Successfully updated document response
   * @throws ApiError
   */
  public updateDocumentAsync({
    orderId,
    documentId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * Document ID
     */
    documentId: number;
    /**
     * A model containing data related to the document to update. Eg: Name, Type
     */
    requestBody: Workspace_Common_Requests_DocumentUpdateRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        403: `External Users do not have permission`,
        404: `Document or file not found`,
      },
    });
  }

  /**
   * For a given orderId, retrieve the related documents. (Auth)
   * @returns Workspace_Common_Responses_DocumentList Documents response
   * @throws ApiError
   */
  public getDocumentsAsync({
    orderId,
    paginateResponse = false,
    page,
    sortProperty,
    sortDirection,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * boolean value to get response in paginated form
     */
    paginateResponse?: boolean;
    /**
     * int value for page number.
     */
    page?: number;
    /**
     * The property on which to sort.
     */
    sortProperty?: Workspace_Common_Models_FileDocumentSortPropertyType;
    /**
     * The direction in which to sort.
     */
    sortDirection?: Workspace_Common_Models_SortDirectionType;
  }): CancelablePromise<Workspace_Common_Responses_DocumentList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents',
      path: {
        orderId: orderId,
      },
      query: {
        paginateResponse: paginateResponse,
        page: page,
        sortProperty: sortProperty,
        sortDirection: sortDirection,
      },
      errors: {
        400: `Invalid page number`,
        401: `Invalid Authorization header`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Upload a document to FAST (Auth)
   * @returns any Document successfully uploaded
   * @throws ApiError
   */
  public uploadDocument({
    orderId,
    formData,
  }: {
    /**
     * The order id for which the document will be uploaded
     */
    orderId: number;
    formData?: {
      DisplayName?: string | null;
      Type: string;
      SubType: string;
      File: Blob;
      Notes?: string | null;
      SdnHitId?: number | null;
      FileType?: Eclipse_SDK_DTO_ImageFileExtensionType;
      'DocumentType.Id'?: number;
      'DocumentType.Description'?: string | null;
      'DocumentType.SubTypes'?: Array<string> | null;
    };
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/documents',
      path: {
        orderId: orderId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Failed`,
      },
    });
  }

  /**
   * Retrieve external order business party employees for a list of documents that do not have ReadDocument permission. (Auth)
   * @returns Workspace_Common_Models_FileBusinessPartyEmployee Business parties response
   * @throws ApiError
   */
  public getAvailableDocumentParties({
    orderId,
    documentIds,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The list of the document ids against which to check permissions.
     */
    documentIds: Array<number>;
  }): CancelablePromise<
    Array<Workspace_Common_Models_FileBusinessPartyEmployee>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/parties/available',
      path: {
        orderId: orderId,
      },
      query: {
        documentIds: documentIds,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieve external order business party employees for a document that do not have ReadDocument permission. (Auth)
   * @returns Workspace_Common_Models_FileBusinessPartyEmployee Business parties response
   * @throws ApiError
   */
  public getAvailableDocumentParties1({
    orderId,
    documentId,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The id of the document against which to check permissions.
     */
    documentId: number;
  }): CancelablePromise<
    Array<Workspace_Common_Models_FileBusinessPartyEmployee>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}/parties/available',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieve external order business party employees for a document that have ReadDocument permission. (Auth)
   * @returns Workspace_Common_Models_FileBusinessPartyEmployee Business parties response
   * @throws ApiError
   */
  public getAuthorizedDocumentParties({
    orderId,
    documentIds,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The list of the document ids against which to check permissions.
     */
    documentIds: Array<number>;
  }): CancelablePromise<
    Array<Workspace_Common_Models_FileBusinessPartyEmployee>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/parties/authorized',
      path: {
        orderId: orderId,
      },
      query: {
        documentIds: documentIds,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieve external order business party employees for a document that have ReadDocument permission. (Auth)
   * @returns Workspace_Common_Models_FileBusinessPartyEmployee Business parties response
   * @throws ApiError
   */
  public getAuthorizedDocumentParties1({
    orderId,
    documentId,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The id of the document against which to check permissions.
     */
    documentId: number;
  }): CancelablePromise<
    Array<Workspace_Common_Models_FileBusinessPartyEmployee>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}/parties/authorized',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Grant ReadDocument permission for multiple User for a list of documents. (Auth)
   * @returns void
   * @throws ApiError
   */
  public grantMultipleDocumentsUserPermissions({
    orderId,
    requestBody,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The documentIds and the FileBusinessParty, with optional User being targeted for adding permissions
     */
    requestBody: Workspace_API_Forms_UserDocumentsTarget;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/documents/permissions',
      path: {
        orderId: orderId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order, Document or Target User not found`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * Remove ReadDocument permission for a given User for a document. (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeDocumentUserPermissions({
    orderId,
    documentId,
    userId,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The id of the document against which to check permissions.
     */
    documentId: number;
    /**
     * The UserId being targetted for permissions removal
     */
    userId: number;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url:
        '/ncs/v1/orders/{orderId}/documents/{documentId}/permissions/{userId}',
      path: {
        orderId: orderId,
        documentId: documentId,
        userId: userId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order, Document or Target User not found`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * Remove ReadDocument permission for a given User for a list of documents. (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeDocumentUserPermissions1({
    orderId,
    documentIds,
    userId,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number;
    /**
     * The id of the documents against which to check permissions.
     */
    documentIds: Array<number>;
    /**
     * The UserId being targetted for permissions removal
     */
    userId: number;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/documents/permissions/{userId}',
      path: {
        orderId: orderId,
        userId: userId,
      },
      query: {
        documentIds: documentIds,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order, Documents or Target User not found`,
        422: `Validation failed.`,
      },
    });
  }

  /**
   * Downloads a zip file of all the documents / images (Auth)
   * @returns any Documents were successfully downloaded
   * @throws ApiError
   */
  public downloadDocuments({
    orderId,
    requestBody,
  }: {
    /**
     * file id
     */
    orderId: number;
    /**
     * list of Ids for the documents to be downloaded
     */
    requestBody?: Array<string> | null;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/documents/download',
      path: {
        orderId: orderId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request.  Max Document Download size was exceeded"`,
        404: `Order not found.  User doesn't have access to the Order, or No Documents were found`,
      },
    });
  }

  /**
   * For a given orderId and documentId, fetch the document content (Auth)
   * @returns System_IO_MemoryStream Document was successfully fetched
   * @throws ApiError
   */
  public getDocumentContentAsync({
    orderId,
    documentId,
    isDocumentInstance = false,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * Document ID
     */
    documentId: string;
    /**
     * Is Document Instance
     */
    isDocumentInstance?: boolean;
  }): CancelablePromise<System_IO_MemoryStream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}/view',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      query: {
        isDocumentInstance: isDocumentInstance,
      },
      errors: {
        404: `Document or file not found`,
      },
    });
  }

  /**
   * For a given orderId and documentId, convert the document format.
   * Uses DMS as service and creates a new document on the platform and our DB. (Auth)
   * @returns Workspace_Common_Responses_OrderDocument Successfully converted document response
   * @throws ApiError
   */
  public convertDocumentAsync({
    orderId,
    documentId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * Document ID
     */
    documentId: number;
    /**
     * A model containing data related to the document to Convert. Eg: DestinationType
     */
    requestBody: Workspace_Common_Requests_DocumentConvertRequest;
  }): CancelablePromise<Workspace_Common_Responses_OrderDocument> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}/convert',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Specified User do not have permission`,
        404: `Document or file not found`,
      },
    });
  }

  /**
   * For a given orderId and documentId, split the document creating another file.
   * Uses DMS as service and creates a new document on the platform and our DB. (Auth)
   * @returns Workspace_Common_Responses_DocumentSplitResponseViewModel Successfully split document response
   * @throws ApiError
   */
  public splitDocumentAsync({
    orderId,
    documentId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    /**
     * Document ID
     */
    documentId: number;
    /**
     * A model containing data related to the document to Split.
     */
    requestBody: Workspace_Common_Requests_DocumentSplitRequestViewModel;
  }): CancelablePromise<
    Array<Workspace_Common_Responses_DocumentSplitResponseViewModel>
  > {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/documents/{documentId}/split',
      path: {
        orderId: orderId,
        documentId: documentId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Specified User do not have permission`,
        404: `Document or file not found`,
      },
    });
  }

  /**
   * Copy a list of documents from source file to destination file (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_DocumentCopyResponse_ Success
   * @throws ApiError
   */
  public copyDocumentAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order ID
     */
    orderId: number;
    requestBody: Workspace_API_ViewModels_Documents_CopyDocumentsViewModel;
  }): CancelablePromise<
    Workspace_API_ViewModels_Templates_DmsResponseViewModel_DocumentCopyResponse_
  > {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/documents/copy',
      path: {
        orderId: orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Publish order documents (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_Boolean_ Successfully published documents
   * @throws ApiError
   */
  public publishDocumentAsync({
    orderId,
    requestBody,
  }: {
    /**
     * The order Id
     */
    orderId: number;
    /**
     * Publish options
     */
    requestBody?: Workspace_API_ViewModels_Documents_PublishDocumentsModel;
  }): CancelablePromise<
    Workspace_API_ViewModels_Templates_DmsResponseViewModel_Boolean_
  > {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/documents/publish',
      path: {
        orderId: orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Failed to publish documents`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Document or file not found`,
      },
    });
  }

  /**
   * Generate an image-document from a template-instance (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_CreateImageResponse_ Successfully imaged the document
   * @throws ApiError
   */
  public generateImageAsync({
    orderId,
    instanceId,
  }: {
    /**
     * The order id
     */
    orderId: number;
    /**
     * The template instance
     */
    instanceId: number;
  }): CancelablePromise<
    Workspace_API_ViewModels_Templates_DmsResponseViewModel_CreateImageResponse_
  > {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/documents/{instanceId}/generate-image',
      path: {
        orderId: orderId,
        instanceId: instanceId,
      },
      errors: {
        400: `Failed to image the document`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Document or file not found`,
      },
    });
  }

  /**
   * Check order Background Refresh Status (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_Boolean_ Successfully published documents
   * @throws ApiError
   */
    public checkOrderBackgroundRefreshStatus({
      orderId,
    }: {
      /**
       * The order Id
       */
      orderId: number,
    }): CancelablePromise<Workspace_API_ViewModels_Templates_DmsResponseViewModel_Boolean_> {
      return this.httpRequest.request({
        method: 'GET',
        url: '/ncs/v1/orders/{orderId}/documents/checkOrderBackgroundRefreshStatus',
        path: {
          'orderId': orderId,
        },
        errors: {
          400: `Failed to publish documents`,
          401: `Unauthorized`,
          403: `Forbidden`,
          404: `Document or file not found`,
        },
      });
    }

  /**
   * Get Dms-Enabled status for an order (Auth)
   * @returns Workspace_API_ViewModels_Documents_DmsEnabledViewModel Succesfully checked the DMS status
   * @throws ApiError
   */
  public isDmsEnabledAsync({
    orderId,
  }: {
    /**
     * The order id
     */
    orderId: number;
  }): CancelablePromise<
    Workspace_API_ViewModels_Documents_DmsEnabledViewModel
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/documents/dms-enabled',
      path: {
        orderId: orderId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * generate policy number for a document (Auth)
   * @returns Workspace_API_ViewModels_Templates_DmsResponseViewModel_String_ Successfully published documents
   * @throws ApiError
   */
    public generatePolicyNumberAsync({
      orderId,
      documentId,
      requestBody,
    }: {
      /**
       * The order Id
       */
      orderId: number,
      /**
       * The document Id
       */
      documentId: number,
      /**
       * The policy number
       */
      requestBody?: Workspace_API_ViewModels_Documents_GeneratePolicyNumberModel,
    }): CancelablePromise<Workspace_API_ViewModels_Templates_DmsResponseViewModel_String_> {
      return this.httpRequest.request({
        method: 'POST',
        url: '/ncs/v1/orders/{orderId}/documents/{documentId}/generate-policy-number',
        path: {
          'orderId': orderId,
          'documentId': documentId,
        },
        body: requestBody,
        mediaType: 'application/json-patch+json',
        errors: {
          400: `Failed to publish documents`,
          401: `Unauthorized`,
          403: `Forbidden`,
          404: `Document or file not found`,
        },
      });
    }
  
}
