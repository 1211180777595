import { FileLoadingKeys } from 'store/files/types';
import { UniversalWireInstructionLoadingKeys } from 'store/universalWireAccounts/types';
import { WireInstructionLoadingKeys } from 'store/wireInstructions/types';
import { DashboardLoadingKeys } from 'store/dashboard/types';
import { BillingAndPaymentsLoadingKeys } from 'store/billingAndPayments/types';
import randomId from 'utils/randomId';
import {
  UiTypeKeys,
  IUiState,
  IUiAction,
  IUiLoaders,
  IUiModals,
  IToastState,
  ModalKey,
  NavigationPromptKey,
} from 'store/ui/types';
import { OrderLoadingKeys, OrderModalKeys } from 'store/orders/types';
import { AuthLoadingKeys } from 'store/auth/types';

const {
  SET_FOOTER_STYLE,
  SET_LOADING_STATE,
  SET_MODAL_STATE,
  ADD_TOAST,
  REMOVE_TOAST,
  CANCEL,
  CONFIRM,
  SHOW_SIDEBAR_WINDOW,
} = UiTypeKeys;

export const initialState: IUiState = {
  loaders: {
    [AuthLoadingKeys.authSessionTimeoutLoading]: false,
    [BillingAndPaymentsLoadingKeys.billingAndPaymentsInvoiceDetailLoading]: false,
    [BillingAndPaymentsLoadingKeys.billingAndPaymentsInvoiceTransactionsLoading]: false,
    [BillingAndPaymentsLoadingKeys.paymentInProcessLoading]: false,
    [BillingAndPaymentsLoadingKeys.invoiceDownloadLoading]: false,
    [DashboardLoadingKeys.dashboardIncompleteLoading]: false,
    [DashboardLoadingKeys.dashboardMyOrdersLoading]: false,
    [DashboardLoadingKeys.dashboardRecentOrdersLoading]: false,
    [FileLoadingKeys.fileLoading]: false,
    [FileLoadingKeys.fileOrganizationContactsLoading]: false,
    [FileLoadingKeys.fileOrganizationsLoading]: false,
    [FileLoadingKeys.fileWireInstructionsLoading]: false,
    [OrderLoadingKeys.orderFileDetailsLoading]: false,
    [OrderLoadingKeys.orderProductsLoading]: false,
    [OrderLoadingKeys.orderDisbursementsLoading]: false,
    [OrderLoadingKeys.orderDocumentPermissionsLoading]: false,
    [OrderLoadingKeys.orderDocumentsLoading]: false,
    [OrderLoadingKeys.orderLatestEpPackageLoading]: false,
    [OrderLoadingKeys.orderTeamPanelLoading]: false,
    [OrderLoadingKeys.orderDepositsLoading]: false,
    [OrderLoadingKeys.editOrderPartySavingInProgress]: false,
    [WireInstructionLoadingKeys.wireInstructionLoading]: false,
    [WireInstructionLoadingKeys.addWireInstructionLoading]: false,
    [WireInstructionLoadingKeys.updateWireInstructionLoading]: false,
    [WireInstructionLoadingKeys.byPassBankVerificationLoading]: false,
    [WireInstructionLoadingKeys.byPassUserVerificationLoading]: false,
    [UniversalWireInstructionLoadingKeys.universalWireInstructionDetailLoading]: false,
    [UniversalWireInstructionLoadingKeys.universalWireInstructionFileAssocLoading]: false,
  } as IUiLoaders,
  modals: {
    [OrderModalKeys.orderAddContactModal]: false,
    [ModalKey.SessionTimeoutModal]: false,
    [NavigationPromptKey.NavigationPromptModal]: false,
  } as IUiModals,
  toasts: {} as IToastState,
};

export default (state = initialState, action: IUiAction): IUiState => {
  switch (action.type) {
    case SET_LOADING_STATE:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.loadingKey]: action.isLoading,
        },
      };

    case SET_FOOTER_STYLE:
      const { type, ...styles } = action;
      return {
        ...state,
        footerStyleOverrides: styles,
      };

    case SHOW_SIDEBAR_WINDOW:
      return {
        ...state,
        showSidebarWindow: action.payload,
      };

    case SET_MODAL_STATE:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.modalKey]: action.isOpen,
        },
      };
    case CANCEL:
      return {
        ...state,
        modals: {
          ...state.modals,
          navigation_prompt_modal: false,
        },
      };
    case CONFIRM:
      return {
        ...state,
        modals: {
          ...state.modals,
          navigation_prompt_modal: true,
        },
      };

    case ADD_TOAST: {
      const { text, intent, options = {} } = action.payload;
      const id = options.toastId || randomId();

      return {
        ...state,
        toasts: {
          ...state.toasts,
          [id]: {
            id,
            text,
            intent,
            options,
          },
        },
      };
    }

    case REMOVE_TOAST: {
      const { idsToRemove } = action.payload;

      const currentIds = Object.keys(state.toasts);

      const toasts = {};

      currentIds.forEach((id) => {
        // If our current key isn't marked for deletion, we can add it to our
        // list of remaining valid toasts
        if (!idsToRemove.some((key) => key === id)) {
          toasts[id] = state.toasts[id];
        }
      });

      return {
        ...state,
        toasts,
      };
    }

    default:
      return state;
  }
};
