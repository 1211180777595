import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M11.2905 8.52748C9.25161 8.52748 5.18143 9.54311 5.18143 11.582V13.1093H17.3996V11.582C17.3996 9.54311 13.3294 8.52748 11.2905 8.52748ZM4.41779 5.47293V3.18202H2.89052V5.47293H0.599609V7.0002H2.89052V9.29111H4.41779V7.0002H6.7087V5.47293H4.41779ZM11.2905 7.0002C12.1006 7.0002 12.8776 6.67839 13.4504 6.10555C14.0232 5.53271 14.3451 4.75577 14.3451 3.94566C14.3451 3.13554 14.0232 2.35861 13.4504 1.78577C12.8776 1.21293 12.1006 0.891113 11.2905 0.891113C10.4804 0.891113 9.70347 1.21293 9.13063 1.78577C8.55779 2.35861 8.23597 3.13554 8.23597 3.94566C8.23597 4.75577 8.55779 5.53271 9.13063 6.10555C9.70347 6.67839 10.4804 7.0002 11.2905 7.0002Z"
      fill={props.fill}
    />
  </svg>
);
