/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Eclipse_SDK_DTO_ImageFileExtensionType } from '../models/Eclipse_SDK_DTO_ImageFileExtensionType';
import type { System_IO_MemoryStream } from '../models/System_IO_MemoryStream';
import type { Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskDocumentUpdateRequest } from '../models/Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskDocumentUpdateRequest';
import type { Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskDocumentResponse } from '../models/Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskDocumentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderChecklistTaskDocumentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Upload a checklist task document. (Auth)
   * @returns any Document successfully uploaded.
   * @throws ApiError
   */
  public uploadAsync({
    orderId,
    checklistId,
    checklistTaskId,
    formData,
  }: {
    /**
     * The order identifier
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask Identifier.
     */
    checklistTaskId: number,
    formData?: {
      File: Blob;
      TaskDocumentId?: number | null;
      IsSecured?: boolean;
      FileType?: Eclipse_SDK_DTO_ImageFileExtensionType;
    },
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/documents/upload',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Files did not pass antimalware scan.`,
        401: `UnAuthorized`,
        403: `User does not have sufficient permissions.`,
        404: `Document was not found.`,
        422: `Validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * To Download the document (Auth)
   * @returns binary Document Downloaded.
   * @throws ApiError
   */
  public downloadAsync({
    orderId,
    checklistId,
    checklistTaskId,
    taskDocumentId,
  }: {
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask Identifier.
     */
    checklistTaskId: number,
    /**
     * Document identifier in ChecklistManagement side
     */
    taskDocumentId: number,
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/documents/{taskDocumentId}/download',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
        'taskDocumentId': taskDocumentId,
      },
      errors: {
        401: `UnAuthorized`,
        403: `User does not have sufficient permissions.`,
        404: `Document was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Fetches the document content for preview. (Auth)
   * @returns System_IO_MemoryStream Document was successfully fetched.
   * @throws ApiError
   */
  public previewAsync({
    orderId,
    checklistId,
    checklistTaskId,
    taskDocumentId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask Identifier.
     */
    checklistTaskId: number,
    /**
     * Document identifier in CF side.
     */
    taskDocumentId: number,
  }): CancelablePromise<System_IO_MemoryStream> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/documents/{taskDocumentId}/preview',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
        'taskDocumentId': taskDocumentId,
      },
      errors: {
        401: `UnAuthorized`,
        403: `User does not have sufficient permissions.`,
        404: `Document was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Updates the document's metadata (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateAsync({
    orderId,
    checklistId,
    checklistTaskId,
    taskDocumentId,
    requestBody,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask Identifier.
     */
    checklistTaskId: number,
    /**
     * Document identifier in CF side.
     */
    taskDocumentId: number,
    /**
     * OrderChecklist Task DocumentRequest.
     */
    requestBody?: Workspace_Common_Requests_ChecklistManagement_Customer_OrderChecklistTaskDocumentUpdateRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/documents/{taskDocumentId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
        'taskDocumentId': taskDocumentId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid input request.`,
        401: `UnAuthorized`,
        403: `User does not have sufficient permissions.`,
        404: `Document was not found.`,
        422: `Invalid request object.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Deleted the document's metadata (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteAsync({
    orderId,
    checklistId,
    checklistTaskId,
    taskDocumentId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask Identifier.
     */
    checklistTaskId: number,
    /**
     * Document identifier in CF side.
     */
    taskDocumentId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/documents/{taskDocumentId}',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
        'taskDocumentId': taskDocumentId,
      },
      errors: {
        401: `UnAuthorized`,
        403: `User does not have sufficient permissions.`,
        404: `Document was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Gets the documens for a task (Auth)
   * @returns Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskDocumentResponse List of documents from specific checklist task
   * @throws ApiError
   */
  public getAsync({
    orderId,
    checklistId,
    checklistTaskId,
  }: {
    /**
     * Order identifier.
     */
    orderId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * ChecklistTask Identifier.
     */
    checklistTaskId: number,
  }): CancelablePromise<Array<Workspace_Common_Responses_ChecklistManagement_Customer_OrderChecklistTaskDocumentResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/checklists/{checklistId}/tasks/{checklistTaskId}/documents',
      path: {
        'orderId': orderId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        401: `UnAuthorized`,
        403: `User does not have sufficient permissions.`,
        404: `Document was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

}
