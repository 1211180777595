import React from 'react';
import styled from 'styled-components';
import { Popover } from 'components/ui/Popover';
import { Row } from 'components/ui/Box';
import { black, shuttleGrey } from 'config/theme';
import { DollarIcon, FileIcon } from 'components/svg/legacyIcons_DO_NOT_USE';

interface ISiteCardMenuProps {
  pushRoute: (route: string) => void;
  fileId: number;
  hasDocumentPermissions?: boolean;
  hasWireInstructionPermissions?: boolean;
}

export const SiteCardMenu = ({
  pushRoute,
  fileId,
  hasWireInstructionPermissions,
  hasDocumentPermissions,
}: ISiteCardMenuProps) => (
  <Row alignItems="center">
    <Popover
      options={[
        {
          hidden: !hasWireInstructionPermissions,
          icon: <DollarIcon fill={shuttleGrey} />,
          iconSize: 20,
          content: <OptionText>Manage Wire Instructions</OptionText>,
          onClick: () => {
            pushRoute(`/order/${fileId}/wire-instructions`);
          },
        },
        {
          hidden: !hasDocumentPermissions,
          icon: <FileIcon fill={shuttleGrey} />,
          iconSize: 20,
          content: <OptionText>Manage Documents</OptionText>,
          onClick: () => {
            pushRoute(`/order/${fileId}/documents`);
          },
        },
      ]}
      modalIsVisible={false}
      popupWidth={278}
    />
  </Row>
);

const OptionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: ${black};
`;
