import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { primaryBlue7, primaryBlue8, white } from 'config/colors';
import ArrowUpIcon from 'icons/ArrowUpIcon';
import { SHADOW_3P } from 'config/shadows';

interface IScrollButton {
  scrollVisibleHeight?: number;
  top?: number;
  left?: number;
}
const ScrollButton = (props: IScrollButton) => {
  const { scrollVisibleHeight = 400, top = 0, left = 0 } = props;
  const [scrollVisible, setScrollVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollVisible);
  }, [scrollVisible]);

  const scrollToTop = () => {
    window.scrollTo({
      top: top,
      left: left,
      behavior: 'smooth',
    });
  };

  const toggleScrollVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > scrollVisibleHeight) {
      setScrollVisible(true);
    } else if (scrolled <= scrollVisibleHeight) {
      setScrollVisible(false);
    }
  };

  return (
    <>
      {scrollVisible && (
        <ButtonScroll
          id={`scroll-top-button`}
          onClick={scrollToTop}
          title="Scroll Top"
        >
          <ArrowUpIcon fill={white} />
        </ButtonScroll>
      )}{' '}
    </>
  );
};

export default ScrollButton;

const ButtonScroll = styled.span`
  cursor: pointer;
  position: fixed;
  border-radius: 50%;
  padding: 4px 6px;
  bottom: 60px;
  right: 5px;
  text-align: center;
  z-index: 11;
  background: ${primaryBlue8};
  box-shadow: ${SHADOW_3P};
  &:hover {
    background: ${primaryBlue7};
  }
`;
