import React from 'react';
import styled from 'styled-components';
import { ClearIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import { mystic, shuttleGrey, geyser, disabledTextColor } from 'config/theme';

interface IClearButtonProps {
  [name: string]: any;
  visible: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
}

export default ({
  visible,
  className,
  onClick,
  disabled = false,
  ...rest
}: IClearButtonProps) => {
  return (
    <Container
      className={className}
      onClick={!disabled ? onClick : undefined}
      onKeyPress={!disabled ? onClick : undefined}
      visible={visible}
      tabIndex={0}
      role="button"
      disabled={disabled}
      {...rest}
    >
      <ClearIcon
        fill={!disabled ? shuttleGrey : disabledTextColor}
        style={{
          width: 14,
          height: 14,
          marginBottom: '-2px',
          marginRight: '-1px',
        }}
      />
    </Container>
  );
};

const Container = styled.div<IClearButtonProps>`
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin-left: -40px;
  &:not([disabled]) {
    ${({ visible }) =>
      visible
        ? `
      &:hover { background-color: ${mystic}; }
      &:active { background-color: ${geyser}; }
      & > svg { display: block; }
      z-index: 1;
    `
        : `
      & > svg { display: none; }
    `}
  }
`;
