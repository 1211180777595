/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Eclipse_SDK_DTO_IBATransactionStatusType } from '../models/Eclipse_SDK_DTO_IBATransactionStatusType';
import type { Eclipse_SDK_DTO_IBATransactionType } from '../models/Eclipse_SDK_DTO_IBATransactionType';
import type { Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel } from '../models/Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel';
import type { Workspace_API_ViewModels_Escrow_BankAccountsViewModel } from '../models/Workspace_API_ViewModels_Escrow_BankAccountsViewModel';
import type { Workspace_API_ViewModels_Escrow_FundApprovalValidationViewModel } from '../models/Workspace_API_ViewModels_Escrow_FundApprovalValidationViewModel';
import type { Workspace_API_ViewModels_Escrow_FundTransmissionDetailViewModel } from '../models/Workspace_API_ViewModels_Escrow_FundTransmissionDetailViewModel';
import type { Workspace_API_ViewModels_Escrow_FundWireDetailViewModel } from '../models/Workspace_API_ViewModels_Escrow_FundWireDetailViewModel';
import type { Workspace_API_ViewModels_Escrow_OutgoingFundsViewModel } from '../models/Workspace_API_ViewModels_Escrow_OutgoingFundsViewModel';
import type { Workspace_API_ViewModels_IBA_IBABankViewModel } from '../models/Workspace_API_ViewModels_IBA_IBABankViewModel';
import type { Workspace_API_ViewModels_IBA_IBAOfficeTransactionsViewModel } from '../models/Workspace_API_ViewModels_IBA_IBAOfficeTransactionsViewModel';
import type { Workspace_API_ViewModels_IBA_IBATransactionApprovalResultViewModel } from '../models/Workspace_API_ViewModels_IBA_IBATransactionApprovalResultViewModel';
import type { Workspace_API_ViewModels_IBA_IBATransactionApprovalViewModel } from '../models/Workspace_API_ViewModels_IBA_IBATransactionApprovalViewModel';
import type { Workspace_API_ViewModels_IBA_IBATransactionPendingApprovalResponseViewModel } from '../models/Workspace_API_ViewModels_IBA_IBATransactionPendingApprovalResponseViewModel';
import type { Workspace_API_ViewModels_OrderOwningOffice_OwningOfficeValidationViewModel } from '../models/Workspace_API_ViewModels_OrderOwningOffice_OwningOfficeValidationViewModel';
import type { Workspace_Common_Models_Office } from '../models/Workspace_Common_Models_Office';
import type { Workspace_Common_Responses_EmployeeList } from '../models/Workspace_Common_Responses_EmployeeList';
import type { Workspace_Common_Responses_OfficeDetailList } from '../models/Workspace_Common_Responses_OfficeDetailList';
import type { Workspace_Common_Responses_OfficeMinList } from '../models/Workspace_Common_Responses_OfficeMinList';
import type { Workspace_Common_Responses_OfficesList } from '../models/Workspace_Common_Responses_OfficesList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OfficesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve all offices (Auth)
   * @returns Workspace_Common_Responses_OfficesList Offices successfully retrieved
   * @throws ApiError
   */
  public getOffices(): CancelablePromise<Workspace_Common_Responses_OfficesList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Retrieve all active offices for a region (Auth)
   * @returns Workspace_Common_Responses_OfficeMinList Offices successfully retrieved
   * @throws ApiError
   */
  public getVisibleOfficesAsync({
    regionId,
  }: {
    /**
     * region id
     */
    regionId: number,
  }): CancelablePromise<Workspace_Common_Responses_OfficeMinList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{regionId}/visible',
      path: {
        'regionId': regionId,
      },
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Retrieve offices for a region and filter hidden (Auth)
   * @returns Workspace_Common_Responses_OfficeDetailList Offices successfully retrieved
   * @throws ApiError
   */
  public getOfficesWithRegionFilterAsync({
    regionFastIds,
    filterHidden,
  }: {
    /**
     * regions to fetch for
     */
    regionFastIds?: Array<number> | null,
    /**
     * remove hidden offices
     */
    filterHidden?: boolean,
  }): CancelablePromise<Workspace_Common_Responses_OfficeDetailList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/by-regions',
      query: {
        'regionFastIds': regionFastIds,
        'FilterHidden': filterHidden,
      },
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Retrieve an office by ID, optionally allowing to use the FAST ID instead of the internal CF one (Auth)
   * @returns Workspace_Common_Models_Office Office successfully retrieved
   * @throws ApiError
   */
  public getOfficeById({
    id,
    isFastId = false,
  }: {
    /**
     * an office ID (CF Internal or FAST ID)
     */
    id: number,
    /**
     * True to indicate the id parameter is the office FAST ID.
     * False to use the internal CF ID. Defaults to <code>false</code>.
     */
    isFastId?: boolean,
  }): CancelablePromise<Workspace_Common_Models_Office> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{id}',
      path: {
        'id': id,
      },
      query: {
        'isFastId': isFastId,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the Disbursements activity right`,
        404: `Office does not exist`,
      },
    });
  }

  /**
   * Retrieve the default offices by id. (Auth)
   * @returns Workspace_Common_Models_Office Offices successfully retrieved
   * @throws ApiError
   */
  public getDefaultOfficesAsync({
    id,
  }: {
    /**
     * an office ID
     */
    id: number,
  }): CancelablePromise<Array<Workspace_Common_Models_Office>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{id}/defaults',
      path: {
        'id': id,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the Disbursements activity right`,
        404: `Offices do not exist`,
      },
    });
  }

  /**
   * Retrieve office employees by office ID and category.
   * category=title returns Title Officer and Title Assistant
   * category=escrow returns Escrow Officer and Escrow Assistant
   * category=underwriter returns Underwriter
   * No category passed in or empty, returns all employees in the office. (Auth)
   * @returns Workspace_Common_Responses_EmployeeList Office employee successfully retrieved
   * @throws ApiError
   */
  public getOfficeEmployeeByCategoryAsync({
    id,
    category = '',
  }: {
    /**
     * a ClarityFirst office ID
     */
    id: number,
    /**
     * category=title|escrow|underwriter|sales|""
     */
    category?: string | null,
  }): CancelablePromise<Workspace_Common_Responses_EmployeeList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{id}/employees',
      path: {
        'id': id,
      },
      query: {
        'category': category,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        404: `no qualified employee found`,
      },
    });
  }

  /**
   * Retrieve office bank accounts by office ID. (Auth)
   * @returns Workspace_API_ViewModels_Escrow_BankAccountsViewModel Office bank accounts successfully retrieved
   * @throws ApiError
   */
  public getOfficeBankAccountsAsync({
    officeId,
  }: {
    /**
     * A ClarityFirst office ID
     */
    officeId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_BankAccountsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/bank-accounts',
      path: {
        'officeId': officeId,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        404: `No bank accounts found`,
      },
    });
  }

  /**
   * Retrieve outgoing funds by office ID (Auth)
   * @returns Workspace_API_ViewModels_Escrow_OutgoingFundsViewModel Outgoing funds successfully retrieved
   * @throws ApiError
   */
  public getOutgoingFundsAsync({
    officeId,
    fromIssuedDate,
    toIssuedDate,
    accountId,
    outgoingFundStatus,
    fromAmount,
    toAmount,
  }: {
    /**
     * A ClarityFirst office ID
     */
    officeId: number,
    fromIssuedDate: string,
    toIssuedDate: string,
    accountId?: number | null,
    outgoingFundStatus?: string | null,
    fromAmount?: number | null,
    toAmount?: number | null,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_OutgoingFundsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/outgoing-funds',
      path: {
        'officeId': officeId,
      },
      query: {
        'AccountId': accountId,
        'FromIssuedDate': fromIssuedDate,
        'ToIssuedDate': toIssuedDate,
        'OutgoingFundStatus': outgoingFundStatus,
        'FromAmount': fromAmount,
        'ToAmount': toAmount,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        404: `No outgoing funds found`,
        422: `Invalid sent information to filter`,
      },
    });
  }

  /**
   * Approves a return or transfer (Auth)
   * @returns void
   * @throws ApiError
   */
  public approveReturnTransferAsync({
    officeId,
    fundId,
  }: {
    /**
     * Office Id
     */
    officeId: number,
    /**
     * Fund Id
     */
    fundId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}/_approve',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the office nor have activity rights`,
        404: `User is external`,
      },
    });
  }

  /**
   * Reject a return or transfer (Auth)
   * @returns void
   * @throws ApiError
   */
  public rejectReturnTransferAsync({
    officeId,
    fundId,
  }: {
    /**
     * Office Id
     */
    officeId: number,
    /**
     * Fund Id
     */
    fundId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}/_reject',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the office nor have activity rights`,
        404: `User is external`,
        409: `could not reject wire or wire has previously been rejected or approved`,
      },
    });
  }

  /**
   * Transmit a return or transfer (Auth)
   * @returns void
   * @throws ApiError
   */
  public transmitReturnTransferAsync({
    officeId,
    fundId,
  }: {
    /**
     * Office Id
     */
    officeId: number,
    /**
     * Fund Id
     */
    fundId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}/_transmit',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the office`,
        404: `User is external`,
        409: `could not transmit`,
      },
    });
  }

  /**
   * Evaluates if a transfer or return can be transmitted (Auth)
   * @returns Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel Status to identify whether it's possible to Transmit - Error message when not
   * @throws ApiError
   */
  public canBeTransmittedAsync({
    officeId,
    fundId,
  }: {
    /**
     * Order identifier
     */
    officeId: number,
    /**
     * Fund identifier
     */
    fundId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Disbursements_TransmitValidationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}/transmit-status',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        404: `Order/Disbursement Not Found or External user`,
      },
    });
  }

  /**
   * Fetch return/transfer wire details (Auth)
   * @returns Workspace_API_ViewModels_Escrow_FundWireDetailViewModel Successfully fetched data
   * @throws ApiError
   */
  public fetchReturnTransferWireDetailsAsync({
    officeId,
    fundId,
  }: {
    /**
     * Office Id
     */
    officeId: number,
    /**
     * Fund Id
     */
    fundId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_FundWireDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the offices`,
        404: `Office Not Found, Wire details not found or External user`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Fetch return/transfer transmission details (Auth)
   * @returns Workspace_API_ViewModels_Escrow_FundTransmissionDetailViewModel Successfully fetched data
   * @throws ApiError
   */
  public fetchReturnTransferTransmissionDetailsAsync({
    officeId,
    fundId,
  }: {
    /**
     * Office Id
     */
    officeId: number,
    /**
     * Fund Id
     */
    fundId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_FundTransmissionDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}/transmission-detail',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User not authorized to access the offices`,
        404: `Office Not Found, Wire details not found or External user`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Evaluates whether a fund can be approved (Auth)
   * @returns Workspace_API_ViewModels_Escrow_FundApprovalValidationViewModel Status to identify whether it's possible to Approve - Error message when not
   * @throws ApiError
   */
  public canBeApprovedAsync({
    officeId,
    fundId,
  }: {
    /**
     * Office identifier
     */
    officeId: number,
    /**
     * Fund identifier
     */
    fundId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Escrow_FundApprovalValidationViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/funds/{fundId}/approval-status',
      path: {
        'officeId': officeId,
        'fundId': fundId,
      },
      errors: {
        403: `User not authorized to access the office`,
        404: `Office Not Found or External user`,
      },
    });
  }

  /**
   * Get all the IBA transactions in pending approval status by office ID (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBATransactionPendingApprovalResponseViewModel IBA transactions successfully retrieved
   * @throws ApiError
   */
  public fetchIbaTransactionPendingApprovalAsync({
    officeId,
  }: {
    /**
     * A ClarityFirst office ID
     */
    officeId: number,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_IBATransactionPendingApprovalResponseViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/iba-transaction-pending-approval',
      path: {
        'officeId': officeId,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        404: `No outgoing funds found`,
      },
    });
  }

  /**
   * Approve IBA transactions in pending approval status (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBATransactionApprovalResultViewModel IBA transactions successfully retrieved
   * @throws ApiError
   */
  public approvePendingIbaTransactionsAsync({
    officeId,
    requestBody,
  }: {
    /**
     * A ClarityFirst office ID
     */
    officeId: number,
    /**
     * Object containing list of transaction Ids
     */
    requestBody: Workspace_API_ViewModels_IBA_IBATransactionApprovalViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_IBATransactionApprovalResultViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/offices/{officeId}/iba-transaction/approve',
      path: {
        'officeId': officeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        404: `No outgoing funds found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Retrieve IBA Transactions by office ID (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBAOfficeTransactionsViewModel Transactions by Office ID are available
   * @throws ApiError
   */
  public getIbaTransactionsSummaryByOfficeAsync({
    officeId,
    fileNumber,
    fromIssueDate,
    toIssueDate,
    fromAmount,
    toAmount,
    ibaTransactionStatusId,
    ibaTransactionTypeId,
    requestor,
    approver,
    beneficiaryName,
    ibaBankId,
    ibaTransactionType,
    ibaTransactionStatusType,
  }: {
    /**
     * A ClarityFirst office ID
     */
    officeId: number,
    /**
     * Gets or sets the File Number
     */
    fileNumber?: string | null,
    /**
     * Gets or sets the From Issue Date an IBA bank product
     */
    fromIssueDate?: string | null,
    /**
     * Gets or sets the To Issue Date an IBA bank product
     */
    toIssueDate?: string | null,
    /**
     * Gets or sets the From Amount an IBA bank product
     */
    fromAmount?: number | null,
    /**
     * Gets or sets the To Amount an IBA bank product
     */
    toAmount?: number | null,
    /**
     * Gets or sets the ID of the transaction status
     */
    ibaTransactionStatusId?: number,
    /**
     * Gets or sets the ID of the transaction type
     */
    ibaTransactionTypeId?: number,
    /**
     * Gets or sets the Requestor an IBA bank product
     */
    requestor?: string | null,
    /**
     * Gets or sets the Approver an IBA bank product
     */
    approver?: string | null,
    /**
     * Gets or sets the Beneficiary Name an IBA bank product
     */
    beneficiaryName?: string | null,
    /**
     * Gets or sets the name of IBA Bank
     */
    ibaBankId?: number,
    ibaTransactionType?: Eclipse_SDK_DTO_IBATransactionType,
    ibaTransactionStatusType?: Eclipse_SDK_DTO_IBATransactionStatusType,
  }): CancelablePromise<Array<Workspace_API_ViewModels_IBA_IBAOfficeTransactionsViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/iba-transactions-summary-office',
      path: {
        'officeId': officeId,
      },
      query: {
        'FileNumber': fileNumber,
        'FromIssueDate': fromIssueDate,
        'ToIssueDate': toIssueDate,
        'FromAmount': fromAmount,
        'ToAmount': toAmount,
        'IBATransactionStatusId': ibaTransactionStatusId,
        'IBATransactionTypeId': ibaTransactionTypeId,
        'Requestor': requestor,
        'Approver': approver,
        'BeneficiaryName': beneficiaryName,
        'IBABankId': ibaBankId,
        'IBATransactionType': ibaTransactionType,
        'IBATransactionStatusType': ibaTransactionStatusType,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Get all IBA banks for a particular office id (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBABankViewModel Success
   * @throws ApiError
   */
  public getIbaBanks({
    officeId,
  }: {
    /**
     * Office identifier
     */
    officeId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_IBA_IBABankViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{officeId}/banks',
      path: {
        'officeId': officeId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the status of the validation criteria for changing the Owning Office in an order (Auth)
   * @returns Workspace_API_ViewModels_OrderOwningOffice_OwningOfficeValidationViewModel Office validations successfully checked
   * @throws ApiError
   */
  public canChangeOwningOfficeForOrderAsync({
    orderId,
  }: {
    /**
     * An order Id / file Id
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_OrderOwningOffice_OwningOfficeValidationViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/can-change-for-order/{orderId}',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        404: `Failed to check validations`,
      },
    });
  }

}
