import workspaceAPI from 'services/workspaceAPI';
import { AsyncAction } from 'store';
import { addToast, setLoadingStateAction } from 'store/ui/actions';
import {
  OrderLoadingKeys,
  OrderTypeKeys,
  IOrderDisbursement,
  IOrderDisbursementHistory,
  ORDER_DISBURSEMENT_CHECK_POSITIVE_ID_TYPES,
} from '../types';
import { throwServerOrServiceDeskError } from '../../errors/helpers';
import { orderBy } from 'lodash';
import { AxiosPromise } from 'axios';

interface IGetDisbursementPositiveIdParams {
  disbursementId: number;
  disbursementTypeId: number;
  orderId: number;
  fileProcessId: number;
  payeeId: number;
}

export const getDisbursementPositiveId = ({
  disbursementId,
  disbursementTypeId,
  orderId,
  fileProcessId,
  payeeId,
}: IGetDisbursementPositiveIdParams): Promise<number> => {
  if (
    disbursementId > 0 ||
    !ORDER_DISBURSEMENT_CHECK_POSITIVE_ID_TYPES.includes(disbursementTypeId)
  ) {
    return new Promise((resolve) => resolve(disbursementId));
  }
  return workspaceAPI
    .post(
      `/orders/${orderId}/disbursements/${fileProcessId}/_add?payeeId=${payeeId}`
    )
    .then(({ data }) => data.disbursementId);
};

export const replaceDisbursementId = (oldId: number, newId: number) => {
  return {
    type: OrderTypeKeys.REPLACE_ORDER_DISBURSEMENT_ID,
    oldId,
    newId,
  };
};

export interface IGetOrderDisbursementInfoParams {
  orderId: number;
  fileProcessId: number;
  disbursementId?: number;
  payeeId?: number;
  loadSuperDisbursement?: boolean;
  loadHistory?: boolean;
}

export const getOrderDisbursementInfo = ({
  orderId,
  fileProcessId,
  disbursementId,
  loadSuperDisbursement = false,
  loadHistory = false,
  payeeId,
}: IGetOrderDisbursementInfoParams): AsyncAction<void> => {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderDisbursementInfoLoading, true)
    );

    try {
      const disbursementResponse = await getOrderDisbursement(
        orderId,
        fileProcessId,
        disbursementId,
        payeeId
      );
      const disbursementData = disbursementResponse.data;
      if (loadSuperDisbursement && disbursementData.superDisbursementId) {
        const superDisbursementResponse = await getOrderDisbursement(
          orderId,
          fileProcessId,
          disbursementData.superDisbursementId,
          payeeId
        );
        disbursementData.superDisbursement = superDisbursementResponse.data;
        const superDisbursementSplitsResponse = await getOrderDisbursementSplits(
          orderId,
          disbursementData.superDisbursementId,
          fileProcessId
        );
        if (superDisbursementSplitsResponse.data.children) {
          disbursementData.splitDetails = superDisbursementSplitsResponse.data.children.find(
            (sp) => sp.id === disbursementId
          );
        }
      }
      if (loadHistory) {
        const disbursementHistoryResponse = await getOrderDisbursementHistory(
          orderId,
          fileProcessId
        );
        disbursementData.adjustmentHistory =
          orderBy(
            disbursementHistoryResponse.data.disbursementHistory,
            ['disbursementId'],
            ['desc']
          ) || [];
      }
      dispatch({
        type: OrderTypeKeys.REQUEST_ORDER_DISBURSEMENT_INFO_SUCCESS,
        disbursementInfo: disbursementData,
      });
    } catch (error) {
      addToast(
        'There was an error loading disbursement information.',
        'error'
      )(dispatch);
    } finally {
      dispatch(
        setLoadingStateAction(
          OrderLoadingKeys.orderDisbursementInfoLoading,
          false
        )
      );
    }
  };
};

export const deleteOrderDisbursement = (
  orderId: number,
  fileProcessId: number
): AsyncAction<void> => {
  return (dispatch) => {
    return workspaceAPI
      .delete(`/orders/${orderId}/disbursements/${fileProcessId}`)
      .then(() => {
        dispatch({
          type: OrderTypeKeys.DELETE_ORDER_DISBURSEMENT_SUCCESS,
          disbursementMetaData: {
            orderId,
            fileProcessId,
          },
        });
      })
      .catch((error) => {
        throwServerOrServiceDeskError(
          error,
          'An error occurred and the disbursement could not be deleted'
        );
      });
  };
};

export const getOrderDisbursement = (
  orderId: number,
  fileProcessId: number,
  disbursementId?: number,
  payeeId?: number
): AxiosPromise<IOrderDisbursement> => {
  const fetchDisbursementInfoUrl =
    disbursementId && disbursementId > 0
      ? `/orders/${orderId}/disbursements/${fileProcessId}?disbursementId=${disbursementId}`
      : `/orders/${orderId}/disbursements/${fileProcessId}?payeeId=${payeeId}`;

  return workspaceAPI.get<IOrderDisbursement>(fetchDisbursementInfoUrl);
};

export const getOrderDisbursementSplits = (
  orderId: number | string,
  disbursementId: number | string,
  fileProcessId: number | string,
  payeeId?: number | string
): AxiosPromise<any> => {
  const fetchDisbursementSplitInfoUrl =
    disbursementId && Number(disbursementId) > 0
      ? `/orders/${orderId}/disbursements/${fileProcessId}/_split?disbursementId=${disbursementId}`
      : `/orders/${orderId}/disbursements/${fileProcessId}/_split?payeeId=${payeeId}`;
  return workspaceAPI.get(fetchDisbursementSplitInfoUrl);
};

export const getOrderDisbursementHistory = (
  orderId: number | string,
  fileProcessId: number | string
): AxiosPromise<any> => {
  return workspaceAPI.get<{ disbursementHistory: IOrderDisbursementHistory[] }>(
    `/orders/${orderId}/disbursements/${fileProcessId}/_history`
  );
};
