import { LookupsTypeKeys, LookupsAction, ILookupsState } from './types';

export const initialState: ILookupsState = {
  countries: [],
  countryStates: {},
  propertyTypes: [],
  businessEntityTypes: [],
  stateCounties: {},
  transactionTypes: {
    state: 'clear',
    data: [],
    lastError: null,
  },
};

export default (state = initialState, action: LookupsAction): ILookupsState => {
  switch (action.type) {
    case LookupsTypeKeys.SET_COUNTRIES:
      return {
        ...state,
        countries: {
          ...state.countries,
          ...action.payload.countries,
        },
      };

    case LookupsTypeKeys.SET_COUNTRY_STATES:
      return {
        ...state,
        countryStates: {
          ...state.countryStates,
          ...action.payload.countryStates,
        },
      };

    case LookupsTypeKeys.SET_STATE_COUNTIES:
      return {
        ...state,
        stateCounties: {
          ...state.stateCounties,
          ...action.payload.stateCounties,
        },
      };

    case LookupsTypeKeys.SET_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: (action.payload.propertyTypes ?? []).sort((a, b) =>
          a.displayName > b.displayName ? 1 : -1
        ),
      };
    case LookupsTypeKeys.FETCH_TRANSACTION_TYPES:
      return {
        ...state,
        transactionTypes: {
          data:
            action.state !== 'error' && action.transactionTypes
              ? action.transactionTypes
              : state.transactionTypes.data,
          state: action.state,
          lastError: action.error,
        },
      };
    case LookupsTypeKeys.SET_BUSINESS_ENTITY_TYPES:
      return {
        ...state,
        businessEntityTypes: action.payload.businessEntityTypes,
      };

    default:
      return state;
  }
};
