import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0C4.95 0 0 4.95 0 11C0 17.05 4.95 22 11 22C17.05 22 22 17.05 22 11C22 4.95 17.05 0 11 0ZM2.2 11C2.2 6.16 6.16 2.2 11 2.2C12.98 2.2 14.85 2.86 16.39 4.07L4.07 16.39C2.86 14.85 2.2 12.98 2.2 11ZM11 19.7996C9.01997 19.7996 7.14997 19.1396 5.60997 17.9296L17.93 5.60957C19.14 7.14957 19.8 9.01957 19.8 10.9996C19.8 15.8396 15.84 19.7996 11 19.7996Z"
      fill={props.fill || '#C4C4C4'}
    />
  </svg>
);
