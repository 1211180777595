import React from 'react';
import styled from 'styled-components';
import Loader, { LoaderType } from 'react-loaders';
import { headerZIndex, white } from 'config/theme';
import { Spinner } from 'components/ui/Spinner/Spinner';

export interface IViewLoaderProps {
  className?: string;
  active: boolean;
  type: LoaderType;
  info?: string;
  innerClassName?: string;
}

export const ViewLoader = ({
  className,
  type,
  active,
  info,
  innerClassName,
}: IViewLoaderProps) => (
  <div className={className} data-testid="viewloader">
    <Loader type={type} active={active} innerClassName={innerClassName} />
    {info && <Info>{info}</Info>}
  </div>
);
ViewLoader.displayName = 'ViewLoader';

export const ViewLoaderSpinner: React.FC<{ color?: string; size?: number }> = ({
  color = '#fff',
  size = 24,
}) => (
  <div data-testid="viewloader">
    <StyledSpinner data-testid="styled-spinner">
      <Spinner color={color} size={size} />
    </StyledSpinner>
  </div>
);
export interface IStyledViewLoaderProps {
  background?: string;
  height?: string;
  top?: string;
}

const StyledSpinner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const StyledViewLoader = styled(ViewLoader)<IStyledViewLoaderProps>`
  position: fixed;
  ${(props: IStyledViewLoaderProps) =>
    props && props.top ? `top: ${props.top};` : `top: 0;`}
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.background || 'rgba(0, 0, 0, 0.3)'};
  height: ${(props) => props.height || 'auto'};
  z-index: ${headerZIndex * 12};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
StyledViewLoader.displayName = 'StyledViewLoader';

export default StyledViewLoader;

const Info = styled.p`
  display: block;
  font-style: italic;
  font-size: 1em;
  color: ${white};
  margin: 10px 12px;
`;
