/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_EditOrderParties_OrganizationAddressPhoneViewModel } from '../models/Workspace_API_ViewModels_EditOrderParties_OrganizationAddressPhoneViewModel';
import type { Workspace_API_ViewModels_EditOrderParties_OrganizationAddressViewModel } from '../models/Workspace_API_ViewModels_EditOrderParties_OrganizationAddressViewModel';
import type { Workspace_Common_Models_Dto_EditOrderParties_OrganizationContactDto } from '../models/Workspace_Common_Models_Dto_EditOrderParties_OrganizationContactDto';
import type { Workspace_Common_Responses_OrganizationContactsList } from '../models/Workspace_Common_Responses_OrganizationContactsList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * For a given Organization ID, retrieve all contact details registered in GAB for it. (Auth)
   * @returns Workspace_Common_Responses_OrganizationContactsList Organization contacts
   * @throws ApiError
   */
  public getOrganizationContacts({
    orgId,
  }: {
    /**
     * GAB Organization Id
     */
    orgId: number,
  }): CancelablePromise<Workspace_Common_Responses_OrganizationContactsList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/organizations/{orgId}/contacts',
      path: {
        'orgId': orgId,
      },
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * For a  given Organization ID, retrieve business address of the organization from GAB (Auth)
   * @returns Workspace_API_ViewModels_EditOrderParties_OrganizationAddressViewModel Organization Address
   * @throws ApiError
   */
  public getOrganizationAddress({
    orgId,
  }: {
    /**
     * GAB Organization Id
     */
    orgId: number,
  }): CancelablePromise<Workspace_API_ViewModels_EditOrderParties_OrganizationAddressViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/organizations/{orgId}/address',
      path: {
        'orgId': orgId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * For a  given Organization ID, retrieve business address and business phone of the organization from GAB (Auth)
   * @returns Workspace_API_ViewModels_EditOrderParties_OrganizationAddressPhoneViewModel Organization Address
   * @throws ApiError
   */
  public getOrganizationAddressPhone({
    orgId,
  }: {
    /**
     * GAB Organization Id
     */
    orgId: number,
  }): CancelablePromise<Workspace_API_ViewModels_EditOrderParties_OrganizationAddressPhoneViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/organizations/{orgId}/address-phone',
      path: {
        'orgId': orgId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * For a given Organization ID, retrieve all contact details registered in GAB for it. Single Eclipse call. (Auth)
   * @returns Workspace_Common_Models_Dto_EditOrderParties_OrganizationContactDto Organization contacts
   * @throws ApiError
   */
  public getOrganizationContactsDetails({
    organizationId,
  }: {
    /**
     * GAB Organization Id
     */
    organizationId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_Dto_EditOrderParties_OrganizationContactDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/organizations/{organizationId}/gab-contacts',
      path: {
        'organizationId': organizationId,
      },
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

}
