import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dispatch } from 'store';
import * as actions from 'store/appNotifications/actions';
import {
  AppNotificationType,
  IAppNotification,
} from 'store/appNotifications/types';
import {
  black,
  blackSqueeze,
  linkWater,
  trout,
  cadetBlue,
  blue,
} from 'config/theme';
import NotificationIcon from './NotificationIcon';
import { timeInWords, timestampToString } from 'utils/date';
import { isEmpty } from 'utils/text';
import { isInternalUser } from 'store/auth/getters';
import { LiveDealsFeatures } from 'store/features/types';
import { isFeatureEnabled } from 'store/features/helpers';
import { NotificationDescription } from './NotificationDescription';
import { toParams } from 'utils/objects';
import { getSettlementStatementLink } from './utils';

interface INotificationRowProps {
  dispatch: Dispatch;
  notification: IAppNotification;
  theme: NotificationRowTheme;
  onClick?: () => void;
  isInternal?: boolean;
  relatedIds?: number[];
  isDocNotificationEnabled: boolean;
}

export class NotificationRow extends React.Component<INotificationRowProps> {
  public render() {
    const {
      notification: {
        timestamp,
        type: notificationType,
        visited,
        message,
        fileDisplayName,
        fileNumber,
        relatedIds,
        subject,
      },
      theme,
      isDocNotificationEnabled,
      isInternal,
    } = this.props;

    const styles = Themes[theme];
    const hasMessageToDisplay =
      notificationType === AppNotificationType.SHARED_DOCUMENTS &&
      !isEmpty(message);

    const sharedDocumentFeatureToggle =
      !isDocNotificationEnabled &&
      notificationType === AppNotificationType.SHARED_DOCUMENTS;

    const canCustomerAccessEmployeeNotificationType =
      !isInternal &&
      (notificationType === AppNotificationType.ADDED_WIRE_INSTRUCTION ||
        notificationType === AppNotificationType.VERIFIED_WIRE_INSTRUCTION);

    const canEmployeeAccessCustomerNotificationType =
      isInternal &&
      (notificationType === AppNotificationType.ADDED_TO_ORDER ||
        notificationType === AppNotificationType.SHARED_DOCUMENTS);

    return sharedDocumentFeatureToggle ||
      canCustomerAccessEmployeeNotificationType ||
      canEmployeeAccessCustomerNotificationType ? null : (
      <Container
        to={this.getNotificationActionPath()}
        onClick={this.handleRowClick}
        visited={!!visited}
        style={styles.container}
        hasMessageToDisplay={hasMessageToDisplay}
      >
        <NotificationIcon
          visited={visited}
          notificationType={notificationType}
        />
        <Description style={styles.description}>
          <NotificationDescription
            notificationType={notificationType}
            fileDisplayName={fileDisplayName}
            fileNumber={fileNumber}
            subject={subject}
            relatedIds={relatedIds}
          />
          {hasMessageToDisplay && (
            <MessageContainer>{'“' + message + '”'}</MessageContainer>
          )}
          <CreatedAt
            title={timestampToString(timestamp)}
            style={styles.createdAt}
          >
            {timeInWords(timestamp)}
          </CreatedAt>
        </Description>
      </Container>
    );
  }

  private customerDocUrl = (subject, fileId, relatedId): string => {
    if (/received/.test(subject)) return `/order/${fileId}/documents`;

    return `/order/${fileId}/documents/document-submission/${relatedId}`;
  };

  private getNotificationActionPath = (): string => {
    const {
      fileId,
      type: notificationType,
      meta,
      relatedId,
      relatedIds,
      subject,
    } = this.props.notification;

    const { statementId, statementClosingGroupId, statementType } = meta || {};

    const requestCode = meta ? meta.requestCode : '';
    const params = toParams({
      code: requestCode,
      file: fileId,
      id: relatedId,
    });

    const wireInstructionFileId = relatedIds?.[0];

    const notificationActionPath = {
      [AppNotificationType.ADD_WIRE_INSTRUCTION]: `/wire-instructions/new?${params}`,
      [AppNotificationType.ADDED_TO_ORDER]: `/order/${fileId}/overview`,
      [AppNotificationType.ADDED_WIRE_INSTRUCTION]: `/order/${fileId}/wire-instructions/${relatedId}?wireInstructionFileId=${wireInstructionFileId}`,
      [AppNotificationType.MILESTONE_DIGEST]: `/order/${fileId}/overview`,
      [AppNotificationType.MILESTONE_UPDATE]: `/order/${fileId}/overview`,
      [AppNotificationType.SHARED_DOCUMENTS]: `/order/${fileId}/documents`,
      [AppNotificationType.CUSTOMER_DOCUMENT_UPLOADS]: this.customerDocUrl(
        subject,
        fileId,
        relatedId
      ),
      [AppNotificationType.VERIFIED_WIRE_INSTRUCTION]: `/order/${fileId}/wire-instructions/${relatedId}?wireInstructionFileId=${wireInstructionFileId}`,
      [AppNotificationType.VERIFY_WIRE_INSTRUCTION]: `/wire-instructions/verification?${params}`,
      [AppNotificationType.INVOICE_CREATED]: `/order/${fileId}/invoices/${relatedId}/invoice-edit`,
      [AppNotificationType.INVOICE_APPROVED]: `/order/${fileId}/invoices/${relatedId}/invoice-edit`,
      [AppNotificationType.INVOICE_REJECTED]: `/order/${fileId}/invoices/${relatedId}/invoice-edit`,
      [AppNotificationType.ORDER10999S_UPDATES]: `/1099s/e-sign?code=${requestCode}`,
      [AppNotificationType.SETTLEMENT_STATEMENT_COMMENTS]: getSettlementStatementLink(
        {
          statementClosingGroupId,
          relatedId,
          fileId,
          statementId,
          statementType,
          isInternal: this.props.isInternal,
          notificationPropId: this.props.notification?.id,
        }
      ),
      [AppNotificationType.PSFINVOICE_UPDATES]: '/billing',
      [AppNotificationType.ORDER_CHECKLIST]: `/order/${fileId}/checklists`,
    }[notificationType];

    return notificationActionPath || '';
  };

  private handleRowClick = (): void => {
    this.markAsRead();

    const {
      dispatch,
      notification: { type, subject, relatedId },
    } = this.props;

    if (
      type == AppNotificationType.ORDER_CHECKLIST &&
      subject &&
      /A comment was added to Task/.test(subject)
    ) {
      dispatch(actions.setTaskWithCommentNotification(relatedId));
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  private markAsRead = (): void => {
    const {
      dispatch,
      notification: { id, visited },
    } = this.props;

    if (!visited) {
      dispatch(actions.markNotificationAsRead(id));
    }
  };
}

function mapStateToProps(state) {
  return {
    isInternal: isInternalUser(state.auth),
    isDocNotificationEnabled: isFeatureEnabled(
      state,
      LiveDealsFeatures.DocNotifications
    ),
  };
}

const ConnectedNotificationRow = connect(mapStateToProps)(NotificationRow);
ConnectedNotificationRow.displayName = 'ConnectedNotificationRow';
export default ConnectedNotificationRow;

export enum NotificationRowTheme {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

const Container = styled(Link)<{
  visited: boolean;
  hasMessageToDisplay: boolean;
}>`
  border-bottom: 1px solid ${linkWater};
  display: flex;
  min-height: 78px;
  ${({ hasMessageToDisplay }) =>
    hasMessageToDisplay &&
    `
    min-height: fit-content;
  `}
  padding: 16px 20px 14px 20px;
  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  ${({ visited }) =>
    !visited
      ? `
    background: ${blackSqueeze};
  `
      : ''}
  &:hover {
    background: rgba(52, 85, 128, 0.14);
    text-decoration: none;
  }
  &:active {
    background: rgba(52, 85, 128, 0.26);
  }
`;
Container.displayName = 'Container';

const Description = styled.div`
  color: ${black};
  padding-left: 14px;
  & > .truncated span {
    display: inline !important;
  }
`;
Description.displayName = 'Description';

const CreatedAt = styled.div`
  color: ${trout};
  font-size: 12px;
  line-height: 18px;
`;

const Themes = {
  [NotificationRowTheme.LARGE]: {
    container: {
      padding: '16px 20px 14px 20px',
    },
    createdAt: {
      marginTop: 4,
    },
    description: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  [NotificationRowTheme.SMALL]: {
    container: {
      padding: '12px 20px',
    },
    createdAt: {
      marginTop: 0,
    },
    description: {
      fontSize: 14,
      lineHeight: '19px',
    },
  },
};

const MessageContainer = styled.div`
  border-left-style: solid;
  border-left-color: ${cadetBlue};
  padding-left: 10px;
  margin: 12px 0px;
  color: ${blue};
`;
MessageContainer.displayName = 'MessageContainer';
