import React from 'react';
import styled from 'styled-components';
import { primaryBlue8 } from 'config/colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

interface IUploadProgressLoader {
  value: number;
}

const theme = createTheme({
  overrides: {
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: `${primaryBlue8}`,
      },
      colorPrimary: {
        backgroundColor: 'none',
      },
      root: {
        backgroundColor: 'none',
      },
    },
  },
});

// eslint-disable-next-line import/no-unused-modules
const UploadProgressLoader = (props: IUploadProgressLoader) => {
  return (
    <ThemeProvider theme={theme}>
      <UploadProgress variant="determinate" value={props.value} />
    </ThemeProvider>
  );
};

export default UploadProgressLoader;

const UploadProgress = styled(LinearProgress)<IUploadProgressLoader>``;
UploadProgress.displayName = 'UploadProgressLoader';
