export enum ICurrency {
  US = '$',
}

export function setCurrency(
  amount: number | string | null,
  currency: ICurrency
): string | null {
  if (!Number(amount) && amount !== 0) {
    return null;
  }

  return `${currency}${amount}`;
}

export function setDefaultFloat(amount: number | string, decimal: number = 2) {
  if (!Number(amount) && amount !== 0) {
    return null;
  }

  return Number(amount).toFixed(decimal);
}

export const formatCurrency = (
  currencyValue,
  removeZeroFraction?: boolean
): string | null => {
  if (currencyValue == null) return null;
  let convertedValue = currencyValue.toString();
  convertedValue = Number(
    convertedValue.replace(/^0+/, '').replace(/[^0-9.-]|\.(?=.*\.)/g, '')
  );

  let f = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(convertedValue);

  if (removeZeroFraction) {
    f = f.replace(/\.00$/, '');
  }

  return f;
};

export const formatToRoundedNumber = (currencyValue) => {
  if (currencyValue != null) {
    let convertedValue = currencyValue.toString();
    convertedValue = convertedValue
      .replace(/^0+/, '')
      .replace(/[^0-9.]|\.(?=.*\.)/g, '');
    const formattedValue = Number(convertedValue).toFixed(0).split('.');

    return formattedValue[0]
      .split('')
      .reverse()
      .reduce((acc, currentValue, i) => {
        return currentValue === '-'
          ? acc
          : currentValue + (i && !(i % 3) ? ',' : '') + acc;
      }, '');
  }
};

export const parseCurrency = (amount: string | number) => {
  return Number(String(amount || 0).replace(/[^0-9.-]/g, ''));
};
