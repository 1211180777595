import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchField from 'components/forms/SearchField';
import { clearSearch, navigateToSearch } from 'store/search/actions';
import { shadow2 } from 'config/theme';
import { isEmpty } from 'utils/text';

export class SearchBar extends React.Component {
  state = {
    searchInputFocused: false,
  };

  handleSearchInputSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const query = data.get('q')?.trim() || '';

    if (!query.match(/\w/g)) {
      this.inputEl.value = '';
      return false;
    }

    this.inputEl.blur();
    this.props.dispatch(navigateToSearch({ query }));
  };

  componentDidUpdate() {
    this.inputEl.value = this.props.query;
  }

  componentDidMount() {
    this.inputEl.value = this.props.query;
  }

  clear = () => {
    this.props.dispatch(clearSearch());
  };

  render() {
    return (
      <SearchBarContainer id="searchBarContainer">
        <StyledSearchField
          inputRef={(comp) => (this.inputEl = comp)}
          focused={this.state.searchInputFocused}
          onSubmit={this.handleSearchInputSubmit}
          onFocus={() => this.setState({ searchInputFocused: true })}
          onBlur={() => this.setState({ searchInputFocused: false })}
          placeholder="Search by order number, address, person, keyword…"
          onClearButtonClick={!isEmpty(this.props.query) ? this.clear : null}
          inputHeight={36}
          autoComplete="off"
          clearButtonId="app-header-search-clear-button"
        />
      </SearchBarContainer>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  const q = new URLSearchParams(ownProps?.location?.search)?.get('q');
  const maintainQuery = [/^\/search/].some((regEx) =>
    regEx.test(ownProps.location.pathname)
  );
  const query = maintainQuery ? q || state.search.query : '';
  return { query };
}

export default withRouter(connect(mapStateToProps)(SearchBar));

const SearchBarContainer = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 430px;
`;

const StyledSearchField = styled(SearchField)`
  box-shadow: ${shadow2};
`;
