import { WorkspaceClient } from 'services/__generated__';

// Moving forward, all workspace API interactions should work with this client
// Do not use workspaceAPI in `index.ts`
const client = new WorkspaceClient({
  BASE: AppConfig.workspaceBackendBaseUrl,
  WITH_CREDENTIALS: true,
});

export default client;
