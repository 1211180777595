import { ExternalButtonLink } from '.';
import { HeroH2 } from './Hero.styles';
import { Row } from 'components/ui/Box';
import {
  BottomLinks,
  DeviceImage,
  FeaturesGrid,
  FeaturesSection as Section,
  List,
  MobileAppContent,
  MobileAppMessage,
} from './Features.styles';
import AppStoreLink from 'components/MobileStoreLink/AppStoreLink';
import bgLinesBottom from 'images/landing-page/Desktop-Lines-Bottom.png';
import bgLinesMiddle from 'images/landing-page/Desktop-Lines-Middle.png';
import devices from 'images/devices/devices.png';
import devices2x from 'images/devices/devices@2x.png';
import devices3x from 'images/devices/devices@3x.png';
import devices4x from 'images/devices/devices@4x.png';
import GooglePlayLink from 'components/MobileStoreLink/GooglePlayLink';
import React, { FunctionComponent } from 'react';

const Features: FunctionComponent = () => {
  return (
    <Section
      sectionProps={{
        style: {
          backgroundImage: `url(${bgLinesMiddle}), url(${bgLinesBottom})`,
          backgroundPosition: 'top center, bottom center',
          backgroundRepeat: 'no-repeat',
          paddingBottom: '102px',
          paddingTop: '84px',
        },
      }}
    >
      <FeaturesGrid>
        <HeroH2>
          ClarityFirst is the first end-to-end digital solution for commercial
          real estate transactions.
        </HeroH2>

        <List>
          <ul>
            <li>Instant access to your deal status, details and documents</li>
            <li>Milestone tracking and notifications</li>
            <li>Robust property data search</li>
            <li>Mobile app for quick on-the-go access</li>
          </ul>
          <MobileAppContent>
            <MobileAppMessage>
              ClarityFirst.com is optimized for desktop computers and tablets.
              On mobile phones, use the ClarityFirst mobile app.
            </MobileAppMessage>
            <Row gap="medium">
              <AppStoreLink />
              <GooglePlayLink />
            </Row>
          </MobileAppContent>
        </List>

        <DeviceImage>
          <div>
            <img
              alt="ClarityFirst mobile application on a phone, and web application rendered on a laptop"
              src={devices}
              srcSet={`${devices} 1x, ${devices2x} 2x, ${devices3x} 3x, ${devices4x} 4x`}
            />
          </div>
        </DeviceImage>

        <BottomLinks>
          <ExternalButtonLink
            aria-label="Request Account Button"
            href="https://ncsmarketing.firstam.com/clarityfirst/?utm_source=cf&utm_medium=web&utm_campaign=request_account&utm_content=top_nav"
            tabIndex={5}
            variant="primary"
          >
            Request Account
          </ExternalButtonLink>
          <ExternalButtonLink
            aria-label="Help"
            href="https://registration.firstam.com/EA/ClarityFirst/HelpCustomersOutside/index.html"
            tabIndex={6}
            variant="secondary"
          >
            Help
          </ExternalButtonLink>
        </BottomLinks>
      </FeaturesGrid>
    </Section>
  );
};

export default Features;
