import { AxiosResponse } from 'axios';

export interface IDisplayNameProgressRow {
  name: string;
  nameSubHeader?: string;
  nameToolTip?: string;
}

export interface IProgressData {
  progressUniqueId: string; // can be anything, has to be unique
  status: TaskStatus;
  pollingUrl: string;
  successFunction: (response: AxiosResponse) => boolean; // (response) => { return response.data.DeliveryStatus === TrustExtractPreviewStatus.SUCCESS }
  displayName: IDisplayNameProgressRow; // "Trust Extract preview file",
  additionalProps: any; // { viewUrl?: string; // documentType: Pdf }
}

export type ProgressBarKeys = 'TrustExtract' | 'DepositCheckImage'; // | 'Example2' | 'Example3';

export enum TaskStatus {
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  SUCCESS = 'Succeeded',
}

export enum MultipleProgressEnum {
  SET_ADD_NEW_PROGRESS_ITEM = 'multipleProgress/SET_ADD_NEW_PROGRESS_ITEM',
  SET_SUCCESS_PROGRESS_ITEM = 'multipleProgress/SET_SUCCESS_PROGRESS_ITEM',
  SET_FAIL_PROGRESS_ITEM = 'multipleProgress/SET_FAIL_PROGRESS_ITEM',
  REMOVE_PROGRESS_ITEM = 'multipleProgress/REMOVE_PROGRESS_ITEM',
  CLEAR_ALL_PROGRESS_ITEMS = 'multipleProgress/CLEAR_ALL_PROGRESS_ITEMS',
  TOGGLE_IS_EXPANDED_PROGRESS_ITEMS = 'multipleProgress/TOGGLE_IS_EXPANDED_PROGRESS_ITEMS',
}

export interface IProgressAction {
  identifier: ProgressBarKeys;
}

export interface IProgressActionMetaData {
  identifier: ProgressBarKeys;
  progressItem: IProgressData;
}

export interface IAddNewProgressAction {
  type: MultipleProgressEnum.SET_ADD_NEW_PROGRESS_ITEM;
  progressItemMetaData: IProgressActionMetaData;
}

export interface ISetSuccessProgressAction {
  type: MultipleProgressEnum.SET_SUCCESS_PROGRESS_ITEM;
  progressItemMetaData: IProgressActionMetaData;
}

export interface ISetFailProgressAction {
  type: MultipleProgressEnum.SET_FAIL_PROGRESS_ITEM;
  progressItemMetaData: IProgressActionMetaData;
}

export interface IRemoveProgressAction {
  type: MultipleProgressEnum.REMOVE_PROGRESS_ITEM;
  progressItemMetaData: IProgressActionMetaData;
}

export interface IClearAllProgressItemsAction {
  type: MultipleProgressEnum.CLEAR_ALL_PROGRESS_ITEMS;
  clearAllItems: IProgressAction;
}

export interface IToggleIsExpandedProgressItemsAction {
  type: MultipleProgressEnum.TOGGLE_IS_EXPANDED_PROGRESS_ITEMS;
  toggleProgressBar: IProgressAction;
}

export type IMultipleProgressStickyAction =
  | ISetFailProgressAction
  | ISetSuccessProgressAction
  | IAddNewProgressAction
  | IRemoveProgressAction
  | IClearAllProgressItemsAction
  | IToggleIsExpandedProgressItemsAction;
