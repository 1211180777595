import React from 'react';

interface ISearchResultsBlankSlateProps {
  height?: string;
  width?: string;
}

export default (props: ISearchResultsBlankSlateProps) => (
  <svg
    width={props.width || '173'}
    height={props.height || '134'}
    viewBox="0 0 173 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="search-results-blank-slate"
  >
    <rect x="0.0820312" y="87" width="6" height="2" rx="1" fill="#9BABC2" />
    <rect x="163.082" y="87" width="6" height="2" rx="1" fill="#9BABC2" />
    <rect x="8.08203" y="87" width="153" height="2" rx="1" fill="#9BABC2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.1255 40.335H36.0724C35.4807 40.335 35.002 40.8183 35.002 41.4132V86.6454C35.002 87.2403 35.4807 87.7217 36.0724 87.7217H55.1255C55.7171 87.7217 56.1959 87.2403 56.1959 86.6454V41.4132C56.1959 40.8183 55.7171 40.335 55.1255 40.335Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.1255 40.335H36.0724C35.4807 40.335 35.002 40.8183 35.002 41.4132V86.6454C35.002 87.2403 35.4807 87.7217 36.0724 87.7217H55.1255C55.7171 87.7217 56.1959 87.2403 56.1959 86.6454V41.4132C56.1959 40.8183 55.7171 40.335 55.1255 40.335Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.423 47.4375H129.176C128.639 47.4375 128.203 47.8778 128.203 48.416V86.7422C128.203 87.2823 128.639 87.7207 129.176 87.7207H148.423C148.961 87.7207 149.397 87.2823 149.397 86.7422V48.416C149.397 47.8778 148.961 47.4375 148.423 47.4375Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.423 47.4375H129.176C128.639 47.4375 128.203 47.8778 128.203 48.416V86.7422C128.203 87.2823 128.639 87.7207 129.176 87.7207H148.423C148.961 87.7207 149.397 87.2823 149.397 86.7422V48.416C149.397 47.8778 148.961 47.4375 148.423 47.4375Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.961 130.315C119.961 132.275 108.363 133.864 94.057 133.864C79.7506 133.864 68.1533 132.275 68.1533 130.315C68.1533 128.352 79.7506 126.763 94.057 126.763C108.363 126.763 119.961 128.352 119.961 130.315Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.8568 87.7208H18.645V75.4764L29.7519 65.9854L40.8568 75.4764V87.7208Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.8568 87.7208H18.645V75.4764L29.7519 65.9854L40.8568 75.4764V87.7208Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0178 77.0593C40.4768 77.0593 39.9338 76.8734 39.4939 76.4957L29.9129 68.306L20.3318 76.4957C19.3451 77.345 17.8543 77.2237 17.0136 76.2237C16.1709 75.2276 16.2915 73.7345 17.2821 72.8872L28.3871 63.3961C29.2667 62.6427 30.559 62.6427 31.4367 63.3961L42.5417 72.8872C43.5342 73.7345 43.6529 75.2276 42.8122 76.2237C42.347 76.7736 41.6814 77.0593 41.0178 77.0593Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0178 77.0593C40.4768 77.0593 39.9338 76.8734 39.4939 76.4957L29.9129 68.306L20.3318 76.4957C19.3451 77.345 17.8543 77.2237 17.0136 76.2237C16.1709 75.2276 16.2915 73.7345 17.2821 72.8872L28.3871 63.3961C29.2667 62.6427 30.559 62.6427 31.4367 63.3961L42.5417 72.8872C43.5342 73.7345 43.6529 75.2276 42.8122 76.2237C42.347 76.7736 41.6814 77.0593 41.0178 77.0593Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.7754 87.6928H26.8882V82.3641C26.8882 80.7301 28.2057 79.4053 29.8308 79.4053C31.4578 79.4053 32.7754 80.7301 32.7754 82.3641V87.6928Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.7754 87.6928H26.8882V82.3641C26.8882 80.7301 28.2057 79.4053 29.8308 79.4053C31.4578 79.4053 32.7754 80.7301 32.7754 82.3641V87.6928Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.072 94.0945L133.717 104.45L121.641 92.3725L131.997 82.0186L144.072 94.0945Z"
      fill="#AEBBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.072 94.0945L133.717 104.45L121.641 92.3725L131.997 82.0186L144.072 94.0945Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.223 114.798C172.034 118.608 172.034 124.787 168.223 128.599C164.412 132.408 158.233 132.408 154.422 128.599L133.721 107.896C132.769 106.942 132.769 105.399 133.721 104.446L144.072 94.0937C145.024 93.142 146.568 93.142 147.522 94.0937L168.223 114.798Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.072 94.0942L138.896 99.2702C139.848 98.3185 141.395 98.3185 142.346 99.2702L163.05 119.972C165.943 122.868 166.626 127.122 165.124 130.675C166.249 130.199 167.308 129.513 168.223 128.598C172.034 124.789 172.036 118.607 168.223 114.798L147.522 94.0942C146.568 93.1405 145.024 93.1405 144.072 94.0942Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.223 114.798C172.034 118.608 172.034 124.787 168.223 128.599C164.412 132.408 158.233 132.408 154.422 128.599L133.721 107.896C132.769 106.942 132.769 105.399 133.721 104.446L144.072 94.0937C145.024 93.142 146.568 93.142 147.522 94.0937L168.223 114.798Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.549 52.6925C143.549 80.988 120.612 103.928 92.3157 103.928C64.0212 103.928 41.082 80.988 41.082 52.6925C41.082 24.3971 64.0212 1.45703 92.3157 1.45703C120.612 1.45703 143.549 24.3971 143.549 52.6925Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.3162 11.2152C118.965 11.2152 140.844 31.5662 143.304 57.5711C143.455 55.9641 143.55 54.3409 143.55 52.6915C143.55 24.3961 120.611 1.45605 92.3162 1.45605C64.0197 1.45605 41.0825 24.3961 41.0825 52.6915C41.0825 54.3409 41.1773 55.9641 41.3285 57.5711C43.7884 31.5662 65.667 11.2152 92.3162 11.2152Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.549 52.6925C143.549 80.988 120.612 103.928 92.3157 103.928C64.0212 103.928 41.082 80.988 41.082 52.6925C41.082 24.3971 64.0212 1.45703 92.3157 1.45703C120.612 1.45703 143.549 24.3971 143.549 52.6925Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.571 52.692C132.571 74.9243 114.55 92.9484 92.3161 92.9484C70.0846 92.9484 52.061 74.9243 52.061 52.692C52.061 30.4597 70.0846 12.4355 92.3161 12.4355C114.55 12.4355 132.571 30.4597 132.571 52.692Z"
      fill="#C8D5E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.8512 81.1573C48.1303 65.4358 48.1303 39.9491 63.8512 24.2276C79.5722 8.50615 105.06 8.50615 120.781 24.2276L63.8512 81.1573Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.571 52.692C132.571 74.9243 114.55 92.9484 92.3161 92.9484C70.0846 92.9484 52.061 74.9243 52.061 52.692C52.061 30.4597 70.0846 12.4355 92.3161 12.4355C114.55 12.4355 132.571 30.4597 132.571 52.692Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.799 24.6348C72.5751 24.6348 71.5811 25.6288 71.5811 26.8548V87.1517C77.644 90.8053 84.7212 92.9487 92.3165 92.9487C97.95 92.9487 103.307 91.7833 108.177 89.6943V26.8548C108.177 25.6288 107.183 24.6348 105.959 24.6348H73.799Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.177 26.8538C108.177 25.6299 107.183 24.6338 105.959 24.6338H73.7995C72.5756 24.6338 71.5815 25.6299 71.5815 26.8538V73.4315L108.177 36.8327V26.8538Z"
      fill="white"
    />
    <path
      d="M73.082 31H88.082C88.6343 31 89.082 31.4477 89.082 32C89.082 32.5523 88.6343 33 88.082 33H73.082V31Z"
      fill="#9BABC2"
    />
    <path
      d="M73.082 39H80.082C80.6343 39 81.082 39.4477 81.082 40C81.082 40.5523 80.6343 41 80.082 41H73.082V39Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.2999 24C73.0761 24 72.082 24.9941 72.082 26.22V86.5169C78.145 90.1706 85.2221 92.314 92.8175 92.314C98.4509 92.314 103.808 91.1485 108.678 89.0596V26.22C108.678 24.9941 107.683 24 106.46 24H74.2999Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.617 83.1895C110.617 81.8426 111.71 80.7497 113.056 80.7497H117.936C118.801 80.7497 119.525 81.2256 119.958 81.9051C123.918 78.1567 127.134 73.636 129.318 68.5508H95.9785V92.7631C101.217 92.2913 106.153 90.8032 110.617 88.5187V83.1895Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.311 62.5257L117.619 55.0672C116.296 54.3474 114.697 54.3474 113.374 55.0672L93.0967 66.1108V68.5506H129.317C130.146 66.6189 130.791 64.5965 131.311 62.5257Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.297 62.9984L117.604 55.5399C116.281 54.82 114.682 54.82 113.36 55.5399L93.082 66.5834V69.0232H129.302C130.131 67.0916 130.776 65.0692 131.297 62.9984Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.935 80.75H113.056C111.709 80.75 110.616 81.8429 110.616 83.1898V88.519C114.048 86.7607 117.185 84.5307 119.958 81.9054C119.526 81.2259 118.8 80.75 117.935 80.75Z"
      fill="#BECFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.935 80.75H113.056C111.709 80.75 110.616 81.8429 110.616 83.1898V88.519C114.048 86.7607 117.185 84.5307 119.958 81.9054C119.526 81.2259 118.8 80.75 117.935 80.75Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.571 83.5103C110.571 82.1752 111.653 81.0919 112.986 81.0919H117.816C118.672 81.0919 119.389 81.5636 119.818 82.2372C123.737 78.5217 126.921 74.0406 129.082 69H96.082V93C101.267 92.5323 106.153 91.0573 110.571 88.7928V83.5103Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.6007 52.125C60.3748 52.125 59.3828 53.117 59.3828 54.343V75.7969C66.0063 85.2213 76.5514 91.6716 88.6592 92.7645V54.343C88.6592 53.117 87.6652 52.125 86.4413 52.125H61.6007Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.2009 53.0481C88.2009 51.8705 87.2179 50.9141 86.0033 50.9141H61.39C60.1753 50.9141 59.1924 51.8705 59.1924 53.0481V75.6362C60.5229 77.4734 61.9913 79.2117 63.6216 80.7948L88.2009 56.9261V53.0481Z"
      fill="white"
    />
    <path d="M60.082 67H88.082V69H60.082V67Z" fill="#9BABC2" />
    <path d="M60.082 59H88.082V61H60.082V59Z" fill="#9BABC2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.082 85C74.8458 88.863 81.6756 91.3631 89.082 92V85H69.082Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.2113 85H89.082V77H61.082C63.4353 80.0457 66.1821 82.7256 69.2113 85Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.082 77V53.8939C89.082 52.8488 88.0655 52 86.8093 52H61.3548C60.0986 52 59.082 52.8488 59.082 53.8939V74.8547C59.6998 75.5864 60.3279 76.313 61.0077 77H89.082Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.082 57C65.082 56.4477 65.5297 56 66.082 56C66.6343 56 67.082 56.4477 67.082 57V59H65.082V57Z"
      fill="#9BABC2"
    />
    <path
      d="M73.082 57C73.082 56.4477 73.5297 56 74.082 56C74.6343 56 75.082 56.4477 75.082 57V59H73.082V57Z"
      fill="#9BABC2"
    />
    <path
      d="M81.082 57C81.082 56.4477 81.5297 56 82.082 56C82.6343 56 83.082 56.4477 83.082 57V59H81.082V57Z"
      fill="#9BABC2"
    />
    <path
      d="M65.082 65C65.082 64.4477 65.5297 64 66.082 64C66.6343 64 67.082 64.4477 67.082 65V67H65.082V65Z"
      fill="#9BABC2"
    />
    <path
      d="M73.082 65C73.082 64.4477 73.5297 64 74.082 64C74.6343 64 75.082 64.4477 75.082 65V67H73.082V65Z"
      fill="#9BABC2"
    />
    <path
      d="M81.082 65C81.082 64.4477 81.5297 64 82.082 64C82.6343 64 83.082 64.4477 83.082 65V67H81.082V65Z"
      fill="#9BABC2"
    />
    <path
      d="M65.082 74C65.082 73.4477 65.5297 73 66.082 73C66.6343 73 67.082 73.4477 67.082 74V76H65.082V74Z"
      fill="#9BABC2"
    />
    <path
      d="M73.082 74C73.082 73.4477 73.5297 73 74.082 73C74.6343 73 75.082 73.4477 75.082 74V76H73.082V74Z"
      fill="#9BABC2"
    />
    <path
      d="M81.082 74C81.082 73.4477 81.5297 73 82.082 73C82.6343 73 83.082 73.4477 83.082 74V76H81.082V74Z"
      fill="#9BABC2"
    />
    <path
      d="M73.082 82C73.082 81.4477 73.5297 81 74.082 81C74.6343 81 75.082 81.4477 75.082 82V84H73.082V82Z"
      fill="#9BABC2"
    />
    <path
      d="M81.082 82C81.082 81.4477 81.5297 81 82.082 81C82.6343 81 83.082 81.4477 83.082 82V84H81.082V82Z"
      fill="#9BABC2"
    />
  </svg>
);
