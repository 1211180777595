import React from 'react';
import MBox, { BoxProps } from '@material-ui/core/Box';
import styled from 'styled-components';

export interface IBoxProps {
  /**
   * This aligns a flex container's lines within when there is extra space in
   * the cross-axis, similar to how justify-content aligns individual items
   * within the main-axis.
   * This property has no effect when there is only one line of flex items.
   */
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'between'
    | 'around'
    | 'evenly';
  /**
   * This defines the default behavior for how flex items are laid out along the
   * cross axis on the current line. Think of it as the justify-content version
   * for the cross-axis (perpendicular to the main-axis).
   */
  alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
  /**
   * the content to be displayed
   */
  children: React.ReactNode | React.ReactNode[] | undefined;
  /**
   * This defines the alignment along the main axis. It helps distribute extra
   * free space leftover when either all the flex items on a line are
   * inflexible, or are flexible but have reached their maximum size. It also
   * exerts some control over the alignment of items when they overflow the line.
   */
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  /**
   * This defines the alignment along the main axis. It helps distribute extra
   * free space leftover when either all the flex items on a line are
   * inflexible, or are flexible but have reached their maximum size. It also
   * exerts some control over the alignment of items when they overflow the line.
   */
  pad?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  /**
   * By default, flex items will all try to fit onto one line. You can change
   * that and allow the items to wrap as needed with this property.
   */
  wrap?: 'nowrap' | 'wrap' | 'reverse';
  /**
   * Gap size
   */
  gap?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';
  full?: boolean;

  dataTestId?: string;

  margin?: string;
}

type BoxDirection = 'row' | 'column';

const gapSizes = {
  xxsmall: 4,
  xsmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 40,
  xxlarge: 62,
};

const ruleNameLookup = {
  around: 'space-around',
  baseline: 'baseline',
  between: 'space-between',
  center: 'center',
  end: 'flex-end',
  evenly: 'space-evenly',
  nowrap: 'nowrap',
  reverse: 'wrap-reverse',
  start: 'flex-start',
  stretch: 'stretch',
  wrap: 'wrap',
};

const padSizes = {
  xsmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 48,
};

export function Column({
  alignContent,
  alignItems,
  children,
  justify,
  full,
  pad,
  wrap,
  gap,
  ...props
}: IBoxProps & Omit<JSX.IntrinsicElements['div'], 'ref'>) {
  return (
    <FlexBox
      alignContent={alignContent}
      alignItems={alignItems}
      direction="column"
      justify={justify}
      padding={pad}
      wrap={wrap}
      gap={gap}
      full={full}
      {...props}
    >
      {children}
    </FlexBox>
  );
}

// Omit 'ref' prop below due to incompatibility between current React version
// and Styled Components 2.x. This should be resolved with upgrade to SC v5.
// TODO: remove below Omit<> once SC upgrade is complete.
export function Row({
  alignContent,
  alignItems,
  children,
  justify,
  pad,
  wrap,
  gap,
  dataTestId,
  ...props
}: IBoxProps & Omit<JSX.IntrinsicElements['div'], 'ref'>) {
  return (
    <FlexBox
      alignContent={alignContent}
      alignItems={alignItems}
      direction="row"
      justify={justify}
      padding={pad}
      wrap={wrap}
      gap={gap}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </FlexBox>
  );
}

export const FlexBox = styled.div<
  IBoxProps & { direction: BoxDirection; padding: string | undefined }
>`
  display: flex;
  &.animate-out {
    transition: transform 5s ease;
    transform: translateX(100vw);
  }
  ${({ padding }) => (padding ? `padding: ${padSizes[padding]}px` : null)};
  gap: ${(props) => (props.gap ? `${gapSizes[props.gap]}px` : null)};
  flex-basis: ${(props) => (props.full ? `100%` : null)};
  ${({ alignContent }) =>
    alignContent ? `align-content: ${ruleNameLookup[alignContent]}` : ''};
  ${({ alignItems }) =>
    alignItems ? `align-items: ${ruleNameLookup[alignItems]}` : ''};
  ${({ justify }) =>
    justify ? `justify-content: ${ruleNameLookup[justify]}` : ''};
  ${({ direction }) => (direction ? `flex-direction: ${direction}` : '')};
  ${({ wrap }) => (wrap ? `flex-wrap: ${ruleNameLookup[wrap]}` : '')};
  ${({ margin }) => (margin ? `margin: ${margin}` : null)};
`;

export const Box = styled(MBox)<BoxProps & { pad?: IBoxProps['pad'] }>`
  ${({ pad }) => (pad ? `padding: ${padSizes[pad]}px` : null)};
`;
