/**
 * @param search location search string of the form `?abc=def&ghi=jkl`
 * @param keys query parameter keys/names to be extracted
 */
export function extractQueryParams<K extends string>(
  search: string,
  keys: K[]
) {
  if (search === '')
    return {
      restSearchParams: '',
      values: {} as Record<K, string | undefined>,
    };
  const urlSearchParams = new URLSearchParams(search); // polyfilled for IE11

  const values = keys.reduce((all, key) => {
    const value = urlSearchParams.get(key);
    if (!value) return all;
    urlSearchParams.delete(key);
    return {
      ...all,
      [key]: value,
    };
  }, {} as Record<K, string | undefined>);
  // make sure to still pass on any remaining query params
  const otherParams = urlSearchParams.toString();
  const restSearchParams = otherParams ? `?${otherParams}` : '';

  return { values, restSearchParams };
}

export const urlPrefixify = (prefix: boolean | string | undefined) =>
  prefix && typeof prefix === 'string' ? `${prefix}.` : '';

export function createQueryString(
  queryParams?: Record<string, string | null | undefined>,
  config?: {
    allowEmptyValues?: boolean;
  }
) {
  const urlSearchParams = new URLSearchParams();

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (config?.allowEmptyValues) {
        urlSearchParams.append(key, value ?? '');
      } else if (value) {
        urlSearchParams.append(key, value);
      }
    });
  }

  const urlSearch = urlSearchParams.toString();

  return urlSearch ? `?${urlSearch}` : urlSearch;
}

export const constructAbsoluteUrl = (path: string) => {
  const { location } = window;
  let url = `${location.protocol}//${location.hostname}`;

  if (location.port) {
    url = `${url}:${location.port}`;
  }

  return `${url}${path}`;
};

export const openInNewTab = (url: string) =>
  window.open(url, '_blank', 'noopener,noreferrer');
