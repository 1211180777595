import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { lightBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M0.420149 2.72659L6.87162 9.0005L0.420149 15.2744L2.4063 17.2017L10.858 9.0005L2.4063 0.799316L0.420149 2.72659Z"
      fill={props.fill || lightBlue}
    />
  </svg>
);
