/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TeamOrderAssignmentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   *  (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public createTeamOrderAssignment({
    teamId,
    fileId,
  }: {
    teamId: string | null,
    fileId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/teams/{teamId}/orders/{fileId}',
      path: {
        'teamId': teamId,
        'fileId': fileId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   *  (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteTeamOrderAssignment({
    teamId,
    fileId,
  }: {
    teamId: string | null,
    fileId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/teams/{teamId}/orders/{fileId}',
      path: {
        'teamId': teamId,
        'fileId': fileId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}
