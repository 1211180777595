import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M17.5669 18.75H14.7169V12.75H17.5669V18.75ZM17.5669 24.75H14.7169V21.75H17.5669V24.75ZM0.466888 29.25H31.8169L16.1419 0.75L0.466888 29.25Z"
      fill={props.fill || white}
    />
  </svg>
);
