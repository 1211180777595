import React from 'react';
import styled from 'styled-components';
import ContentContainer from 'components/ContentContainer';
import { IApplicationState } from 'store';
import { connect } from 'react-redux';
import { LogoTitle } from 'components/svg';

import {
  lightBlue,
  footerHeight,
  pageContentBottomPadding,
  footerTopPadding,
  footerBottomPadding,
  athensGray,
  trout,
  catskillWhite,
} from 'config/theme';
import { getCurrentFullYear } from 'utils/date';
import ScrollButton from './ScrollButton';

const WIDTH_FOR_COLUMN_LAYOUT = 768;

interface IFooterProps {
  googleobj: any;
  footerOverride: React.CSSProperties | undefined;
  showSidebarWindow: boolean;
}

class Footer extends React.Component<IFooterProps> {
  public render() {
    let margin: number = pageContentBottomPadding;
    const currentYear = getCurrentFullYear();

    if (this.props.googleobj) {
      margin = 0;
    }
    return (
      <StickyFooter style={this.props.footerOverride}>
        <FooterContainer
          id="sticky-footer"
          bottomPadding={this.props.googleobj ? 10 : footerBottomPadding}
          margin={margin}
        >
          <Container showSidebarWindow={this.props.showSidebarWindow}>
            <Logo>
              <LogoTitle />
            </Logo>
            <Copyright>
              <span>
                &copy;{currentYear} First American Financial Corporation
              </span>
              {` `}
              <span>and/or its affiliates.</span>
              {` `}
              <span>All rights&nbsp;reserved.</span>
            </Copyright>
            <LegalInfo>
              <span>
                <a
                  href="http://www.firstam.com/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
              </span>
              <span>
                <a
                  href="http://www.firstam.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
              <span>
                <ScrollButton />
              </span>
            </LegalInfo>
          </Container>
        </FooterContainer>
      </StickyFooter>
    );
  }
}

function mapStateToProps(state: IApplicationState) {
  return {
    googleobj: state.googleMap.googleObj,
    showSidebarWindow: !!state.ui.showSidebarWindow,
    footerOverride: state.ui.footerStyleOverrides || {},
  };
}

export default connect(mapStateToProps)(Footer);

interface IFooterContainerProps {
  bottomPadding: number;
  margin: number;
}

const FooterContainer = styled(ContentContainer)<IFooterContainerProps>`
  height: ${footerHeight}px;
  bottom: 0;
  left: 0;
  padding-bottom: ${({ bottomPadding }) => bottomPadding}px;
  margin: ${({ margin }) => margin}px auto 0 auto;
`;

const Container = styled.div<{ showSidebarWindow: boolean }>`
  display: flex;
  align-items: center;
  border-top: 1px solid ${athensGray};
  justify-content: space-between;
  padding-top: ${footerTopPadding}px;

  ${({ showSidebarWindow }) =>
    showSidebarWindow &&
    `
    max-width: calc(100% - 370px);
  `}
`;

const Logo = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

const LegalInfo = styled.div`
  flex: 1;
  position: relative;

  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;

  & > span {
    display: flex;
    padding: 6px 0 0 20px;
  }

  & a {
    text-decoration: none;
    color: ${lightBlue};
  }
`;

const Copyright = styled.small`
  margin-top: 12px;
  color: ${trout};

  span {
    white-space: normal;
  }

  @media only screen and (min-width: ${WIDTH_FOR_COLUMN_LAYOUT}px) {
    margin-top: 0;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 1075px) {
    span {
      display: inline-block;

      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
`;

export const FOOTER_MARGIN_TOP = 10;
const StickyFooter = styled.div`
  margin-top: ${FOOTER_MARGIN_TOP}px;
  background: ${catskillWhite};
  flex-shrink: auto;
`;
