import React from 'react';

interface IApiRedirectProps {
  to: string;
}

export default class ApiRedirect extends React.Component<IApiRedirectProps> {
  public componentDidMount() {
    window.location.href = this.props.to;
  }

  public render() {
    return null;
  }
}
