import { IWireInstructionsState, WireInstructionsTypeKeys } from './types';

export const initialState: IWireInstructionsState = {
  loading: false,
  error: null,
  toaster: null,
  file: null,
  fileParties: [],
  fileContacts: {},
  reasonForUpdateWireInstruction: null,
  selectedInactiveMatch: false,
  wireInstructionsRequest: null,
  wireInstructionsResults: [],
  wireInstructionDetails: null,
  wireInstructionVerification: null,
  wireInstructionVerificationCompleted: false,
  wireInstructionUpdateId: null,
  wireAccountActivity: null,
  bankABADetails: null,
  swiftCodeDetails: null,
  wireInstructionDocumentsMultiUpload: {
    documentIndex: 0,
    stagedDocuments: [],
    stagedExistingDocuments: [],
    stagedExternalDocuments: [],
    inProgressUploads: [],
  },

  wireDisbursements: {
    submitting: false,
    fetching: true,
    data: null,
  },

  uploadDocument: {
    loading: false,
    error: null,
    success: null,
  },
  additionalWireInstructionsResults: [],
  accountPayeeNames: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WireInstructionsTypeKeys.REQUEST:
      return {
        ...state,
        loading: true,
        toaster: null,
        error: null,
      };

    case WireInstructionsTypeKeys.SUCCESS: {
      const toaster = action.toaster || null;

      return {
        ...state,
        loading: false,
        error: null,
        toaster,
      };
    }
    case WireInstructionsTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        toaster: null,
        error: action.error,
      };
    case WireInstructionsTypeKeys.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case WireInstructionsTypeKeys.CLEARTOASTER:
      return {
        ...state,
        toaster: null,
      };
    case WireInstructionsTypeKeys.SET_FILE:
      return {
        ...state,
        file: action.file,
        fileParties: [],
        fileContacts: {},
      };
    case WireInstructionsTypeKeys.RECEIVE_FILE_PARTIES:
      return {
        ...state,
        fileParties: action.parties,
      };
    case WireInstructionsTypeKeys.SELECTED_INACTIVE_MATCH:
      return {
        ...state,
        selectedInactiveMatch: true,
      };
    case WireInstructionsTypeKeys.SET_REASON_UPDATE:
      return {
        ...state,
        reasonForUpdateWireInstruction: action.reasonForUpdateWireInstruction,
      };
    case WireInstructionsTypeKeys.DELETE_INACTIVE_MATCH:
      return {
        ...state,
        selectedInactiveMatch: false,
      };
    case WireInstructionsTypeKeys.RECEIVE_FILE_CONTACTS:
      return {
        ...state,
        fileContacts: {
          ...state.fileContacts,
          [action.organizationId]: action.contacts,
        },
      };
    case WireInstructionsTypeKeys.RECEIVE_WIRE_INSTRUCTIONS_REQUEST:
      return {
        ...state,
        wireInstructionsRequest: action.wireInstructionsRequest,
      };
    case WireInstructionsTypeKeys.RECEIVE_WIRE_INSTRUCTION_DETAILS:
      return {
        ...state,
        wireInstructionDetails: action.wireInstructionDetails,
      };
    case WireInstructionsTypeKeys.RECEIVE_WIRE_INSTRUCTION_RESULT:
      return {
        ...state,
        wireInstructionResults: [
          action.wireInstruction,
          ...state.wireInstructionsResults,
        ],
      };
    case WireInstructionsTypeKeys.RECEIVE_WIRE_INSTRUCTION_VERIFICATION:
      return {
        ...state,
        loading: false,
        wireInstructionVerification: action.wireInstructionVerification,
      };
    case WireInstructionsTypeKeys.VERIFY_WIRE_INSTRUCTION:
      return {
        ...state,
        loading: false,
        wireInstructionVerificationCompleted: true,
      };
    case WireInstructionsTypeKeys.RESET_VERIFY_WIRE_INSTRUCTION:
      return {
        ...state,
        loading: false,
        wireInstructionVerificationCompleted: false,
      };
    case WireInstructionsTypeKeys.SET_WIRE_INSTRUCTIONS:
      return {
        ...state,
        wireInstructionsResults: action.wireInstructions,
      };
    case WireInstructionsTypeKeys.GET_DISBURSEMENTS:
      return {
        ...state,
        wireDisbursements: {
          ...state.wireDisbursements,
          fetching: true,
        },
      };
    case WireInstructionsTypeKeys.GET_DISBURSEMENTS_SUCCESS:
      return {
        ...state,
        wireDisbursements: {
          ...state.wireDisbursements,
          fetching: false,
          data: action.payload.data,
        },
      };
    case WireInstructionsTypeKeys.GET_DISBURSEMENTS_ERROR:
      return {
        ...state,
        wireDisbursements: {
          ...state.wireDisbursements,
          fetching: false,
          data: null,
        },
      };

    case WireInstructionsTypeKeys.SEND_DISBURSEMENT_TO_FAST:
      return {
        ...state,
        wireDisbursements: {
          ...state.wireDisbursements,
          submitting: true,
        },
      };

    case WireInstructionsTypeKeys.SEND_DISBURSEMENT_TO_FAST_SUCCESS: {
      return {
        ...state,
        wireDisbursements: {
          ...state.wireDisbursements,
          fetching: false,
          submitting: false,
        },
      };
    }

    case WireInstructionsTypeKeys.SEND_DISBURSEMENT_TO_FAST_ERROR:
      return {
        ...state,
        loading: false,
        wireDisbursements: {
          ...state.wireDisbursements,
          fetching: false,
          submitting: false,
        },
      };
    case WireInstructionsTypeKeys.RESET_DISBURSEMENTS:
      return {
        ...state,
        toaster: null,
        loading: false,
        wireDisbursements: {
          ...state.wireDisbursements,
          fetching: false,
          data: null,
        },
      };
    case WireInstructionsTypeKeys.UPDATE_WIRE_INSTRUCTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WireInstructionsTypeKeys.UPDATE_WIRE_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wireInstructionUpdateId: action.wireInstructionId,
      };
    case WireInstructionsTypeKeys.UPDATE_WIRE_INSTRUCTIONS_RESET:
      return {
        ...state,
        wireInstructionUpdateId: null,
      };
    case WireInstructionsTypeKeys.RESET_DOCUMENT_INDEX:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          documentIndex: 0,
        },
      };
    case WireInstructionsTypeKeys.RESET_UPLOAD_COMPLETE:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          inProgressUploads: [],
          stagedExistingDocuments: [],
        },
      };
    case WireInstructionsTypeKeys.RESET_STAGE_DOCUMENT_UPLOADS:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          stagedDocuments: [],
        },
      };
    case WireInstructionsTypeKeys.SET_WIRE_ACCOUNT_ACTIVITY:
      return {
        ...state,
        loading: false,
        wireAccountActivity: action.data,
      };
    case WireInstructionsTypeKeys.INCREMENT_DOCUMENT_INDEX:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          documentIndex: ++action.documentIndex,
        },
      };
    case WireInstructionsTypeKeys.UPDATE_DOC_UPLOAD_PROGRESS:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          inProgressUploads: [
            ...state.wireInstructionDocumentsMultiUpload.inProgressUploads,
            {
              progress: 1,
              externalDocumentId: 0,
              documentIndex: action.documentIndex,
              file: action.file,
            },
          ],
        },
      };
    case WireInstructionsTypeKeys.UPDATE_DOC_UPLOAD_COMPLETE:
      const { inProgressUploads } = state.wireInstructionDocumentsMultiUpload;
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          inProgressUploads: inProgressUploads.map((inProgressUpload) =>
            inProgressUpload.documentIndex === action.documentIndex
              ? {
                  ...inProgressUpload,
                  progress: 100,
                  externalDocumentId: action.externalDocumentId,
                }
              : inProgressUpload
          ),
        },
      };
    case WireInstructionsTypeKeys.STAGE_DOCUMENT_UPLOADS:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          stagedDocuments: action.stagedDocuments,
        },
      };
    case WireInstructionsTypeKeys.STAGE_EXISTING_DOCUMENT_UPLOADS:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          stagedExistingDocuments: action.stagedExistingDocuments,
        },
      };
    case WireInstructionsTypeKeys.STAGE_EXTERNAL_DOCUMENT_UPLOADS:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          stagedExternalDocuments: action.stagedExternalDocuments,
        },
      };
    case WireInstructionsTypeKeys.REMOVED_DOCUMENTS:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          removedDocuments: action.removedDocuments,
        },
      };
    case WireInstructionsTypeKeys.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: true,
        },
      };
    case WireInstructionsTypeKeys.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: false,
          error: null,
          id: action.id,
          success: action.success,
        },
      };
    case WireInstructionsTypeKeys.UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: false,
          error: action.error,
        },
      };
    case WireInstructionsTypeKeys.CLEAR_UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: false,
          error: null,
        },
      };
    case WireInstructionsTypeKeys.UPDATE_WIREINSTRUCTION_DOCUMENTS_RESET:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          documentIndex: 0,
          stagedDocuments: [],
          inProgressUploads: [],
          stagedExistingDocuments: [],
          stagedExternalDocuments: [],
        },
      };
    case WireInstructionsTypeKeys.GET_ABA_DETAILS:
      return {
        ...state,
        bankABADetails: action.data,
      };
    case WireInstructionsTypeKeys.RESET_ABA_DETAILS:
      return {
        ...state,
        bankABADetails: null,
      };
    case WireInstructionsTypeKeys.GET_SWIFT_DETAILS:
      return {
        ...state,
        swiftCodeDetails: action.data,
      };
    case WireInstructionsTypeKeys.RESET_SWIFT_DETAILS:
      return {
        ...state,
        swiftCodeDetails: null,
      };
    case WireInstructionsTypeKeys.RECEIVE_ADDTIONAL_WIRE_INSTRUCTION_RESULT:
      return {
        ...state,
        additionalWireInstructionsResults: action.wireInstructions,
      };
    case WireInstructionsTypeKeys.RESET_ADDTIONAL_WIRE_INSTRUCTION_RESULT:
      return {
        ...state,
        additionalWireInstructionsResults: [],
      };
    case WireInstructionsTypeKeys.RECEIVE_LATEST_ACCOUNT_PAYEE_NAMES:
      return {
        ...state,
        accountPayeeNames: action.accountPayeeNames,
      };

    case WireInstructionsTypeKeys.DELETE_STAGE_DOCUMENT_UPLOADED:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          stagedDocuments: state.wireInstructionDocumentsMultiUpload.stagedDocuments.filter(
            (_item, itemIndex) => {
              return itemIndex !== action.documentIndex;
            }
          ),
          inProgressUploads: state.wireInstructionDocumentsMultiUpload.inProgressUploads.filter(
            (_item, itemIndex) => {
              return itemIndex !== action.documentIndex;
            }
          ),
          documentIndex: --state.wireInstructionDocumentsMultiUpload
            .documentIndex,
        },
      };
    case WireInstructionsTypeKeys.DELETE_STAGE_EXTERNAL_DOCUMENT_UPLOADED:
      return {
        ...state,
        wireInstructionDocumentsMultiUpload: {
          ...state.wireInstructionDocumentsMultiUpload,
          stagedExternalDocuments: state.wireInstructionDocumentsMultiUpload.stagedExternalDocuments.filter(
            (item) => {
              return item.externalDocumentId !== action.documentId;
            }
          ),
        },
      };
    default:
      return state;
  }
};
