/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Requests_CreateWireInstructionRequest } from '../models/Workspace_Common_Requests_CreateWireInstructionRequest';
import type { Workspace_Common_Requests_UpdateWireInstructionRequest } from '../models/Workspace_Common_Requests_UpdateWireInstructionRequest';
import type { Workspace_Common_Requests_WireInvitation } from '../models/Workspace_Common_Requests_WireInvitation';
import type { Workspace_Common_Responses_WireInstructionRequest } from '../models/Workspace_Common_Responses_WireInstructionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WireInstructionRequestsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * This endpoint gets a wire instruction request by Code. (Auth)
   * @returns Workspace_Common_Responses_WireInstructionRequest Success
   * @throws ApiError
   */
  public getWireInstructionRequest({
    code,
  }: {
    /**
     * the Code of the request.
     */
    code: string,
  }): CancelablePromise<Workspace_Common_Responses_WireInstructionRequest> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/wire-instruction-requests/{code}',
      path: {
        'code': code,
      },
      errors: {
        401: `Missing or invalid Authorization header.`,
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Wire Instruction Request does not exist.`,
        410: `Wire Instruction action is already completed`,
      },
    });
  }

  /**
   * This endpoint checks a wire instruction request by Code.
   * @returns any Wire Instruction Request exits and can be fetched.
   * @throws ApiError
   */
  public checkWireInstructionRequest({
    code,
  }: {
    /**
     * the Code of the request.
     */
    code: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'HEAD',
      url: '/ncs/v1/wire-instruction-requests/{code}',
      path: {
        'code': code,
      },
      errors: {
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Wire Instruction Request does not exist.`,
        409: `2FA required.`,
        410: `Wire Instruction action is already completed`,
      },
    });
  }

  /**
   * This endpoint creates a new Wire Instruction request (Auth)
   * @returns Workspace_Common_Responses_WireInstructionRequest Wire Instruction request has been created.
   * @throws ApiError
   */
  public postWireInstructionRequest({
    requestBody,
  }: {
    /**
     * The Wire Instruction request information.
     */
    requestBody: Workspace_Common_Requests_CreateWireInstructionRequest,
  }): CancelablePromise<Workspace_Common_Responses_WireInstructionRequest> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/wire-instruction-requests',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Missing or invalid authorization header`,
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Invalid UserType.`,
        422: `Validation Failed.`,
      },
    });
  }

  /**
   * This endpoint updates Wire Instruction request with contact info (Auth)
   * @returns any Wire Instruction request has been created.
   * @throws ApiError
   */
  public updateWireInstructionRequest({
    id,
    requestBody,
  }: {
    /**
     * The Id of the wire instruction.
     */
    id: number,
    /**
     * The Wire Instruction request information.
     */
    requestBody: Workspace_Common_Requests_UpdateWireInstructionRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/wire-instruction-requests/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Missing or invalid authorization header`,
        403: `User doesn't have access to the Wire Instruction.`,
        404: `Invalid UserType.`,
        422: `Validation Failed.`,
      },
    });
  }

  /**
   * Send or Resend  wire request email for the given wire instruction (Auth)
   * @returns any Wire request email was sent
   * @throws ApiError
   */
  public sendInvitation({
    requestBody,
  }: {
    requestBody: Workspace_Common_Requests_WireInvitation,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/wire-instruction-requests/_send-invitation',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `User is not permitted to access this wire instruction`,
        404: `Wire instruction not found`,
        409: `Wire instruction not in the correct status`,
      },
    });
  }

}
