import React from 'react';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import { IApplicationState } from 'store';
import { headerZIndex, white, shuttleGrey } from 'config/theme';
import { Row } from 'components/ui/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import StyledReactTooltip from 'components/ToolTip/StyledReactTooltip';
import { SMALL_BODY_BOLD, SMALL_BODY_MEDIUM } from 'config/typography';
import {
  primaryBlueGhost1,
  primaryBlue2,
  green8,
  red8,
  orange7,
} from 'config/colors';
import useInterval from 'hooks/useInterval';
import useAPIMutation from 'hooks/api/useAPIMutation';
import { JobStatus } from '../constants';
import { pluralize } from 'utils/text';
import Close from 'icons/Close';
import CheckCircle from 'icons/CheckCircle';
import AlertCircle from 'icons/AlertCircle';
import AlertDecagram from 'icons/AlertDecagram';
import {
  setBulkUpdatesStatusUpdates,
  getOrderDetails,
  getSalesPrice,
} from 'store/orders/actions';
import { addToast } from 'store/ui/actions';
import { IBulkUpdateStatus } from '../types';
import { SiteType } from 'components/SiteCard';

const HEADER_HEIGHT = 56;
const ROW_HEIGHT = 52;
const MAX_VISIBLE_ROWS = 3;
const DOM_ID_PREFIX = 'bulk-updates__popover-progress';
const CHEVRON_ICON_TOOLTIP_ID = 'chevron-icon-tooltip-id';

const BulkUpdatesStatusProgress = ({
  pollingList,
  projectFileId,
  currentOrderId,
  addToast,
  setBulkUpdatesStatusUpdates,
  getOrderDetails,
  getSalesPrice,
  fetchOrderDetailsOnFinish,
  isLoanAmountAllocation,
}: ConnectedProps<typeof connector>) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [pollingDelay, setPollingDelay] = React.useState<number | null>(null);
  const inProgress = !!pollingList.find(
    ({ state }) => state === JobStatus.PROCESSING
  );

  const { execute: fetchStatuses } = useAPIMutation({
    autoFire: false,
    method: 'PUT',
    onSuccess: (data: Array<Omit<IBulkUpdateStatus, 'orderNumber'>>) => {
      setBulkUpdatesStatusUpdates(
        pollingList.map((item) => {
          const newState: JobStatus =
            data.find(({ orderId }) => orderId === item.orderId)?.state ||
            JobStatus.PROCESSING;

          return {
            ...item,
            state: newState,
          };
        }),
        isLoanAmountAllocation,
        fetchOrderDetailsOnFinish
      );
    },
    onError: () => {
      addToast(
        'An error has occurred while fetching your Bulk Updates statuses.',
        'error'
      );

      closePopover();
    },
    url: `/orders/${projectFileId}/bulk-updates/site-orders/get-bulk-updates`,
  });

  React.useEffect(() => {
    setPollingDelay(inProgress ? 10 * 1000 : null); //10 seconds
  }, [inProgress]);

  useInterval(
    React.useCallback(async () => {
      fetchStatuses({
        requestData: pollingList.map((item) => {
          return {
            jobId: item.jobId,
            orderId: item.orderId,
          };
        }),
      });
    }, [pollingList]),
    pollingDelay
  );

  const getHeaderTitleAndIcon = () => {
    const failedUpdates = pollingList.filter(
      ({ state }) => state === JobStatus.FAILED
    ).length;

    if (isLoanAmountAllocation) {
      if (inProgress) {
        return { icon: null, title: `Updating sites...` };
      } else {
        if (failedUpdates === 0) {
          // No fail
          return {
            icon: <CheckCircle fill={green8} />,
            title: `Updates were successfully made to sites`,
          };
        } else if (failedUpdates === pollingList.length) {
          // All failed
          return {
            icon: <AlertCircle fill={red8} />,
            title: `Updates failed`,
          };
        }
      }
    }
    const pluralizedSites = `${pollingList.length} ${pluralize(
      pollingList.length,
      'site'
    )}`;

    if (inProgress) {
      return { icon: null, title: `Updating ${pluralizedSites}...` };
    } else {
      const pluralizedUpdate = pluralize(pollingList.length, 'Update');

      if (failedUpdates === 0) {
        // No fail
        return {
          icon: <CheckCircle fill={green8} />,
          title: `${pluralizedUpdate} were successfully made to ${pluralizedSites}`,
        };
      } else if (failedUpdates === pollingList.length) {
        // All failed
        return {
          icon: <AlertCircle fill={red8} />,
          title: `${pluralizedUpdate} failed for ${pluralizedSites}`,
        };
      } else {
        // Partially failed
        // Update(s) were successfully made to X site(s), and failed for Y site(s).
        const successfulUpdates = pollingList.filter(
          ({ state }) => state === JobStatus.SUCCESS
        ).length;

        return {
          icon: <AlertDecagram fill={orange7} />,
          title: `${pluralize(
            successfulUpdates,
            'Update'
          )} were successfully made to ${successfulUpdates} ${pluralize(
            successfulUpdates,
            'site'
          )}, and failed for ${failedUpdates} ${pluralize(
            failedUpdates,
            'site'
          )}.`,
        };
      }
    }
  };

  React.useEffect(() => {
    setIsExpanded(
      inProgress
        ? false
        : !!pollingList.find(({ state }) => state === JobStatus.FAILED)
    );

    if (
      !!window.location.pathname.match(/(^\/order.\d+.\/overview)(?:(?!\/))/) && //check if user is in the order details page
      !inProgress &&
      !!pollingList.length &&
      fetchOrderDetailsOnFinish
    ) {
      getOrderDetails(`${currentOrderId}`, true);
      getSalesPrice(`${currentOrderId}`);
    }
  }, [pollingList, inProgress, window.location]);

  React.useEffect(() => {
    if (!isExpanded && !inProgress) {
      const timeout = setTimeout(closePopover, 7000);
      return () => clearTimeout(timeout);
    }
  }, [inProgress, isExpanded]);

  const closePopover = () => {
    setBulkUpdatesStatusUpdates([]);
  };

  if (pollingList.length <= 0) return null;

  return (
    <FixedContainer>
      <ProgressHeader
        inProgress={inProgress}
        isExpanded={isExpanded}
        onClose={() => closePopover()}
        {...getHeaderTitleAndIcon()}
      />
      {inProgress && (
        <LinearProgress
          {...{ 'data-testid': 'bulk-updates__linear-progress' }}
        />
      )}
      {isExpanded && (
        <StyledProgressRow inProgress={inProgress}>
          {(pollingList || [])
            .filter(({ state }) => state === JobStatus.FAILED)
            .map(({ orderId, orderNumber }) => (
              <ProgressRow
                orderNumber={orderNumber}
                inProgress={inProgress}
                key={`popover-row__site-order-${orderId}`}
                {...{ 'data-testid': 'bulk-updates__order-row' }}
              />
            ))}
        </StyledProgressRow>
      )}
    </FixedContainer>
  );
};

const ProgressHeader = ({ inProgress, isExpanded, onClose, title, icon }) => {
  return (
    <StyledProgressHeader inProgress={inProgress}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header>
          {icon} {title}
        </Header>
      </div>
      {!inProgress && (
        <ButtonContainer
          onClick={onClose}
          id={`${DOM_ID_PREFIX}__button__close`}
          {...{ 'data-testid': 'bulk-updates__close-button' }}
        >
          <StyledCloseIcon />
        </ButtonContainer>
      )}
      <StyledReactTooltip
        id={CHEVRON_ICON_TOOLTIP_ID}
        key={isExpanded ? 'expanded' : 'collapsed'}
        getContent={() => (isExpanded ? 'Minimize window' : 'Maximize window')}
      />
    </StyledProgressHeader>
  );
};

const ProgressRow = ({ orderNumber, inProgress }) => {
  return (
    <StyledRow inProgress={inProgress} justify="between" alignItems="center">
      <Header>{orderNumber}</Header>
    </StyledRow>
  );
};

const connector = connect(
  (state: IApplicationState) => {
    return {
      pollingList: state.orders.ui.bulkUpdatesStatusProgress.siteOrders,
      fetchOrderDetailsOnFinish:
        state.orders.ui.bulkUpdatesStatusProgress.fetchOrderDetailsOnFinish,
      projectFileId:
        state.orders?.order?.fileType === SiteType.MultisiteProject
          ? Number(state.orders?.order?.fileID)
          : Number(state.orders?.order?.projectFileId),
      currentOrderId: state.orders?.order?.fileID,
      isLoanAmountAllocation:
        state.orders.ui.bulkUpdatesStatusProgress.isLoanAmountAllocation,
    };
  },
  { setBulkUpdatesStatusUpdates, addToast, getOrderDetails, getSalesPrice }
);

export default connector(BulkUpdatesStatusProgress);

const FixedContainer = styled.div`
  width: 400px;
  max-height: ${ROW_HEIGHT * MAX_VISIBLE_ROWS + HEADER_HEIGHT}px;
  z-index: ${headerZIndex};
  background-color: ${white};
  margin-left: 16px;
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
`;

const StyledProgressHeader = styled(Row).attrs({ justify: 'between' })<{
  inProgress: boolean;
}>`
  height: ${({ inProgress }) =>
    inProgress ? HEADER_HEIGHT - 4 : HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  padding: 10px 8px 10px 14px;
  align-items: center;
  ${({ inProgress }) =>
    inProgress ? '' : `border-bottom: 2px solid ${primaryBlue2};`}
`;

const ButtonContainer = styled.div`
  min-width: 40px;
  min-height: 40px;

  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }

  &:active {
    & svg path {
      stroke-width: 1;
      stroke: ${shuttleGrey};
    }
  }
`;

const Header = styled.div`
  ${SMALL_BODY_MEDIUM}
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const StyledCloseIcon = styled(Close)`
  width: 26px;
  height: 30px;
  color: ${shuttleGrey};
`;

const StyledProgressRow = styled.div<{
  inProgress: boolean;
}>`
  max-height: ${ROW_HEIGHT * MAX_VISIBLE_ROWS}px;
  background: ${(props) => (props.inProgress ? primaryBlueGhost1 : '')};
  overflow-y: auto;
`;

const StyledRow = styled(Row)<{ inProgress: boolean }>`
  ${SMALL_BODY_BOLD}
  height: ${ROW_HEIGHT - 4}px;
  padding: 16px;
  margin-bottom: 4px;
  border-bottom: 1px solid ${primaryBlue2};
`;
