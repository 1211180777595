import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M21.7509 0.195679L21.555 0.232407L15.0174 2.76664L7.67178 0.195679L0.766908 2.52179C0.509812 2.60749 0.326172 2.82785 0.326172 3.10944V21.6204C0.326172 21.9632 0.595511 22.2325 0.938306 22.2325L1.13419 22.1958L7.67178 19.6615L15.0174 22.2325L21.9222 19.9064C22.1793 19.8207 22.363 19.6003 22.363 19.3187V0.807812C22.363 0.465018 22.0936 0.195679 21.7509 0.195679ZM15.0174 19.784L7.67178 17.2008V2.64421L15.0174 5.22742V19.784Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
