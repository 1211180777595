import React from 'react';
import styled from 'styled-components';
import { Column } from 'components/ui/Box';
import { Attribute } from '../Attribute';
import {
  UserOutlineIcon,
  UsersGroupIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { cadetBlue, trout, lighterBlue, black } from 'config/theme';
import { slugify } from 'utils/text';
import TextTruncate from '../TextTruncate';
import HighLight from '../Highlight';

export interface IOrderContact {
  classSelectorName?: string;
  index?: number;
  location?: string;
  name: string;
  organization?: string;
  role: string;
}

interface IRightContentProps {
  orderPartiesContacts: IOrderContact[];
  serviceTeamContacts: IOrderContact[];
}

const RightContent = ({
  orderPartiesContacts,
  serviceTeamContacts,
}: IRightContentProps) => (
  <Container>
    <Column>
      <ColumnHeader>
        <Attribute
          classSelectorName="site-card-order-parties-header"
          color={trout}
          iconFillColor={cadetBlue}
          iconHeight={15}
          iconSvgComponent={UserOutlineIcon}
          iconWidth={15}
          valueText="Order Parties"
        />
      </ColumnHeader>

      <PartiesList
        classSelectorName="site-card-order-parties"
        parties={orderPartiesContacts}
      />
    </Column>
    <Column>
      <ColumnHeader>
        <Attribute
          classSelectorName="site-card-service-team-header"
          color={trout}
          iconFillColor={cadetBlue}
          iconHeight={16}
          iconSvgComponent={UsersGroupIcon}
          iconWidth={20}
          valueText="Service Team"
        />
      </ColumnHeader>

      <PartiesList
        classSelectorName="site-card-service-team"
        parties={serviceTeamContacts}
      />
    </Column>
  </Container>
);
RightContent.displayName = 'RightContent';

export default RightContent;

const Container = styled.div`
  align-items: start;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  border-left: 1px solid ${lighterBlue};
  padding-left: 46px;
  justify-content: space-between;
  & > div {
    flex: 1 1 304px;
    &:first-child {
      margin-right: 58px;
    }
  }
`;

const OrderContact = ({
  classSelectorName,
  index,
  location,
  name,
  organization,
  role,
}: IOrderContact) => (
  <OrderContactContainer>
    <Name>
      <TextTruncate
        classSelectorName={`${classSelectorName}-name-${index}`}
        text={name}
      />

      <Role>
        <TextTruncate
          classSelectorName={`${classSelectorName}-role-${index}`}
          text={role}
          tooltipId={slugify(`orderContactRole.${name}.${role}`)}
          maxWidth={90}
        />
      </Role>
    </Name>

    {(organization || location) && (
      <Info>
        {organization && (
          <HighLight
            classSelectorName={`${classSelectorName}-organization-${index}`}
            text={organization}
          />
        )}

        {organization && location && ' | '}

        {location && (
          <HighLight
            classSelectorName={`${classSelectorName}-location-${index}`}
            text={location}
          />
        )}
      </Info>
    )}
  </OrderContactContainer>
);

const ColumnHeader = styled.div`
  border-bottom: 1px solid ${lighterBlue};
  color: ${trout};
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 10px;
  & > div {
    font-weight: normal !important;
  }
`;

interface IPartiesListProps {
  classSelectorName?: string;
  parties: IOrderContact[];
}

const PartiesList = ({ classSelectorName, parties }: IPartiesListProps) =>
  parties?.length ? (
    <>
      {parties.map((c: IOrderContact, index) => (
        <OrderContact
          classSelectorName={classSelectorName}
          index={index}
          key={contactKey(c)}
          {...c}
        />
      ))}
    </>
  ) : (
    <span>No parties are available</span>
  );

const contactKey = (c: IOrderContact): string =>
  c.name + c.role + c.organization;

const OrderContactContainer = styled.div`
  align-items: start;
  border-bottom: 1px solid ${lighterBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
  &:last-child {
    border-bottom: 0;
  }
`;

const Name = styled.div`
  align-items: center;
  color: ${black};
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 24px;
  width: 100%;
  & > span {
    align-items: stretch;
    border-radius: 4px;
    display: block;
    flex: 0 0 auto;
    max-width: 200px;
    overflow: hidden;
    em.hlsearch {
      display: inline;
      &::after {
        display: none;
      }
    }
  }
`;

const Info = styled.div`
  color: ${trout};
  font-size: 12px;
  line-height: 24px;
`;

const Role = styled.div`
  border-radius: 2px;
  border: 1px solid ${cadetBlue};
  color: ${trout};
  flex: 0 0 auto;
  font-size: 10px;
  font-weight: bold;
  height: 16px;
  line-height: 16px;
  max-width: 100px;
  padding: 0 4px;
  text-align: center;
  text-transform: uppercase;
  &&& > div {
    text-transform: none;
  }
`;
