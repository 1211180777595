import { IHelpPageState, HelpPageTypeKeys } from './types';

export const initialState: IHelpPageState = {
  loading: false,
  success: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HelpPageTypeKeys.SUBMIT_SUPPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HelpPageTypeKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case HelpPageTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case HelpPageTypeKeys.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
