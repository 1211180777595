import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { goldenPoppy } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3769 19.175L22.3869 20.187C21.5949 20.979 21.0999 21.65 21.0999 23.3H18.8999V22.75C18.8999 21.529 19.3949 20.429 20.1869 19.637L21.5509 18.251C21.9579 17.855 22.1999 17.305 22.1999 16.7C22.1999 15.479 21.2099 14.5 19.9999 14.5C19.4164 14.5 18.8568 14.7318 18.4442 15.1444C18.0317 15.5569 17.7999 16.1165 17.7999 16.7H15.5999C15.5999 15.533 16.0634 14.4139 16.8886 13.5887C17.7138 12.7636 18.8329 12.3 19.9999 12.3C21.1668 12.3 22.286 12.7636 23.1111 13.5887C23.9363 14.4139 24.3999 15.533 24.3999 16.7C24.3999 17.668 24.0039 18.537 23.3769 19.175ZM21.0999 27.7H18.8999V25.5H21.0999V27.7ZM19.9999 9C18.5553 9 17.1249 9.28452 15.7904 9.83733C14.4558 10.3901 13.2431 11.2004 12.2217 12.2218C10.1588 14.2847 8.99988 17.0826 8.99988 20C8.99988 22.9174 10.1588 25.7153 12.2217 27.7782C13.2431 28.7996 14.4558 29.6099 15.7904 30.1627C17.1249 30.7155 18.5553 31 19.9999 31C22.9173 31 25.7152 29.8411 27.7781 27.7782C29.841 25.7153 30.9999 22.9174 30.9999 20C30.9999 13.917 26.0499 9 19.9999 9Z"
      fill={props.fill || goldenPoppy}
    />
  </svg>
);
