import React from 'react';
import { MenuContainer } from './Common';
interface IMenuProps {
  idx: number;
  className?: string;
  // Controls the display of the menu container
  show: boolean;
  // Defines width for the context menu. If not defined "auto" is used
  width?: number;
}

const ContextMenu: React.FC<IMenuProps> = ({
  className,
  show,
  width,
  children,
  idx,
}) => {
  return show ? (
    <MenuContainer className={className} width={width}>
      <div key={idx}>{children}</div>
    </MenuContainer>
  ) : null;
};

// eslint-disable-next-line import/no-unused-modules
export default ContextMenu;
