import {
  SearchTypeKeys,
  SearchAction,
  SearchType,
  ISearchState,
} from './types';

export const initialState: ISearchState = {
  loading: false,
  error: null,
  searchType: SearchType.Files,
  query: '',
  page: 1,
  results: [],
  pagination: null,
  aggregations: null,
  countByFileType: {
    project: '0',
    single: '0',
    site: '0',
    total: '0',
  },
};

export default (state = initialState, action: SearchAction) => {
  switch (action.type) {
    case SearchTypeKeys.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SearchTypeKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        results: action.results,
        pagination: action.pagination,
        aggregations: action.aggregations,
        countByFileType: {
          ...initialState.countByFileType,
          ...action.countByFileType,
        },
      };
    case SearchTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        results: [],
        pagination: null,
      };
    case SearchTypeKeys.CLEAR:
      return { ...initialState };
    case SearchTypeKeys.SET_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      };
    case SearchTypeKeys.SET_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case SearchTypeKeys.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
};
