import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { cadetBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M7.05 14H2.77C3.76 13.5 5.47 12 7 12C7.11 12 7.23 12.01 7.34 12.01C7.68 11.28 8.27 10.68 8.98 10.2C8.25 10.07 7.56 10 7 10C4.66 10 0 12.17 0 14.5V16H7V14.5C7 14.33 7.02 14.16 7.05 14ZM14.5 11.5C12.66 11.5 9 12.51 9 14.5V16H20V14.5C20 12.51 16.34 11.5 14.5 11.5ZM15.71 9.68C16.47 9.25 17 8.44 17 7.5C17 6.12 15.88 5 14.5 5C13.12 5 12 6.12 12 7.5C12 8.44 12.53 9.25 13.29 9.68C13.65 9.88 14.06 10 14.5 10C14.94 10 15.35 9.88 15.71 9.68Z"
      fill={props.fill || cadetBlue}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8ZM7 6C8.10457 6 9 5.10457 9 4C9 2.89543 8.10457 2 7 2C5.89543 2 5 2.89543 5 4C5 5.10457 5.89543 6 7 6Z"
      fill={props.fill || cadetBlue}
    />
  </svg>
);
