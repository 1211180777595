/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Microsoft_AspNetCore_JsonPatch_Operations_Operation } from '../models/Microsoft_AspNetCore_JsonPatch_Operations_Operation';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Requests_CreateSettlementStatementViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Requests_CreateSettlementStatementViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SettlementStatementSyncStatusViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SettlementStatementSyncStatusViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UpdateSectionLineItemViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UpdateSectionLineItemViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UserStatementShareViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UserStatementShareViewModel';
import type { Workspace_API_ViewModels_BusinessParties_FilePartyViewModel } from '../models/Workspace_API_ViewModels_BusinessParties_FilePartyViewModel';
import type { Workspace_API_ViewModels_Settlement_BalanceSummaryViewModel } from '../models/Workspace_API_ViewModels_Settlement_BalanceSummaryViewModel';
import type { Workspace_API_ViewModels_Settlement_CheckForPositiveBalanceViewModel } from '../models/Workspace_API_ViewModels_Settlement_CheckForPositiveBalanceViewModel';
import type { Workspace_API_ViewModels_Settlement_CommercialHeaderViewModel } from '../models/Workspace_API_ViewModels_Settlement_CommercialHeaderViewModel';
import type { Workspace_API_ViewModels_Settlement_Export_BuyerSellerPartyViewModel } from '../models/Workspace_API_ViewModels_Settlement_Export_BuyerSellerPartyViewModel';
import type { Workspace_API_ViewModels_Settlement_Export_RequestViewModel } from '../models/Workspace_API_ViewModels_Settlement_Export_RequestViewModel';
import type { Workspace_API_ViewModels_Settlement_Export_ResponseViewModel } from '../models/Workspace_API_ViewModels_Settlement_Export_ResponseViewModel';
import type { Workspace_API_ViewModels_Settlement_Export_StatusRequestViewModel } from '../models/Workspace_API_ViewModels_Settlement_Export_StatusRequestViewModel';
import type { Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel } from '../models/Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel';
import type { Workspace_API_ViewModels_Settlement_LoanProceedsSummaryViewModel } from '../models/Workspace_API_ViewModels_Settlement_LoanProceedsSummaryViewModel';
import type { Workspace_API_ViewModels_Settlement_MiscellaneousDisbursementChargeViewModel } from '../models/Workspace_API_ViewModels_Settlement_MiscellaneousDisbursementChargeViewModel';
import type { Workspace_API_ViewModels_Settlement_ResequenceChargeViewModel } from '../models/Workspace_API_ViewModels_Settlement_ResequenceChargeViewModel';
import type { Workspace_API_ViewModels_Settlement_ResequencedSectionsViewModel } from '../models/Workspace_API_ViewModels_Settlement_ResequencedSectionsViewModel';
import type { Workspace_API_ViewModels_Settlement_ResequenceSectionViewModel } from '../models/Workspace_API_ViewModels_Settlement_ResequenceSectionViewModel';
import type { Workspace_API_ViewModels_Settlement_SectionViewModel } from '../models/Workspace_API_ViewModels_Settlement_SectionViewModel';
import type { Workspace_API_ViewModels_Settlement_SettlementDatesViewModel } from '../models/Workspace_API_ViewModels_Settlement_SettlementDatesViewModel';
import type { Workspace_API_ViewModels_Settlement_StatementViewModel } from '../models/Workspace_API_ViewModels_Settlement_StatementViewModel';
import type { Workspace_API_ViewModels_Settlement_TrialBalanceNoteViewModel } from '../models/Workspace_API_ViewModels_Settlement_TrialBalanceNoteViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateAllSectionSubTotalsViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateAllSectionSubTotalsViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateCommercialHeaderViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateCommercialHeaderViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateLoanProceedsResultsViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateLoanProceedsResultsViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateLoanProceedsViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateLoanProceedsViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateMiscellaneousDisbursementGroupViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateMiscellaneousDisbursementGroupViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateSectionSubTotalsViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateSectionSubTotalsViewModel';
import type { Workspace_API_ViewModels_Settlement_UpdateSectionTitleViewModel } from '../models/Workspace_API_ViewModels_Settlement_UpdateSectionTitleViewModel';
import type { Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto } from '../models/Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto';
import type { Workspace_Common_Features_SettlementStatement_Models_DTOs_SettlementStatementSectionOrderDto } from '../models/Workspace_Common_Features_SettlementStatement_Models_DTOs_SettlementStatementSectionOrderDto';
import type { Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType } from '../models/Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType';
import type { Workspace_Common_Models_Enums_PartyType } from '../models/Workspace_Common_Models_Enums_PartyType';
import type { WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_SettlementStatementPartiesListViewModel } from '../models/WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_SettlementStatementPartiesListViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettlementStatementsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get balance summary for a file (Auth)
   * @returns Workspace_API_ViewModels_Settlement_BalanceSummaryViewModel Balance summary successfully retrieved
   * @throws ApiError
   */
  public getBalanceSummaryAsync({
    orderId,
  }: {
    /**
     * file id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_BalanceSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/balance-summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Gets the Settlement Statement Header for a particular order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_CommercialHeaderViewModel Settlement Statement successfully retrieved
   * @throws ApiError
   */
  public getCommercialHeaderAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_CommercialHeaderViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/header',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update the Settlement Statement Header for a particular order (Auth)
   * @returns any Settlement Statement successfully retrieved
   * @throws ApiError
   */
  public updateCommercialHeaderAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateCommercialHeaderViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/header',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Creates a balance summary preview for an order (Auth)
   * @returns number Balance summary preview successfully generated
   * @throws ApiError
   */
  public createBalanceSummaryPreviewAsync({
    orderId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/balance-summary/_export',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Fetch balance summary export status (Auth)
   * @returns Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel Successfully retrieved export status.
   * @throws ApiError
   */
  public getBalanceSummaryPreviewStatusAsync({
    orderId,
    previewId,
    requestBody,
  }: {
    /**
     * Order Identifier
     */
    orderId: number,
    /**
     * Preview Id
     */
    previewId: number,
    /**
     * Export settings
     */
    requestBody?: Workspace_API_ViewModels_Settlement_Export_StatusRequestViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/balance-summary/{previewId}',
      path: {
        'orderId': orderId,
        'previewId': previewId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Fetches a settlement statement by id. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Settlement statement fetched successfully
   * @throws ApiError
   */
  public getSettlementStatementAsync({
    orderId,
    settlementStatementId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Identifier for the Settlement Statement
     */
    settlementStatementId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Current user doesn't have access to the settlement statement`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Gets Buyers/Sellers for an Order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_Export_BuyerSellerPartyViewModel Buyers/Sellers successfully retrieved
   * @throws ApiError
   */
  public getBuyersSellers({
    orderId,
    partyType,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Party type: Buyer/Seller
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_Export_BuyerSellerPartyViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/parties/{partyType}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Gets all parties for an order and whether or not a settlement statement can be created for each party
   * If there are no allocations for a specific party made through Party Allocations/Fractional Settlement
   * A settlement statement cannot be created for that party (Auth)
   * @returns WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_SettlementStatementPartiesListViewModel Buyers/Sellers successfully retrieved
   * @throws ApiError
   */
  public getPartiesForStatementNavigationAsync({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<WorkspaceAPI_FeatureAreas_SettlementStatements_ViewModels_Responses_SettlementStatementPartiesListViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/parties',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Updates the line item position values for a section (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel Line item positions processed
   * @throws ApiError
   */
  public updateSectionLineItemPositionsAsync({
    orderId,
    settlementStatementId,
    sectionId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Settlement Statement identifier
     */
    settlementStatementId: number,
    /**
     * Section identifier
     */
    sectionId: number,
    /**
     * List of new line item positions for the section
     */
    requestBody: Array<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UpdateSectionLineItemViewModel>,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionLineItemsResponseViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/sections/{sectionId}/line-items',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Creates a Settlement Statement (Auth)
   * @returns any Settlement statement was created.
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Success
   * @throws ApiError
   */
  public createSettlementStatement({
    orderId,
    settlementStatementType,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * The type of statement to be created
     */
    settlementStatementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
    /**
     * View model containing response data for when a Settlement Statement is created
     */
    requestBody?: Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Requests_CreateSettlementStatementViewModel,
  }): CancelablePromise<any | Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementType}',
      path: {
        'orderId': orderId,
        'settlementStatementType': settlementStatementType,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `The endpoint was called with invalid data`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Updates properties of a Settlement Statement's section (Auth)
   * @returns any Section properties successfully patched
   * @throws ApiError
   */
  public updateSettlementStatementSectionPropertyAsync({
    orderId,
    settlementStatementId,
    sectionId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * The Id for the Settlement Statement whose section needs to be updated.
     */
    settlementStatementId: number,
    /**
     * The Id for the specific section that will be updated.
     */
    sectionId: number,
    /**
     * The Json PATCH operations to be performed.
     */
    requestBody?: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation> | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/sections/{sectionId}',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement or section could not be found`,
        422: `External user or user cannot access into this order`,
      },
    });
  }

  /**
   * Updates properties of a Settlement Statement's section (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel Section properties successfully patched
   * @throws ApiError
   */
  public updateSettlementStatementSectionPositionAsync({
    orderId,
    settlementStatementId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * The Id for the Settlement Statement whose section needs to be updated.
     */
    settlementStatementId: number,
    /**
     * List of objects representing the Section, by the Id, and new position.
     */
    requestBody?: Array<Workspace_Common_Features_SettlementStatement_Models_DTOs_SettlementStatementSectionOrderDto> | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_UpdateSectionPositionsResponseViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{settlementStatementId}/sections',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement or section could not be found`,
        422: `Un-matching section ordering cannot be processed`,
      },
    });
  }

  /**
   * Fetches a settlement statement by its related Order Id and specific Type (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Settlement Statement information successfully retrieved
   * @throws ApiError
   */
  public getSettlementStatementByOrderIdAndType({
    orderId,
    statementType,
    partyId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * The type of Settlement Statement to filter by
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
    /**
     * party Id
     */
    partyId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementType}',
      path: {
        'orderId': orderId,
        'statementType': statementType,
      },
      query: {
        'partyId': partyId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Updates properties of a Settlement Statement's section (Auth)
   * @returns any Section properties successfully patched
   * @throws ApiError
   */
  public updateSettlementStatementLineDetail({
    orderId,
    lineItemDetailId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * The Id for the Settlement Statement whose section needs to be updated.
     */
    lineItemDetailId: number,
    /**
     * The Json PATCH operations to be performed.
     */
    requestBody?: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation> | null,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/line-items/{lineItemDetailId}',
      path: {
        'orderId': orderId,
        'lineItemDetailId': lineItemDetailId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement or section could not be found`,
        422: `External user or user cannot access into this order`,
      },
    });
  }

  /**
   * Exports a settlement statement by its related Order Id and specific Type (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel Settlement Statement successfully exported
   * @throws ApiError
   */
  public exportSettlementStatementByOrderIdAndType({
    orderId,
    statementType,
    partyId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * The type of Settlement Statement to filter by
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
    /**
     * The file business party Id
     */
    partyId?: number | null,
    /**
     * Export settings
     */
    requestBody?: Workspace_Common_Features_SettlementStatement_Models_DTOs_Export_ExportSettlementStatementDto,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_ExportSettlementStatementDocumentViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementType}/export',
      path: {
        'orderId': orderId,
        'statementType': statementType,
      },
      query: {
        'partyId': partyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieves the export settings (print and signature options) for a settlement statement (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel Setting successfully retrieved
   * @throws ApiError
   */
  public getExportSettingsAsync({
    orderId,
    statementType,
    partyId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Settlement statement type
     */
    statementType: Workspace_Common_Features_SettlementStatement_Models_Enums_SettlementStatementType,
    /**
     * The file business party Id
     */
    partyId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_Export_ExportSettingsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementType}/export/settings',
      path: {
        'orderId': orderId,
        'statementType': statementType,
      },
      query: {
        'partyId': partyId,
      },
      errors: {
        400: `Bad Request`,
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order, settlement statement print/signature options not found`,
      },
    });
  }

  /**
   * Grant read permission for multiple User for a list of statements. (Auth)
   * @returns void
   * @throws ApiError
   */
  public grantStatementsUserPermissionsAsync({
    orderId,
    requestBody,
  }: {
    /**
     * id of the order
     */
    orderId: number,
    /**
     * The statementsId and user info for adding permissions
     */
    requestBody: Workspace_API_FeatureAreas_SettlementStatements_ViewModels_UserStatementShareViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/permissions',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Remove read permission for a given User for a statement (Auth)
   * @returns void
   * @throws ApiError
   */
  public removeDocumentUserPermissionsAsync({
    orderId,
    statementId,
    userId,
  }: {
    /**
     * The id of the order
     */
    orderId: number,
    /**
     * The id of the statement against which to check permissions
     */
    statementId: number,
    /**
     * The UserId being targeted for permissions removal
     */
    userId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/permissions/{userId}',
      path: {
        'orderId': orderId,
        'statementId': statementId,
        'userId': userId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `Users do not have permission to access this endpoint`,
        404: `Order not found`,
        422: `Validation failed`,
      },
    });
  }

  /**
   * Retrieve external order business party employees that do not have access to the specified statement. (Auth)
   * @returns Workspace_API_ViewModels_BusinessParties_FilePartyViewModel Business parties response
   * @throws ApiError
   */
  public getAvailableBusinessParties({
    orderId,
    statementId,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number,
    /**
     * The id of the statement against which to check permissions.
     */
    statementId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_BusinessParties_FilePartyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/parties/available',
      path: {
        'orderId': orderId,
        'statementId': statementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieve external order business party employees that have access to the specified statement. (Auth)
   * @returns Workspace_API_ViewModels_BusinessParties_FilePartyViewModel Business parties response
   * @throws ApiError
   */
  public getAuthorizedBusinessParties({
    orderId,
    statementId,
  }: {
    /**
     * The id of the order for which to get the business party employees.
     */
    orderId: number,
    /**
     * The id of the statement against which to check permissions.
     */
    statementId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_BusinessParties_FilePartyViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/parties/authorized',
      path: {
        'orderId': orderId,
        'statementId': statementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External Users do not have permission`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Create Miscellaneous Disbursement Group. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Successfully added charges to group.
   * @throws ApiError
   */
  public createMiscellaneousDisbursementGroupAsync({
    orderId,
    statementId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Settlement statement id
     */
    statementId: number,
    /**
     * List of charges to add to the subgroup
     */
    requestBody?: Array<number> | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/misc-disbursement-groups',
      path: {
        'orderId': orderId,
        'statementId': statementId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Miscellaneous Disbursement Group. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Successfully deleted group.
   * @throws ApiError
   */
  public deleteMiscellaneousDisbursementGroupAsync({
    orderId,
    statementId,
    groupId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Settlement statement id
     */
    statementId: number,
    /**
     * groupId
     */
    groupId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/misc-disbursement-groups/{groupId}',
      path: {
        'orderId': orderId,
        'statementId': statementId,
        'groupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Rename Miscellaneous Disbursement Group. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Successfully renamed group.
   * @throws ApiError
   */
  public renameMiscellaneousDisbursementGroupAsync({
    orderId,
    statementId,
    groupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Settlement statement id
     */
    statementId: number,
    /**
     * Subgroup id
     */
    groupId: number,
    /**
     * Update model
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateMiscellaneousDisbursementGroupViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/misc-disbursement-groups/{groupId}',
      path: {
        'orderId': orderId,
        'statementId': statementId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add charges to Miscellaneous Disbursement Group. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Successfully added charges to group.
   * @throws ApiError
   */
  public insertChargesToMiscellaneousDisbursementGroupAsync({
    orderId,
    statementId,
    groupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Settlement statement id
     */
    statementId: number,
    /**
     * Subgroup id
     */
    groupId: number,
    /**
     * Charges to add
     */
    requestBody?: Array<number> | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/misc-disbursement-groups/{groupId}/charges',
      path: {
        'orderId': orderId,
        'statementId': statementId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove charges from Miscellaneous Disbursement Group. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel Successfully removed charges from group.
   * @throws ApiError
   */
  public removeChargesFromMiscellaneousDisbursementGroupAsync({
    orderId,
    statementId,
    groupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Settlement statement id
     */
    statementId: number,
    /**
     * Subgroup id
     */
    groupId: number,
    /**
     * Charges to remove
     */
    requestBody?: Array<number> | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/{statementId}/misc-disbursement-groups/{groupId}/charges',
      path: {
        'orderId': orderId,
        'statementId': statementId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Start escrow data synchronization from FAST to the settlement statement (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel Successfully started escrow data synchronization from FAST
   * @throws ApiError
   */
  public syncEscrowDataAsync({
    orderId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/_sync',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Get status of escrow data synchronization jobs (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel List of jobs with current status
   * @throws ApiError
   */
  public getEscrowDataSyncStatusAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * List of previously submitted sync jobs
     */
    requestBody?: Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SyncEscrowDataViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/sync/status',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Get last successful synchronization of the settlement statement for a specific order. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SettlementStatementSyncStatusViewModel Success fetching the synchronization data.
   * @throws ApiError
   */
  public getLastSuccessfulSync({
    orderId,
  }: {
    /**
     * Order id.
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_SettlementStatementSyncStatusViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement-statements/sync/last',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid authorization header/token.`,
        403: `External user or user cannot access into this order.`,
        404: `Order not found or sync status not found.`,
      },
    });
  }

  /**
   * Get balance summary for a file (Auth)
   * @returns Workspace_API_ViewModels_Settlement_BalanceSummaryViewModel Balance summary successfully retrieved
   * @throws ApiError
   */
  public getBalanceSummaryAsync1({
    orderId,
  }: {
    /**
     * file id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_BalanceSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/balance-summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Check if an order has a positive balance (Auth)
   * @returns Workspace_API_ViewModels_Settlement_CheckForPositiveBalanceViewModel Balance check was successful
   * @throws ApiError
   */
  public checkForPositiveBalanceAsync({
    orderId,
  }: {
    /**
     * file id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_CheckForPositiveBalanceViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/check-balance',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Gets the Settlement Statement Header for a particular order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_CommercialHeaderViewModel Settlement Statement successfully retrieved
   * @throws ApiError
   */
  public getCommercialHeader({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_CommercialHeaderViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/header',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update the Settlement Statement Header for a particular order (Auth)
   * @returns any Settlement Statement successfully retrieved
   * @throws ApiError
   */
  public updateCommercialHeaderAsync1({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateCommercialHeaderViewModel,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement/header',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Get loan proceeds summary for a file (Auth)
   * @returns Workspace_API_ViewModels_Settlement_LoanProceedsSummaryViewModel Loan summary successfully retrieved
   * @throws ApiError
   */
  public getLoanProceedsSummaryAsync({
    orderId,
  }: {
    /**
     * file id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_LoanProceedsSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/loan-summary',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `External user or user cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update the Settlement Statement Loan Proceeds for a particular order and loan sequence number (Auth)
   * @returns Workspace_API_ViewModels_Settlement_UpdateLoanProceedsResultsViewModel Loan Proceeds successfully updated
   * @throws ApiError
   */
  public updateLoanProceedsAsync({
    orderId,
    loanSequenceNumber,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    loanSequenceNumber: number,
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateLoanProceedsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_UpdateLoanProceedsResultsViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/settlement/loans/{loanSequenceNumber}',
      path: {
        'orderId': orderId,
        'loanSequenceNumber': loanSequenceNumber,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Retrieves the settlement statement sections of a particular order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_StatementViewModel Settlement statement was successfully retrieved.
   * @throws ApiError
   */
  public getSettlementStatement({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_StatementViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/charges',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update settlement statement subtotal flags for all sections (Auth)
   * @returns Workspace_API_ViewModels_Settlement_StatementViewModel Subtotal flags was successfully updated.
   * @throws ApiError
   */
  public updateAllSectionsSubtotalsAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Update flag
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateAllSectionSubTotalsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_StatementViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement/sections',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update settlement statement subtotal flags for supplied section (Auth)
   * @returns Workspace_API_ViewModels_Settlement_StatementViewModel Subtotal flags for section was successfully updated.
   * @throws ApiError
   */
  public updateSectionSubtotalsAsync({
    orderId,
    sectionId,
    requestBody,
  }: {
    /**
     * Order id
     */
    orderId: number,
    /**
     * section id
     */
    sectionId: number,
    /**
     * Update flag
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateSectionSubTotalsViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_StatementViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement/sections/{sectionId}',
      path: {
        'orderId': orderId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Update Settlement statement section title (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateSettlementStatementSectionTitleAsync({
    orderId,
    sectionId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Section id
     */
    sectionId: number,
    /**
     * update model
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateSectionTitleViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/settlement/sections/{sectionId}',
      path: {
        'orderId': orderId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Fetch settlement statement dates (Auth)
   * @returns Workspace_API_ViewModels_Settlement_SettlementDatesViewModel Success
   * @throws ApiError
   */
  public getSettlementDatesAsync({
    orderId,
  }: {
    orderId: number,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_SettlementDatesViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/dates',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Fetch settlement statement export status (Auth)
   * @returns Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel Subtotal flags for section was successfully updated.
   * @throws ApiError
   */
  public getSettlementStatementPreviewStatusAsync({
    orderId,
    previewId,
    requestBody,
  }: {
    /**
     * Order Identifier
     */
    orderId: number,
    /**
     * Preview Id
     */
    previewId: number,
    /**
     * Export settings
     */
    requestBody?: Workspace_API_ViewModels_Settlement_Export_StatusRequestViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement/export/{previewId}',
      path: {
        'orderId': orderId,
        'previewId': previewId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Updates the order of the settlement statement sections of a given order. (Auth)
   * @returns Workspace_API_ViewModels_Settlement_ResequencedSectionsViewModel Sections were successfuly resequenced.
   * @throws ApiError
   */
  public resequenceSectionAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Id of the order to update.
     */
    orderId: number,
    /**
     * Represents the resequence action.
     */
    requestBody?: Workspace_API_ViewModels_Settlement_ResequenceSectionViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_ResequencedSectionsViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement/sections/_resequence',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token.`,
        403: `External user or user cannot access into this order.`,
        404: `Order not found.`,
        409: `Source section does not contain any charges, or an invalid section id was provided.`,
        422: `Input failed validation.`,
      },
    });
  }

  /**
   * Updates the order of the charges of a given order and settlement statment section. (Auth)
   * @returns Workspace_API_ViewModels_Settlement_SectionViewModel Charges were successfuly resequenced.
   * @throws ApiError
   */
  public resequenceChargesAsync({
    orderId,
    sectionId,
    requestBody,
  }: {
    /**
     * Id of the order to update.
     */
    orderId: number,
    /**
     * Id of the section where the cahrge belongs.
     */
    sectionId: number,
    /**
     * Represents the resequence action.
     */
    requestBody?: Workspace_API_ViewModels_Settlement_ResequenceChargeViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_SectionViewModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement/section/{sectionId}/_resequence',
      path: {
        'orderId': orderId,
        'sectionId': sectionId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token.`,
        402: `Input failed validation.`,
        403: `External user or user cannot access into this order.`,
        404: `Order not found.`,
        409: `Conflict`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Creates a settlement statement preview/file for an order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_Export_ResponseViewModel Settlement Statement preview/file successfully generated
   * @throws ApiError
   */
  public createSettlementStatementPreviewAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Required options to generate a preview/file
     */
    requestBody?: Workspace_API_ViewModels_Settlement_Export_RequestViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_Export_ResponseViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement/export',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Request validation failed`,
      },
    });
  }

  /**
   * Creates a balance summary preview for an order (Auth)
   * @returns number Balance summary preview successfully generated
   * @throws ApiError
   */
  public createBalanceSummaryPreviewAsync1({
    orderId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement/balance-summary/_export',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Fetch balance summary export status (Auth)
   * @returns Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel Successfully retrieved export status.
   * @throws ApiError
   */
  public getBalanceSummaryPreviewStatusAsync1({
    orderId,
    previewId,
    requestBody,
  }: {
    /**
     * Order Identifier
     */
    orderId: number,
    /**
     * Preview Id
     */
    previewId: number,
    /**
     * Export settings
     */
    requestBody?: Workspace_API_ViewModels_Settlement_Export_StatusRequestViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_Export_StatusResponseViewmodel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement/balance-summary/{previewId}',
      path: {
        'orderId': orderId,
        'previewId': previewId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets Buyers/Sellers for an Order (Auth)
   * @returns Workspace_API_ViewModels_Settlement_Export_BuyerSellerPartyViewModel >Buyers/Sellers successfully retrieved
   * @throws ApiError
   */
  public getBuyersSellers1({
    orderId,
    partyType,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * Party type: Buyer/Seller
     */
    partyType: Workspace_Common_Models_Enums_PartyType,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_Export_BuyerSellerPartyViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/settlement/parties/{partyType}',
      path: {
        'orderId': orderId,
        'partyType': partyType,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Appends a trial balance summery to a settlement statement (Auth)
   * @returns void
   * @throws ApiError
   */
  public appendTrialBalanceNoteAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Trail balance note payload
     */
    requestBody?: Workspace_API_ViewModels_Settlement_TrialBalanceNoteViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/settlement/trial-balance',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add charges to miscellaneous disbursement charge group. (Auth)
   * @returns Workspace_API_ViewModels_Settlement_StatementViewModel Successfully added charges to group.
   * @throws ApiError
   */
  public addMiscellaneousDisbursementChargesAsync({
    orderId,
    groupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Group Id
     */
    groupId: number,
    /**
     * Add misc disbursement charge payload
     */
    requestBody?: Workspace_API_ViewModels_Settlement_MiscellaneousDisbursementChargeViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_StatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement/misc-disbursement-groups/{groupId}/charges',
      path: {
        'orderId': orderId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove a charge from a miscellaneous disbursement charge group. (Auth)
   * @returns any Success
   * @throws ApiError
   */
  public removeMiscellaneousDisbursementChargeAsync({
    orderId,
    chargeId,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * Charge Id
     */
    chargeId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/settlement/misc-disbursement-group-charges/{chargeId}',
      path: {
        'orderId': orderId,
        'chargeId': chargeId,
      },
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Miscellaneous Disbursement Group (Auth)
   * @returns Workspace_API_ViewModels_Settlement_StatementViewModel Success
   * @throws ApiError
   */
  public createMiscellaneousDisbursementGroupAsync1({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * charges
     */
    requestBody?: Workspace_API_ViewModels_Settlement_MiscellaneousDisbursementChargeViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_Settlement_StatementViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/settlement/misc-disbursement-groups',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Miscellaneous Disbursement Group (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateMiscellaneousDisbursementGroupAsync({
    orderId,
    groupId,
    requestBody,
  }: {
    /**
     * Order Id
     */
    orderId: number,
    /**
     * group id
     */
    groupId: number,
    /**
     * update model
     */
    requestBody?: Workspace_API_ViewModels_Settlement_UpdateMiscellaneousDisbursementGroupViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/ncs/v1/orders/{orderId}/settlement/misc-disbursement-groups/{groupId}',
      path: {
        'orderId': orderId,
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header/token`,
        403: `External user or user cannot access into this order`,
        404: `Order not found`,
        422: `Validation Error`,
      },
    });
  }

}
