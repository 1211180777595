import React from 'react';
import FileTypeIcon from 'components/svg/FileType';
import LinkContents from 'components/LinkContents';
import LockClosed from 'icons/LockClosed';
import useGetFileTypeForExtension from 'hooks/documents/useGetFileTypeForExtension';
import { neutralGray7 } from 'config/colors';
import styled from 'styled-components';
import { SUB_TEXT_BOOK } from 'config/typography';

interface IFileIconFill {
  front: string;
  back: string;
}

export interface IFileNameProps {
  fileName: string;
  displayName: string | JSX.Element;
  customTooltipId?: string;
  handleShowCustomDisplayNameTooltip?: (showTooltip: boolean) => void;
  handleShowCustomSubtextTooltip?: (showTooltip: boolean) => void;
  handleOnLeave?: (onLeave: boolean) => void;
  subText?: string;
  locked?: boolean;
  index?: number;
  hyperlink?: {
    file: File;
  };
  extension?: string | undefined;
  maxWidth?: string | number;
  downloadDocument?: boolean; //download document irrespective of extension
  iconStyleOverrides?: React.CSSProperties;
  displayNameStyleOverrides?: React.CSSProperties;
  subTextStyleOverrides?: React.CSSProperties;
  id?: string;
  data?: Record<string, string>;
  fileIconfill?: IFileIconFill;
}

let tooltipIndex = 0;

export const ReadonlyFileName = (props: IFileNameProps) => {
  const fileTypeForExtension = useGetFileTypeForExtension({
    fileName: props.fileName,
    extension: props.extension,
  });
  const overflowStyles: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  /**Document with docx never opens in new tab, it gets downloaded on click with random name.*/
  const fileExtentionsToBeDownloaded = ['.DOC', '.DOCX'];
  const readonlyFileName = (
    <div
      {...props.data}
      style={{
        maxWidth: (!props.hyperlink && props.maxWidth) || '100%',
        display: 'inline-flex',
        alignItems: 'center',
      }}
      id={props.id ? props.id : 'default-id_preview_file'}
    >
      <FileTypeIcon
        type={fileTypeForExtension}
        style={{
          height: 26,
          width: 23,
          float: 'left',
          position: 'relative',
          top: 1,
          flexShrink: 0,
          marginRight: 8,
          ...props.iconStyleOverrides,
        }}
        fileIconfill={props.fileIconfill}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minWidth: 0,
          width: '100%',
        }}
      >
        <div
          style={{
            fontSize: '14px',
            fontWeight: 500,
            ...props.displayNameStyleOverrides,
          }}
        >
          <LinkContents
            text={props.displayName}
            tooltipId={`file-display-name-${++tooltipIndex}`}
            customTooltipId={props.customTooltipId}
            handleShowCustomDisplayNameTooltip={
              props.handleShowCustomDisplayNameTooltip
            }
            handleOnLeave={props.handleOnLeave}
            data-tip
          />
          {props.locked && (
            <div
              id={`file-locked-icon-${props?.index}`}
              data-for={`file-locked-icon-${props?.index}`}
              data-tip
            >
              <LockClosed
                fill={neutralGray7}
                style={{ marginLeft: '5px', marginTop: '5px', width: '16px' }}
              />{' '}
            </div>
          )}
        </div>
        {!!props.subText && (
          <SubtextContainer
            style={{
              ...overflowStyles,
              ...props.subTextStyleOverrides,
            }}
          >
            <LinkContents
              text={props.subText}
              tooltipId={`file-sub-text-${tooltipIndex}`}
              tooltipPlace="bottom"
              customTooltipId={props.customTooltipId}
              handleShowCustomSubtextTooltip={
                props.handleShowCustomSubtextTooltip
              }
              handleOnLeave={props.handleOnLeave}
              data-tip
            />
          </SubtextContainer>
        )}
      </div>
    </div>
  );

  const blobUrl =
    props.hyperlink && window.URL.createObjectURL(props.hyperlink.file);

  return props.hyperlink ? (
    <a
      href={blobUrl}
      target="_blank"
      style={{ display: 'inline-block', maxWidth: props.maxWidth || '100%' }}
      // with download we can download document with file name not a random file name.
      download={
        (fileExtentionsToBeDownloaded.includes(props.extension!) ||
          props.downloadDocument) &&
        props.hyperlink?.file.name
      }
      onClick={(evt) => {
        if (!!(window.navigator as any).msSaveOrOpenBlob) {
          evt.preventDefault();
          (window.navigator as any).msSaveOrOpenBlob(
            props.hyperlink!.file,
            props.fileName
          );
        }
      }}
    >
      {readonlyFileName}
    </a>
  ) : (
    readonlyFileName
  );
};

const SubtextContainer = styled.div`
  ${SUB_TEXT_BOOK};
`;
