import { toast } from 'components/Snackbar';
import { Row } from 'components/ui/Box';
import Button from 'components/ui/Button';
import React from 'react';
import { IFileOrganizationContacts } from 'store/files/types';
import { FA_SERVICE_URL } from 'utils/constants';
import { IOrderPartyContact } from '../types';

const organizationContactToOrderContact = (
  party: IFileOrganizationContacts,
  idx: number
): IOrderPartyContact => ({
  ...party,
  businessPartyName: party.name,
  cellular: party.cellular || '',
  email: party.email || '',
  id: `${idx}-${party.gabPartyId || party.userId}`,
  phoneNumber: party.phone || '',
});

function errorToastWithRetry(
  message: string,
  toastId: string,
  onClick: () => void
) {
  toast(
    <Row>
      <div>
        <span>{message}</span>
        <span>
          Please try again or contact the IT Service Desk at 866.462.7347 option
          2 or via the{' '}
          <a href={FA_SERVICE_URL} target="_blank">
            ServiceFirst
          </a>{' '}
          website.
        </span>
      </div>
      <Button
        onClick={() => {
          onClick();
          toast.dismiss(toastId);
        }}
        plain
        style={{
          height: 'min-content',
          lineHeight: '20px',
        }}
      >
        RETRY
      </Button>
    </Row>,
    'error',
    {
      autoClose: false,
      toastId,
    }
  );
}

export { errorToastWithRetry, organizationContactToOrderContact };
