import { PageTypeKeys, IPageState } from './types';

export const initialState: IPageState = {
  forceHideNav: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PageTypeKeys.SET_FORCE_HIDE_NAV:
      return {
        ...state,
        forceHideNav: action.forceHideNav,
      };
    default:
      return state;
  }
};
