/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementExternalViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementExternalViewModel';
import type { Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_SharedStatementsViewModel } from '../models/Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_SharedStatementsViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettlementStatementsExternalService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get meta information for settlement statements shared with external user (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_SharedStatementsViewModel Information was successfully retrieved
   * @throws ApiError
   */
  public getSharedStatementsAsync({
    orderId,
  }: {
    /**
     * file id
     */
    orderId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_SharedStatementsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/external/orders/{orderId}/settlement-statements/shared',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User cannot access file`,
        404: `Order not found`,
      },
    });
  }

  /**
   * Fetches a settlement statement by id. (Auth)
   * @returns Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementExternalViewModel Settlement statement fetched successfully
   * @throws ApiError
   */
  public getSettlementStatementAsync({
    orderId,
    settlementStatementId,
  }: {
    /**
     * Order id.
     */
    orderId: number,
    /**
     * Settlement statement id.
     */
    settlementStatementId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_SettlementStatements_ViewModels_Responses_GetSettlementStatementExternalViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/external/orders/{orderId}/settlement-statements/{settlementStatementId}',
      path: {
        'orderId': orderId,
        'settlementStatementId': settlementStatementId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `User cannot access file`,
        404: `Order not found or statement is not shared with user`,
      },
    });
  }

}
