import React from 'react';
import styled from 'styled-components';
import { white } from 'config/theme';

interface ISpinnerProps {
  size: number;
  style?: React.CSSProperties;
  color?: string;
  thickness?: number;
}

export const Spinner = (props: ISpinnerProps) => <SpinnerEl {...props} />;

const SpinnerEl = styled.span.attrs({
  role: 'status',
  className: 'spinner',
})<{
  size: number;
  color?: string;
  thickness?: number;
}>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: ${({ thickness }) => thickness || 3}px solid
    ${({ color }) => color || white};
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: spinner-rotate 1s linear infinite;
  top: 50%;
  margin: -28px auto 0 auto;
  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
