import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default ({ style }: ISVGProps) => (
  <svg
    width="96"
    height="160"
    viewBox="0 0 96 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9622 126.546C25.3115 120.622 22.152 115.213 17.247 113.093C16.7523 112.879 16.9153 112.144 17.4578 112.144H82.4627V8.09588C82.4627 6.71053 81.7262 5.42936 80.5288 4.73387C79.3313 4.03837 77.8584 4.03556 76.6581 4.7226L70.9689 7.98044C69.6927 8.70972 68.113 8.65622 66.8903 7.83965L59.1181 2.64739C57.9601 1.87587 56.4759 1.78295 55.2335 2.40524L43.4642 8.30143C42.3736 8.8505 41.089 8.8505 39.9956 8.30143L28.232 2.40805C26.9839 1.78295 25.4998 1.87587 24.3417 2.65021L16.5724 7.83965C15.3524 8.65622 13.7727 8.70972 12.4966 7.98044L6.80451 4.7226C5.60706 4.03556 4.12853 4.03837 2.9339 4.73387C1.73927 5.42936 1 6.71053 1 8.09588V123.801C1 131.133 7.76302 136.886 15.3834 135.143C19.5885 134.18 23.0037 130.759 23.9622 126.546Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.1016 155.496C89.1016 157.982 69.8508 159.996 46.1016 159.996C22.3495 159.996 3.10156 157.982 3.10156 155.496C3.10156 153.013 22.3495 150.996 46.1016 150.996C69.8508 150.996 89.1016 153.013 89.1016 155.496Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.9689 7.97879C69.6927 8.70807 68.113 8.65457 66.8931 7.838L59.121 2.64856C57.9629 1.87704 56.4815 1.7813 55.2335 2.4064L43.4671 8.3026C42.3736 8.84604 41.089 8.84604 39.9956 8.3026L28.2292 2.4064C26.9868 1.7813 25.4998 1.87704 24.3417 2.64856L16.5724 7.838C15.3524 8.65457 13.7727 8.70807 12.4966 7.97879L6.80732 4.72095C5.60706 4.03391 4.13134 4.03673 2.9339 4.73222C1.73927 5.42771 1 6.70888 1 8.09423V14.9985L12.4966 21.5789C13.7727 22.3082 15.3524 22.2547 16.5724 21.4381L24.3417 16.2487C25.4998 15.4743 26.9868 15.3842 28.2292 16.0065L39.9956 21.8999C41.089 22.4462 42.3736 22.4462 43.4671 21.8999L55.2335 16.0065C56.4815 15.3842 57.9629 15.4743 59.121 16.2487L66.8931 21.4381C68.113 22.2547 69.6927 22.3082 70.9689 21.5789L82.4627 14.9985V8.09423C82.4627 6.70888 81.7262 5.42771 80.5288 4.73222C79.3341 4.03673 77.8584 4.03391 76.6581 4.72095L70.9689 7.97879Z"
      fill="white"
    />
    <path
      d="M10.1016 29.9961C10.1016 29.4438 10.5493 28.9961 11.1016 28.9961H69.1016C69.6539 28.9961 70.1016 29.4438 70.1016 29.9961C70.1016 30.5484 69.6539 30.9961 69.1016 30.9961H11.1016C10.5493 30.9961 10.1016 30.5484 10.1016 29.9961Z"
      fill="#9BABC2"
    />
    <path
      d="M10.1016 41.9961C10.1016 41.4438 10.5493 40.9961 11.1016 40.9961H69.1016C69.6539 40.9961 70.1016 41.4438 70.1016 41.9961C70.1016 42.5484 69.6539 42.9961 69.1016 42.9961H11.1016C10.5493 42.9961 10.1016 42.5484 10.1016 41.9961Z"
      fill="#9BABC2"
    />
    <path
      d="M10.1016 53.9961C10.1016 53.4438 10.5493 52.9961 11.1016 52.9961H35.1016C35.6538 52.9961 36.1016 53.4438 36.1016 53.9961C36.1016 54.5484 35.6538 54.9961 35.1016 54.9961H11.1016C10.5493 54.9961 10.1016 54.5484 10.1016 53.9961Z"
      fill="#9BABC2"
    />
    <path
      d="M10.1016 65.9961C10.1016 65.4438 10.5493 64.9961 11.1016 64.9961H27.1016C27.6538 64.9961 28.1016 65.4438 28.1016 65.9961C28.1016 66.5484 27.6538 66.9961 27.1016 66.9961H11.1016C10.5493 66.9961 10.1016 66.5484 10.1016 65.9961Z"
      fill="#9BABC2"
    />
    <path
      d="M10.1016 77.9961C10.1016 77.4438 10.5493 76.9961 11.1016 76.9961H25.1016C25.6538 76.9961 26.1016 77.4438 26.1016 77.9961C26.1016 78.5484 25.6538 78.9961 25.1016 78.9961H11.1016C10.5493 78.9961 10.1016 78.5484 10.1016 77.9961Z"
      fill="#9BABC2"
    />
    <path
      d="M10.1016 89.9961C10.1016 89.4438 10.5493 88.9961 11.1016 88.9961H25.1016C25.6538 88.9961 26.1016 89.4438 26.1016 89.9961C26.1016 90.5484 25.6538 90.9961 25.1016 90.9961H11.1016C10.5493 90.9961 10.1016 90.5484 10.1016 89.9961Z"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9622 126.546C25.3115 120.622 22.152 115.213 17.247 113.093C16.7523 112.879 16.9153 112.144 17.4578 112.144H82.4627V8.09588C82.4627 6.71053 81.7262 5.42936 80.5288 4.73387C79.3313 4.03837 77.8584 4.03556 76.6582 4.7226L70.9689 7.98044C69.6927 8.70972 68.113 8.65622 66.8903 7.83965L59.1181 2.64739C57.9601 1.87587 56.4759 1.78295 55.2335 2.40524L43.4642 8.30143C42.3736 8.8505 41.089 8.8505 39.9956 8.30143L28.232 2.40805C26.984 1.78295 25.4998 1.87587 24.3417 2.65021L16.5724 7.83965C15.3524 8.65622 13.7727 8.70972 12.4966 7.98044L6.80451 4.7226C5.60706 4.03556 4.12853 4.03837 2.9339 4.73387C1.73927 5.42936 1 6.71053 1 8.09588V123.801C1 131.133 7.76302 136.886 15.3834 135.143C19.5885 134.18 23.0037 130.759 23.9622 126.546Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6406 135.459C19.0691 135.459 24.2777 130.239 24.2777 123.802C24.2777 119.007 21.3853 114.887 17.2561 113.096C16.767 112.885 16.9469 112.145 17.481 112.145H82.1541C87.7085 112.145 92.754 115.915 93.8559 121.369C95.371 128.87 89.6817 135.459 82.4633 135.459H12.6406Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6406 135.459C19.0691 135.459 24.2777 130.239 24.2777 123.802C24.2777 119.007 21.3853 114.887 17.2561 113.096C16.767 112.885 16.9469 112.145 17.481 112.145H82.1541C87.7085 112.145 92.754 115.915 93.8559 121.369C95.371 128.87 89.6817 135.459 82.4633 135.459H12.6406Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.1016 77.4961C75.1016 88.2635 66.3718 96.9961 55.6044 96.9961C44.8313 96.9961 36.1016 88.2635 36.1016 77.4961C36.1016 66.7259 44.8313 57.9961 55.6044 57.9961C66.3718 57.9961 75.1016 66.7259 75.1016 77.4961Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.6016 66.1211C64.9757 66.1211 72.8068 72.7398 74.6748 81.56C74.9546 80.2487 75.0987 78.8894 75.0987 77.4961C75.0987 66.7259 66.3718 57.9961 55.6016 57.9961C44.8285 57.9961 36.1016 66.7259 36.1016 77.4961C36.1016 78.8894 36.2485 80.2487 36.5255 81.56C38.3935 72.7398 46.2246 66.1211 55.6016 66.1211Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.1016 77.4961C75.1016 88.2635 66.3718 96.9961 55.6044 96.9961C44.8313 96.9961 36.1016 88.2635 36.1016 77.4961C36.1016 66.7259 44.8313 57.9961 55.6044 57.9961C66.3718 57.9961 75.1016 66.7259 75.1016 77.4961Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.1016 66.4961C53.1016 65.1154 54.2209 63.9961 55.6016 63.9961C56.9823 63.9961 58.1016 65.1154 58.1016 66.4961V80.4961C58.1016 81.8768 56.9823 82.9961 55.6016 82.9961C54.2209 82.9961 53.1016 81.8768 53.1016 80.4961V66.4961Z"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M54.1016 66.4961C54.1016 65.6677 54.7731 64.9961 55.6016 64.9961C56.43 64.9961 57.1016 65.6677 57.1016 66.4961V68.9961L54.1016 70.9961V66.4961Z"
      fill="#E8F4FA"
    />
    <circle
      cx="55.6016"
      cy="88.4961"
      r="2.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M57.1016 88.4961C57.1016 88.682 56.43 87.8764 55.6016 87.8764C54.7731 87.8764 54.1016 88.682 54.1016 88.4961C54.1016 87.8438 54.6016 86.9961 55.6016 86.9961C56.6016 86.9961 57.1016 87.7969 57.1016 88.4961Z"
      fill="#E8F4FA"
    />
  </svg>
);
