import workspaceAPI from 'services/workspaceAPI';
import { AsyncAction } from 'store';
import { setLoadingStateAction } from 'store/ui/actions';
import { IPagination } from 'store/common/types';
import {
  ILocationFilterAggregation,
  ILocationStateFilter,
  IMultisiteProjectSetGeodataAction,
  IMultisiteProjectSetSitesAction,
  IOrderSiteGeodata,
  ISitesFilters,
  ISitesQuery,
  OrderLoadingKeys,
  OrderTypeKeys,
  ProjectLinkTypes,
  IRemoveSitesFromMultisiteOpts,
  SitesSortField,
  SortDirection,
  IMultisiteProjectState,
} from '../types';
import { defaultPagination } from '../reducers';
import { isEmpty } from 'utils/text';

export function setMultisiteProjectSites(
  sites,
  pagination: IPagination,
  locations: ILocationStateFilter = {}
): IMultisiteProjectSetSitesAction {
  return {
    type: OrderTypeKeys.MULTISITE_PROJECT_SET_SITES,
    payload: {
      sites,
      pagination,
      locations,
    },
  };
}

export async function fetchMultisiteProjectSites(
  fileId: number,
  page = 1,
  pageSize: number = 5000,
  searchTerm = '',
  sortedByField: SitesSortField = 'status',
  sortedByDirection: SortDirection = 'descending',
  filters: ISitesFilters = {}
) {
  return workspaceAPI.get(
    createOrdersOrdersUrl(
      fileId,
      page,
      pageSize,
      searchTerm,
      sortedByField,
      sortedByDirection,
      filters
    )
  );
}

export function getMultisiteProjectSites(
  fileId: number,
  page = 1,
  pageSize: number = 5000,
  searchTerm = '',
  sortedByField: SitesSortField = 'status',
  sortedByDirection: SortDirection = 'descending',
  filters: ISitesFilters = {},
  displayLoaders = true
): AsyncAction<void> {
  return (dispatch) => {
    if (displayLoaders) {
      dispatch(
        setLoadingStateAction(OrderLoadingKeys.orderProjectSitesLoading, true)
      );
    }

    return fetchMultisiteProjectSites(
      fileId,
      page,
      pageSize,
      searchTerm,
      sortedByField,
      sortedByDirection,
      filters
    )
      .then(({ data }) => {
        const { sites, pagination, locations } = mapMultisiteProjectState(data);

        dispatch(setMultisiteProjectSites(sites, pagination, locations));
      })
      .finally(() =>
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderProjectSitesLoading,
            false
          )
        )
      );
  };
}

export function createOrdersOrdersUrl(
  fileId: number | string,
  page: number = 1,
  pageSize: number = 5000,
  searchTerm: string = '',
  sortedByField: SitesSortField = 'status',
  sortedByDirection: SortDirection = 'descending',
  filters: ISitesFilters = {},
  extraParams: object = {}
) {
  const params = new URLSearchParams({
    ...extraParams,
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortProperty: sortedByField,
    sortDirection: sortedByDirection,
  });

  // If theres is no `statuses` filter provided, then we set defaults to "Open" and "Closed"
  // when we make the request, but we don't want to show it in the UI.
  if (!filters.statuses) {
    params.append('OrderStatus', 'Open');
    params.append('OrderStatus', 'Closed');
  } else {
    filters.statuses.forEach((status) => params.append('OrderStatus', status));
  }

  // `filters.states` comes in a <country>__<state> format so we need
  // to split by `__` to get the state required the API is expecting
  filters.states
    ?.map((state: string) => state.split('__')[1])
    ?.filter((s: string) => !isEmpty(s))
    ?.forEach((s: string) => params.append('State', s));

  if (searchTerm) {
    params.append('searchTerm', searchTerm);
    [
      'number',
      'name',
      'status',
      'address',
      'street',
      'city',
      'county',
      'state',
      'zip',
      'country',
    ].forEach((attr) => params.append('searchAttributes', attr));
  }

  return `/orders/${fileId}/orders?${params}`;
}

export function mapMultisiteProjectState(data): IMultisiteProjectState {
  const { columnFilterAggregations: aggregations, pagination } =
    data?._meta ?? {};
  const locationFilters = (aggregations?.locations ??
    {}) as ILocationFilterAggregation;
  const locations = Object.keys(locationFilters).reduce(
    (states: ILocationStateFilter, countryCode: string) => ({
      ...states,
      ...Object.keys(locationFilters[countryCode]).reduce(
        (states, state) => ({
          ...states,
          [`${countryCode}__${state}`]: locationFilters[countryCode][state],
        }),
        {}
      ),
    }),
    {} as ILocationStateFilter
  );

  return {
    sites: data?.files ?? [],
    pagination,
    locations,
  };
}

interface IAllSettledPartition {
  successfulIndexes: number[];
  erroneousIndexes: number[];
}
export function removeSitesFromMultisiteProject({
  projectFileId,
  refetch,
  sitesToRemove,
  onSuccess,
  onError,
}: IRemoveSitesFromMultisiteOpts): AsyncAction<void> {
  return () => {
    const allDeleteRequests = sitesToRemove.map(({ fileId }) =>
      workspaceAPI.delete(`/orders/${projectFileId}/orders/${fileId}`)
    );

    return Promise.allSettled(allDeleteRequests)
      .then((results) => {
        const initialPartition: IAllSettledPartition = {
          successfulIndexes: [],
          erroneousIndexes: [],
        };
        const { successfulIndexes, erroneousIndexes } = results.reduce(
          (acc, { status }, idx) => {
            if (status === 'fulfilled') acc.successfulIndexes.push(idx);
            else acc.erroneousIndexes.push(idx);
            return acc;
          },
          initialPartition
        );
        if (successfulIndexes.length) onSuccess(successfulIndexes);
        if (erroneousIndexes.length) onError(erroneousIndexes);
      })
      .catch(() => {
        /* noop */
      })
      .finally(() => {
        refetch();
      });
  };
}

export function resetMultisiteProject() {
  return (dispatch) => {
    dispatch(setMultisiteProjectSites([], defaultPagination));
  };
}

export function setMultisiteProjectGeodata(
  geodata: IOrderSiteGeodata[],
  unmappableCount: number
): IMultisiteProjectSetGeodataAction {
  return {
    type: OrderTypeKeys.MULTISITE_PROJECT_SET_GEODATA,
    payload: {
      geodata,
      unmappableCount,
    },
  };
}

export function getMultisiteProjectGeodata(fileId: number): AsyncAction<void> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderProjectGeodataLoading, true)
    );

    const params = new URLSearchParams();
    params.append('statuses', 'Open');
    params.append('statuses', 'Closed');

    return workspaceAPI
      .get(`/orders/${fileId}/geodata?${params}`)
      .then(({ data }) => {
        const sortedByLat =
          data?.files?.sort((a, b) => (a.latitude > b.latitude ? -1 : 1)) ??
          ([] as IOrderSiteGeodata[]);

        dispatch(
          setMultisiteProjectGeodata(
            sortedByLat,
            data?._meta?.unmappableCount ?? 0
          )
        );
      })
      .catch(() => {
        /** do nothing */
      })
      .finally(() =>
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderProjectGeodataLoading,
            false
          )
        )
      );
  };
}

export function resetMultisiteProjectGeodata() {
  return (dispatch) => {
    dispatch(setMultisiteProjectGeodata([], 0));
  };
}

export function setBreadcrumbInfoToSitesList(
  multisiteSiteId: number,
  query: ISitesQuery
) {
  return {
    type: OrderTypeKeys.MULTISITE_PROJECT_SET_BREADCRUMB_INFO,
    payload: {
      multisiteSiteId,
      linkInfo: {
        type: ProjectLinkTypes.SitesList,
        query,
      },
    },
  };
}

export function setBreadcrumbInfoToSitesMap(
  multisiteSiteId: number,
  lat: number,
  lng: number,
  zoom: number,
  {
    selectedOrder,
    displaySitesList,
  }: {
    displaySitesList?: boolean;
    selectedOrder?: number;
  }
) {
  return {
    type: OrderTypeKeys.MULTISITE_PROJECT_SET_BREADCRUMB_INFO,
    payload: {
      multisiteSiteId,
      linkInfo: {
        type: ProjectLinkTypes.SitesMap,
        displaySitesList,
        lat,
        lng,
        selectedOrder,
        zoom,
      },
    },
  };
}
