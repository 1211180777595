import {
  IMultipleProgressStickyAction,
  IProgressData,
  MultipleProgressEnum,
  ProgressBarKeys,
  TaskStatus,
} from './types';

interface IProgressItem {
  isExpanded: boolean;
  progressDataItems: IProgressData[];
}
type ProgressBarData = Record<ProgressBarKeys, IProgressItem>;
export interface IProgressBarState {
  progressData: ProgressBarData;
}

export const initialState: IProgressBarState = {
  progressData: {} as ProgressBarData,
};

export default (
  state = initialState,
  action: IMultipleProgressStickyAction
): IProgressBarState => {
  switch (action.type) {
    case MultipleProgressEnum.SET_ADD_NEW_PROGRESS_ITEM:
      const { identifier, progressItem } = action.progressItemMetaData;
      let records = state.progressData[identifier] || [];
      const isDuplicate = records.progressDataItems?.some(
        (item) => item.progressUniqueId === progressItem.progressUniqueId
      );
      if (isDuplicate) return state;
      else {
        // if first record then set isExpanded to true.
        if (
          !records.progressDataItems ||
          records.progressDataItems.length === 0
        ) {
          records = { progressDataItems: [progressItem], isExpanded: true };
        } else {
          records.progressDataItems.push(progressItem);
        }
        // create a new object to trigger change
        const newRecords = [...records.progressDataItems];
        return {
          progressData: {
            ...state.progressData,
            [identifier]: { ...records, progressDataItems: newRecords },
          },
        };
      }

    case MultipleProgressEnum.SET_SUCCESS_PROGRESS_ITEM:
      const {
        identifier: successKey,
        progressItem: successProgressItem,
      } = action.progressItemMetaData;

      const filterRecordsSuccess = state.progressData[successKey] || [];
      const successRecord = filterRecordsSuccess.progressDataItems.find(
        (item) => item.progressUniqueId === successProgressItem.progressUniqueId
      );
      if (!successRecord) return state;
      else {
        successRecord.status = TaskStatus.SUCCESS;
        // create new object to trigger change
        const newSuccessRecords = [...filterRecordsSuccess.progressDataItems];
        return {
          ...state,
          progressData: {
            ...state.progressData,
            [successKey]: {
              ...filterRecordsSuccess,
              progressDataItems: newSuccessRecords,
            },
          },
        };
      }
    case MultipleProgressEnum.SET_FAIL_PROGRESS_ITEM:
      const {
        identifier: failedKey,
        progressItem: failedProgressItem,
      } = action.progressItemMetaData;
      const filterRecordsFailed = state.progressData[failedKey] || [];
      const failedRecord = filterRecordsFailed.progressDataItems.find(
        (item) => item.progressUniqueId === failedProgressItem.progressUniqueId
      );
      if (!failedRecord) return state;
      else {
        failedRecord.status = TaskStatus.FAILED;
        // create new object to trigger change
        const newFailedRecords = [...filterRecordsFailed.progressDataItems];
        return {
          ...state,
          progressData: {
            ...state.progressData,
            [failedKey]: {
              ...filterRecordsFailed,
              progressDataItems: newFailedRecords,
            },
          },
        };
      }
    case MultipleProgressEnum.REMOVE_PROGRESS_ITEM:
      const {
        identifier: removeKey,
        progressItem: removeProgressItem,
      } = action.progressItemMetaData;
      const filterRecordsToRemove = state.progressData[removeKey] || [];
      const filteredRecords = filterRecordsToRemove.progressDataItems.filter(
        (item) => item.progressUniqueId !== removeProgressItem.progressUniqueId
      );
      if (filteredRecords) {
        // create new object to trigger change
        const newRemovedRecords = [...filteredRecords];
        return {
          ...state,
          progressData: {
            ...state.progressData,
            [removeKey]: {
              ...filterRecordsToRemove,
              progressDataItems: newRemovedRecords,
            },
          },
        };
      } else {
        return {
          ...state,
          progressData: {
            ...state.progressData,
            [removeKey]: { isExpanded: false, progressDataItems: [] },
          },
        };
      }
    case MultipleProgressEnum.CLEAR_ALL_PROGRESS_ITEMS:
      const { identifier: clearKey } = action.clearAllItems;
      return {
        ...state,
        progressData: {
          ...state.progressData,
          [clearKey]: { isExpanded: false, progressDataItems: [] },
        },
      };
    case MultipleProgressEnum.TOGGLE_IS_EXPANDED_PROGRESS_ITEMS:
      const { identifier: toggleKey } = action.toggleProgressBar;
      return {
        ...state,
        progressData: {
          ...state.progressData,
          [toggleKey]: {
            ...state.progressData[toggleKey],
            isExpanded: !state.progressData[toggleKey].isExpanded,
          },
        },
      };
    default:
      return state;
  }
};
