import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { white, black } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.1445" cy="16.2773" r="16" fill={black} fillOpacity="0.5" />
    <path
      d="M22.8811 11.6227H19.5128V9.9305C19.5128 8.99132 18.7633 8.23828 17.8286 8.23828H14.4603C13.5256 8.23828 12.7761 8.99132 12.7761 9.9305V11.6227H9.40779C8.47308 11.6227 7.73205 12.3758 7.73205 13.3149L7.72363 22.6221C7.72363 23.5613 8.47308 24.3144 9.40779 24.3144H22.8811C23.8158 24.3144 24.5652 23.5613 24.5652 22.6221V13.3149C24.5652 12.3758 23.8158 11.6227 22.8811 11.6227ZM17.8286 11.6227H14.4603V9.9305H17.8286V11.6227Z"
      fill={props.fill || white}
    />
  </svg>
);
