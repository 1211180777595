/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_ViewModels_IBA_CreateIBATransactionViewModel } from '../models/Workspace_API_ViewModels_IBA_CreateIBATransactionViewModel';
import type { Workspace_API_ViewModels_IBA_CreateIBAViewModel } from '../models/Workspace_API_ViewModels_IBA_CreateIBAViewModel';
import type { Workspace_API_ViewModels_IBA_IBAAccountSummaryViewModel } from '../models/Workspace_API_ViewModels_IBA_IBAAccountSummaryViewModel';
import type { Workspace_API_ViewModels_IBA_IBADetailsViewModel } from '../models/Workspace_API_ViewModels_IBA_IBADetailsViewModel';
import type { Workspace_API_ViewModels_IBA_IBAForOrderViewModel } from '../models/Workspace_API_ViewModels_IBA_IBAForOrderViewModel';
import type { Workspace_API_ViewModels_IBA_IBAtransactionSummaryViewModel } from '../models/Workspace_API_ViewModels_IBA_IBAtransactionSummaryViewModel';
import type { Workspace_API_ViewModels_IBA_IBATransactionViewModel } from '../models/Workspace_API_ViewModels_IBA_IBATransactionViewModel';
import type { Workspace_API_ViewModels_IBA_OrderIBAReportViewModel } from '../models/Workspace_API_ViewModels_IBA_OrderIBAReportViewModel';
import type { Workspace_API_ViewModels_IBA_UpdateIBAViewModel } from '../models/Workspace_API_ViewModels_IBA_UpdateIBAViewModel';
import type { Workspace_Common_Models_IBA_IBABeneficiaryParty } from '../models/Workspace_Common_Models_IBA_IBABeneficiaryParty';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IbaService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all IBAs for a particular order (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBAForOrderViewModel Success
   * @throws ApiError
   */
  public getIbAforOrder({
    orderId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_API_ViewModels_IBA_IBAForOrderViewModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/ibas',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Create a new IBA (Auth)
   * @returns number Success
   * @returns any An IBA was created
   * @throws ApiError
   */
  public createIbaAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * CreateIBAViewModel
     */
    requestBody: Workspace_API_ViewModels_IBA_CreateIBAViewModel,
  }): CancelablePromise<number | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/ibas',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user`,
      },
    });
  }

  /**
   * Get IBA beneficiary parties to retrieve all the buyers and sellers on a given order (Auth)
   * @returns Workspace_Common_Models_IBA_IBABeneficiaryParty Overdraft record details are available
   * @throws ApiError
   */
  public getIbaBeneficiaryPartiesAsync({
    orderId,
  }: {
    /**
     * An Order Id
     */
    orderId: number,
  }): CancelablePromise<Array<Workspace_Common_Models_IBA_IBABeneficiaryParty>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/ibas/beneficiaries',
      path: {
        'orderId': orderId,
      },
      errors: {
        401: `Invalid Authorization header`,
        403: `No File permissions`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Create a new IBA Transaction (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBATransactionViewModel Success
   * @returns any An IBA Transaction was created
   * @throws ApiError
   */
  public createIbaTransactionAsync({
    orderId,
    ibaAccountId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account Id identifier
     */
    ibaAccountId: number,
    /**
     * CreateIBAViewModel
     */
    requestBody: Workspace_API_ViewModels_IBA_CreateIBATransactionViewModel,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_IBATransactionViewModel | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}/transaction',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthenticated user`,
        403: `User doesn't have the required activity right`,
        404: `Order Not Found or External user`,
      },
    });
  }

  /**
   * Get IBA transaction summary for a particular IBA account (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBAtransactionSummaryViewModel Success
   * @throws ApiError
   */
  public getIbAtransactionSummaryAsync({
    orderId,
    ibaAccountId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account Id identifier
     */
    ibaAccountId: number,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_IBAtransactionSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}/transaction-summary',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the IBA details for a particular IBA account (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBADetailsViewModel Success
   * @throws ApiError
   */
  public getIbaDetailsAsync({
    orderId,
    ibaAccountId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account Id identifier
     */
    ibaAccountId: number,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_IBADetailsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates an IBA Bank or Beneficiary (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateIbaAsync({
    orderId,
    ibaAccountId,
    requestBody,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account Id identifier
     */
    ibaAccountId: number,
    /**
     * UpdateIBAViewModel
     */
    requestBody: Workspace_API_ViewModels_IBA_UpdateIBAViewModel,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the IBA details for a particular IBA transaction (Auth)
   * @returns Workspace_API_ViewModels_IBA_IBAAccountSummaryViewModel Success
   * @throws ApiError
   */
  public getIbaAccountSummaryAsync({
    orderId,
    ibaAccountId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account identifier
     */
    ibaAccountId: number,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_IBAAccountSummaryViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}/summary',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Generates a report for the IBA (Auth)
   * @returns number Success
   * @throws ApiError
   */
  public generateOrderIbaReportAsync({
    orderId,
    ibaAccountId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account Id identifier
     */
    ibaAccountId: number,
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}/report',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the IBA report document id (Auth)
   * @returns Workspace_API_ViewModels_IBA_OrderIBAReportViewModel Success
   * @throws ApiError
   */
  public getOrderIbaReportAsync({
    orderId,
    ibaAccountId,
    jobId,
  }: {
    /**
     * Order identifier
     */
    orderId: number,
    /**
     * IBA Account Id identifier
     */
    ibaAccountId: number,
    /**
     * The job id for the report
     */
    jobId?: number,
  }): CancelablePromise<Workspace_API_ViewModels_IBA_OrderIBAReportViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/ibas/{ibaAccountId}/report',
      path: {
        'orderId': orderId,
        'ibaAccountId': ibaAccountId,
      },
      query: {
        'jobId': jobId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}
