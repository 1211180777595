/* eslint-disable import/no-unused-modules */
import { css } from 'styled-components';

export const SHADOW_INSET_2P = css`
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.16) inset,
    0px -2px 5px 0px rgba(0, 0, 0, 0.14) inset,
    0px 2px 5px 0px rgba(0, 0, 0, 0.14) inset;
`;

export const SHADOW_INSET_1P = css`
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1) inset,
    0px 2px 5px 0px rgba(0, 0, 0, 0.1) inset;
`;

export const SHADOW_1P = css`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.17),
    0px 4px 4px 0px rgba(50, 57, 68, 0.05);
`;

export const SHADOW_2P = css`
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
`;

export const SHADOW_3P = css`
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 3px 3px 0px rgba(0, 0, 0, 0.14);
`;

export const SHADOW_4P = css`
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
`;

export const SHADOW_8P = {
  boxShadow:
    '0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.20)',
};

export const SHADOW_12P = css`
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.2),
    0px 5px 22px 0px rgba(0, 0, 0, 0.12), 0px 12px 17px 0px rgba(0, 0, 0, 0.14);
`;

export const SHADOW_16P = css`
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2),
    0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 16px 24px 0px rgba(0, 0, 0, 0.14);
`;

export const SHADOW_20P = css`
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2),
    0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 38px 0px rgba(0, 0, 0, 0.14);
`;
