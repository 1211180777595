import {
  IBulkUpdateStatus,
  JobStatus,
} from 'components/MultiSiteBulkUpdates/Shared';
import ProgressPopup from 'components/ProgressPopup/ProgressPopup';
import { SiteType } from 'components/SiteCard';
import { green8, red8 } from 'config/colors';
import useAPIMutation from 'hooks/api/useAPIMutation';
import useBackgroundProcessingStatus from 'hooks/useBackgroundProcessingStatus';
import AlertCircle from 'icons/AlertCircle';
import CheckCircle from 'icons/CheckCircle';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { IApplicationState } from 'store';
import { setDeleteOrderLoanProgress } from 'store/orders/actions';
import { addToast } from 'store/ui/actions';

type ReduxProps = ConnectedProps<typeof connector>;

const getHeaderTitleAndIcon = ({ pollingList, inProgress, onClose }) => {
  if (inProgress) {
    return { icon: null, title: 'Deleting loan and allocations...', onClose };
  } else {
    const failedUpdates = pollingList.filter(
      ({ state }) => state === JobStatus.FAILED
    ).length;

    if (failedUpdates === 0) {
      // if there are no failed status
      return {
        icon: <CheckCircle fill={green8} />,
        title: 'Loan and allocations successfully deleted',
        onClose,
      };
    } else {
      return {
        icon: <AlertCircle fill={red8} />,
        title: 'Loan and allocations could not be deleted ',
        onClose,
      };
    }
  }
};

const DeleteLoanProgress = ({
  setDeleteOrderLoanProgress,
  fetchOrderDetailsOnFinish,
  pollingList,
  projectFileId,
}: ReduxProps) => {
  const pollingListItems = pollingList.map(({ jobId, orderId, state }) => ({
    jobId,
    itemId: orderId,
    state,
  }));

  const handleClose = () => {
    setDeleteOrderLoanProgress([]);
  };

  const inProgress = !!pollingList.find(
    ({ state }) => state === JobStatus.PROCESSING
  );

  const { execute: fetchStatuses } = useAPIMutation({
    autoFire: false,
    method: 'PUT',
    onSuccess: (data: Array<Omit<IBulkUpdateStatus, 'orderNumber'>>) => {
      setDeleteOrderLoanProgress(
        pollingList.map((item) => {
          const newState: JobStatus =
            data.find(({ orderId }) => orderId === item.orderId)?.state ||
            JobStatus.PROCESSING;

          return {
            ...item,
            state: newState,
          };
        }),
        fetchOrderDetailsOnFinish
      );
    },
    onError: () => {
      addToast(
        'An error has occurred while fetching the progress of deleting the loan.',
        'error'
      );

      handleClose();
    },
    url: `/orders/${projectFileId}/bulk-updates/site-orders/get-bulk-updates`,
  });

  useBackgroundProcessingStatus({
    inProgress,
    pollingList,
    fetchStatuses,
    shouldAutoClearList:
      !inProgress &&
      !pollingList.find(({ state }) => state === JobStatus.FAILED),
    clearList: () => setDeleteOrderLoanProgress([]),
  });

  if (pollingList.length <= 0) return null;

  return (
    <ProgressPopup
      pollingList={pollingListItems}
      inProgress={inProgress}
      progressHeaderProps={getHeaderTitleAndIcon({
        pollingList,
        inProgress,
        onClose: handleClose,
      })}
    />
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    pollingList: state.orders.ui.deleteOrderLoanProgress.items,
    fetchOrderDetailsOnFinish:
      state.orders.ui.deleteOrderLoanProgress.fetchOrderDetailsOnFinish,
    projectFileId:
      state.orders?.order?.fileType === SiteType.MultisiteProject
        ? Number(state.orders?.order?.fileID)
        : Number(state.orders?.order?.projectFileId),
    currentOrderId: state.orders?.order?.fileID,
  };
};

const mapDispatchToProps = {
  setDeleteOrderLoanProgress,
  addToast,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DeleteLoanProgress);
