import styled from 'styled-components';

const HORIZONTAL_PADDING = 20;

const ContentContainer = styled.div`
  box-sizing: content-box;
  margin: 0 auto;
  max-width: 1400px;
  padding-left: ${HORIZONTAL_PADDING}px;
  padding-right: ${HORIZONTAL_PADDING}px;
`;

ContentContainer.displayName = 'ContentContainer';

export default ContentContainer;
