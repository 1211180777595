import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
    data-testid="lock-outline-icon"
  >
    <path
      d="M7.60005 14.0392C8.05092 14.0392 8.48332 13.8626 8.80213 13.5483C9.12094 13.2339 9.30005 12.8076 9.30005 12.363C9.30005 11.4327 8.53505 10.6868 7.60005 10.6868C7.14918 10.6868 6.71678 10.8634 6.39797 11.1778C6.07916 11.4921 5.90005 11.9185 5.90005 12.363C5.90005 12.8076 6.07916 13.2339 6.39797 13.5483C6.71678 13.8626 7.14918 14.0392 7.60005 14.0392ZM12.7 6.49637C13.1509 6.49637 13.5833 6.67296 13.9021 6.98731C14.2209 7.30166 14.4 7.728 14.4 8.17256V16.5535C14.4 16.9981 14.2209 17.4244 13.9021 17.7388C13.5833 18.0531 13.1509 18.2297 12.7 18.2297H2.50005C2.04918 18.2297 1.61678 18.0531 1.29797 17.7388C0.979155 17.4244 0.800049 16.9981 0.800049 16.5535V8.17256C0.800049 7.24227 1.56505 6.49637 2.50005 6.49637H3.35005V4.82018C3.35005 3.70879 3.79782 2.64293 4.59485 1.85706C5.39187 1.0712 6.47288 0.6297 7.60005 0.6297C8.15817 0.6297 8.71082 0.73809 9.22645 0.948681C9.74209 1.15927 10.2106 1.46794 10.6053 1.85706C10.9999 2.24618 11.313 2.70814 11.5265 3.21655C11.7401 3.72496 11.85 4.26987 11.85 4.82018V6.49637H12.7ZM7.60005 2.30589C6.92375 2.30589 6.27514 2.57079 5.79693 3.04231C5.31871 3.51383 5.05005 4.15335 5.05005 4.82018V6.49637H10.15V4.82018C10.15 4.15335 9.88139 3.51383 9.40317 3.04231C8.92495 2.57079 8.27635 2.30589 7.60005 2.30589Z"
      fill={props.fill}
    />
    <path
      d="M7.60005 14.0392C8.05092 14.0392 8.48332 13.8626 8.80213 13.5483C9.12094 13.2339 9.30005 12.8076 9.30005 12.363C9.30005 11.4327 8.53505 10.6868 7.60005 10.6868C7.14918 10.6868 6.71678 10.8634 6.39797 11.1778C6.07916 11.4921 5.90005 11.9185 5.90005 12.363C5.90005 12.8076 6.07916 13.2339 6.39797 13.5483C6.71678 13.8626 7.14918 14.0392 7.60005 14.0392ZM12.7 6.49637C13.1509 6.49637 13.5833 6.67296 13.9021 6.98731C14.2209 7.30166 14.4 7.728 14.4 8.17256V16.5535C14.4 16.9981 14.2209 17.4244 13.9021 17.7388C13.5833 18.0531 13.1509 18.2297 12.7 18.2297H2.50005C2.04918 18.2297 1.61678 18.0531 1.29797 17.7388C0.979155 17.4244 0.800049 16.9981 0.800049 16.5535V8.17256C0.800049 7.24227 1.56505 6.49637 2.50005 6.49637H3.35005V4.82018C3.35005 3.70879 3.79782 2.64293 4.59485 1.85706C5.39187 1.0712 6.47288 0.6297 7.60005 0.6297C8.15817 0.6297 8.71082 0.73809 9.22645 0.948681C9.74209 1.15927 10.2106 1.46794 10.6053 1.85706C10.9999 2.24618 11.313 2.70814 11.5265 3.21655C11.7401 3.72496 11.85 4.26987 11.85 4.82018V6.49637H12.7ZM7.60005 2.30589C6.92375 2.30589 6.27514 2.57079 5.79693 3.04231C5.31871 3.51383 5.05005 4.15335 5.05005 4.82018V6.49637H10.15V4.82018C10.15 4.15335 9.88139 3.51383 9.40317 3.04231C8.92495 2.57079 8.27635 2.30589 7.60005 2.30589Z"
      fill="black"
      fillOpacity="0.18"
    />
  </svg>
);
