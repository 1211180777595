import React from 'react';
import styled from 'styled-components';
import LeftIcon from 'react-icons/lib/md/chevron-left';
import RightIcon from 'react-icons/lib/md/chevron-right';
import { black, blue, pigeonPost, lightBlue } from 'config/theme';
import { IMMUTABLE_HTML_ATTRIBUTE } from 'utils/constants';

interface IPagination {
  domId?: string;
  page: number;
  pages: number;
  disabled?: boolean;
  onPageClick: (page: number) => void;
}

export default class Pagination extends React.Component<IPagination> {
  public static displayName = 'Pagination';
  public render() {
    const { domId, page, pages, disabled, onPageClick } = this.props;

    if (!pages || pages === 1) {
      return null;
    }

    return (
      <PaginationContainer
        id={domId ? `${domId}-container` : 'pagination-container'}
        className="pagination"
      >
        <PaginationNav
          id={domId ? `${domId}-previous` : 'pagination-previous'}
          type="button"
          className="pagination__prev"
          disabled={disabled || page <= 1}
          onClick={() => onPageClick(page - 1)}
          {...{
            [IMMUTABLE_HTML_ATTRIBUTE]: 'Table__Pagination--prev-page',
          }}
        >
          <LeftIcon /> Previous
        </PaginationNav>

        <Pages>{this.renderPaginationNav()}</Pages>

        <PaginationNav
          id={domId ? `${domId}-next` : 'pagination-next'}
          type="button"
          className="pagination__next"
          disabled={disabled || page >= pages}
          onClick={() => onPageClick(page + 1)}
          {...{
            [IMMUTABLE_HTML_ATTRIBUTE]: 'Table__Pagination--next-page',
          }}
        >
          Next <RightIcon />
        </PaginationNav>
      </PaginationContainer>
    );
  }

  private renderPageNumber = (number) => {
    const { page, disabled, onPageClick, domId } = this.props;
    const isCurrent = number === page;

    return (
      <Page
        id={domId ? `${domId}-pagination-page-${number}` : 'pagination-page'}
        type="button"
        key={`paginationPage-${number}`}
        current={isCurrent}
        disabled={disabled}
        onClick={() => !isCurrent && onPageClick(number)}
        className="pagination__page"
        {...{
          [IMMUTABLE_HTML_ATTRIBUTE]: 'Table__Pagination--page',
        }}
      >
        {number}
      </Page>
    );
  };

  private renderEllipsis = (idx, text = '…') => {
    return (
      <Ellipsis key={`paginationEl-${idx}`} className="pagination__ellipsis">
        {text}
      </Ellipsis>
    );
  };

  private renderPaginationNav = () => {
    const { page, pages } = this.props;
    const delta = 2;
    const range = [1];
    const rangeWithDots: React.ReactElement[] = [];
    let l;

    for (let i = page - delta; i <= page + delta; i++) {
      if (i < pages && i > 1) {
        range.push(i);
      }
    }
    range.push(pages);

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(this.renderPageNumber(l + 1));
        } else if (i - l !== 1) {
          rangeWithDots.push(this.renderEllipsis(i));
        }
      }
      rangeWithDots.push(this.renderPageNumber(i));
      l = i;
    }

    return rangeWithDots;
  };
}

const Ellipsis = styled.span`
  color: ${blue};
  margin: 0 4px;
`;

const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 40px;
`;
PaginationContainer.displayName = 'PaginationContainer';

const PageButton = styled.button`
  color: ${lightBlue};
  font-size: 16px;
  height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-top: 2px;
  }
`;

const PaginationNav = styled(PageButton)`
  ${(props) =>
    props.disabled &&
    `
    color: ${pigeonPost};
    &:hover {
      cursor: default;
      text-decoration: none;
    }
  `}
`;

const Pages = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;
Pages.displayName = 'Pages';

const Page = styled(PageButton)<{ current: boolean }>`
  flex: 0 0 20px;
  justify-content: center;
  margin: 0 4px;

  ${(props) =>
    props.current &&
    `
      color: ${black};
      cursor: default;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    `};
`;
Page.displayName = 'Page';
