import { blue } from 'config/theme';
import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props?: ISVGProps) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props?.style}
  >
    <path
      d="M18.5048 0V3.23686C25.5462 4.10215 30.5346 10.4957 29.6684 17.5303C28.9306 23.363 24.3433 27.994 18.5048 28.683V31.8878C27.3266 31.0065 33.7425 23.1868 32.8603 14.3736C32.1385 6.76215 26.0916 0.75313 18.5048 0ZM15.2969 0.0480721C12.1692 0.352529 9.18579 1.55433 6.74776 3.57336L9.04143 5.94492C10.8379 4.50275 13.0032 3.57336 15.2969 3.25288V0.0480721ZM4.48617 5.83275C2.48066 8.26415 1.24648 11.2378 0.941406 14.3736H4.14934C4.45409 12.0981 5.35231 9.9349 6.77984 8.12419L4.48617 5.83275ZM0.957446 17.5784C1.27824 20.7191 2.51329 23.6835 4.50221 26.1192L6.77984 23.8277C5.36343 22.0164 4.46043 19.8579 4.16538 17.5784H0.957446ZM9.04143 26.1833L6.74776 28.3786C9.17741 30.4019 12.152 31.6619 15.2969 32V28.7952C13.0151 28.5004 10.8546 27.5983 9.04143 26.1833ZM17.7028 7.96395V16.3766L24.9207 20.655L23.7177 22.6259L15.2969 17.5784V7.96395H17.7028Z"
      fill={props?.fill || blue}
    />
  </svg>
);
