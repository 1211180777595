import React from 'react';

export default () => (
  <svg
    width="198"
    height="141"
    viewBox="0 0 198 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 96.7112H111.676C107.903 96.7112 104.851 93.6512 104.851 89.8749V21.5112C104.851 17.7349 107.903 14.6748 111.676 14.6748H190.174C193.941 14.6748 197 17.7349 197 21.5112V89.8749C197 93.6512 193.941 96.7112 190.174 96.7112Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.999 23.2203H104.85V21.5144C104.85 17.7349 107.905 14.6748 111.675 14.6748H190.173C193.944 14.6748 196.999 17.7349 196.999 21.5144V23.2203Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 28.3477C190.174 31.1799 187.883 33.475 185.055 33.475C182.227 33.475 179.936 31.1799 179.936 28.3477C179.936 25.5155 182.227 23.2205 185.055 23.2205C187.883 23.2205 190.174 25.5155 190.174 28.3477Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 28.3477C190.174 31.1799 187.883 33.475 185.055 33.475C182.227 33.475 179.936 31.1799 179.936 28.3477C179.936 25.5155 182.227 23.2205 185.055 23.2205C187.883 23.2205 190.174 25.5155 190.174 28.3477Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.11 28.3477C173.11 31.1799 170.818 33.475 167.991 33.475C165.163 33.475 162.871 31.1799 162.871 28.3477C162.871 25.5155 165.163 23.2205 167.991 23.2205C170.818 23.2205 173.11 25.5155 173.11 28.3477Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.11 28.3477C173.11 31.1799 170.818 33.475 167.991 33.475C165.163 33.475 162.871 31.1799 162.871 28.3477C162.871 25.5155 165.163 23.2205 167.991 23.2205C170.818 23.2205 173.11 25.5155 173.11 28.3477Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 55.6947C190.174 58.5236 187.883 60.8187 185.055 60.8187C182.227 60.8187 179.936 58.5236 179.936 55.6947C179.936 52.8625 182.227 50.5674 185.055 50.5674C187.883 50.5674 190.174 52.8625 190.174 55.6947Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 55.6947C190.174 58.5236 187.883 60.8187 185.055 60.8187C182.227 60.8187 179.936 58.5236 179.936 55.6947C179.936 52.8625 182.227 50.5674 185.055 50.5674C187.883 50.5674 190.174 52.8625 190.174 55.6947Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.11 55.6947C173.11 58.5236 170.818 60.8187 167.991 60.8187C165.163 60.8187 162.871 58.5236 162.871 55.6947C162.871 52.8625 165.163 50.5674 167.991 50.5674C170.818 50.5674 173.11 52.8625 173.11 55.6947Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.11 55.6947C173.11 58.5236 170.818 60.8187 167.991 60.8187C165.163 60.8187 162.871 58.5236 162.871 55.6947C162.871 52.8625 165.163 50.5674 167.991 50.5674C170.818 50.5674 173.11 52.8625 173.11 55.6947Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 83.0385C190.174 85.8707 187.883 88.1658 185.055 88.1658C182.227 88.1658 179.936 85.8707 179.936 83.0385C179.936 80.2063 182.227 77.9113 185.055 77.9113C187.883 77.9113 190.174 80.2063 190.174 83.0385Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.174 83.0385C190.174 85.8707 187.883 88.1658 185.055 88.1658C182.227 88.1658 179.936 85.8707 179.936 83.0385C179.936 80.2063 182.227 77.9113 185.055 77.9113C187.883 77.9113 190.174 80.2063 190.174 83.0385Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.11 83.0385C173.11 85.8707 170.818 88.1658 167.991 88.1658C165.163 88.1658 162.871 85.8707 162.871 83.0385C162.871 80.2063 165.163 77.9113 167.991 77.9113C170.818 77.9113 173.11 80.2063 173.11 83.0385Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.11 83.0385C173.11 85.8707 170.818 88.1658 167.991 88.1658C165.163 88.1658 162.871 85.8707 162.871 83.0385C162.871 80.2063 165.163 77.9113 167.991 77.9113C170.818 77.9113 173.11 80.2063 173.11 83.0385Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.185 96H111.815C108.047 96 105 92.9786 105 89.25V69H197V89.25C197 92.9786 193.946 96 190.185 96Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.85 69.364H196.999V42.0217H104.85V69.364Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M197 42H105V21.75C105 18.0214 108.05 15 111.815 15H190.185C193.95 15 197 18.0214 197 21.75V42Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.6106 96.7112H16.113C12.3425 96.7112 9.28711 93.6512 9.28711 89.8749V21.5112C9.28711 17.7349 12.3425 14.6748 16.113 14.6748H94.6106C98.3811 14.6748 101.437 17.7349 101.437 21.5112V89.8749C101.437 93.6512 98.3811 96.7112 94.6106 96.7112Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.436 23.2203H9.28613V21.5144C9.28613 17.7349 12.3448 14.6748 16.112 14.6748H94.6097C98.3834 14.6748 101.436 17.7349 101.436 21.5144V23.2203Z"
      fill="white"
    />
    <path
      d="M20 28H40"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.6106 96.7122H16.113C12.3425 96.7122 9.28711 93.6521 9.28711 89.8758V69.3667H101.437V89.8758C101.437 93.6521 98.3811 96.7122 94.6106 96.7122Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 69H101V42H9V69Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101 42H9V21.75C9 18.0214 12.0537 15 15.8148 15H94.1852C97.9528 15 101 18.0214 101 21.75V42Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 110.385H63.8942C60.1238 110.385 57.0684 107.325 57.0684 103.546V7.83963C57.0684 4.06335 60.1238 1 63.8942 1H142.392C146.159 1 149.218 4.06335 149.218 7.83963V103.546C149.218 107.325 146.159 110.385 142.392 110.385Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.217 9.54669H57.0674V7.84085C57.0674 4.06131 60.126 1.00122 63.8933 1.00122H142.391C146.161 1.00122 149.217 4.06131 149.217 7.84085V9.54669Z"
      fill="white"
    />
    <path
      d="M67 97H87"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67 69H87"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67 15H87"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 14.6746C142.392 17.5069 140.101 19.8019 137.273 19.8019C134.445 19.8019 132.153 17.5069 132.153 14.6746C132.153 11.8424 134.445 9.54736 137.273 9.54736C140.101 9.54736 142.392 11.8424 142.392 14.6746Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 14.6746C142.392 17.5069 140.101 19.8019 137.273 19.8019C134.445 19.8019 132.153 17.5069 132.153 14.6746C132.153 11.8424 134.445 9.54736 137.273 9.54736C140.101 9.54736 142.392 11.8424 142.392 14.6746Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 14.6746C125.328 17.5069 123.036 19.8019 120.208 19.8019C117.38 19.8019 115.089 17.5069 115.089 14.6746C115.089 11.8424 117.38 9.54736 120.208 9.54736C123.036 9.54736 125.328 11.8424 125.328 14.6746Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 14.6746C125.328 17.5069 123.036 19.8019 120.208 19.8019C117.38 19.8019 115.089 17.5069 115.089 14.6746C115.089 11.8424 117.38 9.54736 120.208 9.54736C123.036 9.54736 125.328 11.8424 125.328 14.6746Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 69.3652C142.392 72.1974 140.101 74.4925 137.273 74.4925C134.445 74.4925 132.153 72.1974 132.153 69.3652C132.153 66.5363 134.445 64.2412 137.273 64.2412C140.101 64.2412 142.392 66.5363 142.392 69.3652Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 69.3652C142.392 72.1974 140.101 74.4925 137.273 74.4925C134.445 74.4925 132.153 72.1974 132.153 69.3652C132.153 66.5363 134.445 64.2412 137.273 64.2412C140.101 64.2412 142.392 66.5363 142.392 69.3652Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 69.3652C125.328 72.1974 123.036 74.4925 120.208 74.4925C117.38 74.4925 115.089 72.1974 115.089 69.3652C115.089 66.5363 117.38 64.2412 120.208 64.2412C123.036 64.2412 125.328 66.5363 125.328 69.3652Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 69.3652C125.328 72.1974 123.036 74.4925 120.208 74.4925C117.38 74.4925 115.089 72.1974 115.089 69.3652C115.089 66.5363 117.38 64.2412 120.208 64.2412C123.036 64.2412 125.328 66.5363 125.328 69.3652Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 96.7115C142.392 99.5437 140.101 101.839 137.273 101.839C134.445 101.839 132.153 99.5437 132.153 96.7115C132.153 93.8793 134.445 91.5842 137.273 91.5842C140.101 91.5842 142.392 93.8793 142.392 96.7115Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 96.7115C142.392 99.5437 140.101 101.839 137.273 101.839C134.445 101.839 132.153 99.5437 132.153 96.7115C132.153 93.8793 134.445 91.5842 137.273 91.5842C140.101 91.5842 142.392 93.8793 142.392 96.7115Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 96.7115C125.328 99.5437 123.036 101.839 120.208 101.839C117.38 101.839 115.089 99.5437 115.089 96.7115C115.089 93.8793 117.38 91.5842 120.208 91.5842C123.036 91.5842 125.328 93.8793 125.328 96.7115Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 96.7115C125.328 99.5437 123.036 101.839 120.208 101.839C117.38 101.839 115.089 99.5437 115.089 96.7115C115.089 93.8793 117.38 91.5842 120.208 91.5842C123.036 91.5842 125.328 93.8793 125.328 96.7115Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.185 111H63.8148C60.0504 111 57 107.867 57 103.997V83H149V103.997C149 107.867 145.946 111 142.185 111Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.0684 83.0371H149.218V55.6948H57.0684V83.0371Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.217 28.3482H57.0674V7.83906C57.0674 4.06278 60.126 1.00269 63.8933 1.00269H142.391C146.161 1.00269 149.217 4.06278 149.217 7.83906V28.3482Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67 42H87"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 42.0216C142.392 44.8505 140.101 47.1488 137.273 47.1488C134.445 47.1488 132.153 44.8505 132.153 42.0216C132.153 39.1894 134.445 36.8943 137.273 36.8943C140.101 36.8943 142.392 39.1894 142.392 42.0216Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.392 42.0216C142.392 44.8505 140.101 47.1488 137.273 47.1488C134.445 47.1488 132.153 44.8505 132.153 42.0216C132.153 39.1894 134.445 36.8943 137.273 36.8943C140.101 36.8943 142.392 39.1894 142.392 42.0216Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 42.0216C125.328 44.8505 123.036 47.1488 120.208 47.1488C117.38 47.1488 115.089 44.8505 115.089 42.0216C115.089 39.1894 117.38 36.8943 120.208 36.8943C123.036 36.8943 125.328 39.1894 125.328 42.0216Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.328 42.0216C125.328 44.8505 123.036 47.1488 120.208 47.1488C117.38 47.1488 115.089 44.8505 115.089 42.0216C115.089 39.1894 117.38 36.8943 120.208 36.8943C123.036 36.8943 125.328 39.1894 125.328 42.0216Z"
      stroke="#9BABC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.0684 55.6953H149.218V28.3499H57.0684V55.6953Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.644 141C145.381 141 180.026 138.311 180.026 134.994C180.026 131.678 145.381 128.989 102.644 128.989C59.9069 128.989 25.2617 131.678 25.2617 134.994C25.2617 138.311 59.9069 141 102.644 141Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.1899 88.3686C72.1899 108.108 56.2535 124.113 36.595 124.113C16.9365 124.113 1 108.108 1 88.3686C1 68.6291 16.9365 52.627 36.595 52.627C56.2535 52.627 72.1899 68.6291 72.1899 88.3686Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.1899 88.3686C72.1899 108.108 56.2535 124.113 36.595 124.113C16.9365 124.113 1 108.108 1 88.3686C1 68.6291 16.9365 52.627 36.595 52.627C56.2535 52.627 72.1899 68.6291 72.1899 88.3686Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4522 88.3687C64.4522 103.817 51.9814 116.34 36.5935 116.34C21.2081 116.34 8.7373 103.817 8.7373 88.3687C8.7373 72.9199 21.2081 60.3977 36.5935 60.3977C51.9814 60.3977 64.4522 72.9199 64.4522 88.3687Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5944 68.1668C50.6648 68.1668 62.264 78.6479 64.1532 92.2537C64.3301 90.9796 64.4531 89.6901 64.4531 88.3697C64.4531 72.9209 51.9798 60.3961 36.5944 60.3961C21.2091 60.3961 8.73828 72.9209 8.73828 88.3697C8.73828 89.6901 8.85876 90.9796 9.0382 92.2537C10.9248 78.6479 22.5267 68.1668 36.5944 68.1668Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4522 88.3687C64.4522 103.817 51.9814 116.34 36.5935 116.34C21.2081 116.34 8.7373 103.817 8.7373 88.3687C8.7373 72.9199 21.2081 60.3977 36.5935 60.3977C51.9814 60.3977 64.4522 72.9199 64.4522 88.3687Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.249 72.4947C33.249 70.5646 34.8136 69 36.7437 69C38.6738 69 40.2384 70.5646 40.2384 72.4947V94.5053C40.2384 96.4354 38.6738 98 36.7437 98C34.8136 98 33.249 96.4354 33.249 94.5053V72.4947Z"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M34.2471 72.4962C34.2471 71.1176 35.3647 70 36.7433 70C38.1219 70 39.2395 71.1176 39.2395 72.4962V76L34.2471 79V72.4962Z"
      fill="#E8F4FA"
    />
    <ellipse
      cx="36.7437"
      cy="104.5"
      rx="3.49468"
      ry="3.5"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M39.2395 104.083C39.2395 104.5 38.1219 103.975 36.7433 103.975C35.3647 103.975 34.2471 104.5 34.2471 104.083C34.2471 102.932 35.3647 102 36.7433 102C38.1219 102 39.2395 102.932 39.2395 104.083Z"
      fill="#E8F4FA"
    />
  </svg>
);
