import React from 'react';
import { connect } from 'react-redux';
import { canUserAccessFeature } from 'store/permissions/helpers';
import { ActivityRightValue } from 'store/permissions/types';
import {
  KnoxFeatures,
  LiveDealsFeatures,
  PlatformFeatures,
} from 'store/features/types';

type Features = KnoxFeatures[] | LiveDealsFeatures[] | PlatformFeatures[];

interface IHasPermissionsProps {
  forActivityRights?: ActivityRightValue[];
  forFeatures?: Features;
  userActivityRights?: ActivityRightValue[];
  canUserAccess: boolean;
  children: React.ReactNode;
  noAccessComponent?: React.ReactNode;
}

class HasPermissions extends React.Component<IHasPermissionsProps> {
  public render() {
    const { canUserAccess, children, noAccessComponent } = this.props;
    const NoAccessComponent = noAccessComponent as any;

    if (canUserAccess) {
      return children;
    }

    if (!canUserAccess && noAccessComponent) {
      return <NoAccessComponent />;
    }

    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const userAcceptedActivityRights = ownProps.userActivityRights;
  const acceptedActivityRights = ownProps.forActivityRights || [];
  const featureFlags = ownProps.forFeatures || [];
  const file = ownProps.file || null;

  const canUserAccess = canUserAccessFeature({
    state,
    featureFlags,
    acceptedActivityRights,
    file,
    userAcceptedActivityRights,
  });

  return {
    canUserAccess,
  };
}

export default connect(mapStateToProps)(HasPermissions);
