import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

type ClarityFirstIconColors = 'blue' | 'gray';

interface IClarityFirstIconProps extends ISVGProps {
  color?: ClarityFirstIconColors;
}

const getColors = (color: ClarityFirstIconColors) =>
  color === 'blue' ? '#345580' : '#C4C4C4';

export default ({ style, color = 'blue' }: IClarityFirstIconProps) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7998 0H19.1998C21.8508 0 23.9998 2.15729 23.9998 4.81844V19.2738H19.1998V9.63688V4.81844H14.3998H4.7998V0Z"
      fill={getColors(color)}
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 4.81787H0V19.2732C0 21.9343 2.14903 24.0916 4.8 24.0916H19.2V19.2732H4.8V4.81787Z"
      fill={getColors(color)}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1998 19.2732H4.7998V4.81787H14.3998H19.1998V9.63631V19.2732ZM14.4028 13.8538H7.20277V15.0584H14.4028V13.8538ZM7.20277 11.444H16.8028V12.6486H7.20277V11.444ZM16.8028 9.03417H7.20277V10.2388H16.8028V9.03417Z"
      fill={getColors(color)}
    />
  </svg>
);
