import React from 'react';
import styled from 'styled-components';
import { white, shadow2 } from 'config/theme';
import { IAppNotification } from 'store/appNotifications/types';
import { IPagination } from 'store/common/types';
import Pagination from 'components/Pagination';
import { NotificationRow, NotificationRowTheme } from './index';

interface INotificationsListProps {
  keyName: string;
  notifications: IAppNotification[];
  pagination: IPagination;
  onPaginationPageClick: (n: number) => void;
}

export default class NotificationsList extends React.Component<
  INotificationsListProps
> {
  public render() {
    const {
      keyName,
      notifications,
      pagination: { page, pages },
      onPaginationPageClick,
    } = this.props;

    return (
      <Container id="notificationsList">
        {notifications.map((notification: IAppNotification, idx: number) => (
          <NotificationRow
            key={`notificationRow${idx}${keyName || ''}`}
            notification={notification}
            theme={NotificationRowTheme.LARGE}
          />
        ))}

        {pages > 1 && (
          <PaginationContainer>
            <Pagination
              domId="notificationsPagination"
              page={page}
              pages={pages}
              onPageClick={onPaginationPageClick}
            />
          </PaginationContainer>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  background: ${white};
  border-radius: 3px;
  box-shadow: ${shadow2};
  margin-top: 30px;
`;

const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  height: 74px;
  justify-content: center;
  padding: 0 30px;
  & > div {
    margin-top: 0;
    width: 100%;
  }
`;
