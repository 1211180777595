import React, { Fragment } from 'react';
import FeatureToggle from './FeatureToggle';
import NotFound from 'views/NotFound';

interface IOptions {
  ToggledOffComponent: React.ComponentType<any>;
}

const defaultOpts: IOptions = {
  ToggledOffComponent: NotFound,
};

export default (flag: string) => (
  WrappedComponent,
  options: IOptions = defaultOpts
) => {
  const { ToggledOffComponent } = options;

  return (props: any) => (
    <Fragment>
      <FeatureToggle flag={flag}>
        <WrappedComponent {...props} />
      </FeatureToggle>
      <FeatureToggle flag={flag} off>
        <ToggledOffComponent />
      </FeatureToggle>
    </Fragment>
  );
};
