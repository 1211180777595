import styled from 'styled-components';
import {
  blue,
  extremeBlack,
  forestGreen,
  lightBlue,
  linkWater,
  persianRed,
  sun,
  trout,
  white,
} from 'config/theme';
import Portal from 'components/portals';

export const Block = styled.div<{ width?: number }>`
  align-self: stretch;
  align-items: center;
  display: flex;
  margin-right: 24px;
  padding-right: 24px;
  height: 55px;
  ${({ width }) => (width ? `width: ${width}px;` : '')}

  &:not(:last-child) {
    border-right: 1px solid ${linkWater};
  }

  a {
    color: ${lightBlue};
  }
`;

Block.displayName = 'Block';

export const BlockContent = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 19px;

  svg {
    height: 16px;
    margin-right: 6px;
    margin-top: 2px;
    width: 16px;
  }
`;

export const LightText = styled.span`
  color: ${trout};
`;

LightText.displayName = 'LightText';

export const ModalTitle = styled.h2`
  color: ${blue};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 10px;
  padding-left: 50px;
`;
ModalTitle.displayName = 'ModalTitle';

export const ModalListItem = styled.li`
  border-top: 1px solid ${linkWater};
  padding: 17px 50px;

  &:last-child {
    border-bottom: 1px solid ${linkWater};
  }

  svg {
    margin-right: 10px;
    vertical-align: top;
  }
`;

export const StatusIcon = styled.div`
  height: 16px;
  text-align: center;
  padding-right: 4px;
`;

StatusIcon.displayName = 'StatusIcon';

export const StatusText = styled.div`
  font-weight: 500;
  margin-right: 30px;
  display: flex;
  padding-top: 5px;

  &.open {
    color: ${forestGreen};
  }

  &.closed {
    color: ${blue};
  }

  &.pending {
    color: ${trout};
  }

  &.cancelled {
    color: ${sun};
  }

  &.openInError {
    color: ${persianRed};
  }
`;
StatusText.displayName = 'StatusText';

export const FastButton = styled.a`
  border-radius: 4px;
  background: ${white};
  color: ${blue};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 22px;
  padding: 0 7px;
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid ${blue};
  margin-left: 12px;

  &:hover {
    background-color: ${blue};
    color: ${white};
  }

  &:active {
    background-color: ${blue};
  }

  svg {
    margin-top: 4px;
    height: 12px;
    width: 12px;
    margin-left: 5px;
    margin-right: 0px;
  }
`;

FastButton.displayName = 'fastButton';

export const FastFileNumber = styled.span`
  height: 22px;
  line-height: 22px;
  letter-spacing: 0.004em;
  font-size: 12px;
`;

export const Title = styled.div`
  color: ${extremeBlack};
  line-height: 32px;
  margin: 0;
  margin-left: 12px;
  white-space: nowrap;
`;
Title.displayName = 'Title';

export const AddressContainer = styled.div`
  white-space: nowrap;
  a {
    color: ${lightBlue};
    cursor: pointer;
  }
`;

export const AddressListItemContainer = styled.ul`
  min-height: 350px;
  overflow-y: auto;
  max-height: calc(100vh - 240px);
`;
AddressListItemContainer.displayName = 'AddressListItemContainer';

export const PropertyAddressPortal = styled(Portal)`
  margin-top: 23px;
  & > h2 {
    padding-left: 25px;
  }
  & > ul {
    font-size: 16px;
  }
  & > ul > li {
    padding: 17px 50px;
  }
`;

export const StyledOrderNumber = styled.span`
  font-family: 'kanitmedium';
`;
