import { IBulkUpdateStatus } from './types';

export const buildBulkUpdatesPollingList = (
  jobList: Array<Omit<IBulkUpdateStatus, 'orderNumber'>>,
  getOrderNumberByOrderId: (orderId) => string | undefined
): IBulkUpdateStatus[] => {
  const pollingList: IBulkUpdateStatus[] = [];

  jobList.forEach(({ jobId, orderId, state }) => {
    pollingList.push({
      orderId,
      jobId,
      orderNumber: getOrderNumberByOrderId(orderId) || '',
      state,
    });
  });
  return pollingList;
};
