import {
  FeaturesTypeKeys,
  FeaturesAction,
  IFeaturesState,
  IFeatureComponent,
  IFeatureToggle,
} from './types';

export const initialState: IFeaturesState = {
  error: null,
  loading: false,
  components: [],
  features: [],
  userFeatures: [],
  component: {} as IFeatureComponent,
  feature: {} as IFeatureToggle,
  componentFeatures: [] as IFeatureComponent[],
  featureGroups: [] as IFeatureToggle[],
};

export default (state = initialState, action: FeaturesAction) => {
  switch (action.type) {
    case FeaturesTypeKeys.REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FeaturesTypeKeys.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FeaturesTypeKeys.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FeaturesTypeKeys.FETCH_COMPONENTS:
      return {
        ...state,
        components: action.payload,
        loading: false,
      };
    case FeaturesTypeKeys.FETCH_FEATURES:
      return {
        ...state,
        features: action.payload,
        loading: false,
      };
    case FeaturesTypeKeys.TOGGLE_FEATURE_GROUP:
      return {
        ...state,
        features: state.features.map((feature) =>
          feature.id === action.payload.id
            ? { ...feature, isEnabled: action.payload.isEnabled }
            : feature
        ),
        loading: false,
      };
    case FeaturesTypeKeys.FETCH_USER_FEATURES:
      return {
        ...state,
        userFeatures: action.payload,
        loading: false,
      };
    case FeaturesTypeKeys.FETCH_COMPONENT_FEATURES:
      return {
        ...state,
        componentFeatures: action.payload,
        loading: false,
      };
    case FeaturesTypeKeys.FETCH_FEATURE_GROUPS:
      return {
        ...state,
        featureGroups: action.payload,
        loading: false,
      };
    case FeaturesTypeKeys.TOGGLE_GROUP_UNDER_FEATURE:
      return {
        ...state,
        featureGroups: state.featureGroups.map((group) =>
          group.id === action.payload.id
            ? { ...group, isEnabled: action.payload.isEnabled }
            : group
        ),
        loading: false,
      };
    default:
      return state;
  }
};
