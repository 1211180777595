import {
  red,
  white,
  forestGreen,
  lightGreen,
  persianRed,
  yellow,
  blue,
} from 'config/theme';
import { InfoIcon, WarningIcon } from 'components/svg/legacyIcons_DO_NOT_USE';
import React from 'react';

export default {
  alert: {
    backgroundColor: red,
    icon: 'error',
    textColor: white,
  },

  success: {
    backgroundColor: forestGreen,
    icon: 'check-circle',
    textColor: white,
  },

  animated: {
    backgroundColor: lightGreen,
    textColor: white,
    className: 'animatedNotification',
    animated: true,
  },

  animatedError: {
    backgroundColor: red,
    icon: 'error',
    textColor: white,
    className: 'animatedNotification',
  },

  error: {
    backgroundColor: persianRed,
    textColor: white,
    className: 'animatedNotification',
    customIcon: <InfoIcon style={{ fill: white, width: 32, height: 32 }} />,
  },

  warning: {
    backgroundColor: yellow,
    textColor: blue,
    className: 'animatedNotification',
    customIcon: <InfoIcon style={{ fill: blue, width: 32, height: 32 }} />,
  },

  documentError: {
    backgroundColor: persianRed,
    textColor: white,
    className: 'animatedNotification',
    customIcon: <WarningIcon style={{ fill: white, width: 30, height: 20 }} />,
  },

  animatedSuccess: {
    backgroundColor: lightGreen,
    textColor: white,
    className: 'animatedNotification',
    animated: true,
  },
};
