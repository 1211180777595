import { IPagination } from 'store/common/types';
import {
  AppNotificationAction,
  AppNotificationTypeKeys,
  IAppNotification,
  IAppNotificationsState,
} from './types';

const initialPagination: IPagination = {
  limit: 10,
  page: 1,
  pages: 0,
  total: 0,
};

export const initialState: IAppNotificationsState = {
  counter: 0,
  error: null,
  notifications: [] as IAppNotification[],
  pagination: initialPagination,
  latest: {
    notifications: [] as IAppNotification[],
    pagination: initialPagination,
  },
  taskWithCommentId: null,
};

export default (
  state: IAppNotificationsState = initialState,
  action: AppNotificationAction
): IAppNotificationsState => {
  switch (action.type) {
    case AppNotificationTypeKeys.ERROR:
      return {
        ...state,
        error: action.error,
      };

    case AppNotificationTypeKeys.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        pagination: action.pagination,
      };

    case AppNotificationTypeKeys.SET_LATEST_NOTIFICATIONS:
      return {
        ...state,
        latest: {
          ...state.latest,
          notifications: action.notifications,
          pagination: action.pagination,
        },
      };

    case AppNotificationTypeKeys.MARK_AS_READ: {
      const notifications = state.notifications.map((n: IAppNotification) =>
        action.notificationId === n.id ? { ...n, visited: true } : n
      );
      const latestNotifications = state.latest.notifications.map(
        (n: IAppNotification) =>
          action.notificationId === n.id ? { ...n, visited: true } : n
      );

      return {
        ...state,
        notifications,
        latest: {
          ...state.latest,
          notifications: latestNotifications,
        },
      };
    }

    case AppNotificationTypeKeys.SET_COUNTER:
      return {
        ...state,
        counter: action.counter,
      };

    case AppNotificationTypeKeys.SET_TASK_WITH_COMMENT_NOTIFICATION:
      return {
        ...state,
        taskWithCommentId: action.taskWithCommentId,
      };

    default:
      return { ...state };
  }
};
