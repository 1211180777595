import { AsyncAction } from 'store';
import workspaceAPI from 'services/workspaceAPI';
import {
  AppNotificationTypeKeys,
  AppNotificationsLoadingKeys,
  IAppNotification,
  IAppNotificationErrorAction,
  IAppNotificationMarkAsReadAction,
  IAppNotificationSetNotificationsAction,
  IAppNotificationSetLatestNotificationsAction,
} from './types';
import { AxiosError } from 'axios';
import { setLoadingStateAction } from 'store/ui/actions';
import { IPagination } from 'store/common/types';

function setError(error: AxiosError | null): IAppNotificationErrorAction {
  return {
    type: AppNotificationTypeKeys.ERROR,
    error,
  };
}

function setAppNotifications(
  notifications: IAppNotification[],
  pagination: IPagination
): IAppNotificationSetNotificationsAction {
  return {
    type: AppNotificationTypeKeys.SET_NOTIFICATIONS,
    notifications,
    pagination,
  };
}

export function getAppNotifications(
  page: number = 1,
  limit: number = 20
): AsyncAction<void> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        AppNotificationsLoadingKeys.appNotificationsPageLoading,
        true
      )
    );

    await workspaceAPI
      .get(`/notifications?page=${page}&limit=${limit}`)
      .then(({ data }) => {
        const notifications = (data.notifications || []) as IAppNotification[];
        const pagination = ((data._meta && data._meta.pagination) ||
          {}) as IPagination;

        dispatch(setAppNotifications(notifications, pagination));

        if (page === 1) {
          dispatch(setLatestAppNotifications(notifications, pagination));
        }
      })
      .catch((error) => dispatch(setError(error)))
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            AppNotificationsLoadingKeys.appNotificationsPageLoading,
            false
          )
        );
      });
  };
}

function setLatestAppNotifications(
  notifications: IAppNotification[],
  pagination: IPagination
): IAppNotificationSetLatestNotificationsAction {
  return {
    type: AppNotificationTypeKeys.SET_LATEST_NOTIFICATIONS,
    notifications,
    pagination,
  };
}

export function getLatestAppNotifications() {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        AppNotificationsLoadingKeys.appNotificationsDropdownLoading,
        true
      )
    );

    return await workspaceAPI
      .get(`/notifications`)
      .then(({ data }) => {
        const notifications = (data.notifications || []) as IAppNotification[];
        const pagination = ((data._meta && data._meta.pagination) ||
          {}) as IPagination;
        dispatch(setLatestAppNotifications(notifications, pagination));
      })
      .catch((error) => dispatch(setError(error)))
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            AppNotificationsLoadingKeys.appNotificationsDropdownLoading,
            false
          )
        );
      });
  };
}

export function getLatestAppNotificationsAndResetCounter(): AsyncAction<void> {
  return async (dispatch, getState) => {
    await dispatch(getLatestAppNotifications());

    const latestNotifications: IAppNotification[] = getState().appNotifications
      .latest.notifications;
    const latestNotification = latestNotifications && latestNotifications[0];

    if (latestNotification && latestNotification.id) {
      await dispatch(updateLastViewedNotification(latestNotification.id));
      await dispatch(setNotificationsCounter(0));
    }
  };
}

export function getAppNotificationsAndResetCounter(
  page: number = 1,
  limit: number = 20
): AsyncAction<void> {
  return async (dispatch, getState) => {
    await dispatch(getAppNotifications(page, limit));

    const latestNotifications: IAppNotification[] = getState().appNotifications
      .notifications;
    const latestNotification = latestNotifications && latestNotifications[0];

    if (page === 1 && latestNotification && latestNotification.id) {
      await dispatch(updateLastViewedNotification(latestNotification.id));
      await dispatch(setNotificationsCounter(0));
    }
  };
}

function markAsRead(notificationId: number): IAppNotificationMarkAsReadAction {
  return {
    type: AppNotificationTypeKeys.MARK_AS_READ,
    notificationId,
  };
}

export function markNotificationAsRead(
  notificationId: number
): AsyncAction<void> {
  return async (dispatch) => {
    await workspaceAPI
      .put(`/notifications/${notificationId}/visited`)
      .then(() => {
        dispatch(markAsRead(notificationId));
      })
      .catch((error) => {
        dispatch(setError(error));
      });
  };
}

export function setNotificationsCounter(counter: number) {
  return (dispatch) => {
    dispatch({
      type: AppNotificationTypeKeys.SET_COUNTER,
      counter,
    });
  };
}

export function updateLastViewedNotification(
  notificationId: number
): AsyncAction<void> {
  return async (dispatch) => {
    await workspaceAPI
      .put('/notifications/viewed', { lastViewedId: notificationId })
      .catch((error) => dispatch(setError(error)));
  };
}

export function setTaskWithCommentNotification(
  taskWithCommentId: number | null
) {
  return (dispatch) => {
    dispatch({
      type: AppNotificationTypeKeys.SET_TASK_WITH_COMMENT_NOTIFICATION,
      taskWithCommentId,
    });
  };
}
