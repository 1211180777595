import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M56.4305 99.36C75.9649 99.36 91.8005 97.9094 91.8005 96.12C91.8005 94.3306 75.9649 92.88 56.4305 92.88C36.8962 92.88 21.0605 94.3306 21.0605 96.12C21.0605 97.9094 36.8962 99.36 56.4305 99.36Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.644 8.64136H73.4786L89.0124 24.2004V80.6103C89.0124 81.6847 88.1441 82.5546 87.0715 82.5546H34.644C33.5715 82.5546 32.7031 81.6847 32.7031 80.6103V10.5872C32.7031 9.51282 33.5715 8.64136 34.644 8.64136Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.014 24.2007L73.4802 8.6416H34.6457C33.5731 8.6416 32.7031 9.51145 32.7031 10.5859V18.3662C32.7031 17.2918 33.5731 16.4203 34.6457 16.4203H73.4802V22.2564C73.4802 23.3308 74.3502 24.2007 75.4227 24.2007H89.014Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.644 8.64136H73.4786L89.0124 24.2004V80.6103C89.0124 81.6847 88.1441 82.5546 87.0715 82.5546H34.644C33.5715 82.5546 32.7031 81.6847 32.7031 80.6103V10.5872C32.7031 9.51282 33.5715 8.64136 34.644 8.64136Z"
      stroke="#9BABC2"
      strokeWidth="2.16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.0162 24.2007L73.4824 8.64001V22.2564C73.4824 23.3308 74.3524 24.2007 75.425 24.2007H89.0162Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.0162 24.2007L73.4824 8.64001V22.2564C73.4824 23.3308 74.3524 24.2007 75.425 24.2007H89.0162Z"
      stroke="#9BABC2"
      strokeWidth="2.16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.8581 66.9932C60.8581 79.3467 50.8609 89.3629 38.5287 89.3629C26.1965 89.3629 16.1992 79.3467 16.1992 66.9932C16.1992 54.6397 26.1965 44.6251 38.5287 44.6251C50.8609 44.6251 60.8581 54.6397 60.8581 66.9932Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.8581 66.9932C60.8581 79.3467 50.8609 89.3629 38.5287 89.3629C26.1965 89.3629 16.1992 79.3467 16.1992 66.9932C16.1992 54.6397 26.1965 50.0251 38.5287 50.0251C50.8609 50.0251 60.8581 54.6397 60.8581 66.9932Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.8581 66.9932C60.8581 79.3467 50.8609 89.3629 38.5287 89.3629C26.1965 89.3629 16.1992 79.3467 16.1992 66.9932C16.1992 54.6397 26.1965 44.6251 38.5287 44.6251C50.8609 44.6251 60.8581 54.6397 60.8581 66.9932Z"
      stroke="#9BABC2"
      strokeWidth="2.16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0077 66.9931C56.0077 76.6613 48.1845 84.4981 38.5314 84.4981C28.8798 84.4981 21.0566 76.6613 21.0566 66.9931C21.0566 57.3249 28.8798 49.4882 38.5314 49.4882C48.1845 49.4882 56.0077 57.3249 56.0077 66.9931Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5314 54.3504C47.358 54.3504 54.6345 60.9097 55.8196 69.4246C55.9305 68.6273 56.0077 67.8202 56.0077 66.9939C56.0077 57.3256 48.1829 49.4873 38.5314 49.4873C28.8798 49.4873 21.0566 57.3256 21.0566 66.9939C21.0566 67.8202 21.1322 68.6273 21.2448 69.4246C22.4283 60.9097 29.7064 54.3504 38.5314 54.3504Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0077 66.9931C56.0077 76.6613 48.1845 84.4981 38.5314 84.4981C28.8798 84.4981 21.0566 76.6613 21.0566 66.9931C21.0566 57.3249 28.8798 49.4882 38.5314 49.4882C48.1845 49.4882 56.0077 57.3249 56.0077 66.9931Z"
      stroke="#9BABC2"
      strokeWidth="2.16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5 18.9C40.5 18.6018 40.7418 18.36 41.04 18.36H65.34C65.6382 18.36 65.88 18.6018 65.88 18.9C65.88 19.1982 65.6382 19.44 65.34 19.44H41.04C40.7418 19.44 40.5 19.1982 40.5 18.9Z"
      fill="#9BABC2"
    />
    <path
      d="M40.5 25.38C40.5 25.0817 40.7418 24.84 41.04 24.84H65.34C65.6382 24.84 65.88 25.0817 65.88 25.38C65.88 25.6782 65.6382 25.92 65.34 25.92H41.04C40.7418 25.92 40.5 25.6782 40.5 25.38Z"
      fill="#9BABC2"
    />
    <path
      d="M40.5 32.4C40.5 32.1018 40.7418 31.86 41.04 31.86H79.92C80.2182 31.86 80.46 32.1018 80.46 32.4C80.46 32.6982 80.2182 32.94 79.92 32.94H41.04C40.7418 32.94 40.5 32.6982 40.5 32.4Z"
      fill="#9BABC2"
    />
    <path
      d="M40.5 38.88C40.5 38.5817 40.7418 38.34 41.04 38.34H79.92C80.2182 38.34 80.46 38.5817 80.46 38.88C80.46 39.1782 80.2182 39.42 79.92 39.42H41.04C40.7418 39.42 40.5 39.1782 40.5 38.88Z"
      fill="#9BABC2"
    />
    <rect
      x="59.4004"
      y="45.36"
      width="21.06"
      height="1.08"
      rx="0.54"
      fill="#9BABC2"
    />
    <rect
      x="64.8008"
      y="52.38"
      width="15.66"
      height="1.08"
      rx="0.54"
      fill="#9BABC2"
    />
    <rect
      x="67.5"
      y="58.86"
      width="12.96"
      height="1.08"
      rx="0.54"
      fill="#9BABC2"
    />
    <rect
      x="68.0391"
      y="65.88"
      width="12.42"
      height="1.08"
      rx="0.54"
      fill="#9BABC2"
    />
    <rect
      x="67.5"
      y="72.36"
      width="12.96"
      height="1.08"
      rx="0.54"
      fill="#9BABC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.2342 78.6754H31.7572C31.2697 78.6754 30.875 78.2603 30.875 77.7481V65.745C30.875 65.2328 31.2697 64.8187 31.7572 64.8187H45.2342C45.7216 64.8187 46.1173 65.2328 46.1173 65.745V77.7491C46.1173 78.2603 45.7226 78.6754 45.2342 78.6754Z"
      fill="#BECFE6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.1023 64.8187H31.891C31.3299 64.8187 30.875 65.284 30.875 65.8577V68.9757C30.875 68.402 31.3299 67.9357 31.891 67.9357H45.1023C45.6633 67.9357 46.1173 68.402 46.1173 68.9757V65.8577C46.1173 65.284 45.6633 64.8187 45.1023 64.8187Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.2342 78.6754H31.7572C31.2697 78.6754 30.875 78.2603 30.875 77.7481V65.745C30.875 65.2328 31.2697 64.8187 31.7572 64.8187H45.2342C45.7216 64.8187 46.1173 65.2328 46.1173 65.745V77.7491C46.1173 78.2603 45.7226 78.6754 45.2342 78.6754Z"
      stroke="#9BABC2"
      strokeWidth="2.16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2578 64.8187V61.8547C32.2578 58.1346 35.0493 55.119 38.4938 55.119C41.9364 55.119 44.7288 58.1346 44.7288 61.8547V64.8187H42.2348V61.8547C42.2348 59.6227 40.5599 57.8123 38.4938 57.8123C36.4267 57.8123 34.7518 59.6227 34.7518 61.8547V64.8187H32.2578Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2578 64.8187V61.8547C32.2578 58.1346 35.0493 55.119 38.4938 55.119C41.9364 55.119 44.7288 58.1346 44.7288 61.8547V64.8187H42.2348V61.8547C42.2348 59.6227 40.5599 57.8123 38.4938 57.8123C36.4267 57.8123 34.7518 59.6227 34.7518 61.8547V64.8187H32.2578Z"
      stroke="#9BABC2"
      strokeWidth="2.16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.575 70.9149C40.575 69.8441 39.6446 68.9756 38.4976 68.9756C37.3483 68.9756 36.418 69.8441 36.418 70.9149C36.418 71.6875 36.906 72.3496 37.6063 72.6613V73.6862C37.6063 74.146 38.005 74.5182 38.4976 74.5182C38.989 74.5182 39.3877 74.146 39.3877 73.6862V72.6613C40.0869 72.3496 40.575 71.6875 40.575 70.9149Z"
      fill="#9BABC2"
    />
  </svg>
);
