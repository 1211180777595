import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="108"
    height="9"
    viewBox="0 0 108 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108 4.5C108 6.98629 83.8246 9 54 9C24.1718 9 0 6.98629 0 4.5C0 2.01672 24.1718 0 54 0C83.8246 0 108 2.01672 108 4.5Z"
      fill="#CBD6E4"
    />
  </svg>
);
