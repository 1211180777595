/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_Enums_Checklists_ChecklistsTemplateFilterByProperty } from '../models/Workspace_Common_Models_Enums_Checklists_ChecklistsTemplateFilterByProperty';
import type { Workspace_Common_Models_Enums_Checklists_ChecklistTemplateSortPropertyType } from '../models/Workspace_Common_Models_Enums_Checklists_ChecklistTemplateSortPropertyType';
import type { Workspace_Common_Models_SortDirectionType } from '../models/Workspace_Common_Models_SortDirectionType';
import type { Workspace_Common_Requests_ChecklistsOfficeView_ChecklistRequest } from '../models/Workspace_Common_Requests_ChecklistsOfficeView_ChecklistRequest';
import type { Workspace_Common_Requests_ChecklistsOfficeView_ChecklistsTemplateBulkRequest } from '../models/Workspace_Common_Requests_ChecklistsOfficeView_ChecklistsTemplateBulkRequest';
import type { Workspace_Common_Requests_ChecklistsOfficeView_ChecklistsTemplateUpdateRequest } from '../models/Workspace_Common_Requests_ChecklistsOfficeView_ChecklistsTemplateUpdateRequest';
import type { Workspace_Common_Requests_ChecklistsOfficeView_ChecklistTaskBulkRequest } from '../models/Workspace_Common_Requests_ChecklistsOfficeView_ChecklistTaskBulkRequest';
import type { Workspace_Common_Requests_ChecklistsOfficeView_ChecklistTaskUpdateRequest } from '../models/Workspace_Common_Requests_ChecklistsOfficeView_ChecklistTaskUpdateRequest';
import type { Workspace_Common_Requests_ChecklistsOfficeView_ChecklistUpdateRequest } from '../models/Workspace_Common_Requests_ChecklistsOfficeView_ChecklistUpdateRequest';
import type { Workspace_Common_Responses_ChecklistsOfficeView_ChecklistResponse } from '../models/Workspace_Common_Responses_ChecklistsOfficeView_ChecklistResponse';
import type { Workspace_Common_Responses_ChecklistsOfficeView_ChecklistsTemplateDetailResponse } from '../models/Workspace_Common_Responses_ChecklistsOfficeView_ChecklistsTemplateDetailResponse';
import type { Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTaskResponse } from '../models/Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTaskResponse';
import type { Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTemplateSearchResponse } from '../models/Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTemplateSearchResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChecklistsTemplatesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search checklists templates in an office. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTemplateSearchResponse List of checklists templates that matches the search criteria plus search meta data.
   * @throws ApiError
   */
  public getAsync({
    fastOfficeId,
    searchText,
    page,
    sortProperty,
    sortDirection,
    transactionType,
    serviceType,
    filterBy,
    pageSize,
  }: {
    /**
     * Office identifier that search should be done in its templates.
     */
    fastOfficeId: number,
    searchText?: string | null,
    page?: number,
    sortProperty?: Workspace_Common_Models_Enums_Checklists_ChecklistTemplateSortPropertyType,
    sortDirection?: Workspace_Common_Models_SortDirectionType,
    transactionType?: Array<string> | null,
    serviceType?: Array<number> | null,
    filterBy?: Workspace_Common_Models_Enums_Checklists_ChecklistsTemplateFilterByProperty,
    pageSize?: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTemplateSearchResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates',
      path: {
        'fastOfficeId': fastOfficeId,
      },
      query: {
        'SearchText': searchText,
        'Page': page,
        'SortProperty': sortProperty,
        'SortDirection': sortDirection,
        'TransactionType': transactionType,
        'ServiceType': serviceType,
        'FilterBy': filterBy,
        'PageSize': pageSize,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Office was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Create checklists template. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistsTemplateDetailResponse Checklists template was successfully created.
   * @throws ApiError
   */
  public createAsync({
    fastOfficeId,
    requestBody,
  }: {
    /**
     * Office identifier in FAST to which checklists template will be added.
     */
    fastOfficeId: number,
    /**
     * Checklist template to save in a storage.
     */
    requestBody?: Workspace_Common_Requests_ChecklistsOfficeView_ChecklistsTemplateBulkRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistsTemplateDetailResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates',
      path: {
        'fastOfficeId': fastOfficeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Access is restricted.`,
        404: `Office was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Get the specific checklists template. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistsTemplateDetailResponse ChecklistsTemplate detailed information.
   * @throws ApiError
   */
  public getAsync1({
    fastOfficeId,
    checklistsTemplateId,
  }: {
    /**
     * Office identifier in FAST to which checklists template belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistsTemplateDetailResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Checklists template was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Update existing checklists template. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateAsync({
    fastOfficeId,
    checklistsTemplateId,
    requestBody,
  }: {
    /**
     * Office identifier in FAST which checklists template will be updated.
     */
    fastOfficeId: number,
    /**
     * Checklist template Id which will be updated.
     */
    checklistsTemplateId: number,
    /**
     * Checklist template to update in a storage.
     */
    requestBody?: Workspace_Common_Requests_ChecklistsOfficeView_ChecklistsTemplateUpdateRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Access is restricted.`,
        404: `Checklists template was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Delete the specific checklists template. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteAsync({
    fastOfficeId,
    checklistsTemplateId,
  }: {
    /**
     * Office identifier in FAST to which checklists template belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Checklists template was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Check if template name duplicate exists. (Auth)
   * @returns boolean Checklists template name either exists or not.
   * @throws ApiError
   */
  public checkDuplicateNameAsync({
    fastOfficeId,
    checklistsTemplateName,
  }: {
    /**
     * Office identifier in FAST to which checklists template belong.
     */
    fastOfficeId: number,
    /**
     * Checklist template name.
     */
    checklistsTemplateName: string,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/duplicate',
      path: {
        'fastOfficeId': fastOfficeId,
      },
      query: {
        'checklistsTemplateName': checklistsTemplateName,
      },
      errors: {
        404: `Office was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Get the specific checklist. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistResponse Checklist for the specific checklists template.
   * @throws ApiError
   */
  public getChecklistAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
  }: {
    /**
     * Office identifier in FAST to which checklists template and checklist belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists/{checklistId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Checklist was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Delete the specific checklist. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteChecklistAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
  }: {
    /**
     * Office identifier in FAST to which checklists template and checklist belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists/{checklistId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Checklist was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Create checklist for the specific checklists template. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistResponse Checklist was successfully created.
   * @throws ApiError
   */
  public createChecklistAsync({
    fastOfficeId,
    checklistsTemplateId,
    requestBody,
  }: {
    /**
     * Office identifier in FAST to which checklists template and checklist belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist to save in a storage.
     */
    requestBody?: Workspace_Common_Requests_ChecklistsOfficeView_ChecklistRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Access is restricted.`,
        404: `Checklists template was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Update existing checklist. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateChecklistAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
    requestBody,
  }: {
    /**
     * Office identifier in FAST to which checklists template and checklist belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist Id for the checklist to be updated.
     */
    checklistId: number,
    /**
     * Checklist to update in a storage.
     */
    requestBody?: Workspace_Common_Requests_ChecklistsOfficeView_ChecklistUpdateRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklist/{checklistId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Access is restricted.`,
        404: `Checklist was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Get the specific checklist task. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTaskResponse Checklist task for the specific checklist.
   * @throws ApiError
   */
  public getChecklistTaskAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
    checklistTaskId,
  }: {
    /**
     * Office identifier in FAST to which checklists template, checklist and checklist task belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task identifier.
     */
    checklistTaskId: number,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTaskResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists/{checklistId}/checklist-tasks/{checklistTaskId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Checklist task was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Update an existing checklist task. (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateChecklistTaskAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
    checklistTaskId,
    requestBody,
  }: {
    /**
     * Office identifier in FAST to which checklists template, checklist and checklist task belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task identifier.
     */
    checklistTaskId: number,
    /**
     * Checklist task to update in a storage.
     */
    requestBody?: Workspace_Common_Requests_ChecklistsOfficeView_ChecklistTaskUpdateRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists/{checklistId}/checklist-tasks/{checklistTaskId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Access is restricted.`,
        404: `Checklist task was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Delete the specific checklist task. (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteChecklistTaskAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
    checklistTaskId,
  }: {
    /**
     * Office identifier in FAST to which checklists template, checklist and checklist task belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task identifier.
     */
    checklistTaskId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists/{checklistId}/checklist-tasks/{checklistTaskId}',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
        'checklistTaskId': checklistTaskId,
      },
      errors: {
        403: `Access is restricted.`,
        404: `Checklist task was not found.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

  /**
   * Create checklist task for the specific checklist. (Auth)
   * @returns Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTaskResponse Checklist task was successfully created.
   * @throws ApiError
   */
  public createChecklistTaskAsync({
    fastOfficeId,
    checklistsTemplateId,
    checklistId,
    requestBody,
  }: {
    /**
     * Office identifier in FAST to which checklists template, checklist and checklist task belong.
     */
    fastOfficeId: number,
    /**
     * Checklists template identifier.
     */
    checklistsTemplateId: number,
    /**
     * Checklist identifier.
     */
    checklistId: number,
    /**
     * Checklist task to save in a storage
     */
    requestBody?: Workspace_Common_Requests_ChecklistsOfficeView_ChecklistTaskBulkRequest,
  }): CancelablePromise<Workspace_Common_Responses_ChecklistsOfficeView_ChecklistTaskResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/offices/{fastOfficeId}/checklists-templates/{checklistsTemplateId}/checklists/{checklistId}/checklist-tasks',
      path: {
        'fastOfficeId': fastOfficeId,
        'checklistsTemplateId': checklistsTemplateId,
        'checklistId': checklistId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `Access is restricted.`,
        404: `Checklist was not found.`,
        422: `Request object validation failed.`,
        500: `Unexpected error has occurred.`,
      },
    });
  }

}
