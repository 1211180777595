import { AxiosError } from 'axios';

export enum MilestoneTypeKeys {
  SET_ERROR = 'milestones/SET_ERROR',
  SET_LOADING = 'milestones/SET_LOADING',
  SET_MILESTONES = 'milestones/SET_MILESTONES',
}

export enum MilestoneDateState {
  completed = 'completed',
  estimated = 'estimated',
}

export enum OrderMilestones {
  ORDER_OPENED = 'Order Opened',
  EARNEST_MONEY_RECEIVED = 'Earnest Money Received',
  TITLE_PRODUCT_AVAILABLE = 'Title Product Available',
  CLOSING_DATE = 'Closing Date',
  POLICY_AVAILABLE = 'Policy Available',
}

export enum OrderMilestoneID {
  ORDER_OPENED = 1,
  EARNEST_MONEY_RECEIVED = 2,
  TITLE_PRODUCT_AVAILABLE = 3,
  CLOSING_DATE = 4,
  POLICY_AVAILABLE = 5,
}

export enum QualifyingRepresentingType {
  EARNEST_MONEY_DEPOSIT = 'Earnest Money Deposit',
  ADDITIONAL_DEPOSIT = 'Additional Deposit',
  INITIAL_DEPOSIT = 'Initial Deposit',
}
export interface IMilestone {
  completed: boolean;
  completionDate: string;
  description: string;
  estimatedDate: string | null;
  isVisible: boolean;
  milestoneId: number;
  name: string;
  pending: boolean;
  settlementDate: string;
  showEarnestMoney: boolean;
}

export interface IMilestonesState {
  loading: boolean;
  error: AxiosError | null;
  milestones: IMilestone[];
}

interface IMilestoneSetErrorAction {
  type: MilestoneTypeKeys.SET_ERROR;
  error: AxiosError | null;
}

interface IMilestoneSetLoadingAction {
  type: MilestoneTypeKeys.SET_LOADING;
  loading: boolean;
}

export interface IMilestoneSetMilestonesAction {
  type: MilestoneTypeKeys.SET_MILESTONES;
  milestones: IMilestone[];
}

export type MilestonesAction =
  | IMilestoneSetErrorAction
  | IMilestoneSetLoadingAction
  | IMilestoneSetMilestonesAction;
