import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M4 0.328125L10 6.32812V12.3281C10 13.4281 9.1 14.3281 8 14.3281H1.99C0.89 14.3281 0 13.4281 0 12.3281L0.0100002 2.32812C0.0100002 1.22812 0.9 0.328125 2 0.328125H4ZM4 6.32812H8L4 2.32812V6.32812Z"
      fill="white"
    />
  </svg>
);
