/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableHoldFundsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableHoldFundsViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHoldFundViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHoldFundViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel';
import type { Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundsViewModel } from '../models/Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundsViewModel';
import type { Workspace_Common_Models_Enums_AllocationType } from '../models/Workspace_Common_Models_Enums_AllocationType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HoldFundsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets all hold funds for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundsViewModel Order hold funds
   * @throws ApiError
   */
  public getHoldFundsAsync({
    orderId,
    siteFileId,
    groupId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds',
      path: {
        'orderId': orderId,
      },
      query: {
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Creates new hold funds for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel Order hold funds creates
   * @throws ApiError
   */
  public addHoldFundAsync({
    orderId,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Object which contains information needed to create hold fund
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHoldFundViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Gets all allocatable hold funds for an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableHoldFundsViewModel Order hold funds
   * @throws ApiError
   */
  public getAllocatableHoldFundsAsync({
    orderId,
    allocationType,
    siteFileId,
    groupId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    allocationType?: Workspace_Common_Models_Enums_AllocationType,
    siteFileId?: number | null,
    groupId?: number | null,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_AllocatableHoldFundsViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/allocatable',
      path: {
        'orderId': orderId,
      },
      query: {
        'AllocationType': allocationType,
        'SiteFileId': siteFileId,
        'GroupId': groupId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Gets details of the particular hold fund (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel Order hold funds
   * @throws ApiError
   */
  public getHoldFundDetailsAsync({
    orderId,
    holdFundId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Id of the hold fund
     */
    holdFundId: number,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/{holdFundId}',
      path: {
        'orderId': orderId,
        'holdFundId': holdFundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Updates a hold funds in an order (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel Success
   * @throws ApiError
   */
  public updateHoldFundAsync({
    orderId,
    holdFundId,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Id of the hold funds
     */
    holdFundId: number,
    /**
     * Object which contains information needed to update hold fund
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHoldFundViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/{holdFundId}',
      path: {
        'orderId': orderId,
        'holdFundId': holdFundId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Deletes particular hold fund (Auth)
   * @returns void
   * @throws ApiError
   */
  public deleteHoldFundAsync({
    orderId,
    holdFundId,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Id of the hold fund
     */
    holdFundId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/{holdFundId}',
      path: {
        'orderId': orderId,
        'holdFundId': holdFundId,
      },
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
      },
    });
  }

  /**
   * Creates new hold funds for an order with full allocation to given site (Auth)
   * @returns Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel Order hold funds creates
   * @throws ApiError
   */
  public addHoldFundForSiteAsync({
    orderId,
    siteFileId,
    requestBody,
  }: {
    /**
     * An order id
     */
    orderId: number,
    /**
     * Site Id which will be allocated
     */
    siteFileId: number,
    /**
     * Object which contains information needed to create hold fund
     */
    requestBody?: Workspace_API_FeatureAreas_Charges_ViewModels_CreateUpdateHoldFundViewModel,
  }): CancelablePromise<Workspace_API_FeatureAreas_Charges_ViewModels_HoldFundDetailViewModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/orders/{orderId}/charges/hold-funds/site/{siteFileId}',
      path: {
        'orderId': orderId,
        'siteFileId': siteFileId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header`,
        404: `Order Not Found`,
        409: `Conflict`,
      },
    });
  }

}
