export default {
  outgoingWires: (officeId: number | string) =>
    `/accounting/${officeId}/outgoing-funds`,
  outgoingWireDetails: (
    officeId: number | string,
    orderId: number | string,
    fileProcessId: number | string,
    disbursementId?: number | string
  ) =>
    `/accounting/${officeId}/files/${orderId}/disbursements/${fileProcessId}${
      disbursementId ? `?disbursementId=${disbursementId}` : ''
    }`,
  tranferReturnDetails: (officeId: number | string, fundId: number | string) =>
    `/accounting/${officeId}/funds/${fundId}`,
  ibaTransactions: (officeId: number | string) =>
    `/accounting/${officeId}/iba-transactions`,
  trustAccounting: (officeId: number | string) =>
    `/accounting/${officeId}/trust-accounting`,
};
