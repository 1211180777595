import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default ({ style }: ISVGProps) => (
  <svg
    width="122"
    height="125"
    viewBox="0 0 122 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.52 120.56C108.52 122.748 87.2461 124.52 61.0005 124.52C34.7516 124.52 13.4805 122.748 13.4805 120.56C13.4805 118.375 34.7516 116.6 61.0005 116.6C87.2461 116.6 108.52 118.375 108.52 120.56Z"
      fill="#CBD6E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.691 93.5298H8.37098C4.38972 93.5298 1.16211 90.3022 1.16211 86.3209V8.82557C1.16211 4.84158 4.38972 1.6167 8.37098 1.6167H105.691C109.672 1.6167 112.9 4.84158 112.9 8.82557V86.3209C112.9 90.3022 109.672 93.5298 105.691 93.5298Z"
      fill="#F8FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.2989 1.31982H8.37175C5.88142 1.31982 3.68872 2.58138 2.39167 4.49828C1.61617 5.64787 1.16016 7.03504 1.16016 8.52869V86.024C1.16016 87.2992 1.5206 88.4789 2.10222 89.5165L90.2989 1.31982Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.691 93.5297H8.37098C4.38972 93.5297 1.16211 90.3021 1.16211 86.3209V8.82557C1.16211 4.84157 4.38972 1.6167 8.37098 1.6167H105.691C109.672 1.6167 112.9 4.84157 112.9 8.82557V86.3209C112.9 90.3021 109.672 93.5297 105.691 93.5297Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.691 1.61621H8.37098C4.38972 1.61621 1.16211 4.84382 1.16211 8.82508V23.2428H112.9V8.82508C112.9 4.84382 109.672 1.61621 105.691 1.61621Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="26.6797"
      y="37.3999"
      width="47.52"
      height="2.64"
      rx="1.32"
      fill="#9BABC2"
    />
    <ellipse
      cx="16.5597"
      cy="38.7199"
      rx="4.84"
      ry="4.84"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M20.5196 38.0636C20.5196 38.6433 18.5645 37.8735 16.5536 37.8735C14.5427 37.8735 12.5996 38.6433 12.5996 38.0636C12.5996 36.4654 14.5693 34.7598 16.5802 34.7598C18.5911 34.7598 20.5196 36.4654 20.5196 38.0636Z"
      fill="#E8F4FA"
    />
    <rect
      x="26.6797"
      y="56.7598"
      width="29.04"
      height="2.64"
      rx="1.32"
      fill="#9BABC2"
    />
    <ellipse
      cx="16.5597"
      cy="58.0798"
      rx="4.84"
      ry="4.84"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M20.5196 57.4234C20.5196 58.0032 18.5645 57.2333 16.5536 57.2333C14.5427 57.2333 12.5996 58.0032 12.5996 57.4234C12.5996 55.8253 14.5693 54.1196 16.5802 54.1196C18.5911 54.1196 20.5196 55.8253 20.5196 57.4234Z"
      fill="#E8F4FA"
    />
    <rect
      x="26.6797"
      y="76.1196"
      width="15.84"
      height="2.64001"
      rx="1.32"
      fill="#9BABC2"
    />
    <circle
      cx="16.5597"
      cy="77.4396"
      r="4.84"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M20.5196 76.7833C20.5196 77.3631 18.5645 76.5932 16.5536 76.5932C14.5427 76.5932 12.5996 77.3631 12.5996 76.7833C12.5996 75.1852 14.5693 73.4795 16.5802 73.4795C18.5911 73.4795 20.5196 75.1852 20.5196 76.7833Z"
      fill="#E8F4FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.106 77.2149C120.106 94.5857 106.082 108.67 88.7826 108.67C71.4831 108.67 57.459 94.5857 57.459 77.2149C57.459 59.8441 71.4831 45.7622 88.7826 45.7622C106.082 45.7622 120.106 59.8441 120.106 77.2149Z"
      fill="#E6EDF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.106 77.2149C120.106 94.5857 106.082 108.67 88.7826 108.67C71.4831 108.67 57.459 94.5857 57.459 77.2149C57.459 59.8441 71.4831 45.7622 88.7826 45.7622C106.082 45.7622 120.106 59.8441 120.106 77.2149Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.297 77.215C113.297 90.8099 102.322 101.829 88.781 101.829C75.2419 101.829 64.2676 90.8099 64.2676 77.215C64.2676 63.6201 75.2419 52.6006 88.781 52.6006C102.322 52.6006 113.297 63.6201 113.297 77.215Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.782 59.4373C101.164 59.4373 111.371 68.6607 113.034 80.6338C113.189 79.5126 113.298 78.3778 113.298 77.2158C113.298 63.6209 102.321 52.5991 88.782 52.5991C75.2429 52.5991 64.2686 63.6209 64.2686 77.2158C64.2686 78.3778 64.3746 79.5126 64.5325 80.6338C66.1927 68.6607 76.4023 59.4373 88.782 59.4373Z"
      fill="#F0F0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.297 77.215C113.297 90.8099 102.322 101.829 88.781 101.829C75.2419 101.829 64.2676 90.8099 64.2676 77.215C64.2676 63.6201 75.2419 52.6006 88.781 52.6006C102.322 52.6006 113.297 63.6201 113.297 77.215Z"
      stroke="#9BABC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.8379 63.2457C85.8379 61.5473 87.2148 60.1704 88.9132 60.1704C90.6117 60.1704 91.9885 61.5473 91.9885 63.2457V82.6151C91.9885 84.3135 90.6117 85.6904 88.9132 85.6904C87.2148 85.6904 85.8379 84.3135 85.8379 82.6151V63.2457Z"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M86.7168 63.247C86.7168 62.0338 87.7003 61.0503 88.9135 61.0503C90.1266 61.0503 91.1101 62.0338 91.1101 63.247V66.3303L86.7168 68.9703V63.247Z"
      fill="#E8F4FA"
    />
    <ellipse
      cx="88.9132"
      cy="91.4106"
      rx="3.07531"
      ry="3.08"
      fill="#BECFE6"
      stroke="#9BABC2"
      strokeWidth="2"
    />
    <path
      d="M91.1101 91.0433C91.1101 91.4104 90.1266 90.9487 88.9135 90.9487C87.7003 90.9487 86.7168 91.4104 86.7168 91.0433C86.7168 90.031 87.7003 89.2104 88.9135 89.2104C90.1266 89.2104 91.1101 90.031 91.1101 91.0433Z"
      fill="#E8F4FA"
    />
  </svg>
);
