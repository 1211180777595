import React from 'react';

export default () => (
  <svg
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.38475 0.314453H15.1295L21.8848 7.04347V22.8145C21.8848 23.6432 21.2128 24.3145 20.3848 24.3145H2.38475C1.55673 24.3145 0.884766 23.6432 0.884766 22.8145V1.81444C0.884766 0.985718 1.55681 0.314453 2.38475 0.314453Z"
      fill="#E6736E"
    />
    <svg
      width="12"
      height="5"
      x="25%"
      y="50%"
      viewBox="0 0 12 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83066 2.14265V1.02773H2.39945C2.77039 1.02773 3.02387 1.23716 3.02387 1.58827C3.02387 1.92706 2.77039 2.14265 2.39945 2.14265H1.83066ZM2.49836 2.98038C3.38863 2.98038 4.00069 2.40752 4.00069 1.58211C4.00069 0.769017 3.38863 0.183838 2.49836 0.183838H0.847656V4.55112H1.82448V2.98038H2.49836Z"
        fill="white"
      />
      <path
        d="M5.58586 3.63332V1.10165H6.15464C6.82853 1.10165 7.37876 1.49587 7.37876 2.37056C7.37876 3.24525 6.82853 3.63332 6.15464 3.63332H5.58586ZM6.19174 4.55112C7.51478 4.55112 8.39886 3.71955 8.39886 2.37056C8.39886 1.02157 7.51478 0.183838 6.19792 0.183838H4.60904V4.55112H6.19174Z"
        fill="white"
      />
      <path
        d="M10.0779 4.55112V2.91878H11.6977V2.03177H10.0717V1.10781H11.9203V0.183838H9.09492V4.55112H10.0779Z"
        fill="white"
      />
    </svg>
    <svg
      width="7"
      height="8"
      x="68%"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86302 7.06422H1.63475C0.80673 7.06422 0.134766 6.39226 0.134766 5.56423V0.329224L6.86302 7.06422Z"
        fill="#B54E4A"
      />
    </svg>
  </svg>
);
