import React from 'react';
import {
  IMultisiteDocumentItem,
  ISettlementDocumentItemProgress,
} from 'store/orders/types';
import {
  trackMultisiteSettlementDocumentProgress,
  clearMultisiteSettlementDocumentProgress,
} from 'store/orders/actions';
import { connect } from 'react-redux';
import { IApplicationState } from 'store';
import DocumentGenerationProgress from 'components/DocumentGenerationProgress';
import ExportMultipleDocumentGenerationProgress from 'components/DocumentGenerationProgress/ExportMultipleDocumentGenerationProgress';
import orderDocumentUrls from 'config/urls/documents';
import { useAppSelector } from 'hooks/redux';
import { isFeatureEnabled } from 'store/features/helpers';
import { SettlementStatementFeatures } from 'store/features/types';

interface IMultisiteDocumentGenerationProps {
  multisiteSettlementStatements: IMultisiteDocumentItem[];
  trackMultisiteSettlementDocumentProgress: typeof trackMultisiteSettlementDocumentProgress;
  clearMultisiteSettlementDocumentProgress: typeof clearMultisiteSettlementDocumentProgress;
}

const MultisiteDocumentGenerationProgress = ({
  multisiteSettlementStatements,
  trackMultisiteSettlementDocumentProgress,
  clearMultisiteSettlementDocumentProgress,
}: IMultisiteDocumentGenerationProps) => {
  const { isExportMultipleSSEnabled } = useAppSelector(
    (state: IApplicationState) => ({
      isExportMultipleSSEnabled: isFeatureEnabled(
        state,
        SettlementStatementFeatures.ExportMultipleSS
      ),
    })
  );

  const DocGenComponent = isExportMultipleSSEnabled
    ? ExportMultipleDocumentGenerationProgress
    : DocumentGenerationProgress;

  return (
    <DocGenComponent
      title="Exporting Documents..."
      selectorKey="settlement-statement-multisite"
      items={multisiteSettlementStatements.map((item) => {
        return {
          ...item,
          isMultisite: true,
        };
      })}
      getItemPendingStatus={(itm) => {
        const item = (itm as unknown) as IMultisiteDocumentItem;
        return item.status !== 'Success' && !item.fetching;
      }}
      getItemReadyStatus={(item) => item.status === 'Success'}
      fetchItemBytes={(item) => Promise.resolve(item.bytes)}
      clearItem={(item) => {
        clearMultisiteSettlementDocumentProgress(
          (item as unknown) as IMultisiteDocumentItem
        );
      }}
      trackItems={(items) => {
        trackMultisiteSettlementDocumentProgress(
          (items as unknown) as IMultisiteDocumentItem[]
        );
      }}
      onCompleteItemClick={(itm) => {
        const item = (itm as unknown) as ISettlementDocumentItemProgress;
        window.open(orderDocumentUrls.documents(item.orderId), '_blank');
      }}
      errorText={'There was an error exporting settlement statement'}
      data-testid="ms-settlement-documentGeneration-progress"
      stopOnError
    />
  );
};

function mapStateToProps(state: IApplicationState) {
  return {
    multisiteSettlementStatements:
      state.orders.ui.multisiteSettlementDocuments.items,
    fetchError: state.orders.ui.multisiteSettlementDocuments.error,
  };
}

export default connect(mapStateToProps, {
  clearMultisiteSettlementDocumentProgress,
  trackMultisiteSettlementDocumentProgress,
})(MultisiteDocumentGenerationProgress);
