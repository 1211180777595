import React from 'react';
import styled, { css } from 'styled-components';
import { toast as libraryToast } from 'react-toastify';
import {
  AlertCircleIcon,
  AlertSpikedCircle,
  CheckWiderIcon,
} from '../svg/legacyIcons_DO_NOT_USE';
import {
  primaryBlue8,
  white,
  whiteGhost1,
  whiteGhost2,
} from '../../config/colors';
import {
  IToasterContainerProps,
  ToasterIntent,
  IToast,
  ToastHelper,
  IToastOptions,
} from './types';
import { CloseToastButton } from './CloseToastButton';
import { Route } from 'react-router-dom';
import Button from 'components/ui/Button';
import { Column } from 'components/ui/Box';

const toastCollection: string[] = [];
const messageQueue = [];

const InnerCloseOnRouteChangeBtn = ({
  location,
  originalPath,
  closeBtnProps,
}) => {
  React.useEffect(() => {
    if (originalPath !== location.pathname) closeBtnProps.closeToast();
  }, [location.pathname]);
  return <CloseToastButton {...closeBtnProps} />;
};

const CloseOnRouteChangeBtn = (props) => {
  const originalPath = window.location.pathname;
  return (
    <Route
      render={({ location }) => (
        <InnerCloseOnRouteChangeBtn
          location={location}
          originalPath={originalPath}
          closeBtnProps={props}
        />
      )}
    />
  );
};

/**
 * This toast wrapper is mainly concerned with syncing a queue that ensures that
 * five toasters are displayed, and if a newer toast gets enqueued, the
 * oldest toast will then be discarded automatically.
 */
function toast(
  message: React.ReactNode,
  intent?: ToasterIntent,
  options?: IToastOptions,
  uniqueIdentifier?: string
): string;
function toast(
  message: React.ReactNode,
  intent: ToasterIntent = 'info',
  options?: IToastOptions,
  uniqueIdentifier?: string,
  // The following two are intended for testing, and should not be used normally
  internalToastCollection = toastCollection,
  internalMessageQueue: IToast[] = messageQueue
) {
  // If we have less than 5 toasts on screen, we can directly display the newest one.
  if (internalToastCollection.length < 5) {
    const isInfo = intent === 'info';
    const isCustom = intent === 'custom';
    if (isCustom) {
      intent = 'info';
    }

    /* Here we try to calculate how long our toaster should display dynamically.
     * Our minimum being 3 seconds, and our maximum being 7 seconds. We can only
     * calculate this period for strings. Non-strings get 5 seconds by default.
     *
     * Based on:
     * https://uxdesign.cc/toasts-or-snack-bars-design-organic-system-notifications-1236f2883023
     */
    let autoClose: number | false = 7000;

    if (typeof message === 'string') {
      autoClose = Math.max(Math.min(message.length * 50, 3000), 7000);
    }
    if (options?.autoClose === false) {
      autoClose = false;
    }

    const closeButton = options?.closeOnRouteChange ? (
      <CloseOnRouteChangeBtn uniqueCloseIdentifier={uniqueIdentifier} />
    ) : undefined;
    const id = libraryToast[intent](
      <ToastBodyContainer
        intent={intent}
        isInfo={isInfo}
        isCustom={isCustom}
        hasOnclick={!!options?.onClick}
        id={uniqueIdentifier ? uniqueIdentifier : 'toast-container'}
      >
        {!isInfo && !isCustom && (
          <ToastIconContainer>{icons[intent]}</ToastIconContainer>
        )}

        <ToastTextContainer intent={intent} isInfo={isInfo} isCustom={isCustom}>
          {message}
        </ToastTextContainer>
        {options?.button && (
          <Column
            justify={options?.button?.justify || 'center'}
            margin={options?.button?.margin}
          >
            <Button
              to={options?.button?.to}
              onClick={options?.button?.onClick}
              id={options?.button?.domId}
              uppercase
              ghost
              backgroundHoverColor={whiteGhost1}
              activeBackgroundColor={whiteGhost2}
            >
              {options?.button.text}
            </Button>
          </Column>
        )}
      </ToastBodyContainer>,
      {
        ...options,
        className: isCustom ? 'Toastify__toast--custom' : '',
        closeButton: isCustom ? false : closeButton,
        onClose: () => {
          // We linear search to splice out the matching ID from our array, that
          // way our memory structure matches what is going on with our screen
          for (let i = 0; i < internalToastCollection.length; i++) {
            if (internalToastCollection[i] === id) {
              internalToastCollection.splice(i, 1);
              break;
            }
          }

          /**
           * This aggressively tries to display any remaining toasts in our
           * memory by calling our toast function, which will remove the oldest
           * items as we go.
           */
          while (internalMessageQueue.length > 0) {
            const q = internalMessageQueue.shift()!;

            toast(q.message, q.intent, q.options);
          }
        },
        autoClose,
      }
    );

    internalToastCollection.push(id);
    return id;
  }
  // Add our current message to the queue
  internalMessageQueue.push({
    message,
    intent,
    options,
  });

  // We delete our oldest toast entry
  const next = internalToastCollection.shift()!;
  libraryToast.dismiss(next as string);
  return next;
}

toast.dismiss = (toastId: string) => libraryToast.dismiss(toastId);
toast.info = ((message, options?) =>
  toast(message, 'info', options)) as ToastHelper;
toast.warn = ((message, options?) =>
  toast(message, 'warning', options)) as ToastHelper;
toast.warning = ((message, options?) =>
  toast(message, 'warning', options)) as ToastHelper;
toast.error = ((message, options?) =>
  toast(message, 'error', options)) as ToastHelper;
toast.success = ((message, options?) =>
  toast(message, 'success', options)) as ToastHelper;
toast.custom = ((message, options?) =>
  toast(message, 'custom', options)) as ToastHelper;

const icons = {
  success: <CheckWiderIcon />,
  warning: <AlertSpikedCircle fill={primaryBlue8} />,
  error: (
    <AlertCircleIcon
      style={{
        height: 20,
        width: 20,
      }}
    />
  ),
};

const ToastBodyContainer = styled.div<
  IToasterContainerProps & { hasOnclick?: boolean }
>`
  display: flex;
  font-family: 'Circular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0025em;
  color: ${({ intent }) => (intent === 'warning' ? primaryBlue8 : white)};
  cursor: ${({ hasOnclick }) => (hasOnclick ? 'pointer' : 'initial')};
`;

const ToastTextContainer = styled.div<IToasterContainerProps>`
  padding-left: ${({ isInfo }) => (isInfo ? '14px' : '6px')};
  max-width: ${({ isInfo }) => (isInfo ? '480px' : '450px')};
  margin-top: 14px;
  margin-bottom: 12px;
  flex: 1 1;
  overflow-wrap: break-word;
  ${({ isCustom }) =>
    isCustom
      ? css`
          padding: 0;
          margin: 0;
        `
      : ''}

  a {
    color: ${({ intent }) => (intent === 'warning' ? primaryBlue8 : white)};
    text-decoration: underline;
  }
`;

const ToastIconContainer = styled.div`
  height: 32px;
  width: 32px;
  margin-top: 8px;
  margin-right: 0px;
  margin-left: 7px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default toast;
