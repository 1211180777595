/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_UserFarm } from '../models/Workspace_Common_Models_UserFarm';
import type { Workspace_Common_Requests_PropertySearchRequest } from '../models/Workspace_Common_Requests_PropertySearchRequest';
import type { Workspace_Common_Responses_PropertySearchResponse } from '../models/Workspace_Common_Responses_PropertySearchResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FarmsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the Farm list from Workspace API
   * with the property count (Auth)
   * @returns Workspace_Common_Models_UserFarm List of Farms
   * @throws ApiError
   */
  public getFarms(): CancelablePromise<Array<Workspace_Common_Models_UserFarm>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/farms/get-farms',
      errors: {
        401: `Invalid Authorization header`,
      },
    });
  }

  /**
   * Create or update a farm (Auth)
   * @returns any UserFarm created or updated successfully
   * @throws ApiError
   */
  public createOrUpdateFarm({
    requestBody,
  }: {
    requestBody: Workspace_Common_Models_UserFarm,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/farms/create-update-farm',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        422: `Validation Failed.`,
      },
    });
  }

  /**
   * Get farm details by farmid (Auth)
   * @returns Workspace_Common_Models_UserFarm Success
   * @returns any Get farm details
   * @throws ApiError
   */
  public getFarmDetails({
    farmId,
  }: {
    farmId: number,
  }): CancelablePromise<Workspace_Common_Models_UserFarm | any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/farms/{farmId}/get-farm-detail',
      path: {
        'farmId': farmId,
      },
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Not Found`,
      },
    });
  }

  /**
   * For a given farmId , Delete the farm (Auth)
   * @returns boolean Farm Deleted
   * @throws ApiError
   */
  public deleteFarm({
    farmId,
  }: {
    /**
     * Farm ID
     */
    farmId: number,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ncs/v1/farms/{farmId}/delete-Farm',
      path: {
        'farmId': farmId,
      },
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Farm not found`,
      },
    });
  }

  /**
   * Apply property Filters for Farm (Auth)
   * @returns Workspace_Common_Responses_PropertySearchResponse Success
   * @returns any Ok
   * @throws ApiError
   */
  public applyFiltersInFarm({
    requestBody,
  }: {
    requestBody?: Workspace_Common_Requests_PropertySearchRequest,
  }): CancelablePromise<Workspace_Common_Responses_PropertySearchResponse | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ncs/v1/farms/apply-filters-in-farm',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Invalid Request`,
        401: `Invalid Authorization header`,
        404: `Not Found`,
      },
    });
  }

}
