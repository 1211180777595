import { lightBlue } from 'config/theme';
import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="21"
    height="25"
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M1 16.3321L1.00001 6.28854C1.00001 5.26893 1.82656 4.44238 2.84616 4.44238H9.51442M1 16.3321L3.22115 14.1787L12.8462 23.6924H2.84616C1.82656 23.6924 1.00001 22.8658 1.00001 21.8462L1 16.3321ZM1 16.3321L10.558 6.73901"
      stroke={props.fill || lightBlue}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3652 23.6922L10.8349 23.1619C10.6204 23.3764 10.5562 23.699 10.6723 23.9792C10.7884 24.2595 11.0619 24.4422 11.3652 24.4422V23.6922ZM20.2498 14.8076H20.9998C20.9998 14.5043 20.8171 14.2308 20.5369 14.1147C20.2566 13.9986 19.934 14.0628 19.7195 14.2773L20.2498 14.8076ZM18.4037 22.9422H11.3652V24.4422H18.4037V22.9422ZM11.8956 24.2226L20.7802 15.3379L19.7195 14.2773L10.8349 23.1619L11.8956 24.2226ZM19.4998 14.8076V21.8461H20.9998V14.8076H19.4998ZM18.4037 24.4422C19.8375 24.4422 20.9998 23.2799 20.9998 21.8461H19.4998C19.4998 22.4515 19.0091 22.9422 18.4037 22.9422V24.4422Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M20.249 8.14431L20.776 8.6779C20.9187 8.53699 20.999 8.34482 20.999 8.14431H20.249ZM8.77396 19.4785L8.24803 20.0132C8.54032 20.3007 9.00932 20.3002 9.30101 20.0121L8.77396 19.4785ZM3.36285 14.1559L2.83252 13.6256C2.69111 13.767 2.61204 13.9591 2.61286 14.159C2.61369 14.359 2.69435 14.5504 2.83692 14.6906L3.36285 14.1559ZM19.499 6.28854V8.14431H20.999V6.28854H19.499ZM19.7219 7.61071L8.24692 18.9449L9.30101 20.0121L20.776 8.6779L19.7219 7.61071ZM9.2999 18.9438L3.88879 13.6213L2.83692 14.6906L8.24803 20.0132L9.2999 18.9438ZM3.89318 14.6863L10.7851 7.7944L9.7244 6.73374L2.83252 13.6256L3.89318 14.6863ZM17.2884 5.19238H18.4028V3.69238H17.2884V5.19238ZM20.999 6.28854C20.999 4.85472 19.8366 3.69238 18.4028 3.69238V5.19238C19.0082 5.19238 19.499 5.68315 19.499 6.28854H20.999Z"
      fill={props.fill || lightBlue}
    />
    <path
      d="M20.25 16.2884V7.40381"
      stroke={props.fill || lightBlue}
      strokeWidth="1.5"
    />
    <path
      d="M13.5865 14.8077L8.40381 9.625"
      stroke={props.fill || lightBlue}
      strokeWidth="1.5"
    />
    <path
      d="M18.7693 16.2883L15.0674 12.5864"
      stroke={props.fill || lightBlue}
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1302 10.8389C15.9394 8.90326 18.0287 6.66808 18.0287 4.44231C18.0287 1.98889 16.0398 0 13.5864 0C11.1329 0 9.14404 1.98889 9.14404 4.44231C9.14404 6.65075 11.3294 8.99701 13.0387 10.8321C13.2283 11.0357 13.4121 11.233 13.5864 11.4231C13.763 11.2317 13.9451 11.0369 14.1302 10.8389ZM13.5863 5.92294C14.4041 5.92294 15.067 5.25998 15.067 4.44217C15.067 3.62437 14.4041 2.9614 13.5863 2.9614C12.7685 2.9614 12.1055 3.62437 12.1055 4.44217C12.1055 5.25998 12.7685 5.92294 13.5863 5.92294Z"
      fill={props.fill || lightBlue}
    />
  </svg>
);
