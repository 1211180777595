import React from 'react';
import styled from 'styled-components';
import StyledReactTooltip from 'components/ToolTip/StyledReactTooltip';
import FileTypeIcon from 'components/svg/FileType';
import useGetFileTypeForExtension from 'hooks/documents/useGetFileTypeForExtension';
import ProgressBarDisplay from 'components/ProgressBar/ProgressBarDisplay';
import { IDisplayNameProgressRow } from 'store/ProgressBar/types';
import ProgressHeader from 'components/ProgressBar/ProgressHeaders/ProgressHeader';
import ProgressRowViewStatus from 'components/ProgressBar/ProgressRow/ProgressRowViewStatus';

export const DepositCheckProgressBar = () => {
  const renderProgressHeader = () => {
    return (
      <ProgressHeader
        titleHeader={'Generating Check Images...'}
        inProgressTitleHeader={'Generating Check Images...'}
        identifier={'DepositCheckImage'}
      />
    );
  };

  const FileNameDisplayRender = (
    fileName: IDisplayNameProgressRow,
    uniqueId: string,
    documentType: string
  ) => {
    const fileTypeIcon = useGetFileTypeForExtension({
      fileName: fileName.name + documentType,
    });
    return (
      <FileNameDisplay
        data-tip
        data-for={`deposits-check__view-document-${uniqueId}`}
      >
        <div>
          <FileTypeIcon
            type={fileTypeIcon}
            style={{
              width: 23,
              float: 'left',
              position: 'relative',
              flexShrink: 0,
              marginRight: 8,
            }}
          />
        </div>
        <div>
          <FileName>{fileName?.name ?? ''}</FileName>
          <SubHeader>{fileName?.nameSubHeader ?? ''}</SubHeader>
        </div>
        <StyledReactTooltip
          content={fileName?.nameToolTip}
          place="bottom"
          textAlign="center"
          id={`deposits-check__view-document-${uniqueId}`}
          delayShow={100}
          maxWidth="240px"
        />
      </FileNameDisplay>
    );
  };

  const renderProgressRows = () => {
    return (
      <ProgressRowViewStatus
        fileNameDisplayRender={FileNameDisplayRender}
        documentType=".pdf"
        identifier="DepositCheckImage"
        hideCloseIcon={true}
      />
    );
  };

  return (
    <ProgressBarDisplay
      identifier={'DepositCheckImage'}
      pollingDelay={3000}
      renderProgressHeader={renderProgressHeader}
      renderProgressRows={renderProgressRows}
    />
  );
};

const SubHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

const FileName = styled.div`
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
`;

const FileNameDisplay = styled.div`
  display: flex;
  align-items: 'center';
`;
