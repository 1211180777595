import React from 'react';
import styled from 'styled-components';
import {
  black,
  blue,
  cadetBlue,
  extremeBlack,
  lightBlue,
  lightningYellow,
  linkWater,
  trout,
} from 'config/theme';
import {
  CalendarCheckmarkIcon,
  NoteIcon,
  PulseIcon,
  RibbonCard,
  UserOutlineIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import moment from 'moment';
import { Row, Column } from 'components/ui/Box';
import { Attribute, AttributesContainer } from '../Attribute';
import { StatusColors } from '../utils';
import { SiteStatus } from '../types';
import { SiteCardMenu } from './SiteCardMenu';
import { SingleSiteAddress, MultiSiteHeader } from './Addresses';
import TransactionTypes from './TransactionTypes';
import {
  RightContentProps,
  ISinglesiteHeaderProps,
  IMultisiteHeaderProps,
} from './props';
import { slugify } from 'utils/text';

const RightContent = ({
  businessSource,
  businessSourceContact,
  estimatedCloseDate,
  fileId,
  fileNumber,
  hasBody,
  hasDocumentPermissions,
  hasWireInstructionPermissions,
  isSiteCardBox = false,
  liabilityAmount,
  multilineAttrs,
  multisite,
  name,
  pushRoute,
  status,
  transactionTypes,
  isInternal,
  isCloseDateEstimated,
  ...props
}: RightContentProps) => {
  const transactionTypesValue = transactionTypes?.length && (
    <TransactionTypes
      name={transactionTypes[0]}
      count={transactionTypes.length - 1}
    />
  );

  let contentHead: React.ReactNode = null;

  if (multisite) {
    const {
      citiesCount,
      closedSitesCount,
      countiesCount,
      openSitesCount,
      sitesCount,
      stateCount,
    } = props as IMultisiteHeaderProps;

    contentHead = (
      <MultiSiteHeader
        citiesCount={citiesCount}
        closedSitesCount={closedSitesCount}
        countiesCount={countiesCount}
        fileId={fileId}
        openSitesCount={openSitesCount}
        sitesCount={sitesCount}
        stateCount={stateCount}
      />
    );
  } else if (!multisite) {
    const {
      countyInformation,
      countyInformationMaxWidth,
      stateInformation,
      stateInformationMaxWidth,
      street,
      streetMaxWidth,
    } = props as ISinglesiteHeaderProps;

    contentHead = (
      <SingleSiteAddress
        countyInformation={countyInformation}
        countyInformationMaxWidth={countyInformationMaxWidth}
        fileId={fileId}
        stateInformation={stateInformation}
        stateInformationMaxWidth={stateInformationMaxWidth}
        street={street}
        streetMaxWidth={streetMaxWidth}
      />
    );
  }
  const showDate = estimatedCloseDate && (multisite || !hasBody);
  const showDateLabel: string = !estimatedCloseDate
    ? ''
    : !isInternal && isCloseDateEstimated
    ? 'Not Scheduled'
    : moment(estimatedCloseDate).format('M/D/YYYY').toString();

  return (
    <RowWrapper justify="between" alignContent="center">
      <Column justify="between" alignItems="start">
        {contentHead}

        <AttributesContainer>
          {isSiteCardBox && name && (
            <Attribute
              adjustable
              classSelectorName="site-card-box-name"
              color={extremeBlack}
              iconFillColor={lightBlue}
              iconSvgComponent={RibbonCard}
              iconTooltipId={slugify(`Order Name ${fileNumber}`)}
              iconTooltipText="Order Name"
              maxWidth={165}
              separatorColor={linkWater}
              separatorHeight={!showDate ? '0px' : '18px'}
              valueText={name}
              valueTextSize={12}
            />
          )}

          {status && (
            <Attribute
              adjustable
              classSelectorName="site-card-status"
              color={StatusColors[SiteStatus[status]]}
              iconFillColor={lightningYellow}
              iconSvgComponent={PulseIcon}
              iconTooltipId={slugify(`Order Status ${fileNumber}`)}
              iconTooltipText={multisite ? 'Multisite Status' : 'Order Status'}
              lineHeight="16px"
              separatorColor={linkWater}
              separatorHeight="18px"
              valueText={status}
              valueTextSize={12}
            />
          )}

          {!isSiteCardBox && transactionTypesValue && transactionTypes && (
            <Attribute
              adjustable
              classSelectorName="site-card-order-type"
              iconFillColor={blue}
              iconHeight={13}
              iconSvgComponent={NoteIcon}
              iconTooltipId={slugify(transactionTypes.join('.') + fileId)}
              iconTooltipText={
                transactionTypes?.length > 1 ? 'Order Type(s)' : 'Order Type'
              }
              iconWidth={13}
              separatorColor={linkWater}
              separatorHeight={!showDate ? '0px' : '18px'}
              textColor={black}
              valueComponent={transactionTypesValue}
            />
          )}

          {showDate && (
            <Attribute
              adjustable
              classSelectorName="site-card-closing-date"
              iconFillColor={blue}
              iconHeight={13}
              iconSvgComponent={CalendarCheckmarkIcon}
              iconTooltipId={slugify((estimatedCloseDate || '') + fileId)}
              iconTooltipText={
                multisite ? 'Est. Multisite Closing' : 'Est. Closing'
              }
              iconWidth={13}
              maxWidth={200}
              separatorColor={linkWater}
              separatorHeight="18px"
              textColor={black}
              valueText={showDateLabel}
              valueTextSize={12}
            />
          )}

          {multilineAttrs && <div style={{ height: 1, width: '100%' }} />}

          {businessSource && (
            <Attribute
              adjustable
              classSelectorName="site-card-business-source"
              iconFillColor={cadetBlue}
              iconHeight={13}
              iconSvgComponent={UserOutlineIcon}
              iconTooltipId={slugify(businessSource + fileId)}
              iconTooltipText="Business Source & Contact"
              iconWidth={13}
              maxWidth={186}
              separatorColor={linkWater}
              separatorHeight={businessSourceContact ? '12px' : '18px'}
              textColor={extremeBlack}
              valueText={businessSource}
              valueTextSize={12}
            />
          )}

          {businessSourceContact && (
            <Attribute
              adjustable
              classSelectorName="site-card-business-source-contact"
              maxWidth={144}
              separatorColor={linkWater}
              separatorHeight="18px"
              textColor={trout}
              valueText={businessSourceContact}
              valueTextSize={12}
            />
          )}
        </AttributesContainer>
      </Column>
      {!multisite &&
        (hasDocumentPermissions || hasWireInstructionPermissions) && (
          <SiteCardMenu
            fileId={fileId}
            hasDocumentPermissions={hasDocumentPermissions}
            hasWireInstructionPermissions={hasWireInstructionPermissions}
            pushRoute={pushRoute}
          />
        )}
    </RowWrapper>
  );
};
RightContent.displayName = 'RightContent';

const RowWrapper = styled(Row)`
  flex: 1;
`;

export default RightContent;
