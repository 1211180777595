import React from 'react';
import { IDesignSystemIconProps } from './types';
import { neutralGray7 } from 'config/colors';

// eslint-disable-next-line import/no-unused-modules
export default (props: IDesignSystemIconProps) => (
  <svg
    className={props.className}
    style={props.style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || neutralGray7}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4C4.01 2.9 4.9 2 6 2ZM6 4V20H18V9H13V4H6ZM8.73008 11H16V18.2699L13.8201 16.09L10.91 19L8 16.09L10.91 13.1902L8.73008 11Z"
    />
  </svg>
);
