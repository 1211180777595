import React, { useEffect } from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import { connect } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';

import { removeToast } from '../../store/ui/actions';
import { ISnackbarProps } from './types';
import toast from './ToastUtilities';
import './snackbar-themes.css';
import { CloseToastButton } from './CloseToastButton';

// Wrapper deliberately hardcoded, we should only have one Snackbar per-app, and
// the design team has ensured the variants are consistent
export const Snackbar = ({ pendingToasts, removeToasts }: ISnackbarProps) => {
  useEffect(() => {
    // Our IDs to remove are toaster entries we dispatch a toast for, this is
    // all being done to contain the side effects in components
    const idsToRemove = Object.keys(pendingToasts).map((k) => {
      const { id, text, intent, options } = pendingToasts[k];
      toast(text, intent, options);

      return id;
    });

    // If we have toasters to remove, we remove them
    if (idsToRemove.length) {
      removeToasts(idsToRemove);
    }
  }, [pendingToasts, removeToasts]);

  return (
    <ToastContainer
      autoClose={false}
      closeButton={<CloseToastButton />}
      closeOnClick={false}
      draggable={false}
      hideProgressBar
      newestOnTop
      pauseOnFocusLoss={false}
      position="bottom-right"
      rtl={false}
      transition={Slide}
    />
  );
};

const Slide = cssTransition({
  enter: 'Snackbar--slide-in',
  exit: 'Snackbar--slide-out',
  duration: [200, 500],
});

const mapStateToProps = (state) => ({
  pendingToasts: state.ui.toasts,
});

const mapDispatchToProps = {
  removeToasts: removeToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
