/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderWireInstructionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Sends a verification email for the given wire instruction (Auth)
   * @returns void
   * @throws ApiError
   */
  public sendVerification({
    orderId,
    wireInstructionId,
  }: {
    /**
     * The id of the order for which to get wire instructions
     */
    orderId: number,
    /**
     * The id of the wire instruction for which to send verification
     */
    wireInstructionId: number,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/wire-instructions/{wireInstructionId}/_send-verification',
      path: {
        'orderId': orderId,
        'wireInstructionId': wireInstructionId,
      },
      errors: {
        403: `User is not permitted to access this wire instruction`,
        404: `Wire instruction not found`,
        409: `Wire instruction not in the correct status`,
      },
    });
  }

}
