import { blue, white } from 'config/theme';
import { css } from 'styled-components';

// This function applies a known CSS fix for resetting
// the text baseline when it doesn't match across different browsers.

// The `lineHeight` parameter sets the actual line height we want to set for the element.
export function lineHeightFix(lineHeight: string) {
  return `
    display: inline-block;
    line-height: 0;

    &:after {
      content: '';
      display: inline-block;
      height: ${lineHeight};
    }
  `;
}

export const NO_SPINNER = css`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ALLOW_TOOLTIP_PRIMARY_BLUE = css`
  &.MuiButtonBase-root.Mui-disabled {
    pointer-events: auto;
  }
  &.MuiButtonBase-root.Mui-disabled:hover {
    color: ${white};
    background-color: ${blue};
    opacity: 40%;
    box-shadow: none;
  }
`;

export const CARD_REMOVAL_TRANSITION_NAME = 'card-remove';
export const CARD_REMOVAL_TRANSITION_DURATION = 500;

export const cardRemovalTransitionStyles = (
  transitionName = CARD_REMOVAL_TRANSITION_NAME,
  duration = CARD_REMOVAL_TRANSITION_DURATION
) => `
  &.${transitionName}-enter {
    opacity: 0.01;
  }

  &.${transitionName}-enter-active {
    opacity: 1;
    transition: opacity ${duration}ms ease-in;
  }

  &.${transitionName}-exit {
    opacity: 1;
  }

  &.${transitionName}-exit-active {
    opacity: 0.01;
    transition: opacity ${duration}ms ease-out;
  }
`;

export const RESPONSIVE_SIZE = css`
  height: auto;
  max-width: 100%;
`;
