/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_Common_Models_SessionResponse } from '../models/Workspace_Common_Models_SessionResponse';
import type { Workspace_Common_Requests_VerifyEmailCodeRequest } from '../models/Workspace_Common_Requests_VerifyEmailCodeRequest';
import type { Workspace_Common_Responses_RequestEmailVerificationResponse } from '../models/Workspace_Common_Responses_RequestEmailVerificationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccessControlService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Send the Request Email Verification email (Auth)
   * @returns Workspace_Common_Responses_RequestEmailVerificationResponse Success
   * @throws ApiError
   */
  public requestEmailVerification(): CancelablePromise<Workspace_Common_Responses_RequestEmailVerificationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ncs/v1/users/_me/email-verification',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Verify the email code for the logged in user (Auth)
   * @returns Workspace_Common_Models_SessionResponse Session has successfully initiated
   * @throws ApiError
   */
  public verifyEmailCode({
    requestBody,
  }: {
    /**
     * Code (from RequestEmailVerification) sent in the email
     */
    requestBody: Workspace_Common_Requests_VerifyEmailCodeRequest,
  }): CancelablePromise<Workspace_Common_Models_SessionResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/users/_me/email-verification',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Token is not accesible`,
        404: `User does not exist or not authenticated`,
      },
    });
  }

}
