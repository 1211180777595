/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workspace_API_FeatureAreas_OrderServices_Enum_FileServiceType } from '../models/Workspace_API_FeatureAreas_OrderServices_Enum_FileServiceType';
import type { Workspace_API_FeatureAreas_OrderServices_ViewModels_ProductionOfficeUpdateResponse } from '../models/Workspace_API_FeatureAreas_OrderServices_ViewModels_ProductionOfficeUpdateResponse';
import type { Workspace_API_FeatureAreas_OrderServices_ViewModels_ServiceList } from '../models/Workspace_API_FeatureAreas_OrderServices_ViewModels_ServiceList';
import type { Workspace_API_FeatureAreas_OrderServices_ViewModels_UpdateProductionOfficeContacts } from '../models/Workspace_API_FeatureAreas_OrderServices_ViewModels_UpdateProductionOfficeContacts';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderServicesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update production offices for multiple service type to a file. (Auth)
   * @returns Workspace_API_FeatureAreas_OrderServices_ViewModels_ProductionOfficeUpdateResponse Success
   * @throws ApiError
   */
  public updateServiceProductionOfficeAsync({
    orderId,
    requestBody,
  }: {
    /**
     * Order Id.
     */
    orderId: number,
    /**
     * Services to update.
     */
    requestBody: Workspace_API_FeatureAreas_OrderServices_ViewModels_ServiceList,
  }): CancelablePromise<Array<Workspace_API_FeatureAreas_OrderServices_ViewModels_ProductionOfficeUpdateResponse>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/services/production-offices',
      path: {
        'orderId': orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        403: `A user without permission to edit tries to edit.`,
        404: `File not found/office detail is null.`,
      },
    });
  }

  /**
   * This endpoint updates Production Office contacts (Officer or Assistant) (Auth)
   * @returns void
   * @throws ApiError
   */
  public updateProductionOfficeContactsAsync({
    orderId,
    serviceType,
    officeId,
    requestBody,
  }: {
    /**
     * The order Id
     */
    orderId: number,
    /**
     * The service type: Title | Escrow
     */
    serviceType: Workspace_API_FeatureAreas_OrderServices_Enum_FileServiceType,
    /**
     * The office Id
     */
    officeId: number,
    /**
     * The Production Offices Contacts data
     */
    requestBody: Workspace_API_FeatureAreas_OrderServices_ViewModels_UpdateProductionOfficeContacts,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ncs/v1/orders/{orderId}/services/{serviceType}/production-offices/{officeId}/contacts',
      path: {
        'orderId': orderId,
        'serviceType': serviceType,
        'officeId': officeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Invalid Authorization header, or user doesn't have the activity right`,
        403: `User not authorized to access the office`,
        404: `Failed to check validations`,
      },
    });
  }

}
