import styled from 'styled-components';
import { red8 } from 'config/colors';

interface IContainerProps {
  itemsCount: number;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  margin: auto;
  justify-content: center;
  width: ${(props: IContainerProps) => `
    calc(var(42px) * ${props.itemsCount}
    + var(0.5rem)
    * (${props.itemsCount} - 1)
  `}
  );
`;

// input
interface IInputProps {
  activeIndex: number;
}

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: ${(props: IInputProps) =>
    `calc(${props.activeIndex} * var(42px) + var(0.5rem) * ${props.activeIndex})`};
  opacity: 0;
  width: 42px;
  height: 64px;
`;

// item
export const Item = styled.div`
  width: 42px;
  height: 64px;
  margin: 2px;
  padding: 0;
  border-radius: 4px;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 64px;
  text-align: center;
  border: 0;
  box-shadow: inset 0 0 0 1px #ccc;
  transition: box-shadow 0.2s ease-out;
  &.is-active {
    box-shadow: inset 0 0 0 2px #888;
  }
  &.is-error {
    box-shadow: inset 0 0 0 1px ${red8};
    &.is-active {
      box-shadow: inset 0 0 0 2px ${red8};
    }
  }
`;

export const Separator = styled.span`
  line-height: 64px;
  font-size: 2.5rem;
`;
