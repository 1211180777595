/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/naming-convention */

/*************************************
 * SOLIDS
 *************************************/
export const red10 = '#B63831';
export const red9 = '#a62222';
export const red8 = '#C62828';
export const red7 = '#CE4646';
export const red5 = '#E6A2A2';
export const red2 = '#FFE2E1';
export const red1 = '#FDF2F2;';

export const coral7 = '#EB5757';

export const orange7 = '#C2560C';
export const orange6 = '#FD8C3C';
export const orange3 = '#FED1B1';

export const yellow5 = '#F9C004';
export const yellow4 = '#FBCE53';
export const yellow3 = '#FCDF81';
export const yellow1 = '#FFF3D1';

export const green8 = '#09873C';
export const green6 = '#56C568';
export const green4 = '#A9DFBF';

export const teal8 = '#028193';

export const azure7 = '#0288E0';
export const azure4 = '#B6D9F0';

export const secondaryBlue8 = '#375D90';
export const secondaryBlue7 = '#4372B0';
export const secondaryBlue6 = '#5D86BB';
export const secondaryBlue5 = '#A0B8D7';

export const primaryBlue10 = '#80a1c9';
export const primaryBlue9 = '#2B4669';
export const primaryBlue8 = '#345580';
export const primaryBlue7 = '#506D92';
export const primaryBlue6 = '#99AABF';
export const primaryBlue5 = '#ADB8C7';
export const primaryBlue4 = '#C6D1E0';
export const primaryBlue2 = '#E6EDF7';
export const primaryBlue1 = '#ECF1F7';

export const gray11 = '#A7A9B1';
export const gray10 = '#111111';
export const gray9 = '#424951';
export const gray8 = '#505963';
export const gray7 = '#6A727A';
export const gray6 = '#A7ACB1';
export const gray2 = '#E9EBF0';
export const gray1 = '#F5F7FA';

export const neutralGray7 = '#686B6F';
export const neutralGray6 = '#BCBCBC';
export const neutralGray5 = '#D1D1D1';

export const black = '#000000';
export const white = '#FFFFFF';

/*************************************
 * SKELETON LOADERS
 *************************************/

export const skeletonLoadingBarsDark = '#C9D1DF';
export const skeletonLoadingBarsforeground = '#EDEDEE';

/*************************************
 * TRANSPARENTS
 *************************************/
export const neutralGrayGhost1 = 'rgba(122, 122, 122, 0.14)';
export const neutralGrayGhost2 = 'rgba(122, 122, 122, 0.26)';

export const primaryBlueGhost1 = 'rgba(52, 88, 128, 0.14)';
export const primaryBlueGhost2 = 'rgba(52, 88, 128, 0.26)';
export const primaryBlueGhost1Opaque = '#E2E7ED';
export const primaryBlueGhost2Opaque = '#CBD3DF';

export const grayGhost1 = 'rgba(80, 89, 99, 0.14)';
export const grayGhost2 = 'rgba(80, 89, 99, 0.26)';

export const whiteGhost1 = 'rgba(255, 255, 255, 0.14)';
export const whiteGhost2 = 'rgba(255, 255, 255, 0.26)';

export const redGhost1 = 'rgba(198, 40, 40, 0.14)';
export const redGhost2 = 'rgba(198, 40, 40, 0.26)';

export const whiteButtonDisabled = 'rgba(255, 255, 255, 0.5)';
