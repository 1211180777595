import React from 'react';

export default () => (
  <svg
    width="140"
    height="142"
    viewBox="0 0 140 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.9998 141.03C108.66 141.03 140 109.466 140 70.5302C140 31.5942 108.66 0.0302734 69.9998 0.0302734C31.34 0.0302734 0 31.5942 0 70.5302C0 109.466 31.34 141.03 69.9998 141.03Z"
      fill="#FFCB21"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="141"
      height="142"
    >
      <path
        d="M70.0004 141.03C108.66 141.03 140 109.466 140 70.5302C140 31.5942 108.66 0.0302734 70.0004 0.0302734C31.3406 0.0302734 0.000610352 31.5942 0.000610352 70.5302C0.000610352 109.466 31.3406 141.03 70.0004 141.03Z"
        fill="#FFCB21"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        opacity="0.2"
        d="M145.898 95.3577C145.898 92.6188 149.747 87.639 149.747 87.639L147.311 77.7149C147.205 77.7861 105.106 36.7382 105.106 36.7382C95.3228 41.8247 87.2703 43.3186 81.7961 41.2556C80.6306 40.7932 79.5711 40.1173 78.6528 39.2281C76.1099 36.7382 70.7063 29.4463 70.7063 29.4463C70.7063 29.4463 60.3582 49.7212 36.554 37.3429L34.0818 47.2669C34.0818 47.2669 37.8961 52.2112 37.8961 54.9856C37.8961 57.7601 37.5076 60.5701 37.4723 63.3446C37.4017 68.7868 37.7902 74.229 38.8144 79.529C40.0505 85.7893 42.2402 91.8362 45.8073 97.1362C47.0081 98.9503 48.4208 100.515 49.9748 101.974C50.1161 102.116 91.791 141.741 91.9323 141.883C95.3934 145.191 99.5609 147.681 103.728 150.207C104.717 150.811 105.742 151.381 106.766 151.914C110.509 153.799 113.123 155.685 113.123 155.685C113.123 155.685 115.913 154.013 119.621 151.843C122.412 150.207 124.848 148.855 127.356 147.183C130.464 145.085 133.607 142.986 136.044 140.069C138.41 137.259 140.212 134.022 141.66 130.643C142.401 128.9 143.037 127.086 143.567 125.272C145.156 119.901 145.933 114.281 146.18 108.696C146.322 104.89 146.216 101.084 145.933 97.2785C145.968 96.6382 145.898 95.9979 145.898 95.3577Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M107.331 47.2667L104.894 37.3427C81.0901 49.7566 70.7773 30.0508 70.7773 30.0508C70.7773 30.0508 60.3939 49.7566 36.5897 37.3427L34.1174 47.2667C34.1174 47.2667 37.9318 52.211 37.9318 54.9854C37.9318 57.7599 37.5433 60.5699 37.5079 63.3444C37.4373 68.7866 37.8258 74.2288 38.85 79.5288C40.0861 85.7891 42.2758 91.836 45.8429 97.136C49.6219 102.792 55.3434 106.206 61.0296 109.657C62.0538 110.297 63.078 110.902 64.1728 111.435C67.9872 113.356 70.742 115.277 70.742 115.277C70.742 115.277 73.5321 113.605 77.2404 111.435C80.0305 109.799 82.4675 108.447 84.975 106.775C88.083 104.677 91.2263 102.578 93.6632 99.6615C96.0295 96.8514 97.8307 93.6145 99.2787 90.2354C100.02 88.4925 100.656 86.6784 101.186 84.8643C102.775 79.4932 103.552 73.8731 103.799 68.2886C103.941 64.4826 103.835 60.6766 103.552 56.8706C103.517 56.2304 103.446 55.5901 103.446 54.9854C103.482 52.2465 107.331 47.2667 107.331 47.2667Z"
        fill="#345580"
      />
      <path
        d="M79.536 68.7518V63.9143C79.536 61.6734 78.759 59.6459 77.4169 58.0452C75.7923 56.1244 73.426 54.915 70.7419 54.915C65.8327 54.915 61.9124 58.9345 61.9124 63.9143V68.7518H59.4402V87.9597H82.0436V68.7518H79.536ZM72.2959 78.4624V82.7309H69.2938C69.2938 82.4819 69.2938 78.4624 69.2938 78.4624C68.6934 78 68.3049 77.2531 68.3049 76.3994C68.3049 75.0121 69.4704 73.8739 70.8125 73.8739C72.1899 73.8739 73.2847 75.0121 73.2847 76.3994C73.3554 77.2886 72.8963 78.0356 72.2959 78.4624ZM75.2979 68.7518H65.7621V63.4519C65.7621 60.6063 67.7045 58.2942 70.53 58.2942C73.3554 58.2942 75.2979 60.6063 75.2979 63.4519V68.7518Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="59.2315"
        y1="84.0875"
        x2="128.086"
        y2="118.941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#345580" />
        <stop offset="1" stopColor="#345580" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
