import { Accept } from 'react-dropzone/.';

export enum DocumentExtensions {
  PDF = '.PDF',
  DMS = '.DMS',
  JPG = '.JPG',
  JPEG = '.JPEG',
  PNG = '.PNG',
  GIF = '.GIF',
  TXT = '.TXT',
  TIFF = '.TIFF',
  TIF = '.TIF',
  FAST = '.FAST',
}

type DocumentContentType =
  | 'application/pdf'
  | 'image/jpeg'
  | 'image/png'
  | 'image/gif'
  | 'text/plain';

export const DocumentContentTypeLookup: Partial<Record<
  DocumentExtensions,
  DocumentContentType
>> = {
  [DocumentExtensions.PDF]: 'application/pdf',
  [DocumentExtensions.DMS]: 'application/pdf',
  [DocumentExtensions.FAST]: 'application/pdf',
  [DocumentExtensions.JPG]: 'image/jpeg',
  [DocumentExtensions.JPEG]: 'image/jpeg',
  [DocumentExtensions.PNG]: 'image/png',
  [DocumentExtensions.GIF]: 'image/gif',
  [DocumentExtensions.TXT]: 'text/plain',
};

export const isPaginationAndZoomVisible = (
  extension?: string | null
): boolean => isPdfDocType(extension);

export const getDocumentContentType = (
  extension: string
): DocumentContentType | '' =>
  DocumentContentTypeLookup[extension.toUpperCase()] || '';

export function isImageDocType(extension?: string | null) {
  return includesExtension(
    [
      DocumentExtensions.JPG,
      DocumentExtensions.JPEG,
      DocumentExtensions.PNG,
      DocumentExtensions.GIF,
    ],
    extension
  );
}

export function isTextDocType(extension?: string | null) {
  return includesExtension([DocumentExtensions.TXT], extension);
}

export function isPdfDocType(extension?: string | null) {
  return includesExtension(
    [DocumentExtensions.PDF, DocumentExtensions.DMS, DocumentExtensions.FAST],
    extension
  );
}

function includesExtension(
  extensions: DocumentExtensions[],
  extension?: string | null
) {
  return extensions.includes(extension?.toUpperCase() as any);
}

export const isPreviewable = (extension?: string | null) => {
  switch (extension?.toUpperCase()) {
    case DocumentExtensions.JPG:
    case DocumentExtensions.JPEG:
    case DocumentExtensions.PNG:
    case DocumentExtensions.PDF:
    case DocumentExtensions.TXT:
    case DocumentExtensions.GIF:
      return true;
    default:
      return false;
  }
};

export const acceptedFormatsForDropZone = (formatsArray: string[]): Accept => {
  return formatsArray.reduce((acc, format) => {
    acc[format] = [format];
    return acc;
  }, {} as Accept);
};
