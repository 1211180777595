import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    style={props.style}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0859 5C7.28594 5 5.08594 7.2 5.08594 10C5.08594 12.8 7.28594 15 10.0859 15C12.8859 15 15.0859 12.8 15.0859 10C15.0859 7.2 12.8859 5 10.0859 5ZM10.0859 0C4.58594 0 0.0859375 4.5 0.0859375 10C0.0859375 15.5 4.58594 20 10.0859 20C15.5859 20 20.0859 15.5 20.0859 10C20.0859 4.5 15.5859 0 10.0859 0ZM10.0859 18C5.68594 18 2.08594 14.4 2.08594 10C2.08594 5.6 5.68594 2 10.0859 2C14.4859 2 18.0859 5.6 18.0859 10C18.0859 14.4 14.4859 18 10.0859 18Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
