import { focusShadow, focusShadow2 } from 'config/theme';
import {
  gray6,
  gray8,
  grayGhost1,
  grayGhost2,
  neutralGray5,
  neutralGray7,
  neutralGrayGhost1,
  neutralGrayGhost2,
  primaryBlue6,
  primaryBlue8,
  primaryBlueGhost1,
  primaryBlueGhost2,
  red5,
  red8,
  redGhost1,
  redGhost2,
  white,
  whiteButtonDisabled,
  whiteGhost1,
  whiteGhost2,
  yellow1,
  yellow5,
} from 'config/colors';

export enum IconButtonColor {
  NEUTRAL_GRAY = 'neutralGray',
  PRIMARY_BLUE = 'primaryBlue',
  GRAY = 'gray',
  RED = 'red',
  WHITE = 'white',
  YELLOW = 'yellow',
  PRIMARY_BLUE_6 = 'primaryBlue6',
}

export interface IIconButtonTheme {
  iconColor: string;
  hoverBackgroundColor: string;
  activeBackgroundColor: string;
  focusBoxShadow: string;
  disabledIconColor: string;
}

const themes: {
  [property in IconButtonColor]: IIconButtonTheme;
} = {
  [IconButtonColor.NEUTRAL_GRAY]: {
    iconColor: neutralGray7,
    hoverBackgroundColor: neutralGrayGhost1,
    activeBackgroundColor: neutralGrayGhost2,
    focusBoxShadow: focusShadow,
    disabledIconColor: neutralGray5,
  },
  [IconButtonColor.PRIMARY_BLUE]: {
    iconColor: primaryBlue8,
    hoverBackgroundColor: primaryBlueGhost1,
    activeBackgroundColor: primaryBlueGhost2,
    focusBoxShadow: focusShadow,
    disabledIconColor: primaryBlue6,
  },
  [IconButtonColor.GRAY]: {
    iconColor: gray8,
    hoverBackgroundColor: grayGhost1,
    activeBackgroundColor: grayGhost2,
    focusBoxShadow: focusShadow,
    disabledIconColor: gray6,
  },
  [IconButtonColor.RED]: {
    iconColor: red8,
    hoverBackgroundColor: redGhost1,
    activeBackgroundColor: redGhost2,
    focusBoxShadow: focusShadow,
    disabledIconColor: red5,
  },
  [IconButtonColor.WHITE]: {
    iconColor: white,
    hoverBackgroundColor: whiteGhost1,
    activeBackgroundColor: whiteGhost2,
    focusBoxShadow: focusShadow2,
    disabledIconColor: whiteButtonDisabled,
  },
  [IconButtonColor.YELLOW]: {
    iconColor: yellow5,
    hoverBackgroundColor: neutralGrayGhost1,
    activeBackgroundColor: 'none',
    focusBoxShadow: focusShadow,
    disabledIconColor: yellow1,
  },
  [IconButtonColor.PRIMARY_BLUE_6]: {
    iconColor: primaryBlue6,
    hoverBackgroundColor: neutralGrayGhost1,
    activeBackgroundColor: neutralGrayGhost2,
    focusBoxShadow: focusShadow,
    disabledIconColor: neutralGray5,
  },
};

export default themes;
