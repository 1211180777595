import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { shuttleGrey } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="22"
    height="6"
    viewBox="0 0 22 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
    data-testid="hamburger-icon"
  >
    <path
      d="M16.5 2.75C16.5 2.02065 16.7897 1.32118 17.3055 0.805457C17.8212 0.289732 18.5207 0 19.25 0C19.9793 0 20.6788 0.289732 21.1945 0.805457C21.7103 1.32118 22 2.02065 22 2.75C22 3.47935 21.7103 4.17882 21.1945 4.69454C20.6788 5.21027 19.9793 5.5 19.25 5.5C18.5207 5.5 17.8212 5.21027 17.3055 4.69454C16.7897 4.17882 16.5 3.47935 16.5 2.75ZM8.25 2.75C8.25 2.02065 8.53973 1.32118 9.05546 0.805457C9.57118 0.289732 10.2707 0 11 0C11.7293 0 12.4288 0.289732 12.9445 0.805457C13.4603 1.32118 13.75 2.02065 13.75 2.75C13.75 3.47935 13.4603 4.17882 12.9445 4.69454C12.4288 5.21027 11.7293 5.5 11 5.5C10.2707 5.5 9.57118 5.21027 9.05546 4.69454C8.53973 4.17882 8.25 3.47935 8.25 2.75ZM0 2.75C0 2.02065 0.289731 1.32118 0.805456 0.805457C1.32118 0.289732 2.02065 0 2.75 0C3.47935 0 4.17882 0.289732 4.69454 0.805457C5.21027 1.32118 5.5 2.02065 5.5 2.75C5.5 3.47935 5.21027 4.17882 4.69454 4.69454C4.17882 5.21027 3.47935 5.5 2.75 5.5C2.02065 5.5 1.32118 5.21027 0.805456 4.69454C0.289731 4.17882 0 3.47935 0 2.75Z"
      fill={shuttleGrey}
    />
  </svg>
);
