import { ISearchClearAction } from 'store/search/types';

export enum FiltersTypeKeys {
  SET = 'filters/SET',
}

export interface IDealStatus {
  Open: boolean;
  Closed: boolean;
  Reserved: boolean;
  Cancelled: boolean;
  OpenInError: boolean;
}

export interface ILocation {
  query?: string | null;
}

export interface IEstimatedCloseDate {
  start: Date | null;
  end: Date | null;
}

export interface IOwningOffice {
  query: string | null;
}

export interface ISiteType {
  query: string | null;
}

export interface IOffice {
  state: string;
  city: string;
}

export interface IFilters {
  dealStatus: IDealStatus;
  estimatedCloseDate: IEstimatedCloseDate;
  location: ILocation;
  owningOffice: IOwningOffice;
  siteType?: ISiteType;
}

export interface IFiltersSet {
  type: FiltersTypeKeys.SET;
  filters: IFilters;
}

export type FiltersAction = IFiltersSet | ISearchClearAction;

export type IRangeProps = ['min', 'max'] | ['start', 'end'];

export enum DefaultLow {
  VALUE = '0',
  DATE = '1970-01-01T00:00:00.000Z',
}

export type IDefaultLow = DefaultLow.DATE | DefaultLow.VALUE;

export type IQueryValue = ILocation | IOwningOffice | ISiteType;

export interface IRangeValue {
  start: string | null;
  end: string | null;
}
