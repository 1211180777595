import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';
import { cadetBlue } from 'config/theme';

export default (props: ISVGProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 9C17.5109 9 15.6032 9.81125 14.1967 11.2553C12.7902 12.6993 12 14.6578 12 16.7C12 19.318 13.275 21.617 15.2143 23.014V25.5C15.2143 25.7917 15.3272 26.0715 15.5281 26.2778C15.729 26.4841 16.0016 26.6 16.2857 26.6H22.7143C22.9984 26.6 23.271 26.4841 23.4719 26.2778C23.6728 26.0715 23.7857 25.7917 23.7857 25.5V23.014C25.725 21.617 27 19.318 27 16.7C27 14.6578 26.2098 12.6993 24.8033 11.2553C23.3968 9.81125 21.4891 9 19.5 9ZM16.2857 29.9C16.2857 30.1917 16.3986 30.4715 16.5995 30.6778C16.8005 30.8841 17.073 31 17.3571 31H21.6429C21.927 31 22.1995 30.8841 22.4005 30.6778C22.6014 30.4715 22.7143 30.1917 22.7143 29.9V28.8H16.2857V29.9Z"
      fill={props.fill || cadetBlue}
    />
  </svg>
);
