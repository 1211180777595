import React from 'react';
import { ISVGProps } from 'components/svg/legacyIcons_DO_NOT_USE/types';

export default (props: ISVGProps) => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <path
      d="M13.0027 16.6353C11.8479 16.6353 10.7405 16.1891 9.92404 15.3949C9.10755 14.6007 8.64884 13.5235 8.64884 12.4003C8.64884 11.2771 9.10755 10.1999 9.92404 9.4057C10.7405 8.61148 11.8479 8.16529 13.0027 8.16529C14.1574 8.16529 15.2648 8.61148 16.0813 9.4057C16.8978 10.1999 17.3565 11.2771 17.3565 12.4003C17.3565 13.5235 16.8978 14.6007 16.0813 15.3949C15.2648 16.1891 14.1574 16.6353 13.0027 16.6353V16.6353ZM22.2452 13.574C22.2949 13.1868 22.3322 12.7996 22.3322 12.4003C22.3322 12.001 22.2949 11.6017 22.2452 11.1903L24.8699 9.21799C25.1062 9.03649 25.1684 8.70979 25.0192 8.44359L22.5313 4.25699C22.382 3.99079 22.0461 3.88189 21.7725 3.99079L18.675 5.20079C18.0282 4.72889 17.3565 4.31749 16.5728 4.01499L16.1125 0.808493C16.0628 0.518093 15.8015 0.300293 15.4905 0.300293H10.5148C10.2038 0.300293 9.94255 0.518093 9.89279 0.808493L9.43253 4.01499C8.64884 4.31749 7.97711 4.72889 7.33026 5.20079L4.23284 3.99079C3.95917 3.88189 3.62331 3.99079 3.47404 4.25699L0.986147 8.44359C0.824434 8.70979 0.899071 9.03649 1.13542 9.21799L3.76014 11.1903C3.71039 11.6017 3.67307 12.001 3.67307 12.4003C3.67307 12.7996 3.71039 13.1868 3.76014 13.574L1.13542 15.5826C0.899071 15.7641 0.824434 16.0908 0.986147 16.357L3.47404 20.5436C3.62331 20.8098 3.95917 20.9066 4.23284 20.8098L7.33026 19.5877C7.97711 20.0717 8.64884 20.4831 9.43253 20.7856L9.89279 23.9921C9.94255 24.2825 10.2038 24.5003 10.5148 24.5003H15.4905C15.8015 24.5003 16.0628 24.2825 16.1125 23.9921L16.5728 20.7856C17.3565 20.471 18.0282 20.0717 18.675 19.5877L21.7725 20.8098C22.0461 20.9066 22.382 20.8098 22.5313 20.5436L25.0192 16.357C25.1684 16.0908 25.1062 15.7641 24.8699 15.5826L22.2452 13.574Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M13.0027 16.6353C11.8479 16.6353 10.7405 16.1891 9.92404 15.3949C9.10755 14.6007 8.64884 13.5235 8.64884 12.4003C8.64884 11.2771 9.10755 10.1999 9.92404 9.4057C10.7405 8.61148 11.8479 8.16529 13.0027 8.16529C14.1574 8.16529 15.2648 8.61148 16.0813 9.4057C16.8978 10.1999 17.3565 11.2771 17.3565 12.4003C17.3565 13.5235 16.8978 14.6007 16.0813 15.3949C15.2648 16.1891 14.1574 16.6353 13.0027 16.6353V16.6353ZM22.2452 13.574C22.2949 13.1868 22.3322 12.7996 22.3322 12.4003C22.3322 12.001 22.2949 11.6017 22.2452 11.1903L24.8699 9.21799C25.1062 9.03649 25.1684 8.70979 25.0192 8.44359L22.5313 4.25699C22.382 3.99079 22.0461 3.88189 21.7725 3.99079L18.675 5.20079C18.0282 4.72889 17.3565 4.31749 16.5728 4.01499L16.1125 0.808493C16.0628 0.518093 15.8015 0.300293 15.4905 0.300293H10.5148C10.2038 0.300293 9.94255 0.518093 9.89279 0.808493L9.43253 4.01499C8.64884 4.31749 7.97711 4.72889 7.33026 5.20079L4.23284 3.99079C3.95917 3.88189 3.62331 3.99079 3.47404 4.25699L0.986147 8.44359C0.824434 8.70979 0.899071 9.03649 1.13542 9.21799L3.76014 11.1903C3.71039 11.6017 3.67307 12.001 3.67307 12.4003C3.67307 12.7996 3.71039 13.1868 3.76014 13.574L1.13542 15.5826C0.899071 15.7641 0.824434 16.0908 0.986147 16.357L3.47404 20.5436C3.62331 20.8098 3.95917 20.9066 4.23284 20.8098L7.33026 19.5877C7.97711 20.0717 8.64884 20.4831 9.43253 20.7856L9.89279 23.9921C9.94255 24.2825 10.2038 24.5003 10.5148 24.5003H15.4905C15.8015 24.5003 16.0628 24.2825 16.1125 23.9921L16.5728 20.7856C17.3565 20.471 18.0282 20.0717 18.675 19.5877L21.7725 20.8098C22.0461 20.9066 22.382 20.8098 22.5313 20.5436L25.0192 16.357C25.1684 16.0908 25.1062 15.7641 24.8699 15.5826L22.2452 13.574Z"
      fill="black"
      fillOpacity="0.18"
    />
  </svg>
);
